import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchAccounting = createAsyncThunk(
  "Accounting/fetchAccounting",
  async ( thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res =  axios.get(`${BaseUrl}/accounting`, {
        withCredentials: true,
      });
      const result =  res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const AccountingSlice = createSlice({
  name: "Accounting",
  initialState: {
    Accounting: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearAccounting: (state) => {
      state.Accounting = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: {
    // fetch Accounting
    [fetchAccounting.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchAccounting.fulfilled]: (state, action) => {
      state.Profit = action.payload;
      state.loading = false;
    },
    [fetchAccounting.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
 



  },
});
export const { clearProfits } = AccountingSlice.actions;
export default AccountingSlice.reducer;
