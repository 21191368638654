import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchSeateTypeById = createAsyncThunk(
  'SeatsType/fetchSeateTypeById',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {

      const res = await axios.get(`${BaseUrl}/getSeatType/${id}`, { withCredentials: true });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSeateTypeAll = createAsyncThunk(
  'SeatsType/fetchSeateTypeAll',
  async (id, thunkAPI) => {
    const res = await axios.get(`${BaseUrl}/seatTypesForCompany/${id}`, { withCredentials: true });

    const result = await res.data.data;
    return result;

  }
);
export const fetchSeateTypeAllfilter = createAsyncThunk(
  'SeatsType/fetchSeateTypeAllfilter',
  async (data, thunkAPI) => {

    const res = await axios.get(`${BaseUrl}/seatTypesForCompany/${data.id}`, { withCredentials: true });

    const result = await res.data.data;
    const filtered = result.filter((b) => b.type === data.type); // Filter buses by type

    return filtered;

  }
);

export const fetchAllSeateTypedependNumberSeats = createAsyncThunk(
  'SeatsType/fetchAllSeateTypedependNumberSeats',
  async (values, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/seatTypesForCompany/${values.id}`, { withCredentials: true });
      const result = await res.data.data;
      const filteredArray = res.data.data.filter(e => e.number_seats === parseInt(values.value));
      return filteredArray;


    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateSeateType = createAsyncThunk(
  'SeatsType/updateSeateType',
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    //   bookData.auther = getState().auth.name;
    try {

      await axios.post(`${BaseUrl}/updateTripType/${values.id}`, values, { withCredentials: true });
      sweetalert({
        text: "تم التعديل بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSeateType = createAsyncThunk(
  'SeatsType/deleteSeateType',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await axios.delete(`${BaseUrl}/hardDeleteSeatType/${id}`, { withCredentials: true });
      sweetalert({
        text: "تم الحذف بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else if (error.response && error.response.status === 500) {
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);

const SeatsTypeSlice = createSlice({
  name: 'SeatsType',
  initialState: { SeatType: [], filterTypeSeat: [], SeatsID: [], arrayDependfSeats: null, loading: false, loadingdelete: false, error: null },
  reducers: {
    clearSeatType: (state) => {
      state.SeatType = [];
      state.filterTypeSeat = [];
      state.SeatsID = [];
      state.loading = false;
      state.loadingdelete = false;
      state.arrayDependfSeats = null;
      state.error = null;
    },
    clearSeatTypefilter: (state) => {
      state.filterTypeSeat = [];
      state.loading = false;
      state.loadingdelete = false;
      state.error = null;
    }
  },
  extraReducers: {
    //fetch All Seate Type
    [fetchSeateTypeAll.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchSeateTypeAll.fulfilled]: (state, action) => {
      state.SeatType = action.payload;
      state.loading = false;
    },
    [fetchSeateTypeAll.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //fetch All Seate Type filter
    [fetchSeateTypeAllfilter.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchSeateTypeAllfilter.fulfilled]: (state, action) => {
      state.filterTypeSeat = action.payload;
      state.loading = false;
    },
    [fetchSeateTypeAllfilter.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    // //fetch All Seate Type
    [fetchAllSeateTypedependNumberSeats.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchAllSeateTypedependNumberSeats.fulfilled]: (state, action) => {
      state.arrayDependfSeats = action.payload;
      state.loading = false;
    },
    [fetchAllSeateTypedependNumberSeats.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //fetch  Seate Type ID
    [fetchSeateTypeById.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchSeateTypeById.fulfilled]: (state, action) => {
      state.SeatsID = action.payload;
      state.loading = false;
    },
    [fetchSeateTypeById.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    //delete type bus
    [deleteSeateType.pending]: (state, action) => {
      state.loadingdelete = true;
      state.error = null;
    },
    [deleteSeateType.fulfilled]: (state, action) => {
      // You can update your state as needed after successful deletion
      // For example, if you want to remove the deleted item from the state:
      state.SeatType = state.SeatType.filter(item => item.id !== action.payload);
      state.loadingdelete = false;
    },
    [deleteSeateType.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingdelete = false;
    },
  },
});

export const { clearSeatType, clearSeatTypefilter } = SeatsTypeSlice.actions;

export default SeatsTypeSlice.reducer;
