import { tokens } from "../../styles/theme";
import React, { useEffect, useState , useRef} from "react";
import { Box, useTheme, Button } from "@mui/material";
// import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";
import {  Select, MenuItem } from "@material-ui/core";
import {

  DataGridPro,
} from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import {
  GridFooterContainer,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridPagination,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../../component/controls/Controls";
import ConfirmDialog from "../../component/ConfirmDialogCompany.js";
import { deleteTripType, fetchTripTypeById } from "../../redux/tripTypesSlice";
import { useDispatch } from "react-redux";
import { fetchPaths } from "../../redux/PathSlice";
import { useSelector } from "react-redux";
import { fetchAllCity } from "../../redux/CitySlice";
import StepTripType from "./StepTripType";
import StepTripTypeupdate from "./StepTripTypeupdate.jsx";
import PopupStep from "../../component/PopupStep";
import { fetchCompanyById } from "../../redux/companyTransportDataSlice.jsx";
export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function CustomFooter() {
  return (
    <GridFooterContainer>
      <CustomPagination />
      <GridPagination />
    </GridFooterContainer>
  );
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    flexDirection: "row-reverse",
    paddingRight: "8px",
    height: "45px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
    marginRight: "3px",
  },
  "@media (min-width: 600px) and (max-width:1280px)": {
    searchDesign: {
      marginBottom: "15px",
    },
  },
  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  editButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#000002 !important",
    fontSize: "12px !important",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  deleteButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#000002 !important",
    fontSize: "12px !important",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  editImg: {
    width: "17px",
  },
  Marginbox1: {
    maxWidth: "100% !important",
    flexBasis: "100%",
  },
});

export default function TransportCompanyData({ trip_type, id_company,setShortcut }) {
  
  const [openPopup1, setOpenPopup1] = useState(false);
  const dispatch = useDispatch();
  const [recordForEdit1, setRecordForEdit1] = useState(null);
  const { loading } = useSelector((state) => state.CompanyData);
  const { Cities } = useSelector((state) => state.City);
  const [filteredData, setFilteredData] = useState(trip_type);
  const classes = useStyles();
  const [age, setAge] = useState("all");
  const [searched, setSearched] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [clickedEmployeeId, setClickedEmployeeId] = useState(null);
  const [selectedfromcityId, setSelectedFromCityId] = useState(null);
  const [selectedtocityId, setSelectedToCityId] = useState(null);
  const { loadingdelete,TripTypeID } = useSelector((state) => state.TripType);
  const[rowCount1,setRowCount1] = useState();

  useEffect(() => {
    let typedWord = "";
    let typedWord2 = "";
  
    const handleKeyDown = (event) => {
      if (/^[a-zA-Z]$/.test(event.key)) {
        typedWord += event.key.toLowerCase();
        typedWord2 += event.key;
  
        const lastThreeChars = typedWord.substring(typedWord.length - 3);
  
        if (lastThreeChars === 'add') {
          localStorage.setItem("displayshortcut", "1");
          setOpenPopup(true);
          setRecordForEdit(null);
          setShortcut(1);
          typedWord = '';
        } 
      }
    };
  
    document.addEventListener("keydown", handleKeyDown);
  
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  },[]);

 

  function YourTableComponent() {
    const firstColumnElementRef = useRef(null);
  
    useEffect(() => {
      if (firstColumnElementRef.current) {
        firstColumnElementRef.current.focus();
      }
    }, []);
  }
  const firstColumnElementRef = useRef(null);
  useEffect(() => {
    if (firstColumnElementRef.current) {
      firstColumnElementRef.current.focus();
    }
  }, []);
  const { user } = useSelector((state) => state.auth);

  const AddTripType =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "add.trip.type");

  const UpdateTripType =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "update.trip.type"
    );

  const DeleteTripType =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "hard.delete.trip.type"
    );

  const AddTripTypedefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "add.trip.type"
    );

  const UpdateTripTypedefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "update.trip.type"
    );

  const DeleteTripTypedefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "hard.delete.trip.type"
    );

  const { Companies } = useSelector((state) => state.Company);
  useEffect(() => {}, [dispatch, id_company, Companies.length]);
  useEffect(() => {
    dispatch(fetchAllCity());
    dispatch(fetchCompanyById(id_company));
    setRowCount1(filteredTrips?.length)
  }, [dispatch]);


  const onHandleReset = () => {
    setSelectedFromCityId(null);
    setSelectedToCityId(null);
  };

  function handleDelete(id) {
    setConfirmDialog({
      isOpen: true,
      title: "أنت على وشك حذف  نوع الرحلة",
      img: "img/emp/Inbox cleanup-rafiki.svg",
      onConfirm: () => {
        dispatch(deleteTripType(id));
      },
    });
  }
  function filterTrips(trip_type) {
    if (selectedfromcityId && selectedtocityId) {
      return trip_type.filter(
        (trip) =>
          trip.id_city_from === selectedfromcityId &&
          trip.id_city_to === selectedtocityId
      );
    } else if (selectedfromcityId) {
      return trip_type.filter(
        (trip) => trip.id_city_from === selectedfromcityId
      );
    } else if (selectedtocityId) {
      return trip_type.filter((trip) => trip.id_city_to === selectedtocityId);
    } else {
      return trip_type;
    }
  }
  const filteredTrips = filterTrips(trip_type);
 
  function EditToolbar(props) {
    return (
      <Grid container xs={12} className={classes.Marginbox1}>
        <Grid lg={3} md={3} sm={3} className={classes.AddDesign}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className={` ${selectedfromcityId ? "selects11" : "selects1"}`}
            text="بحث"
            value={selectedfromcityId}
            onChange={(e) => setSelectedFromCityId(e.target.value)}
          >
            {Cities.map((city) => (
              <MenuItem value={city.id} key={city.id}>
                <span>{city.name_ar}</span>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid lg={3} md={3} sm={3} className={classes.AddDesign}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className={` ${selectedtocityId ? "selects22" : "selects2"}`}
            value={selectedtocityId}
            onChange={(e) => setSelectedToCityId(e.target.value)}
          >
            {Cities.map((city) => (
              <MenuItem value={city.id} key={city.id}>
                <span>{city.name_ar}</span>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid lg={1} md={1} sm={2} className={classes.AddDesign}>
          <Button className="reloadButton1" onClick={onHandleReset}>
            <img src="img/reload.svg" alt="reload.svg" />
          </Button>
        </Grid>
        {AddTripType === true || AddTripTypedefault === true ? (
          <Grid lg={2} md={2} sm={3} className={classes.AddDesign}>
            <div className="bottonFullwidth1">
              <Controls.Button
                type="submit"
                text="إضافة"
                onClick={() => {
                  
                  setOpenPopup(true);
                  setRecordForEdit(null);
                }}
              />
            </div>
          </Grid>
        ) : (
          <></>
        )}
        <PopupStep
          title="إضافة نوع رحلة"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setDisplayshortcut={setShortcut}
        >
          <StepTripType
            id_company={id_company}
          />
        </PopupStep>
      </Grid>
    );
  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };




  const processRowUpdate = (newRow) => {
   
  };

  
  const columns = [
    {
      field: "type",
      sortable: false,
      headerName: "نوع الرحلة",
      flex: 1,
      headerAlign: "center",
      align: "center",

    },
 
    {
      field: "idCityFrom",
      sortable: false,
      headerName: "الانطلاق",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "idCityTo",
      sortable: false,
      headerName: "الوجهة",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "return_reduction_upon_cancellation",
      sortable: false,
      headerName: "نسب القطع عند الغاء رحلة قبل مدة محددة ",
      flex: 1,
      headerAlign: "left",
      align: "center",
    },
    {
      field: "action",
      headerAlign: "center",
      headerName: "العمليات",
      flex: 1,
      sortable: false,
      headerName: "",
      align: "center",
      renderCell: ({ id }) => {
        return (
          <Box>
            {UpdateTripType === true || UpdateTripTypedefault === true ? (
              <Button
                className={classes.editButton}
                onClick={() => {
                  setOpenPopup1(true);
                  setRecordForEdit1(null);
                  setClickedEmployeeId(id);
                  dispatch(fetchTripTypeById(id));
                  dispatch(fetchPaths(id_company));
                }}
              >
                تعديل
                <img
                  src="img/edit.svg"
                  className={classes.editImg}
                  alt="delete icon"
                ></img>
              </Button>
            ) : (
              <></>
            )}
            {DeleteTripType === true || DeleteTripTypedefault === true ? (
              <Button
                className={classes.deleteButton}
                onClick={() => {
                  handleDelete(id);
                }}
              >
                حذف
                <img
                  src="img/delete.svg"
                  className={classes.editImg}
                  alt="delete icon"
                ></img>
              </Button>
            ) : (
              <></>
            )}
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
              loadingdelete={loadingdelete}
            />
            <PopupStep
              title="تعديل نوع رحلة"
              openPopup={openPopup1}
              setOpenPopup={setOpenPopup1}
              setDisplayshortcut={setShortcut}
            >
              <StepTripTypeupdate
                id_company={id_company}
                id_tripType={clickedEmployeeId}
                recordForEdit={recordForEdit}
              />
            </PopupStep>
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <Box
        className="box-triptype table-accounting"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },

          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "none",
            fontSize: "15px !important",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "15px !important",
            color: `${colors.primary[1000]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.white[100],
          },
        }}
      >
        {loading ? (
          <div className="spinner1-box">
            <svg class="spinner1" viewBox="0 0 50 50">
              <circle
                class="path1"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          </div>
        ) : (
          <DataGridPro
            rows={filteredTrips || []}
            columns={columns}
            className="field-table"
            editMode="row"
            pageSize={15}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            rowCount={rowCount1}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            components={{
              Toolbar: EditToolbar,
              Footer: CustomFooter,

            }} 
          />
        )}
      </Box>
    </Box>
  );
}
