import React from "react";
import Card from "./Card";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Controls from "../../component/controls/Controls";
import { useState } from "react";
import Popup from "../../component/Popup";
import CompanyAddForm from "./CompanyAddForm";

const useStyles = makeStyles({
  gridDivide: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "23px",
  },
  "@media (min-width: 768px) and (max-width:1366px)": {
    gridDivide: {
      justifyContent: "space-around",
      gap: "0px",
    },
  },
});

const CardList2 = ({ mylist }) => {
  const classes = useStyles();
  const [openPopup, setOpenPopup] = useState(false);
  const { CompaniesData } = useSelector((state) => state.CompanyData);
  const { user } = useSelector((state) => state.auth);
  const AddCompanyPermission =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "add.company"
    );
  const AddCompanyPermissionsdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "add.company"
    );


  return (
    <Grid className={classes.gridContainer} item lg={12}>
      {AddCompanyPermission === true ||
      AddCompanyPermissionsdefault === true ? (
        <Grid
          lg={12}
          md={12}
          sm={12}
          style={{ direction: "ltr" }}
          className={classes.AddDesign}
        >
          <div style={{ direction: "ltr" }} className="add-company">
            <Controls.Button
              type="submit"
              text="إضافة"
              onClick={() => {
                setOpenPopup(true);
              }}
            />
          </div>
        </Grid>
      ) : (
        <></>
      )}
      <Popup
        title="إضافة شركة"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <CompanyAddForm />
      </Popup>
      <Grid
        container
        spacing={4}
        className={classes.gridContainer}
        justify="center"
      >
        <Grid className={classes.gridDivide}>
          <Card AllCompany={CompaniesData} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CardList2;
