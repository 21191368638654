import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './authSlice';
import Trip from './tripsSlice';
import Company, { fetchCompany } from './companyTransportSlice';
import CompanyData, { fetchCompanyById } from './companyTransportDataSlice'
import TripType from './tripTypesSlice'
import City from './CitySlice'
import Station from './StationSlice'
import Offices from './Office'
import Employee from './employeeSlice'
import Roles from './RolesSlice'
import Paths from './PathSlice'
import Notifcations from './notificationslice'
import seatsReducer from './SeatsSlice';
import SeatsType from './SeatsTypeSlice'
import Bus from './busSlice'
import Passengers from './PasssengersTrip'
import passangerreservation from './reservationPassanger'
import Setsbus from './SetsBus'
import reservations from './reservation'
import Compolaint from './Complaints'
import CityEmployee from './AddCityBranchEmployee'
import Branch from './AddCompanyEmpBranch'
import AdminCompany from './AddAssignAdminCompany'
import DisableAccount from './ActiveClientAcoount'
import permission from './Permission' 
import PriveePolice from './prvicepolice' 
import PriveePoliceCompany from './prvicepoliceCompany' 
import Profits from './profit' 
import Ticket from './TickeType' 
import Trip_Schedular from './tripsSchedularSlice' 
import Accounting from './Accounting' 
import Aduit from './AddAndRemoveaudit' 
import TripManulas from './tripsManulDoneSlice' 
import TripLock from './tripsLock' 
const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  Trip,
  DisableAccount,
  Company,
  CompanyData,
  reservations,
  TripType,
  City,
  Station,
  PriveePolice,
  Offices,
  Employee,
  Roles,
  Passengers,
  Setsbus,
  passangerreservation,
  Paths,
  SeatsType,
  Notifcations,
  Bus,
  CityEmployee,
  Branch,
  AdminCompany,
  Compolaint,
  permission,
  PriveePoliceCompany,
  Profits,
  Ticket,
  Accounting,
  Trip_Schedular,
  TripManulas,
  TripLock,
  Aduit,
  createBus: seatsReducer,

});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

persistor.subscribe(() => {
  const state = store.getState();
});

export { store, persistor };
