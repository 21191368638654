import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
const useStyles = makeStyles({
  root: {
    width: 350,
  },
  "@media (min-width: 768px) and (max-width:1366px)": {
    root: {},
  },
  content: {
    display: "flex",
    flexDirection: "column !important",
    alignItems: "center",
    padding: "0 !important",
    position: "relative",
  },
  media: {
    width: 126,
    height: 126,
    marginTop: "15px",
  },
  title: {
    fontFamily: "beIN Normal",
    fontSize: 27,
    color: "#000002",
    fontWeight: "normal",
  },
});

const OutlinedCard = ({ startIndex, endIndex, Complaints }) => {
  const classes = useStyles();
  return (
    <>
      {Complaints?.slice(startIndex, endIndex).map((e) => (
        <Card className={classes.root} variant="outlined">
          <CardContent className={classes.content}>
            <CardMedia title="Contemplative Reptile">
              <img src="img/complaint1.svg" className={classes.media} />
            </CardMedia>

            <div className="body-container-complaint" style={{ width: "100%" }}>
              <div className="divtitleone1">
                <img src="img/compaint2.svg" />
                <span>{e.name}: اسم مقدم الشكوى</span>
              </div>
              <div className="divtitletwo2">
                <img src="img/compaint3.svg" />
                <span>رقم التواصل :{e.mobile_client}</span>
              </div>
              <div className="divtitlethree3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "5px",
                  }}
                >
                  <div style={{ width: "20px", height: "20px" }}>
                    <img
                      src="img/compaint4.svg"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <p>الرحلة التي حصلت خلالها المشكلة</p>
                </div>
                <br />
                <p>مسار الرحلة : {e.company_branch_trip}</p>
                <br />
                <p>تاريخ الرحلة : {e.trip_date}</p>
                <br />
                <p>وقت الرحلة : {e.trip_time}</p>
              </div>
            </div>
          </CardContent>
          <h4 className="bodycomplaint">{e.description}</h4>
        </Card>
      ))}
    </>
  );
};
export default OutlinedCard;
