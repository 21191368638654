import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchTrips = createAsyncThunk(
  "Trip/fetchTrips",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllTrip/${id}`, {
        withCredentials: true,
      });
      const result = await res.data.data;

      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const fetchTripBYId = createAsyncThunk(
  "Trip/fetchTripBYId",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const branch = localStorage.getItem("branch");

    try {
      // Include the branch as a query parameter
      const res = await axios.get(`${BaseUrl}/getTrip/${id}?branch=${branch}`, {
        withCredentials: true,
      });
      const result = res.data.data;
      return result;
    } catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);
export const fetchTripBYIdReservation = createAsyncThunk(
  "Trip/fetchTripBYIdReservation",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getTrip/${id}`, {
        withCredentials: true,
      });
      const reservations = await res.data.data.reservations;
      const countpassanger = await res.data.data.reservations.map(
        (rese) => rese.count_passenger
      );
      return reservations;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const insertTrip = createAsyncThunk(
  "Trip/insertTrip",
  async (values, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/addTrip`, values, { withCredentials: true });
      sweetalert({
        text: "تم الإضافة بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        dispatch(setstatus(true));
      });
    } catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else if (error.response && error.response.status === 500) {
        // Handle the 500 error server   error specifically
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else if (error.response && error.response.status === 401) {
        // Handle the 500 error server   error specifically
        sweetalert({
          text: error.response.data.data[0],
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateTrip = createAsyncThunk(
  "Trip/updateTrip",
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/updateTrip/${values.id}`, values, {
        withCredentials: true,
      });
      sweetalert({
        text: "تم التعديل بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else if (error.response && error.response.status === 500) {
        // Handle the 500 error server   error specifically
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else if (error.response && error.response.status === 401) {
        // Handle the 500 error server   error specifically
        sweetalert({
          text: error.response.data.data[0],
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteTrip = createAsyncThunk(
  "Trip/deleteTrip",
  async ({ id, branch_id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(`${BaseUrl}/hardDeleteTrip/${id}?branch=${branch_id}`, {
        withCredentials: true,
      });
      if (
        response.data &&
        response.data.success === "true" &&
        response.data.data === true
      ) {
        sweetalert({
          text: "تم الحذف بنجاح",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      } else {
        // Handle other responses if needed
        // For example, you can display an error message for failed deletion
        // You can access the specific error message from the response's "data" object
        sweetalert({
          text: response.data.data.original.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else if (error.response && error.response.status === 500) {
        // Handle the 500 error server   error specifically
        console.log(error.response.data,'error.response.data')
        sweetalert({
          text: error.response.data.message ||error.response.data.data ,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else if (error.response && error.response.status === 401) {
        // Handle the 500 error server   error specifically
        sweetalert({
          text: error.response.data.data[0],
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteReservation = createAsyncThunk(
  "reservations/deleteReservation",
  async ({ reservationId, id_branch, id }, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/deleteReservation/${reservationId}?branch=${id_branch}`, {
        withCredentials: true,
      });
      sweetalert({
        text: "تم الحذف بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        dispatch(fetchTripBYId(id));
        // window.location.reload();
      });
      // Return the deleted reservation ID
      return { reservationId };
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else if (error.response && error.response.status === 500) {
        // Handle the 500 error server   error specifically
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else if (error.response && error.response.status === 401) {
        // Handle the 500 error server   error specifically
        sweetalert({
          text: error.response.data.data[0],
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);

const TripSlice = createSlice({
  name: "Trip",
  initialState: {
    Trips: [],
    updateTrip: [],
    TripID: [],
    TripReservation: [],
    status: false,
    loading: false,
    loadingget: false,
    loadingupdate: false,
    loadingAdd: false,
    error: null,
  },
  reducers: {
    clearTripState: (state) => {
      state.Trips = [];
      state.updateTrip = [];
      state.TripID = [];
      state.TripReservation = [];
      state.loading = false;
      state.loadingget = false;
      state.loadingupdate = false;
      state.loadingAdd = false;
      state.status = false;
      state.error = null;
    },
    setstatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: {
    //fetch
    [fetchTrips.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchTrips.fulfilled]: (state, action) => {
      state.Trips = action.payload;
      state.loading = false;
    },
    [fetchTrips.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //Update  trip
    [updateTrip.pending]: (state, action) => {
      state.loadingupdate = true;
      state.error = null;
    },
    [updateTrip.fulfilled]: (state, action) => {
      state.updateTrip = action.payload;
      state.loadingupdate = false;
    },
    [updateTrip.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingupdate = false;
    },
    //fetch
    [fetchTripBYId.pending]: (state, action) => {
      state.loadingget = true;
      state.error = null;
    },
    [fetchTripBYId.fulfilled]: (state, action) => {
      state.TripID = action.payload;
      state.loadingget = false;
    },
    [fetchTripBYId.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingget = false;
    },
    //fetch Reservation
    [fetchTripBYIdReservation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchTripBYIdReservation.fulfilled]: (state, action) => {
      state.TripReservation = action.payload;
      state.loading = false;
    },
    [fetchTripBYIdReservation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //delete  reservation in trip
    [deleteReservation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deleteReservation.fulfilled]: (state, action) => {
      state.TripReservation = state.TripReservation.filter(
        (el) => el.id !== action.payload.id
      );

      state.loading = false;
    },
    [deleteReservation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export const { setstatus, clearTripState } = TripSlice.actions;
export default TripSlice.reducer;
