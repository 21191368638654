import React, { useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, makeStyles, Typography } from '@material-ui/core';
import Controls from "./controls/Controls";
import CloseIcon from '@material-ui/icons/Close';
import { logDOM } from '@testing-library/react';


const useStyles = makeStyles(theme => ({
 
    dialogWrapper: {
        position: 'absolute',
        top: theme.spacing(5),
        width:'662px !important',
        maxWidth:'662px !important',
        height:'auto !important'
    },
    dialogTitle: {
        padding: '0px '
    },
    headerDesgin:{
        textAlign:'center',
        color:'#F9B917',
        fontSize:'25px',
    },
    "@media (min-width: 768px) and (max-width:1556px)": {
        dialogWrapper: {
            height:'auto !important',
            top: '3% !important',
        },
      },

}))

export default function PopupTickit(props) {

    const { title, children, openPopup, setOpenPopup } = props;
    const classes = useStyles();
    
    
    const close = (()=>{
        setOpenPopup(false);
        localStorage.setItem("displayshortcut", "0");
        localStorage.setItem('openpassenger',false);
        localStorage.setItem('changeseat',false);

    })
    
 

    const handleKeyDown = (event) => {
        if (event.key === 'Delete') {
            setOpenPopup(false);
            localStorage.setItem('openpassenger',false);
            localStorage.setItem('changeseat',false);
        }
      };
      
      useEffect(() => {
        if (openPopup) {
          document.addEventListener('keydown', handleKeyDown);
        } else {
          document.removeEventListener('keydown', handleKeyDown);
        }
      
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, [openPopup]);
    return (
        <Dialog open={openPopup}  classes={{ paper: classes.dialogWrapper }} >
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' , alignItems:'center' }} className='headerpoup'>
                    <Typography variant="h6" className={classes.headerDesgin} component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <Controls.ActionButton
                    
                        color="secondary"
                        onClick={()=>close()}>
                        <CloseIcon  />
                    </Controls.ActionButton>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}
