import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import Controls from "./controls/Controls";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-container": {
      direction: "rtl !important",
    },
    position: "absolute",
    top: theme.spacing(7),
    width:'35% !important',
  },
  dialogTitle: {
    textAlign: "right !important",
    fontFamily: "beIN !important",
    fontWeight: "normal !important",
    fontSize: "30px !important",
  },
  title:{
    fontWeight: "normal !important",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
    width: "100%",
    padding: "8px 0px 8px 0 !important",
  },
  titleIcon: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export default function ConfirmDialog(props) {
  const { confirmDialog, setConfirmDialog ,loadingdelete} = props;
  const classes = useStyles();
  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        <img
          src={confirmDialog.img}
          alt="delete"
          className={confirmDialog.clas}
        />
      </DialogTitle>
      <DialogActions className={classes.dialogAction}>
        <div className="button-dailog">
        {loadingdelete  ? (
          <div
            className=" loaddelete1"
            style={{ background: "#F9B917", borderRaduis: "10px" }}
          >
            <div v-if="loading" class="spinner">
              <div class="rect1"></div>
              <div class="rect2"></div>
              <div class="rect3"></div>
              <div class="rect4"></div>
              <div class="rect5"></div>
            </div>
          </div>
        ) : (
          
          <Controls.Button
            className=""
            type="submit"
            text="حذف"
            onClick={confirmDialog.onConfirm}
          />
       
        )}
          <Controls.Button
            text="تراجع"
            color="default"
            onClick={() =>
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}
