import React, { useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { fetchAllCity } from "../../redux/CitySlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { useState } from "react";
import Controls from "../../component/controls/Controls";
import { insertBranchEmployee } from "../../redux/AddCityBranchEmployee";

import { insertAssignAdminCompany } from "../../redux/AddAssignAdminCompany";
function CheckboxCompany({ id_employee }) {
  const { loading, Companies, error } = useSelector((state) => state.Company);
  const { AdminCompanyID, loadingAdd } = useSelector(
    (state) => state.AdminCompany
  );
  const [checkedCompany, setCheckedCompany] = useState([]); // State to manage checked cities
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  //Add Permission
  const RemoveCompanyAdmin =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "remove.company.admin"
    );

  const RemoveCompanyAdmindefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "remove.company.admin"
    );

    useEffect(() => {
      dispatch(fetchAllCity());
      if (AdminCompanyID && AdminCompanyID) {
        setCheckedCompany(AdminCompanyID);
      }
    }, [dispatch, AdminCompanyID]);
  useEffect(() => {},[checkedCompany,AdminCompanyID])

  // Handle checkbox change event
  const handleCityCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const companyId = parseInt(value); // Convert the value to a number
    if (checked) {
      setCheckedCompany((prevCheckedCities) => [
        ...prevCheckedCities,
        companyId,
      ]);
    } else {
      setCheckedCompany((prevCheckedCities) =>
        prevCheckedCities.filter((company) => company !== companyId)
      );
    }
  };
  const handleSave = () => {
    const data = {
      id_user: id_employee,
      id_company: checkedCompany,
    };
    dispatch(insertAssignAdminCompany(data));
  };
  return (
    <div className="checkreservation">
      <FormGroup>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row-reverse",
          }}
        >
          {Companies.map((company) => (
            <Grid item lg={4} md={4} sm={4} style={{ display: "flex" }}>
              {(RemoveCompanyAdmin === true &&
                RemoveCompanyAdmindefault === true) ||
              (RemoveCompanyAdmin === true &&
                RemoveCompanyAdmindefault === false) ||
              (RemoveCompanyAdmin === false &&
                RemoveCompanyAdmindefault === true) ? (
                <FormControlLabel
                  key={company.id} // Provide a unique key for each dynamically generated FormControlLabel
                  control={
                    <Checkbox
                      name="id_company"
                      value={company.id}
                      checked={checkedCompany.includes(company.id)}
                      onChange={handleCityCheckboxChange}
                    />
                  }
                  label={company.name_ar}
                />
              ) : (
                <FormControlLabel
                  key={company.id} // Provide a unique key for each dynamically generated FormControlLabel
                  control={
                    <Checkbox
                      name="id_company"
                      value={company.id}
                      checked={checkedCompany.includes(company.id)}
                      onChange={handleCityCheckboxChange}
                    />
                  }
                  label={company.name_ar}
                  disabled={true}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </FormGroup>
      {(RemoveCompanyAdmin === true && RemoveCompanyAdmindefault === true) ||
      (RemoveCompanyAdmin === true && RemoveCompanyAdmindefault === false) ||
      (RemoveCompanyAdmin === false && RemoveCompanyAdmindefault === true) ? (
        loadingAdd ? (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="load loadingupdate"
              style={{ background: "#F9B917", borderRaduis: "10px" }}
            >
              <div v-if="loading" class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Controls.Button
              onClick={() => handleSave()}
              type="submit"
              text="حفظ"
            />
          </div>
        )
      ) : (
        <div className="bottonFullwidth">
          <Controls.Button
            onClick={() => handleSave()}
            type="submit"
            text="حفظ"
            disabled={true}
          />
        </div>
      )}
    </div>
  );
}

export default CheckboxCompany;
