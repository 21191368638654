import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import SelectBusType from "./SelectBusType";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import ActionButton from "./ActionButton";
import SelectPath from './SelectPath';
import SelectTripBus from './SelectTripBus'
import SelectBranch from './SelectBranch'
import SelectBus from './SelectBus'
import SelectTypeTrip from './SelectTypeTrip'
import SelectPathFilter from './SelectPathFilter';
import SelectCity from './SelectCity';
import SelectTypeTrip2 from './SelectTypeTrip2';
import SelectGender from './SelectGender';
import SelectTrip from './SelectTrip';
import SelectRole from './SelectRole';
import SelectBusType2 from './SelectBusType2';
import SelectTypeTripCart from './SelectTypeTripCart';
import SelectTypeTripCart2 from './SelectTypeTripCart2';
import SelectTypeTripCart3 from './SelectTypeTripCart3';
import SelectBusType3 from './SelectBusType3';
import SelectBusTypeTwo from './SelectBusTypeTwo';
const Controls = {
    Input,
    SelectTypeTrip2,
    SelectBusType2,
    SelectBusType3,
    SelectBusType,
    SelectBusTypeTwo,
    SelectBranch,
    SelectPath,
    SelectCity,
    SelectTripBus,
    SelectPathFilter,
    SelectGender,
    SelectRole,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    SelectTypeTrip,
    SelectTypeTripCart,
    SelectTypeTripCart2,
    SelectTypeTripCart3,
    SelectTrip,
    SelectBus,
    ActionButton
}

export default Controls;