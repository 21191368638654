import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchTripsManulaDone = createAsyncThunk(
  "TripManula/fetchTripsManulaDone",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllTripManualDone/${id}`, {
        withCredentials: true,
      });
      const result = await res.data.data;

      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);




const TripManualDoneSlice = createSlice({
  name: "TripManula",
  initialState: {
    TripManulas: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearTripManulaState: (state) => {
      state.TripManulas = [];
      state.loading = false;
      state.error = null;
    },
  
  },
  extraReducers: {
    //fetch
    [fetchTripsManulaDone.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchTripsManulaDone.fulfilled]: (state, action) => {
      state.TripManulas = action.payload;
      state.loading = false;
    },
    [fetchTripsManulaDone.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  
  },
});
export const {  clearTripManulaState } = TripManualDoneSlice.actions;
export default TripManualDoneSlice.reducer;
