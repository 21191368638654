import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Controls from "./controls/Controls";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    position: "absolute",
    width: "980px !important",
    maxWidth: "980px !important",
    height: "auto !important",
    maxHeight: "auto !important",
    opacity: "1",
    transition: "opacity 2ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "@media (min-width: 600px) and (max-width:1280px)": {
    dialogWrapper: {
      width: "700px !important",
      maxWidth: "700px !important",
    },
  },
  dialogTitle: {
    padding: "0px ",
  },
  headerDesgin: {
    textAlign: "center",
    color: "#F9B917",
    fontSize: "25px",
  },
  "@media (min-width: 768px) and (max-width:1556px)": {
    dialogWrapper: {
      height: 'auto !important',
      top: '3% !important',
    },
  },
}));

export default function PopupCreate2(props) {
  const { title, children, openPopup, setOpenPopup, setSelectedRow, setFilteredTrips } = props;

  const classes = useStyles();
  const handleKeyDown = (event) => {
    if (event.key === 'Delete') {
      setOpenPopup(false);
      localStorage.setItem("displayshortcut", "0");
      localStorage.setItem('openpassenger', false);
      localStorage.setItem('changeseat', false);
    }
  };

  useEffect(() => {
    if (openPopup) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPopup]);

  const close = (() => {
    setOpenPopup(false);
    localStorage.setItem("displayshortcut", "0");
    localStorage.setItem('openpassenger', false);
    localStorage.setItem('changeseat', false);

  })

  return (
    <Dialog open={openPopup} classes={{ paper: classes.dialogWrapper }}>
      <DialogTitle className={classes.dialogTitle}>
        <div
          style={{
            display: "flex",
            margin: "33px 0px 18px 0px",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            className={classes.headerDesgin}
            component="div"
            style={{ flexGrow: 1 }}
          >
            {title}
          </Typography>
          <Controls.ActionButton
            color="secondary"
            onClick={() => close()}
          >
            <CloseIcon />
          </Controls.ActionButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
