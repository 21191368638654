import React, { useState, useEffect, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";
import { makeStyles } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAllRoles } from "../../redux/RolesSlice";
import { TextField } from "formik-material-ui";
import { fetchEmployeeByID, insertEmployee } from "../../redux/employeeSlice";
import * as sweetalert from "sweetalert";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Radio } from "@mui/material";
import {
  fetchCompanyBranch,
  fetchCompanyById,
} from "../../redux/companyTransportDataSlice";
const useStyles = makeStyles((theme) => ({

}));

export default function EmployeeFormAdmin(props) {
  const dispatch = useDispatch();
  const [file, setFile] = useState("");
  const { loading, Roles, error } = useSelector((state) => state.Roles);
  const { EmployeeiesByID, loadingAdd } = useSelector(
    (state) => state.Employee
  );
  const [MonoCompany, setMonoCompany] = useState(false);
  //   const [MultiCompany, setMultiCompany] = useState(false);
  const { Companies } = useSelector((state) => state.Company);
  const { CompanyBranch } = useSelector((state) => state.CompanyData);
  const [checkedCompany, setCheckedCompany] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedBranchId, setSelectedBranchId] = useState("");
  const [MultiselectedBranchEmployee, setMultiSelectedBranchEmployee] =
    useState(false);

  // Handle radio button change event
  const handleCompanyChange = (event) => {
    setSelectedCompanyId(event.target.value);
    dispatch(fetchCompanyBranch(event.target.value));
  };
  const handleBranchChange = (event) => {
    setSelectedBranchId(event.target.value);
  };
  useEffect(() => {
    dispatch(fetchAllRoles());
  }, [dispatch]);
  const classes = useStyles();
  const { addOrEdit, recordForEdit } = props;
  const genderItems = [
    { id: "male", title: "Male" },
    { id: "female", title: "Female" },
  ];
  const RolesAdmin = [{ id: 2, title: "Admin" }];

  const initialFValues = {
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    national_id: "",
    birth_date: "",
    birth_place: "",
    gender: "",
    image: "",
    set_role_id: "",
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues)
      temp.first_name = fieldValues.first_name ? "" : "هذا الحقل مطلوب";
    if ("last_name" in fieldValues)
      temp.last_name = fieldValues.last_name ? "" : "هذا الحقل مطلوب";
    if ("birth_place" in fieldValues)
      temp.birth_place = fieldValues.birth_place ? "" : "هذا الحقل مطلوب";
    if ("gender" in fieldValues)
      temp.gender = fieldValues.gender ? "" : "هذا الحقل مطلوب";
    if ("birth_date" in fieldValues)
      temp.birth_date = fieldValues.birth_date ? "" : "هذا الحقل مطلوب";
    if ("national_id" in fieldValues)
      temp.national_id = fieldValues.national_id ? "" : "هذا الحقل مطلوب";

    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "البريد الالكتروني غير صالح";
    if ("password" in fieldValues) {
      temp.password =
        fieldValues.password.length >= 8
          ? ""
          : "كلمة المرور يجب أن تحتوي على 8 أحرف على الأقل";
    }
    if ("set_role_id" in fieldValues)
      temp.set_role_id =
        fieldValues.set_role_id.length != 0 ? "" : "هذا الحقل مطلوب";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("id_company", selectedCompanyId);
        formData.append("id_branch", selectedBranchId);

        for (let key in values) {
          if (key !== "image") {
            formData.append(key, values[key]);
          }
        }

        dispatch(insertEmployee(formData));
      } catch (error) {
        sweetalert(error.data[0], {
          className: "alert-div",
          button: {
            text: "OK",
          },
        });
      }
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);
  const handleImageChange = (e) => {
    setValues({ ...values, image: e.target.files[0] });
    setFile(e.target.files[0]);
  };
  const handleChangeRole = (e) => {
    setValues({ ...values, set_role_id: e.target.value });
    if (e.target.value == 3 || e.target.value == 4) {
      setMonoCompany(true);
      setMultiSelectedBranchEmployee(false);
    } else if (e.target.value == 5 || e.target.value == 6) {
      setMonoCompany(true);
      setMultiSelectedBranchEmployee(true);
    }
  };
  const handleCompanyChangeMono = (e) => {
    setValues({ ...values, id_company: e.target.value });
    dispatch(fetchCompanyBranch(e.target.value));
  };
  const handleCityCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const companyId = parseInt(value); // Convert the value to a number
    if (checked) {
      setCheckedCompany((prevCheckedCities) => [
        ...prevCheckedCities,
        companyId,
      ]);
    } else {
      setCheckedCompany((prevCheckedCities) =>
        prevCheckedCities.filter((company) => company !== companyId)
      );
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      {/* <Grid container> */}
      <Grid item xs={12}>
        <Controls.Input
          className="tesr"
          name="first_name"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/profile-circle.svg"></img>
                </IconButton>
                <span className="placeholdertitle">الاسم الاول</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.first_name}
          onChange={handleInputChange}
          error={errors.first_name}
        />

        <Controls.Input
          className="tesr"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/lock.svg"></img>
                </IconButton>
                <span className="placeholdertitle">الاسم الاخير</span>
              </InputAdornment>
            </Fragment>
          }
          name="last_name"
          value={values.last_name}
          onChange={handleInputChange}
          error={errors.last_name}
        />
        <Controls.Input
          className="tesr"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/lock.svg"></img>
                </IconButton>
                <span className="placeholdertitle">البريد الالكتروني</span>
              </InputAdornment>
            </Fragment>
          }
          name="email"
          value={values.email}
          onChange={handleInputChange}
          error={errors.email}
        />
        <Controls.Input
          className="tesr"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/lock.svg"></img>
                </IconButton>
                <span className="placeholdertitle">كلمة المرور</span>
              </InputAdornment>
            </Fragment>
          }
          type="password"
          name="password"
          value={values.password}
          onChange={handleInputChange}
          error={errors.password}
        />
        <Controls.Input
          className="tesr"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/lock.svg"></img>
                </IconButton>
                <span className="placeholdertitle">الرقم الوطني</span>
              </InputAdornment>
            </Fragment>
          }
          name="national_id"
          value={values.national_id}
          onChange={handleInputChange}
          error={errors.national_id}
        />
        <Controls.Input
          className="tesr"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/lock.svg"></img>
                </IconButton>
                <span className="placeholdertitle">مكان الولادة</span>
              </InputAdornment>
            </Fragment>
          }
          name="birth_place"
          value={values.birth_place}
          onChange={handleInputChange}
          error={errors.birth_place}
        />
        <Controls.Input
          className="tesr"
        
          type="date"
          name="birth_date"
          value={values.birth_date}
          onChange={handleInputChange}
          error={errors.birth_date}
        />

        <Controls.Select
          name="gender"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/building-4.svg"></img>
                </IconButton>
                <span className="placeholdertitle">الجنس</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.gender || ""}
          onChange={handleInputChange}
          options={genderItems}
          error={errors.gender}
        />
        <Controls.Select
          name="set_role_id"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/briefcase.svg"></img>
                </IconButton>
                <span className="placeholdertitle">دور الموظف</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.set_role_id || ""}
          onChange={handleChangeRole}
          options={RolesAdmin || []}
          error={errors.set_role_id}
        />
        <Grid item xs={12}>
          <Controls.Input
            type="file"
            name="image"
            className="logo"
            label={
              <Fragment>
                <InputAdornment position="end">
                  <IconButton
                    className="iconplaceholder"
                    aria-label="upload image"
                    edge="end"
                  >
                    <img src="img/emp/profile-circle.svg" alt="Profile" />
                  </IconButton>
                  <span className="placeholdertitle">الصورة</span>
                </InputAdornment>
              </Fragment>
            }
            values={values.image}
            onChange={handleImageChange}
            error={errors.image}
            inputProps={{
              accept: "image/*",
            }}
          />
        </Grid>
        {loadingAdd ? (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="load loadingupdate"
              style={{ background: "#F9B917", borderRaduis: "10px" }}
            >
              <div v-if="loading" class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Controls.Button type="submit" text="إضافة" />
          </div>
        )}
      </Grid>
      {/* </Grid> */}
    </Form>
  );
}
