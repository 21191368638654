import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Controls from "../../../component/controls/Controls";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  deleteTicket,
  fetchAllTicket,
  updateTicketPrice,
} from "../../../redux/TickeType";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Delete } from "@mui/icons-material";
import Button from "../../../component/controls/Button";

export default function CardTickit({ id_company }) {
  const dispatch = useDispatch();
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [newpricee, setNewpricee] = useState({});
  const [reduction, setReduction] = useState({});
  const [showbotton, setShowBotton] = useState(false);
  const [showbotton2, setShowBotton2] = useState(false);
  const [updatedIndex, setUpdatedIndex] = useState(null);
  const [IdTicket, setIdTicket] = useState();
  const { user } = useSelector((state) => state.auth);

  const DeleteTicket =
  user != null &&
  user.PermissionsNames.length > 0 &&
  user.PermissionsNames.some((permission) => permission === "hard.delete.ticket");


  const UpdateTicket =
  user != null &&
  user.PermissionsNames.length > 0 &&
  user.PermissionsNames.some((permission) => permission === "update.ticket");
   // Permission as default
   const DeleteTicketDefult =
   user != null &&
   user.rolePermissionsNames.length > 0 &&
   user.rolePermissionsNames.some((permission) => permission === "hard.delete.ticket");


   // Permission as default
   const UpdateTicketTicketDefult =
   user != null &&
   user.rolePermissionsNames.length > 0 &&
   user.rolePermissionsNames.some((permission) => permission === "update.ticket");
  const handleToggleExpand = (index,id) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    setIdTicket(id)
  };

  const { Tickets } = useSelector((state) => state.Ticket);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchAllTicket(id_company));
        const updatedTickets = response.payload;

        const initialPrices = {};
        updatedTickets.forEach((ticket) => {
          if (ticket.TicketType.length > 0) {
            ticket.TicketType.forEach((type) => {
              initialPrices[type.id] = type.price;
            });
          }
        });

        const initialreduction = {};
        updatedTickets.forEach((ticket) => {
          if (ticket.TicketType.length > 0) {
            ticket.TicketType.forEach((type) => {
              initialreduction[type.id] = type.reduction;
            });
          }
        });

        setReduction(initialreduction);
        setNewpricee(initialPrices);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch, id_company]);

  const handelSubmitPrice = (ticketId, price, reduction) => {
    const dataToSend = {
      id_trip_type: "",
      price: {
        [ticketId]: {
          id: ticketId,
          price: price,
          reduction: reduction,
        },
      },
    };
    dispatch(updateTicketPrice(dataToSend));
    setShowBotton(false);
    setShowBotton2(true);
  };
  const handleDeleteTicket = (ticketId) => {
    dispatch(deleteTicket(ticketId));
  };

  const [tripTypesInfo, setTripTypesInfo] = useState({});

// Initialize the state with data from the API
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await dispatch(fetchAllTicket(id_company));
      const updatedTickets = response.payload;
   
      let allTicketTypes = [];
      updatedTickets.forEach(ticket => {
        // Push each ticket type data into the allTicketTypes array
        ticket.TicketType.forEach(type => {
          allTicketTypes.push({
            ticketId: ticket.id,
            typeId: type.id,
            id: type.id_trip_type,
            price: type.price,
            reduction: type.reduction
          });
        });
      });
      setTripTypesInfo(allTicketTypes);

      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  fetchData();
}, [dispatch, id_company])

const handlePriceChange = (id, newPrice) => {
  const updated = tripTypesInfo.map(type => {
    if (type.typeId === id) {
      return { ...type, price: newPrice };
    }
    return type;
  });
  setTripTypesInfo(updated);
};

const handleReductionChange = (id, newReduction) => {
  const updated = tripTypesInfo.map(type => {
    if (type.typeId === id) {
      return { ...type, reduction: newReduction };
    }
    return type;
  });
  setTripTypesInfo(updated);
};
  const handleSubmitAll = () => {
    const filtered = tripTypesInfo.filter(type => type.ticketId === IdTicket);
    const data = [];

    filtered.forEach(type => {
      data.push({
        id: type.id, // Use typeId if you want to keep consistent with your data structure.
        price: Number(type.price),
        reduction: Number(type.reduction)
      });
    });
    const data2 = {
      id_ticket: IdTicket,
      data: data
    }
    dispatch(updateTicketPrice(data2));
  };
  return (
    <>
      <Grid item lg={12} md={12} sm={12} className="box-all-permission">
        {Tickets.map((ticket, index) => (
          <Grid
            lg={12}
            md={4}
            sm={12}
            key={index}
            className="box-catergory-permistion1"
            style={{
              height: expandedIndex === index ? "auto" : "40px",
              padding: "10px !impotrant",
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.56) !important",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {expandedIndex === index ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={() => handleToggleExpand(index,ticket.id)}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        نوع التذكرة : {ticket.title}
                        <ExpandMoreIcon />
                      </div>
                      {(DeleteTicket === true || DeleteTicketDefult === true) && (ticket.title === "طفل" || ticket.title === "عادي" )? (
                        <></>
                      ) : (DeleteTicket === true || DeleteTicketDefult === true) && !(ticket.title === "طفل" || ticket.title === "عادي" )?(
                        <div className="deletticket">
                          <Delete
                            onClick={() => handleDeleteTicket(ticket.id)}
                          />
                        </div>
                      ):(<></>)
                      }
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={() => handleToggleExpand(index,ticket.id)}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        نوع التذكرة : {ticket.title}
                        <ArrowBackIosNewIcon />
                      </div>
                      {(DeleteTicket === true || DeleteTicketDefult === true) && (ticket.title === "طفل" || ticket.title === "عادي" )? (
                        <></>
                      ) : (DeleteTicket === true || DeleteTicketDefult === true) && !(ticket.title === "طفل" || ticket.title === "عادي" )?(
                        <div className="deletticket">
                          <Delete
                            onClick={() => handleDeleteTicket(ticket.id)}
                          />
                        </div>
                      ):(<></>)}

                    </div>
                  </div>
                </>
              )}
            </div>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              style={{ display: expandedIndex === index ? "block" : "none" }}
            >
              {ticket.TicketType.map((tripType, innerIndex) => (
                <>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    key={innerIndex}
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "baseline",
                    }}
                  >
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={3}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      مسار الرحلة : {tripType.path_from} - {tripType.path_to}
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                      <MenuItem className="li-permission">
                        نوع الرحلة: {tripType.type}
                      </MenuItem>
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                      }}
                    >
                      <div style={{ marginLeft: "2%" }}>السعر </div>{" "}
                      <Controls.Input
                        className="tesr ticketrip"
                        name={`TicketType.${ticket.id}.${tripType.id}.price`}
                        value={newpricee[tripType.id]}
                        disabled={(UpdateTicketTicketDefult === true || UpdateTicket === true) ? false:true}
                        onChange={(event) => {
                          handlePriceChange(
                         
                            tripType.id,
                            event.target.value,
                          
                          );
                          setNewpricee((prevState) => ({
                            ...prevState,
                            [tripType.id]: event.target.value,
                          }));
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                      }}
                    >
                      <div style={{ marginLeft: "2%" }}>القطع </div>
                      <Controls.Input
                        className="tesr ticketrip"
                        name={`TicketType.${ticket.id}.${tripType.id}.reduction`}
                        value={reduction[tripType.id]}
                        disabled={(UpdateTicketTicketDefult === true || UpdateTicket === true) ? false:true}

                        onChange={(event) => {
                          handleReductionChange(
                            tripType.id,
                            event.target.value,
                          );
                          setReduction((prevState) => ({
                            ...prevState,
                            [tripType.id]: event.target.value,
                          }));
                        }}
                      />
                    </Grid>
                    <Grid
                      lg={1}
                      md={1}
                      key={innerIndex}
                      style={{ marginRight: "10px", marginTop: "10px" }}
                    >
                      {expandedIndex === index &&
                        innerIndex === updatedIndex &&
                        (showbotton === true ? (
                          <SaveAsIcon
                            onClick={() =>
                              handelSubmitPrice(
                                tripType.id,
                                newpricee[tripType.id],
                                reduction[tripType.id]
                              )
                            }
                          />
                        ) : showbotton2 === true ? (
                          <CheckIcon />
                        ) : (
                          <></>
                        ))}
                    </Grid>
                  </Grid>
                  <hr></hr>
                </>
              ))}
              {(UpdateTicketTicketDefult === true || UpdateTicket === true)?(
                <Grid onClick={handleSubmitAll} style={{display:'flex', justifyContent:'center',color:'white'}}>
                        <div style={{display:'flex', justifyContent:'center', background:'#f9b917',width:'10%'}}>
                        حفظ التغييرات
                        </div>
                        </Grid>
              ):(
                <></>
              )}
                  
            </Grid>
          </Grid>
        ))}
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      ></div>
    </>
  );
}
