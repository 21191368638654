import React, { useState, useCallback, Fragment } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Controls from "../../../component/controls/Controls";
import { useSelector, useDispatch } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import {
  setRows,
  setColumns,
  setSubmitted,
  setNumberSeats,
  setSelectedType,
} from "../../../redux/SeatsSlice";
import { useRef } from "react";
import MatrixRedux from "./MatrixRedux";
import { useForm } from "react-hook-form"; 
import * as Yup from "yup";
import { useEffect } from "react";

const useStyles = makeStyles({
  gridDivide11: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "6px",
    flexWrap: "wrap",
    padding: "20px 25px",
    background: "#fff",
    width: "91%",
    borderRadius: "10px",
    boxShadow: "0 2px 6px rgba(0,0,0,0.16)",
  },
  "@media (min-width: 768px) and (max-width:1556px)": {
    gridDivide11: {
      justifyContent: "space-around",
      alignItems: "center",
      gap: "0px",
    },
  },
  mt: {
    marginTop: "30px",
  },

  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "97%",
    flexDirection: "row-reverse",
    paddingRight: "8px",
    height: "45px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
    marginRight: "3px ",
  },
  searchDesign1: {},
  "@media (min-width: 600px) and (max-width:1280px)": {
    searchDesign: {
      marginBottom: "15px",
    },
  },
  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  contentDivide: {
    width: "100%",
    marginBottom: "40px",
  },
  Marginbox1: {
    marginBottom: "20px !important",
    maxWidth: "100% !important",
    flexBasis: "100%",
  },
});

const AddBusType = ({ mylist, Bus, id_company, setEbanle, enable }) => {
  const classes = useStyles();
  const divRef = useRef(null);
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.createBus.rows);
  const columns = useSelector((state) => state.createBus.columns);
  const numberSeats = useSelector((state) => state.createBus.numberSeats);
  const submitted = useSelector((state) => state.createBus.submitted);
  const selectedType = useSelector((state) => state.createBus.selectedType);
  const cellValues1 = useSelector((state) => state.createBus.cellValues);
  // eslint-disable-next-line no-unused-vars
  const [cellValues11, setCellValues1] = useState([]);
  const [error, setErrors] = useState({});

  const handleRowsChange = useCallback(
    (e) => {
      dispatch(setRows(e.target.value));
    },
    [dispatch]
  );
  const handleColumnsChange = useCallback(
    (e) => {
      dispatch(setColumns(e.target.value));
    },
    [dispatch]
  );
  const handleNumberSeats = useCallback(
    (e) => {
      dispatch(setNumberSeats(e.target.value));
    },
    [dispatch]
  );
  const handleType = useCallback(
    (e) => {
      dispatch(setSelectedType(e.target.value));
    },
    [dispatch]
  );
  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      rows: "",
      columns: "",
      number_seats: "",
      type: "",
    },
  });

  const handelReset = () => {
    reset(); 
    dispatch(setRows(0));
    dispatch(setColumns(0));
    dispatch(setNumberSeats(0));
    dispatch(setSubmitted(false)); 
  }; 
  useEffect(() => {
    handelReset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = (evt) => {
    const userSchema = Yup.object().shape({
      numberSeats: Yup.number()
        .required("عدد المقاعد مطلوب")
        .positive("عدد المقاعد يجب أن يكون إيجابي")
        .integer("عدد المقاعد يجب أن يكون عدداً صحيحاً")
        .min(1, "عدد المقاعد يجب أن يكون على الأقل 1"),
      rows: Yup.number()
        .required("عدد الصفوف مطلوب")
        .positive("عدد الصفوف يجب أن يكون إيجابي")
        .integer("عدد الصفوف يجب أن يكون عدداً صحيحاً")
        .min(1, "عدد الصفوف يجب أن يكون على الأقل 1"),
      columns: Yup.number()
        .required("عدد مقاعد الصف الأخير مطلوب")
        .positive("عدد مقاعد الصف الأخير يجب أن يكون إيجابي")
        .integer("عدد مقاعد الصف الأخير يجب أن يكون عدداً صحيحاً")
        .min(1, "عدد مقاعد الصف الأخير يجب أن يكون على الأقل 1"),
      type: Yup.string().required("نوع الباص مطلوب"),
    });
    try {
      userSchema.validateSync(
        {
          rows,
          columns,
          numberSeats,
          type: selectedType, 
        },
        {
          abortEarly: false,
        }
      );
      setErrors([]);
      dispatch(setSubmitted(true));
    } catch (err) {
      const { inner } = err;
      let formErrors = {};
      if (inner && inner[0]) {
        inner.forEach((error) => {
          const { path, message } = error;

          if (!formErrors[path]) {
            formErrors[path] = message;
          }
        });
      }

      setErrors(formErrors);
    }
  };

 

  return (
    <Grid className={classes.gridContainer} item lg={12}>
      <Grid className={classes.gridDivide11}>
        <Grid item xs={12} lg={12}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "baseline",
            }}
          >
            <p className="title-bus-type">إنشاء نوع باص</p>
          </Grid>
          <Grid className="box-notic">
            <p>1- قم بإدخال عدد الصفوف وعدد مقاعد الصف الأخير</p>
            <p>2- قم بإدخال عدد المقاعد ضمن الباص ضمن الجدول</p>
            <p>3- قم بإهمال الحقول التي لا تتضمن مقعداً</p>
            <p>4- إضغط على إضافة ليتم إضافة نوع باص جديد</p>
          </Grid>
        </Grid>
        <div style={{ width: "100%" }}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            <Grid
              item
              xs={12}
              lg={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                flexWrap: "wrap",
              }}
            >
              <Grid item xs={12} lg={2} md={3} sm={3}>
                <Controls.Input
                  style={{ width: "100%" }}
                  className="tesr"
                  name="rows"
                  label={
                    <Fragment>
                      <InputAdornment position="end">
                        <IconButton
                          className="iconplaceholder"
                          aria-label="toggle password visibility"
                          edge="end"
                        ></IconButton>
                        <span className="placeholdertitle">عدد الصفوف</span>
                      </InputAdornment>
                    </Fragment>
                  }
                  type="number"
                  value={rows}
                  onChange={handleRowsChange}
                />
                {error.rows && (
                  <p
                    className="error-message"
                    style={{ color: "red ", fontSize: "13px" }}
                  >
                    {error.rows}
                  </p>
                )}
              </Grid>
              <Grid item xs={12} lg={2} md={3} sm={3}>
                <Controls.Input
                  style={{ width: "100%" }}
                  className="tesr"
                  name="columns"
                  label={
                    <Fragment>
                      <InputAdornment position="end">
                        <IconButton
                          className="iconplaceholder"
                          aria-label="toggle password visibility"
                          edge="end"
                        ></IconButton>
                        <span className="placeholdertitle">
                          عدد مقاعد الصف الاخير
                        </span>
                      </InputAdornment>
                    </Fragment>
                  }
                  type="number"
                  value={columns}
                  onChange={handleColumnsChange}
                />
                {error.columns && (
                  <p
                    className="error-message"
                    style={{ color: "red ", fontSize: "13px" }}
                  >
                    {error.columns}
                  </p>
                )}
              </Grid>
              <Grid item xs={12} lg={2} md={3} sm={3}>
                <Controls.Input
                  style={{ width: "100%" }}
                  className="tesr"
                  name="numberSeats"
                  label={
                    <Fragment>
                      <InputAdornment position="end">
                        <IconButton
                          className="iconplaceholder"
                          aria-label="toggle password visibility"
                          edge="end"
                        ></IconButton>
                        <span className="placeholdertitle">عدد مقاعد </span>
                      </InputAdornment>
                    </Fragment>
                  }
                  type="number"
                  value={numberSeats}
                  onChange={handleNumberSeats}
                />

                {error.numberSeats && (
                  <p
                    className="error-message"
                    style={{ color: "red ", fontSize: "13px" }}
                  >
                    {error.numberSeats}
                  </p>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
                md={3}
                sm={12}
                className="div-type-seat"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <FormControl
                  error={errors.type} // Set error prop based on touched and errors
                  style={{
                    display: "flex !important",
                    flexDirection: "row-reverse !important",
                    height: "100% !important",
                    marginBottom: "0px !important",
                  }}
                >
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="type"
                    values={selectedType}
                    onChange={handleType}
                  >
                    <FormControlLabel
                      value="vip"
                      control={
                        <Radio
                          sx={{
                            color: "#F9B917",
                            "&.Mui-checked": {
                              color: "#F9B917",
                            },
                          }}
                        />
                      }
                      label="رجال اعمال"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="commercial"
                      control={
                        <Radio
                          sx={{
                            color: "#F9B917",
                            "&.Mui-checked": {
                              color: "#F9B917",
                            },
                          }}
                        />
                      }
                      label="عادي"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                  <FormLabel
                    id="demo-form-control-label-placement"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#F9B917",
                    }}
                  >
                    نوع الباص :
                  </FormLabel>

                  {error.type && (
                    <p
                      className="error-message"
                      style={{
                        color: "red",
                        fontSize: "13px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "baseline",
                        position: "absolute",
                        top: "45%",
                        left: "70%",
                      }}
                    >
                      {error.type}
                    </p>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} className="Addtypeseat">
              <Grid
                item
                xs={12}
                lg={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="bottonFullwidth22"
              >
                <Controls.Button type="submit" text="إنشاء مقاعد" />
              </Grid>
            </Grid>
          </form>
          {submitted && (
            <MatrixRedux
              rows={rows}
              columns={columns}
              numberSeats={numberSeats}
              setCellValues1={setCellValues1} // Pass the function as a prop
              cellValues1={cellValues1}
              divRef={divRef}
              id_company={id_company}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};
export default AddBusType;
