import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";
import { navigate, useNavigate } from "react-router-dom";
import { fetchTripBYId } from "./tripsSlice";
export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchTripPassengers = createAsyncThunk(
  "Passenger/fetchTripPassengers",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {

      const res = await axios.get(`${BaseUrl}/PasssengersForTrip/${id}`, {
        withCredentials: true,
      });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const insertPassengerToReservation = createAsyncThunk(
  "Passenger/insertPassengerToReservation",
  async (values, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/addPassengers`, values, {
        withCredentials: true,
      });
      // Create the SweetAlert pop-up with customClass
      sweetalert({
        text: "تم الإضافة بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
        focusConfirm: false, // Do not auto-focus
        customClass: {
          confirmButton: "custom-button-class", // Add the custom class to the confirm button
        },
      }).then(() => {
        dispatch(fetchTripBYId(values.tripid));
        localStorage.setItem('openpassenger', 'false');
        localStorage.removeItem("passengers");
        localStorage.removeItem("childrens");
      });

      // Use setTimeout to manually set focus on the button after a delay

    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateTPassengerToReservation = createAsyncThunk(

  "Passenger/updateTPassengerToReservation",
  async (values, thunkAPI) => {

    const { dispatch, rejectWithValue, getState } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/updatePassenger/${values.id}`, values, {
        withCredentials: true,
      }).then((res) => {
        if (res.status === 200) {

          sweetalert({
            text: res.data.message,
            icon: "success",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",

              },
            },
            className: "alert-div",
          }).then(() => {
            dispatch(fetchTripBYId(values.tripid));
            localStorage.setItem('openpassenger', 'false');
            localStorage.removeItem("passengers");
            localStorage.removeItem("childrens");
            localStorage.setItem("popup", false);

          });
        }

      })

    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const PassengerSlice = createSlice({
  name: "Passengers",
  initialState: {
    Passengers: [],
    PassengersID: [],
    PassengerInsert: [],
    UpdatePassenger: [],
    loading: false,
    statusupdatepassenger: false,
    error: null,
  },
  reducers: {
    clearPassengers: (state) => {
      state.Passengers = [];
      state.PassengersID = [];
      state.PassengerInsert = [];
      state.UpdatePassenger = [];
      state.loading = false;
      state.statusupdatepassenger = false;
      state.error = null;
    },
    setstatus(state, action) {
      state.statusupdatepassenger = action.payload;
    },
  },
  extraReducers: {
    //fetch
    [fetchTripPassengers.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchTripPassengers.fulfilled]: (state, action) => {
      state.PassengersID = action.payload;
      state.loading = false;
    },
    [fetchTripPassengers.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //insert Data
    [insertPassengerToReservation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [insertPassengerToReservation.fulfilled]: (state, action) => {
      state.PassengerInsert = action.payload;
      state.loading = false;
    },
    [insertPassengerToReservation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //Update  Passenger
    [updateTPassengerToReservation.pending]: (state, action) => {
      state.loading = true;
      state.statusupdatepassenger = true;
      state.error = null;
    },
    [updateTPassengerToReservation.fulfilled]: (state, action) => {
      state.Passengers.push(action.payload);
      state.loading = false;
      state.statusupdatepassenger = false;
    },
    [updateTPassengerToReservation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.statusupdatepassenger = false;
    },
  },
});
export const { clearPassengers, setstatus } = PassengerSlice.actions;
export default PassengerSlice.reducer;
