import React, { useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { fetchAllCity } from "../../redux/CitySlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { useState } from "react";
import Controls from "../../component/controls/Controls";
import {
  insertBranchEmployee,
  insertCityEmployee,
} from "../../redux/AddCityBranchEmployee";
function CheckboxReservation({ id_employee }) {
  const { loading, Cities, error } = useSelector((state) => state.City);
  const { CityEmployeeID, loadingAdd } = useSelector(
    (state) => state.CityEmployee
  );

  const [checkedCities, setCheckedCities] = useState([]); // State to manage checked cities
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllCity());
    if (CityEmployeeID && CityEmployeeID.id_city) {
      setCheckedCities(CityEmployeeID.id_city);
    }
  }, [dispatch, CityEmployeeID]);

  // Handle checkbox change event
  const handleCityCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const cityId = parseInt(value); // Convert the value to a number
    if (checked) {
      setCheckedCities((prevCheckedCities) => [...prevCheckedCities, cityId]);
    } else {
      setCheckedCities((prevCheckedCities) =>
        prevCheckedCities.filter((city) => city !== cityId)
      );
    }
  };
  const handleSave = () => {
    const data = {
      id_user: id_employee,
      id_city: checkedCities,
    };
    dispatch(insertCityEmployee(data));
  };
  return (
    <div className="checkreservation">
      <FormGroup>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row-reverse",
          }}
        >
          {Cities.map((city) => (
            <Grid item lg={4} md={4} sm={4} style={{ display: "flex" }}>
              <FormControlLabel
                key={city.id} // Provide a unique key for each dynamically generated FormControlLabel
                control={
                  <Checkbox
                    name="id_city"
                    value={city.id}
                    checked={checkedCities.includes(city.id)}
                    onChange={handleCityCheckboxChange}
                  />
                }
                label={city.name_ar}
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
      {loadingAdd ? (
        <div
          className="bottonFullwidth"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="load loadingupdate"
            style={{ background: "#F9B917", borderRaduis: "10px" }}
          >
            <div v-if="loading" class="spinner">
              <div class="rect1"></div>
              <div class="rect2"></div>
              <div class="rect3"></div>
              <div class="rect4"></div>
              <div class="rect5"></div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="bottonFullwidth"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Controls.Button
            onClick={() => handleSave()}
            type="submit"
            text="حفظ"
          />
        </div>
      )}
    </div>
  );
}

export default CheckboxReservation;
