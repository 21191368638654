import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Controls from "./controls/Controls";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmDialog from "./ConfirmDialog";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchTripBYId } from "../redux/tripsSlice";
import * as sweetalert from "sweetalert";
import Echo from "laravel-echo";
import socketIOClient from "socket.io-client";
import {
  deleteSeatToReservationnodata,
} from "../redux/reservation";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    position: "absolute",
    top: theme.spacing(5),
    width: "662px !important",
    maxWidth: "662px !important",
    height: "auto !important",
  },
  dialogTitle: {
    padding: "0px ",
  },
  headerDesgin: {
    textAlign: "center",
    color: "#F9B917",
    fontSize: "25px",
  },
  "@media (min-width: 768px) and (max-width:1556px)": {
    dialogWrapper: {
      height: "auto !important",
      top: "3% !important",
    },
  },
}));

export default function Popuppassenger2(props) {
  const dispatch = useDispatch();
  const {
    seatNumber,
    Trip,
    select,
    setSelected,
    idTransit,
    transet,
    direct,
    setDirect,
    onConfiarm,
    title,
    children,
    setstatus,
    setNewStatus,
    openPopup,
    setOpenPopup,
    setDisplayshortcut,
    setOpenopoup1,
    setResultArray,
    openopoup1,
    setUpdatePassenger,
    setSeatAndUser,
    settranset
  } = props;
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const close = () => {
    if (direct) {

      function findReservationDetailsBySeatNumber(seatNumbers, reservations) {
        const reservation = reservations.find((reservation) => {
          return reservation.reservation_seats_number.hasOwnProperty(
            seatNumber
          );
        });
        if (reservation) {
          const hasPassengers =
            reservation.passengers && reservation.passengers.length > 0;
          return {
            reservationId: reservation.id,
            hasPassengers: hasPassengers,
          };
        }

        return null;
      }
      // Usage:
      const reservations = Trip.reservations; // Assuming this is your array of reservations from the API response
      const seatNumbers = seatNumber; // Replace with the seat number you're checking
      const reservationDetails = findReservationDetailsBySeatNumber(
        seatNumbers,
        reservations
      );
      if (reservationDetails?.hasPassengers === false) {
        localStorage.setItem("hasPassengers", reservationDetails.hasPassengers);
        localStorage.setItem("reservationId", reservationDetails.reservationId);
        setConfirmDialog({
          isOpen: true,
          title: "أنت على وشك حذف هذه الحجز",
          img: "img/emp/Inbox cleanup-rafiki.svg",
          onConfirm: () => {
            const reservationId = localStorage.getItem("reservationId");
            handeldelete(reservationId, Trip.id_branch, Trip.id);
          },
        });
      } else {
        // Perform state updates and localStorage changes after successful deletion
        setDirect(true);
        settranset(false);
        setstatus(false);
        setResultArray([]);
        setSeatAndUser([]);
        setNewStatus(false);
        setOpenPopup(false);
        setUpdatePassenger(false);
        localStorage.setItem("openpassenger", "false");
        localStorage.setItem("changeseat", "false");
        localStorage.setItem("ReservationDeleted", "false");
        localStorage.setItem("ReservationPhone", "false");
        if (openopoup1 === true) {
          setOpenopoup1(false);
          localStorage.setItem("openpassenger", "false");
        }
        localStorage.setItem("displayshortcut", "0");
        setDisplayshortcut(0);
        localStorage.removeItem("passengers");
      }
    }
    if (transet) {
      function findReservationDetailsBySeatNumber(
        seatNumbertransit,
        tripTransits,
        idTransitt
      ) {
        // Find the specific trip transit by idTransit
        const specificTransit = tripTransits.find(
          (transit) => transit.id === idTransitt
        );


        if (!specificTransit) {
          return null;
        }
        // Now, search the reservation within this specific transit

        const reservation = specificTransit.reservation.find((reservation) => {
          return reservation.reservation_seats_number.hasOwnProperty(
            seatNumber
          );
        });

        if (reservation) {
          return {
            reservationId: reservation.id,
            // Assuming you want to check for the presence of passengers as well
            hasPassengers:
              reservation.passengers && reservation.passengers.length > 0,
          };
        } else {

          return null;
        }
      }

      // Usage:
      const tripTransits = Trip.tripTransit; // Assuming this is your array of tripTransits from the API response
      const seatNumbertransit = seatNumber; // The seat number you're checking
      const idTransitt = idTransit; // The specific transit ID you're interested in
      const reservationDetails = findReservationDetailsBySeatNumber(
        seatNumbertransit,
        tripTransits,
        idTransitt
      );

      if (reservationDetails) {


      } else {

      }

      if (reservationDetails && reservationDetails?.hasPassengers === false) {
        localStorage.setItem("hasPassengers", reservationDetails.hasPassengers);
        localStorage.setItem("reservationId", reservationDetails.reservationId);
        setConfirmDialog({
          isOpen: true,
          title: "أنت على وشك حذف هذه الحجز",
          img: "img/emp/Inbox cleanup-rafiki.svg",
          onConfirm: () => {
            const reservationId = localStorage.getItem("reservationId");
            handeldelete1(reservationId, Trip.id_branch, Trip.id);
          },
        });
      } else {
        // Perform state updates and localStorage changes after successful deletion
        setDirect(false);
        settranset(true);
        setstatus(false);
        setResultArray([]);
        setSeatAndUser([]);
        setNewStatus(false);
        setOpenPopup(false);
        setUpdatePassenger(false);
        localStorage.setItem("openpassenger", "false");
        localStorage.setItem("changeseat", "false");
        localStorage.setItem("ReservationDeleted", "false");
        localStorage.setItem("ReservationPhone", "false");
        if (openopoup1 === true) {
          setOpenopoup1(false);
          localStorage.setItem("openpassenger", "false");
        }
        localStorage.setItem("displayshortcut", "0");
        setDisplayshortcut(0);
        localStorage.removeItem("passengers");
      }
    }
    function handeldelete(reservationId, id_branch, id) {
      dispatch(deleteSeatToReservationnodata({ reservationId, id_branch, id }))
        .unwrap() // Unwrap the promise to handle success and errors
        .then(() => {
          // This block will run if the deletion was successful
          sweetalert({
            text: "تم الحذف بنجاح",
            icon: "success",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {


            // Perform state updates and localStorage changes after successful deletion
            dispatch(fetchTripBYId(id));
            setConfirmDialog({
              isOpen: false,
              title: "",
              img: "",
            });
            setDirect(true);
            settranset(false);
            setstatus(false);
            setResultArray([]);
            setSeatAndUser([]);
            setNewStatus(false);
            setOpenPopup(false);
            setUpdatePassenger(false);
            localStorage.setItem("openpassenger", "false");
            localStorage.setItem("changeseat", "false");
            localStorage.setItem("ReservationDeleted", "false");
            localStorage.setItem("ReservationPhone", "false");
            if (openopoup1 === true) {
              setOpenopoup1(false);
              localStorage.setItem("openpassenger", "false");
            }
            localStorage.setItem("displayshortcut", "0");
            setDisplayshortcut(0);
            localStorage.removeItem("passengers");
          });
        })
        .catch((error) => {
          // Handle any errors here
          console.error("Deletion failed:", error);
          // Optionally, dispatch an action to store the error in state or show an error message
        });
    }
    function handeldelete1(reservationId, id_branch, id) {
      dispatch(deleteSeatToReservationnodata({ reservationId, id_branch, id }))
        .unwrap() // Unwrap the promise to handle success and errors
        .then(() => {
          // This block will run if the deletion was successful
          sweetalert({
            text: "تم الحذف بنجاح",
            icon: "success",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {


            // Perform state updates and localStorage changes after successful deletion
            dispatch(fetchTripBYId(id));
            setConfirmDialog({
              isOpen: false,
              title: "",
              img: "",
            });
            setDirect(false);
            settranset(true);
            setstatus(false);
            setResultArray([]);
            setNewStatus(false);
            setOpenPopup(false);
            setUpdatePassenger(false);
            localStorage.setItem("openpassenger", "false");
            localStorage.setItem("changeseat", "false");
            localStorage.setItem("ReservationDeleted", "false");
            localStorage.setItem("ReservationPhone", "false");
            if (openopoup1 === true) {
              setOpenopoup1(false);
              localStorage.setItem("openpassenger", "false");
            }
            localStorage.setItem("displayshortcut", "0");
            setDisplayshortcut(0);
            localStorage.removeItem("passengers");
          });
        })
        .catch((error) => {
          // Handle any errors here
          console.error("Deletion failed:", error);
          // Optionally, dispatch an action to store the error in state or show an error message
        });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Delete") {
      setstatus(false);
      onConfiarm();
      setResultArray([]);
      setNewStatus(false);
      setOpenPopup(false);
      setUpdatePassenger(false);
      localStorage.setItem("openpassenger", false);
      localStorage.setItem("changeseat", false);
      if (openopoup1 === true) {
        setOpenopoup1(false);
        localStorage.setItem("openpassenger", false);
      }
      localStorage.setItem("displayshortcut", "0");
      setDisplayshortcut(0);
      localStorage.removeItem("passengers");
    }
  };


  useEffect(() => { }, [select]);

  return (
    <Dialog open={openPopup} classes={{ paper: classes.dialogWrapper }}>
      {(direct || transet) && (
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      )}
      <DialogTitle className={classes.dialogTitle}>
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="headerpoup"
        >
          <Typography
            variant="h6"
            className={classes.headerDesgin}
            component="div"
            style={{ flexGrow: 1 }}
          >
            {title}
          </Typography>
          <Controls.ActionButton color="secondary" onClick={() => close()}>
            <CloseIcon />
          </Controls.ActionButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
