import React, { useState, useEffect, useRef, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Popupaddtravel2 from "../../component/Popupaddtravel2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import companyTransportSlice from "../../redux/companyTransportSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchAllTicket } from "../../redux/TickeType";
import { useSelector } from "react-redux";
import { fetchTripTypeById } from "../../redux/tripTypesSlice";
import packageJson from "../../../package.json";
export const key_Shortcut = packageJson.key_Shortcut;

export default function PassengerAdultSwap(props) {
  const {
    isvalid,
    setIsValid,
    initialValues,
    adults,
    id_company,
    setactiveStep1,
    index,
    id_trip,
    id_trip_type,
  } = props;
  const [isOpen, setIsOpen] = useState(false); // State to control the select open state
  const [isOpen1, setIsOpen1] = useState(false); // State to control the select open state
  const [isOpen2, setIsOpen2] = useState(false); // State to control the select open state

  const firstNameInputRef = useRef(null);
  const inputRefs = [
    useRef(null), // Ref for first_name
    useRef(null), // Ref for last_name
    useRef(null), // Ref for national_id
    useRef(null), // Ref for birth_date
    useRef(null), // Ref for mother_name
    useRef(null), // Ref for middle_name
    useRef(null), // Ref for birth_place
    useRef(null), // Ref for gender
    useRef(null), // Ref for civil_status
  ];
  const handleKeyDown = (event, index) => {
    const noFieldFocused = document.activeElement.tagName !== "INPUT";
    if (event.key === "ArrowUp" && noFieldFocused) {
      inputRefs[0].current.focus();
    }
    if (event.key === "ArrowUp" && index === 1) {
      setIsOpen(true);
    } else if (event.key === "ArrowDown" && index === 7) {
      setIsOpen1(true);
    } else if (event.key === "ArrowDown" && index === 8) {
      setIsOpen2(true);
    } else if (event.key === "ArrowDown" && index === 9) {
      inputRefs[0].current.unfocus();
      // setIsOpen2(true);
    } else if (event.key === "ArrowDown" && index < inputRefs.length - 1) {
      event.preventDefault();
      inputRefs[index + 1].current.focus();
    } else if (
      event.key === "ArrowUp" &&
      index > 0 &&
      index !== 1 &&
      index !== 8
    ) {
      // If first item, loop to the last item
      event.preventDefault();
      inputRefs[index - 1].current.focus();
    }
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === key_Shortcut.Tab) {
        // Prevent the default tab behavior to avoid losing focus
        event.preventDefault();

        // Trigger a click event on the table element
        inputRefs[1].current.focus();
      }
      // Add more conditions for other keys if needed
    };

    // Attach the event listener to the document
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("NewSeat" in fieldValues)
      temp.NewSeat = fieldValues.NewSeat ? "" : "هذا الحقل مطلوب";
    if ("typeReduction" in fieldValues)
      temp.typeReduction = fieldValues.typeReduction ? "" : "هذا الحقل مطلوب";

    setErrors({
      ...temp,
    });
    // Check if any errors are present
    let isvalid = true;
    for (const key in temp) {
      if (temp[key] !== "") {
        isvalid = false; // Set isValid to false if any error exists
        break;
      }
    }

    setErrors({
      ...temp,
    });

    return isvalid; // Return the validation result
    //   if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const storedPassengers = JSON.parse(localStorage.getItem("passengers"));
  const { values, setValues, errors, setErrors, resetForm } =
    useForm(storedPassengers);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [passengers, setPassengers] = useState(
    storedPassengers 
  );
  const [focusinpu, setFocusInput] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTripTypeById(id_trip_type));
    
  }, [dispatch]);
  useEffect(() => {
    localStorage.setItem("passengers", JSON.stringify(passengers));
  }, [passengers]);

  const navigate = useNavigate();
  const { Tickets } = useSelector((state) => state.Ticket);
  const { TripTypeID } = useSelector((state) => state.TripType);
  const removeLocalStorageOnUnload = () => {
    if (navigate.location.pathname !== "StepPassenger") {
      localStorage.removeItem("passengers");
    }
  };
  ///////////////////////////////////////////////////////////
  const [updatedPassengers, setUpdatedPassengers] = useState([]);

  useEffect(() => {}, [passengers[index]?.reduction,TripTypeID]);

  // useEffect(() => {
  //   const calculatedPassengers = passengers.map((passenger) => {
  //     const priceDetail = TripTypeID?.price?.find(
  //       (price) => price.id === passenger.id_price_ticket
  //     );
  //     let finalPrice = parseFloat(passenger.price);
  //     const reductionValue = parseFloat(priceDetail.reduction);
  //     let reduction;

  //     if (reductionValue >= 0 && reductionValue <= 1) {
  //       reduction = finalPrice * reductionValue; // Percentage reduction
  //     } else {
  //       reduction = reductionValue; // Percentage reduction
  //     }

  //     return { ...passenger, reduction: reduction };
  //   });
  //   setUpdatedPassengers(calculatedPassengers);
  //   localStorage.setItem("passengers", JSON.stringify(calculatedPassengers));
  // }, [passengers, passengers[index]]);

  const calculatePrices = (passengers) => {
    return passengers.map((passenger) => {
      const priceInfo = TripTypeID.price.find(
        (price) => price.id === passenger.id_price_ticket
      );
      let finalPrice = parseFloat(priceInfo.price);

      // Apply reduction based on typeReduction
      if (passenger.typeReduction === "total") {
        finalPrice = 0; // Total reduction
      } else {
        const reduction = parseFloat(priceInfo.reduction);
        if (reduction >= 0 && reduction <= 1) {
          finalPrice *= reduction; // Percentage reduction
        }
      }

      return { ...passenger, finalPrice: finalPrice.toFixed(2) };
    });
  };
  ///////////////////////////////////////////////////////////
  useEffect(() => {
    window.addEventListener("beforeunload", removeLocalStorageOnUnload);
    dispatch(fetchAllTicket(id_company));
    return () => {
      window.removeEventListener("beforeunload", removeLocalStorageOnUnload);
    };
  }, []);
  useEffect(() => {
    // inputRefs[1].current.focus();
    window.addEventListener("beforeunload", handleKeyDown);
    return () => {
      window.removeEventListener("beforeunload", handleKeyDown);
    };
  }, []);

  // ... rest of your component code ...
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    const updatedPassengers = [...passengers];
    updatedPassengers[index] = {
      ...updatedPassengers[index],
      [name]: value,
    };
    setPassengers(updatedPassengers);
    const isFormValid = validate(updatedPassengers[index]); // Validate the updated passenger
    // setIsValid(isFormValid); // Update validation status
    setIsValid(
      isFormValid && passengers.every((passenger) => validate(passenger))
    );

    setValues((prevValues) => ({
      ...prevValues,
      updatedPassengers, // Update the adults array in values
    }));
  };

  useEffect(() => {
    // if(focusinpu === true) {
    if (firstNameInputRef.current) {
      firstNameInputRef.current.focus();
      inputRefs[1].current.focus();
    }
    if (selectedOption !== null) {
      inputRefs[1].current.focus();
    }
    // }
  }, [selectedOption]);
  useEffect(() => {
    if (isOpen === false) {
      inputRefs[1].current.focus();
    }
  }, [isOpen]);

  const parsedData = Tickets
    ? Tickets.map((ticket, index) => ticket.title)
    : [];
  useEffect(() => {
    setIsValid(validate(passengers[index]));
  }, [index, passengers]);

  return (
    <Form>
      <Grid
        item
        xs={12}
        md={12}
        sm={12}
        className="add-passenger"
        style={{
          fontSize: "25px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row-reverse",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <p> تبديل مقعد للمسافر رقم : {index + 1}</p>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <p style={{ fontSize: "15px" }}>رقم المقعد</p>
          <p
            style={{
              fontSize: "15px",
              padding: "10px",
              background: "#03314b",
              color: "white",
              borderRadius: "18px",
            }}
          >
            {passengers[index]?.number_seat}
          </p>
        </div>
      </Grid>
      <Grid item xs={12} md={12} className="travelsection">
        <Grid item xs={12} md={12} sm={12} className="travelsectiontwo">
          
          <Controls.Input
            className="tesr code_seat"
            name="NewSeat"
            //       inputRef={inputRefs[0]}
            inputRef={inputRefs[1]}
            onKeyDown={(e) => handleKeyDown(e, 1)}
            label={
              <Fragment>
                <InputAdornment position="end">
                  <span className="placeholdertitle">رقم المقعد الجديد</span>
                </InputAdornment>
              </Fragment>
            }
            value={passengers[index]?.NewSeat}
            // value={values.}
            onChange={(e) => handleInputChange(e, index)}
            // error={errors.first_name}
            error={errors[`NewSeat${index}`]}
          />
        </Grid>
      </Grid>
    </Form>
  );
}
