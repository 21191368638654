import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TypeTripForm from "./TypeTripForm";
import TypeTripPriceForm from "./TypeTripPriceForm";

const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
];

export default function StepTripType({ id_company }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [dataTriptype, setDataTripType] = React.useState([]);
  const totalSteps = () => {
    return steps.length;
  };
  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep1 = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep1() && !allStepsCompleted()
        ? 
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };



  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const handleSubmit = () => {
    const newActiveStep =
      isLastStep1() && !allStepsCompleted()
        ? 
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  return (
    <Box sx={{ width: "100%" }}>

      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === 0 ? (
              <TypeTripForm
                id_company={id_company}
                setActiveStep={setActiveStep}
                setDataTripType={setDataTripType}
                
              />
            ) : activeStep === 1 ? (
              <TypeTripPriceForm id_company={id_company} setActiveStep={setActiveStep} dataTriptype={dataTriptype}/>
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row-reverse",
                gap: "10px",
              }}
            >

            </div>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}
