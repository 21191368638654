import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchComplaints = createAsyncThunk(
  'Complaints/fetchComplaints',
  async (_, thunkAPI) => {
    const { rejectWithValue,getState  } = thunkAPI;
    try {
     
      const state = getState();
      const { user } = state.auth;
      const data ={
        branch :user.id_branch
      }
      const res = await axios.get(`${BaseUrl}/AllComplaints`,data, { withCredentials: true });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



const ComplaintsSlice = createSlice({
  name: 'Complaints',
  initialState: { Complaint: [], loading: false, error: null },
  reducers: {
    clearComplaints: (state) => {
      state.Complaint = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: {
    //fetch
    [fetchComplaints.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchComplaints.fulfilled]: (state, action) => {
      state.Complaint = action.payload;
      state.loading = false;
    },
    [fetchComplaints.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export const { clearComplaints } = ComplaintsSlice.actions;
export default ComplaintsSlice.reducer;
