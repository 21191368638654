import { useNavigate } from "react-router-dom";
import { tokens } from "../../styles/theme";
import React, { useEffect, useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { Grid } from "@material-ui/core";
import { Select, MenuItem } from "@material-ui/core";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  GridFooterContainer,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridPagination,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../../component/controls/Controls";
import PopupStop from "../../component/PopupStop";
import PathTripForm from "./PathTripForm";
import PathTripFormEdit from "./PathTripFormEdit";
import PopupCreate from "../../component/PopupCreate";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { deletePath, fetchPaths } from "../../redux/PathSlice";
import ConfirmDialog from "../../component/ConfirmDialogCompany";
import StopTripType from "./StopTripType";
import { fetchAllCity } from "../../redux/CitySlice";
import { fetchAccounting } from "../../redux/Accounting";
import { BaseUrl } from "../StepPassengerTrip/StepPassenger";
import WifiProtectedSetupIcon from "@mui/icons-material/WifiProtectedSetup";
import {
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
} from "@material-ui/core";

import axios from "axios";
import AddOrRemoveOduit from "./AddOrRemoveOduit";

export function CustomPagination() {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={useGridSelector(apiRef, gridPageSelector) + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}
function CustomFooter() {
  return (
    <GridFooterContainer>
      <CustomPagination />
      <GridPagination />
    </GridFooterContainer>
  );
}
function CustomFooter2() {
  return (
    <GridFooterContainer>
      <CustomPagination />
      <GridPagination />
    </GridFooterContainer>
  );
}
function CustomFooter3() {
  return (
    <GridFooterContainer>
      <CustomPagination />
      <GridPagination />
    </GridFooterContainer>
  );
}
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    flexDirection: "row-reverse",
    paddingRight: "8px",
    height: "45px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
    marginRight: "3px",
  },
  "@media (min-width: 600px) and (max-width:1280px)": {
    searchDesign: {
      marginBottom: "15px",
    },
  },
  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  editButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#000002 !important",
    fontSize: "12px",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  deleteButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#F9B917 !important",
    fontSize: "12px ",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  editImg: {
    width: "15px",
  },
  Marginbox1: {
    maxWidth: "100% !important",
    flexBasis: "100%",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: "100%",
    maxWidth: "100%",
  },
}));

export default function Accounting({ id_company, setShortcut }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const classes = useStyles();
  const [data, setData] = useState();
  const [rowCount, setRowCount] = useState();
  const [rowCount1, setRowCount1] = useState();
  const [rowCount2, setRowCount2] = useState();
  const [shohowtwotable2, setShohowtwotable2] = useState(false);
  const [Shohowtwotable3, setShohowtwotable3] = useState(true);
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [reservationDoneByUsers, setReservationDoneByUsers] = useState([]);
  const [reservationDoneByUsers1, setReservationDoneByUsers1] = useState([]);
  const [reservationDoneByUsers2, setReservationDoneByUsers2] = useState([]);
  const [originalData, setOriginalData] = useState([]); // Holds the unaltered data
  const [originalData1, setOriginalData1] = useState([]); // Holds the unaltered data
  const [originalData2, setOriginalData2] = useState([]); // Holds the unaltered data
  const [originalData3, setOriginalData3] = useState([]); // Holds the unaltered data

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${BaseUrl}/accountBySystemUserAuth/${id_company}`,
          {
            withCredentials: true,
          }
        );

     

        setData(res.data.data);
        setRowCount(res.data.data.length); // Set the total count based on received data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch, id_company]); // Ensure dependencies are correctly set

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const res1 = await axios.get(
          `${BaseUrl}/AccountingSystemUserViewRest/${id_company}`,
          {
            withCredentials: true,
          }
        );
        // Ensure each row has an 'id' property that is required by DataGridPro
   
        const namesSet = new Set(); // To keep track of names we've added
        const uniqueReservations = [];

        res1.data.data.forEach((item) => {
          const name_ar = item.system_user_name;
          if (!namesSet.has(name_ar)) {
            namesSet.add(name_ar);
            uniqueReservations.push({
              name_ar: name_ar,
              id: item.id,
            });
          }
        });
        const uniqueReservations1 = res1.data.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setOriginalData(res1.data.data); // Store original data
        setData1(uniqueReservations1);
        setRowCount1(res1.data.data.length);
        setReservationDoneByUsers(uniqueReservations);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData1();
  }, [dispatch, id_company]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res2 = await axios.get(`${BaseUrl}/getAudits/${id_company}`, {
          withCredentials: true,
        });

        const namesSet = new Set(); // To keep track of names we've added
        const namesSet2 = new Set(); // To keep track of names we've added
        const uniqueReservations = [];
        const uniqueReservations1 = [];

        res2.data.data.forEach((item) => {
          const name_ar = item.done_by_system_user;
          if (!namesSet.has(name_ar)) {
            namesSet.add(name_ar);
            uniqueReservations.push({
              name_ar: name_ar,
              id: item.id,
            });
          }
        });
        res2.data.data.forEach((item) => {
          const name_ar = item.audit_system_user_name;
          if (!namesSet2.has(name_ar)) {
            namesSet2.add(name_ar);
            uniqueReservations1.push({
              name_ar: name_ar,
              id: item.id,
            });
          }
        });
        setData2(res2.data.data);
        setOriginalData1(res2.data.data);
        setOriginalData2(res2.data.data);
        setRowCount2(res2.data.data.length);
        setReservationDoneByUsers1(uniqueReservations);
        setReservationDoneByUsers2(uniqueReservations1);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch, id_company]);

  // useEffect(() => {},[data,data1,data2,rowCount,rowCount1,rowCount2,originalData3])

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [Shohowtwotable, setShohowtwotable] = useState(false);
  const [openPopup1, setOpenPopup1] = useState(false);
  const [recordForEdit1, setRecordForEdit1] = useState(null);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [clickedEmployeeId, setClickedEmployeeId] = useState(null);
  const [selectedNameSystemUser, setSelectedNameSystemUser] = useState([]);
  const [selectedNameSystemUser1, setSelectedNameSystemUser1] = useState([]);
  const [selectedNameSystemUser2, setSelectedNameSystemUser2] = useState([]);
  const [columnWidth, setColumnWidth] = useState(100); // Default width

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 600) {
        setColumnWidth(80); // Smaller width for smaller screens
      } else {
        setColumnWidth(140); // Larger width for larger screens
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { Cities } = useSelector((state) => state.City);

  useEffect(() => {}, [
    data2,
    shohowtwotable2,
    Shohowtwotable,
    originalData1,
    originalData2,
  ]);

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow) => {};
  const getPageSize = () => {
    if (window.innerWidth < 992) {
      return 5; // Set a smaller pageSize for smaller screens
    } else if (window.innerWidth < 1566) {
      return 7; // Default pageSize for larger screens
    } else if (window.innerWidth < 1760) {
      return 8; // Default pageSize for larger screens
    } else if (window.innerWidth < 1930) {
      return 10; // Default pageSize for larger screens
    }
  };

  useEffect(() => {
    getPageSize();
  });

  const handleChange = (event) => {
    const value = event.target.value; // This should be an array of names

    // Manage "Select All" logic
    if (value[value.length - 1] === "all") {
      if (selectedNameSystemUser.length === reservationDoneByUsers.length) {
        setSelectedNameSystemUser([]);
      } else {
        setSelectedNameSystemUser(
          reservationDoneByUsers.map((user) => user.name_ar)
        );
      }
    } else {
      setSelectedNameSystemUser(value);
    }

    // Apply the filter
    if (value.includes("all")) {
      setData1(originalData); // Reset data if "Select All" or empty
    } else {
      const filtered = originalData.filter((item) =>
        value.includes(item.system_user_name)
      );
      setData1(filtered);
    }
  };
  // const handleChange2 = (event) => {
  //   const value = event.target.value; // This should be an array of names
  //   // Manage "Select All" logic
  //   if (value[value.length - 1] === 'all') {
  //     if (selectedNameSystemUser1.length === reservationDoneByUsers1.length) {
  //       setSelectedNameSystemUser1([]);
  //     } else {
  //       setSelectedNameSystemUser1(reservationDoneByUsers1.map((user) => user.name_ar));
  //     }
  //   } else {
  //     setSelectedNameSystemUser1(value);
  //   }

  //   // Apply the filter
  //   if (value.includes("all")) {
  //     setData2(originalData1); // Reset data if "Select All" or empty
  //   } else {
  //     const filtered = originalData1.filter(item => value.includes(item.done_by_system_user));
  //     setData2(filtered);

  //   }

  // };
  // const handleChange3 = (event) => {
  //   const value = event.target.value; // This should be an array of names
  //   // Manage "Select All" logic
  //   if (value[value.length - 1] === 'all') {
  //     if (selectedNameSystemUser2.length === reservationDoneByUsers2.length) {
  //       setSelectedNameSystemUser2([]);
  //     } else {
  //       setSelectedNameSystemUser2(reservationDoneByUsers1.map((user) => user.name_ar));
  //     }
  //   } else {
  //     setSelectedNameSystemUser2(value);
  //   }

  //   // Apply the filter
  //   if (value.includes("all")) {
  //     setData2(originalData2); // Reset data if "Select All" or empty
  //   } else {
  //     const filtered = originalData2.filter(item => value.includes(item.audit_system_user_name));
  //     setData2(filtered);

  //   }

  // };

  const handleChange2 = (event) => {
    const value = event.target.value; // This should be an array of names

    // Handling "Select All" logic
    if (value.includes("all")) {
      if (selectedNameSystemUser1.length === reservationDoneByUsers1.length) {
        setSelectedNameSystemUser1([]);
        setData2(originalData1); // Display all data when none selected
      } else {
        setSelectedNameSystemUser1(
          reservationDoneByUsers1.map((user) => user.name_ar)
        );
        setData2(originalData1); // Clear data when all selected
      }
    } else {
      setSelectedNameSystemUser1(value);
      const filtered = originalData1.filter((item) =>
        value.includes(item.done_by_system_user)
      );
      setData2(filtered);
      setOriginalData3(filtered);
    }
  };

  const handleChange3 = (event) => {
    const value = event.target.value; // This should be an array of names

    // Handling "Select All" logic
    if (value.includes("all")) {
      if (selectedNameSystemUser2.length === reservationDoneByUsers2.length) {
        setSelectedNameSystemUser2([]);
        setData2(originalData3); // Display all data when none selected
      } else {
        setSelectedNameSystemUser2(
          reservationDoneByUsers2.map((user) => user.name_ar)
        );
        setData2([]); // Clear data when all selected
      }
    } else if (value.length === 0) {
      setSelectedNameSystemUser2([]);
      setData2(originalData3);
    } else {
      setSelectedNameSystemUser2(value);
      const filtered = originalData3.filter((item) =>
        value.includes(item.audit_system_user_name)
      );
      setData2(filtered);
    }
  };
  function EditToolbar(props) {
    return (
      <Grid container xs={12} className={classes.Marginbox1}>
        {(user != null && user.roles_name === "مدير الشركة") ||
        (user != null && user.roles_name === "مدير الفرع") ? (
          <Grid
            lg={3}
            md={4}
            sm={3}
            className={classes.AddDesign}
            style={{ marginRight: "1%" }}
          >
            <div className="bottonFullwidth1">
              <Controls.Button
                type="submit"
                text="تشطيب"
                onClick={() => {
                  setShohowtwotable(true);
                  setShohowtwotable3(false);
                  setShohowtwotable2(false);
                  setData([]);

                  try {
                    const res = axios.get(
                      `${BaseUrl}/AccountingSystemUserViewRest/${id_company}`,
                      {
                        withCredentials: true,
                      }
                    );
                    // Ensure each row has an 'id' property that is required by DataGridPro
                    const dataWithIds2 = res.data.data.map((item, index) => ({
                      ...item,
                      // Use 'id_reservation' if available, otherwise generate a unique ID using the index
                      id: index + 1,
                    }));
                    const namesSet = new Set(); // To keep track of names we've added
                    const uniqueReservations = [];

                    res.data.data.forEach((item) => {
                      const name_ar = item.system_user_name;
                      if (!namesSet.has(name_ar)) {
                        namesSet.add(name_ar);
                        uniqueReservations.push({
                          name_ar: name_ar,
                          id: item.id_reservation,
                        });
                      }
                    });

                    setOriginalData(dataWithIds2); // Store original data
                    setData1(dataWithIds2);
                    setRowCount1(dataWithIds2.length);
                    setReservationDoneByUsers(uniqueReservations);
                  } catch (error) {
                    console.error("Error fetching data:", error);
                  }

                  // setOpenPopup(true);
                  setRecordForEdit(null);
                }}
              />
            </div>
          </Grid>
        ) : (
          <></>
        )}
        {/* ) : null} */}
        <PopupCreate
          title="إضافة مسار"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setDisplayshortcut={setShortcut}
        >
          <PathTripForm id_company={id_company} recordForEdit={recordForEdit} />
        </PopupCreate>
      </Grid>
    );
  }
  function EditToolbar1(props) {
    return (
      <Grid container xs={12} className={classes.Marginbox1}>
        <Grid
          item
          lg={4}
          md={4}
          sm={4}
          className="search-employee-multi-select"
        >
          <FormControl
            className={classes.formControl}
            style={{ direction: "rtl" }}
          >
            <InputLabel id="demo-mutiple-checkbox-label">اسم الموظف</InputLabel>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={selectedNameSystemUser}
              onChange={handleChange}
              // renderValue={(selected) => (
              //   // Map each selected ID to its corresponding city name

              //   selected.map(id => {
              //     const users = reservationDoneByUsers.find(user => user.id == id);
              //     return users ? users.name_ar : 'Unknown'; // Handles case where city might not be found
              //   }).join(', ')
              // )}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                    width: 250,
                  },
                },
              }}
            >
              <MenuItem value="all" style={{ direction: "rtl" }}>
                <Checkbox
                  checked={
                    selectedNameSystemUser?.length ===
                    reservationDoneByUsers?.length
                  }
                  style={{ direction: "ltr" }}
                />
                <ListItemText primary="اختيار الكل" />
              </MenuItem>
              {reservationDoneByUsers.map((user) => (
                <MenuItem
                  key={user.id}
                  value={user.name_ar}
                  style={{ direction: "rtl" }}
                >
                  <Checkbox
                    checked={selectedNameSystemUser.includes(user.name_ar)}
                    style={{ direction: "rtl" }}
                  />
                  <ListItemText primary={user.name_ar} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          lg={4}
          md={4}
          sm={3}
          className={classes.AddDesign}
          style={{ marginRight: "1%" }}
        >
          <div className="bottonFullwidth1">
            <Controls.Button
              type="submit"
              text="تاريخ التشطيب"
              onClick={() => {
                setShohowtwotable2(true);
                setShohowtwotable(false);
                setShohowtwotable3(false);
                setData([]);
                // setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          </div>
        </Grid>

        <PopupCreate
          title="إضافة مسار"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setDisplayshortcut={setShortcut}
        >
          <PathTripForm id_company={id_company} recordForEdit={recordForEdit} />
        </PopupCreate>
      </Grid>
    );
  }
  function EditToolbar2(props) {
    return (
      <Grid container xs={12} className={classes.Marginbox1}>
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          className="search-employee-multi-select"
        >
          <FormControl
            className={classes.formControl}
            style={{ direction: "rtl" }}
          >
            <InputLabel id="demo-mutiple-checkbox-label">اسم الموظف</InputLabel>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={selectedNameSystemUser1}
              onChange={handleChange2}
              // renderValue={(selected) => (
              //   // Map each selected ID to its corresponding city name

              //   selected.map(id => {
              //     const users = reservationDoneByUsers.find(user => user.id == id);
              //     return users ? users.name_ar : 'Unknown'; // Handles case where city might not be found
              //   }).join(', ')
              // )}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 223,
                    width: 250,
                  },
                },
              }}
            >
              <MenuItem value="all" style={{ direction: "rtl" }}>
                <Checkbox
                  checked={
                    selectedNameSystemUser1?.length ===
                    reservationDoneByUsers1?.length
                  }
                  style={{ direction: "ltr" }}
                />
                <ListItemText primary="اختيار الكل" />
              </MenuItem>
              {reservationDoneByUsers1.map((user) => (
                <MenuItem
                  key={user.id}
                  value={user.name_ar}
                  style={{ direction: "rtl" }}
                >
                  <Checkbox
                    checked={selectedNameSystemUser1.includes(user.name_ar)}
                    style={{ direction: "rtl" }}
                  />
                  <ListItemText primary={user.name_ar} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          className="search-employee-multi-select"
          style={{ marginRight: "1%" }}
        >
          <FormControl
            className={classes.formControl}
            style={{ direction: "rtl" }}
          >
            <InputLabel id="demo-mutiple-checkbox-label">اسم الموظف</InputLabel>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={selectedNameSystemUser2}
              onChange={handleChange3}
              // renderValue={(selected) => (
              //   // Map each selected ID to its corresponding city name

              //   selected.map(id => {
              //     const users = reservationDoneByUsers1.find(user => user.id == id);
              //     return users ? users.name_ar : 'Unknown'; // Handles case where city might not be found
              //   }).join(', ')
              // )}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                    width: 250,
                  },
                },
              }}
            >
              <MenuItem value="all" style={{ direction: "rtl" }}>
                <Checkbox
                  checked={
                    selectedNameSystemUser2?.length ===
                    reservationDoneByUsers2?.length
                  }
                  style={{ direction: "ltr" }}
                />
                <ListItemText primary="اختيار الكل" />
              </MenuItem>
              {reservationDoneByUsers2.map((user) => (
                <MenuItem
                  key={user.id}
                  value={user.name_ar}
                  style={{ direction: "rtl" }}
                >
                  <Checkbox
                    checked={selectedNameSystemUser2.includes(user.name_ar)}
                    style={{ direction: "rtl" }}
                  />
                  <ListItemText primary={user.name_ar} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          lg={3}
          md={3}
          sm={3}
          className={classes.AddDesign}
          style={{ marginRight: "1%" }}
        >
          <div className="bottonFullwidth1">
            <Controls.Button
              type="submit"
              text=" العودة لجدول التشطيب"
              onClick={() => {
                setShohowtwotable2(false);
                setShohowtwotable(true);
                setShohowtwotable3(false);
                setData([]);
                // setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          </div>
        </Grid>
        {/* ):(<></>)} */}
        {/* ) : null} */}
        <PopupCreate
          title="إضافة مسار"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setDisplayshortcut={setShortcut}
        >
          <PathTripForm id_company={id_company} recordForEdit={recordForEdit} />
        </PopupCreate>
      </Grid>
    );
  }

  const columns = [
    {
      field: "company",
      sortable: false,
      headerName: "الشركة",
      width: columnWidth, // Use state variable

      headerAlign: "center",
      align: "center",
    },
    {
      field: "reservationDoneByUser",
      sortable: false,
      headerName: "اسم الموظف",
      width: columnWidth, // Use state variable

      headerAlign: "center",
      align: "center",
    },
    {
      field: "id_reservation",
      sortable: false,
      headerName: "رقم الحجز",
      width: columnWidth, // Use state variable

      headerAlign: "center",
      align: "center",
    },
    {
      field: "id_payment",
      sortable: false,
      headerName: "رقم الدفع",
      width: columnWidth, // Use state variable

      headerAlign: "center",
      align: "center",
    },
    {
      field: "process_type",
      sortable: false,
      headerName: "نوع العملية",
      width: columnWidth, // Use state variable

      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.value === "add" ? "إضافة حجز" : "إلغاء حجز";
      },
    },
    {
      field: "total_amount",
      sortable: false,
      headerName: "حساب الحجز الكامل",
      width: columnWidth, // Use state variable

      headerAlign: "center",
      align: "center",
    },
    {
      field: "ticket_price",
      sortable: false,
      headerName: "سعر التذكرة",
      width: 80, // Use state variable

      headerAlign: "center",
      align: "center",
    },
    {
      field: "company_ticket_without_platform_profit",
      sortable: false,
      headerName: "تذكرة الشركة بدون ربح المنصة",
      width: 180, // Use state variable
      headerAlign: "center",
      align: "center",
    },
    {
      field: "profit_platform_from_company",
      sortable: false,
      headerName: "ربح المنصة من الشركة",
      width: columnWidth, // Use state variable

      headerAlign: "center",
      align: "center",
    },
    {
      field: "total_price_ticket_from_client_to_company",
      sortable: false,
      headerName: "سعر التذكرة الكلي من العميل للشركة",
      width: 200, // Use state variable

      headerAlign: "center",
      align: "center",
    },
    {
      field: "profit",
      sortable: false,
      headerName: "الربح",
      width: 80, // Use state variable

      headerAlign: "center",
      align: "center",
    },
    {
      field: "company_profit",
      sortable: false,
      headerName: "ربح الشركة ",
      width: 80, // Use state variable

      headerAlign: "center",
      align: "center",
    },
    {
      field: "platform_profit",
      sortable: false,
      headerName: "ربح المنصة ",
      width: 80, // Use state variable

      headerAlign: "center",
      align: "center",
    },
    {
      field: "platform_pay_to_company",
      sortable: false,
      headerName: "ربح الشركة من المنصة",
      width: columnWidth, // Use state variable

      headerAlign: "center",
      align: "center",
    },
  ];

  const columns1 = [
    {
      field: "system_user_name",
      sortable: false,
      headerName: "اسم الموظف",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "result",
      sortable: false,
      headerName: "الصندوق",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerAlign: "center",
      headerName: "العمليات",
      flex: 1,
      sortable: false,
      align: "center",
      renderCell: (params) => {
        return (
          <Box>
            <Button
              className={classes.editButton}
              onClick={() => {
                setOpenPopup1(true);
                setRecordForEdit1(null);
                setClickedEmployeeId(params.row.id_system_user);
              }}
            >
              <WifiProtectedSetupIcon />
              {/* تعديل */}
              {/* <img
                  src="img/edit.svg"
                  className={classes.editImg}
                  alt="delete icon"
                ></img> */}
            </Button>

            <PopupCreate
              title="تعديل الصندوق"
              openPopup={openPopup1}
              setOpenPopup={setOpenPopup1}
              setDisplayshortcut={setShortcut}
            >
              <AddOrRemoveOduit
                id_company={id_company}
                recordForEdit={recordForEdit}
                audit_id_system_user={clickedEmployeeId}
                setShohowtwotable={setShohowtwotable2}
              />
            </PopupCreate>
          </Box>
        );
      },
    },
  ];
  const columns2 = [
    {
      field: "done_by_system_user",
      sortable: false,
      headerName: " اسم الموظف الذي قام بالتشطيب",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "audit_system_user_name",
      sortable: false,
      headerName: "الموظف الذي تم تشطيب صندوقه",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "value",
      sortable: false,
      headerName: "قيمة التشطيب",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "before",
      sortable: false,
      headerName: "قبل التشطيب",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "after",
      sortable: false,
      headerName: "بعد التشطيب",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "rest",
      sortable: false,
      headerName: "rest ",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <Box>
      {Shohowtwotable && (
        <Box
          className="box-triptype table-accounting"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },

            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "none",
              fontSize: "15px !important",
            },
            "& .MuiDataGrid-cellContent": {
              fontSize: "15px !important",
              color: `${colors.primary[1000]} !important`,
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.white[100],
            },
          }}
        >
          <DataGridPro
            rows={data1 || []}
            columns={columns1}
            className="field-table"
            editMode="row"
            pageSize={15}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            rowCount={rowCount1}
            rowModesModel={rowModesModel}
            rowModel="server"
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            components={{
              Toolbar: EditToolbar1,
              Footer: CustomFooter3,
            }}
            // localeText={{
            //   footerRowSelected: CustomPagination,
            // }}
          />
        </Box>
      )}
      {shohowtwotable2 && (
        <Box
          className="box-triptype table-accounting"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },

            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "none",
              fontSize: "15px !important",
            },
            "& .MuiDataGrid-cellContent": {
              fontSize: "15px !important",
              color: `${colors.primary[1000]} !important`,
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.white[100],
            },
          }}
        >
          <DataGridPro
            rows={data2 || []}
            columns={columns2}
            className="field-table"
            editMode="row"
            pageSize={15}
            // rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            rowCount={rowCount2}
            rowModesModel={rowModesModel}
            rowModel="server"
            // onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            components={{
              Toolbar: EditToolbar2,
              Footer: CustomFooter2,
            }}
            // localeText={{
            //   footerRowSelected: CustomPagination,
            // }}
          />
        </Box>
      )}
      {Shohowtwotable3 && (
        <Box
          className="box-triptype table-accounting scroll-accounting"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },

            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "none",
              fontSize: "15px !important",
            },
            "& .MuiDataGrid-cellContent": {
              fontSize: "15px !important",
              color: `${colors.primary[1000]} !important`,
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.white[100],
            },
          }}
        >
          <DataGridPro
            rows={data || []}
            columns={columns}
            className="field-table"
            editMode="row"
            pageSize={15}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            rowCount={rowCount}
            rowModesModel={rowModesModel}
            rowModel="server"
            // onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            components={{
              Toolbar: EditToolbar,
              Footer: CustomFooter,
            }}
            // localeText={{
            //   footerRowSelected: CustomPagination,
            // }}
          />
        </Box>
      )}
    </Box>
  );
}
