import React, { useState, useEffect, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addPath } from "../../redux/PathSlice";
export default function PathTripForm(props) {
  const initialFValues = {
    id_company: props.id_company,
    id_city_from: "",
    id_city_to: "",
    type: "",
    pathStations: [],
  };
  const { loading } = useSelector((state) => state.Paths);
  const addPathStation = () => {
    const newPathStation = {
      name: "",
      order_path: values.pathStations.length + 1,
    };
    setValues((prevValues) => ({
      ...prevValues,
      pathStations: [...prevValues.pathStations, newPathStation],
    }));
  };
  const { Cities } = useSelector((state) => state.City);

  const dispatch = useDispatch();

  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("id_city_from" in fieldValues)
      temp.id_city_from = fieldValues.id_city_from ? "" : "هذا الحقل مطلوب";
    if ("id_city_to" in fieldValues)
      temp.id_city_to = fieldValues.id_city_to ? "" : "هذا الحقل مطلوب";
    if ("type" in fieldValues)
      temp.type = fieldValues.type ? "" : "هذا الحقل مطلوب";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const [selectedValue, setSelectedValue] = React.useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const TypeTrip = [
    { id: "vip", title: "رجال اعمال" },
    { id: "commercial", title: "عادي" },
  ];

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(addPath(values));
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);
  return (
    <Form onSubmit={handleSubmit}>
      <Grid className="createdTrip">
        <>
          <Grid item xs={12} md={12} sm={12}>
            <Controls.Select
              name="id_city_from"
              label={
                <Fragment>
                  <InputAdornment position="end">
                    <IconButton
                      className="iconplaceholder"
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <img src="img/create2.svg" alt="img"></img>
                    </IconButton>
                    <span className="placeholdertitle">الإنطلاق</span>
                  </InputAdornment>
                </Fragment>
              }
              value={values.id_city_from}
              onChange={handleInputChange}
              options={Cities}
              error={errors.id_city_from}
            />
            <Controls.Select
              name="id_city_to"
              label={
                <Fragment>
                  <InputAdornment position="end">
                    <IconButton
                      className="iconplaceholder"
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <img src="img/create3.svg" alt="img"></img>
                    </IconButton>
                    <span className="placeholdertitle">الوجهة</span>
                  </InputAdornment>
                </Fragment>
              }
              value={values.id_city_to}
              onChange={handleInputChange}
              options={Cities}
              error={errors.id_city_to}
            />
            <Controls.SelectTypeTrip
              name="type"
              label={
                <Fragment>
                  <InputAdornment position="end">
                    <IconButton
                      className="iconplaceholder"
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <img src="img/create3.svg" alt="img"></img>
                    </IconButton>
                    <span className="placeholdertitle">نوع الرحلة</span>
                  </InputAdornment>
                </Fragment>
              }
              value={values.type}
              onChange={handleInputChange}
              options={TypeTrip}
              error={errors.type}
            />

            {values.pathStations.map((pathStation, index) => (
              <Controls.Input
                className="tesr122"
                name="pathStations"
                key={index}
                label={
                  <Fragment>
                    <InputAdornment position="end">
                      <IconButton
                        className="iconplaceholder"
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <img src="img/create4.svg"></img>
                      </IconButton>
                      <span className="placeholdertitle">مكان التوقف </span>
                    </InputAdornment>
                  </Fragment>
                }
                value={pathStation.name}
                onChange={(e) => {
                  const updatedPathStations = [...values.pathStations];
                  updatedPathStations[index].name = e.target.value;
                  setValues((prevValues) => ({
                    ...prevValues,
                    pathStations: updatedPathStations,
                  }));
                }}
                error={errors.tripPrice}
              />
            ))}
          </Grid>
        </>
      </Grid>

      <div className="box-button-add-certifcation" onClick={addPathStation}>
        <img src="img/vuesax-linear-add-circle.svg" alt="" />
        <span className="title-add-certifcation">إضافة محطة توقف</span>
      </div>

      <Grid xs={12} md={12}>
        {loading ? (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="load loadingupdate"
              style={{ background: "#F9B917", borderRaduis: "10px" }}
            >
              <div v-if="loading" class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Controls.Button type="submit" text="إضافة" />
          </div>
        )}
      </Grid>
    </Form>
  );
}
