import React from "react";
// import Card from "./Card";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../component/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../../styles/theme";
import HeaderSets from "../../component/HeaderSets";
import SetRed from "./SetRed";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchSetsBus } from "../../redux/SetsBus";
import { useParams } from "react-router-dom";
import { useState } from "react";
const useStyles = makeStyles({
  gridContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexWrap: "wrap",
  },
  gridSets: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    flexDirection: "column",
    // marginBottom: "200px",
    // marginRight: "130px",
  },
  gridBody: {
    // marginBottom: "250px",
    // marginLeft: '10px',
  },
  gridHeader: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "3px solid #707070",
  },
  imgHeader: {
    width: "100%",
  },
  imgHeaderYellow: {
    width: "85px",
    // marginLeft:'50px'
  },
  imgHeaderYellow1: {
    width: "85px",
    visibility: "hidden",
  },
  imgHeaderBasic: {
    border: "1px solid #707070",
  },
  setsBody: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  setSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    // marginLeft: '100px',
  },
  setSection1: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
    // alignItems: "center",
    // marginLeft: '100px',
  },
  gridSetSHeaders: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ImgBus: {
    width: "85px",
    marginLeft: "20px",
    // marginRight: '20px',
  },
  ImgBus1: {
    width: "85px",
    marginLeft: "10px",
  },
  "@media (min-width: 768px) and (max-width:1366px)": {
    gridDivide: {
      justifyContent: "space-around",
      // alignItems: "center",
      gap: "0px",
    },
    gridSets: {
      flexDirection: "row",
      gap: "10px",
    }
  },
  "@media (min-width: 1280px) and (max-width:1920px)": {
    gridSets: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }
  },
});

const SetsBus = ({ mylist,numberpassenger,setNumberPassenger ,setShowPassenger,setOpenPopuppassenger ,openPopuppassenger,show}) => {

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { SetsBus } = useSelector((state) => state.Setsbus);
  const numCols = SetsBus.num_col;
  const seatsByCols = [];
  const trip_seat_status = (SetsBus.trip_seat)
  function countStatus3(seatStatus) {
    let count = 0;
    for (const seatNumber in seatStatus) {
      if (seatStatus[seatNumber] === 3) {
        count++;
      }
    }
    return count;
  }
  
  const numberOfStatus3Seats = countStatus3(trip_seat_status);


  // Organize the seats into columns
  if (SetsBus.seats_bus && numCols) {
    for (let i = 0; i < SetsBus.seats_bus.length; i += numCols) {
      const col = SetsBus.seats_bus.slice(i, i + numCols);
      seatsByCols.push(col);
    }
  }

  const selectedseats= seatsByCols.reduce((count, col) => {
    return (
      count +
      col.reduce((innerCount, seat) => {
        if (seat !== null) {
          const seatStatus = SetsBus.trip_seat[seat];
          const isAvailable3 = seatStatus === 3;
          if (isAvailable3) {
            return innerCount + 1;
          }
        }
        return innerCount;
      }, 0)
    );
  }, 0)
  const [selectedSeatsCount, setSelectedSeatsCount] = useState(numberOfStatus3Seats);
  useEffect(() => {
   
    dispatch(fetchSetsBus(102));
    setSelectedSeatsCount(selectedseats)

  }, [dispatch]);
  // useEffect(()=>{
  //   const elementWithTabIndexZero = document.querySelector('.MuiDataGrid-cell[tabindex="0"]');
  //   elementWithTabIndexZero.setAttribute('tabindex', '-1'); // Set tabindex to 0

  // },[show])
  return (
    <Grid className={classes.gridContainer}>
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridBody} style={{position:'relative'}}>
    
          {seatsByCols.map((col, index) => (
              <div key={index} className={classes.setSection1}>
                  {/* <div           key={index}>{col}</div> */}
                {col.map((seat, seatIndex) => {
                  if (seat !== null) {
                    const seatStatus = SetsBus.trip_seat[seat];
                    const isAvailable = seatStatus === 0;
                    const isAvailable1 = seatStatus === 1;
                    const isAvailable2 = seatStatus === 2;
                    const isAvailable3 = seatStatus === 3;
                    

                    let imgSrc = "";
                    if (isAvailable) {
                      imgSrc = "img/set1.svg";
                    } else if (isAvailable1) {
                      imgSrc = "img/set3.svg";
                    } else if (isAvailable2) {
                      imgSrc = "img/set2.svg";
                    } else if (isAvailable3) {
                      imgSrc = "img/set5.png";
                    }

                  
                  return (
                        <SetRed
                          setShowPassenger={setShowPassenger}
                          setNumberPassenger={setNumberPassenger}
                          numberpassenger={numberpassenger}
                          selectedSeatsCount={selectedSeatsCount}
                          setSelectedSeatsCount={setSelectedSeatsCount}
                          selectedseats={selectedseats}
                          numberOfStatus3Seats={numberOfStatus3Seats}
                          trip_seat_status={trip_seat_status}
                          key={seatIndex}
                          openPopuppassenger={openPopuppassenger}
                          setOpenPopuppassenger={setOpenPopuppassenger}
                          number={seat}
                          
                        />
                )
                  }
                           return <div style={{ width: "85px", height: "77px" }}></div>;

                         })}
              </div> 
               ))}
       
      </Grid>
    </Grid>
  );
};
export default SetsBus;
