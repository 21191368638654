import React, { useEffect, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import { fetchprofitByid, updateProfite } from "../../redux/profit";
function ProfitEdit(props) {
  const dispatch = useDispatch();
  const { Profitid, loadingUpdate, loading } = useSelector(
    (state) => state.Profits
  );
  const initialFValues = {
    id: props.id_company,
    profit_percent: "",
    platform_to_company: "",
    company_to_platform: "",
    platform_client_reduction: "",
    platform_system_reduction: "",
    max_profit: "",
  };
  useEffect(() => {
    if (Profitid) {
      setValues({
        id: props.id_company,
        profit_percent: Profitid.profit_percent,
        platform_to_company: Profitid.platform_to_company,
        company_to_platform: Profitid.company_to_platform,
        platform_client_reduction: Profitid.platform_client_reduction,
        platform_system_reduction: Profitid.platform_system_reduction,
        max_profit: Profitid.max_profit,
      });
    }
  }, [Profitid, props.id_company]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("profit_percent" in fieldValues)
      temp.profit_percent = fieldValues.profit_percent ? "" : "هذا الحقل مطلوب";
    if ("platform_to_company" in fieldValues)
      temp.platform_to_company = fieldValues.platform_to_company
        ? ""
        : "هذا الحقل مطلوب";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(updateProfite(values));
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {loading ? (
        <div className="spinner1-box">
          <svg class="spinner1" viewBox="0 0 50 50">
            <circle
              class="path1"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </div>
      ) : (
        <Grid className="createdTrip">
          <Grid item xs={12} md={12} sm={12}>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row-reverse",
              }}
            >
              <Controls.Input
                InputLabelProps={{ shrink: true }} // Add this line
                className="tesr122"
                name="profit_percent"
                label={
                  <Fragment>
                    <InputAdornment position="end">
                      <span className="placeholdertitle">نسبة الربح</span>
                    </InputAdornment>
                  </Fragment>
                }
                value={values.profit_percent}
                onChange={handleInputChange}
                error={errors.profit_percent}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row-reverse",
              }}
            >
              <Controls.Input
                InputLabelProps={{ shrink: true }} // Add this line
                className="tesr122"
                name="platform_to_company"
                label={
                  <Fragment>
                    <InputAdornment position="end">
                      <span className="placeholdertitle">
                        نسبة الربح من المنصة للشركة
                      </span>
                    </InputAdornment>
                  </Fragment>
                }
                value={values.platform_to_company}
                onChange={handleInputChange}
                error={errors.platform_to_company}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row-reverse",
              }}
            >
              <Controls.Input
                InputLabelProps={{ shrink: true }} // Add this line
                className="tesr122"
                name="company_to_platform"
                label={
                  <Fragment>
                    <InputAdornment position="end">
                      <span className="placeholdertitle">
                        نسبة الربح من الشركة للمنصة
                      </span>
                    </InputAdornment>
                  </Fragment>
                }
                value={values.company_to_platform}
                onChange={handleInputChange}
                error={errors.company_to_platform}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row-reverse",
              }}
            >
              <Controls.Input
                InputLabelProps={{ shrink: true }} // Add this line
                className="tesr122"
                name="platform_client_reduction"
                label={
                  <Fragment>
                    <InputAdornment position="end">
                      <span className="placeholdertitle">
                        نسب القطع عند إلغاء الحجز من قبل الزبون
                      </span>
                    </InputAdornment>
                  </Fragment>
                }
                value={values.platform_client_reduction}
                onChange={handleInputChange}
                error={errors.platform_client_reduction}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row-reverse",
              }}
            >
              <Controls.Input
                InputLabelProps={{ shrink: true }} // Add this line
                className="tesr122"
                name="platform_system_reduction"
                label={
                  <Fragment>
                    <InputAdornment position="end">
                      <span className="placeholdertitle">
                        نسب القطع عند إلغاء الحجز من قبل النظام
                      </span>
                    </InputAdornment>
                  </Fragment>
                }
                value={values.platform_system_reduction}
                onChange={handleInputChange}
                error={errors.platform_system_reduction}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid xs={12} md={12}>
        {loadingUpdate ? (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="load loadingupdate"
              style={{ background: "#F9B917", borderRaduis: "10px" }}
            >
              <div v-if="loading" class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Controls.Button type="submit" text="تعديل" />
          </div>
        )}
      </Grid>
    </Form>
  );
}
export default ProfitEdit;
