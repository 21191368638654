import React from "react";
import { Grid } from "@material-ui/core";
import packageJson from "../../../package.json";

export const key_Shortcut = packageJson.key_Shortcut;


export default function Shortcut(props) {
  return (
    <Grid>
      <Grid item xs={12} md={12} sm={12}  className="shortcut" style={{display:'flex', flexDirection:'column', justifyContent:'end', alignItems:'end', fontSize:'15px'}}>
       <div> <p>   إضافة حجز جديد  </p><p>{key_Shortcut.ctrl}+ {key_Shortcut.F1}</p></div>
       <div> <p>  تعديل  حجز  </p><p>{key_Shortcut.ctrl}+ {key_Shortcut[2]}</p></div>
       <div> <p>    حذف حجز  </p><p>{key_Shortcut.ctrl}+ {key_Shortcut.F3}</p></div>
       <div> <p> كرسي محجوز من قبل شركة  </p><p>{key_Shortcut.ctrl}+ {key_Shortcut.F4}</p></div>
       <div> <p>إضافة بيانات مسافر لكرسي محجوز بدون بيانات  </p><p>{key_Shortcut.ctrl}+ {key_Shortcut.F9}</p></div>
       <div> <p>تبديل كرسي مسافر</p><p>{key_Shortcut.ctrl}+ {key_Shortcut.F2}</p></div>
       <div> <p>حجز هاتفي</p><p>{key_Shortcut.ctrl}+ {key_Shortcut.F99}</p></div>
       <div> <p> تحويل الكرسي لمتوفر </p><p>{key_Shortcut.ctrl}+ {key_Shortcut.F0}</p></div>
       {/* <div> <p> إضافة بيانات لكرسي محجوز بدون بيانات  </p><p>{key_Shortcut.ctrl}+ {key_Shortcut.F3}</p></div> */}
       <div> <p>   تخصص مقاعد لرحلات العبور  </p><p>{key_Shortcut.ctrl}+ {key_Shortcut.F5}</p></div>
       <div> <p>    تخصص مقاعد للادارة  </p><p>{key_Shortcut.ctrl}+ {key_Shortcut.F6}</p></div>
       <div> <p>   تحويل مقاعد من عبور لرحلة اساسية   </p><p>{key_Shortcut.ctrl}+ {key_Shortcut.F7}</p></div>
       <div> <p>   حفظ الحجز  </p><p>{key_Shortcut.ctrl}+ {key_Shortcut.F8}</p></div>
       <div> <p>   معرفة الموظف الذي قام بحجز معين</p><p>{key_Shortcut.i}</p></div>
       <div> <p>الانتقال من حالة معرفة  موظفين الحجز للحجوزات الرحلة</p><p>{key_Shortcut.o}</p></div>

       <div> <p>  الانتقال للمسافر التالي ضمن صفحة إضافة مسافر </p><p>{key_Shortcut.next_passenger}</p></div>
       <div> <p>  الانتقال للمسافر السابق ضمن صفحة إضافة مسافر </p><p>{key_Shortcut.previous_passenger}</p></div>

       <div> <p>   إضافة رحلة  </p><p>{key_Shortcut.Add_form}</p></div>
       <div> <p>   قفل وفك قفل الانتقال لرحلات العبور </p><p>{key_Shortcut.replace_and_available_get_transit}</p></div>

       <div> <p> الانتقال لرحلة الاساسية</p><p>{key_Shortcut.get_direct_trip}</p></div>
       <div> <p>الانتقال لرحلة العبور الاولى</p><p>{key_Shortcut.get_transit_1}</p></div>
       <div> <p> الانتقال لرحلة العبور الثانية</p><p>{key_Shortcut.get_transit_2}</p></div>
       <div><p>الانتقال لجدول الرحلات</p><p>{key_Shortcut.focus_table}</p></div>
       <div><p> الانتقال لخانة رقم المقاعد</p><p>{key_Shortcut.focus_input_seats}</p></div>
       <div><p>إغلاق الصفحة</p><p>{key_Shortcut.close_form}</p></div>
       <div><p>إعادة تحديد الحقل لادخال البيانات في صفحة إضافة مسافر</p><p>{key_Shortcut.Tab}</p></div>
   
      </Grid>
    </Grid>
  );
}
