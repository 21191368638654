import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Grid } from "@material-ui/core";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
`;

const Ticket = styled.div`
  width: 70%;
  min-height: 300px;
  border: 1px solid #000;
  position: relative;
  background-color: #fff;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const Logo = styled.img`
  position: absolute;
  ${({ position }) => {
    switch (position) {
      case 'top center':
        return css`
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
        `;
      case 'top left':
        return css`
          top: 10px;
          left: 10px;
        `;
      case 'top right':
        return css`
          top: 10px;
          right: 10px;
        `;
      case 'bottom center':
        return css`
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
        `;
      case 'bottom left':
        return css`
          bottom: 10px;
          left: 10px;
        `;
      case 'bottom right':
        return css`
          bottom: 10px;
          right: 10px;
        `;
      case 'center left':
        return css`
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
        `;
      case 'center center':
        return css`
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `;
      case 'center right':
        return css`
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
        `;
      default:
        return '';
    }
  }}
  width: 100px;
  height: auto;
`;

const Input = styled.input`
  margin: 10px;
  width: ${({ width }) => width};
`;

const Field = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
`;

const Button = styled.button`
  margin: 10px;
  padding: 10px 20px;
  cursor: pointer;
`;

const FieldRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const TicketDesignForm = () => {
  const [logo, setLogo] = useState(null);
  const [logoPosition, setLogoPosition] = useState('top center');

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const [groups, setGroups] = useState([
    { id: 1, rows: [{ id: 1, inputs: [{ label: 'Label', value: '', size: 100 }] }] },
  ]);

  const addGroup = () => {
    const newGroup = {
      id: groups.length + 1,
      rows: [{ id: 1, inputs: [{ label: 'Label', value: '', size: 100 }] }],
    };
    setGroups([...groups, newGroup]);
  };
  const removeRow = (groupId, rowId) => {
    setGroups(groups.map(group =>
      group.id === groupId
        ? {
            ...group,
            rows: group.rows.filter(row => row.id !== rowId),
          }
        : group
    ));
  };


  const removeInput = (groupId, rowId, inputIndex) => {
    setGroups(groups.map(group =>
      group.id === groupId
        ? {
            ...group,
            rows: group.rows.map(row =>
              row.id === rowId
                ? { ...row, inputs: row.inputs.filter((_, index) => index !== inputIndex) }
                : row
            ),
          }
        : group
    ));
  };

  const addRow = (groupId) => {
    setGroups(groups.map(group =>
      group.id === groupId
        ? {
            ...group,
            rows: [...group.rows, { id: group.rows.length + 1, inputs: [{ label: 'Label', value: '', size: 100 }] }],
          }
        : group
    ));
  };

  const addInput = (groupId, rowId) => {
    setGroups(groups.map(group =>
      group.id === groupId
        ? {
            ...group,
            rows: group.rows.map(row =>
              row.id === rowId
                ? { ...row, inputs: [...row.inputs, { label: 'Label', value: '', size: 100 }] }
                : row
            ),
          }
        : group
    ));
  };

  const handleChange = (groupId, rowId, inputIndex, key, value) => {
    setGroups(groups.map(group =>
      group.id === groupId
        ? {
            ...group,
            rows: group.rows.map(row =>
              row.id === rowId
                ? {
                    ...row,
                    inputs: row.inputs.map((input, index) =>
                      index === inputIndex ? { ...input, [key]: value } : input
                    ),
                  }
                : row
            ),
          }
        : group
    ));
  };

  return (


<Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{display:'flex'}}
    
    >

<Grid
          item
          xs={6}
          sm={6}
          md={6}
    
    >
      <h1>تصميم تذكرة خاصة بالشركة</h1>
      {/* <Field> */}
        <label>
           شعار الشركة:
          <Input type="file" accept="image/*" onChange={handleLogoUpload} />
        </label>
        <label>
          مكان الشعار :
          <select value={logoPosition} onChange={(e) => setLogoPosition(e.target.value)}>
            <option value="top center"> اعلى منتصف</option>
            <option value="top left">اعلى اليسار </option>
            <option value="top right"> اعلى اليمين</option>
            <option value="bottom center"> اسفل المنتصف</option>
            <option value="bottom left"> اسفل اليسار</option>
            <option value="bottom right"> اسفل اليمين</option>
            <option value="center left"> منتصف اليسار</option>
            <option value="center center"> منتصف</option>
            <option value="center right"> منتصف اليمين</option>
          </select>
        </label>
      {/* </Field> */}
      {groups.map(group => (
          <div key={group.id} style={{ marginBottom: '20px' }}>
        
            {group.rows.map(row => (
              <div key={row.id} style={{ marginBottom: '10px' }}>
                {row.inputs.map((input, index) => (
                  <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
                    <input
                      type="text"
                      value={input.label}
                      onChange={(e) =>
                        handleChange(group.id, row.id, index, 'label', e.target.value)
                      }
                      placeholder="Label"
                      style={{ marginRight: '10px' }}
                    />
         
                    <input
                      type="number"
                      value={input.size}
                      onChange={(e) =>
                        handleChange(group.id, row.id, index, 'size', e.target.value)
                      }
                      placeholder="Size (%)"
                      style={{ width: '80px', marginRight: '10px' }}
                    />
                                        <button onClick={() => removeInput(group.id, row.id, index)}>Remove Input</button>

                  </div>
                ))}
                    <button onClick={() => addInput(group.id, row.id)}>إضافة حقل جديد </button>
            <button onClick={() => removeRow(group.id, row.id)}>Remove Row</button>
              </div>
            ))}
            <button onClick={() => addRow(group.id)}>إضافة سطر جديد </button>

          </div>
        ))}
        <button onClick={addGroup}></button>
      </Grid>
  
       
      
      
  
      <Grid
          item
          xs={6}
          sm={6}
          md={6}
    
    >
      <Ticket>
        {logo && <div style={{width:'100%', height:'100px',marginBottom:'10px'}}><Logo src={logo} position={logoPosition} /></div>}
     
     
        {groups.map(group => (
          <div key={group.id} style={{ marginBottom: '20px' }}>
          
            {group.rows.map(row => (
              <div key={row.id} style={{ marginBottom: '10px' }}>
                <div style={{ display: 'flex' }}>
                  {row.inputs.map((input, index) => (
                    <div key={index} style={{ width: `${input.size}%`, marginRight: '10px',display:'flex' }}>
                      <strong>{input.label}:</strong> 
                      <input
                      type="text"
                      value={input.value}
                      onChange={(e) =>
                        handleChange(group.id, row.id, index, 'value', e.target.value)
                      }
                      placeholder="Input"
                      style={{ marginRight: '10px',width:'100%' }}
                    />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          ))}
      </Ticket>
      </Grid>
    </Grid>
  );
};

export default TicketDesignForm;
