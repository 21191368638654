import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../../component/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompany } from "../../../redux/companyTransportSlice";
import { useLocation } from "react-router-dom";
import CardPermission from "./CardPermission";
import CardTickit from "./CardPermission";
const useStyles = makeStyles({
  gridDivide: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "23px",
    width: "95% !important",
  },
  "@media (min-width: 768px) and (max-width:1366px)": {
    gridDivide: {
      justifyContent: "space-around",
      gap: "0px",
    },
  },
});

const TiickitType = ({ id_company }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const user_id = location.state;
  useEffect(() => {
    dispatch(fetchCompany());
  }, [dispatch]);

  return (
    <Grid className={classes.gridContainer} item lg={12}>
      <Grid
        lg={12}
        md={12}
        sm={12}
        style={{ direction: "ltr" }}
        className={classes.AddDesign}
      ></Grid>
      <Grid
        container
        spacing={4}
        className={classes.gridContainer}
        justify="center"
      >
        <Grid className={classes.gridDivide} style={{ width: "100%" }}>
          <CardTickit id_company={id_company} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default TiickitType;
