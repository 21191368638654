import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const OfficeCompanyById = createAsyncThunk(
  'Office/OfficeCompanyById',
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
       const res =await axios.get(`${BaseUrl}/getBranch/${data}?branch=${data}`,{ withCredentials: true });
       const result = await res.data;
       return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const MyOffice = createAsyncThunk(
  'Office/MyOffice',
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
       const res =await axios.get(`${BaseUrl}/myBranches`,{ withCredentials: true });
       const result = await res.data.data;
       return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const Offitrashed = createAsyncThunk(
  'Office/Offitrashed',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
       const res =await axios.get(`${BaseUrl}/getAllTrashedBranchForCompany/${id}`,{ withCredentials: true });
       const result = await res.data;
       return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const RestoreOffice = createAsyncThunk(
  'Office/RestoreOffice',
    async (id, thunkAPI) => {
        const { rejectWithValue, getState } = thunkAPI;
        try {
          await axios.get(`${BaseUrl}/restoreBranch/${id}`, { withCredentials: true }).then((res)=>{
            if(res.data.status === true){
              sweetalert({
                text: res.data.msg,
            icon: "success",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            window.location.reload();
          });
          
        } 
      });
    } 
      catch (error) {
          return rejectWithValue(error.message);
        }
      }

);
export const insertOfficeCompany = createAsyncThunk(
  'Office/insertOfficeCompany',
    async (values, thunkAPI) => {
        const { rejectWithValue, getState } = thunkAPI;
        try {
          await axios.post(`${BaseUrl}/addBranch`,values, { withCredentials: true });
          sweetalert({
            text: "تم الإضافة بنجاح",
            icon: "success",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            window.location.reload();
          });
        
        }   catch (error) {
          // Check if the error status code is 403 Forbidden or another status code
          if (error.response && error.response.status === 403) {
            // Handle the 403 Forbidden error specifically
            sweetalert({
              text: "ليس لديك صلاحية للقيام بهذا",
              icon: "error",
              buttons: {
                confirm: {
                  text: "إغلاق",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            });
          } else {
            // Handle other errors with a generic message
            const errorMessage = error.response?.data?.message || error.response?.data?.data ;
            sweetalert({
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "إغلاق",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            });
          }
          return rejectWithValue(error.message);
        }
      }

);
export const updateOfficeCompany = createAsyncThunk(
  'Office/updateOfficeCompany',
  
    async (values, thunkAPI) => {
        const { rejectWithValue, resolve,reject,getState } = thunkAPI;
      //   bookData.auther = getState().auth.name;
        try {
         const res= await axios.post(`${BaseUrl}/updateBranch/${values.id_brance}`,values, { withCredentials: true });
         sweetalert({
          text: "تم التعديل بنجاح",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
        } 
        catch (error) {
          // Check if the error status code is 403 Forbidden or another status code
          if (error.response && error.response.status === 403) {
            // Handle the 403 Forbidden error specifically
            sweetalert({
              text: "ليس لديك صلاحية للقيام بهذا",
              icon: "error",
              buttons: {
                confirm: {
                  text: "إغلاق",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            });
          } else {
            // Handle other errors with a generic message
            const errorMessage = error.response?.data?.message || error.response?.data?.data;
            sweetalert({
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "إغلاق",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            });
          }
          return rejectWithValue(error.message);
        }
      }
  

);

export const deleteOfficeCompany = createAsyncThunk(
  'Office/deleteOfficeCompany',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(`${BaseUrl}/softDeleteBranch/${id}`, { withCredentials: true });
      if (response.data && response.data.status === true && response.data.msg) {
        sweetalert({
          text: response.data.msg,
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      }
    }  catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage = error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);
export const HarddeleteOfficeCompany = createAsyncThunk(
  'Office/HarddeleteOfficeCompany',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await axios.delete(`${BaseUrl}/hardDeleteBranch/${id}`, { withCredentials: true }).then((res)=>{
        if(res.data.status === true){
          sweetalert({
            text: res.data.msg,
            icon: "success",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            window.location.reload();
          });
        }
        })
    } 
     catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage = error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
 
  }
);

const OfficeCompanySlice = createSlice({
  name: 'Offices',
  initialState: { office: [] ,updateOffice:[],Myoffice:[], insertOffice:[] ,OfficeTrshed:[] ,loading: false, loadingupdate: false,loadingdeletesoft:false, loadingdeleteHard:false ,error: null },
  reducers: {
    clearOffices: (state) => {
      state.office = [];
      state.updateOffice = [];
      state.Myoffice = [];
      state.insertOffice = [];
      state.OfficeTrshed = [];
      state.loading = false;
      state.loadingupdate = false;
      state.loadingdeletesoft = false;
      state.loadingdeleteHard = false;
      state.error = null;
    },
  },
  extraReducers: {
        //fetch
        [OfficeCompanyById.pending]: (state, action) => {
          state.loading = true;
          state.error = null;
        },
        [OfficeCompanyById.fulfilled]: (state, action) => {
          state.office = action.payload;
          state.loading = false;
        },
        [OfficeCompanyById.rejected]: (state, action) => {
          state.error = action.payload;
          state.loading = false;
        },
        //fetch My office
        [MyOffice.pending]: (state, action) => {
          state.loading = true;
          state.error = null;
        },
        [MyOffice.fulfilled]: (state, action) => {
          state.Myoffice = action.payload;
          state.loading = false;
        },
        [MyOffice.rejected]: (state, action) => {
          state.error = action.payload;
          state.loading = false;
        },
        //fetch office trashed
        [Offitrashed.pending]: (state, action) => {
          state.loading = true;
          state.error = null;
        },
        [Offitrashed.fulfilled]: (state, action) => {
          state.OfficeTrshed = action.payload;
          state.loading = false;
        },
        [Offitrashed.rejected]: (state, action) => {
          state.error = action.payload;
          state.loading = false;
        },
        //insert
        [insertOfficeCompany.pending]: (state, action) => {
          state.loading = true;
          state.error = null;
        },
        [insertOfficeCompany.fulfilled]: (state, action) => {
          state.insertOffice.push(action.payload);
          state.loading = false;
        },
        [insertOfficeCompany.rejected]: (state, action) => {
          state.error = action.payload;
          state.loading = false;
        },
        //update
        [updateOfficeCompany.pending]: (state, action) => {
          state.loadingupdate = true;
          state.error = null;
        },
        [updateOfficeCompany.fulfilled]: (state, action) => {
          state.updateOffice.push(action.payload);
          state.loadingupdate = false;
        },
        [updateOfficeCompany.rejected]: (state, action) => {
          state.error = action.payload;
          state.loadingupdate = false;
        },

        //Soft delete 
        [deleteOfficeCompany.pending]: (state, action) => {
          state.loadingdeletesoft = true;
          state.error = null;
        },
        
        [deleteOfficeCompany.fulfilled]: (state, action) => {
          if (Array.isArray(state.office)) {
            const updatedOffices = state.office.filter((el) => el.id !== action.payload.id);
            state.office = updatedOffices;
          }
          state.loadingdeletesoft = false;
        },
      
        [deleteOfficeCompany.rejected]: (state, action) => {
          state.error = action.payload;
          state.loadingdeletesoft = false;
        },
        //Hard delete 
        [HarddeleteOfficeCompany.pending]: (state, action) => {
          state.loadingdeleteHard = true;
          state.error = null;
        },
        [HarddeleteOfficeCompany.fulfilled]: (state, action) => {
          if (Array.isArray(state.office)) {
            const updatedOffices = state.office.filter((el) => el.id !== action.payload.id);
            state.office = updatedOffices;
          }
          state.loadingdeleteHard = false;
          state.loading = false;
        },
        [HarddeleteOfficeCompany.rejected]: (state, action) => {
          state.error = action.payload;
          state.loadingdeleteHard = false;
        },
    
  },
});
export const { clearOffices } = OfficeCompanySlice.actions;
export default OfficeCompanySlice.reducer;
