import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchPriveePolice = createAsyncThunk(
  "PriveePolice/fetchPriveePolice",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/privacyPolicy`, {
        withCredentials: true,
      });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const insertPriveePolice = createAsyncThunk(
  "PriveePolice/insertPriveePolice",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`${BaseUrl}/addPrivacyPolicy`,data, {
        withCredentials: true,
      });
      sweetalert({
        text: res.data.message,
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const PriveePoliceSlice = createSlice({
  name: "PriveePolice",
  initialState: {
    PriveePolice: [],
    insertPriveePolice: [],
    loading: false,
    loadingAdd: false,
    error: null,
  },
  reducers: {
    clearPrivecPolice: (state) => {
      state.PriveePolice = [];
      state.insertPriveePolice = [];
      state.loading = false;
      state.loadingAdd = false;
      state.error = null;
    },
  },
  extraReducers: {
    //fetch Privec Police
    [fetchPriveePolice.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchPriveePolice.fulfilled]: (state, action) => {
      state.PriveePolice = action.payload;
      state.loading = false;
    },
    [fetchPriveePolice.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    //insert Privec Police
    [insertPriveePolice.pending]: (state, action) => {
      state.loadingAdd = true;
      state.error = null;
    },
    [insertPriveePolice.fulfilled]: async (state, action) => {
      state.insertPriveePolice = action.payload;
        state.loadingAdd = false;
    
    },
    [insertPriveePolice.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingAdd = false;
    },



  },
});
export const { clearPrivecPolice } = PriveePoliceSlice.actions;
export default PriveePoliceSlice.reducer;
