import { tokens } from "../../styles/theme";
import React, { useEffect, useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { Grid } from "@material-ui/core";
import Popup from "../../component/Popup";
import OfficeAddForm from "./officeAddForm";
import OfficeEditForm from "./officeEditForm";
import ConfirmDialog from "../../component/ConfirmDialogCompany";
import { DataGridPro } from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import {
  GridFooterContainer,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridPagination,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../../component/controls/Controls";
import { deleteOfficeCompany, OfficeCompanyById } from "../../redux/Office";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchCompanyById } from "../../redux/companyTransportDataSlice";
export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}
function CustomFooter() {
  return (
    <GridFooterContainer>
      <CustomPagination />
      <GridPagination />
    </GridFooterContainer>
  );
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    flexDirection: "row-reverse",
    paddingRight: "8px",
    height: "45px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
    marginRight: "3px",
  },
  "@media (min-width: 600px) and (max-width:1280px)": {
    searchDesign: {
      marginBottom: "15px",
    },
  },
  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  editButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#000002 !important",
    fontSize: "12px",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  deleteButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#F9B917 !important",
    fontSize: "12px",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  editImg: {
    width: "15px",
  },
});

export default function OfficeCompany({ branch, id_company,loading ,setShortcut}) {
  const { user } = useSelector((state) => state.auth);
  const[rowCount1,setRowCount1] = useState();

  const { loadingdeletesoft } = useSelector((state) => state.Offices);
  useEffect(() => {
    let typedWord = "";
    let typedWord2 = "";
  
    const handleKeyDown = (event) => {
      if (/^[a-zA-Z]$/.test(event.key)) {
        typedWord += event.key.toLowerCase();
        typedWord2 += event.key;
  
        const lastThreeChars = typedWord.substring(typedWord.length - 3);
  
        if (lastThreeChars === 'add') {
          localStorage.setItem("displayshortcut", "1");
          setOpenPopup(true);
          setRecordForEdit(null);
          setShortcut(1);
          typedWord = '';
        } 
      }
    };
  
    document.addEventListener("keydown", handleKeyDown);
  
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  },[]);
  const AddBranch =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "add.branch"
    );

  

  const UpdateBranch =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "update.branch"
    );

  
  const SoftDeleteBranch =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "soft.delete.branch"
    );



  const AddBranchdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "add.branch"
    );


  const UpdateBranchdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "update.branch"
    );


  const SoftDeleteBranchdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "soft.delete.branch"
    );


  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState(branch);
  const classes = useStyles();
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup1, setOpenPopup1] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [clickedEmployeeId, setClickedEmployeeId] = useState(null);
  useEffect(() => {
    dispatch(fetchCompanyById(id_company));
  }, [dispatch]);
  useEffect(() => {
    setFilteredData(branch);
    setRowCount1(branch?.lenght);
  }, [setFilteredData, clickedEmployeeId, dispatch, id_company]);
function handleDelete(id) {
    setConfirmDialog({
      isOpen: true,
      title: "أنت على وشك حذف مكتب الشركة",
      img: "img/emp/Inbox cleanup-rafiki.svg",
      onConfirm: () => {
        dispatch(deleteOfficeCompany(id));
      },
    });
  }
  function EditToolbar(props) {
    return (
      <Grid container xs={12} className={classes.Marginbox1} style={{width:'100%', display:'flex', justifyContent:'flex-end'}}>
        {AddBranchdefault === true || AddBranch === true ? (
          <Grid lg={2} md={4} sm={3} className={classes.AddDesign} style={{display:'flex', justifyContent:'end'}}>
            <div className="bottonFullwidth1">
              <Controls.Button
                type="submit"
                text="إضافة"
                onClick={() => {
                  setOpenPopup(true);
                  setRecordForEdit(null);
                }}
              />
            </div>
          </Grid>
        ) : (
          <></>
        )}
        <Popup
          title="إضافة مكتب للشركة"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setDisplayshortcut={setShortcut}
        >
          <OfficeAddForm
            id_company={id_company}
            recordForEdit={recordForEdit}
          />
        </Popup>
      </Grid>
    );
  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

 

  const processRowUpdate = (newRow) => {};

  const columns = [
    {
      field: "name_ar",
      sortable: false,
      headerName: "اسم المكتب",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phone_number",
      sortable: false,
      headerName: "الرقم الاول",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "mobile",
      sortable: false,
      headerName: "الرقم الثاني",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "address",
      sortable: false,
      headerName: "عنوان المكتب",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerAlign: "center",
      flex: 1,
      headerName: "العمليات",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <Box>

            {(UpdateBranchdefault === true || UpdateBranch === true)  ? (
              user.roles_name !== 'مدير الفرع' && (

              <Button
                className={classes.editButton}
                onClick={() => {
                  setOpenPopup1(true);
                  setRecordForEdit(params.row);
                  setClickedEmployeeId(id);
                  dispatch(OfficeCompanyById(id));
                }}
              >
                تعديل
                <img
                  src="img/edit.svg"
                  className={classes.editImg}
                  alt="delete icon"
                ></img>
              </Button>
              ) 
            ) : (
              <></>
            )}
            {SoftDeleteBranchdefault === true || SoftDeleteBranch === true ? (
              <Button
                className={classes.deleteButton}
                onClick={() => {
                  handleDelete(id);
                }}
              >
                حذف
                <img
                  src="img/delete.svg"
                  className={classes.editImg}
                  alt="delete icon"
                ></img>
              </Button>
            ) : (
              <></>
            )}
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
              loadingdelete={loadingdeletesoft}
            />

            <Popup
              title="تعديل مكتب للشركة"
              openPopup={openPopup1}
              setOpenPopup={setOpenPopup1}
              setDisplayshortcut={setShortcut}
            >
              <OfficeEditForm
                id_company={id_company}
                id_brance={clickedEmployeeId}
                recordForEdit={recordForEdit}
              />
            </Popup>
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <Box
       className="box-triptype"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },

          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "none",
            fontSize: "15px !important",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "15px !important",
            color: `${colors.primary[1000]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.white[100],
          },
        }}
      >      {loading ? (
          <div className="spinner1-box">
            <svg class="spinner1" viewBox="0 0 50 50">
              <circle
                class="path1"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          </div>
        ) : (
        <DataGridPro
          rows={branch || []}
          columns={columns}
          className="field-table"
          editMode="row"
          pageSize={15}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            rowCount={rowCount1}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          components={{
            Toolbar: EditToolbar,
            Footer: CustomFooter,
          }}
        />
        )}
      </Box>
    </Box>
  );
}
