import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
export default function SelectTrip(props) {
  const { name, label, value, error = null, onChange, options } = props;
  return (
    <FormControl variant="outlined" {...(error && { error: true })}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        className="selectraduis"
        label={label}
        name={name}
        value={value}
        onChange={onChange}
      >

        {options.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
               {item.info} - {item.type}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
