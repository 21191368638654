import React, { useEffect, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCity } from "../../redux/CitySlice";
import { insertOfficeCompany } from "../../redux/Office";
import axios from "axios";
import * as sweetalert from "sweetalert";
import { fetchTripBYId } from "../../redux/tripsSlice";
import packageJson from "../../../package.json";
import { useRef } from "react";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export default function ReservationPhone(props) {
  const { Cities } = useSelector((state) => state.City);
  const { loading } = useSelector((state) => state.Offices);
  const initialFValues = {
    name_ar: "",
    address: "",
    phone_number: "",
    mobile: "",
    id_company: props.id_company,
    id_city: "",
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllCity());
  }, [dispatch]);
  useEffect(() => {
  }, [props.idReservationresponse]);
  
  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "هذا الحقل مطلوب";
 

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(initialFValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const valueInputArray = Array.isArray(props.valueinput)
      ? props.valueinput
      : props.valueinput.split(/[\s,+]+/);
    const combinedSeats = [
      ...props.selectedSeats,
      ...props.selectedSeats1,
      ...props.selectedSeats2,
      ...valueInputArray,
    ]
      .map((seat) => {
        if (typeof seat === "number") return seat;

        return seat.split(/[\s,+]+/).map(Number);
      })
      .flat();

    const sortedNumbers = Array.from(
      new Set(combinedSeats.filter((seat) => !isNaN(seat)))
    );
    const uniqueNumbersWithoutZero = sortedNumbers.filter(
      (number) => number !== 0
    );
    const seatNumberToSend = uniqueNumbersWithoutZero.sort((a, b) => a - b);
    const isTransitTrip =
    props.TripID.tripTransit &&
    props.TripID.tripTransit.some((transit) => {
      return transit.reservationSeats.hasOwnProperty(seatNumberToSend[0]);
    });

  if (isTransitTrip) {
    const status = props.reservationseat1[seatNumberToSend[0]];
    if (status == 1) {
      props.setResultArray([]);
      sweetalert({
        text: "لا يمكن إضافة حجز لان الكرسي محجوز",
        icon: "error",
        buttons: {
          confirm: {
            text: "إغلاق",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        localStorage.setItem("changeseat", false);
        // dispatch(fetchTripBYId(.tripid));
      });
    } 
    else if (status == 3 || status == 4) {
      props.setResultArray([]);
      sweetalert({
        text: "لا يمكن القيام بهذه العملية",
        icon: "error",
        buttons: {
          confirm: {
            text: "إغلاق",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        localStorage.setItem("changeseat", false);
        // dispatch(fetchTripBYId(.tripid));
      });
    } 
    
    else if (status == 2) {
      props.setResultArray([]);
      sweetalert({
        text: "لا يمكن إضافة حجز لان الكرسي محجوز",
        icon: "error",
        buttons: {
          confirm: {
            text: "إغلاق",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        localStorage.setItem("changeseat", false);
        // dispatch(fetchTripBYId(.tripid));
      });
    } else {
      props.settranset(true);
      const data = {
        triptable_id: props.idTransit,
        trip_id: props.TripID.id,
        triptable_type: "Domain\\TripTransit",
        seat_number: seatNumberToSend,
        branch: props.TripID.id_branch,
        name: values.name,
      };
 

   
      await axios
        .post(`${BaseUrl}/addReservation`, data, {
          withCredentials: true,
        })
        .then((response) => {
          const responseData = response.data.data;
          props.setIdReservationResponse(responseData);
          
          if (!props.arrayReservations[responseData.id]) {
            props.arrayReservations[responseData.id] = responseData;
          }
          if(props.selectedTransitIndex == 0 ){
            props.setReservationseat1((currentResponse1) => {
              const updatedResponse1 = { ...currentResponse1 };
            
              // Iterate over each seat number in the array seatNumberToSend
              seatNumberToSend.forEach(seatNumber => {
                if (updatedResponse1.hasOwnProperty(seatNumber)) {
                  updatedResponse1[seatNumber] = 1;  // Update the status for each seat
                }
              });
            
              return updatedResponse1;
            });
            props.setReservationseat1(prevState => {
              // Create a new state object by copying the previous state
              const updatedState = {...prevState};
        
              // Iterate over each key in the responseData's reservation_seats_number
              Object.keys(responseData.reservation_seats_number).forEach(seatNumber => {
                // Update the specific seat number with the new status from reservation_seats_number
                // Convert seatNumber to an integer if your state keys are integers
                const seatNum = parseInt(seatNumber);
                if (!isNaN(seatNum)) {
                  updatedState[seatNum] = responseData.reservation_seats_number[seatNumber] // assuming this holds the new status
                  
                }
              });
             
              // Return the updated state
              return updatedState;
            });
            
            props.setArrayReservations((prevReservations) => {
              // Create a new object to ensure immutability
              const updatedReservations = { ...prevReservations };
  
              // Add or update the reservation by id_reservation
              if (!updatedReservations[responseData.id]) {
                updatedReservations[responseData.id] = {
                  ...responseData,
                  passengers: [],
                };
              } else {
                // Optionally update some properties if the reservation already exists
                updatedReservations[responseData.id] = {
                  ...updatedReservations[responseData.id],
                  ...responseData, // assuming you want to merge or update details
                  passengers: [],
                };
              }
  
              return updatedReservations; // Return the updated reservations object
            });
      
            props.setArraySeatPassengerTransit1((prevSeatsInfo) => {
                const updatedSeatsInfo = { ...prevSeatsInfo };
                seatNumberToSend.forEach((seatNumber) => {
                if (!isNaN(seatNumber)) {
                  updatedSeatsInfo[seatNumber] = {
                    passengers: null, // Directly using the object
                    id_reservation: responseData.id, // Directly using the object
                    status: 1, // Default status or derive from existing data
                  };
                }
      
              });
                return updatedSeatsInfo;
              });
              // props.setArrayReservations((prevReservations) => {
              //   const updatedReservations = JSON.parse(JSON.stringify(prevReservations));
              
              //   seatNumberToSend.forEach((seatNumber) => {
              //     const reservationIds = Object.keys(updatedReservations).filter(reservationId =>
              //       updatedReservations[reservationId].reservation_seats_number &&
              //       updatedReservations[reservationId].reservation_seats_number[seatNumber]
              //     );
              
              //     reservationIds.forEach(reservationId => {
              //       const reservation = updatedReservations[reservationId];
              //       if (reservation) {
              //         // Update seat status to 2 for each seat number in the array
              //         Object.keys(reservation.reservation_seats_number).forEach((key) => {
              //           if (key === seatNumber.toString()) {
              //             reservation.reservation_seats_number[key] = 1;
              //           }
              //         });
              
              //         // Further updates can be added here, such as updating passenger details
              //       }
              //     });
              //   });
              
              //   return updatedReservations;
              // });
          }else if (props.selectedTransitIndex == 1){
            props.setReservationseat1((currentResponse1) => {
              const updatedResponse1 = { ...currentResponse1 };
            
              // Iterate over each seat number in the array seatNumberToSend
              seatNumberToSend.forEach(seatNumber => {
                if (updatedResponse1.hasOwnProperty(seatNumber)) {
                  updatedResponse1[seatNumber] = 1;  // Update the status for each seat
                }
              });
            
              return updatedResponse1;
            });
        
            props.setReservationseat1(prevState => {
              // Create a new state object by copying the previous state
              const updatedState = {...prevState};
        
              // Iterate over each key in the responseData's reservation_seats_number
              Object.keys(responseData.reservation_seats_number).forEach(seatNumber => {
                // Update the specific seat number with the new status from reservation_seats_number
                // Convert seatNumber to an integer if your state keys are integers
                const seatNum = parseInt(seatNumber);
                if (!isNaN(seatNum)) {
                  updatedState[seatNum] = responseData.reservation_seats_number[seatNumber] // assuming this holds the new status
                  
                }
              });
             
              // Return the updated state
              return updatedState;
            });
       
            
            props.setArraySeatPassengerTransit2((prevSeatsInfo) => {
                const updatedSeatsInfo = { ...prevSeatsInfo };
                seatNumberToSend.forEach((seatNumber) => {
                if (!isNaN(seatNumber)) {
                  updatedSeatsInfo[seatNumber] = {
                    passengers: null, // Directly using the object
                    id_reservation: responseData.id, // Directly using the object
                    status: 1, // Default status or derive from existing data
                  };
                }
      
              });
                return updatedSeatsInfo;
              });
              props.setArrayReservations((prevReservations) => {
                // Create a new object to ensure immutability
                const updatedReservations = { ...prevReservations };
    
                // Add or update the reservation by id_reservation
                if (!updatedReservations[responseData.id]) {
                  updatedReservations[responseData.id] = {
                    ...responseData,
                    passengers: [],
                  };
                } else {
                  // Optionally update some properties if the reservation already exists
                  updatedReservations[responseData.id] = {
                    ...updatedReservations[responseData.id],
                    ...responseData, // assuming you want to merge or update details
                    passengers: [],
                  };
                }
    
                return updatedReservations; // Return the updated reservations object
              });
              // props.setArrayReservations((prevReservations) => {
              //   const updatedReservations = JSON.parse(JSON.stringify(prevReservations));
              
              //   seatNumberToSend.forEach((seatNumber) => {
              //     const reservationIds = Object.keys(updatedReservations).filter(reservationId =>
              //       updatedReservations[reservationId].reservation_seats_number &&
              //       updatedReservations[reservationId].reservation_seats_number[seatNumber]
              //     );
              
              //     reservationIds.forEach(reservationId => {
              //       const reservation = updatedReservations[reservationId];
              //       if (reservation) {
              //         // Update seat status to 2 for each seat number in the array
              //         Object.keys(reservation.reservation_seats_number).forEach((key) => {
              //           if (key === seatNumber.toString()) {
              //             reservation.reservation_seats_number[key] = 1;
              //           }
              //         });
              
              //         // Further updates can be added here, such as updating passenger details
              //       }
              //     });
              //   });
              
              //   return updatedReservations;
              // });
              // props.setArrayReservations((prevReservations) => {
              //   // Deep clone the previous reservations to avoid direct state mutation
              //   const updatedReservations = JSON.parse(
              //     JSON.stringify(prevReservations)
              //   );
      
              //   // Ensure the reservation exists
              //   const reservation = updatedReservations[responseData.id];
              //   if (reservation) {
              //     // Check if the passenger array is not null and properly formatted as an array
              //     {
              //       const existingPassengerIndex = reservation.passengers.findIndex(
              //         (p) => p.id === e.passenger.id
              //       );
      
              //       if (existingPassengerIndex >= 0) {
              //         reservation.passengers[existingPassengerIndex] = {
              //           ...e.passenger,
              //         };
              //       } else {
              //         // Add new passenger directly as an object, no extra nesting
              //         reservation.passengers.push(e.passenger);
              //       }
              //     }
      
              //     // Update seat status to 2 if not already
              //     Object.keys(reservation.reservation_seats_number).forEach(
              //       (seatNumber) => {
              //         if (reservation.reservation_seats_number[seatNumber] !== 2) {
              //           reservation.reservation_seats_number[seatNumber] = 2;
              //         }
              //       }
              //     );
              //   }
      
              //   return updatedReservations;
              // });
          }
          // localStorage.setItem("openpassenger", true);
          localStorage.setItem("AddReservation", false);
          sweetalert({
            text: "تم الحجز بنجاح",
            icon: "success",
            buttons: {
              confirm: {
                text: "إغلاق",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
            props.setOpenPopup(false);
            localStorage.setItem("ReservationPhone",false);
            props.setInputString("");
            props.setValueinput("");
            props.setSelectedSeats1([]);
            props.setSelectedSeats2([]);
            props.setSelectedSeats([]);
            props.setSeatAndUser([]);
            props.setResultArray([]);
            // dispatch(fetchTripBYId(.tripid));
          });
          // dispatch(setLoading1(false));
        })
        .catch((error) => {
          // Handle the error here
          props.setResponse1([]);
          // dispatch(fetchTripBYId(props.TripID.id));
          props.setResponse1(props.TripID.reservation_seats);
        });
      // props.setInputString("");
      // props.setValueinput("");
      // props.setSelectedSeats1([]);
      // props.setSelectedSeats([]);
      // props.setSeatAndUser([]);
      // setResultArray([]);
      // setSelectedSeats([]);
    }
  } else {
    const status = props.response1[seatNumberToSend[0]];
    const isTransitTrip1 =
      props.TripID.tripTransit &&
      props.TripID.tripTransit.some((transit) => {
        return transit.reservationSeats.hasOwnProperty(props.selectedSeats[0]);
      });

    // eslint-disable-next-line eqeqeq
    if (isTransitTrip1 == true) {
      sweetalert({
        title: "خطأ في العملية",
        text: "  ",
        icon: "error",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      });
    } else {
      if (status == 1) {
        props.setResultArray([]);
        sweetalert({
          text: "لا يمكن إضافة حجز لان الكرسي محجوز",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
          // dispatch(fetchTripBYId(.tripid));
        });
      } else if (status == 2) {
        props.setResultArray([]);
        sweetalert({
          text: "لا يمكن إضافة حجز لان الكرسي محجوز",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
          // dispatch(fetchTripBYId(.tripid));
        });
      } else {
        const data = {
          triptable_id: props.TripID.id,
          trip_id: props.TripID.id,
          triptable_type: "Domain\\Trip",
          seat_number: seatNumberToSend,
          branch: props.TripID.id_branch,
          name: values.name,
        };
      

      
        props.setDirect(true);
        props.settranset(false);
        // dispatch(insertreservation(data));

        await axios
          .post(`${BaseUrl}/addReservation`, data, {
            withCredentials: true,
          })
          .then((response) => {

            if(response.data.status == 200){

              const responseData = response.data.data;
              props.setIdReservationResponse(responseData);
              if (!props.arrayReservations[responseData.id]) {
                props.arrayReservations[responseData.id] = responseData;
              }
              props.setArraySeat((prevSeatsInfo) => {
                const updatedSeatsInfo = { ...prevSeatsInfo };
    
                seatNumberToSend.forEach((number) => {
                  const seatNumber = parseInt(number);
    
                  if (!isNaN(seatNumber)) {
                    updatedSeatsInfo[seatNumber] = {
                      passengers: {}, // Assuming you want to reset passengers info
                      id_reservation: responseData.id,
                      status: 1, // Resetting the status
                    };
                  }
                });
    
                return updatedSeatsInfo;
              });
              props.setArrayReservations((prevReservations) => {
                // Create a new object to ensure immutability
                const updatedReservations = { ...prevReservations };
    
                // Add or update the reservation by id_reservation
                if (!updatedReservations[responseData.id]) {
                  updatedReservations[responseData.id] = {
                    ...responseData,
                    passengers: [],
                  };
                } else {
                  // Optionally update some properties if the reservation already exists
                  updatedReservations[responseData.id] = {
                    ...updatedReservations[responseData.id],
                    ...responseData, // assuming you want to merge or update details
                    passengers: [],
                  };
                }
    
                return updatedReservations; // Return the updated reservations object
              });
              props.setResponse1(prevState => {
                // Create a new state object by copying the previous state
                const updatedState = {...prevState};
          
                // Iterate over each key in the responseData's reservation_seats_number
                Object.keys(responseData.reservation_seats_number).forEach(seatNumber => {
                  // Update the specific seat number with the new status from reservation_seats_number
                  // Convert seatNumber to an integer if your state keys are integers
                  const seatNum = parseInt(seatNumber);
                  if (!isNaN(seatNum)) {
                    updatedState[seatNum] = responseData.reservation_seats_number[seatNumber] // assuming this holds the new status
                    
                  }
                });
             
                // Return the updated state
                return updatedState;
              });
              // localStorage.setItem("openpassenger", true);
              localStorage.setItem("AddReservation", false);
              sweetalert({
                text: "تم الحجز بنجاح",
                icon: "success",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
                props.setOpenPopup(false);
                localStorage.setItem("ReservationPhone",false);
                // dispatch(fetchTripBYId(.tripid));
                props.setInputString("");
                props.setValueinput("");
                props.setSelectedSeats1([]);
                props.setSelectedSeats2([]);
                props.setSelectedSeats([]);
                props.setSeatAndUser([]);
                props.setResultArray([]);
              });
            }
            // dispatch(setLoading1(false));
          })
          .catch((error) => {
            // Handle the error here
            props.setResponse1([]);
            // dispatch(fetchTripBYId(props.TripID.id));
            props.setResponse1(props.TripID.reservation_seats);
          });
 
        // resultArray
      }

      // setInputString("");
      // setValueinput("");
      // setSelectedSeats1([]);
      // setSelectedSeats([]);
    }
  }

    // setResultArray(seatNumberToSend);
      // dispatch(insertOfficeCompany(values));
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);
  const inputRef = useRef(null);

  // Focus the input when the component mounts
  const inputRefs = useRef(null);
  useEffect(() => {
    // Ensure the current reference is available before attempting to call focus
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      {/* <Grid container> */}
      <Grid item xs={12}>
        <Controls.Input
          className="tesr"
          name="name"
          inputRef={inputRef}
          label={
              <Fragment>
                <InputAdornment position="end">
                <span className="placeholdertitle"> اسم صاحب الحجز </span>
                </InputAdornment>
              </Fragment>
            }
   
          value={values.name}
          onChange={handleInputChange}
          error={errors.name}
        />
    

    
        {loading ? (
            <div  className="bottonFullwidth" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>

            <div
           className="load loadingupdate"
           style={{ background: "#F9B917", borderRaduis: "10px" }}
           >
           <div v-if="loading" class="spinner">
             <div class="rect1"></div>
             <div class="rect2"></div>
             <div class="rect3"></div>
             <div class="rect4"></div>
             <div class="rect5"></div>
           </div>
         </div>
           </div>
        ) : (
          <div className="bottonFullwidth" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Controls.Button type="submit" text="إضافة" />
          </div>
        )}
      </Grid>
    </Form>
  );
}
