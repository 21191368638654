import React, { useCallback, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ConfirmDialog from "../../component/ConfirmDialog.js";
import { TableVirtuoso } from "react-virtuoso";
import { useEffect } from "react";
import { logDOM } from "@testing-library/react";
import { Box, useTheme, Button, alertClasses, Tooltip } from "@mui/material";
import { Grid } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import BlockIcon from '@mui/icons-material/Block';
import LockIcon from '@mui/icons-material/Lock';
import * as sweetalert from "sweetalert";

import {
  deleteTrip,
  fetchTripBYId,
  fetchTrips,
} from "../../redux/tripsSlice.jsx";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import PopupStop from "../../component/PopupStop.js";
import StopTrip from "./StopTrip.jsx";
import { makeStyles } from "@material-ui/core/styles";
import PopupCreate from "../../component/PopupCreate.js";
import TripFormEdit from "./TripEdit1.js";
import Shortcut from "./Shortcut.js";
import PopupShortcut from "../../component/PopupShortcut.js";
import TripForm from "./TripForm.js";
import PopupCreate2 from "../../component/PopupCreate2.js";
import PopupCreate3 from "../../component/PopupCreate3.js";
import Controls from "../../component/controls/Controls.js";
import { useLocation } from "react-router-dom";
import { Select, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import { Datepicker } from "@zendeskgarden/react-datepickers";
import { Field, Label, Input } from "@zendeskgarden/react-forms";
import { Row, Col } from "@zendeskgarden/react-grid";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { fetchAllCity } from "../../redux/CitySlice.jsx";
import dayjs from "dayjs";
import { setstatus } from "../../redux/reservation.jsx";
import SetRed from "../SetsBusTrip/SetRed.jsx";
import { LeakRemoveTwoTone, WindowSharp } from "@mui/icons-material";
import { fetchCompanyById } from "../../redux/companyTransportDataSlice.jsx";
import axios from "axios";
import packageJson from "../../../package.json";
export const BaseUrl = packageJson.projectURlApi.BaseUrl;
const sample = [
  ["Frozen yoghurt", 159, 6.0, 24, 4.0],
  ["Ice cream sandwich", 237, 9.0, 37, 4.3],
  ["Eclair", 262, 16.0, 24, 6.0],
  ["Cupcake", 305, 3.7, 67, 4.3],
  ["Gingerbread", 356, 16.0, 49, 3.9],
];

export const key_Shortcut = packageJson.key_Shortcut;
const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    flexDirection: "row-reverse",
    paddingRight: "8px",
    height: "45px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
    marginRight: "3px",
  },
  "@media (min-width: 600px) and (max-width:1280px)": {
    searchDesign: {
      marginBottom: "15px",
    },
  },
  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  editButton: {
    display: "none",
    flexDirection: "row-reverse",
    color: "#000002 !important",
    fontSize: "20px",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  deleteButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#F9B917 !important",
    fontSize: "20px ",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  editImg: {
    width: "15px",
  },
  Marginbox1: {
    maxWidth: "100% !important",
    flexBasis: "100%",
  },
});
export default function ReactVirtualizedTable({
  setShortcut,
  id_company,
  schortcut,
}) {
  const classes = useStyles();
  const location = useLocation();
  const id_comp = location.state; // Print the state value

  const dispatch = useDispatch();
  const { loading, Trips, TripID } = useSelector((state) => state.Trip);
  // const [filteredData, setFilteredData] = useState(Trips);
  const currentDateTime = new Date();
  const [show, setShow] = useState(false);
  const [FilteredTrips, setFilteredTrips] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopuppassenger, setOpenPopuppassenger] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [openPopup10, setOpenPopup10] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForEdit11, setRecordForEdit11] = useState(null);
  const [recordForEdit1, setRecordForEdit1] = useState(null);
  const [openPopupStop, setOpenPopupStop] = useState(false);
  const [clickedEmployeeId, setClickedEmployeeId] = useState(null);
  const [selectedfromcityId, setSelectedFromCityId] = useState(null);
  const [selectedtocityId, setSelectedToCityId] = useState(null);
  const [isTableDisabled, setIsTableDisabled] = useState(false);
  const [date, setDate] = React.useState();
  const [numberpassenger, setNumberPassenger] = useState(0);
  const [showpassenger, setShowPassenger] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [valueinput, setValueinput] = useState("");
  const [pathTrip, setPathtrip] = useState("");
  const [idTrip, setIdTrip] = useState("");
  const { Cities } = useSelector((state) => state.City);
  const inputRef = useRef(null);
  const tableRef = useRef(null);
  const pathToColumnRef = useRef(null);
  const { statusNoAvailabel } = useSelector((state) => state.reservations);
  const [openPopup3, setOpenPopup3] = useState(false);
  const [currentTime1, setCurrentTime1] = useState(new Date());
  const { user } = useSelector((state) => state.auth);
  const dataGridRef = useRef(null);
  const [focusedColumn, setFocusedColumn] = useState(null);
  const [newStatus, setNewStatus] = useState(false);
  const [newStatusAvail, setNewStatusAvail] = useState(false);
  const [loadingtrip, setLoadingtrip] = useState(false);
  const [disabledenter, setdisabledenter] = useState(false);
  const [editingTripId, setEditingTripId] = React.useState(null);
  const row = localStorage.getItem('row');
  
  const { status } = useSelector((state) => state.reservations);
  useEffect(() => {},[TripID]);
  useEffect(() => {
    const handleKeyDown = (event) => {
      let typedWord = "";
      let typedWord2 = "";
      if (/^[a-zA-Z]$/.test(event.key)) {
        typedWord += event.key.toLowerCase();
        typedWord2 += event.key;
        const lastThreeChars = typedWord.substring(typedWord.length - 3);
        if (lastThreeChars === "add") {
          localStorage.setItem("displayshortcut", "1");
          setOpenPopup(true);
          setRecordForEdit(null);
          setShortcut(1);
          typedWord = "";
        }
        if (typedWord.substring(typedWord.length - 1) === "x") {
          localStorage.setItem("displayshortcut", "0");
          setShortcut(0);
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      // if (statusNoAvailabel === true) {
      if (loadingtrip === true) setLoadingtrip(false);
    }, 1000);
  }, [loadingtrip]);

  //Add Permission
  const AddTrip =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "add.trip");

  const UpdateTrip =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "update.trip");

  const SoftDeleteTrip =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "soft.delete.trip"
    );
  const UpdateTripLocked =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "update.trip.islock"
    );
  const manualDoneStatus =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "manual.done.status"
    );

  // Permission as default
  const AddTripdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some((permission) => permission === "add.trip");

  const UpdateTripdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "update.trip"
    );

  const SoftDeleteTripdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "soft.delete.trip"
    );
    const UpdateTripLockeddefult =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "update.trip.islock"
    );
    const manualDoneStatusdefult =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "manual.done.status"
    );
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (id_company !== null) {
      dispatch(fetchTrips(id_company));

      localStorage.setItem("displayshortcut", "1");

      // setFilteredData(Trips);
    }
  }, [dispatch, id_company]);

  // useEffect(() => {
  //   dispatch(fetchCompanyById(id_company));
  // }, []);
  useEffect(() => {
    let typedWord = "";
    let typedWord2 = "";

    const handleKeyDown = (event) => {
      // // if (event.key === " ") {
      //   event.preventDefault();
      // }
      if (/^[a-zA-Z]$/.test(event.key)) {
        typedWord += event.key.toLowerCase();
        typedWord2 += event.key;

        const lastThreeChars = typedWord.substring(typedWord.length - 3);

        if (lastThreeChars === "add") {
          localStorage.setItem("displayshortcut", "1");
          setOpenPopup(true);
          setRecordForEdit(null);
          setShortcut(1);
          typedWord = "";
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const handleEditupdd = (id) => {
   
    setEditingTripId(id); // Set the ID of the trip being edited
    setRecordForEdit1(null);
    setClickedEmployeeId(id);
    dispatch(fetchTripBYId(id));
  };

  const handleUpdate = (id) => {
    // Logic to handle update action
  };
  useEffect(() => {
    localStorage.setItem("openpassenger", false);
  }, []);
  useEffect(() => {
    localStorage.setItem("ReservationPhone", false);
  }, []);
  
  const handleDelete = (id, branch_id) => {
    setConfirmDialog({
      isOpen: true,
      title: "أنت على وشك حذف هذه الرحلة",
      img: "img/emp/Inbox cleanup-rafiki.svg",
      onConfirm: () => {
        dispatch(deleteTrip({ id, branch_id })); // Pass both id and branch_id as an object
      },
    });
  };
  function handleOpen() {
    setOpenPopup(true);
    setRecordForEdit(null);
  }
  function handleOpen2() {
    setOpenPopup3(true);
    setRecordForEdit(null);
  }
  const handleChange = () => {
    setValueinput(inputRef.current.value);
  };
  function focusInput() {
    // Focus on the input element
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }
  useEffect(() => {
    if (status === true) {
      setstatus(false);
    }
  }, [status]);
  
  const handleKeyDown2 = (event, id) => {
    // setSelectedRow(row);

    // setSelectedRow(id); // Update the selected row
    if (
      localStorage.getItem("openpassenger") === "false" &&
      localStorage.getItem("changeseat") === "false" && valueinput !== null &&  localStorage.getItem("ReservationPhone") == "false" 
    ) {
      
      if (event.key === "Enter") {
        focusInput();
        setIsDisabled(true);
        // localStorage.setItem("row", selectedRow)
        if (status === true) {
          setstatus(false);
        }
        if (newStatus === true) {
          setNewStatus(false);
        }

        setShow(true);
        setIdTrip(localStorage.getItem("row"));
        dispatch(fetchTripBYId(localStorage.getItem("row")));
        focusInput();
        setLoadingtrip(true);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown2);

    return () => {
      document.removeEventListener("keydown", handleKeyDown2);
    };
  }, []);
  const [showFinishedTrips, setShowFinishedTrips] = useState(false);
  const [notshowFinishedTrips, setnotShowFinishedTrips] = useState(false);
  const [AddReservation1, setAddReservation1] = useState(false);
  const formattedRows = Trips.map((row) => {
    const date = new Date(row.date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
   
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);

    let formattedDate;
    let days;
    // Compare the date parts separately
    if (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    ) {
      days = "اليوم";
      formattedDate='';
    } else if (
      date.getFullYear() === tomorrow.getFullYear() &&
      date.getMonth() === tomorrow.getMonth() &&
      date.getDate() === tomorrow.getDate()
    ) {
      days = "غداً";
      formattedDate='';
    } else if (
      date.getFullYear() === dayAfterTomorrow.getFullYear() &&
      date.getMonth() === dayAfterTomorrow.getMonth() &&
      date.getDate() === dayAfterTomorrow.getDate()
    ) {
      days = "بعد غد";
      formattedDate='';
    } else {
      const dayOptions = { day: "2-digit" };
      const monthOptions = { month: "2-digit" };
      const yearOptions = { year: "numeric" };
      const weekdayOptions = { weekday: "long" };
      const day = date.toLocaleDateString("en-EG", dayOptions);
      const month = date.toLocaleDateString("en-EG", monthOptions);
      const year = date.toLocaleDateString("en-EG", yearOptions);
      const weekday = date.toLocaleDateString("ar-EG", weekdayOptions);

      days = `${weekday}`;
      formattedDate = `${year}/${month}/${day}`;
    }

    return {
      ...row,
      Days: days,
      date: formattedDate,
    };
  });

  
  function filterTrips(Trips) {
    let filteredTrips;

    if (selectedfromcityId && selectedtocityId) {
      filteredTrips = Trips.filter(
        (trip) =>
          trip.path_from === selectedfromcityId &&
          trip.path_to === selectedtocityId
      );
    } else if (selectedfromcityId && selectedtocityId && date) {
      filteredTrips = Trips.filter(
        (trip) =>
          trip.path_from === selectedfromcityId &&
          trip.path_to === selectedtocityId &&
          trip.date === dayjs(date).format("YYYY-MM-DD")
      );
    } else if (selectedfromcityId && date) {
      filteredTrips = Trips.filter(
        (trip) =>
          trip.path_from === selectedfromcityId &&
          trip.date === dayjs(date).format("YYYY-MM-DD")
      );
    } else if (selectedtocityId && date) {
      filteredTrips = Trips.filter(
        (trip) =>
          trip.path_to === selectedtocityId &&
          trip.date === dayjs(date).format("YYYY-MM-DD")
      );
    } else if (selectedfromcityId) {
      filteredTrips = Trips.filter(
        (trip) => trip.path_from === selectedfromcityId
      );
    } else if (selectedtocityId) {
      filteredTrips = Trips.filter((trip) => trip.path_to === selectedtocityId);
    } else if (date) {
      filteredTrips = Trips.filter(
        (trip) => trip.date === dayjs(date).format("YYYY-MM-DD")
      );
    } else if (showFinishedTrips && notshowFinishedTrips) {
      // Show all trips when both checkboxes are unchecked
      filteredTrips = Trips;
    } else if (showFinishedTrips) {
      // Show only finished trips
      filteredTrips = Trips.filter((trip) => {
        const correctedDateTime = trip.dateTime.replace(
          /(\d{4}-\d{2}-\d{2})(\d{2}:\d{2}:\d{2})/,
          "$1 $2"
        );
        const tripDateTime = new Date(correctedDateTime);
        const now = new Date();
        const twoHoursAgo = new Date(now.getTime() + 2 * 60 * 60 * 1000); // 2 hours ago
        return tripDateTime < twoHoursAgo;
      });
    } else if (notshowFinishedTrips) {
      // Show only continuing trips
      filteredTrips = Trips.filter((trip) => {
        const correctedDateTime = trip.dateTime.replace(
          /(\d{4}-\d{2}-\d{2})(\d{2}:\d{2}:\d{2})/,
          "$1 $2"
        );
        const tripDateTime = new Date(correctedDateTime);
        const now = new Date();
        const twoHoursAgo = new Date(now.getTime() + 2 * 60 * 60 * 1000); // 2 hours ago
        return tripDateTime > twoHoursAgo;
      });
    } else {
      filteredTrips = [...Trips]; // Create a shallow copy before sorting
      filteredTrips.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });
    }

    return filteredTrips;
  }
  const filteredTripes = filterTrips(formattedRows);

  const rows = filteredTripes;
  const serializedRows = JSON.stringify(rows);

  // Save the serialized string to localStorage
  localStorage.setItem("rows", serializedRows);
  //

  const handleCheckboxChange = (event) => {
    setShowFinishedTrips(event.target.checked);
  };
  const handleCheckboxChangecontiouns = (event) => {
    setnotShowFinishedTrips(event.target.checked);
  };

  useEffect(() => {},[openPopup]);

 

  useEffect(() => {


      const newFilteredTrips = filterTrips(Trips); // Call your filter function
      setFilteredTrips(newFilteredTrips); // Update state
      selectedRowRef.current = newFilteredTrips[0]?.id; // Reset navigation to first item
      setSelectedRow(newFilteredTrips[0]?.id);

    
  }, []);
  const columns = [
    {
      label: "الانطلاق",
      dataKey: "path_from",
      sortable: false,
      resizable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div
          className=""
          data-field={params.field}
          data-field2={params.row.id}
          tabindex={0}
        >
          {params.value}
        </div>
      ),
    },
    {
      label: "الوجهة",
      dataKey: "path_to",
      sortable: false,
      resizable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div
          className="autofocusenter"
          data-field={params.field}
          data-field2={params.row.id}
          tabindex={0}
        >
          {params.value}
        </div>
      ),
    },
    {
      label: "الفرع",
      dataKey: "branch",
      sortable: false,
      resizable: true,
      headerAlign: "center",
      align: "center",
     
    },
    {
      dataKey: "Days",
      label: "اليوم",
      headerAlign: "center",
      align: "center",
    },
    {
      dataKey: "date",
      label: "التاريخ",
      headerAlign: "center",
      align: "center",
    },
    {
      dataKey: "time",
      label: "التوقيت",
      headerAlign: "center",
      align: "center",
    },
    {
      dataKey: "trip_type",
      label: "نوع الرحلة",
      headerAlign: "center",
      align: "center",
    },
    {
      dataKey: "tripTransit",
      label: "رحلة العبور",
      headerAlign: "center",
      align: "center",
      width: "150px",
      minWidth: "150px",
      maxWidth: "150px",
      renderCell: (params) => {
        if (params.value && params.value.length > 0) {
          return (
            <div>
              {params.value.map((transit, index) => (
                <div key={index}>
                  {transit.name} -- {transit.time}
                </div>
              ))}
            </div>
          );
        } else {
          return <p> </p>;
        }
      },
    },
    {
      dataKey: "stations",
      sortable: false,
      resizable: true,
      label: "أماكن التوقف",
      headerAlign: "center",
      align: "center",
    },
    {
      dataKey: "actions",
      label: "العمليات",
      headerAlign: "center",
      align: "center",
    },
    {
      dataKey: "finishing",
      label: "حالة الرحلة",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // Correct the dateTime format by inserting a space between the date and time
        const correctedDateTime = params.row.dateTime.replace(
          /(\d{4}-\d{2}-\d{2})(\d{2}:\d{2}:\d{2})/,
          "$1 $2"
          );
          const tripDateTime = new Date(correctedDateTime);
          const now = new Date();
          const twoHoursAgo = new Date(now.getTime() + 2 * 60 * 60 * 1000); // 2 hours ago
          let status;
          let color;
          if (tripDateTime > now) {
            status = "مستمرة"; // The trip is in the future
            color = "green"; // Green color for ongoing trips
          } else if (tripDateTime < twoHoursAgo) {
            status = "حان وقت الرحلة"; // The trip was more than 2 hours ago
            color = "red"; // Red color for finished trips
          } else {
            // If you need to handle the else case, add logic here
            status = "غير محدد"; // Example status
            color = "grey"; // Example color for undefined status
          }
          
          return (
            <div
            className="status-cell"
            data-field={params.field}
            data-field2={params.row.id}
            tabindex={0}
            style={{ color: color }} // Apply the color style here
          >
            {status}
          </div>
        );
      },
    },
    {
      dataKey: "actions1",
      label: " العمليات على الرحلة",
      headerAlign: "center",
      align: "center",
    },
    
    // {
    //   field: "tripTransit",
    //   sortable: false,
    //   resizable: true,
    //   headerName: "رحلة العبور",
    //   // maxWidth: 200,
    //   minWidth: "150",
    //   maxWidth: "150",
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => (
    //     <div data-field={params.field}>
    //       {params.value && params.value.length === 1 ? (
    //         <div>
    //           {params.value[0].name} -- {params.value[0].time}
    //         </div>
    //       ) : params.value && params.value.length >= 2 ? (
    //         <div>
    //           <div>
    //             {params.value[0].name} --
    //             {params.value[0].time}
    //           </div>

    //           <div>
    //             {params.value[1].name} --
    //             {params.value[1].time}
    //           </div>
    //         </div>
    //       ) : (
    //         <p> </p>
    //       )}
    //     </div>
    //   ),
    // },
  ];

  const onHandleReset = () => {
    setSelectedFromCityId(null);
    setSelectedToCityId(null);
    setDate(null);
  };
  const handleDateChange = (newValue) => {
    setDate(newValue);
  };
  function EditToolbar(props) {
    return (
      <div
        className="trip"
        style={{
          display: "flex",
          flexDirection: "row",

          gap: "3%",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          // paddingLeft: "10%",
        }}
      >
        <Grid
          container
          xs={12}
          className="container-div-filter-trip"
          style={{ gap: "1%" }}
        >
          <Grid item lg={11} md={11} sm={11}>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notshowFinishedTrips}
                    onChange={handleCheckboxChangecontiouns}
                  />
                }
                label="مستمرة"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showFinishedTrips}
                    onChange={handleCheckboxChange}
                  />
                }
                label="منتهية"
              />
            </FormGroup>
          </Grid>
          <Grid lg={11} md={11} sm={11} style={{ height: "38px" }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className={` ${
                selectedfromcityId
                  ? "selects11trip select1tripdirect"
                  : "selects1trip selects1trip select1tripdirect"
              }`}
              text="بحث"
              value={selectedfromcityId}
              onChange={(e) => setSelectedFromCityId(e.target.value)}
              style={{ marginBottom: "10px !important" }}
            >
              {Cities.map((city) => (
                <MenuItem value={city.name_ar} key={city.id}>
                  <span>{city.name_ar}</span>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid
            lg={11}
            md={11}
            sm={11}
            className={classes.AddDesign}
            style={{ marginBottom: "10% !important", height: "38px" }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className={` ${
                selectedtocityId
                  ? "selects22trip select2tripdirect"
                  : "selects2trip selects2trip select2tripdirect"
              }`}
              value={selectedtocityId}
              onChange={(e) => setSelectedToCityId(e.target.value)}
            >
              {Cities.map((city) => (
                <MenuItem value={city.name_ar} key={city.id}>
                  <span>{city.name_ar}</span>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid
            lg={11}
            md={11}
            sm={11}
            className="filterdate"
            style={{
              border: "1px solid #eee",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "1px 3px 6px rgba(0,0,0,0.3)",
              borderRadius: "10px",
              marginBottom: "10px",
            }}
          >
            <ThemeProvider>
              <Row justifyContent="center">
                <Col sm={5}>
                  <Field
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <Label
                      style={{
                        color: "rgb(207, 204, 205)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "10px",
                      }}
                    >
                      {" "}
                      تاريخ الرحلة
                    </Label>
                    <Datepicker
                      clearable
                      okLabel="موافق"
                      cancelLabel="الغاء"
                      clearLabel="مسح"
                      value={date}
                      onChange={handleDateChange}
                      locale="ar-SY"
                    >
                      <Input
                        className="iput-filter-date"
                        lang="ar-SY"
                        style={{
                          border: "none",
                          width: "60%",
                          cursor: "pointer",
                          marginTop: "0px ",
                        }}
                      />
                    </Datepicker>
                  </Field>
                </Col>
              </Row>
            </ThemeProvider>
          </Grid>

          <Grid
            lg={2}
            md={2}
            sm={2}
            style={{
              marginBottom: "10% !important",
              marginTop: "10%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              className="reloadButton1 reloadertripdirect"
              onClick={onHandleReset}
              style={{ width: "97%" }}
            >
              <img src="img/reload.svg" />
            </Button>
          </Grid>
          {AddTrip === true || AddTripdefault === true ? (
            <Grid
              lg={2}
              md={2}
              sm={2}
              style={{
                marginBottom: "10% !important",
                margin: "0 20%",
                marginTop: "10%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                className="reloadButton1 reloadertripdirect"
                onClick={handleOpen}
                style={{ width: "97%" }}
              >
                <AddIcon />
              </Button>
            </Grid>
          ) : (
            <></>
          )}

          <Grid
            lg={2}
            md={2}
            sm={2}
            style={{
              marginBottom: "10% !important",
              marginTop: "10%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              className="reloadButton1 reloadertripdirect"
              onClick={handleOpen2}
              style={{ width: "97%" }}
            >
              <VpnKeyIcon />
            </Button>
          </Grid>

          <PopupCreate2
            title=" إنشاء رحلة"
            setSelectedRow={setSelectedRow}
            setFilteredTrips={setFilteredTrips}
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            setDisplayshortcut1={setShortcut}
          >
            <TripForm
              id_comp={id_company}
              recordForEdit={recordForEdit}
              setOpenPopup={setOpenPopup}
            />
          </PopupCreate2>
          <PopupShortcut
            title="قائمة الاختصارات"
            openPopup={openPopup3}
            setOpenPopup={setOpenPopup3}
            setDisplayshortcut1={setShortcut}
          >
            <Shortcut
              recordForEdit={recordForEdit}
              setOpenPopup={setOpenPopup3}
            />
          </PopupShortcut>
        </Grid>
      </div>
    );
  }
  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns.map((column) => (
          <React.Fragment key={column.dataKey}>
            <TableCell
              variant="head"
              align={column.numeric || false ? "center" : "center"}
              style={{ width: column.width }}
              sx={{
                backgroundColor: "background.paper",
                height: "5vh",
                padding: "0px",
                textAlign: "center",
              }}
            >
              {column.label}
            </TableCell>
          </React.Fragment>
        ))}
      </TableRow>
    );
  }

  const [selectedRow, setSelectedRow] = useState();
  const selectedRowRef = useRef();

  const virtuosoRef = useRef(null);

  function handleRowClick(event, rowId) {
    selectedRowRef.current = rowId;
    setSelectedRow(selectedRowRef.current);
    const serializedRows = localStorage.getItem("rows");

    // Parse the string back to an array
    const rows = serializedRows ? JSON.parse(serializedRows) : null;
    const currentIndex = rows?.findIndex(
      (row) => row.id === selectedRowRef?.current
    );

    localStorage.setItem("branch",rows[currentIndex]?.id_branch);
  }
  async function handleBlocked(id_branch,rowId) {
    
   const data ={
    branch: id_branch,
    id_trip:rowId,
    status:'manual_done',
  }
  sweetalert({
    title: "إنهاء الرحلة",
    text: 'هل أنت متاكد من إنهاء الرحلة',
    icon: "info",
    buttons: {
      confirm: {
          text: "موافق",
          value: true,
          className: "custom-button-class",
      },
      cancel: {
          text: "إلغاء",
          value: false,
          visible: true,
          className: "cancel-button-class",
      }
  },
    dangerMode:true
  }).then((value) => {
    if (value) {
      axios
    .post(`${BaseUrl}/manualDoneStatus`,data ,{
      withCredentials: true,
    })
    .then((response) => {
      if(response.data.code == 200) {
        sweetalert({
          text: "تم إنهاء الرحلة",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      }

    })
    .catch((error) => {

    });
  }
    });
  }
  async function handleLocked(id_branch,rowId) {
    
    const data ={
      branch: id_branch
    }
    sweetalert({
      title: "قفل الرحلة",
      text: 'هل أنت متاكد من قفل الرحلة',
      icon: "info",
      buttons: {
        confirm: {
            text: "موافق",
            value: true,
            className: "custom-button-class",
        },
        cancel: {
            text: "إلغاء",
            value: false,
            visible: true,
            className: "cancel-button-class",
        }
    },
      dangerMode:true
    }).then((value) => {
      if (value) {
      axios
    .post(`${BaseUrl}/updateTripIsLock/${rowId}`,data, {
      withCredentials: true,
    })
    .then((response) => {
      if(response.data.code == 200){
        sweetalert({
          text: "تم قفل الرحلة ",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      }

    })
    .catch((error) => {

    });
  }
    });
  }

  // const handleAdd = () => { 
  //   if (localStorage.getItem("AddReservation") == "true") {

  //     localStorage.setItem("AddReservation", false);
  //   }
  //   else if (localStorage.getItem("AddReservation") == "false") {
  //     // Call the setAddReservation1 function passed as a prop
  //     localStorage.setItem("AddReservation", true);
  //   }
  // };
  // const handleAddManager = () => {
  //   if (localStorage.getItem("AddReservationManager") == "false") {
  //     // Call the setAddReservation1 function passed as a prop
  //     localStorage.setItem("AddReservationManager", true);
  //   }
  // };
  // const handleAddNoData = () => {
  //   if (localStorage.getItem("AddReservationNoData") == "true") {
  //     localStorage.setItem("AddReservationNoData", false);
  //   }
  //   else if (localStorage.getItem("AddReservationNoData") == "false") {
  //     // Call the setAddReservation1 function passed as a prop
  //     localStorage.setItem("AddReservationNoData", true);
  //   }
  // };
  // const handleAddTransit = () => {
  //   if (localStorage.getItem("AddReservationTransit") == "true") {
  //     localStorage.setItem("AddReservationTransit", false);
  //   }
  //   else if (localStorage.getItem("AddReservationTransit") == "false") {
  //     // Call the setAddReservation1 function passed as a prop
  //     localStorage.setItem("AddReservationTransit", true);
  //   }
  // };
  // const handleseatfromtransittodirect = () => {
  //   if (localStorage.getItem("seatfromtransittodirect") == "true") {
  //     localStorage.setItem("seatfromtransittodirect", false);
  //   }
  //   else if (localStorage.getItem("seatfromtransittodirect") == "false") {
  //     // Call the setAddReservation1 function passed as a prop
  //     localStorage.setItem("seatfromtransittodirect", true);
  //   }
  // };
  // const handleDeleteReservation = () => {
  //   if (localStorage.getItem("DeleteReservation") == "true") {
  //     localStorage.setItem("DeleteReservation", false);
  //   }
  //   else if (localStorage.getItem("DeleteReservation") == "false") {
  //     // Call the setAddReservation1 function passed as a prop
  //     localStorage.setItem("DeleteReservation", true);
  //   }
  // };
  // const hadeltransit0 = () => {
  //   if (localStorage.getItem("transit1") == "true") {
  //     localStorage.setItem("transit1", false);
  //   }
  //   else if (localStorage.getItem("transit1") == "false") {
  //     // Call the setAddReservation1 function passed as a prop
  //     localStorage.setItem("transit1", true);
  //   }
  // };
  // const hadeltransit1 = () => {
  //   if (localStorage.getItem("transit2") == "true") {
  //     localStorage.setItem("transit2", false);
  //   }
  //  else  if (localStorage.getItem("transit2") == "false") {
  //     // Call the setAddReservation1 function passed as a prop
  //     localStorage.setItem("transit2", true);
  //   }
  // };
  // const handleTransit = [
  //   hadeltransit0,
  //   hadeltransit1,
  //   // Add more handleTransit functions as needed
  // ];
  // const hadledirect = () => {
  //   if (localStorage.getItem("tripdirect") == "true") {
  //     localStorage.setItem("tripdirect", false);
  //   }
  //   else if (localStorage.getItem("tripdirect") == "false") {
  //     // Call the setAddReservation1 function passed as a prop
  //     localStorage.setItem("tripdirect", true);
  //   }
  // };
  // const handleReservationUpdateData = () => {
  //   if (localStorage.getItem("ReservationUpdateData") == "true") {
  //     localStorage.setItem("ReservationUpdateData", false);
  //   }
  //  else  if (localStorage.getItem("ReservationUpdateData") == "false") {
  //     // Call the setAddReservation1 function passed as a prop
  //     localStorage.setItem("ReservationUpdateData", true);
  //   }
  // };
  // const handleReservationSetAddData = () => {
  //       if (localStorage.getItem("ReservationAvailable") == "true") {
  //     localStorage.setItem("ReservationAvailable", false);
  //   }
  //  else  if (localStorage.getItem("ReservationAvailable") == "false") {
  //     // Call the setAddReservation1 function passed as a prop
  //     localStorage.setItem("ReservationAvailable", true);
  //   }
  // };
  useEffect(() => {},[selectedRow]);
  useEffect(() => {
    localStorage.setItem("row", selectedRowRef.current);
    const rows = serializedRows ? JSON.parse(serializedRows) : null;
    const currentIndex = rows.findIndex(
      (row) => row.id === selectedRowRef.current
    );
    localStorage.setItem("branch",rows[currentIndex]?.id_branch);
    
  }, [selectedRowRef.current]);
  useEffect(() => {
    window.addEventListener("keydown", navigateRows);
    return () => {
      window.removeEventListener("keydown", navigateRows);
    };
  }, []);

  function navigateRows(event) {
    const serializedRows = localStorage.getItem("rows");

    // Parse the string back to an array
    const rows = serializedRows ? JSON.parse(serializedRows) : null;

    if (
      localStorage.getItem("openpassenger") === "false" &&
      localStorage.getItem("changeseat") === "false" &&
      localStorage.getItem("ReservationPhone") == "false" 
    ) {
      const currentIndex = rows.findIndex(
        (row) => row.id === selectedRowRef.current
      );

      if (event.key === "ArrowDown" && currentIndex < rows.length - 1) {
        const nextIndex = currentIndex + 1;
        selectedRowRef.current = rows[nextIndex].id;
        setSelectedRow(selectedRowRef.current);
        localStorage.setItem("row", selectedRowRef.current);
        localStorage.setItem("branch",rows[nextIndex].id_branch);
        if (nextIndex > 4) {
          if (virtuosoRef.current) {
            virtuosoRef.current.scrollToIndex({
              index: nextIndex,
              align: "center",
              behavior: "auto",
            });
          }
        } else {
          event.preventDefault();
        }
      } else if (event.key === "ArrowUp" && currentIndex > 0) {
        const prevIndex = currentIndex - 1;
        selectedRowRef.current = rows[prevIndex].id;
        localStorage.setItem("row", selectedRowRef.current);
        localStorage.setItem("branch",rows[prevIndex].id_branch);
        setSelectedRow(selectedRowRef.current);
        if (prevIndex < rows.length - 5) {
          if (virtuosoRef.current) {
            virtuosoRef.current.scrollToIndex({
              index: prevIndex > 1 ? prevIndex + 1 : prevIndex,
              align: "center",
              behavior: "auto",
            });
          }
        } else {
          event.preventDefault();
        }
      } else if (event.key === "r" || event.key === "R") {
        selectedRowRef.current = rows[0]?.id;
        setSelectedRow(selectedRowRef.current);
        localStorage.setItem("row", selectedRowRef.current);
        localStorage.setItem("branch",rows[0].id_branch);
        const currentIndex = rows.findIndex(
          (row) => row.id === selectedRowRef.current
        );

        if (event.key === "ArrowDown" && currentIndex < rows.length - 1) {
          const nextIndex = currentIndex + 1;
          selectedRowRef.current = rows[nextIndex].id;
          setSelectedRow(selectedRowRef.current);
          localStorage.setItem("row", selectedRowRef.current);
          localStorage.setItem("branch",rows[nextIndex].id_branch);
          if (nextIndex > 4) {
            if (virtuosoRef.current) {
              virtuosoRef.current.scrollToIndex({
                index: nextIndex,
                align: "center",
                behavior: "auto",
              });
            }
          } else {
            event.preventDefault();
          }
        } else if (event.key === "ArrowUp" && currentIndex > 0) {
          const prevIndex = currentIndex - 1;
          selectedRowRef.current = rows[prevIndex].id;
          localStorage.setItem("row", selectedRowRef.current);
          localStorage.setItem("branch",rows[prevIndex].id_branch);
          setSelectedRow(selectedRowRef.current);
          if (prevIndex < rows.length - 5) {
            if (virtuosoRef.current) {
              virtuosoRef.current.scrollToIndex({
                index: prevIndex > 1 ? prevIndex + 1 : prevIndex,
                align: "center",
                behavior: "auto",
              });
            }
          } else {
            event.preventDefault();
          }
        }
      }
    }
  }
  useEffect(() => {
    localStorage.setItem("openpassenger", false);
    localStorage.setItem("changeseat", false);
    // localStorage.setItem("AddReservation", false);
    // localStorage.setItem("AddReservationManager", false);
    // localStorage.setItem("AddReservationNoData", false);
    // localStorage.setItem("AddReservationTransit", false);
    // localStorage.setItem("DeleteReservation", false);
    // localStorage.setItem("ReservationUpdateData", false);
    // localStorage.setItem("ReservationSetAddData", false);
    // localStorage.setItem("transit1", false);
    // localStorage.setItem("transit2", false);
    // localStorage.setItem("seatfromtransittodirect", false);
  }, []);
  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);
  useEffect(() => {
    if (
      localStorage.getItem("openpassenger") === "false" &&
      localStorage.getItem("changeseat") === "false" &&
      localStorage.getItem("ReservationPhone") == "false" 
    ) {
      const handleKeyDown = (event) => {
        if (
          event.key === key_Shortcut.focus_input_seats ||
          event.key === key_Shortcut.focus_input_seats11
        ) {
          event.preventDefault();
          focusInput();
        }
      };
      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [
    localStorage.getItem("openpassenger"),
    localStorage.getItem("changeseat"),
    localStorage.getItem("ReservationPhone")
  ]);
  function rowContent(index, row) {
    return (
      <TableRow
        key={row.id}
        onClick={(event) => handleRowClick(event, row.id)}
        onKeyDown={(event) => handleKeyDown2(event, row.id)}
        
        style={{
          backgroundColor:
            selectedRow === row.id ? "red" : "transparent",
          border: (selectedRow === row.id && openPopup === false) ? "0.4vh solid #f9bb00" : "none",
          outline: (selectedRow === row.id && openPopup === false) ? "0.4vh solid #f9bb00" : "none",
          width: "100vw",
          display: "contents",
        }}
        sx={{
          "& .MuiTableCell-root": {
            //border: selectedRow === row.id ? "0.1vh solid #f9bb00" : "none",
            borderLeft: "none",
            borderRight: "none",
            borderTop:
              (selectedRow === row.id && openPopup === false)
                ? "0.4vh solid #f9bb00"
                : "0.1vh solid #ccc",
            borderBottom:
            (selectedRow === row.id && openPopup === false) ? "0.4vh solid #f9bb00" : "0vh solid #ccc",
          },
        }}
      >
   
        {columns.map((column, i) => (
          <>
            {column.dataKey !== "stations" &&
              column.dataKey !== "actions" &&
              column.dataKey !== "actions1" &&
              column.dataKey !== "finishing" &&
              column.dataKey !== "trip_type" &&
              column.dataKey !== "tripTransit" && (
                <TableCell
                  key={column.dataKey}
                  align={column.numeric || false ? "right" : "left"}
                  sx={{
                    height: "5vh",
                    padding: "0px",
                    textAlign: "center",
                    fontSize: "11px",
                  }}
                >
                  {row[column.dataKey]}
                </TableCell>
              )}
            {column.dataKey === "trip_type" && (
              <TableCell
                key={column.dataKey}
                sx={{
                  height: "5vh",
                  padding: "0px",
                  textAlign: "center",
                  fontSize: "11px",
                }}
              >
                {row[column.dataKey]}
              </TableCell>
            )}

            {column.dataKey === "stations" && (
              <TableCell
                key={column.dataKey}
                sx={{
                  height: "5vh",
                  padding: "0px",
                  textAlign: "center",
                }}
              >
                <div
                  className="autofocusenter"
                  onKeyDown={handleKeyDown2}
                  tabindex={0} // Allow div to be focusable
                >
                  <img
                    src="img/vuesax-linear-eye.svg"
                    onClick={() => {
                      setOpenPopupStop(true);
                      setRecordForEdit(null);
                      setClickedEmployeeId(row.id);
                      dispatch(fetchTripBYId(row.id));
                    }}
                    alt="" // Decorative image
                  />
                </div>
                <PopupStop
                  title="أماكن التوقف"
                  openPopup={openPopupStop}
                  setOpenPopup={setOpenPopupStop}
                >
                  <StopTrip id_Trip={clickedEmployeeId} TripID={TripID} />
                </PopupStop>
              </TableCell>
            )}
            {column.dataKey === "actions" && (
              <TableCell
                key={column.i}
                sx={{
                  height: "5vh",
                  padding: "0px",
                  textAlign: "center",
                }}
              >
                {UpdateTrip === true || UpdateTripdefault === true ? (
                  <Button
                    className={classes.editButton}
                    onClick={() => {
                      handleEditupdd(row.id);
                    }}
                    //  disabled={compareResult < 0 ? true : false}
                  >
                    <img
                      src="img/edit.svg"
                      className={classes.editImg}
                      alt="delete icon"
                    ></img>
                  </Button>
                ) : null}

                <PopupCreate3
                  title="تعديل رحلة"
                  openPopup={editingTripId === row.id} // Popup opens only if this is the trip being edited
                  setOpenPopup={() => setEditingTripId(null)} // Reset the editing ID to null when closing the popup
                  setDisplayshortcut={setShortcut}
                >
                  <TripFormEdit
                    id_Trip={clickedEmployeeId}
                    idcompany={id_company}
                    TripID={TripID}
                    recordForEdit={recordForEdit11}
                  />
                </PopupCreate3>

                {(SoftDeleteTrip === true ||
                  SoftDeleteTripdefault === true) && (
                  <Button
                    className={classes.editButton}
                    onClick={() => handleDelete(row.id, row.id_branch)} // Make sure `branch_id` is the correct property from your `row` object

                  >
                    <img
                      src="img/delete.svg"
                      className={classes.editImg}
                      alt="delete icon"
                    />
                  </Button>
                )}

                <ConfirmDialog
                  confirmDialog={confirmDialog}
                  setConfirmDialog={setConfirmDialog}
                />
              </TableCell>
            )}
            {column.dataKey === "actions1" && (
              <TableCell
                key={column.i}
                sx={{
                  height: "5vh",
                  padding: "0px",
                  textAlign: "center",
                }}
              >
                {manualDoneStatus === true || manualDoneStatusdefult === true  ? (
                  <Tooltip title="إنهاء الرحلة يدويا">

                  <Button
                    className="blocked"
                    onClick={() => {
                      handleBlocked(row.id_branch,row.id);
                    }}
                    //  disabled={compareResult < 0 ? true : false}
                  >
                   <BlockIcon />
                  </Button>
                  </Tooltip>
                ) : null}


                {(UpdateTripLocked === true ||
                  UpdateTripLockeddefult === true) && (
                    <Tooltip title="قفل الرحلة">

                  <Button
                    className="locked"
                    onClick={() => handleLocked(row.id_branch,row.id)} // Make sure `branch_id` is the correct property from your `row` object

                  >
                   <LockIcon/>
                  </Button>
                  </Tooltip>
                )}

              </TableCell>
            )}
            {column.dataKey === "finishing" && (
              <TableCell
                key={column.dataKey}
                align={column.align}
                sx={{
                  height: "5vh",
                  padding: "0px",
                  textAlign: "center",
                  fontSize: "11px",
                }}
              >
                {column.renderCell({ row, field: column.dataKey })}
              </TableCell>
            )}
            {column.dataKey === "tripTransit" && (
              <TableCell
                key={column.dataKey}
                align={column.numeric || false ? "right" : "left"}
                sx={{
                  height: "5vh",

                  padding: "0px",
                  textAlign: "center",
                  fontSize: "11px",
                }}
              >
                {row[column.dataKey].length > 0 ? (
                  row[column.dataKey].map((transit, index) => (
                    <div key={index}>
                      {transit.name} -- {transit.time}
                    </div>
                  ))
                ) : (
                  <p> </p>
                )}
              </TableCell>
            )}
          </>
        ))}
      </TableRow>
    );
  }

  return (
    <>
      <div style={{ display: "flex", width: "100%" }}>
        <Paper
          style={{ height: "35.7vh", overflow: "auto" }}
          className="rightbox"
        >
          <TableVirtuoso
            data={filteredTripes}
            ref={virtuosoRef}
            totalCount={filteredTripes.length}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
          />
        </Paper>
        <div style={{ paddingRight: "1%" }} className="leftbox">
          <EditToolbar />
        </div>
      </div>

      {show && (
        <>
          {/* <Box>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              style={{
                marginTop: "1%",
                boxShadow: "0px 3px 6px rgba(0,0,0,0.6)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "98.6%",
              }}
            >
              <Grid
                item
                xs={9}
                md={9}
                sm={9}
                style={{
                  paddingRight: "10px",
                  fontSize: "12px",
                  display: "flex",
                }}
              >
                <span style={{ marginLeft: "3%" }}>{pathTrip}</span>
                <Grid
                  item
                  style={{
                    fontSize: "12px",
                    display: "flex",
                  }}
                >
                  <Grid
                    item
                    style={{
                      fontWeight: "bold",
                      marginRight: "2vw",
                    }}
                  >
                    إضافة :{" "}
                  </Grid>
                  <Grid
                    item
                    className="button-setting"
                    onClick={() => {
                      handleAdd();
                    }}
                  >
                    حجز
                  </Grid>
                  <Grid
                    item
                    className="button-setting"
                    onClick={() => {
                      handleAddManager();
                    }}
                  >
                    إدارة
                  </Grid>
                  <Grid
                    item
                    className="button-setting"
                    onClick={() => {
                      handleAddNoData();
                    }}
                  >
                    بدون بيانات
                  </Grid>
                </Grid>

                <Grid
                  item
                  style={{
                    fontSize: "12px",
                    display: "flex",
                  }}
                >
                  <Grid
                    item
                    style={{
                      fontWeight: "bold",
                      marginRight: "2vw",
                    }}
                  >
                    تعديل :{" "}
                  </Grid>
                  <Grid
                    item
                    className="button-setting"
                    onClick={() => {
                      handleReservationUpdateData();
                    }}
                  >
                    حجز
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    fontSize: "12px",
                    display: "flex",
                  }}
                >
                  <Grid
                    item
                    style={{
                      fontWeight: "bold",
                      marginRight: "2vw",
                    }}
                  >
                    تحويل :{" "}
                  </Grid>
                  <Grid
                    item
                    className="button-setting"
                    onClick={() => {
                      handleseatfromtransittodirect();
                    }}
                  >
                    تحويل لاساسي
                  </Grid>
                  <Grid
                    item
                    className="button-setting"
                    onClick={() => {
                      handleReservationSetAddData();
                    }}
                  >
                    {" "}
                    تحويل لمتوفر
                  </Grid>
                  {TripID?.tripTransit && TripID?.tripTransit.length > 0 ? (
                    <Grid
                      item
                      className="button-setting"
                      onClick={() => {
                        handleAddTransit();
                      }}
                    >
                      {" "}
                      تحويل لعبور
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid
                  item
                  style={{
                    fontSize: "12px",
                    display: "flex",
                  }}
                >
                  <Grid
                    item
                    style={{
                      fontWeight: "bold",
                      marginRight: "2vw",
                    }}
                  >
                    حذف :{" "}
                  </Grid>
                  <Grid
                    item
                    className="button-setting"
                    onClick={() => {
                      handleDeleteReservation();
                    }}
                  >
                    حجز
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                sm={6}
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={6}
                  md={6}
                  sm={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      padding: "0px 5px",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    الرحلات
                  </p>
                  {TripID && (
                    <React.Fragment>
                     
                      <div
                        style={{ height: "25px", cursor: "pointer" }}
                        className="tiptransitdiv"
                        onClick={hadledirect}
                      >
                        {TripID.info}
                      </div>
                      -
                      {TripID.tripTransit?.map((transit, transitIndex) => (
                        <React.Fragment key={transitIndex}>
                          <div
                            style={{ height: "25px", cursor: "pointer" }}
                            className="tiptransitdiv"
                            onClick={() => handleTransit[transitIndex]()}
                          >
                            {transit.name}
                          </div>
                          {transitIndex < TripID.tripTransit.length - 1
                            ? "-"
                            : ""}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  )}
                </Grid>

                <Grid
                  item
                  xs={6}
                  md={6}
                  sm={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      padding: "0px 5px",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    رقم المقاعد
                  </p>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    sm={6}
                    style={{ padding: "1% 0px" }}
                    className="input-seats"
                  >
                    <input
                      style={{ width: "90%" }}
                      ref={inputRef}
                      onChange={handleChange} 
                      value={valueinput}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box> */}
          <Grid item xs={12} md={12} sm={12}>
            <Box className="">
              {loadingtrip ? (
                <div className="spinner1-box">
                  <svg class="spinner1" viewBox="0 0 50 50">
                    <circle
                      class="path1"
                      cx="25"
                      cy="25"
                      r="20"
                      fill="none"
                      stroke-width="5"
                    ></circle>
                  </svg>
                </div>
              ) : (
                <SetRed
                  setNewStatus={setNewStatus}
                  setAddReservation1={setAddReservation1}
                  AddReservation1={AddReservation1}
                  setNewStatusAvail={setNewStatusAvail}
                  newStatus={newStatus}
                  newStatusAvail={newStatusAvail}
                  show={show}
                  id_company={id_company}
                  nofocus={inputRef.current}
                  typetrip={TripID.trip_type}
                  id_trip={idTrip}
                  setShortcut={setShortcut}
                  setShowPassenger={setShowPassenger}
                  setNumberPassenger={setNumberPassenger}
                  numberpassenger={numberpassenger}
                  openPopuppassenger={openPopuppassenger}
                  setOpenPopuppassenger={setOpenPopuppassenger}
                  valueinput={valueinput}
                  setValueinput={setValueinput}
                  setPathtrip={setPathtrip}
                  schortcut={schortcut}
                  setdisabledenter={setdisabledenter}
                  disabledenter={disabledenter}
                  setSelectedRow={setSelectedRow}
                />
              )}
            </Box>
          </Grid>
        </>
      )}
    </>
  );
}
