import React, { useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { fetchAllCity } from "../../redux/CitySlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { useState } from "react";
import Controls from "../../component/controls/Controls";
import * as sweetalert from "sweetalert";

import Switch from "@mui/material/Switch";
import axios from "axios";
import packageJson from "../../../package.json";
export const BaseUrl = packageJson.projectURlApi.BaseUrl;
function ToggleISActive({ id_employee,setISActive,ISActive }) {
  const { loading, Cities, error } = useSelector((state) => state.City);
  const { CityEmployeeID, loadingAdd } = useSelector(
    (state) => state.CityEmployee
  );

  const [checkedCities, setCheckedCities] = useState([]); // State to manage checked cities
  





  const handleSave  =  () => {
    const data = {
      id_user: id_employee,
      id_city: checkedCities,
    };
     axios
    .get(`${BaseUrl}/updateIsActive/${id_employee}`, {
      withCredentials: true,
    })
    .then((response) => {
      setISActive(response.data.is_active)

      sweetalert({
        text: "تم التعديل بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "إغلاق",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch((error) => {

    });
    
  };
  useEffect(() => {setISActive(ISActive)},[ISActive,setISActive]);

  return (
    <div className="checkreservation">
      <FormGroup>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row-reverse",
            justifyContent:'center',
          }}
        >

                         
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={ISActive == 1}
                                  onChange={handleSave}
                                  color="warning"
                                />
                              }
                              label="إسناد"
                            />
                            
        </Grid>
      </FormGroup>
    
    </div>
  );
}

export default ToggleISActive;
