import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchTripsSchedular = createAsyncThunk(
  "TripsSchedular/fetchTripsSchedular",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllTripStatic/${id}`, {
        withCredentials: true,
      });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchTripBYIdStatic = createAsyncThunk(
  "TripsSchedular/fetchTripBYIdStatic",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getTripStatic/${id}`, {
        withCredentials: true,
      });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchTripBYIdReservation = createAsyncThunk(
  "TripsSchedular/fetchTripBYIdReservation",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getTrip/${id}`, {
        withCredentials: true,
      });
      const reservations = await res.data.data.reservations;
      const countpassanger = await res.data.data.reservations.map(
        (rese) => rese.count_passenger
      );
      return reservations;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const insertTripStatic = createAsyncThunk(
  "TripsSchedular/insertTripStatic",
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/addTripStatic`, values, { withCredentials: true });
      sweetalert({
        text: "تم الإضافة بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        localStorage.removeItem('trip_schedular_2')
        window.location.reload();
      });
    }  catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } 
      else if (error.response && error.response.status === 500) {
        // Handle the 500 error server   error specifically
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage = error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);
export const insertTripSchedular = createAsyncThunk(
  "TripsSchedular/insertTripSchedular",
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/schedulingTrips`, values, { withCredentials: true });
      sweetalert({
        text: "تم الإضافة بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        localStorage.setItem('trip','[]');
        localStorage.setItem('dates','[]');
        localStorage.setItem('removeIdSeatType','false');
        window.location.reload();
      });
    }  catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } 
      else if (error.response && error.response.status === 500) {
        // Handle the 500 error server   error specifically
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage = error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateTrip = createAsyncThunk(
  "TripsSchedular/updateTrip",
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/updateTrip/${values.id}`, values, {
        withCredentials: true,
      });
      sweetalert({
        text: "تم التعديل بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteTripStatic = createAsyncThunk(
  "TripsSchedular/deleteTripStatic",
      async ({ id, branch_id }, thunkAPI) => { 
        const { rejectWithValue } = thunkAPI;
        try {
          const response = await axios.delete(`${BaseUrl}/hardDeleteTripStatic/${id}?branch=${branch_id}`, {
            withCredentials: true,
          });
      if (response.data && response.data.status == 200 ) {
        sweetalert({
          text: "تم الحذف بنجاح",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      } 
      else {
        // Handle other responses if needed
        // For example, you can display an error message for failed deletion
        // You can access the specific error message from the response's "data" object
        sweetalert({
          text: response.data.data.original.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteReservation = createAsyncThunk(
  "reservations/deleteReservation",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/deleteReservation/${id}`, {
        withCredentials: true,
      });
      sweetalert({
        text: "تم الحذف بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
      // Return the deleted reservation ID
      return { id };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const TripSlice = createSlice({
  name: "TripSchedular",
  initialState: {
    TripsSchedulars: [],
    TripIDSchedular: [],
    InsertTripsSchedulars: [],
    TripReservation: [],
    TripStatic: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearTripScheluerState: (state) => {
      state.TripsSchedulars = [];
      state.InsertTripsSchedulars = [];
      state.TripIDSchedular = [];
      state.TripReservation = [];
      state.TripStatic = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: {
    //fetch
    [fetchTripsSchedular.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchTripsSchedular.fulfilled]: (state, action) => {
      state.TripsSchedulars = action.payload;
      state.loading = false;
    },
    [fetchTripsSchedular.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //fetch
    [fetchTripBYIdStatic.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchTripBYIdStatic.fulfilled]: (state, action) => {
      state.TripIDSchedular = action.payload;
      state.loading = false;
    },
    [fetchTripBYIdStatic.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //fetch Reservation
    [fetchTripBYIdReservation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchTripBYIdReservation.fulfilled]: (state, action) => {
      state.TripReservation = action.payload;
      state.loading = false;
    },
    [fetchTripBYIdReservation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //insert trip static
    [insertTripStatic.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [insertTripStatic.fulfilled]: (state, action) => {
      state.TripStatic = action.payload;
      state.loading = false;
    },
    [insertTripStatic.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //insert trip Schedular
    [insertTripSchedular.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [insertTripSchedular.fulfilled]: (state, action) => {
      state.InsertTripsSchedulars = action.payload;
      state.loading = false;
    },
    [insertTripSchedular.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //delete  reservation in trip
    [deleteReservation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deleteReservation.fulfilled]: (state, action) => {
     
      state.TripReservation = state.TripReservation.filter(
        (el) => el.id !== action.payload.id
      );


      state.loading = false;
    },
    [deleteReservation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //delete trip static
    [deleteTripStatic.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deleteTripStatic.fulfilled]: (state, action) => {
        if (action.payload && action.payload.id) {
          state.TripsSchedulars = state.TripsSchedulars.filter((el) => el.id !== action.payload.id);
        }
        state.loading = false;
    },
    [deleteTripStatic.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export const { clearTripScheluerState } = TripSlice.actions;
export default TripSlice.reducer;
