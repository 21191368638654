import React, { useState, useEffect, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";

import { makeStyles } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
const useStyles = makeStyles((theme) => ({}));

const genderItems = [
  { id: "male", title: "Male" },
  { id: "female", title: "Female" },
  { id: "other", title: "Other" },
];

const initialFValues = {
  id: 0,
  fullName: "",
  email: "",
  mobile: "",
  city: "",
  gender: "male",
  departmentId: "",
  hireDate: new Date(),
  isPermanent: false,
};

export default function EmployeeForm(props) {
  const classes = useStyles();
  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("fullName" in fieldValues)
      temp.fullName = fieldValues.fullName ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    if ("mobile" in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required.";
    if ("departmentId" in fieldValues)
      temp.departmentId =
        fieldValues.departmentId.length != 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  return (
    <Form onSubmit={handleSubmit}>
      {/* <Grid container> */}
      <Grid item xs={12}>
        <Controls.Input
          className="tesr"
          name="fullName"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/lock.svg"></img>
                </IconButton>
                <span className="placeholdertitle">كلمة المرور الحالية</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.fullName}
          onChange={handleInputChange}
          error={errors.fullName}
        />
        <Controls.Input
          className="tesr"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/lock.svg"></img>
                </IconButton>
                <span className="placeholdertitle">كلمة المرور الجديدة</span>
              </InputAdornment>
            </Fragment>
          }
          name="password"
          value={values.password}
          onChange={handleInputChange}
          error={errors.password}
        />
        <Controls.Input
          className="tesr"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/lock.svg"></img>
                </IconButton>
                <span className="placeholdertitle">
                  تاكيد كلمة المرور الجديدة
                </span>
              </InputAdornment>
            </Fragment>
          }
          name="password"
          value={values.password}
          onChange={handleInputChange}
          error={errors.password}
        />

        <div className="bottonFullwidth">
          <Controls.Button type="submit" text="تعديل" />
        </div>
      </Grid>
    </Form>
  );
}
