import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";

export function useForm(initialFValues, validateOnChange = false, validate) {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "typeNotification") {
      if (value === "notificationTrip") {
        setValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      } else if (value === "notificationClients ") {
        setValues((prevValues) => ({
          ...prevValues,
          [name]: value,
          id_trip: "",
        }));
      }
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

    if (validateOnChange) {
      validate({ [name]: value });
    }
  };
  const handleInputChange11 = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleInputChange11,
    resetForm,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRaduis: "8px !important",
    },
    "& .MuiFormControl-root": {
      width: "100% ",
      marginBottom: "40px",
    },
    "& .MuiOutlinedInput-root:hover": {},

    "& .MuiInputLabel-outlined": {
      transform: "translate(0px, 27px) scale(1) !important",
    },
    "@media (min-width:768px) and (max-width:1556px)": {
      "& .MuiInputLabel-outlined": {
        transform: "translate(0px, 19px) scale(1) !important",
      },
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(1) !important",
      fontSize: "10px !important",
    },
    "& .price-ticket-trip-ticket  .MuiInputLabel-shrink": {
      transform: "translate(1px, -6px) scale(1) !important",
      fontSize: "10px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #000 ",
    },
  },
}));

export function Form(props) {
  const classes = useStyles();
  const { children, ...other } = props;
  return (
    <form className={classes.root} autoComplete="off" {...other}>
      {props.children}
    </form>
  );
}
