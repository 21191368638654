import React, { useState, useEffect, Fragment } from "react";
import { Grid, TextField } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "./useForm";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useDispatch } from "react-redux";
import { insertTrip } from "../../redux/tripsSlice";
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  clearTripTypeState,
  fetchTripTypeById,
  fetchTripTypeByIdfilter,
  fetchTripTypeByIdfilter2,
} from "../../redux/tripTypesSlice";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "antd";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as sweetalert from "sweetalert";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { InputLabel } from "@mui/material";
import InputRow from "./inputRow";
import { insertTripStatic } from "../../redux/tripsSchedularSlice";
export default function TripForm(props) {
  const [value, setValue] = React.useState(null);
  const [date1, setDate1] = React.useState(null);

  const dispatch = useDispatch();
  const initialFValues = {
    driver_name: "",
    driver_assistant_name: "",
    date: "",
    time_h: "",
    time_m: "",
    id_trip_type: "",
    id_seat_type: "",
    id_branch: "",
    type: "",
    transit_seats: [],
    transit_seats_input: "",
    transit_count_seats: 0,
  };
  const { CompaniesData } = useSelector((state) => state.CompanyData);
  const { filteredBranch } = useSelector((state) => state.TripType);
  const { loading } = useSelector((state) => state.Trip_Schedular);
  const [dateError, setDateError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [selectedValue, setSelectedValue] = React.useState("direct");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "type",
    inputProps: { "aria-label": item },
  });
  const handleCreate = () => {
    setSelectedValue("a");
  };
  const handleExport = () => {};

  // =========generation trip pass =================================================================
  const [initialFValues1, setIinitialFValues1] = useState([
    {
      id: 1,
      order: 1,
      id_trip_type: "",
      time_h: "",
      time_m: "",
    },
  ]);

  const handleRemove = (id) => {
    setIinitialFValues1((prevValues) =>
      prevValues.filter((row) => row.id !== id)
    );
  };
  const handleAdd = () => {
    setIinitialFValues1([
      ...initialFValues1,
      {
        id: initialFValues1.length + 1, // Generate a unique ID for the new row
        order: initialFValues1.length + 1, // Increment the order
        id_trip_type: "",
        time_h: "",
        time_m: "",
      },
    ]);
  };

  // =========generation trip pass =================================================================
  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("driver_name" in fieldValues) {
      if (!fieldValues.driver_name) {
        temp.driver_name = "هذا الحقل مطلوب";
      } else if (!/^[\p{L}\s]+$/u.test(fieldValues.driver_name)) {
        temp.driver_name = "يرجى إدخال أحرف فقط";
      } else {
        temp.driver_name = "";
      }
    }
    if ("driver_assistant_name" in fieldValues) {
      if (!fieldValues.driver_assistant_name) {
        temp.driver_assistant_name = "هذا الحقل مطلوب";
      } else if (!/^[\p{L}\s]+$/u.test(fieldValues.driver_assistant_name)) {
        temp.driver_assistant_name = "يرجى إدخال أحرف فقط";
      } else {
        temp.driver_assistant_name = "";
      }
    }

    if ("date" in fieldValues)
      temp.date = fieldValues.date ? "" : "هذا الحقل مطلوب";

    if ("id_bus" in fieldValues)
      temp.id_bus = fieldValues.id_bus ? "" : "هذا الحقل مطلوب";
    if ("id_branch" in fieldValues)
      temp.id_branch = fieldValues.id_branch ? "" : "هذا الحقل مطلوب";
    if ("id_trip_type" in fieldValues)
      temp.id_trip_type = fieldValues.id_trip_type ? "" : "هذا الحقل مطلوب";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value != null) {
      const currentDate = new Date();
      const cuttentDate = dayjs(currentDate).format("YYYY-MM-DD");
      const selectedDate = values.date;

      if (selectedDate !== null && value !== null) {
        if (values.type === "direct") {
          const data = {
            time_h: values.time_h,
            time_m: values.time_m,
            id_trip_type: values.id_trip_type,
            branch: values.id_branch,
            id_branch: values.id_branch,
            type: values.type,
            transit: {
              trip: initialFValues1,
              transit_seats: values.transit_seats,
              transit_count_seats: values.transit_count_seats,
            },
          };
          dispatch(insertTripStatic(data)).then(() => {
            dispatch(clearTripTypeState());
          });
        } else {
          const data = {
            time_h: values.time_h,
            time_m: values.time_m,
            id_trip_type: values.id_trip_type,
            id_branch: values.id_branch,
            branch: values.id_branch,
            type: values.type,
            transit: initialFValues1,
            transit_seats: values.transit_seats,
            transit_count_seats: values.transit_count_seats,
          };
          dispatch(insertTripStatic(data)).then(() => {
            dispatch(clearTripTypeState());
          });
        }
      } else if (!selectedDate) {
        sweetalert({
          text: "يرجى اختيار التاريخ",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        sweetalert({
          text: " يرجى اختيار التاريخ أكبر او يساوي التاريخ الحالي",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
    }
    if (date1 == null) {
      setDateError("يرجى اختيار تاريخ الرحلة");
    }
    if (value == null) {
      setTimeError("يرجى اختيار توقيت الرحلة");
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  const handleChange1 = async (e) => {
    const tripTypeId = e.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      id_trip_type: e.target.value,
      trip_price: "", // Clear the trip_price field when selecting a new trip type
    }));
    dispatch(fetchTripTypeByIdfilter2(e.target.value));
  };
  const handleAddSeat = (e) => {
    const newSeats = e.target.value.split(",").map((s) => Number(s.trim()));
    setValues({
      ...values,
      transit_seats: newSeats,
      transit_seats_input: "",
    });
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Grid className="createdTrip">
        <Grid item xs={12} md={12} sm={12}>
          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row-reverse",
            }}
          >
            <Grid lg={12} md={12} sm={12}>
              <Grid
                lg={12}
                md={12}
                sm={12}
                style={{ display: "flex", flexDirection: "row-reverse" }}
              >
                <Grid lg={6} md={6} sm={6}>
                  <Controls.SelectTrip
                    className="tesr122"
                    name="id_trip_type"
                    label={
                      <Fragment>
                        <InputAdornment position="end">
                          <IconButton
                            className="iconplaceholder"
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <img src="img/create1.svg"></img>
                          </IconButton>
                          <span className="placeholdertitle">مسار الرحلة</span>
                        </InputAdornment>
                      </Fragment>
                    }
                    value={values.id_trip_type}
                    onChange={handleChange1}
                    error={errors.id_trip_type}
                    options={CompaniesData.trip_type}
                  />
                </Grid>
                <Grid lg={6} md={6} sm={6} className="trip-select-type">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-form-control-label-placement"
                      name="type"
                      onChange={handleInputChange}
                      style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      <FormControlLabel
                        value="transit"
                        control={
                          <Radio
                            {...controlProps("transit")}
                            sx={{
                              color: "#f9b917",
                              "&.Mui-checked": {
                                color: "#f9b917",
                              },
                            }}
                          />
                        }
                        label="عبور"
                        labelPlacement="start"
                        onClick={handleExport}
                      />
                      <FormControlLabel
                        value="direct"
                        control={
                          <Radio
                            sx={{
                              color: "#f9b917",
                              "&.Mui-checked": {
                                color: "#f9b917",
                              },
                            }}
                          />
                        }
                        label="مباشر"
                        labelPlacement="start"
                        onClick={handleCreate}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                lg={12}
                md={12}
                sm={12}
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  gap: "10px",
                }}
              >
                <Grid item xs={6} md={6} sm={6}>
                  <Controls.SelectBranch
                    className="tesr122"
                    name="id_branch"
                    label={
                      <Fragment>
                        <InputAdornment position="end">
                          <IconButton
                            className="iconplaceholder"
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <img src="img/create1.svg"></img>
                          </IconButton>
                          <span className="placeholdertitle">فروع الشركة</span>
                        </InputAdornment>
                      </Fragment>
                    }
                    value={values.id_branch}
                    onChange={handleInputChange}
                    error={errors.id_branch}
                    options={filteredBranch || []}
                  />
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={6} md={6} sm={6} className="timetrip">
                    <FormControl fullWidth className="timediv">
                      <InputLabel htmlFor="time-picker">
                        اختر توقيت الرحلة
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          size="large"
                          format="HH:mm"
                          title="اختر التاريخ"
                          onChange={(newValue) => {
                            setValue(newValue);
                            const currentTimehours =
                              dayjs(newValue).format("HH");
                            const currentTimeminute =
                              dayjs(newValue).format("mm");
                            setValues((prevValues) => ({
                              ...prevValues,
                              time_h: currentTimehours,
                              time_m: currentTimeminute,
                            }));
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid>
                {selectedValue == "transit" ? (
                  <>
                    <hr></hr>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontSize: "25px", padding: "10px 0px" }}>
                        رحلات العبور
                      </p>
                      <p
                        style={{
                          fontSize: "20px",
                          padding: "10px 0px",
                          color: "#f9b917",
                          cursor: "pointer",
                        }}
                        onClick={handleAdd}
                      >
                        إضافة رحلة عبور <AddCircleOutlineIcon />
                      </p>
                    </Grid>
                    {initialFValues1.map((item, index) => (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        style={{
                          display: "flex",
                          marginBottom: "40px",
                          marginTop: "10px",
                        }}
                      >
                        <Grid item xs={12} md={12} key={index}>
                          <InputRow
                            key={index}
                            initialFValues={initialFValues1}
                            setIinitialFValues1={setIinitialFValues1}
                            index={index}
                            item={item}
                            handleChange={handleChange}
                            handleRemove={() => handleRemove(item.id)}
                            handleAdd={handleAdd}
                          />
                        </Grid>
                      </Grid>
                    ))}
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item xs={5} md={5} lg={5}>
                        <TextField
                          id="outlined-textarea"
                          label={
                            <Fragment>
                              <span id="number1">{"عدد المقاعد"}</span>
                            </Fragment>
                          }
                          name="transit_count_seats"
                          multiline
                          variant="outlined"
                          value={values.transit_count_seats}
                          onChange={handleInputChange}
                          error={errors.transit_count_seats}
                        />
                      </Grid>
                      <Grid item xs={5} md={5} lg={5}>
                        <TextField
                          id="outlined-textarea"
                          label={
                            <Fragment>
                              <span id="number1">{"ارقام  المقاعد"}</span>
                            </Fragment>
                          }
                          name="transit_seats_input"
                          multiline
                          variant="outlined"
                          onChange={(e) => handleAddSeat(e)}
                          error={errors.transit_seats_input}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12} md={12}>
            {loading ? (
              <div
                className="bottonFullwidth"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="load loadingupdate"
                  style={{ background: "#F9B917", borderRaduis: "10px" }}
                >
                  <div v-if="loading" class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="bottonFullwidth"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Controls.Button type="submit" text="إضافة" />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}
