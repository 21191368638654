import React, { useState, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { insertCompany } from "../../redux/companyTransportDataSlice";
import { useSelector } from "react-redux";
export default function CompanyAddForm(props) {
  const dispatch = useDispatch();
  const { loadingAdd} = useSelector((state) => state.CompanyData);
  const [file, setFile] = useState("");
  const initialFValues = {
    name_ar: "",
    description: "",
    social_media: {
      WhatsApp: "",
      facebook: "",
    },
    logo: "",
    profit: {
      profit_percent: 0,
      platform_to_company: 0,
      company_to_platform: 0,
      platform_client_reduction: 0,
      platform_system_reduction: 0,
      max_profit: 0,
    },
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name_ar" in fieldValues)
      temp.name_ar = fieldValues.name_ar ? "" : "هذا الحقل مطلوب";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
  
    if (name.includes("profit.")) {
      // This handles nested properties like "profit.profit_percent"
      const [parent, child] = name.split(".");
      setValues((prevValues) => ({
        ...prevValues,
        [parent]: {
          ...prevValues[parent],
          [child]: value,
        },
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("name_ar", values.name_ar);
      formData.append("description", values.description);
      formData.append("social_media[WhatsApp]", values.social_media.WhatsApp);
      formData.append("social_media[facebook]", values.social_media.facebook);

      formData.append("profit[profit_percent]", values.profit.profit_percent);
      formData.append("profit[platform_to_company]", values.profit.platform_to_company);
      formData.append("profit[company_to_platform]", values.profit.company_to_platform);
      formData.append("profit[platform_client_reduction]", values.profit.platform_client_reduction);
      formData.append("profit[platform_system_reduction]", values.profit.platform_system_reduction);
      formData.append("profit[max_profit]", values.profit.max_profit);
      formData.append("logo", values.logo);
      dispatch(insertCompany(formData));
    }
  };
  const handleImageChange = (e) => {
    setValues({ ...values, logo: e.target.files[0] });
    setFile(e.target.files[0]);
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Controls.Input
          className="tesr"
          name="name_ar"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/office/vuesax-linear-building.svg"></img>
                </IconButton>
                <span className="placeholdertitle"> اسم الشركة </span>
              </InputAdornment>
            </Fragment>
          }
          value={values.name_ar}
          onChange={handleInputChange}
          error={errors.name_ar}
        />
        <Controls.Input
          className="tesr"
          name="description"
          multiline
          minRows={2}
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/office/vuesax-linear-building.svg"></img>
                </IconButton>
                <span className="placeholdertitle">وصف الشركة</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.description}
          onChange={handleInputChange}
          error={errors.description}
        />

        <Controls.Input
          className="tesr"
          name="social_media.WhatsApp"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/office/vuesax-linear-call-calling.svg"></img>
                </IconButton>
                <span className="placeholdertitle">حساب الواتساب</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.social_media.WhatsApp}
          onChange={(e) => {
            const { name, value } = e.target;
            if (name.startsWith("social_media.")) {
              const nestedFieldName = name.split(".")[1];
              setValues((prevValues) => ({
                ...prevValues,
                social_media: {
                  ...prevValues.social_media,
                  [nestedFieldName]: value,
                },
              }));
            } else {
              setValues((prevValues) => ({
                ...prevValues,
                [name]: value,
              }));
            }
          }}
        />
        <Controls.Input
          className="tesr"
          name="social_media.facebook"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/office/vuesax-linear-call-calling.svg"></img>
                </IconButton>
                <span className="placeholdertitle">حساب الفيس بوك</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.social_media.facebook}
          onChange={(e) => {
            const { name, value } = e.target;
            if (name.startsWith("social_media.")) {
              const nestedFieldName = name.split(".")[1];
              setValues((prevValues) => ({
                ...prevValues,
                social_media: {
                  ...prevValues.social_media,
                  [nestedFieldName]: value,
                },
              }));
            } else {
              setValues((prevValues) => ({
                ...prevValues,
                [name]: value,
              }));
            }
          }}
        />
        <Controls.Input
          className="tesr"
          name="profit.profit_percent"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/office/vuesax-linear-building.svg"></img>
                </IconButton>
                <span className="placeholdertitle">نسبة الربح</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.profit.profit_percent}
          onChange={handleInputChange1}
         
        />
        <Controls.Input
          className="tesr"
          name="profit.platform_to_company"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/office/vuesax-linear-building.svg"></img>
                </IconButton>
                <span className="placeholdertitle">نسبة الربح من المنصة للشركة</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.profit.platform_to_company}
          onChange={handleInputChange1}
        
        />
        <Controls.Input
          className="tesr"
          name="profit.company_to_platform"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/office/vuesax-linear-building.svg"></img>
                </IconButton>
                <span className="placeholdertitle">نسبة الربح من الشركة للمنصة</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.profit.company_to_platform}
          onChange={handleInputChange1}
         
        />
        <Controls.Input
          className="tesr"
          name="profit.platform_client_reduction"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/office/vuesax-linear-building.svg"></img>
                </IconButton>
                <span className="placeholdertitle">
                  نسبة الخصم عند الغاء الحجز من قبل الزبون
                </span>
              </InputAdornment>
            </Fragment>
          }
          value={values.profit.platform_client_reduction}
          onChange={handleInputChange1}
 
        />
        <Controls.Input
          className="tesr"
          name="profit.platform_system_reduction"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/office/vuesax-linear-building.svg"></img>
                </IconButton>
                <span className="placeholdertitle">
                نسبة الخصم عند الغاء الحجز من قبل النظام
                </span>
              </InputAdornment>
            </Fragment>
          }
          value={values.profit.platform_system_reduction}
          onChange={handleInputChange1}

        />
        <Controls.Input
          className="tesr"
          name="profit.max_profit"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/office/vuesax-linear-building.svg"></img>
                </IconButton>
                <span className="placeholdertitle">الربح الكلي</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.profit.max_profit}
          onChange={handleInputChange1}
        />
        <Grid item xs={12}>
          <Controls.Input
            type="file"
            name="logo"
            className="logo"
            label={
              <Fragment>
                <InputAdornment position="end">
                  <IconButton
                    className="iconplaceholder"
                    aria-label="upload image"
                    edge="end"
                  >
                    <img src="img/emp/profile-circle.svg" alt="Profile" />
                  </IconButton>
                  <span className="placeholdertitle">الصورة</span>
                </InputAdornment>
              </Fragment>
            }
            values={file}
            onChange={handleImageChange}
            error={errors.logo}
            inputProps={{
              accept: "image/*",
            }}
          />
          {values.logo && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "10px",
                width: "100%",
              }}
            >
              <div style={{ width: "100px", height: "100px" }}>
                <img
                  src={URL.createObjectURL(file)}
                  alt="company-image"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          )}
        </Grid>
        
        {loadingAdd  ? (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="load loadingupdate"
              style={{ background: "#F9B917", borderRaduis: "10px" }}
            >
              <div v-if="loading" class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
              </div>
            </div>
          </div>
        ) : (
          <div className="bottonFullwidth"  style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Controls.Button type="submit" text="إضافة" />
          </div>
        )}

      </Grid>
    </Form>
  );
}
