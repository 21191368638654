import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchCompany = createAsyncThunk(
  'Company/fetchCompany',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllCompanies` ,{ withCredentials: true });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
  
);



const CompanySlice = createSlice({
  name: 'Company',
  initialState: { Companies: [], loading: false, error: null },

    reducers: {
      clearCompany: (state) => {
        state.Companies = [];
        state.loading = false;
        state.error = null;
      },
    },
  
  extraReducers: {
    //fetch
    [fetchCompany.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchCompany.fulfilled]: (state, action) => {
      state.Companies = action.payload;
      state.loading = false;
    },
    [fetchCompany.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export const { clearCompany } = CompanySlice.actions;

export default CompanySlice.reducer;
