import { useLocation, useNavigate } from "react-router-dom";
import { tokens } from "../../styles/theme";
import React, { useEffect, useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { Grid } from "@material-ui/core";
import PopupCreate from "../../component/PopupCreate";
import { Select, MenuItem } from "@material-ui/core";
import ConfirmDialog from "../../component/ConfirmDialog.js";
import { fetchAllReservation } from "../../redux/reservation.jsx";
import { DataGridPro } from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import {
  GridFooterContainer,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridPagination,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../../component/controls/Controls";
import PopupStop from "../../component/PopupStop";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTrip,
  fetchTripBYId,
  fetchTripBYIdReservation,
  fetchTrips,
} from "../../redux/tripsSlice";
import { fetchCompany } from "../../redux/companyTransportSlice";
import { fetchCompanyById } from "../../redux/companyTransportDataSlice";
import { fetchTripTypeById } from "../../redux/tripTypesSlice";
import { fetchPaths } from "../../redux/PathSlice";
import {
  fetchDisableAccount,
  insertActiveAccount,
} from "../../redux/ActiveClientAcoount";
import ConfirmDialog2 from "../../component/ConfirmDialog2";
import SearchBar from "material-ui-search-bar";
export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function CustomFooter() {
  return (
    <GridFooterContainer>
      <CustomPagination />
      <GridPagination />
    </GridFooterContainer>
  );
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    flexDirection: "row-reverse",
    paddingRight: "8px",
    height: "38px important",
    maxHeight: "38px important",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
    marginRight: "3px",
  },
  "@media (min-width: 600px) and (max-width:1800px)": {
    searchDesign: {
      height: "38px important",
    },
  },
  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "38px !important",
    borderRadius: "5px !important",
  },
  editButton: {
    display: "none",
    flexDirection: "row-reverse",
    color: "#000002 !important",
    fontSize: "20px",
    fontFamily: "beIN",
  },
  deleteButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#F9B917 !important",
    fontSize: "20px ",
    fontFamily: "beIN",
  },
  editImg: {
    width: "15px",
  },
  Marginbox1: {
    maxWidth: "100% !important",
    maxHeight: "38px !important",
    flexBasis: "100%",
  },
});

export default function DeactiveTable() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDisableAccount());
  }, [dispatch]);
  const { user } = useSelector((state) => state.auth);
  //Add Permission

  const acyiveaccount =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "activate.client.account"
    );

  // Permission as default
  const acyiveaccountdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "activate.client.account"
    );

  const location = useLocation();
  const id_comp = location.state; // Print the state value

  const { ActiveAccount } = useSelector((state) => state.DisableAccount);

  const [filteredData, setFilteredData] = useState(ActiveAccount);

  const classes = useStyles();
  const [age, setAge] = useState("all");
  const [searched, setSearched] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [clickedEmployeeId, setClickedEmployeeId] = useState(null);
  useEffect(() => {
    if (id_comp !== null) {
      dispatch(fetchCompany());
      dispatch(fetchCompanyById(id_comp));
    }
  }, [dispatch, setFilteredData]);

  function handleE(id) {
    setConfirmDialog({
      isOpen: true,
      title: "أنت على وشك تفعيل هذا الحساب",
      img: "img/emp/Inbox cleanup-rafiki.svg",
      onConfirm: () => {
        dispatch(insertActiveAccount(id));
      },
    });
  }

  function filterTrips(ActiveAccount) {
    if (searched) {
      return ActiveAccount.filter((active) => active.phone.includes(searched));
    } else {
      return ActiveAccount;
    }
  }

  const filteredTrips = filterTrips(ActiveAccount);

  const onHandleReset = () => {
    setSearched("");
  };
  function EditToolbar(props) {
    return (
      <Grid
        container
        xs={12}
        className={classes.Marginbox1}
        style={{ marginBottom: "10px" }}
      >
        <Grid lg={3} md={6} sm={6} className="search-deactive-user">
          <SearchBar
            className={classes.searchDesign}
            value={searched}
            onChange={(newValue) => setSearched(newValue)}
            onCancelSearch={() => setSearched("")}
            autoFocus
            style={{ height: "38px" }}
          />
        </Grid>
        <Grid lg={1} md={1} sm={3} className={classes.AddDesign}>
          <Button className="reloadButton1" onClick={onHandleReset}>
            <img src="img/reload.svg" />
          </Button>
        </Grid>
      </Grid>
    );
  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const triggerText = "تعديل";
  const triggerText1 = "اضافة قيمة";

  const notesEditorOptions = { height: 100 };
  const processRowUpdate = (newRow) => {};
  const getPageSize = () => {
    if (window.innerWidth < 992) {
      return 5; // Set a smaller pageSize for smaller screens
    } else if (window.innerWidth < 1566) {
      return 7; // Default pageSize for larger screens
    } else if (window.innerWidth < 1760) {
      return 8; // Default pageSize for larger screens
    } else if (window.innerWidth < 1930) {
      return 13; // Default pageSize for larger screens
    }
  };
  useEffect(() => {
    getPageSize();
  });
  const columns = [
    {
      field: "id",
      sortable: false,
      headerName: "",
      resizable: true,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phone",
      sortable: false,
      headerName: "رقم الموبايل",
      resizable: true,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerAlign: "center",
      resizable: true,
      minWidth: 150,
      flex: 1,
      sortable: false,
      headerName: "",
      align: "center",
      renderCell: (params) => {
        const id = params.row.id;
        return (
          <Box>
            {acyiveaccount === true || acyiveaccountdefault === true ? (
              <Button
                className={classes.editButton}
                onClick={() => {
                  handleE(id);
                }}
              >
                <span style={{ color: "red" }}>تفعيل</span>
              </Button>
            ) : (
              <></>
            )}

            <ConfirmDialog2
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <Box
        className="box-triptype"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },

          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "none",
            fontSize: "15px !important",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "15px !important",
            color: `${colors.primary[1000]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.white[100],
          },
        }}
      >
        <DataGridPro
          rows={filteredTrips || []}
          columns={columns}
          className="field-table"
          editMode="row"
          pageSize={getPageSize()}
          pagination
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          components={{
            Toolbar: EditToolbar,
            Footer: CustomFooter,
          }}
          localeText={{
            footerRowSelected: CustomPagination,
          }}
        />
      </Box>
    </Box>
  );
}
