import React, { useState, useEffect, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { OfficeCompanyById, updateOfficeCompany } from "../../redux/Office";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function OfficeEditForm(props) {
  const dispatch = useDispatch();
  const { loading, office, loadingupdate } = useSelector(
    (state) => state.Offices
  );
  const { Cities } = useSelector((state) => state.City);
  useEffect(() => {
    if (props.id_brance) dispatch(OfficeCompanyById(props.id_brance));
  }, [props.id_brance]);

  const initialFValues = {
    name_ar: office.name_ar,
    address: office.address,
    phone_number: office.phone_number,
    mobile: office.mobile,
    minute_reduction: office.minute_reduction,
    id_company: props.id_company,
    id_city: office.id_city,
    id_brance: props.id_brance,
    brance: props.id_brance,
  };

  useEffect(() => {
    if (office.id === props.id_brance) {
      setValues({
        ...initialFValues,
        name_ar: office.name_ar,
        address: office.address,
        phone_number: office.phone_number,
        mobile: office.mobile,
        minute_reduction: office.minute_reduction,
        id_company: props.id_company,
        id_city: office.id_city,
        id_brance: props.id_brance,
        brance: props.id_brance,
      });
    }
  }, [office, props.id_brance]);
  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name_ar" in fieldValues)
      temp.name_ar = fieldValues.name_ar ? "" : "هذا الحقل مطلوب";
    if ("minute_reduction" in fieldValues)
      temp.minute_reduction = fieldValues.minute_reduction ? "" : "هذا الحقل مطلوب";
    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "هذا الحقل مطلوب";
    if ("id_city" in fieldValues)
      temp.id_city = fieldValues.id_city ? "" : "هذا الحقل مطلوب";
    if ("phone_number" in fieldValues) {
      if (!fieldValues.phone_number) {
        temp.phone_number = "هذا الحقل مطلوب";
      } else {
        temp.phone_number = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const valuesWithBranch = {
        ...values,
        branch:values.id_brance, // This adds the branch key to your values object
      };
      dispatch(updateOfficeCompany(valuesWithBranch));
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);
  return (
    <>
      {loading ? (
        <div className="spinner1-box">
          <svg class="spinner1" viewBox="0 0 50 50">
            <circle
              class="path1"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <Controls.Input
              className="tesr"
              name="name_ar"
              InputLabelProps={{ shrink: true }}
              label={
                <Fragment>
                  <InputAdornment position="end">
                    <IconButton
                      className="iconplaceholder"
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <img src="img/office/vuesax-linear-building.svg"></img>
                    </IconButton>
                    <span className="placeholdertitle"> اسم المكتب </span>
                  </InputAdornment>
                </Fragment>
              }
              value={values.name_ar}
              onChange={handleInputChange}
              error={errors.name_ar}
            />
            <Controls.Input
              className="tesr"
              name="phone_number"
              InputLabelProps={{ shrink: true }}
              label={
                <Fragment>
                  <InputAdornment position="end">
                    <IconButton
                      className="iconplaceholder"
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <img src="img/office/vuesax-linear-call-calling.svg"></img>
                    </IconButton>
                    <span className="placeholdertitle">رقم الهاتف الأول</span>
                  </InputAdornment>
                </Fragment>
              }
              value={values.phone_number}
              onChange={handleInputChange}
              error={errors.phone_number}
            />
            <Controls.Input
              className="tesr"
              InputLabelProps={{ shrink: true }}
              name="mobile"
              label={
                <Fragment>
                  <InputAdornment position="end">
                    <IconButton
                      className="iconplaceholder"
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <img src="img/office/vuesax-linear-call-calling.svg"></img>
                    </IconButton>
                    <span className="placeholdertitle">رقم الهاتف الثاني</span>
                  </InputAdornment>
                </Fragment>
              }
              value={values.mobile}
              onChange={handleInputChange}
              error={errors.mobile}
            />
                    <Controls.Input
          className="tesr"
          name="minute_reduction"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/typeTrip/3.svg" alt="img-trip-time"></img>
                </IconButton>
                <span className="placeholdertitle">المدة الذي يحق خلالها إلغاء الحجز بالدقيقة</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.minute_reduction}
          onChange={handleInputChange}
          error={errors.minute_reduction}
        />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label1">
                <div
                  style={{
                    background: "white",
                    width: "72px",
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "20px",
                    height: "19px",
                  }}
                >
                  <img src="img/office/vuesax-linear-building.svg"></img>{" "}
                  <span
                    className="placeholdertitle"
                    style={{
                      display: "flex",
                      justify: "center",
                      alignItems: "center",
                      paddingRight: "5px",
                    }}
                  >
                    المحافظة
                  </span>
                </div>
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.id_city || ""}
                name="id_city"
                onChange={(e) => {
                  const selectedPath = e.target.value;
                  setValues((prevValues) => ({
                    ...prevValues,
                    id_city: selectedPath,
                  }));
                }}
              >
                {Array.isArray(Cities) ? (
                  Cities.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name_ar}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">Loading cities...</MenuItem>
                )}
              </Select>
            </FormControl>
            <Controls.Input
              InputLabelProps={{ shrink: true }}
              name="address"
              label={
                <Fragment>
                  <InputAdornment position="end">
                    <IconButton
                      className="iconplaceholder"
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <img src="img/office/vuesax-linear-building.svg"></img>{" "}
                    </IconButton>
                    <span className="placeholdertitle">العنوان</span>
                  </InputAdornment>
                </Fragment>
              }
              value={values.address}
              onChange={handleInputChange}
              error={errors.address}
            />
            {loadingupdate ? (
                 <div  className="bottonFullwidth" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>

                 <div
                className="load loadingupdate"
                style={{ background: "#F9B917", borderRaduis: "10px" }}
                >
                <div v-if="loading" class="spinner">
                  <div class="rect1"></div>
                  <div class="rect2"></div>
                  <div class="rect3"></div>
                  <div class="rect4"></div>
                  <div class="rect5"></div>
                </div>
              </div>
                </div>
            ) : (
              <div className="bottonFullwidth"style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Controls.Button type="submit" text="تعديل" />
              </div>
            )}
          </Grid>
        </Form>
      )}
    </>
  );
}
