import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchAllTrashedSystemUser = createAsyncThunk(
  'Employee/fetchAllTrashedSystemUser',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllTrashedSystemUserForCompany/${id}`, { withCredentials: true });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchAllTrashedSystemUserAdmin = createAsyncThunk(
  'Employee/fetchAllTrashedSystemUserAdmin',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllTrashedSystemUser`, { withCredentials: true });
      const result = await res.data.data;
      const adminUsers = result.filter(user => user.roles === 'Admin');

      return adminUsers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchEmployee = createAsyncThunk(
  'Employee/fetchEmployee',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllSystemUsers`, { withCredentials: true });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchEmployeeCompany = createAsyncThunk(
  'Employee/fetchEmployeeCompany',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllSystemUsersForCompany/${id}`, { withCredentials: true });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchEmployeeCompanyAdmin = createAsyncThunk(
  'Employee/fetchEmployeeCompanyAdmin',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllAdmins`, { withCredentials: true });
      const result = await res.data.data;

      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchEmployeeCompanyAdminName = createAsyncThunk(
  'Employee/fetchEmployeeCompanyAdminName',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllAdmins`, { withCredentials: true });
      const result = await res.data.data;
      const first_name = result.map(name => name.first_name);
      return first_name;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchEmployeeCompanyAdminBranch = createAsyncThunk(
  'Employee/fetchEmployeeCompanyAdminBranch',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllAdmins`, { withCredentials: true });
      const result = await res.data.data;
      const branch_name = result.filter(employee => employee.branch_name !== "");
      const branch_name1 = branch_name.map(name => name.branch_name);
      return branch_name1;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchEmployeeByID = createAsyncThunk(
  'Employee/fetchEmployeeByID',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getSystemUser/${id}`, { withCredentials: true });
      const result = await res.data.data;

      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchEmployeeByName = createAsyncThunk(
  'Employee/fetchEmployeeByName',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllSystemUsersForCompany/${id}`, { withCredentials: true });
      const result = await res.data.data;
      const first_name = result.map(name => name.first_name);
      return first_name;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchEmployeeByBranch = createAsyncThunk(
  'Employee/fetchEmployeeByBranch',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllSystemUsersForCompany/${id}`, { withCredentials: true });
      const result = await res.data.data;
      const branch_name = result.filter(employee => employee.branch_name !== "");
      const branch_name1 = branch_name.map(name => name.branch_name);
      const uniqueBranchNames = [...new Set(branch_name1)];
      return uniqueBranchNames;

    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const insertEmployee = createAsyncThunk(
  'Employee/insertEmployee',
  async (values, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`${BaseUrl}/addSystemUser`, values, {
        withCredentials: true,
      });

      if (res.status === 200) {
        sweetalert({
          text: res.data.success,
          icon: 'success',
          buttons: {
            confirm: {
              text: 'تم',
              className: 'custom-button-class',
            },
          },
          className: 'alert-div',
        }).then(() => {
          window.location.reload();

        });
      }
    } catch (error) {
      if (error.response) {
        // Handle validation errors
        if (error.response.data && Array.isArray(error.response.data.data)) {
          const errorMessages = error.response.data.data.join('\n');

          if (errorMessages == `The email has already been taken.`) {
            sweetalert({
              title: '',
              text: 'البريد الالكتروني موجود مسبقاً',
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'OK',
                  className: 'custom-button-class',
                },
              },
              className: 'alert-div',
            });

          } else if (errorMessages == `The national id has already been taken.`) {
            sweetalert({
              title: '',
              text: 'الرقم الوطني موجود مسبقاً',
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'OK',
                  className: 'custom-button-class',
                },
              },
              className: 'alert-div',
            });
          } else {
            sweetalert({
              title: '',
              text: 'البريد الالكتروني والرقم الوطني موجود مسبقاً',
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'OK',
                  className: 'custom-button-class',
                },
              },
              className: 'alert-div',
            });
          }

        } else {
          return rejectWithValue(error.response.data); // Reject the promise with the response error data
        }
      } else {
        return rejectWithValue({ message: 'An error occurred' }); // Reject the promise with a default error message
      }
    }
  }
);

export const RestoreEmployee = createAsyncThunk(
  'Employee/RestoreEmployee',
  async (id, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/restoreSystemUser/${id}`, { withCredentials: true });
      if (res.status === 200) {
        sweetalert({
          text: res.data.msg,
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      } else {
        sweetalert({
          text: res.data.msg,
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data); // Reject the promise with the response error data
      } else {
        return rejectWithValue({ message: "An error occurred" }); // Reject the promise with a default error message
      }
    }
  }
);
export const UpdateEmployee = createAsyncThunk(
  'Employee/UpdateEmployee',
  async (values, thunkAPI) => {
    const id = values.get('id'); // Access the 'id' property from the FormData object

    const { rejectWithValue, getState } = thunkAPI;
    try {
      const res = await axios.post(`${BaseUrl}/updateSystemUser/${id}`, values, { withCredentials: true });
      if (res.status === 200) {
        sweetalert({
          text: res.data.original.message,
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      } else {
        sweetalert({
          text: res.data.success,
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data); // Reject the promise with the response error data
      } else {
        return rejectWithValue({ message: "An error occurred" }); // Reject the promise with a default error message
      }
    }
  }
);
export const PasswordUpdateEmployee = createAsyncThunk(
  'Employee/PasswordUpdateEmployee',
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const res = await axios.post(`${BaseUrl}/updateSystemUser/${values.user_id}`, values, { withCredentials: true });
      if (res.data.original.status === true) {
        sweetalert({
          text: res.data.original.message,
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      } else {
        sweetalert({
          text: "تم التعديل بنجاح",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data); // Reject the promise with the response error data
      } else {
        return rejectWithValue({ message: "An error occurred" }); // Reject the promise with a default error message
      }
    }
  }
);


export const deleteEmployeeById = createAsyncThunk(
  'Employee/deleteEmployeeById',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.delete(`${BaseUrl}/softDeleteSystemUser/${id}`, { withCredentials: true });
      if (res.data && res.data.data.status === true && res.data.data.msg) {
        sweetalert({
          text: res.data.data.msg,
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const HarddeleteEmployeeById = createAsyncThunk(
  'Employee/HarddeleteEmployeeById',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.delete(`${BaseUrl}/hardDeleteSystemUser/${id}`, { withCredentials: true });
      if (res.data && res.data.status === true && res.data.msg) {
        sweetalert({
          text: res.data.msg,
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const EmployeeSlice = createSlice({
  name: 'Employee',
  initialState: { Employeeies: [], TrashedEmployeeies: [], names: [], nameAdmin: [], branchAdmin: [], Branches: [], EmployeeiesByID: [], Admins: [], InsertEmployeeies: [], UpdateEmployeeies: [], Passwordupdate: [], EmployeeCompany: [], EmployeeAdmin: [], loading: false, loadingAdd: false, loadingUpdate: false, loadingdelete: false, loadingpassword: false, loadingAddcompany: false, error: null },
  reducers: {
    clearEmployeeState: (state) => {
      state.Employeeies = [];
      state.InsertEmployeeies = [];
      state.names = [];
      state.Branches = [];
      state.EmployeeiesByID = [];
      state.TrashedEmployeeies = [];
      state.Admins = [];
      state.names = [];
      state.branchAdmin = [];
      state.nameAdmin = [];
      state.UpdateEmployeeies = [];
      state.Passwordupdate = [];
      state.EmployeeCompany = [];
      state.EmployeeAdmin = [];
      state.loading = false;
      state.loadingAdd = false;
      state.loadingUpdate = false;
      state.loadingdelete = false;
      state.loadingpassword = false;
      state.loadingAddcompany = false;

      state.error = null;
    },
  },
  extraReducers: {
    //fetch All trashed employees
    [fetchAllTrashedSystemUser.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchAllTrashedSystemUser.fulfilled]: (state, action) => {
      state.TrashedEmployeeies = action.payload;
      state.loading = false;
    },
    [fetchAllTrashedSystemUser.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //fetch All the employees
    [fetchEmployee.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchEmployee.fulfilled]: (state, action) => {
      state.Employeeies = action.payload;
      state.loading = false;
    },
    [fetchEmployee.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //fetch All the employees Admin
    [fetchEmployeeCompanyAdmin.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchEmployeeCompanyAdmin.fulfilled]: (state, action) => {
      state.EmployeeAdmin = action.payload;
      state.loading = false;
    },
    [fetchEmployeeCompanyAdmin.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //fetch All the employees Admin Name
    [fetchEmployeeCompanyAdminName.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchEmployeeCompanyAdminName.fulfilled]: (state, action) => {
      state.nameAdmin = action.payload;
      state.loading = false;
    },
    [fetchEmployeeCompanyAdminName.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //fetch employees by Company

    [fetchEmployeeCompany.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchEmployeeCompany.fulfilled]: (state, action) => {
      state.EmployeeCompany = action.payload;
      state.loading = false;
    },
    [fetchEmployeeCompany.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    //fetch All the employees by Id
    [fetchEmployeeByID.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchEmployeeByID.fulfilled]: (state, action) => {
      state.EmployeeiesByID = action.payload;
      state.loading = false;
    },
    [fetchEmployeeByID.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    //fetch All the employees by name
    [fetchEmployeeByName.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchEmployeeByName.fulfilled]: (state, action) => {
      state.names = action.payload;
      state.loading = false;
    },
    [fetchEmployeeByName.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //fetch All the employees by branch
    [fetchEmployeeByBranch.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchEmployeeByBranch.fulfilled]: (state, action) => {
      state.Branches = action.payload;
      state.loading = false;
    },
    [fetchEmployeeByBranch.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    //fetch All the Admin trush 
    [fetchAllTrashedSystemUserAdmin.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchAllTrashedSystemUserAdmin.fulfilled]: (state, action) => {
      state.Admins = action.payload;
      state.loading = false;
    },
    [fetchAllTrashedSystemUserAdmin.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //insert System Users
    [insertEmployee.pending]: (state, action) => {
      state.loadingAdd = true;
      state.error = null;
    },
    [insertEmployee.fulfilled]: (state, action) => {
      state.InsertEmployeeies.push(action.payload);
      state.loadingAdd = false;
    },
    [insertEmployee.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingAdd = false;
    },
    //update System Users
    [UpdateEmployee.pending]: (state, action) => {
      state.loadingUpdate = true;
      state.error = null;
    },
    [UpdateEmployee.fulfilled]: (state, action) => {
      state.UpdateEmployeeies.push(action.payload);
      state.loadingUpdate = false;
    },
    [UpdateEmployee.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingUpdate = false;
    },
    //update System Users password
    [PasswordUpdateEmployee.pending]: (state, action) => {
      state.loadingpassword = true;
      state.error = null;
    },
    [PasswordUpdateEmployee.fulfilled]: (state, action) => {
      state.Passwordupdate.push(action.payload);
      state.loadingpassword = false;
    },
    [PasswordUpdateEmployee.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingpassword = false;
    },
    //Soft delete Systems User
    [deleteEmployeeById.pending]: (state, action) => {
      state.loadingdelete = true;
      state.error = null;
    },
    [deleteEmployeeById.fulfilled]: (state, action) => {
      const deletedEmployeeId = action.meta.arg; // Access the ID from the action metadata
      state.Employeeies = state.Employeeies.filter((el) => el.id !== deletedEmployeeId);
      state.loadingdelete = false;

    },

    [deleteEmployeeById.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingdelete = false;
    },
    //Hard delete Systems User
    [HarddeleteEmployeeById.pending]: (state, action) => {
      state.loadingdelete = true;
      state.error = null;
    },
    [HarddeleteEmployeeById.fulfilled]: (state, action) => {
      const deletedEmployeeId = action.meta.arg; // Access the ID from the action metadata
      state.Employeeies = state.Employeeies.filter((el) => el.id !== deletedEmployeeId);
      state.loadingdelete = false;
    },

    [HarddeleteEmployeeById.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingdelete = false;
    },
  },
});
export const { clearEmployeeState } = EmployeeSlice.actions;
export default EmployeeSlice.reducer;

