import { Grid, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { useForm } from "../../component/useForm";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { InputLabel } from "@mui/material";
import { TimePicker } from "antd";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import CancelIcon from "@mui/icons-material/Cancel";
import Controls from "../../component/controls/Controls";
import InputAdornment from "@mui/material/InputAdornment";
import { useSelector } from "react-redux";
function InputRowupdate({
  index,
  item,
  handleChange,
  handleRemove,
  initialFValues,
  setIinitialFValues1,
  handleAdd,
}) {
  const [imageUrl, setImageUrl] = useState();
  const [selectedImage1, setSelectedImage1] = useState();
  const [dateError, setDateError] = useState(""); // Error message for date
  const [date1, setDate1] = React.useState(null);
  const [value, setValue] = React.useState(null);
  const { CompaniesData } = useSelector((state) => state.CompanyData);
  const renderInput = (params) => <TextField {...params} name="date" />;
  const handleDateChange = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setDate1(formattedDate);
    const updatedValues = [...values];
    updatedValues[index] = {
      ...updatedValues[index],
      date: formattedDate,
    };
    setValues(updatedValues);
    setIinitialFValues1((prevInitialFValues) =>
      prevInitialFValues.map((obj) =>
        obj.id === prevInitialFValues[index].id
          ? {
              ...obj,
              date: formattedDate,
            }
          : obj
      )
    );

    setDateError("");
  };
  useEffect(() => {
    if (selectedImage1) {
      setImageUrl(URL.createObjectURL(selectedImage1));
    }
  }, [selectedImage1]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("Add_certifcation" in fieldValues) {
      if (fieldValues.Add_certifcation == "")
        temp.Add_certifcation = "This field is required.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
    }
  };

  const handleInputChange11 = (e, id) => {
    const { name, value } = e.target;
    setValues((prevValues) =>
      prevValues.map((obj) => (obj.id === id ? { ...obj, [name]: value } : obj))
    );

    setIinitialFValues1((prevInitialFValues) =>
      prevInitialFValues.map((obj) =>
        obj.id === id ? { ...obj, [name]: value } : obj
      )
    );
  };

  const { values, setValues, errors, setErrors, resetForm } = useForm(
    initialFValues,
    true,
    validate
  );
  useEffect(() => {
    if (initialFValues && initialFValues.length > 0) {
      const updatedValues = initialFValues.map((value) => ({
        ...value,
        date: value.date ? dayjs(value.date).format("YYYY-MM-DD") : null,
      }));
      setValues(updatedValues);
    }
  }, [initialFValues,setValues]);
  const [timeValue, setTimeValue] = useState(
    initialFValues[index].time_h
      ? dayjs(
          `${initialFValues[index].time_h}:${
            initialFValues[index].time_m || "00"
          }`,
          "HH:mm"
        )
      : null
  );

  useEffect(() => {
    // Update the timeValue state when initialFValues changes
    if (initialFValues[index].time_h) {
      setTimeValue(
        dayjs(
          `${initialFValues[index].time_h}:${
            initialFValues[index].time_m || "00"
          }`,
          "HH:mm"
        )
      );
    }
  }, [initialFValues, index]);

  const handleTimeChange = (newValue) => {
    setTimeValue(newValue); // Update local state
    // Now propagate changes up to the parent state
    if (newValue) {
      const currentTimehours = newValue.format("HH");
      const currentTimeminute = newValue.format("mm");
      setIinitialFValues1((prevInitialFValues) =>
        prevInitialFValues.map((obj, idx) =>
          idx === index
            ? { ...obj, time_h: currentTimehours, time_m: currentTimeminute }
            : obj
        )
      );
    } else {
      // Handle null newValue (time cleared)
      setIinitialFValues1((prevInitialFValues) =>
        prevInitialFValues.map((obj, idx) =>
          idx === index ? { ...obj, time_h: "", time_m: "" } : obj
        )
      );
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <Grid
        item
        xs={12}
        lg={12}
        md={12}
        sm={12}
        style={{ display: "flex", gap: "15px", flexDirection: "row-reverse" }}
      >
        <Grid item xs={3} md={3} lg={3}>
          <Controls.SelectTrip
            className="tesr122"
            name="id_trip_type"
            label={
              <Fragment>
                <InputAdornment position="end">
                  <span className="placeholdertitle">مسار الرحلة</span>
                </InputAdornment>
              </Fragment>
            }
            value={initialFValues[index].id_trip_type}
            onChange={(e) =>
              setIinitialFValues1((prevValues) =>
                prevValues.map((obj, i) =>
                  i === index ? { ...obj, id_trip_type: e.target.value } : obj
                )
              )
            }
            options={CompaniesData.trip_type}
          />
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          lg={6}
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-evenly",
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={5} md={5} sm={5} className="datetrip">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  // label="اختر تاريخ الرحلة"
                  value={initialFValues[index].date}
                  onChange={handleDateChange}
                  renderInput={renderInput}
                  views={["year", "month", "day"]}
                />
                {dateError && (
                  <p style={{ color: "red", fontSize: "13px" }}>{dateError}</p>
                )}
              </LocalizationProvider>
            </Grid>
            <Grid item xs={5} md={5} sm={5} className="timetrip timetrip2">
              <FormControl fullWidth className="timediv timetrip1">
                <InputLabel htmlFor="time-picker" shrink>
                  اختر توقيت الرحلة
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    size="large"
                    value={timeValue}
                    onChange={handleTimeChange}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>

        <div
          className="box-button-remove-trip-pass"
          onClick={() => handleRemove(index)}
        >
          <CancelIcon />
        </div>
      </Grid>
    </div>
  );
}

export default InputRowupdate;
// import { Grid, TextField } from "@material-ui/core";
// import React, { useState, useEffect } from "react";
// import { Fragment } from "react";
// import { useForm } from "../../component/useForm";
// import "date-fns";
// import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { InputLabel } from "@mui/material";
// import { TimePicker } from "antd";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import FormControl from "@mui/material/FormControl";
// import CancelIcon from "@mui/icons-material/Cancel";
// import Controls from "../../component/controls/Controls";
// import InputAdornment from "@mui/material/InputAdornment";
// import IconButton from "@mui/material/IconButton";
// import { useSelector } from "react-redux";
// import dayjs from "dayjs";

// function InputRowupdate({
//   index,
//   initialFValues,
//   setIinitialFValues1,
//   handleRemove,
// }) {
//   const { CompaniesData } = useSelector((state) => state.CompanyData);
//   const renderInput = (params) => <TextField {...params} name="date" />;

//   const handleDateChange = (newValue) => {
//     const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
//     const updatedValues = [...initialFValues];
//     updatedValues[index] = {
//       ...updatedValues[index],
//       date: formattedDate,
//     };
//     setIinitialFValues1(updatedValues);
//   };

//   useEffect(() => {
//     // Update date format in initialFValues
//     const updatedValues = initialFValues.map((value) => ({
//       ...value,
//       date: value.date ? dayjs(value.date).format("YYYY-MM-DD") : null,
//     }));
//     setIinitialFValues1(updatedValues);
//   }, [initialFValues, setIinitialFValues1]);

//   return (
//     <div style={{ width: "100%" }}>
//       <Grid
//         item
//         xs={12}
//         lg={12}
//         md={12}
//         sm={12}
//         style={{ display: "flex", gap: "15px", flexDirection: "row-reverse" }}
//       >
//         <Grid item xs={3} md={3} lg={3}>
//           <Controls.SelectTrip
//             className="tesr122"
//             name="id_trip_type"
//             label={
//               <Fragment>
//                 <InputAdornment position="end">
//                   <span className="placeholdertitle">مسار الرحلة</span>
//                 </InputAdornment>
//               </Fragment>
//             }
//             value={initialFValues[index].id_trip_type}
//             onChange={(e) =>
//               setIinitialFValues1((prevValues) =>
//                 prevValues.map((obj, i) =>
//                   i === index ? { ...obj, id_trip_type: e.target.value } : obj
//                 )
//               )
//             }
//             options={CompaniesData.trip_type}
//           />
//         </Grid>
//         <Grid
//           item
//           xs={6}
//           md={6}
//           lg={6}
//           style={{
//             display: "flex",
//             flexDirection: "row-reverse",
//             justifyContent: "space-evenly",
//           }}
//         >
//           <MuiPickersUtilsProvider utils={DateFnsUtils}>
//             <Grid item xs={5} md={5} sm={5} className="datetrip">
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <DatePicker
//                   label="اختر تاريخ الرحلة"
//                   value={initialFValues[index].date}
//                   onChange={handleDateChange}
//                   renderInput={renderInput}
//                   views={["year", "month", "day"]}
//                 />
//               </LocalizationProvider>
//             </Grid>
//             <Grid item xs={5} md={5} sm={5} className="timetrip timetrip2">
//               <FormControl fullWidth className="timediv timetrip1">
//                 <InputLabel htmlFor="time-picker">اختر توقيت الرحلة</InputLabel>
//                 <TimePicker
//                   size="large"
//                   title="اختر التاريخ"
//                   // value={`${initialFValues[index].time_h}`}
//                   onChange={(newValue) =>
//                     setIinitialFValues1((prevValues) =>
//                       prevValues.map((obj, i) =>
//                         i === index
//                           ? {
//                               ...obj,
//                               time_h: dayjs(newValue).format("HH"),
//                               time_m: dayjs(newValue).format("mm"),
//                             }
//                           : obj
//                       )
//                     )
//                   }
//                 />
//               </FormControl>
//             </Grid>
//           </MuiPickersUtilsProvider>
//         </Grid>
//         <div className="box-button-remove-trip-pass" onClick={() => handleRemove(index)}>
//           <CancelIcon />
//         </div>
//       </Grid>
//     </div>
//   );
// }

// export default InputRowupdate;
