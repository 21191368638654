import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import { useNavigate } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupBus from "../../../component/PopupBus";
import BusFormEdit from "../BusFormEdit";
import ConfirmDialog from "../../../component/ConfirmDialogBus";
import { useDispatch } from "react-redux";
import { deleteBus } from "../../../redux/busSlice";
import { fetchCompanyById } from "../../../redux/companyTransportDataSlice";
import { useSelector } from "react-redux";
import { fetchSeateTypeAll } from "../../../redux/SeatsTypeSlice";
const useStyles = makeStyles({
  root: {
    minWidth: 200,
    marginBottom: "20px !important",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 !important",
    position: "relative",
  },
  media: {
    width: 126,
    height: 126,
    marginTop: "15px",
  },
  title: {
    fontFamily: "beIN Normal",
    fontSize: 27,
    color: "#000002",
    fontWeight: "normal !important",
  },
  "@media (min-width:768px) and (max-width:1200px)": {
    title: {
      fontSize: 12, // Adjust the font size for smaller screens
      fontWeight: "lighter !important",
    },
  },
  "@media (min-width:1200px) and (max-width:1800px)": {
    title: {
      fontSize: 14, // Adjust the font size for smaller screens
      fontWeight: "lighter !important",
    },
  },
});

const Buses = ({ startIndex, endIndex, Bus, id_company , loading}) => {
  const { user } = useSelector((state) => state.auth);
  const { loadingdelete } = useSelector((state) => state.Bus);

  //Add Permission

  const UpdateBus =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "update.bus");

  user.PermissionsNames.some((permission) => permission.includes("update.bus"));
  const DeleteBus =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "hard.delete.bus"
    );

  // Permission as default

  const UpdateBusdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some((permission) => permission === "update.bus");

  const DeleteBusdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "hard.delete.bus"
    );

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bull = <span className={classes.bullet}>•</span>;
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopupPassword, setOpenPopupPassword] = useState(false);
  const [openPopupEdit, setOpenPopupEdit] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [clickedBusTypeId, setClickedBusTypeId] = useState(null);
  useEffect(() => {
    // dispatch(fetchCompanyById(id_company));
  }, [dispatch, id_company]);
  function handleEdit() {
    setOpenPopupEdit(true);
    setRecordForEdit(null);
    dispatch(fetchSeateTypeAll(id_company));
  }
  function handlePassword() {
    setOpenPopupPassword(true);
    setRecordForEdit(null);
  }
  function handleDelete() {
    setConfirmDialog({
      isOpen: true,
      title: "انت على وشك حذف هذا الباص",
      img: "img/Bus driver-pana.svg",
      clas: "clas",
      onConfirm: () => {
        dispatch(deleteBus(clickedBusTypeId));
      },
    });
  }
  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <>
          {loading ? (
          <div className="spinner1-box">
            <svg class="spinner1" viewBox="0 0 50 50">
              <circle
                class="path1"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          </div>
        ) : (
      Bus &&
        Bus.slice(startIndex, endIndex).map((bus) => (
          <Card className={classes.root} variant="outlined">
            <CardContent className={classes.content}>
              {UpdateBus === true ||
              UpdateBusdefault === true ||
              DeleteBus === true ||
              DeleteBusdefault === true ? (
                <div className="card-menu1">
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                      setClickedBusTypeId(bus.id);
                    }}
                  >
                    <img src="img/More icon.svg" alt="more" />
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className="testnew"
                  >
                    {UpdateBus === true || UpdateBusdefault === true ? (
                      <MenuItem onClick={handleEdit} className="menuStyle">
                        <img src="img/Path 17091.svg" />
                        <span>تعديل</span>
                      </MenuItem>
                    ) : (
                      <></>
                    )}
                    {DeleteBus === true || DeleteBusdefault === true ? (
                      <MenuItem
                        className="menuStyle"
                        onClick={() => handleDelete()}
                      >
                        <img src="img/Path 17089.svg" />
                        <span>حذف</span>
                      </MenuItem>
                    ) : (
                      <></>
                    )}
                  </Menu>

                  <ConfirmDialog
                    confirmDialog={confirmDialog}
                    setConfirmDialog={setConfirmDialog}
                    loadingdelete={loadingdelete}
                  />

                  <PopupBus
                    title="تعديل الباص"
                    openPopup={openPopupEdit}
                    setOpenPopup={setOpenPopupEdit}
                  >
                    <BusFormEdit
                      id_company={id_company}
                      recordForEdit={recordForEdit}
                      id_Bus={clickedBusTypeId}
                    />
                  </PopupBus>
                </div>
              ) : (
                <></>
              )}
              {/* ======================= */}
              <CardMedia title="Contemplative Reptile">
                <img
                  src="img/Bus driver-pana.svg"
                  className={classes.media}
                  alt="bus-img"
                />
              </CardMedia>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
              <div className="body-container1">
              <p> رقم الباص : </p>   
                <div className="divtitleone">
                  <p>{bus.plate_number}</p>
                </div>
              </div>
              </Typography>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
              <div className="body-container1">
              <p> لوحة الباص : </p>   
                <div className="divtitleone">
                  <p>{bus.plate_code}</p>
                </div>
              </div>
              </Typography>
            </CardContent>
          </Card>
        ))
        )}
    </>
  );
};
export default Buses;
