import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";
export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchAllBus = createAsyncThunk(
  "Buss/fetchAllBus",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllBus`, {
        withCredentials: true,
      });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchBusBYId = createAsyncThunk(
  "Buss/fetchBusBYId",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getBus/${id}`, {
        withCredentials: true,
      });
      const result = await res.data.data;

      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const insertBus = createAsyncThunk(
  "Buss/insertBus",
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/addBus`, values, { withCredentials: true });
      sweetalert({
        text: "تم الإضافة بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data ;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateBus = createAsyncThunk(
  "Buss/updateBus",
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/updateBus/${values.id}`, values, {
        withCredentials: true,
      });
      sweetalert({
        text: "تم التعديل بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteBus = createAsyncThunk(
  "Buss/deleteBus",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await axios.delete(`${BaseUrl}/hardDeleteBus/${id}`, {
        withCredentials: true,
      });
      sweetalert({
        text: "تم الحذف بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);

const BusSlice = createSlice({
  name: "Buss",
  initialState: {
    Buss: [],
    BusID: [],
    InsertBus: [],
    UpdateBus: [],
    loading: false,
    loadingupdate: false,
    loadingdelete: false,
    error: null,
  },
  reducers: {
    clearBus: (state) => {
      state.Buss = [];
      state.BusID = [];
      state.InsertBus = [];
      state.UpdateBus = [];
      state.loading = false;
      state.loadingupdate = false;
      state.loadingdelete = false;
      state.error = null;
    },
  },
  extraReducers: {
    //fetch All Bus
    [fetchAllBus.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchAllBus.fulfilled]: (state, action) => {
      state.Buss = action.payload;
      state.loading = false;
    },
    [fetchAllBus.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //Fetch Bus By ID
    [fetchBusBYId.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchBusBYId.fulfilled]: (state, action) => {
      state.BusID = action.payload;
      state.loading = false;
    },
    [fetchBusBYId.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //insert  Bus
    [insertBus.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [insertBus.fulfilled]: (state, action) => {
      state.InsertBus.push(action.payload);
      state.loading = false;
    },
    [insertBus.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //Update  Bus
    [updateBus.pending]: (state, action) => {
      state.loadingupdate = true;
      state.error = null;
    },
    [updateBus.fulfilled]: (state, action) => {
      state.UpdateBus.push(action.payload);
      state.loadingupdate = false;
    },
    [updateBus.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingupdate = false;
    },
    //delete Bus
    [deleteBus.pending]: (state, action) => {
      state.loadingdelete = true;
      state.error = null;
    },
    [deleteBus.fulfilled]: (state, action) => {
      state.Buss = state.Buss.filter((el) => el.id !== action.payload.id);
      state.loadingdelete = false;
    },

    [deleteBus.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingdelete = false;
    },
  },
});
export const { clearBus } = BusSlice.actions;

export default BusSlice.reducer;
