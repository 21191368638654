import React, { useEffect, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchAllCity } from "../../redux/CitySlice";
import { fetchPathsById, updatePath } from "../../redux/PathSlice";
import { fetchTripType } from "../../redux/tripTypesSlice";
import { fetchCompanyById } from "../../redux/companyTransportDataSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";

export default function PathTripForm(props) {
  const dispatch = useDispatch();

  const { loading,PathsID,loadingupdate } = useSelector((state) => state.Paths);
  const {  Cities, error } = useSelector((state) => state.City);
  useEffect(() => {
    if (PathsID.id === props.id_path_row || props.id_path_row !== null) {
      dispatch(fetchPathsById(props.id_path_row));
      dispatch(fetchAllCity());
      dispatch(fetchTripType());
      // dispatch(fetchCompanyById(props.id_company));
    }
  }, [dispatch, props.id_path_row]);

  const initialFValues = {
    id: props.id_path_row,
    id_company: props.id_company,
    id_city_from: "",
    id_city_to: "",
    type: "",
    pathStations: "",
  };

  useEffect(() => {
    if (PathsID && PathsID.id === props.id_path_row) {
      setValues({
        id: props.id_path_row,
        id_company: props.id_company,
        id_city_from: PathsID.idCityFrom,
        id_city_to: PathsID.idCityTo,
        type: PathsID.type,
        pathStations: PathsID.stations.map((stations) => stations),
      });
    }
  }, [PathsID, props.id_path_row]);

  const addPathStation = () => {
    const newPathStation = {
      name: "",
      order_path: values.pathStations.length + 1,
    };
    setValues((prevValues) => ({
      ...prevValues,
      pathStations: [...prevValues.pathStations, newPathStation],
    }));
  };
  const handleDeleteStation = (index) => {
    const updatedPathStations = values.pathStations
      .filter((_, idx) => idx !== index)
      .map((station, idx) => ({ ...station, order_path: idx + 1 }));
    setValues((prevValues) => ({
      ...prevValues,
      pathStations: updatedPathStations,
    }));
  };
  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("id_city_from" in fieldValues)
      temp.id_city_from = fieldValues.id_city_from ? "" : "هذا الحقل مطلوب";
    if ("id_city_to" in fieldValues)
      temp.id_city_to = fieldValues.id_city_to ? "" : "هذا الحقل مطلوب";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const [selectedValue, setSelectedValue] = React.useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      dispatch(updatePath(values));
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);
  return (
    <>
    {loading ?(
         <div className="spinner1-box">
         <svg class="spinner1" viewBox="0 0 50 50">
           <circle
             class="path1"
             cx="25"
             cy="25"
             r="20"
             fill="none"
             stroke-width="5"
           ></circle>
         </svg>
       </div>
    ):(

   
  
    <Form onSubmit={handleSubmit}>
      <Grid className="createdTrip">
        <>
          <Grid item xs={12} md={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                <div
                  style={{
                    background: "white",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "25px",
                    height: "15px",
                  }}
                >
                  
                  <img src="img/create2.svg" alt="img" className='image-in'></img>
                  <span className="placeholdertitle">الإنطلاق</span>
                </div>
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.id_city_from}
                name="id_city_from"
                onChange={(e) => {
                  const selectedPath = e.target.value;
                  setValues((prevValues) => ({
                    ...prevValues,
                    id_city_from: selectedPath,
                  }));
                }}
              >
                {Array.isArray(Cities) &&
                  Cities.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name_ar}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                <div
                  style={{
                    background: "white",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "25px",
                    height: "15x",
                  }}
                >
                  <img src="img/create3.svg" alt="img" className='image-in'></img>

                  <span className="placeholdertitle">الوجهة</span>
                </div>
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.id_city_to}
                name="id_city_to"
                onChange={(e) => {
                  const selectedPath = e.target.value;
                  setValues((prevValues) => ({
                    ...prevValues,
                    id_city_to: selectedPath,
                  }));
                }}
              >
                {Array.isArray(Cities) &&
                  Cities.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name_ar}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <Controls.Input
             InputLabelProps={{ shrink: true }} 
              name="type"
              label={
                <Fragment>
                  <InputAdornment position="end">
                    <IconButton
                      className="iconplaceholder"
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <img src="img/create3.svg" alt="img" className='image-in'></img>
                    </IconButton>
                    <span className="placeholdertitle">نوع الرحلة</span>
                  </InputAdornment>
                </Fragment>
              }
              value={values.type}
              disabled
              error={errors.type}
            />
            {Array.isArray(values.pathStations) &&
              values.pathStations.map((pathStation1, index) => (
                <div key={index} className="path-station-container">
                  <Controls.Input
                    className="tesr122"
                    name="pathStations"
                    key={index}
                    label={
                      <Fragment>
                        <InputAdornment position="end">
                          <IconButton
                            className="iconplaceholder"
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <img src="img/create4.svg"></img>
                          </IconButton>
                          <span className="placeholdertitle">مكان التوقف </span>
                        </InputAdornment>
                      </Fragment>
                    }
                    value={pathStation1.name}
                    onChange={(e) => {
                      const updatedPathStations = values.pathStations.map(
                        (station, idx) =>
                          idx === index
                            ? { ...station, name: e.target.value }
                            : station
                      );
                      setValues((prevValues) => ({
                        ...prevValues,
                        pathStations: updatedPathStations,
                      }));
                    }}
                  />
                  <div className="icon-container">
                    <IconButton
                      className="delete-icon"
                      onClick={() => handleDeleteStation(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              ))}
          </Grid>
        </>
      </Grid>
      <div className="box-button-add-certifcation" onClick={addPathStation}>
        <img src="img/vuesax-linear-add-circle.svg" alt="" />
        <span className="title-add-certifcation">إضافة محطة توقف</span>
      </div>
      <Grid xs={12} md={12}>
      {loadingupdate  ? (
              <div
              className="bottonFullwidth"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="load loadingupdate"
                style={{ background: "#F9B917", borderRaduis: "10px" }}
              >
                <div v-if="loading" class="spinner">
                  <div class="rect1"></div>
                  <div class="rect2"></div>
                  <div class="rect3"></div>
                  <div class="rect4"></div>
                  <div class="rect5"></div>
                </div>
              </div>
            </div>
        ) : (
        <div className="bottonFullwidth"  style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Controls.Button type="submit" text="تعديل" />
        </div>
        )}
      </Grid>
    </Form>
     )}
    </>
  );
}
