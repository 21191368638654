import React, { useState, useEffect, useRef, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm1";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchAllTicket } from "../../redux/TickeType";
import { useSelector } from "react-redux";
import { fetchTripTypeById } from "../../redux/tripTypesSlice";
import packageJson from "../../../package.json";
export const key_Shortcut = packageJson.key_Shortcut;
const genderItems = [
  { id: "male", title: "ذكر" },
  { id: "female", title: "أنثى" },
];
const CivilItems = [
  { id: "military", title: "عسكري" },
  { id: "civil", title: "مدني" },
];

export default function PassengerAdult(props) {
  const {
    isvalid,
    setIsValid,
    initialValues,
    adults,
    id_company,
    setactiveStep1,
    index,
    id_trip,
    id_trip_type,
  } = props;
  const [isOpen, setIsOpen] = useState(false); // State to control the select open state
  const [isOpen1, setIsOpen1] = useState(false); // State to control the select open state
  const [isOpen2, setIsOpen2] = useState(false); // State to control the select open state

  const firstNameInputRef = useRef(null);
  const inputRefs = [
    useRef(null), // Ref for first_name
    useRef(null), // Ref for last_name
    useRef(null), // Ref for national_id
    useRef(null), // Ref for birth_date
    useRef(null), // Ref for mother_name
    useRef(null), // Ref for middle_name
    useRef(null), // Ref for birth_place
    useRef(null), // Ref for gender
    useRef(null), // Ref for civil_status
  ];
  const handleKeyDown = (event, index) => {
    const noFieldFocused = document.activeElement.tagName !== "INPUT";
    if (event.key === key_Shortcut.Tab && noFieldFocused) {
      inputRefs[0].current.focus();
    }

    else if (event.key === key_Shortcut.Tab && index === 7) {
      setIsOpen1(true);
    }
    else if (event.key === key_Shortcut.Tab && index === 8) {
      setIsOpen2(true);
    }
    else if (event.key === key_Shortcut.Tab && index === 9) {
      inputRefs[0].current.unfocus();
    }
    else if (event.key === key_Shortcut.Tab && index < inputRefs.length - 1) {
      event.preventDefault();
      inputRefs[index + 1].current.focus();
    } else if (
      event.key === key_Shortcut.Tab &&
      index > 0 &&
      index !== 1 &&
      index !== 8
    ) {
      // If first item, loop to the last item
      event.preventDefault();
      inputRefs[index - 1].current.focus();
    }
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === key_Shortcut.Shift) {
        // Prevent the default tab behavior to avoid losing focus
        event.preventDefault();

        // Trigger a click event on the table element
        inputRefs[1].current.focus();
      }
      // Add more conditions for other keys if needed
    };

    // Attach the event listener to the document
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues)
      temp.first_name = fieldValues.first_name ? "" : "هذا الحقل مطلوب";
    if ("middle_name" in fieldValues)
      temp.middle_name = fieldValues.middle_name ? "" : "هذا الحقل مطلوب";
    if ("last_name" in fieldValues)
      temp.last_name = fieldValues.last_name ? "" : "هذا الحقل مطلوب";
    if ("mother_name" in fieldValues)
      temp.mother_name = fieldValues.mother_name ? "" : "هذا الحقل مطلوب";
    if ("national_id" in fieldValues)
      temp.national_id = fieldValues.national_id ? "" : "هذا الحقل مطلوب";
    if ("gender" in fieldValues)
      temp.gender = fieldValues.gender ? "" : "هذا الحقل مطلوب";
    if ("birth_date" in fieldValues)
      temp.birth_date = fieldValues.birth_date ? "" : "هذا الحقل مطلوب";
    if ("birth_place" in fieldValues)
      temp.birth_place = fieldValues.birth_place ? "" : "هذا الحقل مطلوب";
    if ("civil_status" in fieldValues)
      temp.civil_status = fieldValues.civil_status ? "" : "هذا الحقل مطلوب";

    setErrors({
      ...temp,
    });
    // Check if any errors are present
    let isvalid = true;
    for (const key in temp) {
      if (temp[key] !== "") {
        isvalid = false; // Set isValid to false if any error exists
        break;
      }
    }

    setErrors({
      ...temp,
    });

    return isvalid; // Return the validation result
  };
  const { values, setValues, errors, setErrors, resetForm } =
    useForm(initialValues);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const storedPassengers = JSON.parse(localStorage.getItem("passengers"));
  const [passengers, setPassengers] = useState(
    storedPassengers || initialValues
  );
  const [focusinpu, setFocusInput] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("passengers", JSON.stringify(passengers));
  }, [passengers]);

  const navigate = useNavigate();
  const { Tickets } = useSelector((state) => state.Ticket);
  const { TripTypeID } = useSelector((state) => state.TripType);
  const removeLocalStorageOnUnload = () => {
    if (navigate.location.pathname !== "StepPassenger") {
      localStorage.removeItem("passengers");
    }
  };
  useEffect(() => {
    window.addEventListener("beforeunload", removeLocalStorageOnUnload);
    dispatch(fetchAllTicket(id_company));
    return () => {
      window.removeEventListener("beforeunload", removeLocalStorageOnUnload);
    };
  }, []);
  useEffect(() => {
    window.addEventListener("beforeunload", handleKeyDown);
    return () => {
      window.removeEventListener("beforeunload", handleKeyDown);
    };
  }, []);

  // ... rest of your component code ...
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (e.target.name === "gender") {
      const updatedPassengers = [...passengers];
      updatedPassengers[index] = {
        ...updatedPassengers[index],
        [name]: value,
      };

      setPassengers(updatedPassengers);
      const isFormValid = validate(updatedPassengers[index]); // Validate the updated passenger
      setIsValid(
        isFormValid && passengers.every((passenger) => validate(passenger))
      );

      setValues((prevValues) => ({
        ...prevValues,
        adults: updatedPassengers, // Update the adults array in values
      }));
      setIsOpen1(false);
      setIsOpen2(true);
    }
    else if (name === "id_price_ticket") {
      // Find the selected ticket details based on the value
      const selected = TripTypeID.price.find((ticket) => ticket.id === value);
      setSelectedTicket(selected); // Update selectedTicket state
      const updatedPassengers = [...passengers];
      updatedPassengers[index] = {
        ...updatedPassengers[index],
        [name]: value,
        price: selected.price
      };
      setPassengers(updatedPassengers);
      const isFormValid = validate(updatedPassengers[index]); // Validate the updated passenger
      setIsValid(
        isFormValid && passengers.every((passenger) => validate(passenger))
      );

      setValues((prevValues) => ({
        ...prevValues,
        adults: updatedPassengers, // Update the adults array in values
      }));
    }

    else {
      const updatedPassengers = [...passengers];
      updatedPassengers[index] = {
        ...updatedPassengers[index],
        [name]: value,
      };
      setPassengers(updatedPassengers);
      const isFormValid = validate(updatedPassengers[index]); // Validate the updated passenger
      // setIsValid(isFormValid); // Update validation status
      setIsValid(
        isFormValid && passengers.every((passenger) => validate(passenger))
      );

      setValues((prevValues) => ({
        ...prevValues,
        adults: updatedPassengers, // Update the adults array in values
      }));
    }
  };

  useEffect(() => {
    if (firstNameInputRef.current) {
      firstNameInputRef.current.focus();
      inputRefs[1].current.focus();
    }
    if (selectedOption !== null) {
      inputRefs[1].current.focus();
    }

  }, [selectedOption]);
  useEffect(() => {
    if (isOpen === false) {
      inputRefs[1].current.focus();
    }
  }, [isOpen]);

  const parsedData = Tickets
    ? Tickets.map((ticket, index) => ticket.title)
    : [];
  useEffect(() => {
    setIsValid(validate(passengers[index]));
  }, [index, passengers]);
  useEffect(() => {
    dispatch(fetchTripTypeById(id_trip_type));
  }, [dispatch]);
  return (
    <Form>
      <Grid
        item
        xs={12}
        md={12}
        sm={12}
        className="add-passenger"
        style={{
          fontSize: "25px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row-reverse",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <p>الحجز للمسافر رقم : {index + 1}</p>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <p style={{ fontSize: "15px" }}>رقم المقعد</p>
          <p
            style={{
              fontSize: "15px",
              padding: "10px",
              background: "#03314b",
              color: "white",
              borderRadius: "18px",
            }}
          >
            {passengers[index]?.number_seat}
          </p>
        </div>
      </Grid>
      <Grid item xs={12} md={12} className="travelsection">
        <Grid item xs={12} md={12} sm={12} className="travelsectiontwo">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row-reverse",
              height: "100%",
            }}
          >
            <Grid item xs={5} md={5} sm={12} className="add-passenger">
              <Controls.SelectTypeTripCart
                name="id_price_ticket"

                inputRef={inputRefs[0]}
                onKeyDown={(e) => handleKeyDown(e, 0)}
                label={
                  <Fragment>
                    <InputAdornment style={{ marginRight: "10%" }}>
                      <span
                        className="placeholdertitle"
                        style={{ marginRight: "10%" }}
                      >
                        نوع التذكرة
                      </span>
                    </InputAdornment>
                  </Fragment>
                }
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                value={passengers[index]?.id_price_ticket}
                onChange={(e) => handleInputChange(e, index)}
                options={TripTypeID.price || []}
              />
            </Grid>

            <Grid item xs={6} md={6} sm={12} style={{ display: 'flex', flexDirection: 'row-reverse', gap: '2%' }}>
              <span className="placeholdertitle" style={{ fontSize: '13px', width: '50%', marginTop: '3%' }}>سعر التذكرة</span>
              <Controls.Input
                className="add-passenger"
                name="price"
                shrink={true}
                inputRef={inputRefs[11]} // Use the appropriate ref index
                value={selectedTicket?.price || passengers[index]?.price} // Use the selected ticket's price
                readOnly
              />
            </Grid>
          </div>

          <Controls.Input
            className="tesr add-passenger"
            name="first_name"
            inputRef={inputRefs[1]}
            onKeyDown={(e) => handleKeyDown(e, 1)}
            label={
              <Fragment>
                <InputAdornment position="end">
                  <span className="placeholdertitle">الاسم الأول </span>
                </InputAdornment>
              </Fragment>
            }
            value={passengers[index]?.first_name}
            onChange={(e) => handleInputChange(e, index)}
            error={errors[`first_name_${index}`]}
          />

          <Controls.Input
            className="tesr add-passenger"
            name="last_name"
            inputRef={inputRefs[2]}
            onKeyDown={(e) => handleKeyDown(e, 2)}
            label={
              <Fragment>
                <InputAdornment position="end">
                  <span className="placeholdertitle">الاسم الأخير</span>
                </InputAdornment>
              </Fragment>
            }
            value={passengers[index]?.last_name}
            onChange={(e) => handleInputChange(e, index)}
            error={errors[`last_name${index}`]}
          />

          <Controls.Input
            className="tesr add-passenger"
            name="national_id"
            inputRef={inputRefs[3]}
            onKeyDown={(e) => handleKeyDown(e, 3)}
            label={
              <Fragment>
                <InputAdornment position="end">
                  <span className="placeholdertitle">الرقم الوطني</span>
                </InputAdornment>
              </Fragment>
            }
            value={passengers[index]?.national_id}
            onChange={(e) => handleInputChange(e, index)}
            error={errors[`national_id${index}`]}
          />
          <Controls.Input
            className="tesr add-passenger"
            name="birth_date"
            inputRef={inputRefs[4]}
            onKeyDown={(e) => handleKeyDown(e, 4)}
            label={
              <Fragment>
                <InputAdornment position="end">
                  <span className="placeholdertitle">تاريخ الولادة</span>
                </InputAdornment>
              </Fragment>
            }
            value={passengers[index]?.birth_date}
            onChange={(e) => handleInputChange(e, index)}
            error={errors[`birth_date${index}`]}
          />
        </Grid>
        <Grid item xs={12} md={12} sm={12} className="travelsect">
          <Controls.Input
            className="tesr add-passenger"
            name="mother_name"
            inputRef={inputRefs[5]}
            onKeyDown={(e) => handleKeyDown(e, 5)}
            label={
              <Fragment>
                <InputAdornment position="end">
                  <span className="placeholdertitle">اسم الأم</span>
                </InputAdornment>
              </Fragment>
            }
            value={passengers[index]?.mother_name}
            onChange={(e) => handleInputChange(e, index)}
            error={errors[`mother_name${index}`]}
          />

          <Controls.Input
            className="tesr add-passenger"
            name="middle_name"
            inputRef={inputRefs[6]}
            onKeyDown={(e) => handleKeyDown(e, 6)}
            label={
              <Fragment>
                <InputAdornment position="end">
                  <span className="placeholdertitle">اسم الأب</span>
                </InputAdornment>
              </Fragment>
            }
            value={passengers[index]?.middle_name}
            onChange={(e) => handleInputChange(e, index)}
            error={errors[`middle_name${index}`]}
          />

          <Controls.Input
            className="tesr add-passenger"
            name="birth_place"
            inputRef={inputRefs[7]}
            onKeyDown={(e) => handleKeyDown(e, 7)}
            label={
              <Fragment>
                <InputAdornment position="end">
                  <span className="placeholdertitle">محل الولادة</span>
                </InputAdornment>
              </Fragment>
            }
            value={passengers[index]?.birth_place}
            onChange={(e) => handleInputChange(e, index)}
            error={errors[`birth_place${index}`]}
          />
          <Controls.SelectTypeTripCart2
            name="gender"
            className="add-passenger"
            inputRef={inputRefs[8]}
            onKeyDown={(e) => handleKeyDown(e, 8)}
            label={
              <Fragment>
                <InputAdornment position="end">
                  <span className="placeholdertitle">الجنس</span>
                </InputAdornment>
              </Fragment>
            }
            value={passengers[index]?.gender}
            error={errors[`gender${index}`]}
            onChange={(e) => handleInputChange(e, index)}
            options={genderItems || []}
            isOpen1={isOpen1}
            setIsOpen1={setIsOpen1}
          />
          <Controls.SelectTypeTripCart3
            name="civil_status"
            className="add-passenger"
            inputRef={inputRefs[9]}
            onKeyDown={(e) => handleKeyDown(e, 9)}
            label={
              <Fragment>
                <InputAdornment position="end">
                  <span className="placeholdertitle">الوضع الحالي</span>
                </InputAdornment>
              </Fragment>
            }
            value={passengers[index]?.civil_status}
            error={errors[`civil_status${index}`]}
            onChange={(e) => handleInputChange(e, index)}
            options={CivilItems || []}
            isOpen2={isOpen2}
            setIsOpen2={setIsOpen2}
          />
        </Grid>
      </Grid>
    </Form>
  );
}
