import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../component/Header";
import Box from "@mui/material/Box";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Test1 from "../../component/test1";
import DeactiveTable from "./DeactiveTable";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchDisableAccount } from "../../redux/ActiveClientAcoount";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  gridDivide: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const DeactiveClient = ({ mylist }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDisableAccount());
  }, [dispatch]);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { ActiveAccount } = useSelector((state) => state.DisableAccount);
  return (
    <Box>

      <Grid container spacing={2}>

        <Grid item xs={12} sm={12} md={12}>
          <TabPanel value={value} index={0}>
            <DeactiveTable />
          </TabPanel>
          <TabPanel value={value} index={1}>
            الباصات
          </TabPanel>
          <TabPanel value={value} index={2}>
            وسائل النقل
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
};
export default DeactiveClient;
