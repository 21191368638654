import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";
import { fetchSeateTypeAll, fetchSeateTypeAllfilter } from './SeatsTypeSlice';

export const BaseUrl = packageJson.projectURlApi.BaseUrl;

export const fetchTripType = createAsyncThunk(
  'TripType/fetchTripType',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllTripType`, { withCredentials: true });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const fetchTripTypeById = createAsyncThunk(
  'TripType/fetchTripTypeById',
  async (id, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;

    try {
      const res = await axios.get(`${BaseUrl}/getTripType/${id}`, { withCredentials: true });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchTripTypeByIdfilter = createAsyncThunk(
  'TripType/fetchTripTypeByIdfilter',
  async (id, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;

    try {
      const res = await axios.get(`${BaseUrl}/getTripType/${id}`, { withCredentials: true });
      const result = res.data.data;
      let typeseat
      if (result.type == "رجال اعمال") {
        typeseat = 'رجال اعمال'
      } else {
        typeseat = 'عادي'
      }
      const data = {
        id: result.id_company,
        type: typeseat,
      };


      dispatch(fetchBusByIdFilter(data));
      dispatch(fetchSeateTypeAllfilter(data))

      const id_city = {
        id: result.id_company,
        id_city_from: result.id_city_from,
      }
      dispatch(fetchBuranchhByIdFilter(id_city));
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchTripTypeByIdfilter2 = createAsyncThunk(
  'TripType/fetchTripTypeByIdfilter2',
  async (id, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;

    try {
      const res = await axios.get(`${BaseUrl}/getTripType/${id}`, { withCredentials: true });
      const result = res.data.data;
      let typeseat
      if (result.type == "رجال اعمال") {
        typeseat = 'رجال اعمال'
      } else {
        typeseat = 'عادي'
      }



      const id_city = {
        id: result.id_company,
        id_city_from: result.id_city_from,
      }
      dispatch(fetchBuranchhByIdFilter(id_city));
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchBusByIdFilter = createAsyncThunk(
  'TripType/fetchBusByIdFilter',
  async (values, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const res = await axios.get(`${BaseUrl}/getCompany/${values.id}`, { withCredentials: true });

      const { bus } = res.data.data; // Extract bus array

      const filteredBuses = bus.filter((b) => b.type === values.type); // Filter buses by type
     
      return filteredBuses;

    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchBusByIdFilter1 = createAsyncThunk(
  'TripType/fetchBusByIdFilter',
  async (values, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getCompany/${values}`, { withCredentials: true });

      const { bus } = res.data.data; 
      return bus;

    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchBuranchhByIdFilter = createAsyncThunk(
  'TripType/fetchBuranchhByIdFilter',
  async (values, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const res = await axios.get(`${BaseUrl}/getCompany/${values.id}`, { withCredentials: true });
      const { branch } = res.data.data;

      const filteredBranch = branch.filter((b) => b.id_city === values.id_city_from);



      return filteredBranch;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const insertTripType = (values) => async (dispatch) => {
  try {
    dispatch(setLoading1(true));

    const response = await axios.post(`${BaseUrl}/addTripType`, values, { withCredentials: true });
    const responseData = response.data.data;

    dispatch(setTripTypeData1(responseData));
    sweetalert({
      text: "تم الإضافة بنجاح",
      icon: "success",
      buttons: {
        confirm: {
          text: "تم",
          className: "custom-button-class",
        },
      },
      className: "alert-div",
    }).then(() => {
      window.location.reload();
      localStorage.setItem("displayshortcut", "0");
    });

    dispatch(setLoading1(false));
  } catch (error) {
    // Extracting error message from Axios Error object
    const message = error.response.data.data
    dispatch(setError1(message)); // Assuming you have a setError1 action to handle error state
    dispatch(setLoading1(false));

    if (error.response && error.response.status === 403) {
      // Handle the 403 Forbidden error specifically
      sweetalert({
        text: "ليس لديك صلاحية للقيام بهذا",
        icon: "error",
        buttons: {
          confirm: {
            text: "إغلاق",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      });
    } else {
      // Handle other errors with a generic message
   
      const errorMessage = error.response?.data?.data || error.response?.data?.message;
      sweetalert({
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "إغلاق",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      });
    }
  }
};
export const updateTripType = createAsyncThunk(
  'TripType/updateTripType',
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {

      await axios.post(`${BaseUrl}/updateTripType/${values.id}`, values, { withCredentials: true });
      sweetalert({
        text: "تم التعديل بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    }
    catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
     
        const errorMessage = error.response?.data?.message || error.response?.data?.data  ;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteTripType = createAsyncThunk(
  'TripType/deleteTripType',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(`${BaseUrl}/hardDeleteTripType/${id}`, { withCredentials: true });
      if (response.data && response.data.success === "true" && response.data.data === true) {
        sweetalert({
          text: "تم الحذف بنجاح",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      } else {

        sweetalert({
          text: response.data.data.original.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
    }
    catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage = error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);
const insertTripTypeSlice = createSlice({
  name: 'TripType',
  initialState: { TripType: [], TripTypeID: [], TypeBus: [],TypeBus2: [], TripTypeIDFilter: [], TripTypeIDFilter2:[],InsertTripType: [], UpdateTripType: [], filteredBranch: [], loadingSelected: false, loading: false, loadingdelete: false, loadingAddTriptype: false, error: null },
  reducers: {
    clearTripTypeState: (state) => {
      state.TripTypeID = [];
      state.TripType = [];
      state.TypeBus = [];
      state.TypeBus2 = [];
      state.TripTypeIDFilter = [];
      state.filteredBranch = [];
      state.loadingAddTriptype = [];
      state.InsertTripType = [];
      state.UpdateTripType = [];
      state.TripTypeIDFilter2=[];
      state.loading = false;
      state.loadingdelete = false;
      state.loadingSelected = false;
      state.loadingAddTriptype = false;
    },
    clearTripTypeStatefilter: (state) => {
      state.filteredBranch = [];
      state.loading = false;
      state.loadingdelete = false;
      state.loadingAddTriptype = false;
      state.loadingSelected = false;
    },
    setTripTypeData1(state, action) {
      state.InsertTripType = action.payload;
      state.loadingAddTriptype = false;
    },
    setLoading1(state, action) {
      state.loading = action.payload;
    },
    setError1(state, action) {
      state.error = action.payload;
    },

  },
  extraReducers: {
    //Fetch trip types
    [fetchTripType.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchTripType.fulfilled]: (state, action) => {
      state.TripType = action.payload;
      state.loading = false;
    },
    [fetchTripType.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //Fetch trip types By ID
    [fetchTripTypeById.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchTripTypeById.fulfilled]: (state, action) => {
      state.TripTypeID = action.payload;
      state.loading = false;
    },
    [fetchTripTypeById.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //Fetch trip types By ID
    [fetchTripTypeByIdfilter.pending]: (state, action) => {
      state.loadingSelected = true;
      state.error = null;
    },
    [fetchTripTypeByIdfilter.fulfilled]: (state, action) => {
      state.TripTypeIDFilter = action.payload;
      state.loadingSelected = false;
    },
    [fetchTripTypeByIdfilter.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingSelected = false;
    },
    //Fetch trip types By ID
    [fetchTripTypeByIdfilter2.pending]: (state, action) => {
      state.loadingSelected = true;
      state.error = null;
    },
    [fetchTripTypeByIdfilter.fulfilled]: (state, action) => {
      state.TripTypeIDFilter2 = action.payload;
      state.loadingSelected = false;
    },
    [fetchTripTypeByIdfilter2.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingSelected = false;
    },
    //Fetch Bus  By TYPE
    [fetchBusByIdFilter.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchBusByIdFilter.fulfilled]: (state, action) => {
      state.TypeBus = action.payload;
      state.loading = false;
    },
    [fetchBusByIdFilter.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //Fetch Bus  By TYPE
    [fetchBusByIdFilter1.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchBusByIdFilter1.fulfilled]: (state, action) => {
      state.TypeBus2 = action.payload;
      state.loading = false;
    },
    [fetchBusByIdFilter1.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //Fetch Filter Branch
    [fetchBuranchhByIdFilter.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchBuranchhByIdFilter.fulfilled]: (state, action) => {
      state.filteredBranch = action.payload;
      state.loading = false;
    },
    [fetchBuranchhByIdFilter.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    //Update  trip types
    [updateTripType.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [updateTripType.fulfilled]: (state, action) => {
      state.UpdateTripType.push(action.payload);
      state.loading = false;
    },
    [updateTripType.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //delete  trip types
    [deleteTripType.pending]: (state, action) => {
      state.loadingdelete = true;
      state.error = null;
    },
    [deleteTripType.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        state.TripType = state.TripType.filter((el) => el.id !== action.payload.id);
      }
      state.loadingdelete = false;
    },
    [deleteTripType.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingdelete = false;
    },
  },
});
export const { setTripTypeData1, clearTripTypeStatefilter, setLoading1, setError1, clearTripTypeState } = insertTripTypeSlice.actions;


export default insertTripTypeSlice.reducer;