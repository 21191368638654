import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchTripsLock = createAsyncThunk(
  "TripLock/fetchTripsLock",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getTripsIsLock/${id}`, {
        withCredentials: true,
      });
      const result = await res.data.data;

      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);




const TripLockSlice = createSlice({
  name: "TripLock",
  initialState: {
    TripLocks: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearTripLocksState: (state) => {
      state.TripLocks = [];
      state.loading = false;
      state.error = null;
    },
  
  },
  extraReducers: {
    //fetch
    [fetchTripsLock.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchTripsLock.fulfilled]: (state, action) => {
      state.TripLocks = action.payload;
      state.loading = false;
    },
    [fetchTripsLock.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  
  },
});
export const {  clearTripLocksState } = TripLockSlice.actions;
export default TripLockSlice.reducer;
