import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Controls from "../../../component/controls/Controls";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  AssginPermission,
  ResmovePermission,
  activePermission,
  fetchAllPermission,
} from "../../../redux/Permission";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as sweetalert from "sweetalert";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["إضافة موظف", "حذف موظف", "تعديل بيانات موظف"];

export default function CardPermission({ user_id }) {
  const dispatch = useDispatch();
  const [personName, setPersonName] = React.useState([]);
  useEffect(() => {
    dispatch(fetchAllPermission(user_id));
  }, [dispatch]);

  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const [permissionsByCategory, setPermissionsByCategory] = React.useState({});
  const { Permission } = useSelector((state) => state.permission);
  const permissionArray = Object.values(Permission);
  const uniqueCategories = [
    ...new Set(permissionArray.map((item) => item.category)),
  ];
  const [checkedpermission, setCheckedpermission] = React.useState([]); // State to manage checked cities
  const [currentheight, setCurrentheight] = React.useState(false); // State to manage checked cities
  useEffect(() => {
    const uniqueCategories = [
      ...new Set(permissionArray.map((item) => item.category)),
    ];
    setSelectedCategories(uniqueCategories);
  }, [permissionArray]);
  const [openCategory, setOpenCategory] = React.useState(null);
  const handleCategoryClick = (cat) => {
    if (openCategory === cat) {
      setOpenCategory(null); // Close the currently open category
    } else {
      setOpenCategory(cat); // Open the clicked category
    }
  };
  useEffect(() => {
    const permissionsMap = {};
    permissionArray.forEach((item) => {
      if (!permissionsMap[item.category]) {
        permissionsMap[item.category] = [];
      }
      permissionsMap[item.category].push(item);
    });
    setPermissionsByCategory(permissionsMap);
  }, [Permission]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handletoggle = (event) => {
    const { value, checked } = event.target;
    const permissionId = parseInt(value); // Convert the value to a number
    const newActiveValue = checked ? 1 : 0;
    setCheckedpermission((prevCheckedPermissions) => {
      const updatedPermissions = prevCheckedPermissions.map((permission) => {
        if (permission.permissions_id === permissionId) {
          if (permission.id === permissionId) {
            // Update the active status of the specific permission
            return { ...permission, active: checked ? 1 : 0 };
          } else {
            return permission;
          }
        }
      });
      const updatedPermissions1 = permissionArray.map((permission) => {
        if (permission.id === permissionId) {
          // Update the active status of the specific permission
          return { ...permission, active: checked ? 1 : 0 };
        } else {
          return permission;
        }
      });
      const updatedPermission = updatedPermissions1.find(
        (perm) => perm.id === permissionId
      );
      const data = {
        systemUser_id: user_id,
        ...updatedPermission,
      };
      dispatch(activePermission(data));
      // Update the permissionsByCategory using the updatedPermissions array
      const updatedPermissionsByCategory = {};
      for (const category in permissionsByCategory) {
        updatedPermissionsByCategory[category] = permissionsByCategory[
          category
        ].map((perm) =>
          perm.id === permissionId ? { ...perm, active: checked ? 1 : 0 } : perm
        );
      }

      // Log the updated permissions for debugging
      
      setPermissionsByCategory(updatedPermissionsByCategory);
      return updatedPermissions;
    });
  };

  const handlePermissionCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const permissionId = parseInt(value); // Convert the value to a number
    setCheckedpermission((prevCheckedPermissions) => {
      const updatedPermissions = prevCheckedPermissions.map((permission) => {
        if (permission.id === permissionId) {
          // Update the active status of the specific permission
          return { ...permission, exists: checked ? 1 : 0 };
        } else {
          return permission;
        }
      });

      const updatedPermissions1 = permissionArray.map((permission) => {
        if (permission.id === permissionId) {
          // Update the active status of the specific permission
          return { ...permission, exists: checked ? 1 : 0 };
        } else {
          return permission;
        }
      });
      const updatedPermission = updatedPermissions1.find(
        (perm) => perm.id === permissionId
      );

      if (checked == true) {
        const data = {
          systemUser_id: user_id,
          permissions: [updatedPermission],
        };
        dispatch(AssginPermission(data));
      } else {
       
        const data = {
          systemUser_id: user_id,
          ...updatedPermission
        };
        dispatch(ResmovePermission(data));
      }

      // Update the permissionsByCategory using the updatedPermissions array
      const updatedPermissionsByCategory = {};
      for (const category in permissionsByCategory) {
        updatedPermissionsByCategory[category] = permissionsByCategory[
          category
        ].map((perm) =>
          perm.id === permissionId ? { ...perm, exists: checked ? 1 : 0 } : perm
        );
      }

      setPermissionsByCategory(updatedPermissionsByCategory);
      return updatedPermissions;
    });
  };

  const handleSave = () => {
    sweetalert({
      text: 'تم الحفظ بنجاح',
      icon: 'success',
      buttons: {
        confirm: {
          text: 'تم',
          className: 'custom-button-class',
        },
      },
      className: 'alert-div',
    }).then(() => {window.location.reload()});
  };

  return (
    <>
      <Grid item lg={12} md={12} sm={12} className="box-all-permission" style={{
                display:'flex',
                justifyContent: 'center',
              }}>
        {uniqueCategories &&
          uniqueCategories.map((cat, index) => (
            <Grid
              lg={3}
              md={4}
              sm={12}
              key={index}
              className="box-catergory-permistion"
              style={{
                height: openCategory === cat ? "auto" : "40px",
                padding: "10px !impotrant",
               
              }}
            >
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={() => handleCategoryClick(cat)}
              >
                {cat}
                {openCategory === cat ? (
                  <ExpandMoreIcon />
                ) : (
                  <ArrowBackIosNewIcon />
                )}
              </div>
              <div style={{ display: openCategory === cat ? "block" : "none" }}>
                {permissionsByCategory[cat] &&
                  permissionsByCategory[cat].map((permission) => (
                    <div
                      key={permission.id}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                      className="permincheckbox"
                    >
                      <>
                        <MenuItem
                          value={permission.id}
                          className="li-permission"
                        >
                          <Checkbox
                            name="permissions_id"
                            value={permission.id}
                            checked={permission.exists === 1}
                            onChange={handlePermissionCheckboxChange}
                          />
                          <ListItemText primary={permission.name} />
                        </MenuItem>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          {permission.exists === 1 ? (
                            <FormControlLabel
                              control={
                                <Switch
                                  value={permission.id}
                                  checked={permission.active === 1}
                                  onChange={handletoggle}
                                  color="warning"
                                />
                              }
                              label="اسناد للغير"
                            />
                          ) : (
                            <FormControlLabel
                              control={
                                <Switch
                                  value={permission.id}
                                  checked={permission.active === 1}
                                  onChange={handletoggle}
                                  color="warning"
                                  disabled
                                />
                              }
                              label="اسناد للغير"
                            />
                          )}
                        </div>
                      </>
                    </div>
                  ))}
              </div>
            </Grid>
          ))}
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          className="bottonFullwidth3"
          style={{ width: "20% !important", background: "#F9B917 !important" }}
        >
          <Controls.Button
            type="submit"
            text="حفظ"
            onClick={() => {
              handleSave();
            }}
          />
        </div>
      </div>
    </>
  );
}
