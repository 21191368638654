import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";
import { fetchTripBYId } from "./tripsSlice";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;

export const insertpassangerreservation = createAsyncThunk(
  "passangerreservation/insertpassangerreservation",
  async (values, thunkAPI) => {
    const { rejectWithValue, getState, dispatch } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/addReservation`, values, {
        withCredentials: true,
      });
      sweetalert({
        text: "تم الإضافة بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("An error occurred");
      }
    }
  }
);
export const deletePassengerToReservation = createAsyncThunk(
  "passangerreservation/deletePassengerToReservation",
  async (data, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      localStorage.setItem("changeseat", true);
      await axios.post(`${BaseUrl}/deletePassengerFromReservation`, data, {
        withCredentials: true,
      });

      sweetalert({
        text: "تم الحذف بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        dispatch(fetchTripBYId(data.id_trip));
        localStorage.setItem("openpassenger", false);
        localStorage.setItem("changeseat", false);
      });
    } catch (error) {
      localStorage.setItem("changeseat", false);
      return rejectWithValue(error.message);
    }
  }
);

const Insertpassangerreservation = createSlice({
  name: "passangerreservation",
  initialState: {
    passanferCount: [],
    deletePassenger: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearpassengerState: (state) => {
      state.passanferCount = [];
      state.deletePassenger = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: {
    //insert  types insert passanger
    [insertpassangerreservation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [insertpassangerreservation.fulfilled]: (state, action) => {
      state.passanferCount.push(action.payload);
      state.loading = false;
    },
    [insertpassangerreservation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //delete  Passenger for reservation
    [deletePassengerToReservation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deletePassengerToReservation.fulfilled]: (state, action) => {
      state.deletePassenger = state.deletePassenger.filter(
        (el) => el.id !== action.payload.id
      );
      state.loading = false;
    },

    [deletePassengerToReservation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export const { clearpassengerState } = Insertpassangerreservation.actions;
export default Insertpassangerreservation.reducer;
