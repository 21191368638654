import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";
export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const insertCityEmployee = createAsyncThunk(
  "BranchEmployee/insertCityEmployee",
  async (values, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/addCityBranchEmployee`, values, {
        withCredentials: true,
      });
      sweetalert({
        text: "تم التعديل بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchCitiesBranchEmployee = createAsyncThunk(
  "BranchEmployee/fetchCitiesBranchEmployee",
  async (id, thunkAPI) => {
    const {  rejectWithValue } = thunkAPI;
   
    try {
      const res = await axios.get(`${BaseUrl}/citiesBranchEmployee/${id}`, {
        withCredentials: true,
      });
      const  result  = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const CityEmployeeSlice = createSlice({
  name: "AddCityEmployee",
  initialState: {
    CityEmployee: [],
    CityEmployeeID: [],
    loading: false,
    loadingAdd: false,
    error: null,
  },
  reducers: {
    clearCityEmployee: (state) => {
      state.CityEmployee = [];
      state.CityEmployeeID = [];
      state.loading = false;
      state.loadingAdd = false;
      state.error = null;

    },
  },
  extraReducers: {
    //insert Branch Employee
    [insertCityEmployee.pending]: (state, action) => {
      state.loadingAdd = true;
      state.error = null;
    },
    [insertCityEmployee.fulfilled]: (state, action) => {
      state.CityEmployee.push(action.payload);
      state.loadingAdd = false;
    },
    [insertCityEmployee.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingAdd = false;
    },
    //Fetch Branch Employee By ID
    [fetchCitiesBranchEmployee.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchCitiesBranchEmployee.fulfilled]: (state, action) => {
      state.CityEmployeeID = action.payload;
      state.loading = false;
    },
    [fetchCitiesBranchEmployee.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export const { clearCityEmployee } = CityEmployeeSlice.actions;
export default CityEmployeeSlice.reducer;
