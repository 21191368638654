import React from "react";
import { Box, useTheme } from "@mui/material";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import { useDispatch } from "react-redux";
import { useState } from "react";

const SetRed = ({ img, number }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
;
  const[numberSeat,setNumberSeat]= useState(4);
 

  return (
    <Box
    
    >
  
             
              <Card style={{marginTop:'0px !important'}}>
              <div style={{ position: "relative"}} >
                {" "}
                <CardMedia
                  component="img"
                  image={img}
                  title="Pancakes"
                  alt="Pancakes"
                />
                <div
                  style={{
                    position: "absolute",
                    color: "white",
                    top: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                    <p style={{fontSize:'30px '}}>
                            {number}
                    </p>
                </div>
              </div>
            </Card>
         

    </Box>
  );
};

export default SetRed;
