import React, { useEffect, Fragment } from "react";
import { FormHelperText, Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm.js";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { clearSeatType, fetchAllSeateTypedependNumberSeats } from "../../redux/SeatsTypeSlice";
import { useSelector } from "react-redux";
import { fetchBusBYId, updateBus } from "../../redux/busSlice";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
export default function BusFormEdit(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.id_Bus) {
      dispatch(fetchBusBYId(props.id_Bus));
    }
  }, [dispatch, props.id_Bus]);
  const { BusID,loadingupdate } = useSelector((state) => state.Bus);
  useEffect(() => {

 
    if (BusID && BusID.id_seats_type) {
      dispatch(fetchAllSeateTypedependNumberSeats(BusID.Seats_number));
    }
  }, [dispatch, BusID, BusID.id_seats_type]);
  useEffect(() => {
    if (BusID.id === props.id_Bus) {
      setValues({
        ...initialFValues,
      });
    }
  }, [BusID, props.id_Bus]);

    const initialFValues = {
      id:BusID.id,
      id_company: props.id_company,
      id_seats_type:BusID.id_seats_type,
      plate_code: BusID.plate_code,
      plate_number: BusID.plate_number,
      Seats_number:BusID.Seats_number,
      type: BusID.type,

    };

    
      
    const {arrayDependfSeats ,SeatType} = useSelector((state) => state.SeatsType);


  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("id_seats_type" in fieldValues)
      temp.id_seats_type = fieldValues.id_seats_type ? "" : "هذا الحقل مطلوب";
    if ("plate_code" in fieldValues)
      temp.plate_code = fieldValues.plate_code ? "" : "هذا الحقل مطلوب";
      if ("plate_number" in fieldValues) {
        if (!fieldValues.plate_number) {
          temp.plate_number = "هذا الحقل مطلوب";
        } else if (!/^\d+$/.test(fieldValues.plate_number)) {
          temp.plate_number = "يرجى إدخال أرقام فقط";
        } else {
          temp.plate_number = "";
        }
      }
      if ("Seats_number" in fieldValues) {
        if (!fieldValues.Seats_number) {
          temp.Seats_number = "هذا الحقل مطلوب";
        } else if (!/^\d+$/.test(fieldValues.Seats_number)) {
          temp.Seats_number = "يرجى إدخال أرقام فقط";
        } else {
          temp.Seats_number = "";
        }
      }
    if ("type" in fieldValues)
      temp.type = fieldValues.type ? "" : "هذا الحقل مطلوب";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(updateBus(values))
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);
    
 
    const handleChange = (e) => {
      setValues((prevValues) => ({
        ...prevValues,
        Seats_number: e.target.value,
      }));
      const data={
        id:props.id_company,
        value:e.target.value,
      }
    dispatch(clearSeatType());
    dispatch(fetchAllSeateTypedependNumberSeats(data));
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Controls.Input
            className="tesr"
            name="plate_number"
            label={
              <Fragment>
                <InputAdornment position="end">
                  <IconButton
                    className="iconplaceholder"
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <img src="img/2fd33edff69c9a7ef5379653d49b8aa1.svg"></img>
                  </IconButton>
                  <span className="placeholdertitle">رقم لائحة الباص</span>
                </InputAdornment>
              </Fragment>
            }
            InputLabelProps={{ shrink: true }} 
            value={values.plate_number}
            onChange={handleInputChange}
            error={errors.plate_number}
          />
          <Controls.Input
            className="tesr"
            name="plate_code"
            label={
              <Fragment>
                <InputAdornment position="end">
                  <IconButton
                    className="iconplaceholder"
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <img src="img/2fd33edff69c9a7ef5379653d49b8aa1.svg"></img>
                  </IconButton>
                  <span className="placeholdertitle">
                    المنطفة التي تنتمي إليها اللائحة
                  </span>
                </InputAdornment>
              </Fragment>
            }
            InputLabelProps={{ shrink: true }} 
            value={values.plate_code}
            onChange={handleInputChange}
            error={errors.plate_code}
          />
           <FormControl>
                <FormLabel id="demo-form-control-label-placement">
                  :نوع الباص
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="type"
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="vip"
                    control={
                      <Radio
                        sx={{
                          color: "#F9B917",
                          "&.Mui-checked": {
                            color: "#F9B917",
                          },
                        }}
                      />
                    }
                    label="رجال اعمال"
                    labelPlacement="start"
                    checked={values.type === "رجال اعمال"} 
                  />
                  <FormControlLabel
                    value="commercial"
                    control={
                      <Radio
                        sx={{
                          color: "#F9B917",
                          "&.Mui-checked": {
                            color: "#F9B917",
                          },
                        }}
                      />
                    }
                    checked={values.type === "عادي"} 
                    label="عادي"
                    labelPlacement="start"
                  />
                </RadioGroup>
              </FormControl>
              {errors.type && <FormHelperText style={{color:'red',marginTop:'-20px'}}>{errors.type}</FormHelperText>}
          <Controls.Input
            className="tesr"
            InputLabelProps={{ shrink: true }} 
            name="Seats_number"
            label={
              <Fragment>
                <InputAdornment position="end">
                  <IconButton
                    className="iconplaceholder"
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <img src="img/BusSetNumber.svg"></img>
                  </IconButton>
                  <span className="placeholdertitle">عدد المقاعد</span>
                </InputAdornment>
              </Fragment>
            }
            value={values.Seats_number}
            onChange={handleChange}
            error={errors.Seats_number}
          />
          {SeatType.length > 0 ? (

          <Controls.SelectBusTypeTwo
           InputLabelProps={{ shrink: true }}
            name="id_seats_type"
            label={
              <Fragment>
                <InputAdornment position="end">
                  <IconButton
                    className="iconplaceholder"
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <img src="img/2fd33edff69c9a7ef5379653d49b8aa1.svg"></img>
                  </IconButton>
             
                  <span className="placeholdertitle">نوع الباص</span>
                </InputAdornment>
              </Fragment>
            }
            value={values.id_seats_type || ''}
            onChange={handleInputChange}
            options={ SeatType  }
            error={errors.id_seats_type}
          />
          ):(
            <Controls.SelectBusType2
           InputLabelProps={{ shrink: true }} 
            name="id_seats_type"
            label={
              <Fragment>
                <InputAdornment position="end">
                  <IconButton
                    className="iconplaceholder"
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <img src="img/2fd33edff69c9a7ef5379653d49b8aa1.svg"></img>
                  </IconButton>
                  <span className="placeholdertitle">نوع الباص</span>
                </InputAdornment>
              </Fragment>
            }
            onChange={handleInputChange}
            options={ arrayDependfSeats || []}
            error={errors.id_seats_type}
          />
          )}
           
        {loadingupdate? (
           <div  className="bottonFullwidth" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>

           <div
          className="load loadingupdate"
          style={{ background: "#F9B917", borderRaduis: "10px" }}
          >
          <div v-if="loading" class="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
          </div>
        </div>
          </div>
        ) : (
        

          <div className="bottonFullwidth" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Controls.Button type="submit" text="تعديل" />
          </div>
       
        )}
        </Grid>
      </Grid>
    </Form>
  );
}
