import React, { useState, useEffect, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";

import { makeStyles } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { insertNotifcations } from "../../redux/notificationslice";
import { fetchTrips } from "../../redux/tripsSlice";
import { useSelector } from "react-redux";

const genderItems = [
  { id: "male", title: "Male" },
  { id: "female", title: "Female" },
  { id: "other", title: "Other" },
];

export default function NotifcationAddForm(props) {
  const { loadingAdd } = useSelector((state) => state.Notifcations);

  const { Trips } = useSelector((state) => state.Trip);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTrips());
  }, [dispatch]);
  const initialFValues = {
    body: "",
    title: "",
    id_trip: "",
    typeNotification: "",
  };

  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("body" in fieldValues)
      temp.body = fieldValues.body ? "" : "هذا الحقل مطلوب";
    if ("title" in fieldValues)
      temp.title = fieldValues.title ? "" : "هذا الحقل مطلوب";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (values.typeNotification === "notificationClients") {
        // If typeNotification is 'notificationClients', set id_trip to null
        setValues((prevValues) => ({
          ...prevValues,
          id_trip: null,
          // Add other fields and their values as needed
        }));
      }

      // Always dispatch the insertNotifcations action after setting the values
      dispatch(insertNotifcations(values));
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);
  return (
    <Form onSubmit={handleSubmit}>
      {/* <Grid container> */}
      <Grid item xs={12}>
        <Controls.Input
          className="tesr"
          name="title"
          label={
            <Fragment>
              <InputAdornment position="end">
                <span className="placeholdertitle">عنوان الإشعار</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.title}
          onChange={handleInputChange}
          error={errors.title}
        />
        <Grid item xs={12} md={12}>
          <TextField
            className="tesr22"
            name="body"
            fullWidth
            multiline
            label="وصف الاشعار"
            InputProps={{
              rows: 8,
            }}
            value={values.body}
            onChange={handleInputChange}
            error={errors.body}
          />
        </Grid>

        <Grid item xs={12} md={12} className="selectnotifcation">
          <FormControl>
            <FormLabel id="demo-form-control-label-placement">
              :اختيار المستلمين
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="typeNotification"
              onChange={handleInputChange}
            >
              <FormControlLabel
                value="notificationTrip"
                control={
                  <Radio
                    sx={{
                      color: "#F9B917",
                      "&.Mui-checked": {
                        color: "#F9B917",
                      },
                    }}
                  />
                }
                label="المسافرين ضمن رحلة محددة"
                labelPlacement="start"
              />
              <FormControlLabel
                value="notificationClients "
                control={
                  <Radio
                    sx={{
                      color: "#F9B917",
                      "&.Mui-checked": {
                        color: "#F9B917",
                      },
                    }}
                  />
                }
                label="كل المستخدمين"
                labelPlacement="start"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {values.typeNotification === "notificationTrip" && (
          <Grid item xs={12} md={12}>
            <Controls.SelectTrip
              name="id_trip"
              label={
                <Fragment>
                  <InputAdornment position="end">
                    <IconButton
                      className="iconplaceholder"
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <img src="img/notifcation/2fd33edff69c9a7ef5379653d49b8aa1.svg"></img>
                    </IconButton>
                    <span className="placeholdertitle">اختيار الرحلة</span>
                  </InputAdornment>
                </Fragment>
              }
              value={values.id_trip}
              onChange={handleInputChange}
              options={Trips}
              error={errors.id_trip}
            />
          </Grid>
        )}
        {loadingAdd ? (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="load loadingupdate"
              style={{ background: "#F9B917", borderRaduis: "10px" }}
            >
              <div v-if="loading" class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Controls.Button type="submit" text="إضافة" />
          </div>
        )}
      </Grid>
    </Form>
  );
}
