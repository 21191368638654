import React, { useState, useEffect, Fragment } from "react";
import { Grid, TextField } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "./useForm";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useDispatch } from "react-redux";
import { insertTrip, setstatus } from "../../redux/tripsSlice";
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  clearTripTypeState,
  clearTripTypeStatefilter,
  fetchTripTypeById,
  fetchTripTypeByIdfilter,
  fetchTripTypeByIdfilter2,
} from "../../redux/tripTypesSlice";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "antd";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as sweetalert from "sweetalert";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { InputLabel } from "@mui/material";
import InputRow from "./inputRow";
import ConfirmDialog4 from "../../component/ConfirmDialog4";
import { insertTripStatic } from "../../redux/tripsSchedularSlice";
import { clearSeatTypefilter, fetchSeateTypeAll } from "../../redux/SeatsTypeSlice";
export default function TripForm(props) {
  const [value, setValue] = React.useState(null);
  const renderInput = (params) => <TextField {...params} name="date" />;
  const [date1, setDate1] = React.useState(null);
  const [time1, setTime1] = React.useState(null);

  const dispatch = useDispatch();
  const initialFValues = {
    driver_name: "",
    driver_assistant_name: "",
    date: "",
    time_h: "",
    time_m: "",
    id_trip_type: "",
    id_seat_type: "",
    id_branch: "",
    type: "",
    transit_seats: [],
    transit_seats_input: "",
    transit_count_seats: 0,
  };
  const { CompaniesData } = useSelector((state) => state.CompanyData);
  const { loading, filteredBranch } = useSelector((state) => state.TripType);
  const { status , loadingAdd } = useSelector((state) => state.Trip);
  const { SeatType,filterTypeSeat } = useSelector((state) => state.SeatsType);
  const [dateError, setDateError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [openStatus, setOpenStatus] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState("direct");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "type",
    inputProps: { "aria-label": item },
  });
  const handleCreate = () => {
    setSelectedValue("a");
  };
 
  const handleExport = () => {
    setSelectedValue("transit");
    // setExportd(true)
  };
  const handleAdd = () => {
    setIinitialFValues1([
      ...initialFValues1,
      {
        id: initialFValues1.length + 1, // Generate a unique ID for the new row
        order: initialFValues1.length + 1, // Increment the order
        id_trip_type: "",
        date: "",
        time_h: "",
        time_m: "",
      },
    ]);
  };
  const handleDateChange = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setDate1(formattedDate);
    setValues((prevValues) => ({
      ...prevValues,
      date: formattedDate,
    }));
    setDateError("");
  };
  // =========generation trip pass =================================================================
  const [initialFValues1, setIinitialFValues1] = useState([
    {
      id: 1,
      order: 1,
      id_trip_type: "",
      date: "",
      time_h: "",
      time_m: "",
    },
  ]);

  const handleRemove = (id) => {
    setIinitialFValues1((prevValues) =>
      prevValues.filter((row) => row.id !== id)
    );
  };

  // =========generation trip pass =================================================================
  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

  

    if ("date" in fieldValues)
      temp.date = fieldValues.date ? "" : "هذا الحقل مطلوب";
    // if ("time" in fieldValues)
    //   temp.time = fieldValues.time ? "" : "هذا الحقل مطلوب";
    if ("id_bus" in fieldValues)
      temp.id_bus = fieldValues.id_bus ? "" : "هذا الحقل مطلوب";
    if ("id_branch" in fieldValues)
      temp.id_branch = fieldValues.id_branch ? "" : "هذا الحقل مطلوب";
    if ("id_trip_type" in fieldValues)
      temp.id_trip_type = fieldValues.id_trip_type ? "" : "هذا الحقل مطلوب";
    if ("type" in fieldValues)
      temp.type = fieldValues.type ? "" : "هذا الحقل مطلوب";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "هل تريد إضافة الرحلة للرحلات المجدولة",
    subTitle: "هل تريد إضافة الرحلة للرحلات المجدولة",
    onConfirm: {},
  });

  useEffect(() => {
    setTimeout(() => {
      setOpenStatus(status);
      if (openStatus === true) {
        // props.setOpenPopup(false)
        setConfirmDialog({
          isOpen: true,
          title: "هل تريد إضافة الرحلة للرحلات المجدولة",
          subTitle: "هل تريد إضافة الرحلة للرحلات المجدولة",
          onConfirm: {},
        });
      }
    }, 200);
  }, [status, setOpenStatus, openStatus]);
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate() && date1 != null && value != null) {
      const currentDate = new Date();
      const cuttentDate = dayjs(currentDate).format("YYYY-MM-DD");
      const selectedDate = values.date;
      const upper = selectedDate >= cuttentDate;
      const same = selectedDate === cuttentDate;
      const lower = selectedDate < cuttentDate;

      if (selectedDate !== null && value !== null) {
        if (values.type === "direct") {
          const data = {
            driver_name: values.driver_name,
            driver_assistant_name: values.driver_assistant_name,
            date: values.date,
            time_h: values.time_h,
            time_m: values.time_m,
            id_trip_type: values.id_trip_type,
            id_seat_type: values.id_seat_type,
            id_branch: values.id_branch,
            branch: values.id_branch,
            type: values.type,
          };
          const data2 = {
            time_h: values.time_h,
            time_m: values.time_m,
            id_trip_type: values.id_trip_type,
            id_branch: values.id_branch,
            branch: values.id_branch,
            type: values.type,
            transit: {
              trip: initialFValues1,
              transit_seats: values.transit_seats,
              transit_count_seats: values.transit_count_seats,
            },
          };
          // dispatch(insertTrip(data));
          dispatch(insertTrip(data)).then(() => {
            dispatch(clearSeatTypefilter());
            dispatch(clearTripTypeStatefilter());
            
          });
          localStorage.setItem("trip_schedular_2", JSON.stringify(data2)); // Save data2
        } else {
          const data = {
            driver_name: values.driver_name,
            driver_assistant_name: values.driver_assistant_name,
            date: values.date,
            time_h: values.time_h,
            time_m: values.time_m,
            id_trip_type: values.id_trip_type,
            id_seat_type: values.id_seat_type,
            id_branch: values.id_branch,
            branch: values.id_branch,
            type: values.type,
            transit: {
              trip: initialFValues1,
              transit_seats: values.transit_seats,
              transit_count_seats: values.transit_count_seats,
            },
          };
          const modifiedData = initialFValues1.map((item) => {
            const { id, order, id_trip_type, time_h, time_m } = item;
            return { id, order, id_trip_type, time_h, time_m };
          });

          const data2 = {
            time_h: values.time_h,
            time_m: values.time_m,
            id_trip_type: values.id_trip_type,
            id_branch: values.id_branch,
            branch: values.id_branch,
            type: values.type,
            transit: modifiedData,
            transit_seats: values.transit_seats,
            transit_count_seats: values.transit_count_seats,
          };
          localStorage.setItem("trip_schedular_2", JSON.stringify(data2)); // Save data2

          dispatch(insertTrip(data)).then(() => {
            dispatch(clearTripTypeState());
            dispatch(clearSeatTypefilter());
            dispatch(clearTripTypeStatefilter());
            
          });
        }
      } else if (!selectedDate) {
        sweetalert({
          text: "يرجى اختيار التاريخ",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        sweetalert({
          text: " يرجى اختيار التاريخ أكبر او يساوي التاريخ الحالي",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
    }
    if (date1 == null) {
      setDateError("يرجى اختيار تاريخ الرحلة");
    }
    if (value == null) {
      setTimeError("يرجى اختيار توقيت الرحلة");
    }
  };

  // useEffect(() => {
  //   dispatch(fetchSeateTypeAll(props.id_comp))
  // }, []);
  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  const handleChange1 = async (e) => {
    const tripTypeId = e.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      id_trip_type: e.target.value,
      trip_price: "",
    }));
    dispatch(fetchTripTypeByIdfilter(e.target.value));
  };
  const handleAddSeat = (e) => {
    const newSeats = e.target.value.split(",").map((s) => Number(s.trim()));
    setValues({
      ...values,
      transit_seats: newSeats,
      transit_seats_input: "",
    });
  };
  const updateStatus = (newStatus) => {
    dispatch(setstatus(newStatus));
  };
  const handleConfirm = () => {
    // Send data here
    const savedData2 = JSON.parse(localStorage.getItem("trip_schedular_2"));
    dispatch(insertTripStatic(savedData2));
    // Set status to false
    updateStatus(false);
    // Reload the window
    // window.location.reload();
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Grid className="createdTrip">
        <ConfirmDialog4
          setOpenStatus={setOpenStatus}
          updateStatus={updateStatus}
          confirmDialog={confirmDialog}
          onConfirm={handleConfirm}
          setConfirmDialog={setConfirmDialog}
        />
        <Grid item xs={12} md={12} sm={12}>
          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row-reverse",
            }}
          >
            <Grid lg={12} md={12} sm={12}>
              <Grid
                lg={12}
                md={12}
                sm={12}
                style={{ display: "flex", flexDirection: "row-reverse" }}
              >
                <Grid lg={6} md={6} sm={6}>
                  <Controls.SelectTrip
                    className="tesr122"
                    name="id_trip_type"
                    label={
                      <Fragment>
                        <InputAdornment position="end">
                          <IconButton
                            className="iconplaceholder"
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <img src="img/path.png"></img>
                          </IconButton>
                          <span className="placeholdertitle">مسار ونوع الرحلة</span>
                        </InputAdornment>
                      </Fragment>
                    }
                    value={values.id_trip_type}
                    onChange={handleChange1}
                    error={errors.id_trip_type}
                    options={CompaniesData.trip_type}
                  />
                </Grid>
                <Grid lg={6} md={6} sm={6} className="trip-select-type">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-form-control-label-placement"
                      name="type"
                      // defaultValue="top"
                      onChange={handleInputChange}
                      style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      <FormControlLabel
                        value="transit"
                        control={
                          <Radio
                            // {...controlProps("transit")}
                            sx={{
                              color: "#f9b917",
                              "&.Mui-checked": {
                                color: "#f9b917",
                              },
                            }}
                          />
                        }
                        label="عبور"
                        labelPlacement="start"
                        onClick={handleExport}
                      />
                      <FormControlLabel
                        value="direct"
                        control={
                          <Radio
                            // {...controlProps('a')}
                            sx={{
                              color: "#f9b917",
                              "&.Mui-checked": {
                                color: "#f9b917",
                              },
                            }}
                          />
                        }
                        label="مباشر"
                        labelPlacement="start"
                        onClick={handleCreate}
                      />
                    </RadioGroup>
                   
                {errors.type && (
                    <p
                      className="error-message"
                      style={{
                        color: "red",
                        fontSize: "13px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "baseline",
                        position: "absolute",
                        top: "75%",
                        left: "10%",
                      }}
                    >
                      {errors.type}
                    </p>
                    
                  )}
                  </FormControl>
                </Grid>
                
              </Grid>

              <Grid
                lg={12}
                md={12}
                sm={12}
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  gap: "10px",
                }}
              >
                <Grid item xs={4} md={4} sm={4}>
                  <Controls.Input
                    className="tesr122"
                    name="driver_name"
                    label={
                      <Fragment>
                        <InputAdornment position="end">
                          <IconButton
                            className="iconplaceholder"
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <img src="img/person.png"></img>
                          </IconButton>
                          <span className="placeholdertitle">اسم السائق</span>
                        </InputAdornment>
                      </Fragment>
                    }
                    value={values.driver_name}
                    onChange={handleInputChange}
                    error={errors.driver_name}
                  />
                </Grid>
                <Grid item xs={4} md={4} sm={4}>
                  <Controls.Input
                    className="tesr122"
                    name="driver_assistant_name"
                    label={
                      <Fragment>
                        <InputAdornment position="end">
                          <IconButton
                            className="iconplaceholder"
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <img src="img/person.png"></img>
                          </IconButton>
                          <span className="placeholdertitle">اسم المعاون</span>
                        </InputAdornment>
                      </Fragment>
                    }
                    value={values.driver_assistant_name}
                    onChange={handleInputChange}
                    error={errors.driver_assistant_name}
                  />
                </Grid>
                <Grid item xs={4} md={4} sm={4}>
                  <Controls.SelectBusType
                    name="id_seat_type"
                    label={
                      <Fragment>
                        <InputAdornment position="end">
                          <IconButton
                            className="iconplaceholder"
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <img src="img/create1.svg"></img>
                          </IconButton>
                          <span className="placeholdertitle">نوع الباص</span>
                        </InputAdornment>
                      </Fragment>
                    }
                    value={values.id_seat_type}
                    onChange={handleInputChange}
                    options={filterTypeSeat || []}
                    error={errors.id_seats_type}
                    className="oloa"
                  />
                </Grid>
                <Grid item xs={5} md={5} sm={5}>
                  <Controls.SelectBranch
                    className="tesr122"
                    name="id_branch"
                    label={
                      <Fragment>
                        <InputAdornment position="end">
                          <IconButton
                            className="iconplaceholder"
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <img src="img/office.png"></img>
                          </IconButton>
                          <span className="placeholdertitle">فروع الشركة</span>
                        </InputAdornment>
                      </Fragment>
                    }
                    value={values.id_branch}
                    onChange={handleInputChange}
                    error={errors.id_branch}
                    options={filteredBranch || []}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row-reverse",
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={5} md={5} sm={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="اختر تاريخ الرحلة"
                        value={date1}
                        onChange={handleDateChange}
                        renderInput={renderInput}
                        views={["year", "month", "day"]}
                      />
                      {dateError && (
                        <p style={{ color: "red", fontSize: "13px" }}>
                          {dateError}
                        </p>
                      )}
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={5} md={5} sm={5} className="timetrip">
                    <FormControl fullWidth className="timediv">
                      <InputLabel htmlFor="time-picker">
                        اختر توقيت الرحلة
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          // value={dayjs(value, 'HH:mm:ss')}
                          // defaultValue={'ادخل القيمة'}
                          format="HH:mm"
                          size="large"
                          title="اختر التاريخ"
                          onChange={(newValue) => {
                            setValue(newValue);
                            const currentTimehours =
                              dayjs(newValue).format("HH");
                            const currentTimeminute =
                              dayjs(newValue).format("mm");
                            setValues((prevValues) => ({
                              ...prevValues,
                              time_h: currentTimehours,
                              time_m: currentTimeminute,
                            }));
                            // setTimeError("")
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid>
                {selectedValue == "transit" ? (
                  <>
                    <hr></hr>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontSize: "20px", padding: "10px 0px" }}>
                        رحلات العبور
                      </p>
                      <p
                        style={{
                          fontSize: "15px",
                          padding: "10px 0px",
                          color: "#f9b917",
                          cursor: "pointer",
                          display:'flex',
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                        onClick={handleAdd}
                      >
                        إضافة رحلة عبور <AddCircleOutlineIcon />
                      </p>
                    </Grid>
                    {initialFValues1.map((item, index) => (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        style={{
                          display: "flex",
                          marginBottom: "40px",
                          marginTop: "10px",
                        }}
                      >
                        <Grid item xs={12} md={12} key={index}>
                          <InputRow
                            key={index}
                            initialFValues={initialFValues1}
                            setIinitialFValues1={setIinitialFValues1}
                            index={index}
                            item={item}
                            handleChange={handleChange}
                            handleRemove={() => handleRemove(item.id)}
                            handleAdd={handleAdd}
                          />
                        </Grid>
                      </Grid>
                    ))}
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item xs={5} md={5} lg={5}>
                        <TextField
                          id="outlined-textarea"
                          label={
                            <Fragment>
                              <span id="number1">{"عدد المقاعد"}</span>
                            </Fragment>
                          }
                          name="transit_count_seats"
                          variant="outlined"
                          value={values.transit_count_seats}
                          onChange={handleInputChange}
                          // onChange={(e) => handleInputChange11(e, values[index].id)}
                          error={errors.transit_count_seats}
                        />
                      </Grid>
                      <Grid item xs={5} md={5} lg={5}>
                        <TextField
                        InputLabelProps={{ shrink: true }} // Add this line
                          id="outlined-textarea"
                          label={
                            <Fragment>
                              <span id="number1">{"ارقام  المقاعد"}</span>
                            </Fragment>
                          }
                          name="transit_seats_input"
                          
                          variant="outlined"
                          // value={values.transit_seats_input || ''}
                          // onChange={handleInputChange}
                          onChange={(e) => handleAddSeat(e)}
                          // onChange={(e) => handleInputChange11(e, values[index].id)}
                          error={errors.transit_seats_input}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12} md={12}>
            {loadingAdd ? (
              <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="load loadingupdate"
              style={{ background: "#F9B917", borderRaduis: "10px" }}
            >
              <div v-if="loading" class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
              </div>
            </div>
          </div>
            ) : (
              <div className="bottonFullwidth"   style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
                <Controls.Button type="submit" text="إضافة" />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}
