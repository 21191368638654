import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchAllprofit = createAsyncThunk(
  "Profits/fetchAllprofit",
  async ( thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res =  axios.get(`${BaseUrl}/getProfitCompanies`, {
        withCredentials: true,
      });
      const result =  res.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchprofitByid = createAsyncThunk(
  "Profits/fetchprofitByid",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getProfitCompany/${id}`, {
        withCredentials: true,
      });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateProfite = createAsyncThunk(
  "Profits/updateProfite",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`${BaseUrl}/updateProfitCompany/${data.id}`,data, {
        withCredentials: true,
      });
      sweetalert({
        text: 'تم التعديل بنجاح',
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const ProfitsSlice = createSlice({
  name: "Profits",
  initialState: {
    Profit: [],
    Profitid: '',
    Profitupdate: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearProfits: (state) => {
      state.Profit = [];
      state.Profitid = '';
      state.Profitupdate = [];
      state.loading = false;
      state.loadingUpdate = false;
      state.error = null;
    },
  },
  extraReducers: {
    // fetch All Profit
    [fetchAllprofit.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchAllprofit.fulfilled]: (state, action) => {
      state.Profit = action.payload;
      state.loading = false;
    },
    [fetchAllprofit.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    // fetch All profit by id 
    [fetchprofitByid.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchprofitByid.fulfilled]: (state, action) => {
      state.Profitid = action.payload;
      state.loading = false;
    },
    [fetchprofitByid.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    // update Profit by id 
    [updateProfite.pending]: (state, action) => {
      state.loadingUpdate = true;
      state.error = null;
    },
    [updateProfite.fulfilled]: (state, action) => {
      state.Profitupdate = action.payload;
      state.loadingUpdate = false;
    },
    [updateProfite.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingUpdate = false;
    },



  },
});
export const { clearProfits } = ProfitsSlice.actions;
export default ProfitsSlice.reducer;
