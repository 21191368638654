import {  useNavigate } from "react-router-dom";
import { tokens } from "../../styles/theme";
import React, { useEffect, useState } from "react";
import { Box,  useTheme, Button } from "@mui/material";
import { Grid } from "@material-ui/core";
import {  Select, MenuItem } from "@material-ui/core";
import {
  DataGridPro,
} from "@mui/x-data-grid-pro";
import {
  GridFooterContainer,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridPagination,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../../component/controls/Controls";
import PopupStop from "../../component/PopupStop";
import PathTripForm from "./PathTripForm";
import PathTripFormEdit from "./PathTripFormEdit";
import PopupCreate from "../../component/PopupCreate";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { deletePath, fetchPaths } from "../../redux/PathSlice";
import ConfirmDialog from "../../component/ConfirmDialogCompany";
import StopTripType from "./StopTripType";
import { fetchAllCity } from "../../redux/CitySlice";
export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const {  } = useSelector((state) => state.Paths);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function CustomFooter() {
  return (
    <GridFooterContainer>
      <CustomPagination />
      <GridPagination />
    </GridFooterContainer>
  );
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    flexDirection: "row-reverse",
    paddingRight: "8px",
    height: "45px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
    marginRight: "3px",
  },
  "@media (min-width: 600px) and (max-width:1280px)": {
    searchDesign: {
      marginBottom: "15px",
    },
  },
  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  editButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#000002 !important",
    fontSize: "12px",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  deleteButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#F9B917 !important",
    fontSize: "12px ",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  editImg: {
    width: "15px",
  },
  Marginbox1: {
    maxWidth: "100% !important",
    flexBasis: "100%",
  },
});

export default function TranspotCompanyPaths({ id_company ,setShortcut}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const[rowCount1,setRowCount1] = useState();

  useEffect(() => {
    let typedWord = "";
    let typedWord2 = "";
  
    const handleKeyDown = (event) => {
      if (/^[a-zA-Z]$/.test(event.key)) {
        typedWord += event.key.toLowerCase();
        typedWord2 += event.key;
  
        const lastThreeChars = typedWord.substring(typedWord.length - 3);
  
        if (lastThreeChars === 'add') {
          localStorage.setItem("displayshortcut", "1");
          setOpenPopup(true);
          setRecordForEdit(null);
          setShortcut(1);
          typedWord = '';
        } 
      }
    };
  
    document.addEventListener("keydown", handleKeyDown);
  
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  },[]);


  const AddPath =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "add.path");

  const UpdatePath =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "update.path");

  const DeletePath =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "hard.delete.path"
    );

  const AddPathdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some((permission) => permission === "add.path");

  const UpdatePathdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "update.path"
    );

  const DeletePathdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "hard.delete.path"
    );

  const {  Paths,loadingdelete } = useSelector((state) => state.Paths);


          useEffect(() => {
            dispatch(fetchAllCity());
            dispatch(fetchPaths(id_company));
    setRowCount1(Paths.length)
            
  }, [dispatch]);
  const [openPopup1, setOpenPopup1] = useState(false);
  const [recordForEdit1, setRecordForEdit1] = useState(null);
  const [openPopupStop, setOpenPopupStop] = useState(false);
  const [clickedEmployeeId, setClickedEmployeeId] = useState(null);

  const rows = [
    {
      id: 1,
      lastName: "Snow",
      firstName: "Jon",
      age: 35,
      image: "img/vuesax-linear-eye.svg",
    },
    {
      id: 2,
      lastName: "Lannister",
      firstName: "Cersei",
      age: 42,
      image: "img/vuesax-linear-eye.svg",
    },
    {
      id: 3,
      lastName: "Lannister",
      firstName: "Jaime",
      age: 45,
      image: "img/vuesax-linear-eye.svg",
    },
    {
      id: 4,
      lastName: "Stark",
      firstName: "Arya",
      age: 16,
      image: "img/vuesax-linear-eye.svg",
    },
    {
      id: 5,
      lastName: "Targaryen",
      firstName: "Daenerys",
      age: null,
      image: "img/vuesax-linear-eye.svg",
    },
    {
      id: 6,
      lastName: "Melisandre",
      firstName: null,
      age: 150,
      image: "img/vuesax-linear-eye.svg",
    },
    {
      id: 7,
      lastName: "Clifford",
      firstName: "Ferrara",
      age: 44,
      image: "img/vuesax-linear-eye.svg",
    },
    {
      id: 8,
      lastName: "Frances",
      firstName: "Rossini",
      age: 36,
      image: "img/vuesax-linear-eye.svg",
    },
    {
      id: 9,
      lastName: "Roxie",
      firstName: "Harvey",
      age: 65,
      image: "img/vuesax-linear-eye.svg",
    },
  ];
  const [filteredData, setFilteredData] = useState(Paths);
  const classes = useStyles();
  const [age, setAge] = useState("all");
  const [searched, setSearched] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [selectedfromcityId, setSelectedFromCityId] = useState(null);
  const [selectedtocityId, setSelectedToCityId] = useState(null);
  const { Cities } = useSelector((state) => state.City);
  useEffect(() => {
    dispatch(fetchAllCity())
  }, []);

  useEffect(() => {
    setFilteredData(
      age === "all" ? Paths : Paths.filter((dt) => dt.age === age)
    );
  }, [age]);
  const requestSearch = (searchedVal) => {
    const filteredRows = filteredData.filter((row) => {
      return row.lastName.toLowerCase().includes(searchedVal.toLowerCase());
    });

    setFilteredData(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const onHandleReset = () => {
    setSelectedFromCityId(null);
    setSelectedToCityId(null);
  };
  function handleDelete(id) {
    setConfirmDialog({
      isOpen: true,
      title: "أنت على وشك حذف مسار الرحلة",
      img: "img/emp/Inbox cleanup-rafiki.svg",
      onConfirm: () => {
        dispatch(deletePath(id));
      },
    });
  }
  function filterPaths(Paths) {
    if (selectedfromcityId && selectedtocityId) {
      return Paths.filter(
        path =>
          path.idCityFrom === selectedfromcityId &&
          path.idCityTo === selectedtocityId
      );
    } else if (selectedfromcityId) {
      return Paths.filter(path => path.idCityFrom === selectedfromcityId);
    } else if (selectedtocityId) {
      return Paths.filter(path => path.idCityTo === selectedtocityId);
    } else {
      return Paths;
    }
  }
  const filteredPath= filterPaths(Paths);
  useEffect(() => {
    // Check if filteredPath is defined before accessing its length
    if (filteredPath) {
      setRowCount1(filteredPath.length);
    } else {
      // Handle the case where filteredPath is undefined
      // You might want to set a default value or perform other actions
      setRowCount1(0);
    }
  }, [filteredPath]);
  function EditToolbar(props) {
    return (
      <Grid container xs={12} className={classes.Marginbox1}>
 
        <Grid lg={4} md={6} sm={6} className={classes.AddDesign}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className={` ${selectedfromcityId ? 'selects11' : 'selects1'}`}
            text="بحث"
            value={selectedfromcityId}
            onChange={(e) => setSelectedFromCityId(e.target.value)}

          >
          
          {Cities.map(city =>(
            <MenuItem value={ city.id} key={city.id}>
              <span>{city.name_ar}</span>
            </MenuItem>
          ))}
          </Select>
        </Grid>
        
        <Grid lg={4} md={6} sm={6} className={classes.AddDesign}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className={` ${selectedtocityId ? 'selects22' : 'selects2'}`}
            value={selectedtocityId}
            onChange={(e) => setSelectedToCityId(e.target.value)}
            >
            {Cities.map(city =>(
            <MenuItem value={city.id} key={city.id}>
              <span>{city.name_ar}</span>
            </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid lg={1} md={1} sm={3} className={classes.AddDesign}>
          <Button className="reloadButton1" onClick={onHandleReset}>
            <img src="img/reload.svg" />
          </Button>
        </Grid>
        {AddPath === true || AddPathdefault === true ? (
          <Grid lg={3} md={4} sm={3} className={classes.AddDesign}>
            <div className="bottonFullwidth1">
              <Controls.Button
                type="submit"
                text="إضافة"
                onClick={() => {
                  setOpenPopup(true);
                  setRecordForEdit(null);
                }}
              />
            </div>
          </Grid>
        ) : null}
        <PopupCreate
          title="إضافة مسار"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setDisplayshortcut={setShortcut}
        >
          <PathTripForm id_company={id_company} recordForEdit={recordForEdit} />
        </PopupCreate>
      </Grid>
    );
  }

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [rowModesModel, setRowModesModel] = React.useState({});
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const triggerText = "تعديل";
  const triggerText1 = "اضافة قيمة";
  const notesEditorOptions = { height: 100 };
  const processRowUpdate = (newRow) => {
  };
  const getPageSize = () => {
    if (window.innerWidth < 992) {
      return 5; // Set a smaller pageSize for smaller screens
    } else if (window.innerWidth < 1566) {
      return 7; // Default pageSize for larger screens
    } else if (window.innerWidth < 1760) {
      return 8; // Default pageSize for larger screens
    } else if (window.innerWidth < 1930) {
      return 10; // Default pageSize for larger screens
    }
  };
  useEffect(() => {
    getPageSize();
  });

  const handleViewValue = (id) => () => {};

  const rowsWithRowNumber = Array.isArray(Paths)
  ?  Paths.filter((row) => row !== undefined).map((row, index) => ({
      ...row,
      id: row.id !== null ? row.id : index, // Use the row's existing id or the index as a fallback
      rowNumber: row.id !== null ? row.id : index,
    }))
  : [];

  const columns = [
    {
      field: "CityFrom",
      sortable: false,
      headerName: "الانطلاق",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "CityTo",
      sortable: false,
      headerName: "الوجهة",
      flex: 1,
      headerAlign: "center",
      align: "center",
   
    },
    {
      field: "type",
      sortable: false,
      headerName: "نوع المسار",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "stations",
      sortable: false,
      resizable: true,
      headerName: "أماكن التوقف",
      // width: 20,
      // minWidth: 50,
      // maxWidth: 500,
      minWidth: 30,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: ({ id }) => {
        return (
          <>
            <img
              style={{ cursor: "pointer" }}
              src="img/vuesax-linear-eye.svg"
              className={classes.editImg}
              onClick={() => {
                setOpenPopupStop(true);
                setRecordForEdit(null);
                setClickedEmployeeId(id);
              }}
              alt="img-eye-stop-starion"
            />
            <PopupStop
              title="أماكن التوقف"
              openPopup={openPopupStop}
              setOpenPopup={setOpenPopupStop}
            >
              <StopTripType
                recordForEdit={recordForEdit}
                id_path_row={clickedEmployeeId}
              />
            </PopupStop>
          </>
        );
      },
    },
    {
      field: "action",
      headerAlign: "center",
      headerName: "العمليات",
      flex: 1,
      sortable: false,
      align: "center",
      renderCell: ({ id }) => {
        return (
          <Box>
            {UpdatePath === true || UpdatePathdefault === true ? (
              <Button
                className={classes.editButton}
                onClick={() => {
                  setOpenPopup1(true);
                  setRecordForEdit1(null);
                  setClickedEmployeeId(id);
                }}
              >
                تعديل
                <img
                  src="img/edit.svg"
                  className={classes.editImg}
                  alt="delete icon"
                ></img>
              </Button>
            ) : null}
            {DeletePath === true || DeletePathdefault === true ? (
              <Button
                className={classes.deleteButton}
                onClick={() => {
                  handleDelete(id);
                }}
              >
                حذف
                <img
                  src="img/delete.svg"
                  className={classes.editImg}
                  alt="delete icon"
                ></img>
              </Button>
            ) : null}
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
              loadingdelete={loadingdelete}
            />

            <PopupCreate
              title="تعديل مسار"
              openPopup={openPopup1}
              setOpenPopup={setOpenPopup1}
              setDisplayshortcut={setShortcut}
            >
              <PathTripFormEdit
                id_company={id_company}
                recordForEdit={recordForEdit}
                id_path_row={clickedEmployeeId}
              />
            </PopupCreate>
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <Box
        className="box-triptype table-accounting"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },

          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "none",
            fontSize: "15px !important",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "15px !important",
            color: `${colors.primary[1000]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.white[100],
          },
        }}
      >

          <DataGridPro
            rows={filteredPath || []}
            columns={columns}
            className="field-table"
            editMode="row"
            // pageSize={getPageSize()}
            // pagination
            pageSize={15}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            rowCount={rowCount1}
            rowModesModel={rowModesModel}
            rowModel="server"
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            components={{
              Toolbar: EditToolbar,
              Footer: CustomFooter,
            }}
            // localeText={{
            //   footerRowSelected: CustomPagination,
            // }}
          />
   
      </Box>
    </Box>
  );
}
