import React, { useEffect, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCity } from "../../redux/CitySlice";
import { insertOfficeCompany } from "../../redux/Office";
export default function OfficeAddForm(props) {
  const { Cities } = useSelector((state) => state.City);
  const { loading } = useSelector((state) => state.Offices);
  const initialFValues = {
    name_ar: "",
    address: "",
    phone_number: "",
    mobile: "",
    minute_reduction: "",
    id_company: props.id_company,
    id_city: "",
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllCity());
  }, [dispatch]);
  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name_ar" in fieldValues)
      temp.name_ar = fieldValues.name_ar ? "" : "هذا الحقل مطلوب";
    if ("minute_reduction" in fieldValues)
      temp.minute_reduction = fieldValues.minute_reduction ? "" : "هذا الحقل مطلوب";
    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "هذا الحقل مطلوب";
    if ("id_city" in fieldValues)
      temp.id_city = fieldValues.id_city ? "" : "هذا الحقل مطلوب";
      if ("phone_number" in fieldValues) {
        if (!fieldValues.phone_number) {
          temp.phone_number = "هذا الحقل مطلوب";
        } else if (!/^\d+$/.test(fieldValues.phone_number)) {
          temp.phone_number = "يرجى إدخال أرقام فقط";
        } else {
          temp.phone_number = "";
        }
      }

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      
      dispatch(insertOfficeCompany(values));
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  return (
    <Form onSubmit={handleSubmit}>
      {/* <Grid container> */}
      <Grid item xs={12}>
        <Controls.Input
          className="tesr"
          name="name_ar"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/office/vuesax-linear-building.svg"></img>
                </IconButton>
                <span className="placeholdertitle"> اسم المكتب </span>
              </InputAdornment>
            </Fragment>
          }
          value={values.name_ar}
          onChange={handleInputChange}
          error={errors.name_ar}
        />
        <Controls.Input
          className="tesr"
          name="phone_number"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/office/vuesax-linear-call-calling.svg"></img>
                </IconButton>
                <span className="placeholdertitle">رقم الهاتف الأول</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.phone_number}
          onChange={handleInputChange}
          error={errors.phone_number}
        />
        <Controls.Input
          className="tesr"
          name="mobile"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/office/vuesax-linear-call-calling.svg"></img>
                </IconButton>
                <span className="placeholdertitle">رقم الهاتف الثاني</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.mobile}
          onChange={handleInputChange}
          error={errors.mobile}
        />
        <Controls.Input
          className="tesr"
          name="minute_reduction"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/typeTrip/3.svg" alt="img-trip-time"></img>
                </IconButton>
                <span className="placeholdertitle">المدة الذي يحق خلالها إلغاء الحجز</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.minute_reduction}
          onChange={handleInputChange}
          error={errors.minute_reduction}
        />
        <Controls.Select
         className="tesr"
          name="id_city"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/office/vuesax-linear-building.svg"></img>
                </IconButton>
                <span className="placeholdertitle">المحافظة</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.id_city}
          onChange={handleInputChange}
          options={Cities}
          error={errors.id_city}
        />

        <Controls.Input
          className="tesr"
          name="address"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/office/vuesax-linear-building.svg"></img>
                </IconButton>
                <span className="placeholdertitle">العنوان</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.address}
          onChange={handleInputChange}
          error={errors.address}
        />
        {loading ? (
            <div  className="bottonFullwidth" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>

            <div
           className="load loadingupdate"
           style={{ background: "#F9B917", borderRaduis: "10px" }}
           >
           <div v-if="loading" class="spinner">
             <div class="rect1"></div>
             <div class="rect2"></div>
             <div class="rect3"></div>
             <div class="rect4"></div>
             <div class="rect5"></div>
           </div>
         </div>
           </div>
        ) : (
          <div className="bottonFullwidth" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Controls.Button type="submit" text="إضافة" />
          </div>
        )}
      </Grid>
    </Form>
  );
}
