import React, { useEffect, Fragment, useState } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { insertTripType } from "../../redux/tripTypesSlice";
import { fetchPaths, fetchPathsfilter } from "../../redux/PathSlice";
import { useSelector } from "react-redux";

export default function TypeTripForm(props) {
  const dispatch = useDispatch();
  // const [isLoading, setIsLoading] = useState(false); // Add this state
  useEffect(() => {
    dispatch(fetchPaths(props.id_company));
  }, [dispatch, props.id_company]);
  const { Paths, PathFilter } = useSelector((state) => state.Paths);
  const { loading } = useSelector((state) => state.TripType);

  const TypeTrip = [
    { id: "رجال اعمال", title: "رجال اعمال" },
    { id: "عادي", title: "عادي" },
  ];

  const initialFValues = {
    id_company: props.id_company,
    id_path: "",
    type: "",
    // price:1000,
    // number_hours_before_cancellation: "",
    // return_reduction_upon_cancellation: "",
  };

  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("type" in fieldValues)
      temp.type = fieldValues.type ? "" : "هذا الحقل مطلوب";

    // if ("price" in fieldValues) {
    //   const phoneNumberRegex = /^[0-9]/; // Assuming a 10-digit phone number pattern
    //   if (!fieldValues.price.match(phoneNumberRegex)) {
    //     temp.price = "الإدخال المطلوب ارقام";
    //   } else {
    //     temp.price = "";
    //   }
    // } else {
    //   temp.price = ""; // Set the validation message to empty if the field is not present
    // }
    if ("id_path" in fieldValues)
      temp.id_path = fieldValues.id_path ? "" : "هذا الحقل مطلوب";

    // if ("return_reduction_upon_cancellation" in fieldValues) {
    //   const phoneNumberRegex = /^[0-9]/; // Assuming a 10-digit phone number pattern

    //   if (
    //     !fieldValues.return_reduction_upon_cancellation.match(phoneNumberRegex)
    //   ) {
    //     temp.return_reduction_upon_cancellation = "الإدخال المطلوب أرقام";
    //   } else {
    //     const value = parseFloat(
    //       fieldValues.return_reduction_upon_cancellation
    //     );
    //     if (value < 0.1 || value > 1) {
    //       temp.return_reduction_upon_cancellation =
    //         "يجب أن تكون القيمة بين 0.1 و 1";
    //     } else {
    //       temp.return_reduction_upon_cancellation = "";
    //     }
    //   }
    // } else {
    //   temp.return_reduction_upon_cancellation = ""; // Set the validation message to empty if the field is not present
    // }
    // if ("number_hours_before_cancellation" in fieldValues) {
    //   const phoneNumberRegex = /^[0-9]/; // Assuming a 10-digit phone number pattern

    //   if (
    //     !fieldValues.number_hours_before_cancellation.match(phoneNumberRegex)
    //   ) {
    //     temp.number_hours_before_cancellation = "الإدخال المطلوب أرقام";
    //   } else if (parseInt(fieldValues.number_hours_before_cancellation) > 100) {
    //     temp.number_hours_before_cancellation =
    //       "يجب أن تكون القيمة أصغر أو تساوي 100";
    //   } else {
    //     temp.number_hours_before_cancellation = "";
    //   }
    // } else {
    //   temp.number_hours_before_cancellation = ""; // Set the validation message to empty if the field is not present
    // }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  const [arraytypecart, setArrayTypeCart] = useState([
    localStorage.getItem("type_cart"),
  ]);
  const [price, setprice] = useState(0);
  // const parsedData = arraytypecart ? JSON.parse(arraytypecart) : [];
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // dispatch(insertTripType(values));
      if (values.type === "رجال اعمال") {
        // Perform any updates needed for "رجال اعمال"
        // For example, setting a 'VIP' status or modifying any other property
        values.type = 'vip'; // This is just an example; adjust according to your needs
      
        props.setDataTripType(values)
        props.setActiveStep(1)
      }else{
        values.type = 'commercial'; // This is just an example; adjust according to your needs
      
        props.setDataTripType(values)
        props.setActiveStep(1)
      }
      
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  const handleChange = (e) => {
    const value = {
      id: props.id_company,
      path: e.target.value,
    };
    setValues((prevValues) => ({
      ...prevValues,
      type: e.target.value,
    }));
  
    dispatch(fetchPathsfilter(value));
  };
  // In your handleChange function
  const [selectedType, setSelectedType] = useState("");
  const handletype = (e) => {
    const { name, value } = e.target;
    setSelectedType(e.target.value);

    // const result = parsedData.find((option) => option.id === value).price;
    // setprice(result);
    // setValues({
    //   ...values,

    //   price: result,
    // });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Controls.SelectTypeTrip
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/typeTrip/1.svg" alt="img-trip-type"></img>
                </IconButton>
                <span className="placeholdertitle"> نوع الرحلة </span>
              </InputAdornment>
            </Fragment>
          }
          value={values.type}
          onChange={handleChange}
          error={errors.type}
          options={TypeTrip}
        />
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            gap: "10px",
          }}
          lg={12}
          md={12}
        >
        </Grid>
        {/* <Controls.Input
          className="tesr"
          name="return_reduction_upon_cancellation"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/typeTrip/3.svg" alt="img-trip-price"></img>
                </IconButton>
                <span className="placeholdertitle">
                  {" "}
                  نسب القطع عند الغاء الرحلة
                </span>
              </InputAdornment>
            </Fragment>
          }
          value={values.return_reduction_upon_cancellation}
          onChange={handleInputChange}
          error={errors.return_reduction_upon_cancellation}
        />
        <Controls.Input
          className="tesr"
          name="number_hours_before_cancellation"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/typeTrip/3.svg" alt="img-trip-time"></img>
                </IconButton>
                <span className="placeholdertitle">
                  المدة التي يتم خلالها القطع عند الالغاء
                </span>
              </InputAdornment>
            </Fragment>
          }
          value={values.number_hours_before_cancellation}
          onChange={handleInputChange}
          error={errors.number_hours_before_cancellation}
        /> */}

        <Controls.SelectPath
          name="id_path"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/typeTrip/1.svg" alt="img-trip-type"></img>
                </IconButton>
                <span className="placeholdertitle">مسار الرحلة</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.id_path}
          onChange={handleInputChange}
          options={PathFilter || []}
          error={errors.id_path}
        />
                <div
                  className="button-passenger"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Controls.Button
                    // onClick={handleNext}
                    onClick={handleSubmit}
                    type="submit"
                    text="التالي"
                  />
                  </div>
     
      </Grid>
    </Form>
  );
}
