import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";
export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const UserLogin = createAsyncThunk(
  "auth/login",
  async (frmData, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BaseUrl}/login`, frmData, {
        withCredentials: true,
      });
      const {
        user_id,
        "roles.permissions": rolepermissions,
        permission,
        roles_name,
        first_name,
        last_name,
        branch_name,
        id_branch,
      } = res.data.data;
      const rolePermissionsNames = rolepermissions.map(
        (permission) => permission.name
      );
      const PermissionsNames = permission.map((permission) => permission.name);
      if (res.data.status === "success") {
        sweetalert(res.data.message, {
          className: "alert-div",
          button: {
            text: "تم",
          },
        });

        return {
          user_id,
          roles_name,
          PermissionsNames,
          rolePermissionsNames,
          first_name,
          last_name,
          branch_name,
          id_branch,
        };
      } else if (res.data.status === false || res.data.status === "false") {
        sweetalert(res.data.message, {
          className: "alert-div",
          button: {
            text: "تم",
          },
        });
        localStorage.setItem("isLoggedIn", "false"); // or remove this line since "isLoggedIn" is already false
        return rejectWithValue(res.data.message);
      } else {
        return rejectWithValue(res.data.message);
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          message: "خطأ في البريد الالكتروني او كلمة المرور",
        });
      }
    }
  }
);
export const UserLogout = createAsyncThunk(
  "auth/UserLogout",
  async ({ rejectWithValue }) => {
    try {
      const res = await axios.post(`${BaseUrl}/logout`, {
        withCredentials: true,
      });

      // Handle the response here, such as checking for success, clearing local storage, and redirecting.
      if (res.data.success) {
        // Clear local storage, redirect, or perform any other necessary actions.
        // Example:
        localStorage.removeItem("isLoggedIn");
        window.location.href = "/"; // Redirect to the home page.
      } else {
        return rejectWithValue(res.data.message);
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ message: "An error occurred." });
      }
    }
  }
);
const loginSlice = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    isAuth: false,
    error: "",
    user: null, // Initialize user as null
  },
  reducers: {
    clearauth: (state) => {
      state.user = null;
      state.isAuth = false;
      state.isLoading = false;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UserLogin.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(UserLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuth = true;
        state.user = action.payload;
        state.error = "";
      })
      .addCase(UserLogout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.user = null;
      })

      .addCase(UserLogout.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(UserLogout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuth = false;
        state.user = action.payload;
        state.error = "";
      })
      .addCase(UserLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.user = null;
      });
  },
});

export const { loginPending, loginSuccess, loginFail, saveAuthData, logout } =
  loginSlice.actions;
export const { clearauth } = loginSlice.actions;
export default loginSlice.reducer;
