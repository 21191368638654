import React, { useState, useEffect, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";

import { makeStyles } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { updateCompany } from "../../redux/companyTransportDataSlice";
import { useDispatch } from "react-redux";
import {
  PasswordUpdateEmployee,
  UpdateEmployee,
  UpdateEmployeePassword,
} from "../../redux/employeeSlice";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({

}));

const genderItems = [
  { id: "male", title: "Male" },
  { id: "female", title: "Female" },
  { id: "other", title: "Other" },
];

export default function EmployeeForm(props) {
  const dispatch = useDispatch();
  const { loadingpassword } = useSelector((state) => state.Employee);

  const initialFValues = {
    user_id: props.id_employee,
    password: "",
  };
  const classes = useStyles();
  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("fullName" in fieldValues)
      temp.fullName = fieldValues.fullName ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    if ("mobile" in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required.";
    if ("departmentId" in fieldValues)
      temp.departmentId =
        fieldValues.departmentId.length != 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (validate()) {
    dispatch(PasswordUpdateEmployee(values));
    // }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  return (
    <Form onSubmit={handleSubmit}>
      {/* <Grid container> */}
      <Grid item xs={12}>
        <Controls.Input
          className="tesr"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/lock.svg"></img>
                </IconButton>
                <span className="placeholdertitle">كلمة المرور الجديدة</span>
              </InputAdornment>
            </Fragment>
          }
          name="password"
          value={values.password}
          onChange={handleInputChange}
          error={errors.password}
        />

        {loadingpassword ? (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="load loadingupdate"
              style={{ background: "#F9B917", borderRaduis: "10px" }}
            >
              <div v-if="loading" class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Controls.Button type="submit" text="تعديل" />
          </div>
        )}
      </Grid>
   
    </Form>
  );
}
