import {Grid } from '@material-ui/core'
import React, { useState, useRef,useEffect } from 'react';
import './addPrivacyPolicy.css'
import JoditEditor from 'jodit-react';
import axios from 'axios';
import * as sweetalert from "sweetalert";
import { useNavigate } from "react-router-dom";
import Controls from '../../component/controls/Controls';
import { fetchPriveePolice, insertPriveePolice } from '../../redux/prvicepolice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { insertprvicepoliceCompany } from '../../redux/prvicepoliceCompany';
import Header from '../../component/Header';
function AddPrivacyPolicy({ placeholder }) {
    const editor = useRef(null);
    const { loading,loadingAdd, PriveePolice } = useSelector((state) => state.PriveePolice);
    const [content, setContent] = useState(PriveePolice?.content);
	const [notes, setNotes] = useState(PriveePolice?.notes);
    const navigate = useNavigate();
    const dispatch = useDispatch();
   useEffect(()=>{
    dispatch(fetchPriveePolice())
  },[dispatch])
   useEffect(()=>{
  },[PriveePolice])
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    const bodyFormData = new FormData();
    bodyFormData.append("content", content);
    bodyFormData.append("notes", notes);
    dispatch(insertPriveePolice(bodyFormData))       
    }
  return (
    <div className="main-div">
    <div>
    {loadingAdd ? (
            <div className="spinner1-box">
              <svg class="spinner1" viewBox="0 0 50 50">
                <circle
                  class="path1"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </div>
          ) : (
    <form className="form-group new-design" method="post" 
        enctype="multipart/form-data" onSubmit={handleSubmit}  style={{padding:"0 50px"}}>

         <div className="editor">
         <JoditEditor
			ref={editor}
      value={loading ? '': PriveePolice?.content}
			
			tabIndex={13} // tabIndex of textarea
			onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
		/>  
        </div>
         <h3 className="titlepage">إضافة ملاحظات</h3>
         <div className="editor">
         <JoditEditor
			ref={editor}
			value={loading ? '': PriveePolice?.notes}
			tabIndex={3} // tabIndex of textarea
			onBlur={newContent => setNotes(newContent)} // preferred to use only this option to update the content for performance reasons
		/>
        </div>
 
       <Grid item md={6} xs={12} spacing={2} class="div-btn-submit"> 
              <div className="bottonFullwidth">
        
        <Controls.Button type="submit" text="إضافة" />
      </div> 
        </Grid>
    </form>
          )}
    </div>
    </div>
  )
}

export default AddPrivacyPolicy