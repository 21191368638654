import React, { useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { fetchAllCity } from "../../redux/CitySlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { useState } from "react";
import Controls from "../../component/controls/Controls";
import {
  fetchCompanyEmpBranch,
  insertCompanyEmpBranch,
} from "../../redux/AddCompanyEmpBranch";
function CheckboxBranch({ id_employee }) {
  const { loading, CompanyBranch, error } = useSelector(
    (state) => state.CompanyData
  );
  const { CompanyEmpBranchID, loadingAdd } = useSelector(
    (state) => state.Branch
  );
  const { user } = useSelector((state) => state.auth);
  //Add Permission
  const AddCompanyBranch =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "add.company.emp.branch"
    );

  // Permission as default
  const AddCompanyBranchdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "add.company.emp.branch"
    );

  const [checkedBranch, setCheckedBranch] = useState([]); // State to manage checked cities
  const dispatch = useDispatch();

  useEffect(() => {
    if (CompanyEmpBranchID && CompanyEmpBranchID.id_branch) {
      setCheckedBranch(CompanyEmpBranchID.id_branch);
    }
  }, [CompanyEmpBranchID]);

  // Handle checkbox change event
  const handleCityCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const branchId = parseInt(value); // Convert the value to a number
    if (checked) {
      setCheckedBranch((prevCheckedCities) => [...prevCheckedCities, branchId]);
    } else {
      setCheckedBranch((prevCheckedCities) =>
        prevCheckedCities.filter((branch) => branch !== branchId)
      );
    }
  };
  const handleSave = () => {
    const data = {
      id_user: id_employee,
      id_branch: checkedBranch,
    };
    dispatch(insertCompanyEmpBranch(data));
  };
  return (
    <div className="checkreservation">
      <FormGroup>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row-reverse",
          }}
        >
          {CompanyBranch.branch ? (
            CompanyBranch.branch.map((branch) => (
              <Grid item lg={4} md={4} sm={4} style={{ display: "flex" }}>
                {(AddCompanyBranch === true &&
                  AddCompanyBranchdefault === true) ||
                (AddCompanyBranch === true &&
                  AddCompanyBranchdefault === false) ||
                (AddCompanyBranch === false &&
                  AddCompanyBranchdefault === true) ? (
                  <FormControlLabel
                    key={branch.id} // Provide a unique key for each dynamically generated FormControlLabel
                    control={
                      <Checkbox
                        name="id_branch"
                        value={branch.id}
                        checked={checkedBranch.includes(branch.id)}
                        onChange={handleCityCheckboxChange}
                      />
                    }
                    label={branch.name_ar}
                  />
                ) : (
                  <FormControlLabel
                    key={branch.id} // Provide a unique key for each dynamically generated FormControlLabel
                    control={
                      <Checkbox
                        name="id_branch"
                        value={branch.id}
                        checked={checkedBranch.includes(branch.id)}
                        onChange={handleCityCheckboxChange}
                      />
                    }
                    label={branch.name_ar}
                    disabled={true}
                  />
                )}
              </Grid>
            ))
          ) : (
            // Render a loading state or an empty state when CompanyBranch.branch is undefined
            <div>Loading...</div>
          )}
        </Grid>
      </FormGroup>
      {(AddCompanyBranch === true && AddCompanyBranchdefault === true) ||
      (AddCompanyBranch === true && AddCompanyBranchdefault === false) ||
      (AddCompanyBranch === false && AddCompanyBranchdefault === true) ? (
        loadingAdd ? (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="load loadingupdate"
              style={{ background: "#F9B917", borderRaduis: "10px" }}
            >
              <div v-if="loading" class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Controls.Button
              onClick={() => handleSave()}
              type="submit"
              text="حفظ"
            />
          </div>
        )
      ) : (
        <div className="bottonFullwidth">
          <Controls.Button
            onClick={() => handleSave()}
            type="submit"
            text="حفظ"
            disabled={true}
          />
        </div>
      )}
    </div>
  );
}

export default CheckboxBranch;
