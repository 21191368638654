import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

export default function SelectBusType2(props) {
  const { name, label, value, error = null, onChange, options } = props;
  return (
    <FormControl variant="outlined" {...(error && { error: true })} >
      <InputLabel  >{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        
      >
        {options.map((item) => (
          <MenuItem
         
            key={item.id}
            value={item.id}
            style={{ display: "flex", justifyContent: "space-around" }}
          >

          <div style={{width:'30%',height:'40vh'}}>
                    <hr className="hr-seat"></hr>
            <img src={item.image} alt="seats_Type" style={{width:'100%',height:'90%'}} />
          </div>
  
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
