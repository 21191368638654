import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Form } from "../../component/useForm";
import TypeTickitForm from "./TypeTicket";
import PopupTickit from "../../component/PopupTickit";
import TiickitType from "./TiickitTypeCard/TiickitType";
import { useSelector } from "react-redux";
const AddTypeTickit = ({ id_company }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [initialFValues, setIinitialFValues] = useState([
    {
      id: 1,
      type: "",
    },
  ]);
  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("type_cart", JSON.stringify(initialFValues));
  };
  const handleAdd1 = (values) => {
    setOpenPopup(true);
  };
  const AddTicket =
  user != null &&
  user.PermissionsNames.length > 0 &&
  user.PermissionsNames.some((permission) => permission === "add.ticket");

   // Permission as default
   const AddTicketDefult =
   user != null &&
   user.rolePermissionsNames.length > 0 &&
   user.rolePermissionsNames.some((permission) => permission === "add.ticket");
  return (
    <>
      <Grid className="sectionlogin">
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sm={12}
          className="sectionloginright"
        >
          <Grid item xs={12} md={12} className="logosectionloginright">
            <div className="logosectionloginright__img"></div>
          </Grid>

          
          <Form
            onSubmit={handleSubmit}
            className="FormLogin"
            name="form1"
            style={{ height: "90vh" }}
          >
          {AddTicket === true || AddTicketDefult === true ? (
            <Grid
              item
              xs={12}
              lg={12}
              md={12}
              sm={12}
              style={{
                marginBottom: "10px",
                display: "flex",
                gap: "10px",
                textAlign: "center",
                marginRight: "3%",
              }}
            >
              <PopupTickit
                title="إضافة نوع تذكرة"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
              >
                <TypeTickitForm id_company={id_company} />
              </PopupTickit>
              <div
                className="box-button-add-certifcation aad-ticket"
                style={{
                  backgroundColor: "#03314b",
                  padding: "6px 20px",
                  marginTop: "2%",
                  borderRadius: "5px",
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={handleAdd1}
              >
                <img src="img/vuesax-linear-add-circle.svg" alt="" />
                <span className="title-add-certifcation">
                  {"إضافة نوع تذكرة جديدة"}
                </span>
              </div>
            </Grid>
            ):(<></>)}
            <Grid
              item
              xs={12}
              lg={12}
              md={12}
              sm={12}
              style={{
                marginBottom: "10px",
                display: "flex",
                gap: "10px",
                textAlign: "center",
              }}
            ></Grid>
            <Grid>
              <TiickitType id_company={id_company} />
            </Grid>
          </Form>
       
        </Grid>
      </Grid>
    </>
  );
};

export default AddTypeTickit;
