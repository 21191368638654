import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import { useNavigate } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ConfirmDialog from "../../../component/ConfirmDialogBus.js";
import {
  deleteSeateType,
  fetchSeateTypeAll,
} from "../../../redux/SeatsTypeSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSelector } from "react-redux";
const useStyles = makeStyles({
  root: {
    minWidth: 200,
    marginBottom: "20px !important",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 !important",
    position: "relative",
  },
  media: {
    width: 126,
    height: 126,
    marginTop: "15px",
  },
  title: {
    fontFamily: "beIN Normal",
    fontSize: 27,
    color: "#000002",
    fontWeight: "normal",
  },
});
const mylist = [
  {
    name: "رقم الباص",
    img: "img/Bus driver-pana.svg",
  },
  {
    name: "رقم الباص",
    img: "img/Bus driver-pana.svg",
  },
  {
    name: "رقم الباص",
    img: "img/Bus driver-pana.svg",
  },
  {
    name: "رقم الباص",
    img: "img/Bus driver-pana.svg",
  },
  {
    name: "رقم الباص",
    img: "img/Bus driver-pana.svg",
  },
  {
    name: "رقم الباص",
    img: "img/Bus driver-pana.svg",
  },
  {
    name: "رقم الباص",
    img: "img/Bus driver-pana.svg",
  },
  {
    name: "رقم الباص",
    img: "img/Bus driver-pana.svg",
  },
];
const BusesType = ({
  startIndex,
  endIndex,
  BusType,
  setOpenPopup2,
  setImageBusTypeId,
  id_company,
  loading,
}) => {
  const { user } = useSelector((state) => state.auth);
  const { loadingdelete } = useSelector((state) => state.SeatsType);
  //Add Permission
  const HardDeleteSeat =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "hard.delete.seat.type"
    );

  // Permission as default
  const HardDeleteSeatdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "hard.delete.seat.type"
    );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSeateTypeAll(id_company));
    // dispatch(fetchCompanyById(id_company));
  }, [dispatch]);
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [clickedBusTypeId, setClickedBusTypeId] = useState(null);

 
  function handleDelete() {
    setConfirmDialog({
      isOpen: true,
      title: "انت على وشك حذف هذا الباص",
      img: "img/Bus driver-pana.svg",
      clas: "clas",
      onConfirm: () => {
        dispatch(deleteSeateType(clickedBusTypeId));
      },
    });
  }

  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <>
      {loading ? (
        <div className="spinner1-box">
          <svg class="spinner1" viewBox="0 0 50 50">
            <circle
              class="path1"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </div>
      ) : (
        Array.isArray(BusType) && BusType.slice(startIndex, endIndex).map((busType) => (
          <Card className={classes.root} variant="outlined">
            <CardContent className={classes.content}>
              {HardDeleteSeat === true || HardDeleteSeatdefault === true ? (
                <div className="card-menu1">
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                      setClickedBusTypeId(busType.id);
                    }}
                  >
                    <img src="img/More icon.svg" />
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className="testnew"
                  >
                    <MenuItem
                      className="menuStyle"
                      onClick={() => handleDelete()}
                    >
                      <img src="img/Path 17089.svg" />
                      <span>حذف</span>
                    </MenuItem>
                  </Menu>
                  <ConfirmDialog
                    confirmDialog={confirmDialog}
                    setConfirmDialog={setConfirmDialog}
                    loadingdelete={loadingdelete}
                  />
                </div>
              ) : (
                <></>
              )}

              <CardMedia title="Contemplative Reptile">
                <img
                  src="img/Bus driver-pana.svg"
                  className={classes.media}
                  alt="bus-img"
                />
              </CardMedia>
              <Typography
                className='title-card'
                gutterBottom
              >
                عدد المقاعد
              </Typography>
              <div className="body-container1">
                <div className="divtitleone">
                  <p>{busType.number_seats}</p>
                  <VisibilityIcon
                    onClick={() => {
                      setOpenPopup2(true);
                      setRecordForEdit(null);
                      setImageBusTypeId(busType.id);
                    }}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        ))
      )}
    </>
  );
};
export default BusesType;
