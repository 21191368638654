import React from 'react'
import { Dialog, DialogTitle, DialogContent, makeStyles, Typography } from '@material-ui/core';
import Controls from "./controls/Controls";
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles(theme => ({
 
    dialogWrapper: {
        position: 'absolute',
        width:'980px !important',
        maxWidth:'980px !important',
        height:'750px !important',
        maxHeight:'750px !important',
        opacity:'1',
        transition: 'opacity 2ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        display:'flex',
        flexDirection:'column',   
    },
    dialogTitle: {
        padding: '0px '
    },
    headerDesgin:{
        textAlign:'center',
        color:'#F9B917',
        fontSize:'25px',
    },
 

}))

export default function Popupaddtravel(props) {

    const { title, children, openPopup, setOpenPopup } = props;
    const classes = useStyles();

    return (
        <Dialog open={openPopup}  classes={{ paper: classes.dialogWrapper }} >
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' ,margin:'33px 0px 18px 0px', alignItems:'center' }}>
                    <Typography variant="h6" className={classes.headerDesgin} component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <Controls.ActionButton
                    
                        color="secondary"
                        onClick={()=>{setOpenPopup(false)}}>
                        <CloseIcon  />
                    </Controls.ActionButton>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}
