import React, { useState, useEffect, Fragment } from 'react'
import { Grid, } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchSeateTypeById } from '../../redux/SeatsTypeSlice';
export default function BusTypeImage(props) { 
const {  SeatsID } = useSelector((state) => state.SeatsType);
const dispatch = useDispatch();
useEffect(() => {
    dispatch(fetchSeateTypeById(props.id_bus_type));
},[dispatch]);
    return (
        <>

        {props.loading ?(
            <div className='spinner1-box' style={{height:'100% !important'}}>
                    <svg class="spinner1" viewBox="0 0 50 50">
                      <circle
                        class="path1"
                        cx="25"
                        cy="25"
                        r="20"
                        fill="none"
                        stroke-width="5"
                      ></circle>
                    </svg>
                  </div>
            ):(
            <Grid item xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center' , width:'100%' , height:'100%'}} >
                 <img src={SeatsID.image}  alt="صورة مفاعد الباص"  style={{ width:'35%', height:'100%'}} />
            </Grid>
            )}
   
        </>
    )
}
