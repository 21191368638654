import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import packageJson from "../../package.json";
import * as sweetalert from "sweetalert";
export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchAllNotifcations = createAsyncThunk(
  'Notifcation/fetchAllNotifcations',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllNotification`, { withCredentials: true });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const insertNotifcations = createAsyncThunk(
  'Notifcation/insertNotifcations',
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {

      await axios.post(`${BaseUrl}/handleNotification`,values, { withCredentials: true });
      sweetalert({
        text: "تم الإضافة بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const NotifcationsSlice = createSlice({
  name: 'Notifcation',
  initialState: { Notifcations: [], loading: false, loadingAdd:false , error: null },
  reducers: {
    clearNotifcations: (state) => {
      state.Notifcations = [];
      state.loading = false;
      state.loadingAdd = false;
      state.error = null;
    },
  },
  extraReducers: {
    //fetch
    [fetchAllNotifcations.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchAllNotifcations.fulfilled]: (state, action) => {
      state.Notifcations = action.payload;
      state.loading = false;
    },
    [fetchAllNotifcations.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
      //insert  trip types
      [insertNotifcations.pending]: (state, action) => {
      state.loadingAdd = true;
      state.error = null;
    },
    [insertNotifcations.fulfilled]: (state, action) => {
      state.Notifcations.push(action.payload);
      state.loadingAdd = false;
    },
    [insertNotifcations.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingAdd = false;
    },
  },
});
export const { clearNotifcations } = NotifcationsSlice.actions;
export default NotifcationsSlice.reducer;
