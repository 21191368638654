import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import SetRed from "./SetRed";
import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";
import { insertSeateType, setCellValues } from "../../../redux/SeatsSlice";
import Controls from "../../../component/controls/Controls";
import { Grid } from "@mui/material";
import * as sweetalert from "sweetalert";
export default function Matrix({
  setCellValues1,
  cellValues1,
  divRef,
  id_company,
  numberSeats,
}) {
  const dispatch = useDispatch();
  const { rows, columns, selectedType, selectedCompany,loadingAdd } = useSelector(
    (state) => state.createBus
  );
  const [matrix, setMatrix] = useState(createMatrix(rows, columns));
  const [countelementarray, setCountelementarray] = useState(0);
  const [newarray, setNewarray] = useState([]);

  function createMatrix(rows, columns) {
    const matrix = [];

    for (var i = 0; i < rows; i++) {
      const row = Array(columns).fill(null);

      matrix.push(row);
    }

    return matrix;
  }

  function handleChange(e, rowIndex, columnIndex) {
    const value = e.target.value;
    const updatedMatrix = matrix.map((row, idx) =>
      idx === rowIndex
        ? [...row.slice(0, columnIndex), value, ...row.slice(columnIndex + 1)]
        : row
    );

    setMatrix(updatedMatrix);

    const cellValues = updatedMatrix.flatMap((row) =>
      row.map((cell) => (cell !== "" ? cell : "null"))
    );
    dispatch(setCellValues(cellValues));
    setNewarray(cellValues);
    const updatedCount = cellValues.filter(
      (element) => element !== "null" && element !== null
    ).length;
    setCountelementarray(updatedCount);
  }

  useEffect(() => {}, [countelementarray]);
  const handleExportAndUpload = () => {
    // const isUnique = new Set(newarray).length === newarray.length;
    const numbersArray = newarray.filter((value) => value !== null).map(Number);
    const uniqueSet = new Set(numbersArray);
    // Convert the Set back to an array
    const uniqueNumbersArray = [...uniqueSet];

    if (uniqueNumbersArray.length !== numbersArray.length) {
      sweetalert({
        text: "يرجى التأكد من عدم تكرار أرقام المقاعد",
        icon: "error",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {});
      return; // Stop further execution
    }

    if (countelementarray < numberSeats) {
      sweetalert({
        text: " عدد المقاعد المضافة أصغر من عدد المقاعد الكلي ",
        icon: "error",
        buttons: {
          confirm: {
            text: "إغلاق",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {});
    } else if (countelementarray > numberSeats) {
      sweetalert({
        text: "عدد المقاعد المضافة أكبر من عدد المقاعد الكلي",
        icon: "error",
        buttons: {
          confirm: {
            text: "إغلاق",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
      });
    } else {
      html2canvas(divRef.current, {
        backgroundColor: "white",
        useCORS: true,
      }).then((canvas) => {
        canvas.toBlob((blob) => {
          const file = new File([blob], "image.png", { type: "image/png" });
          const formData = new FormData();
          formData.append("image", file);
          formData.append("id_company", id_company);
          formData.append("type", selectedType);
          formData.append("num_col", columns);
          formData.append("num_row", rows);
          formData.append("number_seats", numberSeats);
          formData.append(
            "seats",
            cellValues1.map((num) => `${num}`).join(",")
          );
            dispatch(insertSeateType(formData))
            .unwrap()
            .then((originalPromiseResult) => {
              // Handle success
              // Set loading to false here if necessary
            })
            .catch((rejectedValueOrSerializedError) => {
              // Handle error
              // Set loading to false here if necessary
            });
        }, "image/png");
      });
    }
  };
  useEffect(() => {
    setMatrix(createMatrix(rows, columns));
  }, [rows, columns]);

  useEffect(() => {
    const cellValues = matrix.flatMap((row) =>
      row.map((cell) => (cell !== "" ? cell : "null"))
    );
    dispatch(setCellValues(cellValues));
  }, [matrix, dispatch]);

  return (
    <div style={{ display: "flex" }} className="typeseatmatrix">
      <div className="busseatmatrix">
        <div ref={divRef} style={{ borderRadius: "10px", direction: "ltr" }}>
          {matrix.map((row, rowIndex) => (
            <div key={rowIndex} style={{ display: "flex" }}>
              {row.map((cell, columnIndex) =>
                cell ? (
                  <div
                    style={{ display: "flex" }}
                    key={`${rowIndex}-${columnIndex}`}
                  >
                    <div
                      className="filled-cell"
                      style={{ width: "80px", height: "75px", marginTop: "0" }}
                    >
                      <SetRed
                        key={`${rowIndex}-${columnIndex}`}
                        img="img/Path 6.svg"
                        number={cell}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{ display: "flex" }}
                    key={`${rowIndex}-${columnIndex}`}
                  >
                    <div
                      className="filled-cell"
                      style={{ width: "80px", height: "75px" }}
                    ></div>
                  </div>
                )
              )}
            </div>
          ))}
        </div>
 
        {loadingAdd? (
          <div
            className="bottonFullwidth load"
            style={{ background: "#F9B917", borderRaduis: "10px" }}
          >
            <div v-if="loading" class="spinner">
              <div class="rect1"></div>
              <div class="rect2"></div>
              <div class="rect3"></div>
              <div class="rect4"></div>
              <div class="rect5"></div>
            </div>
          </div>
        ) : (
          <Grid
            item
            xs={12}
            lg={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "40%",
              marginTop: "20%",
            }}
            className="bottonFullwidth"
          >
            <Controls.Button
              type="submit"
              text="إضافة"
              onClick={() => {
                handleExportAndUpload();
              }}
            />
          </Grid>
        )}
      </div>

      <div style={{ direction: "ltr" }}>
        {matrix.map((row, rowIndex) => (
          <div
            key={rowIndex}
            style={{ display: "flex", marginRight: "10px" }}
            className="inputseatmatix"
          >
            {row.map((cell, columnIndex) => (
              <input
                key={`${rowIndex}-${columnIndex}`}
                value={cell || ""}
                onChange={(e) => handleChange(e, rowIndex, columnIndex)}
              />
            ))}
          </div>
        ))}
      </div>
      <div></div>
    </div>
  );
}
