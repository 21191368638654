import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchPaths = createAsyncThunk(
  "Path/fetchPaths",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${BaseUrl}/getAllPathsForCompany/${id}`,
        { withCredentials: true }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchPathsfilter = createAsyncThunk(
  "Path/fetchPathsfilter",
  async (value, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${BaseUrl}/getAllPathsForCompany/${value.id}`,
        { withCredentials: true }
      );
      const filteredArray = res.data.data.filter(
        (e) => e.type === (value.path)
      );
      return filteredArray;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchPathsById = createAsyncThunk(
  "Path/fetchPathsById",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${BaseUrl}/path/${id}`,
        { withCredentials: true }
        );
        const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addPath = createAsyncThunk(
  "Path/addPath",
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      await axios.post(
        `${BaseUrl}/addPath`,
        values,
        { withCredentials: true }
      );
      sweetalert({
        text: "تم الإضافة بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage = error.response?.data?.message || error.response?.data?.data;
        if(errorMessage =='validation error'){
          sweetalert({
            text:  'يرجى اختيار انطلاق ووجهة مختلقين'   ,
            icon: "error",
            buttons: {
              confirm: {
                text: "إغلاق",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          });
        }else{
          sweetalert({
            text: errorMessage,
            icon: "error",
            buttons: {
              confirm: {
                text: "إغلاق",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          });  
        }
        
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updatePath = createAsyncThunk(
  'Path/updatePath',
  async (values,thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
  //   bookData.auther = getState().auth.name;
    try {

      await axios.post(`${BaseUrl}/updatePath/${values.id}`,values, { withCredentials: true });
      sweetalert({
        text: "تم التعديل بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage = error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deletePath = createAsyncThunk(
  "Path/deletePath",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await axios.delete(
        `${BaseUrl}/deletePath/${id}`,
        { withCredentials: true }
      );
      sweetalert({
        text: "تم الحذف بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });  
    } catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage = error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);
const PathSlice = createSlice({
  name: "Path",
  initialState: {
    Paths: [],
    PathsID: [],
    insertPath: [],
    PathUpdate: [],
    PathFilter: [],
    loading: false,
    loadingupdate: false,
    loadingdelete: false,
    error: null,
  },
  reducers: {
    clearPath: (state) => {
      state.Paths = [];
      state.PathsID = [];
      state.insertPath = [];
      state.PathUpdate = [];
      state.PathFilter = [];
      state.loading = false;
      state.loadingupdate = false;
      state.loadingdelete = false;
      state.error = null;
    },
  },
  extraReducers: {
    //fetch
    [fetchPaths.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchPaths.fulfilled]: (state, action) => {
      state.Paths = action.payload;
      state.loading = false;
    },
    [fetchPaths.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //fetch Path Filter
    [fetchPathsfilter.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchPathsfilter.fulfilled]: (state, action) => {
      state.PathFilter = action.payload;
      state.loading = false;
    },
    [fetchPathsfilter.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    // fetch Path By ID
    [fetchPathsById.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchPathsById.fulfilled]: (state, action) => {
      state.PathsID = action.payload;
      state.loading = false;
    },
    [fetchPathsById.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //Update  trip types
    [updatePath.pending]: (state, action) => {
      state.loadingupdate = true;
      state.error = null;
    },
    [updatePath.fulfilled]: (state, action) => {
      state.PathUpdate=action.payloa
      state.loadingupdate = false;
    },
    [updatePath.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingupdate = false;
    },
    //insert
    [addPath.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [addPath.fulfilled]: (state, action) => {
      state.insertPath.push(action.payload);
      state.loading = false;
    },
    [addPath.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    //delete
    [deletePath.pending]: (state, action) => {
      state.loadingdelete = true;
      state.error = null;
    },

    [deletePath.fulfilled]: (state, action) => {
      const deletedEmployeeId = action.meta.arg; // Access the ID from the action metadata
      state.Paths = state.Paths.filter((el) => el.id !== deletedEmployeeId);
      state.loadingdelete = false;
    },
    [deletePath.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingdelete = false;
    },
  },
});
export const { clearPath } = PathSlice.actions;
export default PathSlice.reducer;
