import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import packageJson from "../../package.json";
import * as sweetalert from "sweetalert";
export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchDisableAccount = createAsyncThunk(
  'ActiveAccount/fetchDisableAccount',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getDisableAccount` ,{ withCredentials: true });
      const result = await res.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
  
);


export const insertActiveAccount = createAsyncThunk(
  'ActiveAccount/ActiveAccount',
  async (id, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;

    try {

      await axios.get(`${BaseUrl}/activateAccount/${id}`, { withCredentials: true });
      sweetalert({
        text: "تم التفعيل بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
   
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const ActiveAcountSlice = createSlice({
  name: 'ActiveAccount',
  initialState: { ActiveAccount: [],Active:[], loading: false, error: null },
  reducers: {
    clearActive: (state) => {
      state.ActiveAccount = [];
      state.Active = [];
      state.loading = false;
      state.error = null;

    },
  },
  extraReducers: {
    //fetch System user
    [fetchDisableAccount.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchDisableAccount.fulfilled]: (state, action) => {
      state.ActiveAccount = action.payload;
      state.loading = false;
    },
    [fetchDisableAccount.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //Active System user
    [insertActiveAccount.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [insertActiveAccount.fulfilled]: (state, action) => {
      state.Active.push(action.payload);
      state.loading = false;
    },
    [insertActiveAccount.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export const { clearActive } = ActiveAcountSlice.actions;
export default ActiveAcountSlice.reducer;
