import { useLocation, useNavigate } from "react-router-dom";
import { tokens } from "../../styles/theme";
import React, { useEffect, useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { Grid } from "@material-ui/core";
import PopupCreate from "../../component/PopupCreate";
import PopupShortcut from "../../component/PopupShortcut.js";
import TripForm from "./TripForm";
import { Select, MenuItem } from "@material-ui/core";
import ConfirmDialog from "../../component/ConfirmDialog.js";
import { DataGridPro } from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import { Datepicker } from "@zendeskgarden/react-datepickers";
import { Field, Label, Input } from "@zendeskgarden/react-forms";
import { Row, Col } from "@zendeskgarden/react-grid";
import { ThemeProvider } from "@zendeskgarden/react-theming";

import Popuppassenger from "../../component/Popuppassenger";

import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../../component/controls/Controls";
import StopTrip from "./StopTrip";
import PopupStop from "../../component/PopupStop";
import PopupCreate2 from "../../component/PopupCreate2.js";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTrip,
  fetchTripBYId,
  fetchTripBYIdReservation,
  fetchTrips,
} from "../../redux/tripsSlice";
import TripFormEdit from "./TripEdit1";
import { fetchCompany } from "../../redux/companyTransportSlice";
import { setstatus } from "../../redux/reservation";
import dayjs from "dayjs";
import { useRef } from "react";
import { fetchAllCity } from "../../redux/CitySlice";
import "moment/locale/ar-sa";
import SetsBus from "../SetsBusTrip/SetsBus";
import StepPassenger from "../StepPassengerTrip/StepPassenger";
import SetRed from "../SetsBusTrip/SetRed";
import packageJson from "../../../package.json";
import Shortcut from "./Shortcut.js";

export const key_Shortcut = packageJson.key_Shortcut;
export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    flexDirection: "row-reverse",
    paddingRight: "8px",
    height: "45px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
    marginRight: "3px",
  },
  "@media (min-width: 600px) and (max-width:1280px)": {
    searchDesign: {
      marginBottom: "15px",
    },
  },
  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  editButton: {
    display: "none",
    flexDirection: "row-reverse",
    color: "#000002 !important",
    fontSize: "20px",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  deleteButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#F9B917 !important",
    fontSize: "20px ",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  editImg: {
    width: "15px",
  },
  Marginbox1: {
    maxWidth: "100% !important",
    flexBasis: "100%",
  },
});
export default function TripsTable2({ id_company, setShortcut, schortcut }) {
  const [currentTime1, setCurrentTime1] = useState(new Date());
  const { user } = useSelector((state) => state.auth);
  const dataGridRef = useRef(null);
  const [focusedColumn, setFocusedColumn] = useState(null);
  const [newStatus, setNewStatus] = useState(false);
  const [newStatusAvail, setNewStatusAvail] = useState(false);
  const [loadingtrip, setLoadingtrip] = useState(false);
  const { status } = useSelector((state) => state.reservations);
  //Add Permission
  const AddTrip =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "add.trip");

  const UpdateTrip =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "update.trip");

  const SoftDeleteTrip =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "soft.delete.trip"
    );

  // Permission as default
  const AddTripdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some((permission) => permission === "add.trip");

  const UpdateTripdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "update.trip"
    );

  const SoftDeleteTripdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "soft.delete.trip"
    );

  const location = useLocation();
  const id_comp = location.state; // Print the state value

  const { loading, Trips, TripID } = useSelector((state) => state.Trip);

  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState(Trips);
  const currentDateTime = new Date();
  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopuppassenger, setOpenPopuppassenger] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForEdit11, setRecordForEdit11] = useState(null);
  const [recordForEdit1, setRecordForEdit1] = useState(null);
  const [openPopupStop, setOpenPopupStop] = useState(false);
  const [clickedEmployeeId, setClickedEmployeeId] = useState(null);
  const [selectedfromcityId, setSelectedFromCityId] = useState(null);
  const [selectedtocityId, setSelectedToCityId] = useState(null);
  const [isTableDisabled, setIsTableDisabled] = useState(false);
  const [date, setDate] = React.useState();
  const [numberpassenger, setNumberPassenger] = useState(0);
  const [showpassenger, setShowPassenger] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [valueinput, setValueinput] = useState("");
  const [pathTrip, setPathtrip] = useState("");
  const [idTrip, setIdTrip] = useState("");
  const { Cities } = useSelector((state) => state.City);
  const inputRef = useRef(null);
  const tableRef = useRef(null);
  const pathToColumnRef = useRef(null);
  const {statusNoAvailabel} = useSelector((state) => state.reservations);
  const [openPopup3, setOpenPopup3] = useState(false);

  // const getDataGridInstance = () => {
  //   if (tableRef.current) {
  //     return tableRef.current;
  //   }
  //   return null;
  // };
  useEffect(() => {
    if (status === true) {
      setstatus(false);
    }
  }, [status]);

  // useEffect(() => {   dispatch(fetchTrips(id_company));},[dispatch,id_company])
  useEffect(() => {
    if (id_company !== null) {
      dispatch(fetchTrips(id_company));
      dispatch(fetchCompany());
      localStorage.setItem("displayshortcut", "1");
      setFilteredData(Trips);
      if (Trips !== null) {
        setTimeout(() => {
          const targetElement = document.querySelector(
            ".field-table1-trip .MuiDataGrid-virtualScrollerRenderZone > div:first-child"
          );
          const element = document.querySelector(
            ".field-table1-trip .MuiDataGrid-virtualScrollerRenderZone > div:first-child > .MuiDataGrid-cell"
          );
          if (targetElement) {
            element.focus();
            targetElement.classList.add("Mui-selected");
          }
        }, 1000);
      }
    }
  }, [dispatch, setFilteredData]);

  // useEffect(() => {
  //   if (id_company !== null) {
  //     dispatch(fetchCompany());
  //     localStorage.setItem("displayshortcut", "1");
  //     if (Trips !== null) {
  //       const fetchInterval = setInterval(() => {
  //         dispatch(fetchTrips(id_company));
  //       }, 1000);

  //       return () => clearInterval(fetchInterval); // Cleanup interval on component unmount
  //     }
  //   }
  // }, [dispatch, id_company, Trips]);
  //   const rowCount = tableRef.current;
  //   const ariaRowCount = rowCount.getAttribute('aria-rowcount');
  useEffect(() => {
    const elementsWithTabIndexZero = document.querySelectorAll(
      ".field-table1-trip .Mui-selected"
    );
  });
  const handleChange = () => {
    setValueinput(inputRef.current.value);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      let typedWord = "";
      let typedWord2 = "";
      if (/^[a-zA-Z]$/.test(event.key)) {
        typedWord += event.key.toLowerCase();
        typedWord2 += event.key;
        const lastThreeChars = typedWord.substring(typedWord.length - 3);
        if (lastThreeChars === "add") {
          localStorage.setItem("displayshortcut", "1");
          setOpenPopup(true);
          setRecordForEdit(null);
          setShortcut(1);
          typedWord = "";
        }
        if (typedWord.substring(typedWord.length - 1) === "x") {
          localStorage.setItem("displayshortcut", "0");
          setShortcut(0);
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    let typedWord = "";
    let typedWord2 = "";
    // const handleKeyDown = (event) => {
    //   const currentElement = document.activeElement;
    //   const currentColumn = currentElement.getAttribute("data-field");
    //   // Check if the current column is not "path_from" or "path_to"
    //   if (currentColumn !== "path_from" && currentColumn !== "path_to") {
    //     event.preventDefault();
    //     return;
    //   }
    //   if (/^[a-zA-Z]$/.test(event.key)) {
    //     // ... (your existing code)
    
    //     if (event.key === key_Shortcut.focus_table) {

    //       if (currentColumn === "path_from" || currentColumn === "path_to") {
    //         const targetElement = document.querySelector(
    //           ".field-table1-trip .MuiDataGrid-virtualScrollerRenderZone > div:first-child"
    //         );
    
    //         const element = document.querySelector(
    //           ".field-table1-trip .MuiDataGrid-virtualScrollerRenderZone > div:first-child > .MuiDataGrid-cell"
    //         );
    
    //         const autofocusenter = document.querySelector(
    //           ".field-table1-trip .autofocusenter"
    //         );
    
    //         if (targetElement) {
    //           element.focus();
    //           autofocusenter.focus();
    //           targetElement.classList.add("Mui-selected");
    //         }
    //       }
    //     }
    
    //     if (
    //       event.key === key_Shortcut.focus_input_seats ||
    //       event.key === key_Shortcut.focus_input_seats11
    //     ) {
    //       event.preventDefault();
    //       focusInput();
    //     }
    //   }
    // };
    const handleKeyDown = (event) => {
      // if (event.key === " ") {
      //   event.preventDefault();
    
      const fieldName = event.target.getAttribute("data-field");
      const trip_id = event.target.getAttribute("data-field2");
   
     
      if (/^[a-zA-Z]$/.test(event.key)) {
        typedWord += event.key.toLowerCase();
        typedWord2 += event.key;

        const lastThreeChars = typedWord.substring(typedWord.length - 3);
        if (lastThreeChars === "add") {
          localStorage.setItem("displayshortcut", "1");
          setOpenPopup(true);
          setRecordForEdit(null);
          setShortcut(1);
          typedWord = "";
        }
        if (event.key === key_Shortcut.focus_table) {
          const targetElement = document.querySelector(
            ".field-table1-trip .MuiDataGrid-virtualScrollerRenderZone > div:first-child"
          );
          const element = document.querySelector(
            ".field-table1-trip .MuiDataGrid-virtualScrollerRenderZone > div:first-child > .MuiDataGrid-cell"
          );
          const autofocusenter = document.querySelector(
            ".field-table1-trip .autofocusenter"
          );
          if (targetElement) {
            element.focus();
            autofocusenter.focus();
            targetElement.classList.add("Mui-selected");
          }
        }
        if (
          event.key === key_Shortcut.focus_input_seats ||
          event.key === key_Shortcut.focus_input_seats11
        ) {
          event.preventDefault();
          focusInput();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const focusInput = () => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.focus();
    }
  };
 
  
  const handleKeyDown1 = (event) => {
    if (event.key === "Enter") {
      // setOpenPopupStop(true);
      setIsDisabled(true);
    }
  };
  // useEffect(() => {
  //   if (isDisabled === true) {
  //     const elements = tableRef.current.querySelectorAll('.disabled-grid .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row [tabindex="0"]');
  //     elements.forEach(element => {
  //       element.setAttribute('tabindex', '-1');
  //     });
  //  }
  // }, [isDisabled]);
  // useEffect(() => {
    // if (statusNoAvailabel === true) {
  //     setLoadingtrip(statusNoAvailabel)
  //     // setNewStatusAvail(statusNoAvailabel);
  //     // if (newStatusAvail === true) {
  //       // setNewStatusAvail(false);
  //     // }
  //   }

  // }, [statusNoAvailabel]);

  const handleKeyDown2 = (event) => {
    if (event.key === "Enter") {
      focusInput();
      setIsDisabled(true);
      if (status === true) {
        setstatus(false);
      }
      if (newStatus === true) {
        setNewStatus(false);
      }
      const fieldName = event.target.getAttribute("data-field");
      const trip_id = event.target.getAttribute("data-field2");
 
      if (fieldName === "path_from" || fieldName === "path_to") {
        setShow(true);
        setIdTrip(trip_id);
        dispatch(fetchTripBYId(trip_id));
        focusInput();
        setLoadingtrip(true);
      }
    }
  };
  useEffect(() => {
    if (Trips !== null) {
      setTimeout(() => {
        const targetElement = document.querySelector(
          ".field-table1-trip .MuiDataGrid-virtualScrollerRenderZone > div:first-child"
        );
        const element = document.querySelector(
          ".field-table1-trip .MuiDataGrid-virtualScrollerRenderZone > div:first-child > .MuiDataGrid-cell"
        );
        const autofocusenter = document.querySelector(
          ".field-table1-trip .autofocusenter"
        );
        if (targetElement) {
          element.focus();
          autofocusenter.focus();
          targetElement.classList.add("Mui-selected");
        }
      }, 1000);
    }
  }, [Trips]);
  useEffect(() => {
    setTimeout(() => {

      // if (statusNoAvailabel === true) {
      if (loadingtrip === true) setLoadingtrip(false);
      
    }, 2000);
  }, [loadingtrip]);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoadingtrip(statusNoAvailabel);
  //     if (loadingtrip === true) {
  //       setLoadingtrip(false);
  //       statusNoAvailabel= false;
  //     }

  //   }, 300);
  // }, [statusNoAvailabel, loadingtrip,setLoadingtrip]);
  const intervalRef = useRef(null);

  function handleOpen() {
    setOpenPopup(true);
    setRecordForEdit(null);
  }
  function handleOpen2() {
    setOpenPopup3(true);
    setRecordForEdit(null);
  }
  function handleE(id) {
    setOpenPopup2(true);
    setRecordForEdit1(null);
    setClickedEmployeeId(id);
    dispatch(fetchTripBYId(id));
  }
  useEffect(() => {
    dispatch(fetchAllCity());
  }, [dispatch, id_company]);
  const onHandleReset = () => {
    setSelectedFromCityId(null);
    setSelectedToCityId(null);
    setDate(null);
  };
  // function filterTrips(Trips) {
  //   let filteredTrips;
  //   if (selectedfromcityId && selectedtocityId) {
  //     return Trips.filter(
  //       (trip) =>
  //         trip.path_from === selectedfromcityId &&
  //         trip.path_to === selectedtocityId
  //     );
  //   }
  //   else if (selectedfromcityId && selectedtocityId && date) {
  //     return Trips.filter(
  //       (trip) =>
  //         trip.path_from === selectedfromcityId &&
  //         trip.path_to === selectedtocityId &&
  //         trip.date === dayjs(date).format("YYYY-MM-DD")
  //     );
  //   }
  //    else if (selectedfromcityId && date) {
  //     return Trips.filter(
  //       (trip) =>
  //         trip.path_from === selectedfromcityId &&
  //         trip.date === dayjs(date).format("YYYY-MM-DD")
  //     );
  //   } else if (selectedtocityId && date) {
  //     return Trips.filter(
  //       (trip) =>
  //         trip.path_to === selectedtocityId &&
  //         trip.date === dayjs(date).format("YYYY-MM-DD")
  //     );
  //   } else if (selectedfromcityId) {
  //     return Trips.filter((trip) => trip.path_from === selectedfromcityId);
  //   } else if (selectedtocityId) {
  //     return Trips.filter((trip) => trip.path_to === selectedtocityId);
  //   } else if (date) {
  //     return Trips.filter(
  //       (trip) => trip.date === dayjs(date).format("YYYY-MM-DD")
  //     );
  //   } else {
  //     filteredTrips = [...Trips]; // Create a shallow copy before sorting
  //     filteredTrips.sort((a, b) => {
  //       const dateA = new Date(a.date);
  //       const dateB = new Date(b.date);
  //       return dateA - dateB;
  //     });
  //   }

  //   return filteredTrips;
  // }
  function filterTrips(Trips) {
    let filteredTrips;

    if (selectedfromcityId && selectedtocityId) {
      filteredTrips = Trips.filter(
        (trip) =>
          trip.path_from === selectedfromcityId &&
          trip.path_to === selectedtocityId
      );
    } else if (selectedfromcityId && selectedtocityId && date) {
      filteredTrips = Trips.filter(
        (trip) =>
          trip.path_from === selectedfromcityId &&
          trip.path_to === selectedtocityId &&
          trip.date === dayjs(date).format("YYYY-MM-DD")
      );
    } else if (selectedfromcityId && date) {
      filteredTrips = Trips.filter(
        (trip) =>
          trip.path_from === selectedfromcityId &&
          trip.date === dayjs(date).format("YYYY-MM-DD")
      );
    } else if (selectedtocityId && date) {
      filteredTrips = Trips.filter(
        (trip) =>
          trip.path_to === selectedtocityId &&
          trip.date === dayjs(date).format("YYYY-MM-DD")
      );
    } else if (selectedfromcityId) {
      filteredTrips = Trips.filter(
        (trip) => trip.path_from === selectedfromcityId
      );
    } else if (selectedtocityId) {
      filteredTrips = Trips.filter((trip) => trip.path_to === selectedtocityId);
    } else if (date) {
      filteredTrips = Trips.filter(
        (trip) => trip.date === dayjs(date).format("YYYY-MM-DD")
      );
    } else {
      filteredTrips = [...Trips]; // Create a shallow copy before sorting
      filteredTrips.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });
    }

    return filteredTrips;
  }

  const filteredTripes = filterTrips(Trips);

  const handleDateChange = (newValue) => {
    setDate(newValue);
  };
  useEffect(() => {
    if (dataGridRef.current) {
      const firstRowId = dataGridRef.current.apiRef.current.getRows()[0]?.id;
      const firstColumn = "path_from";
      dataGridRef.current.apiRef.current.setCellFocus(firstRowId, firstColumn);
    }
  }, []);
  function EditToolbar(props) {
    return (
      <div
        className="trip"
        style={{
          display: "flex",
          flexDirection: "row",
    
          gap:'3%',
          justifyContent:'flex-start',
          alignItems: 'flex-start',
          // paddingLeft: "10%",
        }}
      >
        <Grid container xs={12} className="container-div-filter-trip" style={{gap:'1%'}}>
          <Grid lg={2} md={2} sm={2}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className={` ${
                selectedfromcityId
                  ? "selects11trip select1tripdirect"
                  : "selects1trip selects1trip select1tripdirect"
              }`}
              text="بحث"
              value={selectedfromcityId}
              onChange={(e) => setSelectedFromCityId(e.target.value)}
              style={{ marginBottom: "10px !important" }}
            >
              {Cities.map((city) => (
                <MenuItem value={city.name_ar} key={city.id}>
                  <span>{city.name_ar}</span>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid
            lg={2}
            md={2}
            sm={2}
            className={classes.AddDesign}
            style={{ marginBottom: "10% !important" }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className={` ${
                selectedtocityId
                  ? "selects22trip select2tripdirect"
                  : "selects2trip selects2trip select2tripdirect"
              }`}
          
              value={selectedtocityId}
              onChange={(e) => setSelectedToCityId(e.target.value)}
            >
              {Cities.map((city) => (
                <MenuItem value={city.name_ar} key={city.id}>
                  <span>{city.name_ar}</span>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid
            lg={4}
            md={4}
            sm={2}
            className="filterdate"
            style={{
              border: "1px solid #eee",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "1px 3px 6px rgba(0,0,0,0.3)",
              borderRadius: "10px",
              marginBottom: "10px",
             
            }}
          >
            <ThemeProvider>
              <Row justifyContent="center" >
                <Col sm={5}>
                  <Field
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row-reverse",
                     
                    }}
                  >
                    <Label
                      style={{
                        color: "rgb(207, 204, 205)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "10px",
                      }}
                    >
                      {" "}
                      تاريخ الرحلة
                    </Label>
                    <Datepicker
                      clearable
                      okLabel="موافق"
                      cancelLabel="الغاء"
                      clearLabel="مسح"
                      value={date}
                      onChange={handleDateChange}
                      locale="ar-SY"
                    >
                      <Input
                        className="iput-filter-date"
                        lang="ar-SY"
                        style={{
                          border: "none",
                          width: "60%",
                          cursor: "pointer",
                          marginTop: "0px ",
                        }}
                      />
                    </Datepicker>
                  </Field>
                </Col>
              </Row>
            </ThemeProvider>
          </Grid>
          <Grid
            lg={2}
            md={2}
            sm={1}
            style={{
              marginBottom: "10% !important",
              marginTop: "10px !important",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              className="reloadButton1 reloadertripdirect"
              onClick={onHandleReset}
              style={{ width: "97%" }}
            >
              <img src="img/reload.svg" />
            </Button>
          </Grid>
          {AddTrip === true || AddTripdefault === true ? (
            <Grid
              lg={3}
              md={3}
              sm={1}
              className={classes.AddDesign}
              style={{
                marginBottom: "10% !important",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="bottonFullwidth1 width-button-trip">
                <Controls.Button
                  type="submit"
                  text="إضافة"
                  onClick={handleOpen}
                />
              </div>
            </Grid>
          ) : (
            <></>
          )}
            <Grid
              lg={3}
              md={3}
              sm={1}
              // className={}
              style={{
                // marginBottom: "20% !important",
                // marginTop: "-3%",
          
                display: "flex",
                justifyContent: "center",
                height:'38px'
              }}
            >
              <div className="bottonFullwidth22 width-button-trip">
                <Controls.Button
                  type="submit"
                  text=" الاختصارات"
                  onClick={handleOpen2}
                />
              </div>
            </Grid>
          <PopupCreate2
            title=" إنشاء رحلة"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            setDisplayshortcut1={setShortcut}
          >
            <TripForm
              id_comp={id_company}
              recordForEdit={recordForEdit}
              setOpenPopup={setOpenPopup}
            />
          </PopupCreate2>
          <PopupShortcut
            title="قائمة الاختصارات"
            openPopup={openPopup3}
            setOpenPopup={setOpenPopup3}
            setDisplayshortcut1={setShortcut}
          >
            <Shortcut
              recordForEdit={recordForEdit}
              setOpenPopup={setOpenPopup3}
            />
          </PopupShortcut>
        </Grid>
      </div>
    );
  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
  };

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [openPopup1, setOpenPopup1] = useState(false);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const goTo = ({ id, compareResult }) => {
    dispatch(fetchTripBYIdReservation(id));
    navigate(`/reservation`, { state: { id: id, datetime: compareResult } });
  };
  function handleClickEdit(id) {
    setClickedEmployeeId(id);
    dispatch(fetchTripBYId(id));
  }

  function handleDelete(id) {
    setConfirmDialog({
      isOpen: true,
      title: "أنت على وشك حذف هذه الرحلة",
      img: "img/emp/Inbox cleanup-rafiki.svg",
      onConfirm: () => {
        dispatch(deleteTrip(id));
      },
    });
  }
  const getPageSize = () => {
    if (window.innerWidth < 992) {
      return 5; // Set a smaller pageSize for smaller screens
    } else if (window.innerWidth < 1566) {
      return 7; // Default pageSize for larger screens
    } else if (window.innerWidth < 1760) {
      return 8; // Default pageSize for larger screens
    } else if (window.innerWidth < 1930) {
      return 13; // Default pageSize for larger screens
    }
  };

  const columns = [
    {
      field: "id",
      sortable: false,
      headerName: "id",
      resizable: true,
      headerAlign: "center",
      align: "center",
      
    },
    {
      field: "path_from",
      sortable: false,
      resizable: true,
      headerName: "الانطلاق",
      minWidth: 100,
      maxWidth: 100,
      headerAlign: "center",
      align: "center",
      // tabindex:0,
      renderCell: (params) => (
        <div
          className=""
          data-field={params.field}
          data-field2={params.row.id}
          onKeyDown={handleKeyDown2}
          tabindex={0} // Allow div to be focusable
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "tripTransit",
      sortable: false,
      resizable: true,
      headerName: "رحلة العبور",
      // maxWidth: 200,
      minWidth:'150',
      maxWidth:'150',
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div  data-field={params.field}>
          {params.value && params.value.length === 1 ? (
            <div>
              {params.value[0].name} -- {params.value[0].time}
            </div>
          ) : params.value && params.value.length >= 2 ? (
            <div>
              <div>
                {params.value[0].name} --
                {params.value[0].time}
              </div>

              <div>
                {params.value[1].name} --
                {params.value[1].time}
              </div>
            </div>
          ) : (
            <p> </p>
          )}
        </div>
      ),
    },
    {
      field: "path_to",
      sortable: false,
      resizable: true,
      headerName: "الوجهة",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div
          className="autofocusenter"
          data-field={params.field}
          data-field2={params.row.id}
          onKeyDown={handleKeyDown2}
          tabindex={0} // Allow div to be focusable
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "dateTime",
      sortable: false,
      headerName: "التوقيت",
      resizable: true,
      headerAlign: "center",
      align: "center",
      
    },
    {
      field: "id_trip_type",
      sortable: false,
      headerName: "نوع الرحلة",
      resizable: true,
      headerAlign: "center",
      align: "center",
      ref: { pathToColumnRef },
    },
    {
      field: "status",
      sortable: false,
      resizable: true,
      headerName: "أماكن التوقف",
      headerAlign: "center",
      minWidth:'50',
      maxWidth:'50',
      align: "center",
      renderCell: ({ id }) => {
        return (
          <>
            <div
              className="autofocusenter"
              // data-field={params.field}
              onKeyDown={handleKeyDown2}
              tabindex={0} // Allow div to be focusable
            >
              <img
                src="img/vuesax-linear-eye.svg"
                onClick={() => {
                  setOpenPopupStop(true);
                  setRecordForEdit(null);
                  setClickedEmployeeId(id);
                  dispatch(fetchTripBYId(id));
                }}
                alt="eye-image"
              />
            </div>
            <PopupStop
              title="أماكن التوقف"
              openPopup={openPopupStop}
              setOpenPopup={setOpenPopupStop}
            >
              <StopTrip id_Trip={clickedEmployeeId} TripID={TripID} />
            </PopupStop>
          </>
        );
      },
    },

    {
      field: "action2",
      headerAlign: "center",
      headerName: "",
      resizable: true,
      sortable: false,
      minWidth:'50',
      maxWidth:'50',
      align: "center",
      renderCell: (params) => {
        const id = params.row.id;
        const date = params.row.date;
        const time = params.row.time;
        const tripDateTime = new Date(`${date}T${time}`);
        const isDateTimePassed = `${date}${time}` < new Date();
        const compareResult = tripDateTime.getTime() - currentTime1.getTime();

        return (
          <Box>
            <Button
              className={classes.editButton}
              onClick={() => goTo({ id, compareResult })}
            >
              <img
                src="img/eye.svg"
                className={classes.editImg}
                alt="delete icon"
              ></img>
            </Button>
          </Box>
        );
      },
    },
    {
      field: "action3",
      headerAlign: "center",
      resizable: true,
      sortable: false,
      headerName: "",
      minWidth:'50',
      maxWidth:'50',
      align: "center",
      renderCell: (params) => {
        const id = params.row.id;
        const date = params.row.date;
        const time = params.row.time;
        const tripDateTime = new Date(`${date}T${time}`);
        const isDateTimePassed = `${date}${time}` < new Date();
        const compareResult = tripDateTime.getTime() - currentTime1.getTime();
        return (
          <Box>
            {UpdateTrip === true || UpdateTripdefault === true ? (
              <Button
                className={classes.editButton}
                onClick={() => {
                  handleE(id);
                }}
                disabled={compareResult < 0 ? true : false}
              >
                <img
                  src="img/edit.svg"
                  className={classes.editImg}
                  alt="delete icon"
                ></img>
              </Button>
            ) : (
              <></>
            )}

            <PopupCreate
              title=" تعديل رحلة"
              openPopup={openPopup2}
              setOpenPopup={setOpenPopup2}
              setDisplayshortcut={setShortcut}
            >
              <TripFormEdit
                id_Trip={clickedEmployeeId}
                TripID={TripID}
                recordForEdit={recordForEdit11}
              />
            </PopupCreate>
          </Box>
        );
      },
    },
    {
      field: "action4",
      headerAlign: "center",
      resizable: true,
      sortable: false,
      headerName: "",
      align: "center",
      minWidth:'50',
      maxWidth:'50',
      renderCell: (params) => {
        const id = params.row.id;
        const date = params.row.date;
        const time = params.row.time;
        const tripDateTime = new Date(`${date}T${time}`);
        const isDateTimePassed = `${date}${time}` < new Date();
        const compareResult = tripDateTime.getTime() - currentTime1.getTime();

        return (
          <Box>
            {SoftDeleteTrip === true || SoftDeleteTripdefault === true ? (
              <Button
                className={classes.editButton}
                onClick={() => {
                  handleDelete(id);
                }}
                disabled={compareResult < 0 ? true : false}
              >
                <img
                  src="img/delete.svg"
                  className={classes.editImg}
                  alt="delete icon"
                ></img>
              </Button>
            ) : (
              <></>
            )}
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </Box>
        );
      },
    },
    {
      field: "حالة الرحلة",
      headerAlign: "center",
      minWidth:'50',
      maxWidth:'50',
      sortable: false,
      headerName: "",
      align: "center",
      renderCell: (params) => {
        const id = params.row.id;
        const date = params.row.date;
        const time = params.row.time;
        const tripDateTime = new Date(`${date}T${time}`);
        const isDateTimePassed = `${date}${time}` < new Date();
        const compareResult = tripDateTime.getTime() - currentTime1.getTime();

        return (
          <Box>
            {compareResult < 0 ? (
              <Button className="finishtrip" disabled={true}>
                منتهية
              </Button>
            ) : (
<></>            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box>
        <Box
          className="box-triptype1 triptablewidth"
          disabled
          style={{ display: "flex", gap: "10%" }}
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },

            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "none",
              fontSize: "15px !important",
            },
            "& .MuiDataGrid-cellContent": {
              fontSize: "15px !important",
              color: `${colors.primary[1000]} !important`,
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.white[100],
            },
          }}
        >
          {/* {loading ? (
            <div className="spinner1-box">
              <svg class="spinner1" viewBox="0 0 50 50">
                <circle
                  class="path1"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </div>
          ) : ( */}
          <div style={{ position: "relative", width: "100%",}}>
            {/* <div style={{width:'40vw', height:'400px' , zIndex:'2', background:'rgba(187, 187, 187, 0.4)',position: 'absolute'}}></div> */}
            {/* <DataGridPro
              className="field-table1-trip"
              // className={`field-table1-trip ${isDisabled ? 'disabled-grid' : ''}`}
              style={{
                position: "absolute",
                zIndex: "1",
                width: "40vw !important",
                // overflow: 'scroll',
                
               
              }}
              rows={Trips || []}
              // disableRowSelectionOnClick={isTableDisabled}
              columns={columns.map((column) => ({
                ...column,
                headerClassName: "my-custom-header-class", // Add this line if you want a custom class for headers
                headerRender: (params) => (
                  <div
                    tabIndex={params.field === focusedColumn ? 0 : -1} // Set tabindex based on focused column
                    onFocus={() => setFocusedColumn(params.field)}
                  >
                    {params.headerName}
                  </div>
                ),
              }))}
              // disabled={isDisabled}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              components={{
                Toolbar: EditToolbar,
              }}
            /> */}
                <DataGridPro
            rows={Trips || []}
            columns={columns}
            className="field-table3"
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            components={{
              Toolbar: EditToolbar,
            }} 
          />
          </div>
        </Box>
      </Box>

      {show && (
        <>
          <Box>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              style={{
                marginTop: "10px",
                boxShadow: "0px 3px 6px rgba(0,0,0,0.6)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "98.6%",
              }}
            >
              <Grid
                item
                xs={9}
                md={9}
                sm={9}
                style={{
                  paddingRight: "10px",
                  fontSize: "20px",
                }}
              >
                {pathTrip}
              </Grid>
              <Grid
                item
                xs={3}
                md={3}
                sm={3}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  
                }}
              >
                <p style={{ padding: "0px 5px", fontWeight: "bold" ,fontSize:'12px'}}>
                  رقم المقاعد
                </p>
                <Grid
                  item
                  xs={6}
                  md={6}
                  sm={6}
                  style={{ padding: "10px 0px" }}
                  className="input-seats"
                >
                  <input
                    style={{ width: "90%" }}
                    ref={inputRef}
                    onChange={handleChange} // Attach the onChange event handler
                    value={valueinput}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Grid item xs={12} md={12} sm={12}>
            <Box className="">
              {loadingtrip ? (
                <div className="spinner1-box">
                  <svg class="spinner1" viewBox="0 0 50 50">
                    <circle
                      class="path1"
                      cx="25"
                      cy="25"
                      r="20"
                      fill="none"
                      stroke-width="5"
                    ></circle>
                  </svg>
                </div>
              ) : (
                <SetRed
                  setNewStatus={setNewStatus}
                  setNewStatusAvail={setNewStatusAvail}
                  newStatus={newStatus}
                  newStatusAvail={newStatusAvail}
                  show={show}
                  id_company={id_company}
                  nofocus={inputRef.current}
                  typetrip={TripID.trip_type}
                  id_trip={idTrip}
                  setShortcut={setShortcut}
                  setShowPassenger={setShowPassenger}
                  setNumberPassenger={setNumberPassenger}
                  numberpassenger={numberpassenger}
                  openPopuppassenger={openPopuppassenger}
                  setOpenPopuppassenger={setOpenPopuppassenger}
                  valueinput={valueinput}
                  setValueinput={setValueinput}
                  setPathtrip={setPathtrip}
                  schortcut={schortcut}
                />
              )}
            </Box>
          </Grid>
        </>
      )}
    </>
  );
}
