import { Grid } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import "./addPrivacyPolicy.css";
import JoditEditor from "jodit-react";
import axios from "axios";
import * as sweetalert from "sweetalert";
import { useNavigate } from "react-router-dom";
import Controls from "../../component/controls/Controls";
import {
  fetchPriveePolice,
  insertPriveePolice,
} from "../../redux/prvicepolice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  fectchprvicepoliceCompany,
  insertPriveePoliceCompany,
} from "../../redux/prvicepoliceCompany";
import { fetchCompany } from "../../redux/companyTransportSlice";
function AddPrivacyPolicyCompany({ id_company }) {
  const editor = useRef(null);
  const { loading, PriveePoliceCompany } = useSelector(
    (state) => state.PriveePoliceCompany
  );
  const [content, setContent] = useState(
    PriveePoliceCompany?.privacyPolicy || ""
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if(id_company){
      dispatch(fectchprvicepoliceCompany(id_company));
    }
  }, [dispatch, id_company]);

  const handleSubmit1 = (e) => {
    e.preventDefault();
    const bodyFormData = new FormData();
    bodyFormData.append("privacyPolicy", content);
    bodyFormData.append("id", id_company);
    dispatch(insertPriveePoliceCompany(bodyFormData));
  };
  return (
    <div className="main-div">

        <form
          className="form-group new-design"
          method="post"
          style={{ padding: "0 50px" }}
          enctype="multipart/form-data"
          onSubmit={handleSubmit1}
        >
          <div className="editor">
            <JoditEditor
              ref={editor}
              value={PriveePoliceCompany.privacyPolicy}
              tabIndex={13} // tabIndex of textarea
              onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => {}}
            />
          </div>

          <Grid item md={6} xs={12} spacing={2} class="div-btn-submit">
            <div className="bottonFullwidth">
              <Controls.Button type="submit" text="إضافة" />
            </div>
          </Grid>
        </form>
 
    </div>
  );
}

export default AddPrivacyPolicyCompany;
