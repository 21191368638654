import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const updateaudit = createAsyncThunk(
  "Audit/updateaudit",
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const res = await axios.post(`${BaseUrl}/audit`, values, {
        withCredentials: true,
      });
      if (res.data.code === 200) {
        sweetalert({
          text: "تم التشطيب بنجاح",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {});
      }
    } catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage = error.response?.data?.data || error.response?.data?.message;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);

const AuditSlice = createSlice({
  name: "Audit",
  initialState: {
    Audit: [],
    loading: false,
    loadingupdate: false,
    error: null,
  },
  reducers: {
    clearPath: (state) => {
      state.Audit = [];
      state.loading = false;
      state.loadingupdate = false;
      state.error = null;
    },
  },
  extraReducers: {
    //Update  trip types
    [updateaudit.pending]: (state, action) => {
      state.loadingupdate = true;
      state.error = null;
    },
    [updateaudit.fulfilled]: (state, action) => {
      state.Audit = action.payloa;
      state.loadingupdate = false;
    },
    [updateaudit.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingupdate = false;
    },
  },
});
export const { clearPath } = AuditSlice.actions;
export default AuditSlice.reducer;
