import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import packageJson from "../../package.json";
import * as sweetalert from "sweetalert";
export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchAllPermission = createAsyncThunk(
  'Permission/fetchAllPermission',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAvailablePermissionsToAssign/${id}` ,{ withCredentials: true });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
  
);


export const AssginPermission = createAsyncThunk(
  'Permission/AssginPermission',
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {

      const res=await axios.post(`${BaseUrl}/AssignPermissionToSystemUser`, values,{ withCredentials: true });

   
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const ResmovePermission = createAsyncThunk(
  'Permission/ResmovePermission',
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {

      const res=await axios.post(`${BaseUrl}/removePermissionsSystemUser`, values,{ withCredentials: true });

   
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const activePermission = createAsyncThunk(
  'Permission/activePermission',
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {

      const res=await axios.post(`${BaseUrl}/activePermission`, values,{ withCredentials: true });

   
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const PermissionSlice = createSlice({
  name: 'Permissions',
  initialState: { Permission: [], RemovePermission:[],loading: false, error: null },
  reducers: {
    clearPermissionState: (state) => {
      state.Permission = [];
      state.RemovePermission = [];
      state.loading = false;
      state.error = null;
    }
  },
  extraReducers: {
    //fetch System user
    [fetchAllPermission.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchAllPermission.fulfilled]: (state, action) => {
      state.Permission = action.payload;
      state.loading = false;
    },
    [fetchAllPermission.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    // //Assgin Permission
    [AssginPermission.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [AssginPermission.fulfilled]: (state, action) => {
      state.insertPermission=action.payload;
      state.loading = false;
    },
    [AssginPermission.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    // //Assgin Permission
    [ResmovePermission.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [ResmovePermission.fulfilled]: (state, action) => {
      state.RemovePermission=action.payload;
      state.loading = false;
    },
    [ResmovePermission.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export const { clearPermissionState } = PermissionSlice.actions;
export default PermissionSlice.reducer;
