import React, { useState, useEffect, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchCompanyById,
  updateCompany,
} from "../../redux/companyTransportDataSlice";
export default function CompanyEditForm(props) {
  const dispatch = useDispatch();
  const { loading, CompaniesData ,loadingUpdate} = useSelector((state) => state.CompanyData);
  const [logoChanged, setLogoChanged] = useState(false);
  const initialFValues = {
    id: "",
    name_ar: "",
    description: "",
    phone_number: "",
    social_media: {
      WhatsApp: "",
      facebook: "",
    },
    logo: "",
  };

  useEffect(() => {
    if (props.id_company) {
      dispatch(fetchCompanyById(props.id_company));
    }
  }, [dispatch, props.id_company]);

  useEffect(() => {
    if (CompaniesData && CompaniesData.social_media) {
      setValues((prevValues) => ({
        id: CompaniesData.id || "",
        name_ar: CompaniesData.name_ar || "",
        description: CompaniesData.description || "",
        phone_number: CompaniesData.phone_number || "",
        social_media: {
          WhatsApp: CompaniesData.social_media.WhatsApp || "",
          facebook: CompaniesData.social_media.facebook || "",
        },
        logo: CompaniesData.logo || "",
      }));
    }
  }, [CompaniesData]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name_ar" in fieldValues)
      temp.name_ar = fieldValues.name_ar ? "" : "هذا الحقل مطلوب";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (logoChanged) {
      formData.append("id", values.id);
      formData.append("name_ar", values.name_ar);
      formData.append("description", values.description);
      formData.append("social_media[WhatsApp]", values.social_media.WhatsApp);
      formData.append("social_media[facebook]", values.social_media.facebook);
      formData.append("logo", values.logo);
    } else {
      formData.append("id", values.id);
      formData.append("name_ar", values.name_ar);
      formData.append("description", values.description);
      formData.append("social_media[WhatsApp]", values.social_media.WhatsApp);
      formData.append("social_media[facebook]", values.social_media.facebook);
    }
    dispatch(updateCompany(formData));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("social_media.")) {
      const nestedFieldName = name.split(".")[1];
      setValues((prevValues) => ({
        ...prevValues,
        social_media: {
          ...prevValues.social_media,
          [nestedFieldName]: value,
        },
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const [file, setFile] = useState(values.logo);
  const handleImageChange = (e) => {
    setValues({ ...values, logo: e.target.files[0] });
    setFile(e.target.files[0]);
    const selectedFile = e.target.files[0];
    setFile(URL.createObjectURL(selectedFile));
    setLogoChanged(true);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        {loading ? (
          <div className="spinner1-box">
            <svg class="spinner1" viewBox="0 0 50 50">
              <circle
                class="path1"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          </div>
        ) : (
          <>
            <Controls.Input
              className="tesr"
              name="name_ar"
              InputLabelProps={{ shrink: true }}
              label={
                <Fragment>
                  <InputAdornment position="end">
                    <IconButton
                      className="iconplaceholder"
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <img src="img/office/vuesax-linear-building.svg"></img>
                    </IconButton>
                    <span className="placeholdertitle"> اسم الشركة </span>
                  </InputAdornment>
                </Fragment>
              }
              value={values.name_ar}
              onChange={handleInputChange}
              error={errors.name_ar}
            />
            <Controls.Input
              className="tesr"
              name="description"
              minRows={2}
              multiline
              InputLabelProps={{ shrink: true }}
              label={
                <Fragment>
                  <InputAdornment position="end">
                    <IconButton
                      className="iconplaceholder"
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <img src="img/office/vuesax-linear-building.svg"></img>{" "}
                    </IconButton>
                    <span className="placeholdertitle">وصف الشركة</span>
                  </InputAdornment>
                </Fragment>
              }
              value={values.description}
              onChange={handleInputChange}
              error={errors.description}
            />
            <Controls.Input
              InputLabelProps={{ shrink: true }}
              className="tesr"
              name="social_media.WhatsApp"
              label={
                <Fragment>
                  <InputAdornment position="end">
                    <IconButton
                      className="iconplaceholder"
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <img src="img/office/vuesax-linear-call-calling.svg"></img>
                    </IconButton>
                    <span className="placeholdertitle">حساب الواتساب</span>
                  </InputAdornment>
                </Fragment>
              }
              value={values.social_media.WhatsApp}
              onChange={(e) => {
                const { name, value } = e.target;
                if (name.startsWith("social_media.")) {
                  const nestedFieldName = name.split(".")[1];
                  setValues((prevValues) => ({
                    ...prevValues,
                    social_media: {
                      ...prevValues.social_media,
                      [nestedFieldName]: value,
                    },
                  }));
                } else {
                  setValues((prevValues) => ({
                    ...prevValues,
                    [name]: value,
                  }));
                }
              }}
            />
            
            <Controls.Input
              InputLabelProps={{ shrink: true }}
              className="tesr"
              name="social_media.facebook"
              label={
                <Fragment>
                  <InputAdornment position="end">
                    <IconButton
                      className="iconplaceholder"
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <img src="img/office/vuesax-linear-call-calling.svg"></img>
                    </IconButton>
                    <span className="placeholdertitle">حساب الفيس بوك</span>
                  </InputAdornment>
                </Fragment>
              }
              value={values.social_media.facebook}
              onChange={(e) => {
                const { name, value } = e.target;
                if (name.startsWith("social_media.")) {
                  const nestedFieldName = name.split(".")[1];
                  setValues((prevValues) => ({
                    ...prevValues,
                    social_media: {
                      ...prevValues.social_media,
                      [nestedFieldName]: value,
                    },
                  }));
                } else {
                  setValues((prevValues) => ({
                    ...prevValues,
                    [name]: value,
                  }));
                }
              }}
            />

            <Grid item xs={12}>
              <Controls.Input
                type="file"
                name="logo"
                className="logo"
                label={
                  <Fragment>
                    <InputAdornment position="end">
                      <IconButton
                        className="iconplaceholder"
                        aria-label="upload image"
                        edge="end"
                      >
                        <img src="img/emp/profile-circle.svg" alt="Profile" />
                      </IconButton>
                      <span className="placeholdertitle">الصورة</span>
                    </InputAdornment>
                  </Fragment>
                }
                values={file}
                onChange={handleImageChange}
                error={errors.logo}
                inputProps={{
                  accept: "image/*",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                  width: "100%",
                }}
              >
                <div style={{ width: "100px", height: "100px" }}>
                  {file ? (
                    <img
                      src={file}
                      alt="company-image"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <img
                      src={values.logo}
                      alt="company-image"
                      style={{ width: "100%", height: "100%" }}
                    />
                  )}
                </div>
              </div>
            </Grid>
            {loadingUpdate  ? (
              <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="load loadingupdate"
              style={{ background: "#F9B917", borderRaduis: "10px" }}
            >
              <div v-if="loading" class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
              </div>
            </div>
          </div>
        ) : (
          <div className="bottonFullwidth"  style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <Controls.Button type="submit" text="تعديل" />
            </div>
        )}
          </>
        )}
      </Grid>
    </Form>
  );
}
