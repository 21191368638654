import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";
import { fetchTripBYId } from "./tripsSlice";
import { fetchSetsBus } from "./SetsBus";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;

export const fetchAllReservations = createAsyncThunk(
  "reservations/fetchAllReservations",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(`${BaseUrl}/reservation/${id}`, {
        withCredentials: true,
      });
      const responseData = response.data.data;
      return responseData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchPassengerReservation = createAsyncThunk(
  "reservations/fetchPassengerReservation",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(
        `${BaseUrl}/reservation/${data.id_reservation}`,
        {
          withCredentials: true,
        }
      );

      const responseData = response.data.data;
      // Assuming you have a specific passenger ID you want to find
      const passengerIdToFind = data.id_passenger;

      // Find the passenger with the specified ID
      const foundPassenger = responseData.passengers.find(
        (passenger) => passenger.id === passengerIdToFind
      );

      // You can return foundPassenger here if you want to use it in Redux state
      return foundPassenger;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const insertreservation = createAsyncThunk(
  "reservations/insertreservation",
  async (values, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    if (values.status == "no passenger") {
      try {
        dispatch(setLoading1(true));
        const response = await axios.post(
          `${BaseUrl}/addReservation`,
          values.data,
          { withCredentials: true }
        );
        const responseData = response.data.data;

        localStorage.setItem("changeseat", false);
        localStorage.setItem("openpassenger", false);
        if (response.data.status == 200) {
          sweetalert({
            text: "تم  حجز الكراسي بدون بيانات مسافرين",
            icon: "success",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);

            localStorage.setItem("openpassenger", false);
          });
        }
        dispatch(setReservationData(responseData));
        dispatch(setLoading1(false));
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(setLoading1(true));
          dispatch(setstatusnoAvailabel(true));
          sweetalert({
            text: error.response.data.message,
            icon: "error",
            buttons: {
              confirm: {
                text: "إغلاق",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          });
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue("An error occurred");
        }
      }
    } else {
      try {
        dispatch(setLoading1(true));
        const response = await axios.post(`${BaseUrl}/addReservation`, values, {
          withCredentials: true,
        });
        const responseData = response.data.data;

        dispatch(setReservationData(responseData));
        localStorage.setItem("openpassenger", true);
        localStorage.setItem("AddReservation", false);
        dispatch(setLoading1(false));
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(setLoading1(true));
          dispatch(setstatusnoAvailabel(true));
          sweetalert({
            text: error.response.data.message,
            icon: "error",
            buttons: {
              confirm: {
                text: "إغلاق",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          });
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue("An error occurred");
        }
      }
    }
  }
);
function removeSeatReservationFromLocalStorage(tripId, seatNumber, userId) {
  // Retrieve the current array of reservations from localStorage
  const seatReservations =
    JSON.parse(localStorage.getItem("seatpinding")) || [];

  // Filter out the reservation that needs to be removed
  const updatedReservations = seatReservations.filter(
    (reservation) =>
      !(reservation.trip_id == tripId && reservation.seat_number == seatNumber)
  );

  // Save the updated array back to localStorage
  localStorage.setItem("seatpinding", JSON.stringify(updatedReservations));
}

export const deleteSeatToReservation = createAsyncThunk(
  "reservations/deleteSeatToReservation",
  async (data, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      localStorage.setItem("changeseat", true);
      await axios.post(
        `${BaseUrl}/deleteReservation/${data.id_reservation}?branch=${data.branch}`,
        data.branch,
        { withCredentials: true }
      );
      removeSeatReservationFromLocalStorage(data.trip_id, data.seat_number);

      sweetalert({
        text: "تم الحذف بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        localStorage.setItem("openpassenger", false);
        localStorage.setItem("changeseat", false);
      });
    } catch (error) {
      localStorage.setItem("changeseat", false);
      return rejectWithValue(error.message);
    }
  }
);
export const deleteSeatToReservationnodata = createAsyncThunk(
  "reservations/deleteSeatToReservation",
  async (data, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      await axios.post(
        `${BaseUrl}/deleteReservation/${data.reservationId}?branch=${data.id_branch}`,
        data.id_branch,
        { withCredentials: true }
      );

      sweetalert({
        text: "تم الحذف بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        dispatch(fetchTripBYId(data.id));
        localStorage.setItem("openpassenger", false);
        localStorage.setItem("changeseat", false);
      });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const ReservationSlice = createSlice({
  name: "reservations",
  initialState: {
    IDreservation: [],
    reservation: [],
    deletseatreservation: [],
    insertreservation1: [],
    passengerReservation: [],
    status: false,
    statusNoAvailabel: false,
    loading: true,
    error: null,
  },
  reducers: {
    clearreservationsState: (state) => {
      state.passengerReservation = [];
      state.IDreservation = [];
      state.reservation = [];
      state.deletseatreservation = [];
      state.insertreservation1 = [];
      state.status = false;
      state.statusNoAvailabel = false;
      state.loading = false;
      state.error = null;
    },
    clearStatusState: (state) => {
      state.status = "";
    },
    setReservationData(state, action) {
      state.insertreservation1 = action.payload;
    },
    setstatus(state, action) {
      state.status = action.payload;
    },
    setstatusnoAvailabel(state, action) {
      state.statusNoAvailabel = action.payload;
    },
    setLoading1(state, action) {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    //fetch All reservation
    [fetchAllReservations.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchAllReservations.fulfilled]: (state, action) => {
      state.IDreservation = action.payload;
      state.loading = false;
    },

    [fetchAllReservations.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    //fetch passenger in  reservation
    [fetchPassengerReservation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchPassengerReservation.fulfilled]: (state, action) => {
      state.passengerReservation = action.payload;
      state.loading = false;
    },
    [fetchPassengerReservation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    //insert reservation
    [insertreservation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [insertreservation.fulfilled]: (state, action) => {
      state.reservation = action.payload;
      state.loading = false;
    },
    [insertreservation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //delete  seat for reservation
    [deleteSeatToReservation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSeatToReservation.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        state.deletseatreservation = state.deletseatreservation.filter(
          (el) => el.id !== action.payload.id
        );
      }
      state.loading = false;
    },

    [deleteSeatToReservation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export const {
  clearStatusState,
  setLoading1,
  setstatus,
  setReservationData,
  clearreservationsState,
  setstatusnoAvailabel,
} = ReservationSlice.actions;
export default ReservationSlice.reducer;
