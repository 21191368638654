import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchAllCity = createAsyncThunk(
  'book/fetchTrips',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/cities`, { withCredentials: true });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



const CitySlice = createSlice({
  name: 'City',
  initialState: { Cities: [], loading: false, error: null },
  reducers: {
    clearCity: (state) => {
      state.Cities = [];
      state.loading = false;
      state.error = null;

    },
  },
  extraReducers: {
    //fetch
    [fetchAllCity.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchAllCity.fulfilled]: (state, action) => {
      state.Cities = action.payload;
      state.loading = false;
    },
    [fetchAllCity.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export const { clearCity } = CitySlice.actions;
export default CitySlice.reducer;
