import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { Select, MenuItem } from "@material-ui/core";
import Controls from "../../../component/controls/Controls";
import { Button } from "@mui/material";
import PopupBus from "../../../component/PopupBus";
import BusForm from "../BusForm";
import SearchBar from "material-ui-search-bar";
import BusesType from "./BusesType";
import { fetchSeateTypeAll } from "../../../redux/SeatsTypeSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import BusTypeImage from "../BusTypeImage";
import { fetchCompanyById } from "../../../redux/companyTransportDataSlice";
const useStyles = makeStyles({
  gridDivide11: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "6px",
    flexWrap: "wrap",
    padding: "20px 25px",
    background: "#fff",
    width: "91%",
    borderRadius: "10px",
    boxShadow: "0 2px 6px rgba(0,0,0,0.16)",
  },
  "@media (min-width: 768px) and (max-width:1556px)": {
    gridDivide11: {
      justifyContent: "center",
      alignItems: "center",
      gap: "1%",
    },
  },
  mt: {
    marginTop: "30px",
  },

  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    flexDirection: "row-reverse",
    paddingRight: "8px",
    height: "45px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
    marginRight: "3px ",
  },

  "@media (min-width: 600px) and (max-width:1280px)": {
    searchDesign: {
      marginBottom: "15px",
    },
  },
  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  contentDivide: {
    width: "100%",
    marginBottom: "40px",
  },
  Marginbox1: {
    marginBottom: "20px !important",
    maxWidth: "100% !important",
    flexBasis: "100%",
  },
});

const BusListType = ({ mylist, setEbanle, id_company, setShortcut }) => {
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    let typedWord = "";
    let typedWord2 = "";

    const handleKeyDown = (event) => {
      if (/^[a-zA-Z]$/.test(event.key)) {
        typedWord += event.key.toLowerCase();
        typedWord2 += event.key;

        const lastThreeChars = typedWord.substring(typedWord.length - 3);

        if (lastThreeChars === "add") {
          localStorage.setItem("displayshortcut", "1");
          setOpenPopup2(true);
          setRecordForEdit(null);
          setShortcut(1);
          typedWord = "";
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //Add Permission
  const AddSeat =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "add.seat.type");

  // Permission as default
  const AddSeatdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "add.seat.type"
    );

  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Set the initial page to 1
  const itemsPerPage = 4;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const classes = useStyles();
  const [age, setAge] = useState("all");
  const [searched, setSearched] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [imageBusTypeId, setImageBusTypeId] = useState("");
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSeateTypeAll(id_company))
      .then((response) => {
        // Check if the API response has a 'success' field and it's not true
        if (response.data && response.data.success !== "true") {
          // Set the error message from the API response
          setErrorMessage(response.data.data.original.message);
        } else {
          // API request was successful, clear the error message
          setErrorMessage("");
        }
      })
      .catch((error) => {
        // Handle any other errors, e.g., network issues
        console.error("Error:", error);
        setErrorMessage("An error occurred while fetching data.");
      });
  }, [dispatch, id_company]);

  const { loading, SeatType } = useSelector((state) => state.SeatsType);

  const requestSearch = (searchedVal) => {};
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const onHandleReset = () => {};
  return (
    <Grid
      className={classes.gridContainer}
      item
      lg={12}
      style={{ cursor: "pointer" }}
    >
      <Grid
        container
        spacing={4}
        className={classes.gridContainer}
        justify="center"
      >
        <Grid
          container
          xs={12}
          className={classes.Marginbox1}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {AddSeat === true || AddSeatdefault === true ? (
            <Grid lg={2} md={4} sm={3} className={classes.AddDesign}>
              <div className="bottonFullwidth1">
                <Controls.Button
                  type="submit"
                  text="إضافة"
                  onClick={() => {
                    setOpenPopup(true);
                    setRecordForEdit(null);
                    setEbanle(false);
                  }}
                />
              </div>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <PopupBus
          title=" نوع الباص"
          openPopup={openPopup2}
          setOpenPopup={setOpenPopup2}
          setDisplayshortcut={setShortcut}
        >
          <BusTypeImage
            id_bus_type={imageBusTypeId}
            recordForEdit={recordForEdit}
            loading={loading}
          />
        </PopupBus>
        <PopupBus
          title="إضافة الباص "
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <BusForm recordForEdit={recordForEdit} />
        </PopupBus>
        {errorMessage ? (
          <div className="error-message">{errorMessage}</div>
        ) : (
          <Grid className={classes.gridDivide11}>
            <BusesType
              loading={loading}
              id_company={id_company}
              startIndex={startIndex}
              endIndex={endIndex}
              BusType={SeatType}
              openPopup2={openPopup2}
              setOpenPopup2={setOpenPopup2}
              setImageBusTypeId={setImageBusTypeId}
            />

            <Grid lg={12} md={12} sm={12}>
              {SeatType && SeatType.length > 0 && (
                <Pagination
                  count={Math.ceil(SeatType.length / itemsPerPage)}
                  page={currentPage}
                  onChange={(event, page) => setCurrentPage(page)}
                  className={classes.mt}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default BusListType;
