import React from 'react'
import { Dialog, DialogTitle, DialogContent, makeStyles, Typography } from '@material-ui/core';
import Controls from "./controls/Controls";
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles(theme => ({
 
    dialogWrapper: {
        position: 'absolute',
        top: theme.spacing(5),
        width:'662px !important',
        maxWidth:'662px !important',
        height:'500px !important'
    },
    dialogTitle: {
        padding: '0px '
    },
    headerDesgin:{
        textAlign:'center',
        color:'#F9B917',
        fontSize:'18px',
    },
    "@media (min-width: 768px) and (max-width:1556px)": {
        dialogWrapper: {
            height:'590px !important',
            top: '3% !important',
        },
      },

}))

export default function PopupCompany(props) {
    const { title, children, openPopup, setOpenPopup } = props;
    const classes = useStyles();
    const handleClose = (()=>{
        setOpenPopup(false);
        localStorage.setItem("displayshortcut", "0");
        // window.location.reload();
    })

    return (
        <Dialog open={openPopup}  classes={{ paper: classes.dialogWrapper }} >
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' ,margin:'33px 0px 18px 0px', alignItems:'center' }}>
                    <Typography variant="h6" className={classes.headerDesgin} component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <Controls.ActionButton
                    
                        color="secondary"
                        onClick={()=>{handleClose()}}>
                        <CloseIcon  />
                    </Controls.ActionButton>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}
