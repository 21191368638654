import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Popup from "../../component/Popup";
import Popupupdate from "../../component/Popupupdate";
import EmployeeFormPassword from "./EmployeeFormPassword";
import ConfirmDialog from "../../component/ConfirmDialogCompany";
import { useDispatch } from "react-redux";
import {
  deleteEmployeeById,
  fetchEmployeeByID,
  fetchEmployeeCompany,
} from "../../redux/employeeSlice";
import EmployeeFormEdit from "./EmployeeEdit";
import { useSelector } from "react-redux";
import CheckboxReservation from "./CheckboxReservation";
import {
  citiesBranchEmployee,
  fetchCitiesBranchEmployee,
} from "../../redux/AddCityBranchEmployee";
import CheckboxBranch from "./CheckboxBranch";
import { fetchCompany } from "../../redux/companyTransportSlice";
import CheckboxCompany from "./CheckboxCompany";
import { fetchCompanyEmpBranch } from "../../redux/AddCompanyEmpBranch";
import { fetchAssignAdminCompany } from "../../redux/AddAssignAdminCompany";
import { fetchCompanyBranch } from "../../redux/companyTransportDataSlice";
import { useLocation, useNavigate } from "react-router-dom";
const useStyles = makeStyles({
  root: {
    minWidth: 200,
  },
  "@media (min-width: 768px) and (max-width:1366px)": {
    root: {
      marginBottom: "20px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 !important",
    position: "relative",
  },
  media: {
    width: "100%",
    height: "100%",
  },
  title: {
    fontFamily: "beIN Normal",
    fontSize: 27,
    color: "#000002",
    fontWeight: "normal",
  },
});

const OutlinedCard = ({ startIndex, endIndex, Employeeies }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state;
  useEffect(() => {
    dispatch(fetchEmployeeCompany(id));
  }, [dispatch]);

  const { user } = useSelector((state) => state.auth);
  //Add Permission
  const UpdateEmpoyee =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "update.systemUser"
    );

  const SoftdeleteEmpoyee =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "soft.delete.systemUser"
    );

 
  const AssginCompanyToadmin =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "assign.company.admin"
    );

 
  const AssginCompanyBranch =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "view.company.emp.branch"
    );


  // Permission as default
  const UpdateEmpoyeedefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "update.systemUser"
    );

    user.rolePermissionsNames.some((permission) =>
      permission.includes("update.systemUser")
    );
  const SoftdeleteEmpoyeedefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "soft.delete.systemUser"
    );


  const AssginCompanyToadmindefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "assign.company.admin"
    );


  const AssginCompanyBranchdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "view.company.emp.branch"
    );


  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopupPassword, setOpenPopupPassword] = useState(false);
  const [openPopupEdit, setOpenPopupEdit] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopupReservation, setOpenPopupReservation] = useState(false);
  const [recordForReservation, setRecordForReservation] = useState(null);
  const [openPopupBranch, setOpenPopupBranch] = useState(false);
  const [recordForBranch, setRecordForBranch] = useState(null);
  const [openPopupCompany, setOpenPopupCompany] = useState(false);
  const [recordForCompany, setRecordForCompany] = useState(null);
  const [clickedEmployeeId, setClickedEmployeeId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [openPopup1, setOpenPopup1] = useState(false);

  const { loading, EmployeeiesByID, error,loadingdelete } = useSelector(
    (state) => state.Employee
  );

  const handleEdit = useCallback(
    (id) => {
      const record = Employeeies.find((emp) => emp.user_id === id);
      if (record) {
        setRecordForEdit(record);
        setOpenPopupEdit(true);
        dispatch(fetchEmployeeByID(id));
      }
    },
    [setOpenPopupEdit, setRecordForEdit, Employeeies]
  );
  const handleAddreservation = (id) => {
    setRecordForReservation(null);
    setOpenPopupReservation(true);
    dispatch(fetchCitiesBranchEmployee(id));
  };
  const handleAddBranch = (id) => {
    setRecordForBranch(null);
    setOpenPopupBranch(true);
    // dispatch(fetchCompanyEmpBranch(id))
  };
  const handleAddCompany = (id) => {
    setRecordForCompany(null);
    setOpenPopupCompany(true);
    dispatch(fetchAssignAdminCompany(id));
  };

  function handlePassword(id) {
    setOpenPopupPassword(true);
    setRecordForEdit(null);
  }
  function handleDelete() {
    setConfirmDialog({
      isOpen: true,
      title: "أنت على وشك حذف الموظف  ",
      img: "img/emp/Inbox cleanup-rafiki.svg",
      onConfirm: () => {
        dispatch(deleteEmployeeById(clickedEmployeeId));
      },
    });
  }
  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <>
       {Array.isArray(Employeeies) ? (
      Employeeies.slice(startIndex, endIndex).map((emp) => (
        <Card className={classes.root} variant="outlined" key={emp.user_id}>
          <CardContent className={classes.content}>
            <div className="card-menu">
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(event) => {
                  dispatch(fetchEmployeeByID(emp.user_id));
                
                  setOpenPopup1(true);
                  setRecordForEdit(null);
                  setClickedEmployeeId(emp.user_id);
                  setCompanyId(emp.id_company);
                  setAnchorEl(event.currentTarget);
                }}
              >
                <img src="img/More icon.svg" />
              </Button>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="testtt"
              >
                <MenuItem
                  className="menuStyle"
                  onClick={() =>
                    navigate(`/permission-user`, { state: emp.user_id })
                  }
                >
                  <img src="img/Path 17091.svg" />
                  <span>إسناد صلاحيات</span>
                </MenuItem>
                {UpdateEmpoyee === true || UpdateEmpoyeedefault === true ? (
                  <MenuItem
                    className="menuStyle"
                    onClick={() => handleEdit(clickedEmployeeId)}
                  >
                    <img src="img/Path 17091.svg" />
                    <span>تعديل</span>
                  </MenuItem>
                ) : (
                  <></>
                )}

                <MenuItem
                  onClick={() => handlePassword(clickedEmployeeId)}
                  className="menuStyle"
                >
                  <img src="img/vuesax-linear-refresh-2.svg" />
                  <span>تغيير كلمة المرور</span>
                </MenuItem>

                {EmployeeiesByID.roles_name === "BranchEmployee" ? (
                  <MenuItem
                    onClick={() => handleAddreservation(clickedEmployeeId)}
                    className="menuStyle"
                  >
                    <img src="img/vuesax-linear-refresh-2.svg" />
                    <span>إسناد وجهة حجز</span>
                  </MenuItem>
                ) : EmployeeiesByID.roles_name === "CompanyEmployee" ? (
                  AssginCompanyBranch === true ||
                  AssginCompanyBranchdefault === true ? (
                    <MenuItem
                      onClick={() => handleAddBranch(clickedEmployeeId)}
                      className="menuStyle"
                    >
                      <img src="img/vuesax-linear-refresh-2.svg" />
                      <span>إسناد مكاتب شركة</span>
                    </MenuItem>
                  ) : (
                    <></>
                  )
                ) : EmployeeiesByID.roles_name === "Admin" ? (
                  AssginCompanyToadmin === true ||
                  AssginCompanyToadmindefault === true ? (
                    <MenuItem
                      onClick={() => handleAddCompany(clickedEmployeeId)}
                      className="menuStyle"
                    >
                      <img src="img/vuesax-linear-refresh-2.svg" />
                      <span>إسناد شركات</span>
                    </MenuItem>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}

                {SoftdeleteEmpoyee === true ||
                SoftdeleteEmpoyeedefault === true ? (
                  <MenuItem
                    className="menuStyle"
                    onClick={() => handleDelete(clickedEmployeeId)}
                  >
                    <img src="img/Path 17089.svg" />
                    <span>حذف</span>
                  </MenuItem>
                ) : (
                  <></>
                )}
              </Menu>

              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                loadingdelete={loadingdelete}
              />
              <Popupupdate
                title="تغيير كلمة المرور"
                openPopup={openPopupPassword}
                setOpenPopup={setOpenPopupPassword}
              >
                <EmployeeFormPassword
                  recordForEdit={recordForEdit}
                  // addOrEdit={addOrEdit}
                  id_employee={clickedEmployeeId}
                />
              </Popupupdate>
              <Popupupdate
                title="تعديل موظف"
                openPopup={openPopupEdit}
                setOpenPopup={setOpenPopupEdit}
                
              >
                <EmployeeFormEdit
                  id_employee={clickedEmployeeId}
                  recordForEdit={recordForEdit}
                  // addOrEdit={addOrEdit}
                />
              </Popupupdate>
              <Popupupdate
                title="إسناد وجهة حجز"
                openPopup={openPopupReservation}
                setOpenPopup={setOpenPopupReservation}
              >
                <CheckboxReservation id_employee={clickedEmployeeId} />
              </Popupupdate>
              <Popupupdate
                title="إسناد مكاتب شركة"
                openPopup={openPopupBranch}
                setOpenPopup={setOpenPopupBranch}
              >
                <CheckboxBranch id_employee={clickedEmployeeId} />
              </Popupupdate>
              <Popupupdate
                title="إسناد شركات"
                openPopup={openPopupCompany}
                setOpenPopup={setOpenPopupCompany}
              >
                <CheckboxCompany id_employee={clickedEmployeeId} />
              </Popupupdate>
            </div>
            {/* ======================= */}
            <CardMedia
              title="Contemplative Reptile"
              style={{ width: "100px", height: "100px", marginTop: "10px" }}
            >
              {emp.image == "" ? (
                <img
                  src="img/notifcation/photo-1633332755192-727a05c4013d.svg"
                  className={classes.media}
                  alt="employee_img"
                />
              ) : (
                <img
                  src={emp.image}
                  className={classes.media}
                  alt="employee_img"
                />
              )}
            </CardMedia>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {emp.name}
            </Typography>
            <div className="body-container">
              <div className="divtitleone">
                <img src="img/apartment_FILL0_wght400_GRAD0_opsz48 (2).svg" />
                <span> اسم الموظف : {emp.first_name}</span>
              </div>
              <div className="divtitletwo">
                <img src="img/vpn_key_FILL0_wght400_GRAD0_opsz48.svg" />
                <span>رقم الموظف :{emp.user_id}</span>
              </div>
              <div className="divtitlethree">
                <img src="img/badge_FILL0_wght400_GRAD0_opsz48 (1).svg" />
                <span>دور الموظف : {emp.roles}</span>
              </div>
            </div>
          </CardContent>
        </Card>
      ))
      ) : (
  <p>No employee data available.</p>
)}
    </>
  );
};
export default OutlinedCard;
