import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchStation = createAsyncThunk(
  'Station/fetchStation',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
       const res =await axios.get(`${BaseUrl}/stations`,{ withCredentials: true });
       const result = await res.data;
       return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);




const StarionSlice = createSlice({
  name: 'Station',
  initialState: { Starions: [], loading: false, error: null },
  reducers: {},
  extraReducers: {
    //fetch
    [fetchStation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchStation.fulfilled]: (state, action) => {
      state.Stations = action.payload;
      state.loading = false;
    },
    [fetchStation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },




  },
});

export default StarionSlice.reducer;
