import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";

import { makeStyles } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { fetchTripTypeById, updateTripType } from "../../redux/tripTypesSlice";
import { fetchPaths, fetchPathsfilter } from "../../redux/PathSlice";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function TypeTripFormEdit(props) {
  const dispatch = useDispatch();

  const { TripTypeID } = useSelector((state) => state.TripType);
  useEffect(() => {},[props.activeStep])
  useEffect(() => {
    if (props.id_tripType) {
      dispatch(fetchPaths(props.id_company));
      dispatch(fetchTripTypeById(props.id_tripType));
    }
  }, [dispatch, props.id_tripType]);

  const { loading, Paths, PathFilter, error } = useSelector(
    (state) => state.Paths
  );

  const initialFValues = {
    id: '',
    id_company: props.id_company,
    id_path:'',
    type: '',
    // price:'',
    // number_hours_before_cancellation: '',
    // return_reduction_upon_cancellation:'',
  };

  // // const [values, setValues] = useState([]);
  // useEffect(() => {
  //   if (TripTypeID.id === props.id_tripType) {
  //     const updatedValues = {
  //       ...initialFValues,
  //       id_path: TripTypeID.id_path,
  //       // price: TripTypeID.price,
  //       number_date_before_cancellation: TripTypeID.number_date_before_cancellation,
  //       return_reduction_upon_cancellation: TripTypeID.return_reduction_upon_cancellation,
  //     };
  //     // setValues({...initialFValues,updatedValues})
  //     // setPricenew(updatedValues.price)
  //   }
  // }, [TripTypeID, props.id_tripType, dispatch, initialFValues]);
  useEffect(() => {
    if (TripTypeID.id ) {
      setValues({
        id: TripTypeID.id,
        id_company: props.id_company,
        id_path: TripTypeID.id_path,
        type: TripTypeID.type,
        // price: TripTypeID.price,
        // number_hours_before_cancellation:
        //   TripTypeID.number_hours_before_cancellation,
        // return_reduction_upon_cancellation:
        //   TripTypeID.return_reduction_upon_cancellation,
      });
    }
  }, [TripTypeID, props.id_tripType]);
  
  const { addOrEdit, recordForEdit } = props;
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    // if ("type" in fieldValues) {
    //   if (!fieldValues.type) {
    //     temp.type = "هذا الحقل مطلوب";
    //   } else if (
    //     fieldValues.type !== "vip" &&
    //     fieldValues.type !== "commercial"
    //   ) {
    //     temp.type = "القيمة يجب أن تكون 'vip' أو 'commercial'";
    //   } else {
    //     temp.type = "";
    //   }
    // }
    // if ("price" in fieldValues)
    //   temp.price = fieldValues.price ? "" : "هذا الحقل مطلوب";
    if ("id_path" in fieldValues)
      temp.id_path = fieldValues.id_path ? "" : "هذا الحقل مطلوب";
    // if ("return_reduction_upon_cancellation" in fieldValues) {
    //   const phoneNumberRegex = /^[0-9]/; // Assuming a 10-digit phone number pattern
    //   if (
    //     !fieldValues.return_reduction_upon_cancellation.match(phoneNumberRegex)
    //   ) {
    //     temp.return_reduction_upon_cancellation = "الإدخال المطلوب أرقام";
    //   } else {
    //     const value = parseFloat(
    //       fieldValues.return_reduction_upon_cancellation
    //     );
    //     if (value < 0.1 || value > 1) {
    //       temp.return_reduction_upon_cancellation =
    //         "يجب أن تكون القيمة بين 0.1 و 1";
    //     } else {
    //       temp.return_reduction_upon_cancellation = "";
    //     }
    //   }
    // } else {
    //   temp.return_reduction_upon_cancellation = ""; // Set the validation message to empty if the field is not present
    // }
    // if ("number_hours_before_cancellation" in fieldValues) {
    //   const phoneNumberRegex = /^[0-9]+$/; // Regex for numbers, '+' ensures one or more digits
    //   // Convert the value to a string to safely use the match method
    //   const valueAsString = String(fieldValues.number_hours_before_cancellation);
    
    //   if (!valueAsString.match(phoneNumberRegex)) {
    //     temp.number_hours_before_cancellation = "الإدخال المطلوب أرقام";
    //   } else if (parseInt(valueAsString) > 100) {
    //     temp.number_hours_before_cancellation = "يجب أن تكون القيمة أصغر أو تساوي 100";
    //   } else {
    //     temp.number_hours_before_cancellation = "";
    //   }
    // } else {
    //   temp.number_hours_before_cancellation = ""; // Set the validation message to empty if the field is not present
    // }
    
    // if ("number_hours_before_cancellation" in fieldValues) {
    //   const phoneNumberRegex = /^[0-9]/; // Assuming a 10-digit phone number pattern
    //   if (
    //     !fieldValues.number_hours_before_cancellation.match(phoneNumberRegex)
    //   ) {
    //     temp.number_hours_before_cancellation = "الإدخال المطلوب أرقام";
    //   } else if (parseInt(fieldValues.number_hours_before_cancellation) > 100) {
    //     temp.number_hours_before_cancellation =
    //       "يجب أن تكون القيمة أصغر أو تساوي 100";
    //   } else {
    //     temp.number_hours_before_cancellation = "";
    //   }
    // } else {
    //   temp.number_hours_before_cancellation = ""; // Set the validation message to empty if the field is not present
    // }
    // if ("number_date_before_cancellation" in fieldValues) {
    //   const phoneNumberRegex = /^[0-9]/; // Assuming a 10-digit phone number pattern
      
    //   if (
    //     typeof fieldValues.number_date_before_cancellation === 'string' && 
    //     fieldValues.number_date_before_cancellation.match(phoneNumberRegex)
    //   ) {
    //     if (parseInt(fieldValues.number_date_before_cancellation) > 10) {
    //       temp.number_date_before_cancellation = "يجب أن تكون القيمة أصغر أو تساوي 10";
    //     } else {
    //       temp.number_date_before_cancellation = "الإدخال المطلوب أرقام";
    //     }
    //   } else {
    //     temp.number_date_before_cancellation = "";
    //   }
    // }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  const [pathss, setPathss] = React.useState(TripTypeID.id_path);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // props.setActiveStep(1)
      dispatch(updateTripType(values));
  
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);
  return (
    <Form onSubmit={handleSubmit}>
      <Grid item xs={12}>
      {loading ?(

      <div className='spinner1-box' style={{height:'100% !important'}}>
              <svg class="spinner1" viewBox="0 0 50 50">
                <circle
                  class="path1"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </div>
      ):(

      <>
        <Controls.Input
          className="tesr"
          InputLabelProps={{ shrink: true }} // Add this line
          name="type"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/2fd33edff69c9a7ef5379653d49b8aa1.svg"></img>
                </IconButton>
                <span className="placeholdertitle">نوع الرحلة </span>
              </InputAdornment>
            </Fragment>
          }
          value={values.type}
          disabled
          error={errors.type}
        />

        {/* <Controls.Input
          className="tesr"
          InputLabelProps={{ shrink: true }} // Add this line
          name="return_reduction_upon_cancellation"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/typeTrip/3.svg"></img>
                </IconButton>
                <span className="placeholdertitle">
                  {" "}
                  نسب القطع عند الغاء الرحلة
                </span>
              </InputAdornment>
            </Fragment>
          }
          value={values.return_reduction_upon_cancellation}
          onChange={handleInputChange}
          error={errors.return_reduction_upon_cancellation}
        /> */}
        {/* <Controls.Input
          className="tesr"
          InputLabelProps={{ shrink: true }} // Add this line
          name="number_hours_before_cancellation"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/typeTrip/3.svg"></img>
                </IconButton>
                <span className="placeholdertitle">
                  المدة التي يتم خلالها القطع عند الالغاء
                </span>
              </InputAdornment>
            </Fragment>
          }
          value={values.number_hours_before_cancellation}
          onChange={handleInputChange}
          error={errors.number_hours_before_cancellation}
        /> */}
        <FormControl fullWidth className="tesr">
          <InputLabel id="demo-simple-select-label">
            <div
              style={{
                background: "white",
                width: "100px",
                display: "flex",
                justifyContent: "center",
                marginRight: "22px",
                height: "20px",
                alignItems:'center',
                position:'relative',
                top:'-3px'
              }}
            >  <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                   <img src="img/typeTrip/1.svg" alt="img-trip-type"></img>
                </IconButton>
             
              <span className="placeholdertitle" style={{ display: "block" }}>
                مسار الرحلة
              </span>
            </div>
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={values.id_path || ""}
            name="id_path"
            onChange={handleInputChange}
          >
            {Array.isArray(Paths) &&
              Paths.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.FromTo}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
  
        </>
      )}
      <div
                  className="button-passenger"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Controls.Button
                    // onClick={handleNext}
                    // onClick={handleSubmit}
                    type="submit"
                    text="تعديل"
                  />
                  </div>
      </Grid>
    </Form>
  );
}
