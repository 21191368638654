import React, { useState } from "react";
import Card from "./CardRating";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../component/Header";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import SearchBar from "material-ui-search-bar";
import { Checkbox, Select, MenuItem } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { Box, Typography, useTheme, Button } from "@mui/material";
const useStyles = makeStyles({
  gridDivide: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "23px",
  },
  "@media (min-width: 768px) and (max-width:1366px)": {
    gridDivide: {
      justifyContent: "center",
      alignItems: "center",
    },
    searchDesign: {
      width: "100%",
    },
  },
  "@media (min-width: 1366px) and (max-width:1556px)": {
    gridDivide: {
      justifyContent: "space-around",
      alignItems: "space-around",
    },
  },
  mt: {
    marginTop: "30px",
  },

  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    height: "45px !important",
    marginBottom: "20px !important",
    paddingRight: "8px",
    flexDirection: "row-reverse",
    oxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
  },
  searchDesign1: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    height: "45px !important",
    marginBottom: "20px !important",
    paddingRight: "8px",
    flexDirection: "row-reverse",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
  },

  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  contentDivide: {
    width: "100%",
    marginBottom: "40px",
  },
  Marginbox1: {
    marginBottom: "20px !important",
  },
  AddDesign: {
    marginBottom: "20px",
  },
});

const CardListRating = ({ mylist }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  ];
  const [filteredData, setFilteredData] = useState(rows);
  const [currentPage, setCurrentPage] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const [age, setAge] = useState("all");
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [searched, setSearched] = useState("");
  const requestSearch = (searchedVal) => {
    const filteredRows = filteredData.filter((row) => {
      return row.lastName.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setFilteredData(filteredRows);
  };
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  const onHandleReset = () => {
    setFilteredData(rows);
  };
  return (
    <Grid className={classes.gridContainer} item lg={12}>
      <Grid
        container
        spacing={4}
        className={classes.gridContainer}
        justify="center"
      >
        <Grid container xs={12} className={classes.Marginbox1}>
          <Grid lg={3} md={6} sm={12}>
            <SearchBar
              className={classes.searchDesign1}
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
          </Grid>
          <Grid lg={3} md={6} sm={12} className={classes.AddDesign}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="selects1"
              text="بحث"
            >
              <MenuItem value={"ID"}>
                <em>ID</em>
              </MenuItem>
              <MenuItem value={"FirstName"}>
                <em>FirstName</em>
              </MenuItem>
              <MenuItem value={"LastName"}>
                <em>LastName</em>
              </MenuItem>
              <MenuItem value={"Age"}>
                <em>Age</em>
              </MenuItem>
              <MenuItem value={"Full Name"}>
                <em>Full Name</em>
              </MenuItem>
            </Select>
          </Grid>
          <Grid lg={3} md={6} sm={12} className={classes.AddDesign}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="selects2"
              onChange={(e) => setAge(e.target.value)}
            >
              <MenuItem value={"all"}>
                <em>all</em>
              </MenuItem>
              <MenuItem value={35}>35</MenuItem>
              <MenuItem value={42}>42</MenuItem>
              <MenuItem value={16}>16</MenuItem>
            </Select>
          </Grid>
          <Grid lg={1} md={1} sm={3} className={classes.AddDesign}>
            <Button className="reloadButton1" onClick={onHandleReset}>
              <img src="img/reload.svg" />
            </Button>
          </Grid>
  
        </Grid>
        <Grid className={classes.gridDivide}>
          <Card />
        </Grid>
        <Pagination
          count={10}
          page={currentPage}
          onChange={handleChange}
          className={classes.mt}
        />
      </Grid>
    </Grid>
  );
};
export default CardListRating;
