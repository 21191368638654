
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TypeTripFormEdit from "./TypeTripFormEdit";
import TypeTripPriceFormUpdate from "./TypeTripPriceFormUpdate";
import { useEffect } from "react";

const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
];

export default function StepTripTypeupdate({ id_company }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [Data, SetData] = React.useState();
  const totalSteps = () => {
    return steps.length;
  };
useEffect(()=>{},[Data])
  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  useEffect(() => {},[activeStep])
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: "100%" }}>

      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === 0 ? (
              <TypeTripFormEdit
                id_company={id_company}
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                SetData={SetData}
              />
            ) : activeStep === 1 ? (
              <TypeTripPriceFormUpdate id_company={id_company} setActiveStep={setActiveStep} activeStep={activeStep} />
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row-reverse",
                gap: "10px",
              }}
            >
            </div>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}
