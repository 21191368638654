import React, { useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, makeStyles, Typography } from '@material-ui/core';
import Controls from "./controls/Controls";
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        position: 'absolute',
        width:'662px !important',
        maxWidth:'662px !important',
        opacity:'1',
        display:'flex',
        flexDirection:'column',
        transition: 'opacity 2ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    dialogTitle: {
        padding: '0px '
    },
    headerDesgin:{
        textAlign:'center',
        color:'#F9B917',
        fontSize:'18px',
    },
    "@media (min-width: 768px) and (max-width:1556px)": {
        dialogWrapper: {
            height:'auto !important',
            top: '3% !important',
        },
      },

}))

export default function PopupStop(props) {

    const { title, children, openPopup, setOpenPopup ,setDisplayshortcut} = props;
    const classes = useStyles();
    const handleKeyDown = (event) => {
        if (event.key === 'Delete') {
          localStorage.setItem("displayshortcut", "0");
          localStorage.setItem('openpassenger',false);
          localStorage.setItem('changeseat',false);
          setOpenPopup(false);
        }
      };
      
      useEffect(() => {
        if (openPopup) {
          document.addEventListener('keydown', handleKeyDown);
        } else {
          document.removeEventListener('keydown', handleKeyDown);
        }
      
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, [openPopup]);
          
    const close = (()=>{
        setOpenPopup(false);
        localStorage.setItem("displayshortcut", "0");
        localStorage.setItem('openpassenger',false);
        localStorage.setItem('changeseat',false);
    })
    
    return (
        <Dialog open={openPopup}  classes={{ paper: classes.dialogWrapper }} >
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' ,margin:'33px 0px 18px 0px', alignItems:'center' }}>
                    <Typography variant="h6" className={classes.headerDesgin} component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <Controls.ActionButton
                    
                        color="secondary"
                        onClick={()=>close()}>
                        <CloseIcon  />
                    </Controls.ActionButton>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}
