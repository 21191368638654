import { useLocation, useNavigate } from "react-router-dom";
import { tokens } from "../../styles/theme";
import React, { useEffect, useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { Grid } from "@material-ui/core";
import PopupUpdate from "../../component/Popupupdate";
import ProfitEdit from "./ProfitEdit";
import { DataGridPro } from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import {
  GridFooterContainer,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridPagination,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteTrip,
  fetchTripBYId,
  fetchTripBYIdReservation,
  fetchTrips,
} from "../../redux/tripsSlice";

import dayjs from "dayjs";
import { useRef } from "react";
import {  fetchprofitByid } from "../../redux/profit";

import axios from "axios";
import packageJson from "../../../package.json";
import Header from "../../component/Header";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function CustomFooter() {
  return (
    <GridFooterContainer>
      <CustomPagination />
      <GridPagination />
    </GridFooterContainer>
  );
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    flexDirection: "row-reverse",
    paddingRight: "8px",
    height: "45px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
    marginRight: "3px",
  },
  "@media (min-width: 600px) and (max-width:1280px)": {
    searchDesign: {
      marginBottom: "15px",
    },
  },
  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  editButton: {
    display: "none",
    flexDirection: "row-reverse",
    color: "#000002 !important",
    fontSize: "20px",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  deleteButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#F9B917 !important",
    fontSize: "20px ",
    fontFamily: "beIN",
  },
  editImg: {
    width: "15px",
  },
  Marginbox1: {
    maxWidth: "100% !important",
    flexBasis: "100%",
  },
});

export default function ProfitTable() {
  const [currentTime1, setCurrentTime1] = useState(new Date());
  const [profitss, setProfitss] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const {loading, Profit } = useSelector((state) => state.Profits);
  //Add Permission

  useEffect(() => {
    // Fetch the data using axios or any other method
    const fetchData = async () => {
      try {
        const res = await axios.get(`${BaseUrl}/getProfitCompanies`, {
          withCredentials: true,
        });
        const result = res.data.data;
        setProfitss(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the function to fetch data
  }, []);

  // Rest of your code ...




  const location = useLocation();
  const id_comp = location.state; // Print the state value

 


  const [filteredData, setFilteredData] = useState(profitss);
  const currentDateTime = new Date();
  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };
  const currentTime = currentDateTime.toLocaleTimeString("en-US", options);

  const formattedDate = dayjs(currentDateTime).format("YYYY-MM-DD");
  const classes = useStyles();
  const [age, setAge] = useState("all");
  const [searched, setSearched] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForEdit11, setRecordForEdit11] = useState(null);
  const [recordForEdit1, setRecordForEdit1] = useState(null);
  const [openPopupStop, setOpenPopupStop] = useState(false);
  const [clickedEmployeeId, setClickedEmployeeId] = useState(null);
  const intervalRef = useRef(null);

 
  function handleOpen() {
    setOpenPopup(true);
    setRecordForEdit(null);
  }
  function handleE(id) {
    setOpenPopup2(true);
    setRecordForEdit1(null);
    setClickedEmployeeId(id);
    dispatch(fetchprofitByid(id));
  }

  const onHandleReset = () => {
    setFilteredData(Profit);
  };

  function EditToolbar(props) {
    return (
      <div className="trip">
  
    
        <Grid container xs={12} className={classes.Marginbox1}>
      
        </Grid>
      </div>
    );
  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
  };

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [openPopup1, setOpenPopup1] = useState(false);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };


  function handleClickEdit(id) {
    setClickedEmployeeId(id);
    dispatch(fetchTripBYId(id));
  }

  function handleDelete(id) {
    setConfirmDialog({
      isOpen: true,
      title: "أنت على وشك حذف هذه الرحلة",
      img: "img/emp/Inbox cleanup-rafiki.svg",
      onConfirm: () => {
        dispatch(deleteTrip(id));
      },
    });
  }
  
  const rowsWithRowNumber = profitss.map((row, index) => ({
    ...row,
    id: row.id,
    rowNumber: row.id ,
  }));
  const getPageSize = () => {
    if (window.innerWidth < 992) {
      return 5; // Set a smaller pageSize for smaller screens
    } else if (window.innerWidth < 1566) {
      return 7; // Default pageSize for larger screens
    } else if (window.innerWidth < 1760) {
      return 8; // Default pageSize for larger screens
    } else if (window.innerWidth < 1930) {
      return 13; // Default pageSize for larger screens
    }
  };
  useEffect(() => {
    getPageSize();
  });
  const columns = [

    {
      field: "company_name",
      sortable: false,
      headerName: "اسم الشركة",
      resizable: true,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "profit_percent",
      sortable: false,
      resizable: true,
      headerName: "نسبة الربح",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "platform_to_company",
      sortable: false,
      resizable: true,
      headerName: "نسبة الريح من المنصة للشركة",
      minWidth: 80,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "company_to_platform",
      sortable: false,
      resizable: true,
      headerName: "نسية الربح من الشركة للمنصة",
      minWidth: 80,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerAlign: "center",
      headerName: "العمليات",
      resizable: true,
      minWidth: 200,
      flex: 1,
      sortable: false,
   
      align: "center",
      renderCell: (params) => {
        const id = params.row.id;

     
        return (
          <Box>
    
              <Button
                className={classes.editButton}
                onClick={() => {
                  handleE(id);
                }}
             
              >
                <img
                  src="img/edit.svg"
                  className={classes.editImg}
                  alt="delete icon"
                ></img>
              </Button>
           

        
            <PopupUpdate
              title=" تعديل النسبة"
              openPopup={openPopup2}
              setOpenPopup={setOpenPopup2}
              
            >
              <ProfitEdit
                id_company={clickedEmployeeId}
                recordForEdit={recordForEdit11}
              />
            </PopupUpdate>
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },

          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "none",
            fontSize: "15px !important",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "15px !important",
            color: `${colors.primary[1000]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.white[100],
          },
        }}
      >
  
          <DataGridPro
            rows={rowsWithRowNumber || []}
            columns={columns}
            sx={{ overflowX: "scroll" }}
            className="field-table1"
            editMode="row"
            pageSize={getPageSize()}
            pagination
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            components={{
              Toolbar: EditToolbar,
              Footer: CustomFooter,
            }}
            localeText={{
              footerRowSelected: CustomPagination,
            }}
          />
    
      </Box>
    </Box>
  );
}
