import React, {  Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useFormStop, FormStop } from "./useFormStop";
import { makeStyles } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    direction: "rtl",
  },

}));


const initialFValues = {
  tripName: "",
  tripPrice: "",
  tripDiscount: "",
  tripDate: "",
};

export default function StopTrip({TripID}) {
  
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("tripName" in fieldValues)
      temp.tripName = fieldValues.tripName ? "" : "This field is required.";
    if ("tripPrice" in fieldValues)
      temp.tripPrice = fieldValues.tripPrice ? "" : "This field is required.";
    if ("tripDiscount" in fieldValues)
      temp.tripDiscount = fieldValues.tripDiscount
        ? ""
        : "This field is required.";
    if ("tripDate" in fieldValues)
      temp.tripDate = fieldValues.tripDate ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useFormStop(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
     
    }
  };


  return (
    
    
  

    
    <Grid className="stopPlaces">
   
     
      <Grid item xs={2} md={2}>
        <img src="img/stop.svg"></img>
      </Grid>
    
      <FormStop onSubmit={handleSubmit}>
        <Grid item xs={10} md={10}>
          <Controls.Input
            disabled={true}
            className="gggggggg"
            name="fullName"
            label={
              <Fragment>
                <InputAdornment position="end">
                  <IconButton
                    className="iconplaceholder"
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <img src="img/stop1.svg"></img>
                  </IconButton>
                  <span className="placeholdertitle">الانطلاق: {TripID.path_from}</span>
                </InputAdornment>
              </Fragment>
            }
            value={TripID.tripPrice}
            onChange={handleInputChange}
            error={errors.tripPrice}
          />
          {/* {PathsID.stations} */}
          {TripID.stations &&
            TripID.stations.map((station) => (
                  <Controls.Input
                  disabled={true}
                   key={station.id}
                    className="tesr"
                    // name="tripPrice"
                    label={
                      <Fragment>
                        <InputAdornment position="end">
                          <IconButton
                            className="iconplaceholder"
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <img src="img/stop2.svg"></img>
                          </IconButton>
                          <span className="placeholdertitle">
                            نقطة التوقف رقم 1 :{station.name}
                          
                          </span>
                        </InputAdornment>
                      </Fragment>
                    }
                    value={values.tripPrice}
                    onChange={handleInputChange}
                    error={errors.tripPrice}
                  />
          ))}
     
          <Controls.Input
            className="tesr"
            name="tripPrice"
            disabled={true}
            label={
              <Fragment>
                <InputAdornment position="end">
                  <IconButton
                    className="iconplaceholder"
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <img src="img/stop1.svg"></img>
                  </IconButton>
                  <span className="placeholdertitle">الوجهة:  {TripID.path_to}</span>
                </InputAdornment>
              </Fragment>
            }
            value={values.tripPrice}
            onChange={handleInputChange}
            error={errors.tripPrice}
          />
        </Grid>
      
      </FormStop>
     
    </Grid>
   
  );
}
