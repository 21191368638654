import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import Controls from "./controls/Controls";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  dialog: {
    border: " 1px solid #F9B917 !important",
    boxShadow: "0 3px 6px rgba(0 , 0 , 0 ,  0.3)",
    "& .MuiDialog-container": {
      direction: "rtl !important",
    },
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "right !important",
    fontFamily: "beIN !important",
    fontWeight: "normal !important",
    fontSize: "30px !important",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export default function ConfirmDialog(props) {
  const { confirmDialog, setConfirmDialog } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <img src={confirmDialog.img} alt="delete" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="subtitle">{confirmDialog.title}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <div className="button-dailog">
          <Controls.Button
            className=""
            type="submit"
            text="تم"
            onClick={() => {
              setConfirmDialog({ ...confirmDialog, isOpen: false });
              navigate(`/StepPassenger`, {
                state: {
                  child: props.children,
                  adults: props.adultss,
                  id_trip:props.id_trip,
                  datetime:props.datetime,
                  id_reservation: props.id_reservation,
                },
              });
            }}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}
