import React, { useState } from "react";
import Sidebar from "./SideBar";
import { Outlet } from "react-router-dom";
import { Grid } from "@material-ui/core";
const AppLayout = () => {
  return (
    <div className="app">
      <Sidebar />
      <main className="content" > 
        <Outlet  />
      </main>
    </div>

  );
};

export default AppLayout;
