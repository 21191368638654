import { Box, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import "./StepPassenger1.css";
import PassengerAdultDeleted from "./PassengerAdultDeleted";
import Controls from "../../component/controls/Controls";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  insertPassengerToReservation,
  updateTPassengerToReservation,
} from "../../redux/PasssengersTrip";
import { useSelector } from "react-redux";
import packageJson from "../../../package.json";
import axios from "axios";
import * as sweetalert from "sweetalert";
import { fetchTripBYId } from "../../redux/tripsSlice";
import PassengerAdultSwap from "./PassengerAdultSwap";
export const BaseUrl = packageJson.projectURlApi.BaseUrl;

export const key_Shortcut = packageJson.key_Shortcut;
function StepPassengerSwap({
  updatePassenger,
  id_trip_type,
  setUpdatePassenger,
  onConfiarm,
  numberpassenger,
  id_company,
  id_trip,
  reservationIds,
  direct,
  transet,
  setstatus,
  updateSeat,
  setUpdateSeat,
  reservationss,
  setReservationseat1,
  setResponse1,
  response1,
  idDirect,
  setArraySeat,
  arrayseat,
  idReservationresponse,
  setSelectedSeats1,
  setSelectedSeats,
  setSelectedSeats2,
  setSeatAndUser,
  setArraySeatPassengerTransit1,
  arrayseatPassengerTransit1,
  setArraySeatPassengerTransit2,
  arrayseatPassengerTransit2,
  selectedTransitIndex,
  setArrayReservations,
  arrayReservations,
  setIdTicketPrice,
  idTricketPrice,
  idTransit,
  resultArray,
  valueinput,
  settranset,
  setDirect,
  reservationseat1,
  setResultArray,
  setInputString,
  setValueinput,
  selectedSeats,
  selectedSeats2,
  selectedSeats1,
  setOpenPopup
}) {
  const dispatch = useDispatch();
  let insertreservation1 = idReservationresponse;
  const child = 0;
  const adults = numberpassenger;
  const { TripID } = useSelector((state) => state.Trip);

  const steps = [];
  for (let i = 1; i <= numberpassenger.length; i++) {
    steps.push({
      id: `${i}`,
      label: `الحجز للمسافر البالغ رقم ${i}`,
      img1: "img/Circul.png",
      img2: "img/replacestep/Group 10438.svg",
      route: `/step-create-course/${i}`,
      isChild: false,
    });
  }

  // Add steps for children
  for (let i = 1; i <= child; i++) {
    steps.push({
      id: `${i}`,
      label: `الحجز للطفل رقم ${i}`,
      img1: "img/Circul.png",
      route: `/step-create-course/child/${i}`,
      isChild: true,
    });
  }

  const initialFValues = {
    // id_reservation:
    // insertreservation1.id || insertreservation1[0]?.reservationId,
    adults: [],
    children: [],
  };
  
  for (let i = 0; i < adults.length; i++) {
    const seatNumber = numberpassenger[i];
  
    if (direct == true) {
      initialFValues.adults.push({
        id: i + 1,
        code_seat: "",
        number_seat: seatNumber,
        typeReduction: "",
        id_price_ticket: "",
      });
    }
    if (transet == true) {
      initialFValues.adults.push({
        id: i + 1,
        code_seat: "",
        number_seat: seatNumber,
        typeReduction: "",
        id_price_ticket: "",
      });
    }
  }

  const [activeStep1, setactiveStep1] = React.useState(
    JSON.parse(localStorage.getItem("activeStep1")) || 1
  );
  const handleKeyDown = (event) => {
    if (
      event.key === key_Shortcut.next_passenger &&
      activeStep1 !== steps.length
    ) {
      setactiveStep1((prevactiveStep1) => prevactiveStep1 + 1);
    } else if (
      event.key === key_Shortcut.previous_passenger &&
      activeStep1 !== 1
    ) {
      setactiveStep1((prevactiveStep1) => prevactiveStep1 - 1);
    }
  };
  const passengers = JSON.parse(localStorage.getItem("passengers"));
  useEffect(() => {
  
    if (passengers && Array.isArray(passengers)) {
      // This array will hold objects each containing id_price_ticket, number_seat, and price
      const passengerDetails = passengers.map((passenger) => ({
        id_price_ticket: passenger.id_price_ticket,
        number_seat: passenger.number_seat,
        id_trip: passenger.id_trip,
      }));

      // Log the details to see the output

      // Set these details into state or perform other actions
      setIdTicketPrice(passengerDetails); // Assuming setIdTicketPrice can now accept an array of objects
    } else {
    }
  }, [passengers]);

  useEffect(() => {}, [response1]);
  useEffect(() => {
    setIdTicketPrice(idTricketPrice);
  }, [idTricketPrice]);
  useEffect(() => {
    setArraySeat(arrayseat);
  }, [arrayseat]);
  useEffect(() => {
    setArrayReservations(arrayReservations);
  }, [arrayReservations]);
  useEffect(() => {
    setArraySeatPassengerTransit1(arrayseatPassengerTransit1);
  }, [arrayseatPassengerTransit1]);
  useEffect(() => {
    setArraySeatPassengerTransit2(arrayseatPassengerTransit2);
  }, [arrayseatPassengerTransit2]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep1, steps.length]);
  useEffect(() => {
    document.addEventListener("didClose", () => {
      const confirmButton = document.querySelector(".swal-button--confirm");
      if (confirmButton) {
        confirmButton.focus();
      }
    });
  });

  const handleKeyDown2 = async (event) => {
    if (event.ctrlKey && event.key === "8") {
      // setResponse1(response1);
      //   if (updatePassenger === true) {
      //     const datapassenger = JSON.parse(localStorage.getItem("passengers"));
      //     if (direct === true) {
      //       const data = {
      //         branch: TripID.id_branch,
      //         tripid: TripID.id,
      //         id_reservation: reservationIds[0]?.reservationId1,
      //         first_name: datapassenger[0].first_name,
      //         id: datapassenger[0].id,
      //         middle_name: datapassenger[0].middle_name,
      //         last_name: datapassenger[0].last_name,
      //         mother_name: datapassenger[0].mother_name,
      //         national_id: datapassenger[0].national_id,
      //         gender: datapassenger[0].gender,
      //         birth_date: datapassenger[0].birth_date,
      //         birth_place: datapassenger[0].birth_place,
      //         civil_status: datapassenger[0].civil_status,
      //         number_seat: datapassenger[0].number_seat,
      //         id_price_ticket: datapassenger[0].id_price_ticket,
      //         price: datapassenger[0].price,
      //         title: datapassenger[0].title,
      //       };
      //       // dispatch(updateTPassengerToReservation(data));
      //       const allValid = passengers.every(
      //         (passenger) =>
      //           passenger.first_name !== "" && passenger.id_price_ticket !== ""
      //       );
      //       if (!allValid) {
      //         sweetalert({
      //           text: " يرجى إدخال بيانات جميع المسافرين ",
      //           icon: "warning",
      //           buttons: {
      //             confirm: {
      //               text: "تم",
      //               className: "custom-button-class",
      //             },
      //           },
      //           dangerMode: true,
      //         });
      //         return; // Stop the function if not all passengers are valid
      //       }
      //       const fecth= async() =>{
      //       const response = await axios.post(
      //         `${BaseUrl}/updatePassenger/${data.id}`,
      //         data,
      //         {
      //           withCredentials: true,
      //         }
      //       );

      //       if (response.status === 200) {
      //         setSelectedSeats1([]);
      //         setSelectedSeats([]);
      //         setSeatAndUser([]);
      //         setSelectedSeats2([]);
      //         await informSuccess();
      //         cleanupLocalStorage();
      //         setArraySeat((prevSeatsInfo) => {
      //           const updatedSeatsInfo = { ...prevSeatsInfo };
      //           const seatNumber = parseInt(datapassenger[0].number_seat);
      //           if (!isNaN(seatNumber)) {
      //             updatedSeatsInfo[seatNumber] = {
      //               passengers: response.data.data, // Assuming you want the whole passenger object here
      //               id_reservation: reservationIds[0]?.reservationId1, // Ensure reservationIds[0] is defined
      //               status: 2, // Setting status to 2, consider making this dynamic if needed
      //             };
      //           }

      //           return updatedSeatsInfo;
      //         });
      //         const reservationId = reservationIds[0]?.reservationId1;
      //         const updatedPassenger = response.data.data; // The passenger data from the response
      //         const passengerId = updatedPassenger.id; // The ID of the passenger that was updated

      //         // setArrayReservations((prevReservations) => {
      //         //   // Deep clone the previous reservations to avoid direct state mutation
      //         //   const updatedReservations = JSON.parse(
      //         //     JSON.stringify(prevReservations)
      //         //   );

      //         //   // Check if the specific reservation exists
      //         //   if (updatedReservations[reservationId]) {
      //         //     // Find the index of the passenger we want to update
      //         //     const passengerIndex = updatedReservations[
      //         //       reservationId
      //         //     ].passengers.findIndex((p) => p.id === passengerId);

      //         //     // If the passenger exists, update their data
      //         //     if (passengerIndex !== -1) {
      //         //       updatedReservations[reservationId].passengers[
      //         //         passengerIndex
      //         //       ] = updatedPassenger;
      //         //     }
      //         //   }

      //         //   return updatedReservations;
      //         // });
      //         const mappedById = response.data.reservations.reduce(
      //           (acc, item) => {
      //             acc[item.id] = item;
      //             return acc;
      //           },
      //           {}
      //         );
      //         setArrayReservations(mappedById);
      //      }
      //     }
      //     fecth()
      //      const informSuccess = async () => {
      //       await sweetalert({
      //         text:'تم التعديل بنجاح',
      //         icon: "success",
      //         buttons: {
      //           confirm: {
      //             text: "تم",
      //             className: "custom-button-class",
      //           },
      //         },
      //         className: "alert-div",
      //         focusConfirm: false,
      //         customClass: {
      //           confirmButton: "custom-button-class",
      //         },
      //       });
      //     }

      //     const cleanupLocalStorage = () => {
      //       localStorage.setItem("openpassenger", "false");
      //       localStorage.removeItem("passengers");
      //       localStorage.removeItem("childrens");
      //       localStorage.setItem("popup", false);

      //         // sweetalert({
      //         //   text: response.data.message,
      //         //   icon: "success",
      //         //   buttons: {
      //         //     confirm: {
      //         //       text: "تم",
      //         //       className: "custom-button-class",
      //         //       // closeModal: false, // This prevents the modal from closing immediately
      //         //     },
      //         //   },
      //         //   className: "alert-div",
      //         // }).then(() => {
      //         //   localStorage.setItem("openpassenger", "false");
      //         //   localStorage.removeItem("passengers");
      //         //   localStorage.removeItem("childrens");
      //         //   localStorage.setItem("popup", false);
      //         // });
      //       }
      //     } else if (transet === true) {
      //       const data = {
      //         branch: TripID.id_branch,
      //         tripid: TripID.id,
      //         id_reservation: insertreservation1[0]?.reservationId,
      //         first_name: datapassenger[0].first_name,
      //         id: datapassenger[0].id,
      //         middle_name: datapassenger[0].middle_name,
      //         last_name: datapassenger[0].last_name,
      //         mother_name: datapassenger[0].mother_name,
      //         national_id: datapassenger[0].national_id,
      //         gender: datapassenger[0].gender,
      //         birth_date: datapassenger[0].birth_date,
      //         birth_place: datapassenger[0].birth_place,
      //         civil_status: datapassenger[0].civil_status,
      //         seat_number: datapassenger[0].number_seat,
      //         id_price_ticket: datapassenger[0].id_price_ticket,
      //       };

      //       // dispatch(updateTPassengerToReservation(data));
      //       // onConfiarm();
      //       const allValid = passengers.every(
      //         (passenger) =>
      //           passenger.first_name !== "" && passenger.id_price_ticket !== ""
      //       );
      //       if (!allValid) {
      //         sweetalert({
      //           text: " يرجى إدخال بيانات جميع المسافرين ",
      //           icon: "warning",
      //           buttons: {
      //             confirm: {
      //               text: "تم",
      //               className: "custom-button-class",
      //             },
      //           },
      //           dangerMode: true,
      //         });
      //         return; // Stop the function if not all passengers are valid
      //       }
      //       const fecth= async() =>{
      //       const response = await axios.post(
      //         `${BaseUrl}/updatePassenger/${data.id}`,
      //         data,
      //         {
      //           withCredentials: true,
      //         }
      //       );

      //       if (response.status === 200) {
      //         setSelectedSeats1([]);
      //         setSelectedSeats([]);
      //         setSelectedSeats2([]);
      //         setSeatAndUser([]);
      //         await informSuccess();
      //         cleanupLocalStorage();
      //         if (selectedTransitIndex == 0) {
      //           setArraySeatPassengerTransit1((prevSeatsInfo) => {
      //             const updatedSeatsInfo = { ...prevSeatsInfo };
      //             const seatNumber = parseInt(datapassenger[0].number_seat);
      //             if (!isNaN(seatNumber)) {
      //               updatedSeatsInfo[seatNumber] = {
      //                 passengers: response.data.data, // Assuming you want the whole passenger object here
      //                 id_reservation:
      //                   reservationIds[0]?.reservationId1[0].reservationId, // Ensure reservationIds[0] is defined
      //                 status: 2, // Setting status to 2, consider making this dynamic if needed
      //               };
      //             }

      //             return updatedSeatsInfo;
      //           });
      //           const mappedById = response.data.reservations.reduce(
      //             (acc, item) => {
      //               acc[item.id] = item;
      //               return acc;
      //             },
      //             {}
      //           );
      //           setArrayReservations(mappedById);
      //           const reservationId =
      //             reservationIds[0]?.reservationId1[0].reservationId;
      //           const updatedPassenger = response.data.data; // The passenger data from the response
      //           const passengerId = updatedPassenger.id; // The ID of the passenger that was updated

      //           // setArrayReservations((prevReservations) => {
      //           //   // Deep clone the previous reservations to avoid direct state mutation
      //           //   const updatedReservations = JSON.parse(
      //           //     JSON.stringify(prevReservations)
      //           //   );

      //           //   // Check if the specific reservation exists
      //           //   if (updatedReservations[reservationId]) {
      //           //     // Find the index of the passenger we want to update
      //           //     const passengerIndex = updatedReservations[
      //           //       reservationId
      //           //     ].passengers.findIndex((p) => p.id === passengerId);

      //           //     // If the passenger exists, update their data
      //           //     if (passengerIndex !== -1) {
      //           //       updatedReservations[reservationId].passengers[
      //           //         passengerIndex
      //           //       ] = updatedPassenger;
      //           //     }
      //           //   }

      //           //   return updatedReservations;
      //           // });

      //           // sweetalert({
      //           //   text: response.data.message,
      //           //   icon: "success",
      //           //   buttons: {
      //           //     confirm: {
      //           //       text: "تم",
      //           //       className: "custom-button-class",
      //           //     },
      //           //   },
      //           //   className: "alert-div",
      //           // }).then(() => {
      //           //   localStorage.setItem("openpassenger", "false");
      //           //   localStorage.removeItem("passengers");
      //           //   localStorage.removeItem("childrens");
      //           //   localStorage.setItem("popup", false);
      //           // });
      //         }
      //         if (selectedTransitIndex == 1) {
      //           setArraySeatPassengerTransit2((prevSeatsInfo) => {
      //             const updatedSeatsInfo = { ...prevSeatsInfo };
      //             const seatNumber = parseInt(datapassenger[0].number_seat);
      //             if (!isNaN(seatNumber)) {
      //               updatedSeatsInfo[seatNumber] = {
      //                 passengers: response.data.data, // Assuming you want the whole passenger object here
      //                 id_reservation:
      //                   reservationIds[0]?.reservationId1[0].reservationId, // Ensure reservationIds[0] is defined
      //                 status: 2, // Setting status to 2, consider making this dynamic if needed
      //               };
      //             }

      //             return updatedSeatsInfo;
      //           });
      //           const mappedById = response.data.reservations.reduce(
      //             (acc, item) => {
      //               acc[item.id] = item;
      //               return acc;
      //             },
      //             {}
      //           );
      //           setArrayReservations(mappedById);
      //           const reservationId =
      //             reservationIds[0]?.reservationId1[0].reservationId;
      //           const updatedPassenger = response.data.data; // The passenger data from the response
      //           const passengerId = updatedPassenger.id; // The ID of the passenger that was updated

      //           // setArrayReservations((prevReservations) => {
      //           //   // Deep clone the previous reservations to avoid direct state mutation
      //           //   const updatedReservations = JSON.parse(
      //           //     JSON.stringify(prevReservations)
      //           //   );

      //           //   // Check if the specific reservation exists
      //           //   if (updatedReservations[reservationId]) {
      //           //     // Find the index of the passenger we want to update
      //           //     const passengerIndex = updatedReservations[
      //           //       reservationId
      //           //     ].passengers.findIndex((p) => p.id === passengerId);

      //           //     // If the passenger exists, update their data
      //           //     if (passengerIndex !== -1) {
      //           //       updatedReservations[reservationId].passengers[
      //           //         passengerIndex
      //           //       ] = updatedPassenger;
      //           //     }
      //           //   }

      //           //   return updatedReservations;
      //           // });
      //           // sweetalert({
      //           //   text: response.data.message,
      //           //   icon: "success",
      //           //   buttons: {
      //           //     confirm: {
      //           //       text: "تم",
      //           //       className: "custom-button-class",
      //           //     },
      //           //   },
      //           //   className: "alert-div",
      //           // }).then(() => {
      //           //   localStorage.setItem("openpassenger", "false");
      //           //   localStorage.removeItem("passengers");
      //           //   localStorage.removeItem("childrens");
      //           //   localStorage.setItem("popup", false);
      //           // });
      //         }
      //       }
      //       }
      //       fecth()
      //       const informSuccess = async () => {
      //        await sweetalert({
      //          text:'تم التعديل بنجاح',
      //          icon: "success",
      //          buttons: {
      //            confirm: {
      //              text: "تم",
      //              className: "custom-button-class",
      //            },
      //          },
      //          className: "alert-div",
      //          focusConfirm: false,
      //          customClass: {
      //            confirmButton: "custom-button-class",
      //          },
      //        });
      //      }

      //      const cleanupLocalStorage = () => {
      //        localStorage.setItem("openpassenger", "false");
      //        localStorage.removeItem("passengers");
      //        localStorage.removeItem("childrens");
      //        localStorage.setItem("popup", false);

      //          // sweetalert({
      //          //   text: response.data.message,
      //          //   icon: "success",
      //          //   buttons: {
      //          //     confirm: {
      //          //       text: "تم",
      //          //       className: "custom-button-class",
      //          //       // closeModal: false, // This prevents the modal from closing immediately
      //          //     },
      //          //   },
      //          //   className: "alert-div",
      //          // }).then(() => {
      //          //   localStorage.setItem("openpassenger", "false");
      //          //   localStorage.removeItem("passengers");
      //          //   localStorage.removeItem("childrens");
      //          //   localStorage.setItem("popup", false);
      //          // });
      //        }
      //     }
      //   } else if (updateSeat === true) {
      //     // if (transet == true) {
      //     //   const data = {
      //     //     id_reservation: reservationIds[0].reservationId1[0].reservationId,
      //     //     branch: TripID.id_branch,
      //     //     tripid: TripID.id,
      //     //     adults: JSON.parse(localStorage.getItem("passengers")),
      //     //     children: [],
      //     //   };
      //     //   dispatch(insertPassengerToReservation(data));
      //     // }
      //     if (transet == true) {
      //       const data = {
      //         id_reservation: reservationIds[0].reservationId1[0].reservationId,
      //         branch: TripID.id_branch,
      //         tripid: TripID.id,
      //         adults: JSON.parse(localStorage.getItem("passengers")),
      //         children: [],
      //       };
      //       const allValid = passengers.every(
      //         (passenger) =>
      //           passenger.first_name !== "" && passenger.id_price_ticket !== ""
      //       );
      //       const totalPrice = passengers.reduce((total, passenger) => {
      //         return total + parseFloat(passenger.price); // Ensure the ticket price is treated as a float
      //       }, 0);
      //       if (!allValid) {
      //         sweetalert({
      //           text: " يرجى إدخال بيانات جميع المسافرين ",
      //           icon: "warning",

      //           buttons: {
      //             confirm: {
      //               text: "تم",
      //               className: "custom-button-class",
      //             },
      //           },
      //           dangerMode: true,
      //         });
      //         return; // Stop the function if not all passengers are valid
      //       }else{

      //         // Display the total price with SweetAlert
      //         sweetalert({
      //           title: "إجمالي السعر",
      //           text: `المجموع الكلي لأسعار التذاكر هو: ${totalPrice} ل.س`,
      //           icon: "info",
      //           buttons: {
      //             confirm: {
      //                 text: "موافق",
      //                 value: true,
      //                 className: "custom-button-class",
      //             },
      //             cancel: {
      //                 text: "إلغاء",
      //                 value: false,
      //                 visible: true,
      //                 className: "cancel-button-class",
      //             }
      //         },
      //           dangerMode:true
      //         }).then((value) => {
      //           if (value) {
      //           const fecth= async() =>{
      //             try{
      //       // dispatch(insertPassengerToReservation(data));
      //       const response = await axios.post(`${BaseUrl}/addPassengers`, data, {
      //         withCredentials: true,
      //       });
      //       if (response.data.status === 200) {
      //         setSelectedSeats1([]);
      //         setSelectedSeats([]);
      //         setSeatAndUser([]);
      //         await informSuccess();
      //         cleanupLocalStorage();
      //         onConfiarm();
      //         setReservationseat1(response.data.data.seatsStatus);
      //         if (selectedTransitIndex == 0) {
      //           setArraySeatPassengerTransit1((prevSeatsInfo) => {
      //             const updatedSeatsInfo = { ...prevSeatsInfo };
      //             response.data.data.passengersData.forEach((passenger) => {
      //               const seatNumber = parseInt(passenger.pivot.number_seat);

      //               if (!isNaN(seatNumber)) {
      //                 if (updatedSeatsInfo[seatNumber]) {
      //                   updatedSeatsInfo[seatNumber] = {
      //                     passengers: passenger,
      //                     id_reservation: passenger.pivot.id_reservation,
      //                     status: 2, // Default status or derive from existing data
      //                   };
      //                 } else {
      //                   updatedSeatsInfo[seatNumber] = {
      //                     passengers: passenger,
      //                     id_reservation: passenger.pivot.id_reservation,
      //                     status: 2, // Default status or derive from existing data
      //                   };
      //                 }
      //               }
      //             });

      //             return updatedSeatsInfo;
      //           });
      //           const mappedById = response.data.data.reservations.reduce(
      //             (acc, item) => {
      //               acc[item.id] = item;
      //               return acc;
      //             },
      //             {}
      //           );
      //           setArrayReservations(mappedById);
      //         } else if (selectedTransitIndex == 1) {
      //           setArraySeatPassengerTransit2((prevSeatsInfo) => {
      //             const updatedSeatsInfo = { ...prevSeatsInfo };
      //             response.data.data.passengersData.forEach((passenger) => {
      //               const seatNumber = parseInt(passenger.pivot.number_seat);

      //               if (!isNaN(seatNumber)) {
      //                 if (updatedSeatsInfo[seatNumber]) {
      //                   updatedSeatsInfo[seatNumber] = {
      //                     passengers: passenger,
      //                     id_reservation: passenger.pivot.id_reservation,
      //                     status: 2, // Default status or derive from existing data
      //                   };
      //                 } else {
      //                   updatedSeatsInfo[seatNumber] = {
      //                     passengers: passenger,
      //                     id_reservation: passenger.pivot.id_reservation,
      //                     status: 2, // Default status or derive from existing data
      //                   };
      //                 }
      //               }
      //             });

      //             return updatedSeatsInfo;
      //           });
      //           const mappedById = response.data.data.reservations.reduce(
      //             (acc, item) => {
      //               acc[item.id] = item;
      //               return acc;
      //             },
      //             {}
      //           );
      //           setArrayReservations(mappedById);
      //         }

      //         // setReservationseat1(response.data.data.seatsStatus);

      //         // Create the SweetAlert pop-up with customClass
      //         // sweetalert({
      //         //   text: "تم الإضافة بنجاح",
      //         //   icon: "success",
      //         //   buttons: {
      //         //     confirm: {
      //         //       text: "تم",
      //         //       className: "custom-button-class",
      //         //     },
      //         //   },
      //         //   className: "alert-div",
      //         //   focusConfirm: false, // Do not auto-focus
      //         //   customClass: {
      //         //     confirmButton: "custom-button-class", // Add the custom class to the confirm button
      //         //   },
      //         // }).then(() => {
      //         //   dispatch(fetchTripBYId(data.tripid));
      //         //   localStorage.setItem("openpassenger", "false");
      //         //   localStorage.removeItem("passengers");
      //         //   localStorage.removeItem("childrens");
      //         // });

      //         // // dispatch(fetchTripBYId(TripID.id));
      //         // onConfiarm();
      //       }
      //     } catch (error) {
      //       console.error('Error making API request', error);
      //       // Handle errors in API request here, perhaps alert the user.
      //     }
      //     }
      //     fecth()
      //     const informSuccess = async () => {
      //       await sweetalert({
      //         text: "تم الإضافة بنجاح",
      //         icon: "success",
      //         buttons: {
      //           confirm: {
      //             text: "تم",
      //             className: "custom-button-class",
      //           },
      //         },
      //         className: "alert-div",
      //         focusConfirm: false,
      //         customClass: {
      //           confirmButton: "custom-button-class",
      //         },
      //       });
      //     }

      //     const cleanupLocalStorage = () => {
      //       localStorage.setItem("openpassenger", "false");
      //       localStorage.removeItem("passengers");
      //       localStorage.removeItem("childrens");
      //     }
      //   }else {
      //     // Handle the cancellation case, maybe clean up some data or revert some changes
      // }
      //   });
      //       }
      //     } else if (direct == true) {
      //       const data = {
      //         id_reservation: reservationIds[0].reservationId1,
      //         branch: TripID.id_branch,
      //         tripid: TripID.id,
      //         adults: JSON.parse(localStorage.getItem("passengers")),
      //         children: [],
      //       };
      //       const allValid = passengers.every(
      //         (passenger) =>
      //           passenger.first_name !== "" && passenger.id_price_ticket !== ""
      //       );
      //       const totalPrice = passengers.reduce((total, passenger) => {
      //         return total + parseFloat(passenger.price); // Ensure the ticket price is treated as a float
      //       }, 0);

      //       if (!allValid) {
      //         sweetalert({
      //           text: " يرجى إدخال بيانات جميع المسافرين ",
      //           icon: "warning",
      //           buttons: {
      //             confirm: {
      //               text: "تم",
      //               className: "custom-button-class",
      //             },
      //           },
      //           dangerMode: true,
      //         });
      //         return; // Stop the function if not all passengers are valid
      //       }else{

      //         // Display the total price with SweetAlert
      //         sweetalert({
      //           title: "إجمالي السعر",
      //           text: `المجموع الكلي لأسعار التذاكر هو: ${totalPrice} ل.س`,
      //           icon: "info",
      //           buttons: {
      //             confirm: {
      //                 text: "موافق",
      //                 value: true,
      //                 className: "custom-button-class",
      //             },
      //             cancel: {
      //                 text: "إلغاء",
      //                 value: false,
      //                 visible: true,
      //                 className: "cancel-button-class",
      //             }
      //         },
      //           dangerMode:true
      //         }).then((value) => {
      //           if (value) {
      //           const fecth= async() =>{
      //             try{
      //       // dispatch(insertPassengerToReservation(data));
      //       const response = await axios.post(`${BaseUrl}/addPassengers`, data, {
      //         withCredentials: true,
      //       });
      //       if (response.data.status === 200) {
      //         if (idDirect == TripID.id) {
      //           setResponse1(response.data.data.seatsStatus);
      //           await informSuccess();
      //           cleanupLocalStorage();
      //           onConfiarm();
      //           setSelectedSeats1([]);
      //           setSelectedSeats([]);
      //           setSeatAndUser([]);
      //           setArraySeat((prevSeatsInfo) => {
      //             const updatedSeatsInfo = { ...prevSeatsInfo };
      //             response.data.data.passengersData.forEach((passenger) => {
      //               const seatNumber = parseInt(passenger.pivot.number_seat);

      //               if (!isNaN(seatNumber)) {
      //                 if (updatedSeatsInfo[seatNumber]) {
      //                   updatedSeatsInfo[seatNumber] = {
      //                     passengers: passenger,
      //                     id_reservation: passenger.pivot.id_reservation,
      //                     status: 2, // Default status or derive from existing data
      //                   };
      //                 } else {
      //                   updatedSeatsInfo[seatNumber] = {
      //                     passengers: passenger,
      //                     id_reservation: passenger.pivot.id_reservation,
      //                     status: 2, // Default status or derive from existing data
      //                   };
      //                 }
      //               }
      //             });

      //             return updatedSeatsInfo;
      //           });
      //           const updatedPassenger = response.data.data.passengersData; // The passenger data from the response
      //           const passengerId = updatedPassenger.id; // The ID of the passenger that was updated

      //           const newPassengers = response.data.data.passengersData; // Your new passengers data array
      //           const reservationId = newPassengers[0]?.pivot?.id_reservation; // ID of the reservation to update
      //           const mappedById = response.data.data.reservations.reduce(
      //             (acc, item) => {
      //               acc[item.id] = item;
      //               return acc;
      //             },
      //             {}
      //           );
      //           setArrayReservations(mappedById);
      //           // setArrayReservations((prevReservations) => {
      //           //   // Deep clone the previous reservations to avoid direct state mutation
      //           //   const updatedReservations = JSON.parse(
      //           //     JSON.stringify(prevReservations)
      //           //   );

      //           //   // Check if the specific reservation exists
      //           //   if (updatedReservations[reservationId]) {
      //           //     // Update passengers array
      //           //     updatedReservations[reservationId].passengers.push(
      //           //       ...newPassengers.map((np) => ({
      //           //         id: np.id,
      //           //         first_name: np.first_name,
      //           //         middle_name: np.pivot.middle_name,
      //           //         last_name: np.pivot.last_name,
      //           //         mother_name: np.pivot.mother_name,
      //           //         national_id: np.pivot.national_id,
      //           //         gender: np.pivot.gender,
      //           //         birth_date: np.pivot.birth_date,
      //           //         birth_place: np.pivot.birth_place,
      //           //         civil_status: np.pivot.civil_status,
      //           //         number_seat: np.pivot.number_seat,
      //           //         id_price_ticket: np.pivot.id_price_ticket,
      //           //         price: np.pivot.price,
      //           //         title: np.pivot.title,
      //           //       }))
      //           //     );

      //           //     // Now update the reservation_seats_number status for each new passenger
      //           //     newPassengers.forEach((passenger) => {
      //           //       const seatNumber = passenger.pivot.number_seat.toString(); // Convert to string if needed
      //           //       if (
      //           //         updatedReservations[reservationId]
      //           //           .reservation_seats_number[seatNumber]
      //           //       ) {
      //           //         // Update the status to 2 if seat number exists
      //           //         updatedReservations[
      //           //           reservationId
      //           //         ].reservation_seats_number[seatNumber] = 2;
      //           //       } else {
      //           //         // Handle cases where the seat number might not exist already
      //           //         // You might want to add the seat number with status 2 or handle differently
      //           //         updatedReservations[
      //           //           reservationId
      //           //         ].reservation_seats_number[seatNumber] = 2;
      //           //       }
      //           //     });
      //           //   } else {
      //           //     // Handle the case where reservationId does not exist in the arrayReservations
      //           //     // You might need to add it or handle the error
      //           //   }

      //           //   return updatedReservations;
      //           // });
      //         } else {
      //           setReservationseat1(response.data.data.seatsStatus);
      //         }
      //         // setReservationseat1(response.data.data.seatsStatus);

      //         // Create the SweetAlert pop-up with customClass
      //         // sweetalert({
      //         //   text: "تم الإضافة بنجاح",
      //         //   icon: "success",
      //         //   buttons: {
      //         //     confirm: {
      //         //       text: "تم",
      //         //       className: "custom-button-class",
      //         //     },
      //         //   },
      //         //   className: "alert-div",
      //         //   focusConfirm: false, // Do not auto-focus
      //         //   customClass: {
      //         //     confirmButton: "custom-button-class", // Add the custom class to the confirm button
      //         //   },
      //         // }).then(() => {
      //         //   localStorage.setItem("openpassenger", "false");
      //         //   localStorage.removeItem("passengers");
      //         //   localStorage.removeItem("childrens");
      //         // });

      //         // onConfiarm();
      //       }

      //       onConfiarm();
      //       setUpdateSeat(false);
      //       onConfiarm();
      //       setUpdateSeat(false);
      //     } catch (error) {
      //       console.error('Error making API request', error);
      //       // Handle errors in API request here, perhaps alert the user.
      //     }
      //     }
      //     fecth()
      //     const informSuccess = async () => {
      //       await sweetalert({
      //         text: "تم الإضافة بنجاح",
      //         icon: "success",
      //         buttons: {
      //           confirm: {
      //             text: "تم",
      //             className: "custom-button-class",
      //           },
      //         },
      //         className: "alert-div",
      //         focusConfirm: false,
      //         customClass: {
      //           confirmButton: "custom-button-class",
      //         },
      //       });
      //     }

      //     const cleanupLocalStorage = () => {
      //       localStorage.setItem("openpassenger", "false");
      //       localStorage.removeItem("passengers");
      //       localStorage.removeItem("childrens");
      //     }
      //   }else {
      //     // Handle the cancellation case, maybe clean up some data or revert some changes
      // }
      //   });

      //     }
      //   }
      //   }
      //   else {
      //     if (direct == true) {

      //       const data = {
      //         id_reservation: insertreservation1.id,
      //         branch: TripID.id_branch,
      //         tripid: TripID.id,
      //         adults: JSON.parse(localStorage.getItem("passengers")),
      //         children: [],
      //       };
      //       const allValid = passengers.every(
      //         (passenger) =>
      //           passenger.first_name !== "" && passenger.id_price_ticket !== ""
      //       );
      //       const totalPrice = passengers.reduce((total, passenger) => {
      //         return total + parseFloat(passenger.price); // Ensure the ticket price is treated as a float
      //       }, 0);
      //       if (!allValid) {
      //         sweetalert({
      //           text: " يرجى إدخال بيانات جميع المسافرين ",
      //           icon: "warning",
      //           buttons: {
      //             confirm: {
      //               text: "تم",
      //               className: "custom-button-class",
      //             },
      //           },
      //           dangerMode: true,
      //         });
      //         return; // Stop the function if not all passengers are valid
      //       } else {

      //         // Display the total price with SweetAlert
      //         sweetalert({
      //           title: "إجمالي السعر",
      //           text: `المجموع الكلي لأسعار التذاكر هو: ${totalPrice} ل.س`,
      //           icon: "info",
      //           buttons: {
      //             confirm: {
      //                 text: "موافق",
      //                 value: true,
      //                 className: "custom-button-class",
      //             },
      //             cancel: {
      //                 text: "إلغاء",
      //                 value: false,
      //                 visible: true,
      //                 className: "cancel-button-class",
      //             }
      //         },
      //           dangerMode:true
      //         }).then((value) => {
      //           if (value) {
      //           const fecth= async() =>{
      //             try{
      //             // dispatch(insertPassengerToReservation(data));
      //             const response =  await axios.post(`${BaseUrl}/addPassengers`, data, {
      //               withCredentials: true,
      //             });
      //             if (response.data.status === 200) {
      //               await informSuccess();
      //               cleanupLocalStorage();
      //               onConfiarm();
      //               if (idDirect == TripID.id) {
      //                 setResponse1(response.data.data.seatsStatus);

      //                 setSelectedSeats1([]);
      //                 setSelectedSeats([]);
      //                 setSeatAndUser([]);
      //                 setArraySeat((prevSeatsInfo) => {
      //                   const updatedSeatsInfo = { ...prevSeatsInfo };
      //                   response.data.data.passengersData.forEach((passenger) => {
      //                     const seatNumber = parseInt(passenger.pivot.number_seat);

      //                     if (!isNaN(seatNumber)) {
      //                       if (updatedSeatsInfo[seatNumber]) {
      //                         updatedSeatsInfo[seatNumber] = {
      //                           passengers: passenger,
      //                           id_reservation: passenger.pivot.id_reservation,
      //                           status: 2, // Default status or derive from existing data
      //                         };
      //                       } else {
      //                         updatedSeatsInfo[seatNumber] = {
      //                           passengers: passenger,
      //                           id_reservation: passenger.pivot.id_reservation,
      //                           status: 2, // Default status or derive from existing data
      //                         };
      //                       }
      //                     }
      //                   });

      //                   return updatedSeatsInfo;
      //                 });
      //                 const mappedById = response.data.data.reservations.reduce(
      //                   (acc, item) => {
      //                     acc[item.id] = item;
      //                     return acc;
      //                   },
      //                   {}
      //                 );
      //                 setArrayReservations(mappedById);
      //               } else {
      //                 setReservationseat1(response.data.data.seatsStatus);
      //               }
      //               // setReservationseat1(response.data.data.seatsStatus);

      //               // Create the SweetAlert pop-up with customClass
      //             //   await sweetalert({
      //             //     text: "تم الإضافة بنجاح",
      //             //     icon: "success",
      //             //     buttons: {
      //             //         confirm: {
      //             //             text: "تم",
      //             //             className: "custom-button-class",
      //             //         },
      //             //     },
      //             //     className: "alert-div",
      //             //     focusConfirm: false,  // Do not auto-focus
      //             //     customClass: {
      //             //         confirmButton: "custom-button-class", // Add the custom class to the confirm button
      //             //     },
      //             // });

      //             // localStorage.setItem("openpassenger", "false");
      //             // localStorage.removeItem("passengers");
      //             // localStorage.removeItem("childrens");

      //             //   onConfiarm();
      //             }
      //           } catch (error) {
      //             console.error('Error making API request', error);
      //             // Handle errors in API request here, perhaps alert the user.
      //           }
      //           }
      //           fecth()
      //           const informSuccess = async () => {
      //             await sweetalert({
      //               text: "تم الإضافة بنجاح",
      //               icon: "success",
      //               buttons: {
      //                 confirm: {
      //                   text: "تم",
      //                   className: "custom-button-class",
      //                 },
      //               },
      //               className: "alert-div",
      //               focusConfirm: false,
      //               customClass: {
      //                 confirmButton: "custom-button-class",
      //               },
      //             });
      //           }

      //           const cleanupLocalStorage = () => {
      //             localStorage.setItem("openpassenger", "false");
      //             localStorage.removeItem("passengers");
      //             localStorage.removeItem("childrens");
      //           }

      //         } else {
      //           // Handle the cancellation case, maybe clean up some data or revert some changes
      //       }
      //         });

      //       }
      //     }
      //      else if (transet == true) {
      //       const data = {
      //         id_reservation: insertreservation1.id,
      //         branch: TripID.id_branch,
      //         tripid: TripID.id,
      //         adults: JSON.parse(localStorage.getItem("passengers")),
      //         children: [],
      //       };
      //       const allValid = passengers.every(
      //         (passenger) =>
      //           passenger.first_name !== "" && passenger.id_price_ticket !== ""
      //       );
      //       const totalPrice = passengers.reduce((total, passenger) => {
      //         return total + parseFloat(passenger.price); // Ensure the ticket price is treated as a float
      //       }, 0);
      //       if (!allValid) {
      //         sweetalert({
      //           text: " يرجى إدخال بيانات جميع المسافرين ",
      //           icon: "warning",
      //           buttons: {
      //             confirm: {
      //               text: "تم",
      //               className: "custom-button-class",
      //             },
      //           },
      //           dangerMode: true,
      //         });
      //         return; // Stop the function if not all passengers are valid
      //       } else {
      //         // Display the total price with SweetAlert
      //         sweetalert({
      //           title: "إجمالي السعر",
      //           text: `المجموع الكلي لأسعار التذاكر هو: ${totalPrice} ل.س`,
      //           icon: "info",
      //           buttons: {
      //             confirm: {
      //                 text: "موافق",
      //                 value: true,
      //                 className: "custom-button-class",
      //             },
      //             cancel: {
      //                 text: "إلغاء",
      //                 value: false,
      //                 visible: true,
      //                 className: "cancel-button-class",
      //             }
      //         },
      //           dangerMode:true
      //         }).then((value) => {
      //           if (value) {
      //           const fecth= async() =>{
      //             try{
      //           // dispatch(insertPassengerToReservation(data));
      //           const response = await axios.post(`${BaseUrl}/addPassengers`, data, {
      //             withCredentials: true,
      //           });
      //           if (response.data.status === 200) {
      //             await informSuccess();
      //               cleanupLocalStorage();
      //               onConfiarm();
      //             setSelectedSeats1([]);
      //             setSelectedSeats([]);
      //             setSeatAndUser([]);

      //             setReservationseat1(response.data.data.seatsStatus);
      //             if (selectedTransitIndex == 0) {
      //               setArraySeatPassengerTransit1((prevSeatsInfo) => {
      //                 const updatedSeatsInfo = { ...prevSeatsInfo };
      //                 response.data.data.passengersData.forEach((passenger) => {
      //                   const seatNumber = parseInt(passenger.pivot.number_seat);

      //                   if (!isNaN(seatNumber)) {
      //                     if (updatedSeatsInfo[seatNumber]) {
      //                       updatedSeatsInfo[seatNumber] = {
      //                         passengers: passenger,
      //                         id_reservation: passenger.pivot.id_reservation,
      //                         status: 2, // Default status or derive from existing data
      //                       };
      //                     } else {
      //                       updatedSeatsInfo[seatNumber] = {
      //                         passengers: passenger,
      //                         id_reservation: passenger.pivot.id_reservation,
      //                         status: 2, // Default status or derive from existing data
      //                       };
      //                     }
      //                   }
      //                 });

      //                 return updatedSeatsInfo;
      //               });
      //               const mappedById = response.data.data.reservations.reduce(
      //                 (acc, item) => {
      //                   acc[item.id] = item;
      //                   return acc;
      //                 },
      //                 {}
      //               );
      //               setArrayReservations(mappedById);
      //             } else if (selectedTransitIndex == 1) {
      //               setArraySeatPassengerTransit2((prevSeatsInfo) => {
      //                 const updatedSeatsInfo = { ...prevSeatsInfo };
      //                 response.data.data.passengersData.forEach((passenger) => {
      //                   const seatNumber = parseInt(passenger.pivot.number_seat);

      //                   if (!isNaN(seatNumber)) {
      //                     if (updatedSeatsInfo[seatNumber]) {
      //                       updatedSeatsInfo[seatNumber] = {
      //                         passengers: passenger,
      //                         id_reservation: passenger.pivot.id_reservation,
      //                         status: 2, // Default status or derive from existing data
      //                       };
      //                     } else {
      //                       updatedSeatsInfo[seatNumber] = {
      //                         passengers: passenger,
      //                         id_reservation: passenger.pivot.id_reservation,
      //                         status: 2, // Default status or derive from existing data
      //                       };
      //                     }
      //                   }
      //                 });

      //                 return updatedSeatsInfo;
      //               });
      //               const mappedById = response.data.data.reservations.reduce(
      //                 (acc, item) => {
      //                   acc[item.id] = item;
      //                   return acc;
      //                 },
      //                 {}
      //               );
      //               setArrayReservations(mappedById);
      //             }

      //             // setReservationseat1(response.data.data.seatsStatus);

      //             // Create the SweetAlert pop-up with customClass
      //             // sweetalert({
      //             //   text: "تم الإضافة بنجاح",
      //             //   icon: "success",
      //             //   buttons: {
      //             //     confirm: {
      //             //       text: "تم",
      //             //       className: "custom-button-class",
      //             //     },
      //             //   },
      //             //   className: "alert-div",
      //             //   focusConfirm: false, // Do not auto-focus
      //             //   customClass: {
      //             //     confirmButton: "custom-button-class", // Add the custom class to the confirm button
      //             //   },
      //             // }).then(() => {
      //             //   dispatch(fetchTripBYId(data.tripid));
      //             //   localStorage.setItem("openpassenger", "false");
      //             //   localStorage.removeItem("passengers");
      //             //   localStorage.removeItem("childrens");
      //             // });

      //             // // dispatch(fetchTripBYId(TripID.id));
      //             // onConfiarm();
      //           }
      //         }
      //         catch (error) {
      //           console.error('Error making API request', error);
      //           // Handle errors in API request here, perhaps alert the user.
      //         }
      //         }
      //         fecth()
      //         const informSuccess = async () => {
      //           await sweetalert({
      //             text: "تم الإضافة بنجاح",
      //             icon: "success",
      //             buttons: {
      //               confirm: {
      //                 text: "تم",
      //                 className: "custom-button-class",
      //               },
      //             },
      //             className: "alert-div",
      //             focusConfirm: false,
      //             customClass: {
      //               confirmButton: "custom-button-class",
      //             },
      //           });
      //         }

      //         const cleanupLocalStorage = () => {
      //           localStorage.setItem("openpassenger", "false");
      //           localStorage.removeItem("passengers");
      //           localStorage.removeItem("childrens");
      //         }
      //       } else {
      //         // Handle the cancellation case, maybe clean up some data or revert some changes
      //     }
      //         });
      //       }
      //     }
      //   }
      // else {
      //   if (direct == true) {
      //     const data = {
      //       id_reservation: insertreservation1.id,
      //       branch: TripID.id_branch,
      //       tripid: TripID.id,
      //       adults: JSON.parse(localStorage.getItem("passengers")),
      //       children: [],
      //     };
      //     const allValid = passengers.every(
      //       (passenger) =>
      //         passenger.first_name !== "" && passenger.id_price_ticket !== ""
      //     );
      //     if (!allValid) {
      //       sweetalert({
      //         text: " يرجى إدخال بيانات جميع المسافرين ",
      //         icon: "warning",
      //         buttons: {
      //           confirm: {
      //             text: "تم",
      //             className: "custom-button-class",
      //           },
      //         },
      //         dangerMode: true,
      //       });
      //       return; // Stop the function if not all passengers are valid
      //     }
      //     // dispatch(insertPassengerToReservation(data));
      //     const response = await axios.post(`${BaseUrl}/addPassengers`, data, {
      //       withCredentials: true,
      //     });
      //     if (response.data.status === 200) {
      //       if (idDirect == TripID.id) {
      //         setResponse1(response.data.data.seatsStatus);

      //         setSelectedSeats1([]);
      //         setSelectedSeats([]);
      //         setSeatAndUser([]);
      //         setArraySeat((prevSeatsInfo) => {
      //           const updatedSeatsInfo = { ...prevSeatsInfo };
      //           response.data.data.passengersData.forEach((passenger) => {
      //             const seatNumber = parseInt(passenger.pivot.number_seat);

      //             if (!isNaN(seatNumber)) {
      //               if (updatedSeatsInfo[seatNumber]) {
      //                 updatedSeatsInfo[seatNumber] = {
      //                   passengers: passenger,
      //                   id_reservation: passenger.pivot.id_reservation,
      //                   status: 2, // Default status or derive from existing data
      //                 };
      //               } else {
      //                 updatedSeatsInfo[seatNumber] = {
      //                   passengers: passenger,
      //                   id_reservation: passenger.pivot.id_reservation,
      //                   status: 2, // Default status or derive from existing data
      //                 };
      //               }
      //             }
      //           });

      //           return updatedSeatsInfo;
      //         });
      //         const mappedById = response.data.data.reservations.reduce(
      //           (acc, item) => {
      //             acc[item.id] = item;
      //             return acc;
      //           },
      //           {}
      //         );
      //         setArrayReservations(mappedById);
      //       } else {
      //         setReservationseat1(response.data.data.seatsStatus);
      //       }
      //       // setReservationseat1(response.data.data.seatsStatus);

      //       // Create the SweetAlert pop-up with customClass
      //       sweetalert({
      //         text: "تم الإضافة بنجاح",
      //         icon: "success",
      //         buttons: {
      //           confirm: {
      //             text: "تم",
      //             className: "custom-button-class",
      //           },
      //         },
      //         className: "alert-div",
      //         focusConfirm: false, // Do not auto-focus
      //         customClass: {
      //           confirmButton: "custom-button-class", // Add the custom class to the confirm button
      //         },
      //       }).then(() => {
      //         localStorage.setItem("openpassenger", "false");
      //         localStorage.removeItem("passengers");
      //         localStorage.removeItem("childrens");
      //       });

      //       onConfiarm();
      //     }
      //   } else if (transet == true) {
      //     const data = {
      //       id_reservation: insertreservation1.id,
      //       branch: TripID.id_branch,
      //       tripid: TripID.id,
      //       adults: JSON.parse(localStorage.getItem("passengers")),
      //       children: [],
      //     };
      //     const allValid = passengers.every(
      //       (passenger) =>
      //         passenger.first_name !== "" && passenger.id_price_ticket !== ""
      //     );
      //     if (!allValid) {
      //       sweetalert({
      //         text: " يرجى إدخال بيانات جميع المسافرين ",
      //         icon: "warning",
      //         buttons: {
      //           confirm: {
      //             text: "تم",
      //             className: "custom-button-class",
      //           },
      //         },
      //         dangerMode: true,
      //       });
      //       return; // Stop the function if not all passengers are valid
      //     }
      //     // dispatch(insertPassengerToReservation(data));
      //     const response = await axios.post(`${BaseUrl}/addPassengers`, data, {
      //       withCredentials: true,
      //     });
      //     if (response.data.status === 200) {
      //       setSelectedSeats1([]);
      //       setSelectedSeats([]);
      //       setSeatAndUser([]);

      //       setReservationseat1(response.data.data.seatsStatus);
      //       if (selectedTransitIndex == 0) {
      //         setArraySeatPassengerTransit1((prevSeatsInfo) => {
      //           const updatedSeatsInfo = { ...prevSeatsInfo };
      //           response.data.data.passengersData.forEach((passenger) => {
      //             const seatNumber = parseInt(passenger.pivot.number_seat);

      //             if (!isNaN(seatNumber)) {
      //               if (updatedSeatsInfo[seatNumber]) {
      //                 updatedSeatsInfo[seatNumber] = {
      //                   passengers: passenger,
      //                   id_reservation: passenger.pivot.id_reservation,
      //                   status: 2, // Default status or derive from existing data
      //                 };
      //               } else {
      //                 updatedSeatsInfo[seatNumber] = {
      //                   passengers: passenger,
      //                   id_reservation: passenger.pivot.id_reservation,
      //                   status: 2, // Default status or derive from existing data
      //                 };
      //               }
      //             }
      //           });

      //           return updatedSeatsInfo;
      //         });
      //         const mappedById = response.data.data.reservations.reduce(
      //           (acc, item) => {
      //             acc[item.id] = item;
      //             return acc;
      //           },
      //           {}
      //         );
      //         setArrayReservations(mappedById);
      //       } else if (selectedTransitIndex == 1) {
      //         setArraySeatPassengerTransit2((prevSeatsInfo) => {
      //           const updatedSeatsInfo = { ...prevSeatsInfo };
      //           response.data.data.passengersData.forEach((passenger) => {
      //             const seatNumber = parseInt(passenger.pivot.number_seat);

      //             if (!isNaN(seatNumber)) {
      //               if (updatedSeatsInfo[seatNumber]) {
      //                 updatedSeatsInfo[seatNumber] = {
      //                   passengers: passenger,
      //                   id_reservation: passenger.pivot.id_reservation,
      //                   status: 2, // Default status or derive from existing data
      //                 };
      //               } else {
      //                 updatedSeatsInfo[seatNumber] = {
      //                   passengers: passenger,
      //                   id_reservation: passenger.pivot.id_reservation,
      //                   status: 2, // Default status or derive from existing data
      //                 };
      //               }
      //             }
      //           });

      //           return updatedSeatsInfo;
      //         });
      //         const mappedById = response.data.data.reservations.reduce(
      //           (acc, item) => {
      //             acc[item.id] = item;
      //             return acc;
      //           },
      //           {}
      //         );
      //         setArrayReservations(mappedById);
      //       }

      //       // setReservationseat1(response.data.data.seatsStatus);

      //       // Create the SweetAlert pop-up with customClass
      //       sweetalert({
      //         text: "تم الإضافة بنجاح",
      //         icon: "success",
      //         buttons: {
      //           confirm: {
      //             text: "تم",
      //             className: "custom-button-class",
      //           },
      //         },
      //         className: "alert-div",
      //         focusConfirm: false, // Do not auto-focus
      //         customClass: {
      //           confirmButton: "custom-button-class", // Add the custom class to the confirm button
      //         },
      //       }).then(() => {
      //         dispatch(fetchTripBYId(data.tripid));
      //         localStorage.setItem("openpassenger", "false");
      //         localStorage.removeItem("passengers");
      //         localStorage.removeItem("childrens");
      //       });

      //       // dispatch(fetchTripBYId(TripID.id));
      //       onConfiarm();
      //     }
      //   }
      // }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown2);
    return () => {
      window.removeEventListener("keydown", handleKeyDown2);
    };
  }, [dispatch, onConfiarm]);

  const handleSubmit = async (e) => {
    const valueInputArray = Array.isArray(valueinput)
      ? valueinput
      : valueinput.split(/[\s,+]+/);
    const combinedSeats = [
      ...selectedSeats,
      ...selectedSeats1,
      ...selectedSeats2,
      ...valueInputArray,
    ]
      .map((seat) => {
        if (typeof seat === "number") return seat;

        return seat.split(/[\s,+]+/).map(Number);
      })
      .flat();

    const sortedNumbers1 = Array.from(
      new Set(combinedSeats.filter((seat) => !isNaN(seat)))
    );
    const uniqueNumbersWithoutZero = sortedNumbers1.filter(
      (number) => number !== 0
    );
    const seatNumbersToSend2 = uniqueNumbersWithoutZero.sort((a, b) => a - b);

    setResultArray(seatNumbersToSend2);
    const seatNumbersToSend = seatNumbersToSend2;
    const reservationIds1 = [];

    const passengerData = [];
    let directTrip = 0;
    const reservationIdDirect = Object.values(arrayReservations).find(
      (reservation) => {
        const seatNumbers = Object.keys(
          reservation.reservation_seats_number || {} // Adding fallback to empty object to ensure it always works
        ).map(Number); // Convert keys to numbers since seat numbers are often numeric
        return seatNumbers.includes(seatNumbersToSend2[0]);
      }
    )?.id;
  

    const reservationIdTransit =
      Array.isArray(TripID.tripTransit) && arrayReservations
        ? Object.values(arrayReservations)?.reduce((acc, reservation) => {
            // Ensure reservation_seats_number is not undefined before proceeding
            if (reservation && reservation.reservation_seats_number) {
              const seatNumbers = Object.keys(
                reservation.reservation_seats_number
              ).map(Number);
              if (seatNumbers.includes(seatNumbersToSend2[0])) {
                acc.push({ reservationId: reservation.id });
              }
            }
            return acc;
          }, [])
        : null;
    // eslint-disable-next-line eqeqeq
    if (idDirect == TripID.id) {
      if (Array.isArray(TripID.tripTransit) && TripID.tripTransit.length > 0) {
        const seatNumbersToSend = seatNumbersToSend2;
        let reservationseat = null;

        // Convert the object keys into an array and then use find
        const reservationSeatKeys = Object.keys(
          TripID.tripTransit[0].reservationSeats
        );
        reservationseat = reservationSeatKeys.find((seat) =>
          seatNumbersToSend2.includes(Number(seat))
        );

        if (seatNumbersToSend2.includes(Number(reservationseat))) {
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "لا يمكن حذف كرسي عبور من رحلة اساسية",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
        } else {
          // Seat not found in transit trip
          const status = response1[seatNumbersToSend2[0]];

          reservationIds1.push({ reservationId1: reservationIdDirect });

          // eslint-disable-next-line eqeqeq
          if (status == 5 || status == 0) {
            localStorage.setItem("changeseat", true);
            sweetalert({
              text: "لا يمكن تبديل كرسي لا يحتوي على بيانات",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
            // eslint-disable-next-line eqeqeq
          } else if (status == 4) {
            setResultArray([]);
            localStorage.setItem("changeseat", true);
            sweetalert({
              text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
            // eslint-disable-next-line eqeqeq
          } else if (status == 3) {
            setResultArray([]);
            localStorage.setItem("changeseat", true);
            sweetalert({
              text: "Ctrl + 0 لتغيير حالة كرسي ادارة لمتوفر اضغط على  ",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
            // eslint-disable-next-line eqeqeq
          }
          //  else if (status == 1) {
          //   const id_reservation = reservationIds1[0].reservationId1;
          //   const seat_number = seatNumbersToSend2;
          //   const passengerDeleteData = {
          //     id_reservation,
          //     seat_number,
          //     branch: TripID.id_branch,
          //     trip_id: TripID.id,
          //   };
          //   axios
          //     .post(`${BaseUrl}/remove-seat-reservation`, passengerDeleteData, {
          //       withCredentials: true,
          //     })
          //     .then((response) => {
          //       // Handle the successful response here
          //       if (response.data.status === 200) {
          //         setResponse1(response.data.data.seats);
          //         setArraySeat((prevSeatsInfo) => {
          //           const updatedSeatsInfo = { ...prevSeatsInfo };
          //           const seatNumber = parseInt(seat_number);

          //           if (!isNaN(seatNumber)) {
          //             updatedSeatsInfo[seatNumber] = {
          //               passengers: {},
          //               status: 0,
          //               id_reservation: undefined,
          //             };
          //           }

          //           return updatedSeatsInfo;
          //         });
          //         const mappedById = response.data.data.reservations.reduce(
          //           (acc, item) => {
          //             acc[item.id] = item;
          //             return acc;
          //           },
          //           {}
          //         );
          //         setArrayReservations(mappedById);

          //         sweetalert({
          //           text: response.data.message,
          //           icon: "success",
          //           buttons: {
          //             confirm: {
          //               text: "تم",
          //               className: "custom-button-class",
          //             },
          //           },
          //           className: "alert-div",
          //         }).then(() => {
          //           // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
          //           localStorage.setItem("openpassenger", false);
          //           localStorage.setItem("changeseat", false);
          //         });
          //       }
          //     });
          //   // dispatch(deleteSeatToReservation(passengerDeleteData));
          //   settranset(false);
          //   setDirect(true);
          //   setResultArray([]);
          //   setInputString("");
          //   setValueinput("");
          //   setSeatAndUser([]);
          //   setSelectedSeats([]);
          //   setSelectedSeats1([]);
          //   setSelectedSeats2([]);
          //   // eslint-disable-next-line eqeqeq
          // }
           else if (status == 1 || status == 2) {
            const id_reservation = reservationIds1[0].reservationId1;
            const number_seat = seatNumbersToSend2;
            const sortedPassengers = passengers
            .filter(passenger => passenger.NewSeat) // Filter out passengers without a code_seat
            .sort((a, b) => a.number_seat - b.number_seat); // Sort by number_seat
        
          // Step 5: Extract code_seat values
          const codeSeats = sortedPassengers.map(passenger => passenger.NewSeat);
      
    
            const passengerDeleteData = {
              id_reservation,
              oldSeat:Number(number_seat[0]),
              newSeat:Number(codeSeats[0]),
              branch: TripID.id_branch,
            };
            let passengerDetails = passengers.map(passenger => {
              return `<div style="margin-bottom: 10px;">
                        <strong> المقعد القديم:</strong> ${passengers[0].number_seat} -
                        <strong> المقعد الجديد:</strong> ${passengers[0].NewSeat} -
                      </div>`;
            }).join("");
          
            sweetalert({
              title: "تأكيد تيديل مقعد مسافر",
              text: "هل أنت متأكد أنك تريد تيديل الراكب التالية تفاصيلهم؟",
              content: {
                element: "div",
                
                attributes: {
                  innerHTML: `<div>${passengerDetails}</div>`
                },
              },
              icon: "warning",
              buttons: {
                confirm: {
                    text: "موافق",
                    value: true,
                    className: "custom-button-class",
                },
                cancel: {
                    text: "إلغاء",
                    value: false,
                    visible: true,
                    className: "cancel-button-class",
                },
              },
              dangerMode: true,
              
              className: "custom-sweetalert"
            }).then((value) => {
              if (value) {
            axios
              .post(
                `${BaseUrl}/swapSeatsReservation`,
                passengerDeleteData,
                { withCredentials: true }
              )
              .then((response) => {
                if (response.status === 200) {
                  setResponse1(response.data.data[0]);
                
                  const mappedById = response.data.reservations.reduce(
                    (acc, item) => {
                      acc[item.id] = item;
                      return acc;
                    },
                    {}
                  );
                  setArrayReservations(mappedById);
                  let seatwithPassenger = response.data.data[0];
                  let passenger;
                  let seatWithPassengerNull = Object.keys(response.data.data[0]).reduce(
                    (accumulator, currentKey) => {
                      const reservationInfo = response.data.reservations.find(
                        (reservation) =>
                          reservation.reservation_seats_number[currentKey] > 0
                      );
                      passenger = reservationInfo
                        ? reservationInfo.passengers?.find(
                            (p) => p.number_seat == currentKey
                          )
                        : null;
            
                      accumulator[currentKey] = {
                        passengers: Object(passenger),
                        status: reservationInfo?.reservation_seats_number[currentKey],
                        id_reservation: reservationInfo?.id,
                      };
                      return accumulator;
                    },
                    {}
                  );
            
                  setArraySeat(seatWithPassengerNull); 

                  

                
                  sweetalert({
                    text: response.data.message,
                    icon: "success",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                    localStorage.setItem("openpassenger", false);
                    localStorage.setItem("changeseat", false);
                    localStorage.removeItem("passengers");
                    setOpenPopup(false);
                    // dispatch(deletePassengerToReservation(passengerDeleteData));
            settranset(false);
            setDirect(true);
            setResultArray([]);
            setInputString("");
            setValueinput("");
            setSeatAndUser([]);
            setSelectedSeats([]);
            setSelectedSeats1([]);
            setSelectedSeats2([]);
                  });
                }
              })
              .catch((error) => {
                // Check if the error response is HTTP 500
                let errorMessage ;
                
                if (error.response && error.response.status === 500) {
                  errorMessage = error.response.data.message || error.response?.data?.data;
                }
                sweetalert({
                  title: "خطأ",
                  text: errorMessage,
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "حسنًا",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                });
                console.error("Error occurred:", error);
                // Optionally reset or clear certain parts of the UI or state
              });
            }
          });

            
          }
        }
      } else if (
        Array.isArray(TripID.tripTransit) &&
        // eslint-disable-next-line eqeqeq
        TripID.tripTransit.length == 0
      ) {
        const seatNumbersToSend = seatNumbersToSend2;
        let reservationseat = null;

        // Seat not found in transit trip
        const status = response1[seatNumbersToSend2[0]];
        reservationIds1.push({ reservationId1: reservationIdDirect });

        // eslint-disable-next-line eqeqeq
        if (status == 5 || status == 0) {
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "لا يمكن تيديل كرسي لا يحتوي على بيانات",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
          // eslint-disable-next-line eqeqeq
        } else if (status == 4) {
          setResultArray([]);
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
          // eslint-disable-next-line eqeqeq
        } else if (status == 3) {
          setResultArray([]);
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "Ctrl + 0 لتغيير حالة كرسي ادارة اضغط على  ",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
          // eslint-disable-next-line eqeqeq
        } 
       
        else if ( status == 1 || status == 2) {
          const id_reservation = reservationIds1[0].reservationId1;
          const number_seat = seatNumbersToSend2;
          const sortedPassengers = passengers
          .filter(passenger => passenger.code_seat) // Filter out passengers without a code_seat
          .sort((a, b) => a.number_seat - b.number_seat); // Sort by number_seat
      
        // Step 5: Extract code_seat values
        const codeSeats = passengers.map(passenger => passenger.NewSeat);
    
        const passengerDeleteData = {
          id_reservation,
          oldSeat:Number(number_seat[0]),
          newSeat:Number(codeSeats[0]),
          branch: TripID.id_branch,
        };
        let passengerDetails = passengers.map(passenger => {
          return `<div style="margin-bottom: 10px;">
                    <strong> المقعد القديم:</strong> ${passengers[0].number_seat} -
                    <strong> المقعد الجديد:</strong> ${passengers[0].NewSeat} -
                  </div>`;
        }).join("");
      
        sweetalert({
          title: "تأكيد تيديل مقعد مسافر",
          text: "هل أنت متأكد أنك تريد تيديل الراكب التالية تفاصيلهم؟",
          content: {
            element: "div",
            
            attributes: {
              innerHTML: `<div>${passengerDetails}</div>`
            },
          },
          icon: "warning",
          buttons: {
            confirm: {
                text: "موافق",
                value: true,
                className: "custom-button-class",
            },
            cancel: {
                text: "إلغاء",
                value: false,
                visible: true,
                className: "cancel-button-class",
            },
          },
          dangerMode: true,
          
          className: "custom-sweetalert"
        }).then((value) => {
          if (value) {
          axios
            .post(
              `${BaseUrl}/swapSeatsReservation`,
              passengerDeleteData,
              { withCredentials: true }
            )
            .then((response) => {
              // Handle the successful response here
                if (response.status === 200) {
                  setResponse1(response.data.data[0]);
                
                  const mappedById = response.data.reservations.reduce(
                    (acc, item) => {
                      acc[item.id] = item;
                      return acc;
                    },
                    {}
                  );
                  setArrayReservations(mappedById);
                  let seatwithPassenger = response.data.data[0];
                  let passenger;
                  let seatWithPassengerNull = Object.keys(response.data.data[0]).reduce(
                    (accumulator, currentKey) => {
                      const reservationInfo = response.data.reservations.find(
                        (reservation) =>
                          reservation.reservation_seats_number[currentKey] > 0
                      );
                      passenger = reservationInfo
                        ? reservationInfo.passengers?.find(
                            (p) => p.number_seat == currentKey
                          )
                        : null;
            
                      accumulator[currentKey] = {
                        passengers: Object(passenger),
                        status: reservationInfo?.reservation_seats_number[currentKey],
                        id_reservation: reservationInfo?.id,
                      };
                      return accumulator;
                    },
                    {}
                  );
                
                  setArraySeat(seatWithPassengerNull); 
                sweetalert({
                  text: response.data.message,
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                  localStorage.setItem("openpassenger", false);
                  localStorage.setItem("changeseat", false);
                  localStorage.removeItem("passengers");
                  setOpenPopup(false);
                        // dispatch(deletePassengerToReservation(passengerDeleteData));
            settranset(false);
            setDirect(true);
            setResultArray([]);
            setInputString("");
            setValueinput("");
            setSeatAndUser([]);
            setSelectedSeats([]);
            setSelectedSeats1([]);
            setSelectedSeats2([]);
                });
              }
            })
            .catch((error) => {
              // Check if the error response is HTTP 500
              let errorMessage ;
              
              if (error.response && error.response.status === 500) {
                errorMessage = error.response.data.message || error.response?.data?.data;
              }
              sweetalert({
                title: "خطأ",
                text: errorMessage,
                icon: "error",
                buttons: {
                  confirm: {
                    text: "حسنًا",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              });
              console.error("Error occurred:", error);
              // Optionally reset or clear certain parts of the UI or state
            });
      
          }
        });
        }
      }
      // eslint-disable-next-line eqeqeq
    } else if (idDirect != TripID.id) {
      let reservationseat = null;
      const reservationSeatKeys = Object.keys(
        TripID.tripTransit[selectedTransitIndex].reservationSeats
      );
      reservationseat = reservationSeatKeys.find((seat) =>
        seatNumbersToSend.includes(Number(seat))
      );
      settranset(true);
      setDirect(false);
      // if (reservationseat == seatNumbersToSend2) {
      reservationIds1.push({ reservationId1: reservationIdTransit });
      const status = reservationseat1[seatNumbersToSend2[0]];

      // eslint-disable-next-line eqeqeq
      if (status == 5 || status == 0) {
        localStorage.setItem("changeseat", true);
        sweetalert({
          text: "لا يمكن تيديل كرسي لا يحتوي على بيانات",
          icon: "error",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
        // eslint-disable-next-line eqeqeq
      } else if (status == 4) {
        setResultArray([]);
        localStorage.setItem("changeseat", true);
        sweetalert({
          text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
          icon: "error",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
        // eslint-disable-next-line eqeqeq
      } else if (status == 3) {
        setResultArray([]);
        localStorage.setItem("changeseat", true);
        sweetalert({
          text: "Ctrl + 0 لتغيير حالة كرسي ادارة لمتوفر اضغط على  ",
          icon: "error",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
        // eslint-disable-next-line eqeqeq
      } 
   
       else if (status == 1 || status == 2) {
        const id_reservation =
          reservationIds1[0]?.reservationId1[0].reservationId;
        const number_seat = seatNumbersToSend2;
        // const number_seat = passengerData.map(
        //   (passenger) => passenger.number_seat
        // );
        const sortedPassengers = passengers
        .filter(passenger => passenger.NewSeat) // Filter out passengers without a code_seat
        .sort((a, b) => a.number_seat - b.number_seat); // Sort by number_seat
    
      // Step 5: Extract code_seat values
      const codeSeats = passengers.map(passenger => passenger.NewSeat);
    
            const passengerDeleteData = {
              id_reservation,
              oldSeat:Number(number_seat[0]),
              newSeat:Number(codeSeats[0]),
              branch: TripID.id_branch,
            };
            let passengerDetails = passengers.map(passenger => {
              return `<div style="margin-bottom: 10px;">
                        <strong> المقعد القديم:</strong> ${passengers[0].number_seat} -
                        <strong> المقعد الجديد:</strong> ${passengers[0].NewSeat} -
                      </div>`;
            }).join("");
          
            sweetalert({
              title: "تأكيد تيديل مقعد مسافر",
              text: "هل أنت متأكد أنك تريد تيديل الراكب التالية تفاصيلهم؟",
              content: {
                element: "div",
                
                attributes: {
                  innerHTML: `<div>${passengerDetails}</div>`
                },
              },
              icon: "warning",
              buttons: {
                confirm: {
                    text: "موافق",
                    value: true,
                    className: "custom-button-class",
                },
                cancel: {
                    text: "إلغاء",
                    value: false,
                    visible: true,
                    className: "cancel-button-class",
                },
              },
              dangerMode: true,
              
              className: "custom-sweetalert"
            }).then((value) => {
              if (value) {
        axios
          .post(
            `${BaseUrl}/swapSeatsReservation`,
            passengerDeleteData,
            { withCredentials: true }
          )
          .then((response) => {
            if (response.data.status === 200) {
              setReservationseat1(response.data.data[0]);
                
              const mappedById = response.data.reservations.reduce(
                (acc, item) => {
                  acc[item.id] = item;
                  return acc;
                },
                {}
              );
          

              if (selectedTransitIndex == 0) {
                setReservationseat1(response.data.data[0]);

                setArrayReservations(mappedById);
                let seatwithPassenger = response.data.data[0];
                let passenger;
                let seatWithPassengerNull = Object.keys(response.data.data[0]).reduce(
                  (accumulator, currentKey) => {
                    const reservationInfo = response.data.reservations.find(
                      (reservation) =>
                        reservation.reservation_seats_number[currentKey] > 0
                    );
                    passenger = reservationInfo
                      ? reservationInfo.passengers?.find(
                          (p) => p.number_seat == currentKey
                        )
                      : null;
          
                    accumulator[currentKey] = {
                      passengers: Object(passenger),
                      status: reservationInfo?.reservation_seats_number[currentKey],
                      id_reservation: reservationInfo?.id,
                    };
                    return accumulator;
                  },
                  {}
                );
          
                setArraySeatPassengerTransit1(seatWithPassengerNull); 
                sweetalert({
                  text: response.data.message,
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                  localStorage.setItem("openpassenger", false);
                  localStorage.setItem("changeseat", false);
                  localStorage.removeItem("passengers");

                  setOpenPopup(false);
                  settranset(true);
                  setDirect(false);
                  setResultArray([]);
                  setInputString("");
                  setValueinput("");
                  setSeatAndUser([]);
                  setSelectedSeats([]);
                  setSelectedSeats1([]);
                  setSelectedSeats2([]);
                });
              } else if (selectedTransitIndex == 1) {
                setArrayReservations(mappedById);
                setReservationseat1(response.data.data[0]);

                let seatwithPassenger = response.data.data[0];
                let passenger;
                let seatWithPassengerNull = Object.keys(response.data.data[0]).reduce(
                  (accumulator, currentKey) => {
                    const reservationInfo = response.data.reservations.find(
                      (reservation) =>
                        reservation.reservation_seats_number[currentKey] > 0
                    );
                    passenger = reservationInfo
                      ? reservationInfo.passengers?.find(
                          (p) => p.number_seat == currentKey
                        )
                      : null;
          
                    accumulator[currentKey] = {
                      passengers: Object(passenger),
                      status: reservationInfo?.reservation_seats_number[currentKey],
                      id_reservation: reservationInfo?.id,
                    };
                    return accumulator;
                  },
                  {}
                );
          
                setArraySeatPassengerTransit2(seatWithPassengerNull); 
                sweetalert({
                  text: response.data.message,
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                  localStorage.setItem("openpassenger", false);
                  localStorage.setItem("changeseat", false);
                  setOpenPopup(false);
                  
                  settranset(true);
                  setDirect(false);
                  setResultArray([]);
                  setInputString("");
                  setValueinput("");
                  setSeatAndUser([]);
                  setSelectedSeats([]);
                  setSelectedSeats1([]);
                  setSelectedSeats2([]);
                });
              }

              // sweetalert({
              //   text: "تم الحذف بنجاح",
              //   icon: "success",
              //   buttons: {
              //     confirm: {
              //       text: "تم",
              //       className: "custom-button-class",
              //     },
              //   },
              //   className: "alert-div",
              // }).then(() => {
              //   // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
              //   localStorage.setItem("openpassenger", false);
              //   localStorage.setItem("changeseat", false);
              // });
            }
          })
          .catch((error) => {
            // Check if the error response is HTTP 500
            let errorMessage ;
            
            if (error.response && error.response.status === 500) {
              errorMessage = error.response.data.message || error.response?.data?.data;
            }
            sweetalert({
              title: "خطأ",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "حسنًا",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            });
            console.error("Error occurred:", error);
            // Optionally reset or clear certain parts of the UI or state
          });
        }
      });
        // dispatch(deletePassengerToReservation(passengerDeleteData));
      
      }
    }
    // }

    // setResultArray([]);
    // setInputString("");
    // setValueinput("");
    // setSeatAndUser([]);
    // setSelectedSeats([]);
    // setSelectedSeats1([]);
    // setSelectedSeats2([]);
    // });
  };

  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const [isvalid, setIsValid] = useState(true);

  const handleNext = () => {
    setactiveStep1((prevactiveStep1) => prevactiveStep1 + 1);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft" && activeStep1 !== steps.length) {
        setactiveStep1((prevactiveStep1) => prevactiveStep1 + 1);
      } else if (event.key === "ArrowRight" && activeStep1 !== 1) {
        setactiveStep1((prevactiveStep1) => prevactiveStep1 - 1);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeStep1, steps.length]);
  const handleBack = () => {
    setactiveStep1((prevactiveStep1) => prevactiveStep1 - 1);
  };
  const lastIndex = steps.length - 1;
  const isLastStep = activeStep1 === lastIndex + 1;
  const isFirstStep = activeStep1 === 1;
  
  return (
    <Grid item lg={12} md={12} sm={12} className="">
      <Grid item lg={12} md={12} sm={12}>
        <Box className="create-passenger">
          <React.Fragment>
            {steps.map((step, index) => {
              const isActiveStep = activeStep1 === index + 1; // Check if the step is active
              const isLastStep = index === lastIndex; // Check if it's the last step

              return (
                <div key={step.id}>
                  {isActiveStep ? (
                    <div>
                      {/* {step.isChild ? (
                          <PassangerChild
                            isAddingReservation={false}
                            adults={adults}
                            isvalid={isvalid}
                          setIsValid={setIsValid}
                            indexx={index}
                            initialValues={initialFValues.children}
                          />
                        ) : ( */}
                      <PassengerAdultSwap
                        id_trip={id_trip}
                        id_trip_type={id_trip_type}
                        isvalid={isvalid}
                        setIsValid={setIsValid}
                        id_company={id_company}
                        adults={adults}
                        index={index}
                        // initialValues={initialFValues.adults}
                      />
                      {/* )} */}
                    </div>
                  ) : null}
                </div>
              );
            })}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "3%",
                flexDirection: "row-reverse",
              }}
            >
              {isFirstStep ? null : ( // Conditionally render the "Back" button
                <div
                  className="button-passenger"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Controls.Button
                    onClick={handleBack}
                    type="submit"
                    text="السابق"
                  />
                </div>
              )}

              {isLastStep ? (
                <div
                  className="button-passenger"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Controls.Button
                    onClick={handleSubmit}
                    type="submit"
                    text="تبديل"
                  />
                </div>
              ) : (
                <div
                  className="button-passenger"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Controls.Button
                    onClick={handleNext}
                    type="submit"
                    text="التالي"
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        </Box>
      </Grid>
    </Grid>
  );
}

export default StepPassengerSwap;
