import React, { useState, useEffect, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { UpdateEmployee } from "../../redux/employeeSlice";
import * as sweetalert from "sweetalert";
import { fetchAllRoles } from "../../redux/RolesSlice";

export default function EmployeeFormEdit(props) {
  const dispatch = useDispatch();

  const { EmployeeiesByID, loadingUpdate } = useSelector(
    (state) => state.Employee
  );
  const [Addimage, setAddimage] = useState(false);
  const { Roles } = useSelector((state) => state.Roles);
  const { addOrEdit, recordForEdit } = props;
  const genderItems = [
    { id: "male", title: "Male" },
    { id: "female", title: "Female" },
  ];
  const initialFValues = {
    id: EmployeeiesByID.user_id,
    email: EmployeeiesByID.email,
    first_name: EmployeeiesByID.first_name,
    last_name: EmployeeiesByID.last_name,
    national_id: EmployeeiesByID.national_id,
    birth_date: EmployeeiesByID.birth_date,
    birth_place: EmployeeiesByID.birth_place,
    gender: EmployeeiesByID.gender,
    image: EmployeeiesByID.image,
    id_roles: EmployeeiesByID.id_roles,
  };

  useEffect(() => {
    if (EmployeeiesByID.id === props.id_employee) {
      setValues({
        ...initialFValues,
        id: EmployeeiesByID.user_id,
        email: EmployeeiesByID.email,
        first_name: EmployeeiesByID.first_name,
        last_name: EmployeeiesByID.last_name,
        national_id: EmployeeiesByID.national_id,
        birth_date: EmployeeiesByID.birth_date,
        birth_place: EmployeeiesByID.birth_place,
        gender: EmployeeiesByID.gender,
        image: EmployeeiesByID.image,
        id_roles: EmployeeiesByID.id_roles,
      });
    }
  }, [
    EmployeeiesByID,
    props.id_employee,
    EmployeeiesByID.gender,
    EmployeeiesByID.roles,
  ]);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues)
      temp.first_name = fieldValues.first_name ? "" : "هذا الحقل مطلوب";
    if ("last_name" in fieldValues)
      temp.last_name = fieldValues.last_name ? "" : "هذا الحقل مطلوب";
    if ("birth_place" in fieldValues)
      temp.birth_place = fieldValues.birth_place ? "" : "هذا الحقل مطلوب";
    if ("gender" in fieldValues)
      temp.gender = fieldValues.gender ? "" : "هذا الحقل مطلوب";
    if ("birth_date" in fieldValues)
      temp.birth_date = fieldValues.birth_date ? "" : "هذا الحقل مطلوب";
    if ("national_id" in fieldValues)
      temp.national_id = fieldValues.national_id ? "" : "هذا الحقل مطلوب";

    if ("email" in fieldValues) {
      if (fieldValues.email) {
        temp.email = /$^|.+@.+..+/.test(fieldValues.email)
          ? ""
          : "البريد الإلكتروني غير صالح"; // Validation for email format
      } else {
        temp.email = "هذا الحقل مطلوب"; // Email field is required
      }
    }
    if ("password" in fieldValues) {
      temp.password =
        fieldValues.password.length >= 8
          ? ""
          : "كلمة المرور يجب أن تحتوي على 8 أحرف على الأقل";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);
  const [file, setFile] = useState("");
  const [file1, setFil1] = useState(values.logo);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        if (Addimage === true) {
          const formData = new FormData();
          formData.append("image", file);
          formData.append("id", EmployeeiesByID.user_id);
          formData.append("email", values.email);
          formData.append("first_name", values.first_name);
          formData.append("last_name", values.last_name);
          formData.append("national_id", values.national_id);
          formData.append("birth_date", values.birth_date);
          formData.append("birth_place", values.birth_place);
          formData.append("gender", values.gender);
          formData.append("id_roles", values.id_roles);
          dispatch(UpdateEmployee(formData));
        } else if (Addimage === false) {
          const formData = new FormData();
          formData.append("id", EmployeeiesByID.user_id);
          for (let key in values) {
            if (key !== "id" && key !== "image") {
              formData.append(key, values[key]);
            }
          }

          dispatch(UpdateEmployee(formData));
        }

        // Reset form or perform any additional actions
      } catch (error) {
        // Error: Handle the rejection
        sweetalert(error.data[0], {
          className: "alert-div",
          button: {
            text: "OK",
          },
        });
      }
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);
  const handleImageChange = (e) => {
    setValues({ ...values, image: e.target.files[0] });
    setFile(e.target.files[0]);
    const selectedFile = e.target.files[0];
    setFil1(URL.createObjectURL(selectedFile));
    setAddimage(true);
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Controls.Input
          className="tesr"
          InputLabelProps={{ shrink: true }} // Add this line
          name="first_name"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/profile-circle.svg"></img>
                </IconButton>
                <span className="placeholdertitle">الاسم الاول</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.first_name}
          onChange={handleInputChange}
          error={errors.first_name}
        />

        <Controls.Input
          className="tesr"
          InputLabelProps={{ shrink: true }} // Add this line
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/profile-circle.svg"></img>
                </IconButton>
                <span className="placeholdertitle">الاسم الاخير</span>
              </InputAdornment>
            </Fragment>
          }
          name="last_name"
          value={values.last_name}
          onChange={handleInputChange}
          error={errors.last_name}
        />
        <Controls.Input
          className="tesr"
          InputLabelProps={{ shrink: true }} // Add this line
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/profile-circle.svg"></img>
                </IconButton>
                <span className="placeholdertitle">البريد الالكتروني</span>
              </InputAdornment>
            </Fragment>
          }
          name="email"
          value={values.email}
          onChange={handleInputChange}
          error={errors.email}
        />
        <Controls.Input
          className="tesr"
          InputLabelProps={{ shrink: true }} // Add this line
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/profile-circle.svg"></img>
                </IconButton>
                <span className="placeholdertitle">الرقم الوطني</span>
              </InputAdornment>
            </Fragment>
          }
          name="national_id"
          value={values.national_id}
          onChange={handleInputChange}
          error={errors.national_id}
        />
        <Controls.Input
          className="tesr"
          InputLabelProps={{ shrink: true }} // Add this line
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/profile-circle.svg"></img>
                </IconButton>
                <span className="placeholdertitle">مكان الولادة</span>
              </InputAdornment>
            </Fragment>
          }
          name="birth_place"
          value={values.birth_place}
          onChange={handleInputChange}
          error={errors.birth_place}
        />
        <Controls.Input
          className="tesr"
          InputLabelProps={{ shrink: true }} // Add this line
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/profile-circle.svg"></img>
                </IconButton>
                <span className="placeholdertitle">تاريخ الولادة</span>
              </InputAdornment>
            </Fragment>
          }
          type="date"
          name="birth_date"
          value={values.birth_date}
          onChange={handleInputChange}
          error={errors.birth_date}
        />

        <Controls.SelectGender
          className="tesr"
          name="gender"
          InputLabelProps={{ shrink: true }} // Add this line
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/profile-circle.svg"></img>
                </IconButton>
                <span className="placeholdertitle">الجنس</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.gender || ""}
          onChange={handleInputChange}
          options={genderItems}
          error={errors.gender}
        />
        <Controls.Select
          className="tesr"
          name="id_roles"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/briefcase.svg"></img>
                </IconButton>
                <span className="placeholdertitle">دور الموظف</span>
              </InputAdornment>
            </Fragment>
          }
          value={initialFValues.id_roles}
          onChange={handleInputChange}
          options={Roles || []}
          error={errors.id_roles}
        />
        <Grid item xs={12}>
          <Controls.Input
            InputLabelProps={{ shrink: true }} // Add this line
            type="file"
            name="image"
            className="logo"
            label={
              <Fragment>
                <InputAdornment position="end">
                  <IconButton
                    className="iconplaceholder"
                    aria-label="upload image"
                    edge="end"
                  >
                    <img src="img/emp/profile-circle.svg"></img>
                  </IconButton>
                  <span className="placeholdertitle">الصورة</span>
                </InputAdornment>
              </Fragment>
            }
            values={file}
            onChange={handleImageChange}
            error={errors.image}
            inputProps={{
              accept: "image/*",
            }}
          />
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "10px",
            width: "100%",
          }}
        >
          <div style={{ width: "100px", height: "100px" }}>
            {file ? (
              <img
                src={file1}
                alt="company-image"
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <img
                src={values.image}
                alt="company-image"
                style={{ width: "100%", height: "100%" }}
              />
            )}
          </div>
        </div>
        {loadingUpdate ? (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="load loadingupdate"
              style={{ background: "#F9B917", borderRaduis: "10px" }}
            >
              <div v-if="loading" class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Controls.Button type="submit" text="تعديل" />
          </div>
        )}
      </Grid>
    </Form>
  );
}
