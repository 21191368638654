import React, { useState, useEffect } from "react";
import Card from "./Card";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../component/Header";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { Checkbox, Select, MenuItem } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { Box, Typography, useTheme, Button } from "@mui/material";
import Popup from "../../component/Popup";
import EmployeeForm from "./NotifcationAddForm.js";
import {
  GridColDef,
  GridFooterContainer,
  GridValueGetterParams,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridPagination,
} from "@mui/x-data-grid";

import SearchBar from "material-ui-search-bar";
import MainButton from "../../component/MainButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAllNotifcations } from "../../redux/notificationslice";
import NotifcationAddForm from "./NotifcationAddForm.js";
import PopupAdmin from "../../component/PopupAdmin.js";
const useStyles = makeStyles({
  gridDivide2: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    marginLeft: '2%',
    "& .MuiPaper-rounded": {
      borderRadius: "0 !important",
    },
  },
  "@media (min-width: 768px) and (max-width:1366px)": {
    gridDivide2: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginLeft: '2%'
    },
    searchDesign: {
      width: "100%",
    },
  },
  "@media (min-width: 768px) and (max-width:992px)": {
    gridDivide: {
      justifyContent: "space-around",
      alignItems: "space-around",
      width: "70vw !important",
    },
  },
  "@media (min-width: 992px) and (max-width:1280px)": {
    gridDivide: {
      justifyContent: "space-around",
      alignItems: "space-around",
      width: "75vw !important",
    },
  },
  "@media (min-width: 1280px) and (max-width:1556px)": {
    gridDivide: {
      justifyContent: "space-around",
      alignItems: "space-around",
      width: "80vw !important",
    },
  },
  mt: {
    marginTop: "30px",
  },

  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    // width: '30%',
    height: "45px !important",
    marginBottom: "20px !important",
    paddingRight: "8px",
    flexDirection: "row-reverse",
  },
  searchDesign1: {
    marginBottom: "20px !important",
    height: "45px !important",
  },

  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  contentDivide: {
    width: "100%",
    marginBottom: "40px",
  },
  Marginbox1: {
    marginBottom: "20px !important",
  },
  AddDesign: {
    marginBottom: "20px",
  },
});

const Notifcation1 = ({ mylist }) => {
  const { Notifcations,loadingAdd } = useSelector((state) => state.Notifcations);
  const { user } = useSelector((state) => state.auth);
  //Add Permission

  const Addnotifcation =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "add.notification"
    );

  // Permission as default
  const Addnotifcationdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "add.notification"
    );

   

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllNotifcations());
  }, [dispatch]);
  const classes = useStyles();
  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Set the initial page to 1
  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  ];
  const [filteredData, setFilteredData] = useState(rows);
  const [filter, setFilter] = useState(true);
  const [age, setAge] = useState("all");
  const [searched, setSearched] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  useEffect(() => {
    setFilteredData(age === "all" ? rows : rows.filter((dt) => dt.age === age));
  }, [age]);
  const requestSearch = (searchedVal) => {
    const filteredRows = filteredData.filter((row) => {
      return row.lastName.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setFilteredData(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const onHandleReset = () => {
    setFilteredData(rows);
  };

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <Grid className={classes.gridContainer} item lg={12}>

      <Grid
        container
        spacing={4}
        className={classes.gridContainer}
        justify="center"
      >
        <Grid container xs={12} className={classes.Marginbox1} style={{display:'flex', width:'100%', justifyContent:'flex-end'}}>

          {Addnotifcation === true || Addnotifcationdefault === true ? (
            <Grid lg={2} md={4} sm={3} className={classes.AddDesign} >
              <div className="bottonFullwidth1">
                <Controls.Button
                  type="submit"
                  text="إضافة"
                  onClick={() => {
                    setOpenPopup(true);
                    setRecordForEdit(null);
                  }}
                />
              </div>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <PopupAdmin
          title="إنشاء إشعار"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <NotifcationAddForm
            recordForEdit={recordForEdit}
          />
        </PopupAdmin>

        <Grid className={classes.gridDivide2}>
          <Card
            Notifcations={Notifcations}
            startIndex={startIndex}
            endIndex={endIndex}
          />
        </Grid>
        <Pagination
          count={Math.ceil(Notifcations.length / itemsPerPage)}
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
          className={classes.mt}
        />
      </Grid>
    </Grid>
  );
};
export default Notifcation1;
