import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

export default function SelectTypeTripCart3(props) {
  const {
    isOpen2,
    setIsOpen2,
    name,
    label,
    value,
    error = null,
    onChange,
    options,
  } = props;

  return (
    <FormControl
      variant="outlined"
      {...(error && { error: true })}
      className="add-passenger"
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        className="selectradius"
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        open={isOpen2}
        onClose={() => {
          setIsOpen2(false);
        }}
        onOpen={() => {
          setIsOpen2(true);
        }}
      >
        {options.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            {item.title}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
