import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Popup from "../../component/Popup";
import EmployeeFormPassword from "./EmployeeFormPassword";
import ConfirmDialog from "../../component/ConfirmDialogCompany";
import { useDispatch } from "react-redux";
import {
  HarddeleteEmployeeById,
  RestoreEmployee,
  deleteEmployeeById,
  fetchAllTrashedSystemUser,
  fetchEmployeeByID,
} from "../../redux/employeeSlice";
import EmployeeFormEdit from "./EmployeeEdit";
import { useSelector } from "react-redux";
const useStyles = makeStyles({
  root: {
    minWidth: 200,
  },
  "@media (min-width: 768px) and (max-width:1366px)": {
    root: {
      marginBottom: "20px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 !important",
    position: "relative",
  },
  media: {
    width: 126,
    height: 126,
    marginTop: "15px",
  },
  title: {
    fontFamily: "beIN Normal",
    fontSize: 27,
    color: "#000002",
    fontWeight: "normal",
  },
});

const CardTrashedAdmin = ({ startIndex, endIndex, TrashedEmployeeies }) => {
  const { user } = useSelector((state) => state.auth);
  //Add Permission
  const RestorEmpoyee =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "restore.systemUser"
    );

 
  const HardDeleteEmpoyee =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "hard.delete.systemUser"
    );


  // Permission as default
  const RestorEmpoyeedefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "restore.systemUser"
    );

    
  const HardDeleteEmpoyeedefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "hard.delete.systemUser"
    );
    

  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllTrashedSystemUser());
  }, [dispatch]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopupPassword, setOpenPopupPassword] = useState(false);
  const [openPopupEdit, setOpenPopupEdit] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [clickedEmployeeId, setClickedEmployeeId] = useState(null);
  const [openPopup1, setOpenPopup1] = useState(false);
  const { loadingdelete } = useSelector((state) => state.Employee);

  const handleEdit = useCallback(
    (id) => {
      setClickedEmployeeId(id);
      dispatch(RestoreEmployee(id));
    },
    [TrashedEmployeeies]
  );

  function handleDelete() {
    setConfirmDialog({
      isOpen: true,
      title: "أنت على وشك حذف الموظف بشكل نهائي",
      img: "img/emp/Inbox cleanup-rafiki.svg",
      onConfirm: () => {
        dispatch(HarddeleteEmployeeById(clickedEmployeeId));
      },
    });
  }
  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <>
      {TrashedEmployeeies.slice(startIndex, endIndex).map((emp) => (
        <Card className={classes.root} variant="outlined" key={emp.user_id}>
          <CardContent className={classes.content}>
            {HardDeleteEmpoyee === true ||
            HardDeleteEmpoyeedefault === true ||
            RestorEmpoyee === true ||
            RestorEmpoyeedefault === true ? (
              <div className="card-menu">
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(event) => {
                    setOpenPopup1(true);
                    setRecordForEdit(null);
                    setClickedEmployeeId(emp.user_id);
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <img src="img/More icon.svg" />
                </Button>

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className="testtt"
                >
                  {RestorEmpoyee === true || RestorEmpoyeedefault === true ? (
                    <MenuItem
                      className="menuStyle"
                      onClick={() => handleEdit(clickedEmployeeId)}
                    >
                      <img src="img/Path 17091.svg" />
                      <span>استرجاع</span>
                    </MenuItem>
                  ) : (
                    <></>
                  )}

                  {HardDeleteEmpoyee === true ||
                  HardDeleteEmpoyeedefault === true ? (
                    <MenuItem
                      className="menuStyle"
                      onClick={() => handleDelete(clickedEmployeeId)}
                    >
                      <img src="img/Path 17089.svg" />
                      <span>حذف</span>
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                </Menu>

                <ConfirmDialog
                  confirmDialog={confirmDialog}
                  setConfirmDialog={setConfirmDialog}
                  loadingdelete={loadingdelete}
                />
              </div>
            ) : (
              <></>
            )}
            {/* ======================= */}
            <CardMedia title="Contemplative Reptile">
              <img
                src={emp.image}
                className={classes.media}
                alt="employee_img"
              />
            </CardMedia>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {emp.name}
            </Typography>
            <div className="body-container">
              <div className="divtitleone">
                <img src="img/apartment_FILL0_wght400_GRAD0_opsz48 (2).svg" />
                <span> اسم الموظف: {emp.first_name}</span>
              </div>
              <div className="divtitletwo">
                <img src="img/vpn_key_FILL0_wght400_GRAD0_opsz48.svg" />
                <span>رقم الموظف :{emp.user_id}</span>
              </div>
              <div className="divtitlethree">
                <img src="img/badge_FILL0_wght400_GRAD0_opsz48 (1).svg" />
                <span>دور الموظف : {emp.roles}</span>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </>
  );
};
export default CardTrashedAdmin;
