import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core";

export function useFormStop(initialFValues, validateOnChange = false, validate) {


    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState({});

    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        if (validateOnChange)
            validate({ [name]: value })
    }

    const resetForm = () => {
        setValues(initialFValues);
        setErrors({})
    }


    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm

    }
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiOutlinedInput-root": {
            borderRaduis:'8px !important'
          },
        '& .MuiFormControl-root': {
            width: '100% !important',
            marginBottom: '48px',
        },
        "& .MuiOutlinedInput-root:hover" :{},
        "& .MuiOutlinedInput-notchedOutline":{
            border:'1px solid #fff !important',
            direction :'rtl',
            
        },
        "& .MuiInputLabel-outlined": {
            transform: 'translate(0px, 27px) scale(1) !important',
        },
        "& .MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(1) !important',
            fontSize:'10px !important'
        },
        "& .MuiOutlinedInput-input :focus": {
            outline:'0',
            display: 'block',
            padding:'0',
            margin:'0',
         
        },
      
        
     
    }
}))

export function FormStop(props) {

    const classes = useStyles();
    const { children, ...other } = props;
    return (
        <form className={classes.root} autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}

