import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import { useNavigate } from "react-router-dom";
import Rating from '@mui/material/Rating';
const useStyles = makeStyles({
  root: {
    minWidth: 200,
  },
  "@media (min-width: 768px) and (max-width:1366px)": {
    root: {
      marginBottom: "20px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  media: {
    width: 50,
    height: 50,
  },
  title: {
    fontFamily: "beIN Normal",
    fontSize: 20,
    color: "#000002",
    fontWeight: 200,
  },
});
const mylist = [
  {
    name: "اسم المستخدم",
    img: "img/rating1.svg",
    value:3.5,
  },
  {
    name: "اسم المستخدم",
    img: "img/rating1.svg",
    value:5,
  },
  {
    name: "اسم المستخدم",
    img: "img/rating1.svg",
    value:0,
  },
  {
    name: "اسم المستخدم",
    img: "img/rating1.svg",
    value:4,
  },
  {
    name: "اسم المستخدم",
    img: "img/rating1.svg",
    value:3,
  },
  {
    name: "اسم المستخدم",
    img: "img/rating1.svg",
    value:2.5,
  },
  {
    name: "اسم المستخدم",
    img: "img/rating1.svg",
    value:2.5,
  },
  {
    name: "اسم المستخدم",
    img: "img/rating1.svg",
    value:2.5,
  },
  {
    name: "اسم المستخدم",
    img: "img/rating1.svg",
    value:2.5,
  },
  {
    name: "اسم المستخدم",
    img: "img/rating1.svg",
    value:2.5,
  },
  {
    name: "اسم المستخدم",
    img: "img/rating1.svg",
    value:2.5,
  },
  {
    name: "اسم المستخدم",
    img: "img/rating1.svg",
    value:2.5,
  },
];
const OutlinedCard = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const bull = <span className={classes.bullet}>•</span>;
  const [value, setValue] = useState(3.5);
  return (
    <>
      {mylist.map((e) => (
        <Card
          className={classes.root}
          variant="outlined"
        >
          <CardContent className={classes.content}>
            <CardMedia title="Contemplative Reptile">
              <img src={e.img} className={classes.media} />
            </CardMedia>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {e.name}
            </Typography>
            <Rating
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
          </CardContent>
        </Card>
      ))}
    </>
  );
};
export default OutlinedCard;
