import React from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainButton: {
    background: "#F9B917 !important",
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    fontFamily:"beIN"
  },
}));
const MainButton = ({ title,  background,onClick ,disabled}) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      className={classes.mainButton}
      background={background}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};

export default MainButton;
