import React, { Fragment, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAllTicket, insertTicketPrice } from "../../redux/TickeType";
import { insertTripType } from "../../redux/tripTypesSlice";

export default function TypeTripPriceForm(props) {
  const dispatch = useDispatch();
  const { InsertTripType, loadingAddTriptype } = useSelector(
    (state) => state.TripType
  );
  const { Tickets } = useSelector((state) => state.Ticket);
  const [initialFValues, setInitialFValues] = useState({
    tickets: {},
  });
  const datatrip = props.dataTriptype;
  useEffect(() => {
    dispatch(fetchAllTicket(props.id_company));
  }, []);
  useEffect(() => {
    if (Tickets) {
      const updatedTickets = {};
      Tickets.forEach((ticket) => {
        const defaultPrice = "0";
        const defaultReduction = "0";

        updatedTickets[ticket.id] = {
          price: defaultPrice,
          reduction: defaultReduction,
        };
      });

      setInitialFValues({ tickets: updatedTickets });
    }
  }, [Tickets]);

  const { values, setValues, errors, setErrors } = useForm(
    initialFValues,
    true
  );
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Extract ticket id from the name attribute
    const [, ticketId, field] = name.match(/^price\.(\d+)\.(\w+)$/);

    // Update initialFValues with the new price/reduction
    setInitialFValues((prevValues) => ({
      tickets: {
        ...prevValues.tickets,
        [ticketId]: {
          ...prevValues.tickets[ticketId],
          [field]: value,
        },
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedTickets = Object.keys(initialFValues.tickets).map(
      (ticketId) => {
        const ticket = initialFValues.tickets[ticketId];
        const price = ticket.price || "0";
        const reduction = ticket.reduction || "0";
        return {
          id_ticket: parseInt(ticketId),
          price: parseFloat(price),
          reduction: parseFloat(reduction),
        };
      }
    );

    const dataToSend = {
      id_company: datatrip.id_company,
      id_path: datatrip.id_path,
      type: datatrip.type,
      // number_hours_before_cancellation:
      //   datatrip.number_hours_before_cancellation,
      // return_reduction_upon_cancellation:
      //   datatrip.return_reduction_upon_cancellation,
      // price: "0",
      tickets: formattedTickets,
    };
    dispatch(insertTripType(dataToSend));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Grid lg={12} md={12}>
          {Tickets &&
            Tickets.map((ticket) => (
              <Grid
                key={ticket.id}
                item
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                }}
                lg={12}
                md={12}
              >
                <Grid
                  lg={3}
                  md={3}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "13px",
                  }}
                >
                  نوع التذكرة : {ticket.title}
                </Grid>
                <Grid lg={4} md={4}>
                  <Controls.Input
                    className="tesr price-ticket-trip-ticket"
                    name={`price.${ticket.id}.price`}
                    label={
                      <Fragment>
                        <InputAdornment position="end">
                          <span className="placeholdertitle">سعر التذكرة</span>
                        </InputAdornment>
                      </Fragment>
                    }
                    value={initialFValues.tickets[ticket.id]?.price || 0}
                    onChange={handleInputChange}
                    error={errors.price}
                  />
                </Grid>
                <Grid lg={4} md={4}>
                  <Controls.Input
                    className="tesr price-ticket-trip-ticket"
                    name={`price.${ticket.id}.reduction`}
                    label={
                      <Fragment>
                        <InputAdornment position="end">
                          <span className="placeholdertitle">سعر القطع</span>
                        </InputAdornment>
                      </Fragment>
                    }
                    value={initialFValues.tickets[ticket.id]?.reduction || 0}
                    onChange={handleInputChange}
                    error={errors.reduction}
                  />
                </Grid>
              </Grid>
            ))}
          <div
            className="button-passenger"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            {loadingAddTriptype ? (
              <div
                className="bottonFullwidth"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="load loadingupdate"
                  style={{ background: "#F9B917", borderRaduis: "10px" }}
                >
                  <div v-if="loading" class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="bottonFullwidth"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Controls.Button
                  onClick={handleSubmit}
                  type="submit"
                  text="إضافة"
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </Form>
  );
}
