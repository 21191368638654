import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import packageJson from "../../package.json";
import * as sweetalert from "sweetalert";
import { fetchTripBYId } from './tripsSlice';
export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchSetsBus = createAsyncThunk(
  'SetsBus /fetchSetsBus',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/seatsStatus/${id}`, { withCredentials: true });
      const result = await res.data.data;

      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const insertSeatStatus = createAsyncThunk(
  'SetsBus/insertSeatStatus',
  async (values, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    try {

      if (values.status == 4) {
        const response = await axios.post(`${BaseUrl}/changeStatusSeat`, values, { withCredentials: true });
        sweetalert({
          text: "تم حجز  بدون بيانات",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          dispatch(fetchTripBYId(values.tripid));
          localStorage.setItem('changeseat', false);
        });
      }
      else if (values.status == 3) {


        const response = await axios.post(`${BaseUrl}/changeStatusSeat`, values, { withCredentials: true });
        sweetalert({
          text: "تم حجز الكراسي من قبل الادارة",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem('changeseat', false);
          dispatch(fetchTripBYId(values.tripid));
        });
      }
      if (values.status == 5) {
        const response = await axios.post(`${BaseUrl}/changeStatusSeat`, values, { withCredentials: true });

        dispatch(fetchTripBYId(values.tripid));

      }
      if (values.status == 0) {
        const response = await axios.post(`${BaseUrl}/changeStatusSeat`, values, { withCredentials: true });
        sweetalert({
          text: response.data.message,
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem('changeseat', false);
        });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        })
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("An error occurred");
      }
    }
  }
);
export const insertSeatStatuszero = createAsyncThunk(
  'SetsBus/insertSeatStatus',
  async (values, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    try {

      if (values.status == 5) {
        const response = await axios.post(`${BaseUrl}/changeStatusSeat`, values, { withCredentials: true });

      }
      if (values.status == 0) {
        const response = await axios.post(`${BaseUrl}/changeStatusSeat`, values, { withCredentials: true });

        localStorage.setItem('changeseat', false);

      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        })
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("An error occurred");
      }
    }
  }
);
export const insertSeatStatusselect = createAsyncThunk(
  'SetsBus/insertSeatStatusselect',
  async (values, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    try {


      const response = await axios.post(`${BaseUrl}/changeStatusSeat`, values, { withCredentials: true });


    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        })
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("An error occurred");
      }
    }
  }
);
export const insertSeatTransit = createAsyncThunk(
  'SetsBus/insertSeatTransit',
  async (values, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    const { callback } = values; // Extract the callback function from the values
    localStorage.setItem("changeseat", true);
    try {
      const response = await axios.post(`${BaseUrl}/SeatsTrip`, values, { withCredentials: true });
      sweetalert({
        text: "تم  إضافة الكراسي إلى رحلات  العبور",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      })
        .then(() => {
          dispatch(fetchTripBYId(values.id_trip));
          if (callback) {
            callback(); // Call the callback function if it's provided
          }
        });
      localStorage.setItem("changeseat", false);
    } catch (error) {
      localStorage.setItem("changeseat", false);
      if (error.response && error.response.data && error.response.data.message) {
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        })
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("An error occurred");
      }
    }
  }
);
export const insertSeatTransittodirect = createAsyncThunk(
  'SetsBus/insertSeatTransit',
  async (values, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    const { callback } = values; // Extract the callback function from the values
    localStorage.setItem("changeseat", true);

    try {
      const response = await axios.post(`${BaseUrl}/SeatsTrip`, values, { withCredentials: true });
      sweetalert({
        text: "تم إضافة الكراسي إلى الرحلة الاساسية",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        dispatch(fetchTripBYId(values.id_trip));

        if (callback) {
          callback(); // Call the callback function if it's provided
        }
      });
      localStorage.setItem("changeseat", false);

    } catch (error) {
      localStorage.setItem("changeseat", false);

      if (error.response && error.response.data && error.response.data.message) {
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        })
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("An error occurred");
      }
    }
  }
);



const SetsBusSlice = createSlice({
  name: 'Setsbus',
  initialState: { SetsBus: [], SeatStatus: [], SeatTransit: [], loading: false, error: null },
  reducers: {
    clearSetsbus: (state) => {
      state.SetsBus = [];
      state.SeatStatus = [];
      state.SeatTransit = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: {
    //fetch
    [fetchSetsBus.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchSetsBus.fulfilled]: (state, action) => {
      state.SetsBus = action.payload;
      state.loading = false;
    },
    [fetchSetsBus.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //insert
    [insertSeatStatus.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [insertSeatStatus.fulfilled]: (state, action) => {
      state.SeatStatus = action.payload;
      state.loading = false;
    },
    [insertSeatStatus.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [insertSeatStatusselect.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [insertSeatStatusselect.fulfilled]: (state, action) => {
      state.SeatStatus = action.payload;
      state.loading = false;
    },
    [insertSeatStatusselect.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //insert
    [insertSeatTransit.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [insertSeatTransit.fulfilled]: (state, action) => {
      state.SeatTransit = action.payload;
      state.loading = false;
    },
    [insertSeatTransit.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },


  },
});
export const { clearSetsbus } = SetsBusSlice.actions;
export default SetsBusSlice.reducer;
