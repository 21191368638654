import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";
export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const insertCompanyEmpBranch = createAsyncThunk(
  "CompanyEmpBranch/insertCompanyEmpBranch",
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/addCompanyEmpBranch`, values, {
        withCredentials: true,
      });
      sweetalert({
        text: "تم التعديل بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchCompanyEmpBranch = createAsyncThunk(
  "CompanyEmpBranch/CompanyEmpBranch",
  async (id, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;

    try {
      const res = await axios.get(`${BaseUrl}/getBranchCompanyEmp/${id}`, {
        withCredentials: true,
      });
      const result = await res.data.data;

      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const CompanyEmpBranchSlice = createSlice({
  name: "CompanyEmpBranch",
  initialState: {
    CompanyEmpBranch: [],
    CompanyEmpBranchID: [],
    loading: false,
    loadingAdd: false,
    error: null,
  },
  reducers: {
    clearCompanyEmpBranch: (state) => {
      state.CompanyEmpBranch = [];
      state.CompanyEmpBranchID = [];
      state.loading = false;
      state.loadingAdd = false;
      state.error = null;
    },
  },
  extraReducers: {
    //insert Branch Employee
    [insertCompanyEmpBranch.pending]: (state, action) => {
      state.loadingAdd = true;
      state.error = null;
    },
    [insertCompanyEmpBranch.fulfilled]: (state, action) => {
      state.CompanyEmpBranch.push(action.payload);
      state.loadingAdd = false;
    },
    [insertCompanyEmpBranch.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingAdd = false;
    },
    //Fetch Branch Employee By ID
    [fetchCompanyEmpBranch.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchCompanyEmpBranch.fulfilled]: (state, action) => {
      state.CompanyEmpBranchID = action.payload;
      state.loading = false;
    },
    [fetchCompanyEmpBranch.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export const { clearCompanyEmpBranch } = CompanyEmpBranchSlice.actions;
export default CompanyEmpBranchSlice.reducer;
