import { Box, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import "./StepPassenger1.css";
import PassengerAdultDeleted from "./PassengerAdultDeleted";
import Controls from "../../component/controls/Controls";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  insertPassengerToReservation,
  updateTPassengerToReservation,
} from "../../redux/PasssengersTrip";
import { useSelector } from "react-redux";
import packageJson from "../../../package.json";
import axios from "axios";
import * as sweetalert from "sweetalert";
import { fetchTripBYId } from "../../redux/tripsSlice";
export const BaseUrl = packageJson.projectURlApi.BaseUrl;

export const key_Shortcut = packageJson.key_Shortcut;
function StepPassengerDeleted({
  updatePassenger,
  id_trip_type,
  setUpdatePassenger,
  onConfiarm,
  numberpassenger,
  id_company,
  id_trip,
  reservationIds,
  direct,
  transet,
  setstatus,
  updateSeat,
  setUpdateSeat,
  reservationss,
  setReservationseat1,
  setResponse1,
  response1,
  idDirect,
  setArraySeat,
  arrayseat,
  idReservationresponse,
  setSelectedSeats1,
  setSelectedSeats,
  setSelectedSeats2,
  setSeatAndUser,
  setArraySeatPassengerTransit1,
  arrayseatPassengerTransit1,
  setArraySeatPassengerTransit2,
  arrayseatPassengerTransit2,
  selectedTransitIndex,
  setArrayReservations,
  arrayReservations,
  setIdTicketPrice,
  idTricketPrice,
  idTransit,
  resultArray,
  valueinput,
  settranset,
  setDirect,
  reservationseat1,
  setResultArray,
  setInputString,
  setValueinput,
  selectedSeats,
  selectedSeats2,
  selectedSeats1,
  setOpenPopuppassenger2,
  setOpenPopup
}) {
  const dispatch = useDispatch();
  let insertreservation1 = idReservationresponse;
  const child = 0;
  const adults = numberpassenger;
  const { TripID } = useSelector((state) => state.Trip);

  const steps = [];
  for (let i = 1; i <= numberpassenger.length; i++) {
    steps.push({
      id: `${i}`,
      label: `الحجز للمسافر البالغ رقم ${i}`,
      img1: "img/Circul.png",
      img2: "img/replacestep/Group 10438.svg",
      route: `/step-create-course/${i}`,
      isChild: false,
    });
  }

  // Add steps for children
  for (let i = 1; i <= child; i++) {
    steps.push({
      id: `${i}`,
      label: `الحجز للطفل رقم ${i}`,
      img1: "img/Circul.png",
      route: `/step-create-course/child/${i}`,
      isChild: true,
    });
  }

  const initialFValues = {
    // id_reservation:
    // insertreservation1.id || insertreservation1[0]?.reservationId,
    adults: [],
    children: [],
  };
  
  for (let i = 0; i < adults.length; i++) {
    const seatNumber = numberpassenger[i];

    if (direct == true) {
      initialFValues.adults.push({
        id: i + 1,
        code_seat: "",
        number_seat: seatNumber,
        typeReduction: "",
        id_price_ticket: "",
      });
    }
    if (transet == true) {
      initialFValues.adults.push({
        id: i + 1,
        code_seat: "",
        number_seat: seatNumber,
        typeReduction: "",
        id_price_ticket: "",
      });
    }
  }

  const [activeStep1, setactiveStep1] = React.useState(
    JSON.parse(localStorage.getItem("activeStep1")) || 1
  );
  const handleKeyDown = (event) => {
    if (
      event.key === key_Shortcut.next_passenger &&
      activeStep1 !== steps.length
    ) {
      setactiveStep1((prevactiveStep1) => prevactiveStep1 + 1);
    } else if (
      event.key === key_Shortcut.previous_passenger &&
      activeStep1 !== 1
    ) {
      setactiveStep1((prevactiveStep1) => prevactiveStep1 - 1);
    }
  };
  const passengers = JSON.parse(localStorage.getItem("passengers"));
  useEffect(() => {
  
    if (passengers && Array.isArray(passengers)) {
      // This array will hold objects each containing id_price_ticket, number_seat, and price
      const passengerDetails = passengers.map((passenger) => ({
        id_price_ticket: passenger.id_price_ticket,
        number_seat: passenger.number_seat,
        id_trip: passenger.id_trip,
      }));

      // Log the details to see the output

      // Set these details into state or perform other actions
      setIdTicketPrice(passengerDetails); // Assuming setIdTicketPrice can now accept an array of objects
    } else {
    }
  }, [passengers]);

  useEffect(() => {}, [response1]);
  useEffect(() => {
    setIdTicketPrice(idTricketPrice);
  }, [idTricketPrice]);
  useEffect(() => {
    setArraySeat(arrayseat);
  }, [arrayseat]);
  useEffect(() => {
    setArrayReservations(arrayReservations);
  }, [arrayReservations]);
  useEffect(() => {
    setArraySeatPassengerTransit1(arrayseatPassengerTransit1);
  }, [arrayseatPassengerTransit1]);
  useEffect(() => {
    setArraySeatPassengerTransit2(arrayseatPassengerTransit2);
  }, [arrayseatPassengerTransit2]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep1, steps.length]);
  useEffect(() => {
    document.addEventListener("didClose", () => {
      const confirmButton = document.querySelector(".swal-button--confirm");
      if (confirmButton) {
        confirmButton.focus();
      }
    });
  });

  const handleKeyDown2 = async (event) => {
    if (event.ctrlKey && event.key === "8") {
      // setResponse1(response1);
  
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown2);
    return () => {
      window.removeEventListener("keydown", handleKeyDown2);
    };
  }, [dispatch, onConfiarm]);

  const handleSubmit = async (e) => {
    const valueInputArray = Array.isArray(valueinput)
      ? valueinput
      : valueinput.split(/[\s,+]+/);
    const combinedSeats = [
      ...selectedSeats,
      ...selectedSeats1,
      ...selectedSeats2,
      ...valueInputArray,
    ]
      .map((seat) => {
        if (typeof seat === "number") return seat;

        return seat.split(/[\s,+]+/).map(Number);
      })
      .flat();

    const sortedNumbers1 = Array.from(
      new Set(combinedSeats.filter((seat) => !isNaN(seat)))
    );
    const uniqueNumbersWithoutZero = sortedNumbers1.filter(
      (number) => number !== 0
    );
    const seatNumbersToSend2 = uniqueNumbersWithoutZero.sort((a, b) => a - b);

    setResultArray(seatNumbersToSend2);
    const seatNumbersToSend = seatNumbersToSend2;
    const reservationIds1 = [];

    const passengerData = [];
    let directTrip = 0;
    // seatNumbersToSend2.forEach((seat) => {
    const reservationIdDirect = Object.values(arrayReservations).find(
      (reservation) => {
        const seatNumbers = Object.keys(
          reservation.reservation_seats_number || {} // Adding fallback to empty object to ensure it always works
        ).map(Number); // Convert keys to numbers since seat numbers are often numeric
        return seatNumbers.includes(seatNumbersToSend2[0]);
      }
    )?.id;
    // const reservationIdTransit = Array.isArray(TripID.tripTransit)
    //   ? Object.values(arrayReservations)?.reduce((acc, reservation) => {
    //       const seatNumbers = Object.keys(
    //         reservation.reservation_seats_number
    //       ).map(Number);
    //       if (seatNumbers.includes(seatNumbersToSend2[0])) {
    //         acc.push({ reservationId: reservation.id });
    //       }
    //       return acc;
    //     }, [])
    //   : null;

    const reservationIdTransit =
      Array.isArray(TripID.tripTransit) && arrayReservations
        ? Object.values(arrayReservations)?.reduce((acc, reservation) => {
            // Ensure reservation_seats_number is not undefined before proceeding
            if (reservation && reservation.reservation_seats_number) {
              const seatNumbers = Object.keys(
                reservation.reservation_seats_number
              ).map(Number);
              if (seatNumbers.includes(seatNumbersToSend2[0])) {
                acc.push({ reservationId: reservation.id });
              }
            }
            return acc;
          }, [])
        : null;
    // eslint-disable-next-line eqeqeq
    if (idDirect == TripID.id) {
      if (Array.isArray(TripID.tripTransit) && TripID.tripTransit.length > 0) {
        const seatNumbersToSend = seatNumbersToSend2;
        let reservationseat = null;

        // Convert the object keys into an array and then use find
        const reservationSeatKeys = Object.keys(
          TripID.tripTransit[0].reservationSeats
        );
        reservationseat = reservationSeatKeys.find((seat) =>
          seatNumbersToSend2.includes(Number(seat))
        );

        if (seatNumbersToSend2.includes(Number(reservationseat))) {
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "لا يمكن حذف كرسي عبور من رحلة اساسية",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
        } else {
          // Seat not found in transit trip
          const status = response1[seatNumbersToSend2[0]];

          reservationIds1.push({ reservationId1: reservationIdDirect });

          // eslint-disable-next-line eqeqeq
          if (status == 5 || status == 0) {
            localStorage.setItem("changeseat", true);
            sweetalert({
              text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
            // eslint-disable-next-line eqeqeq
          } else if (status == 4) {
            setResultArray([]);
            localStorage.setItem("changeseat", true);
            sweetalert({
              text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
            // eslint-disable-next-line eqeqeq
          } else if (status == 3) {
            setResultArray([]);
            localStorage.setItem("changeseat", true);
            sweetalert({
              text: "Ctrl + 0 لتغيير حالة كرسي ادارة لمتوفر اضغط على  ",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
            // eslint-disable-next-line eqeqeq
          } else if (status == 1) {
            const id_reservation = reservationIds1[0].reservationId1;
            const seat_number = seatNumbersToSend2;
            const passengerDeleteData = {
              id_reservation,
              seat_number,
              branch: TripID.id_branch,
              trip_id: TripID.id,
            };
            axios
              .post(`${BaseUrl}/remove-seat-reservation`, passengerDeleteData, {
                withCredentials: true,
              })
              .then((response) => {
                // Handle the successful response here
                if (response.data.status === 200) {
                  setResponse1(response.data.data.seats);
                  setArraySeat((prevSeatsInfo) => {
                    const updatedSeatsInfo = { ...prevSeatsInfo };
                    const seatNumber = parseInt(seat_number);

                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: {},
                        status: 0,
                        id_reservation: undefined,
                      };
                    }

                    return updatedSeatsInfo;
                  });
                  const mappedById = response.data.data.reservations.reduce(
                    (acc, item) => {
                      acc[item.id] = item;
                      return acc;
                    },
                    {}
                  );
                  setArrayReservations(mappedById);

                  sweetalert({
                    text: response.data.message,
                    icon: "success",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                    localStorage.setItem("openpassenger", false);
                    localStorage.setItem("changeseat", false);
                  });
                }
              });
            // dispatch(deleteSeatToReservation(passengerDeleteData));
            settranset(false);
            setDirect(true);
            setResultArray([]);
            setInputString("");
            setValueinput("");
            setSeatAndUser([]);
            setSelectedSeats([]);
            setSelectedSeats1([]);
            setSelectedSeats2([]);
            // eslint-disable-next-line eqeqeq
          }
           else if (status == 2) {
            const id_reservation = reservationIds1[0].reservationId1;
            const number_seat = seatNumbersToSend2;
            const sortedPassengers = passengers
            .filter(passenger => passenger.code_seat) // Filter out passengers without a code_seat
            .sort((a, b) => a.number_seat - b.number_seat); // Sort by number_seat
        
          // Step 5: Extract code_seat values
          const codeSeats = sortedPassengers.map(passenger => passenger.code_seat);
          const typeReductionSeats = sortedPassengers.map(passenger => passenger.typeReduction);
        
    
            const passengerDeleteData = {
              id_reservation,
              number_seat,
              code_seat:codeSeats,
              typeReduction:typeReductionSeats,
              branch: TripID.id_branch,
              id_trip: TripID.id,
            };
            let passengerDetails = JSON.parse(localStorage.getItem("passengers")).map(passenger => {
              let reductionText = '';
              switch (passenger.typeReduction) {
                  case 'total':
                      reductionText = 'كامل القيمة';
                      break;
                  case 'percentage':
                      reductionText = `نسبة الحسم ${passenger.reduction} `;
                      break;
                  case 'zero':
                      reductionText = '0';
                      break;
                  default:
                      reductionText = 'غير معروف';
              }
            
              return `<div style="margin-bottom: 10px;">
                        <strong>مقعد:</strong> ${passenger.number_seat} -
                        <strong>كود:</strong> ${passenger.code_seat} -
                        <strong>الحسم:</strong> ${reductionText} -
                        <strong>سعر التذكرة:</strong> ${passenger.price}
                      </div>`;
            }).join("");
          
            sweetalert({
              title: "تأكيد الحذف",
              text: "هل أنت متأكد أنك تريد إزالة الراكب/الركاب التالية تفاصيلهم؟",
              content: {
                element: "div",
                
                attributes: {
                  innerHTML: `<div>${passengerDetails}</div>`
                },
              },
              icon: "warning",
              buttons: {
                confirm: {
                    text: "موافق",
                    value: true,
                    className: "custom-button-class",
                },
                cancel: {
                    text: "إلغاء",
                    value: false,
                    visible: true,
                    className: "cancel-button-class",
                },
              },
              dangerMode: true,
              
              className: "custom-sweetalert"
            }).then((value) => {
              if (value) {
            axios
              .post(
                `${BaseUrl}/deletePassengerFromReservation`,
                passengerDeleteData,
                { withCredentials: true }
              )
              .then((response) => {
                if (response.data.status === 200) {
                  setResponse1(response.data.data.seats);
                  setArraySeat((prevSeatsInfo) => {
                    const updatedSeatsInfo = { ...prevSeatsInfo };
                    const seatNumber = parseInt(number_seat);

                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: {},
                        status: 0,
                        id_reservation: undefined,
                      };
                    }

                    return updatedSeatsInfo;
                  });
                  const mappedById = response.data.data.reservation.reduce(
                    (acc, item) => {
                      acc[item.id] = item;
                      return acc;
                    },
                    {}
                  );
                  setArrayReservations(mappedById);
                  // setArrayReservations((prevReservations) => {
                  //   const updatedReservations = { ...prevReservations };
                  //   delete updatedReservations[id_reservation]; // Delete the reservation using its ID
                  //   return updatedReservations;
                  // });
                  // setArrayReservations((prevReservations) => {
                  //   const updatedReservations = JSON.parse(
                  //     JSON.stringify(prevReservations)
                  //   );
                  //   if (updatedReservations[id_reservation]) {
                  //     number_seat.forEach((seatNumber) => {
                  //       delete updatedReservations[id_reservation]
                  //         .reservation_seats_number[seatNumber];
                  //     });
                  //     const passengerIndex = updatedReservations[
                  //       id_reservation
                  //     ].passengers.findIndex(
                  //       (passenger) => passenger.number_seat == seatNumber
                  //     );
                  //     if (passengerIndex !== -1) {
                  //       updatedReservations[id_reservation].passengers.splice(
                  //         passengerIndex,
                  //         1
                  //       );
                  //     }
                  //     // Optionally, if the reservation_seats_number is empty, consider removing or adjusting the reservation itself
                  //     // if (Object.keys(updatedReservations[id_reservation].reservation_seats_number).length === 0) {
                  //     //   delete updatedReservations[id_reservation]; // Or handle it another way
                  //     // }
                  //   }
                  //   return updatedReservations;
                  // });
                  sweetalert({
                    text: response.data.message,
                    icon: "success",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                    localStorage.setItem("openpassenger", false);
                    localStorage.setItem("changeseat", false);
                    setOpenPopup(false);
                    // dispatch(deletePassengerToReservation(passengerDeleteData));
            settranset(false);
            setDirect(true);
            setResultArray([]);
            setInputString("");
            setValueinput("");
            setSeatAndUser([]);
            setSelectedSeats([]);
            setSelectedSeats1([]);
            setSelectedSeats2([]);
                  });
                }
              })
              .catch((error) => {
                // Check if the error response is HTTP 500
                let errorMessage ;
                
                if (error.response && error.response.status === 500) {
                  errorMessage = error.response.data.message || error.response?.data?.data;
                }
                sweetalert({
                  title: "خطأ",
                  text: errorMessage,
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "حسنًا",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                });
                console.error("Error occurred:", error);
                // Optionally reset or clear certain parts of the UI or state
              });
            }
          });

            
          }
        }
      } else if (
        Array.isArray(TripID.tripTransit) &&
        // eslint-disable-next-line eqeqeq
        TripID.tripTransit.length == 0
      ) {
        const seatNumbersToSend = seatNumbersToSend2;
        let reservationseat = null;

        // Seat not found in transit trip
        const status = response1[seatNumbersToSend2[0]];
        reservationIds1.push({ reservationId1: reservationIdDirect });

        // eslint-disable-next-line eqeqeq
        if (status == 5 || status == 0) {
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
          // eslint-disable-next-line eqeqeq
        } else if (status == 4) {
          setResultArray([]);
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
          // eslint-disable-next-line eqeqeq
        } else if (status == 3) {
          setResultArray([]);
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "Ctrl + 0 لتغيير حالة كرسي ادارة اضغط على  ",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
          // eslint-disable-next-line eqeqeq
        } else if (status == 1) {
          const id_reservation = reservationIds1[0].reservationId1;
          const seat_number = seatNumbersToSend2;
          const passengerDeleteData = {
            id_reservation,
            seat_number,
            branch: TripID.id_branch,
            trip_id: TripID.id,
          };
          // dispatch(deleteSeatToReservation(passengerDeleteData));
          axios
            .post(`${BaseUrl}/remove-seat-reservation`, passengerDeleteData, {
              withCredentials: true,
            })
            .then((response) => {
              // Handle the successful response here
              if (response.data.status === 200) {
                setResponse1(response.data.data.seats);
                setArraySeat((prevSeatsInfo) => {
                  const updatedSeatsInfo = { ...prevSeatsInfo };
                  const seatNumber = parseInt(seat_number);

                  if (!isNaN(seatNumber)) {
                    updatedSeatsInfo[seatNumber] = {
                      passengers: {},
                      status: 0,
                      id_reservation: undefined,
                    };
                  }

                  return updatedSeatsInfo;
                });
                const mappedById = response.data.data.reservations.reduce(
                  (acc, item) => {
                    acc[item.id] = item;
                    return acc;
                  },
                  {}
                );
                setArrayReservations(mappedById);

                sweetalert({
                  text: response.data.message,
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                  localStorage.setItem("openpassenger", false);
                  localStorage.setItem("changeseat", false);
                  setOpenPopup(false);
                });
              }
            });
          // dispatch(deleteSeatToReservation(passengerDeleteData));
          settranset(false);
          setDirect(true);
          setResultArray([]);
          setInputString("");
          setValueinput("");
          setSeatAndUser([]);
          setSelectedSeats([]);
          setSelectedSeats1([]);
          setSelectedSeats2([]);
          // eslint-disable-next-line eqeqeq
        } 
        else if (status == 2) {
          const id_reservation = reservationIds1[0].reservationId1;
          const number_seat = seatNumbersToSend2;
          const sortedPassengers = passengers
          .filter(passenger => passenger.code_seat) // Filter out passengers without a code_seat
          .sort((a, b) => a.number_seat - b.number_seat); // Sort by number_seat
      
        // Step 5: Extract code_seat values
        const codeSeats = sortedPassengers.map(passenger => passenger.code_seat);
        const typeReductionSeats = sortedPassengers.map(passenger => passenger.typeReduction);
          const passengerDeleteData = {
            id_reservation,
            number_seat,
            code_seat:codeSeats,
            typeReduction:typeReductionSeats,
            branch: TripID.id_branch,
            id_trip: TripID.id,
          };
          let passengerDetails = JSON.parse(localStorage.getItem("passengers")).map(passenger => {
            let reductionText = '';
            switch (passenger.typeReduction) {
                case 'total':
                    reductionText = 'كامل القيمة';
                    break;
                case 'percentage':
                    reductionText = `نسبة الحسم  ${passenger.reduction} `;
                    
                    break;
                case 'zero':
                    reductionText = '0';
                    break;
                default:
                    reductionText = 'غير معروف';
            }
            return `<div style="margin-bottom: 10px;">
                      <strong>مقعد:</strong> ${passenger.number_seat} -
                      <strong>كود:</strong> ${passenger.code_seat} -
                      <strong>الحسم:</strong> ${reductionText} -
                      <strong>سعر التذكرة:</strong> ${passenger.price}
                    </div>`;
          }).join("");
        
          sweetalert({
            title: "تأكيد الحذف",
            text: "هل أنت متأكد أنك تريد إزالة الراكب/الركاب التالية تفاصيلهم؟",
            content: {
              element: "div",
              
              attributes: {
                innerHTML: `<div>${passengerDetails}</div>`
              },
            },
            icon: "warning",
            buttons: {
              confirm: {
                  text: "موافق",
                  value: true,
                  className: "custom-button-class",
              },
              cancel: {
                  text: "إلغاء",
                  value: false,
                  visible: true,
                  className: "cancel-button-class",
              },
            },
            dangerMode: true,
            
            className: "custom-sweetalert"
          }).then((value) => {
            if (value) {
          axios
            .post(
              `${BaseUrl}/deletePassengerFromReservation`,
              passengerDeleteData,
              { withCredentials: true }
            )
            .then((response) => {
              // Handle the successful response here
              if (response.data.status === 200) {
                setResponse1(response.data.data.seats);
                setArraySeat((prevSeatsInfo) => {
                  const updatedSeatsInfo = { ...prevSeatsInfo };
                  const seatNumber = parseInt(number_seat);

                  if (!isNaN(seatNumber)) {
                    updatedSeatsInfo[seatNumber] = {
                      passengers: {},
                      status: 0,
                      id_reservation: undefined,
                    };
                  }

                  return updatedSeatsInfo;
                });
                const mappedById = response.data.data.reservation.reduce(
                  (acc, item) => {
                    acc[item.id] = item;
                    return acc;
                  },
                  {}
                );
                setArrayReservations(mappedById);
                // setArrayReservations((prevReservations) => {
                //   const updatedReservations = JSON.parse(JSON.stringify(prevReservations));
                //   if (updatedReservations[id_reservation]) {
                //     number_seat.forEach(seatNumber => {
                //       delete updatedReservations[id_reservation].reservation_seats_number[seatNumber];
                //     });

                //     // Optionally, if the reservation_seats_number is empty, consider removing or adjusting the reservation itself
                //     if (Object.keys(updatedReservations[id_reservation].reservation_seats_number).length === 0) {
                //       delete updatedReservations[id_reservation]; // Or handle it another way
                //     }
                //   }
                //   return updatedReservations;
                // });
                // setArrayReservations((prevReservations) => {
                //   const updatedReservations = JSON.parse(
                //     JSON.stringify(prevReservations)
                //   );
                //   if (updatedReservations[id_reservation]) {
                //     number_seat.forEach((seatNumber) => {
                //       delete updatedReservations[id_reservation]
                //         .reservation_seats_number[seatNumber];
                //     });
                //     const passengerIndex = updatedReservations[
                //       id_reservation
                //     ].passengers.findIndex(
                //       (passenger) => passenger.number_seat == seatNumber
                //     );
                //     if (passengerIndex !== -1) {
                //       updatedReservations[id_reservation].passengers.splice(
                //         passengerIndex,
                //         1
                //       );
                //     }
                //     // Optionally, if the reservation_seats_number is empty, consider removing or adjusting the reservation itself
                //     // if (Object.keys(updatedReservations[id_reservation].reservation_seats_number).length === 0) {
                //     //   delete updatedReservations[id_reservation]; // Or handle it another way
                //     // }
                //   }
                //   return updatedReservations;
                // });
                // setArrayReservations((prevReservations) => {
                //   const updatedReservations = { ...prevReservations };
                //   delete updatedReservations[id_reservation]; // Delete the reservation using its ID
                //   return updatedReservations;
                // });
                sweetalert({
                  text: response.data.message,
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                  localStorage.setItem("openpassenger", false);
                  localStorage.setItem("changeseat", false);
                  setOpenPopup(false);
                        // dispatch(deletePassengerToReservation(passengerDeleteData));
            settranset(false);
            setDirect(true);
            setResultArray([]);
            setInputString("");
            setValueinput("");
            setSeatAndUser([]);
            setSelectedSeats([]);
            setSelectedSeats1([]);
            setSelectedSeats2([]);
                });
              }
            })
            .catch((error) => {
              // Check if the error response is HTTP 500
              let errorMessage ;
              
              if (error.response && error.response.status === 500) {
                errorMessage = error.response.data.message || error.response?.data?.data;
              }
              sweetalert({
                title: "خطأ",
                text: errorMessage,
                icon: "error",
                buttons: {
                  confirm: {
                    text: "حسنًا",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              });
              console.error("Error occurred:", error);
              // Optionally reset or clear certain parts of the UI or state
            });
      
          }
        });
        }
      }
      // eslint-disable-next-line eqeqeq
    } else if (idDirect != TripID.id) {
      let reservationseat = null;
      const reservationSeatKeys = Object.keys(
        TripID.tripTransit[selectedTransitIndex].reservationSeats
      );
      reservationseat = reservationSeatKeys.find((seat) =>
        seatNumbersToSend.includes(Number(seat))
      );
      settranset(true);
      setDirect(false);
      // if (reservationseat == seatNumbersToSend2) {
      reservationIds1.push({ reservationId1: reservationIdTransit });
      const status = reservationseat1[seatNumbersToSend2[0]];

      // eslint-disable-next-line eqeqeq
      if (status == 5 || status == 0) {
        localStorage.setItem("changeseat", true);
        sweetalert({
          text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
          icon: "error",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
        // eslint-disable-next-line eqeqeq
      } else if (status == 4) {
        setResultArray([]);
        localStorage.setItem("changeseat", true);
        sweetalert({
          text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
          icon: "error",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
        // eslint-disable-next-line eqeqeq
      } else if (status == 3) {
        setResultArray([]);
        localStorage.setItem("changeseat", true);
        sweetalert({
          text: "Ctrl + 0 لتغيير حالة كرسي ادارة لمتوفر اضغط على  ",
          icon: "error",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
        // eslint-disable-next-line eqeqeq
      } else if (status == 1) {
        const id_reservation =
          reservationIds1[0]?.reservationId1[0].reservationId;
        const seat_number = seatNumbersToSend2;
        const passengerDeleteData = {
          id_reservation,
          seat_number,
          branch: TripID.id_branch,
          trip_id: TripID.id,
        };
        axios
          .post(`${BaseUrl}/remove-seat-reservation`, passengerDeleteData, {
            withCredentials: true,
          })
          .then((response) => {
            // Handle the successful response here
            if (response.data.status === 200) {
              setReservationseat1(response.data.data.seats);

              if (selectedTransitIndex == 0) {
                setArraySeatPassengerTransit1((prevSeatsInfo) => {
                  const updatedSeatsInfo = { ...prevSeatsInfo };

                  seatNumbersToSend2.forEach((seat_number) => {
                    const seatNumber = parseInt(seat_number);
                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: {}, // Empty object for passengers
                        status: 0, // Set status to 0
                        id_reservation: undefined, // No reservation ID
                      };
                    } else {
                    }
                  });

                  return updatedSeatsInfo;
                });
                const mappedById = response.data.data.reservations.reduce(
                  (acc, item) => {
                    acc[item.id] = item;
                    return acc;
                  },
                  {}
                );
                setArrayReservations(mappedById);
                sweetalert({
                  text: response.data.message,
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                  localStorage.setItem("openpassenger", false);
                  localStorage.setItem("changeseat", false);
                  setOpenPopup(false);
                });
              } else if (selectedTransitIndex == 1) {
                setArraySeatPassengerTransit2((prevSeatsInfo) => {
                  const updatedSeatsInfo = { ...prevSeatsInfo };
                  const seatNumber = parseInt(seat_number);

                  if (!isNaN(seatNumber)) {
                    updatedSeatsInfo[seatNumber] = {
                      passengers: {},
                      status: 0,
                      id_reservation: undefined,
                    };
                  }

                  return updatedSeatsInfo;
                });
                const mappedById = response.data.data.reservations.reduce(
                  (acc, item) => {
                    acc[item.id] = item;
                    return acc;
                  },
                  {}
                );
                setArrayReservations(mappedById);
                sweetalert({
                  text: response.data.message,
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                  localStorage.setItem("openpassenger", false);
                  localStorage.setItem("changeseat", false);
                  setOpenPopup(false);
                });
              }
            }
          });
        settranset(true);
        setDirect(false);
        setResultArray([]);
        setInputString("");
        setValueinput("");
        setSeatAndUser([]);
        setSelectedSeats([]);
        setSelectedSeats1([]);
        setSelectedSeats2([]);
      }
       else if (status == 2) {
        const id_reservation =
          reservationIds1[0]?.reservationId1[0].reservationId;
        const number_seat = seatNumbersToSend2;
        // const number_seat = passengerData.map(
        //   (passenger) => passenger.number_seat
        // );
        const sortedPassengers = passengers
        .filter(passenger => passenger.code_seat) // Filter out passengers without a code_seat
        .sort((a, b) => a.number_seat - b.number_seat); // Sort by number_seat
    
      // Step 5: Extract code_seat values
      const codeSeats = sortedPassengers.map(passenger => passenger.code_seat);
      const typeReductionSeats = sortedPassengers.map(passenger => passenger.typeReduction);
        const passengerDeleteData = {
          id_reservation,
          number_seat,
          code_seat:codeSeats,
          typeReduction:typeReductionSeats,
          branch: TripID.id_branch,
          id_trip: TripID.id,
        };
        let passengerDetails = JSON.parse(localStorage.getItem("passengers")).map(passenger => {
          let reductionText = '';
          switch (passenger.typeReduction) {
              case 'total':
                  reductionText = 'كامل القيمة';
                  break;
              case 'percentage':
                  reductionText = `نسبة الحسم  ${passenger.reduction} `;
                  break;
              case 'zero':
                  reductionText = '0';
                  break;
              default:
                  reductionText = 'غير معروف';
          }
          return `<div style="margin-bottom: 10px;">
                    <strong>مقعد:</strong> ${passenger.number_seat} -
                    <strong>كود:</strong> ${passenger.code_seat} -
                    <strong>الحسم:</strong> ${reductionText} -
                    <strong>سعر التذكرة:</strong> ${passenger.price}
                  </div>`;
        }).join("");
      
        sweetalert({
          title: "تأكيد الحذف",
          text: "هل أنت متأكد أنك تريد إزالة الراكب/الركاب التالية تفاصيلهم؟",
          content: {
            element: "div",
            
            attributes: {
              innerHTML: `<div>${passengerDetails}</div>`
            },
          },
          icon: "warning",
          buttons: {
            confirm: {
                text: "موافق",
                value: true,
                className: "custom-button-class",
            },
            cancel: {
                text: "إلغاء",
                value: false,
                visible: true,
                className: "cancel-button-class",
            },
          },
          dangerMode: true,
          
          className: "custom-sweetalert"
        }).then((value) => {
          if (value) {
        axios
          .post(
            `${BaseUrl}/deletePassengerFromReservation`,
            passengerDeleteData,
            { withCredentials: true }
          )
          .then((response) => {
            if (response.data.status === 200) {
              setReservationseat1(response.data.data.seats);
              if (selectedTransitIndex == 0) {
                setArraySeatPassengerTransit1((prevSeatsInfo) => {
                  const updatedSeatsInfo = { ...prevSeatsInfo };

                  seatNumbersToSend2.forEach((seat_number) => {
                    const seatNumber = parseInt(seat_number);
                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: {}, // Empty object for passengers
                        status: 0, // Set status to 0
                        id_reservation: undefined, // No reservation ID
                      };
                    } else {
                    }
                  });

                  return updatedSeatsInfo;
                });
                const mappedById = response.data.data.reservation.reduce(
                  (acc, item) => {
                    acc[item.id] = item;
                    return acc;
                  },
                  {}
                );
                setArrayReservations(mappedById);
                sweetalert({
                  text: response.data.message,
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                  localStorage.setItem("openpassenger", false);
                  localStorage.setItem("changeseat", false);
                  setOpenPopup(false);
                  setReservationseat1(
                    TripID.tripTransit[selectedTransitIndex].reservationSeats
                  );
                  settranset(true);
                  setDirect(false);
                  setResultArray([]);
                  setInputString("");
                  setValueinput("");
                  setSeatAndUser([]);
                  setSelectedSeats([]);
                  setSelectedSeats1([]);
                  setSelectedSeats2([]);
                });
              } else if (selectedTransitIndex == 1) {
                setArraySeatPassengerTransit2((prevSeatsInfo) => {
                  const updatedSeatsInfo = { ...prevSeatsInfo };
                  const seatNumber = parseInt(number_seat);

                  if (!isNaN(seatNumber)) {
                    updatedSeatsInfo[seatNumber] = {
                      passengers: {},
                      status: 0,
                      id_reservation: undefined,
                    };
                  }

                  return updatedSeatsInfo;
                });
                const mappedById = response.data.data.reservation.reduce(
                  (acc, item) => {
                    acc[item.id] = item;
                    return acc;
                  },
                  {}
                );
                setArrayReservations(mappedById);
                sweetalert({
                  text: response.data.message,
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                  localStorage.setItem("openpassenger", false);
                  localStorage.setItem("changeseat", false);
                  setOpenPopup(false);
                  setReservationseat1(
                    TripID.tripTransit[selectedTransitIndex].reservationSeats
                  );
                  settranset(true);
                  setDirect(false);
                  setResultArray([]);
                  setInputString("");
                  setValueinput("");
                  setSeatAndUser([]);
                  setSelectedSeats([]);
                  setSelectedSeats1([]);
                  setSelectedSeats2([]);
                });
              }

              // sweetalert({
              //   text: "تم الحذف بنجاح",
              //   icon: "success",
              //   buttons: {
              //     confirm: {
              //       text: "تم",
              //       className: "custom-button-class",
              //     },
              //   },
              //   className: "alert-div",
              // }).then(() => {
              //   // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
              //   localStorage.setItem("openpassenger", false);
              //   localStorage.setItem("changeseat", false);
              // });
            }
          })
          .catch((error) => {
            // Check if the error response is HTTP 500
            let errorMessage ;
            
            if (error.response && error.response.status === 500) {
              errorMessage = error.response.data.message || error.response?.data?.data;
            }
            sweetalert({
              title: "خطأ",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "حسنًا",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            });
            console.error("Error occurred:", error);
            // Optionally reset or clear certain parts of the UI or state
          });
        }
      });
        // dispatch(deletePassengerToReservation(passengerDeleteData));
      
      }
    }
    // }

    // setResultArray([]);
    // setInputString("");
    // setValueinput("");
    // setSeatAndUser([]);
    // setSelectedSeats([]);
    // setSelectedSeats1([]);
    // setSelectedSeats2([]);
    // });
  };

  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const [isvalid, setIsValid] = useState(true);

  const handleNext = () => {
    setactiveStep1((prevactiveStep1) => prevactiveStep1 + 1);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft" && activeStep1 !== steps.length) {
        setactiveStep1((prevactiveStep1) => prevactiveStep1 + 1);
      } else if (event.key === "ArrowRight" && activeStep1 !== 1) {
        setactiveStep1((prevactiveStep1) => prevactiveStep1 - 1);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeStep1, steps.length]);
  const handleBack = () => {
    setactiveStep1((prevactiveStep1) => prevactiveStep1 - 1);
  };
  const lastIndex = steps.length - 1;
  const isLastStep = activeStep1 === lastIndex + 1;
  const isFirstStep = activeStep1 === 1;
  
  return (
    <Grid item lg={12} md={12} sm={12} className="">
      <Grid item lg={12} md={12} sm={12}>
        <Box className="create-passenger">
          <React.Fragment>
            {steps.map((step, index) => {
              const isActiveStep = activeStep1 === index + 1; // Check if the step is active
              const isLastStep = index === lastIndex; // Check if it's the last step

              return (
                <div key={step.id}>
                  {isActiveStep ? (
                    <div>
                      {/* {step.isChild ? (
                          <PassangerChild
                            isAddingReservation={false}
                            adults={adults}
                            isvalid={isvalid}
                          setIsValid={setIsValid}
                            indexx={index}
                            initialValues={initialFValues.children}
                          />
                        ) : ( */}
                      <PassengerAdultDeleted
                        id_trip={id_trip}
                        id_trip_type={id_trip_type}
                        isvalid={isvalid}
                        setIsValid={setIsValid}
                        id_company={id_company}
                        adults={adults}
                        index={index}
                        // initialValues={initialFValues.adults}
                      />
                      {/* )} */}
                    </div>
                  ) : null}
                </div>
              );
            })}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "3%",
                flexDirection: "row-reverse",
              }}
            >
              {isFirstStep ? null : ( // Conditionally render the "Back" button
                <div
                  className="button-passenger"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Controls.Button
                    onClick={handleBack}
                    type="submit"
                    text="السابق"
                  />
                </div>
              )}

              {isLastStep ? (
                <div
                  className="button-passenger"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Controls.Button
                    onClick={handleSubmit}
                    type="submit"
                    text="حذف"
                  />
                </div>
              ) : (
                <div
                  className="button-passenger"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Controls.Button
                    onClick={handleNext}
                    type="submit"
                    text="التالي"
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        </Box>
      </Grid>
    </Grid>
  );
}

export default StepPassengerDeleted;
