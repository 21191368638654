import React, { Fragment } from "react";
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
} from "@material-ui/core";
import { Box } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UserLogin } from "../../redux/authSlice";
import { useState } from "react";
import * as sweetalert from "sweetalert";
import { useSelector } from "react-redux";
const useStyle = makeStyles((theme) => ({
  generalstyle: {
    width: "100vw",
    height: "100vh",
    backgroundImage: `URL('img/bg.jpeg')`,
    backgroundSize: "cover",
  },
  padding: {
    padding: "47px 45px",
  },
  button: {
    borderRadius: "100px",
    padding: "12px 45px",
  },
  mainbbox: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: ` translate(-50%,-50%)`,
  },
  textRight: {
    textAlign: "right",
  },
  dir: {
    direction: "rtl",
  },
  borderRadiues: {
    borderRadius: "47px 45px 55px 73px !important",
    width: "100vw",
  },
  textSize: {
    fontSize: 25,
  },
  paddingnone: {
    padding: "0 !important",
  },
  justifyCont: {
    justifyContent: "center",
  },
  margintop: {
    margintop: "40px",
  },

  "@media (min-width:768px) and (max-width:1200px)": {
    marginRight: {
      marginRight: "0px",
    },
  },
  customImage: {
    width: "4% !important",
    marginLeft: "4px",
  },
}));

let validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("بريد الكتروني غير صالح")
    .required("هذا الحقل مطلوب"),
  password: Yup.string().required("هذا الحقل مطلوب"),
});

const UserForm = () => {
  const navigate = useNavigate();
  const classes = useStyle();
  const dispatch = useDispatch();
  const [FCM, setFcm] = useState("");
  // useEffect(() => {
  //   const msg = firebase.messaging();
  //   const requestNotificationPermission = async () => {
  //     try {
  //       const permission = await Notification.requestPermission();
  //

  //       if (permission == "granted") {
  //         // Permission granted, now get the FCM token.
  //         const data = await msg.getToken();
  //         setFcm(data);
  //       } else {
  //         // Permission denied or dismissed by the user.
  //         // Handle the situation as needed.
  //         throw new Error("Permission denied for notifications.");
  //       }
  //     } catch (error) {
  //       // Handle any errors that occurred during permission request or token retrieval.
  //       console.error("Failed to get permission or token:", error);
  //     }
  //   };

  //   requestNotificationPermission();
  // }, [FCM]);

  const initialValues = {
    email: "",
    password: "",
    device_token: FCM,
  };
  const { isLoading } = useSelector((state) => state.auth);

  const onSubmit = async (values) => {
    try {
      const resultAction = await dispatch(
        UserLogin({ ...values, device_token: initialValues.device_token })
      );

      if (UserLogin.rejected.match(resultAction)) {
        // Handle the rejected action (display an error message, etc.)
        sweetalert(resultAction.payload.message, {
          className: "alert-div",
          button: {
            text: "تم",
          },
        }).then(() => {
          window.location.reload();
        });
      } else {
        // Successful login, navigate to the desired location
        localStorage.setItem("isLoggedIn", "true");
        navigate("/TransportCompanyData");
        window.location.reload();
      }
    } catch (error) {
      // Handle any unexpected errors here
      console.error("An error  :", error);
    }
  };

  return (
    <Box
      className={classes.generalstyle}
      sx={{
        "& .css-1wc848c-MuiFormHelperText-root": {
          textAlign: "right",
        },
        "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
          background: "#AEAEAE",
          border: "1px solid #AEAEAE !important",
          height: "15px !important",
        },
        "& .loginfelid .MuiInputBase-input.MuiOutlinedInput-input": {
          background: "#AEAEAE !important",
          border: "1px solid #AEAEAE !important",
          height: "15px !important",
        },
        "& .MuiButton-containedPrimary": {
          background: "#F9B917 !important",
          color: "#fff",
        },
        "& .MuiCardContent-root": {
          padding: "0 !important",
        },
        "& .MuiButton-contained.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.26)",
          boxShadow: "none",
          backgroundColor: "rgba(0, 0, 0, 0.12)",
        },
        "& .MuiCardActions-root": {
          padding: "0 !important",
          marginTop: "69px",
        },
        "& .MuiFormControl-fullWidth": {
          marginTop: "40px",
        },
        "& .MuiInputLabel-root": {
          direction: "rtl !important",
          textAlign: "right !important",
          top: "0%",
          right: "5% !important",
          transformOrigin: "top right !important",
        },
        "& .MuiPaper-rounded": {
          borderRadius: "20px !important",
        },
      }}
    >
      <Grid
        item
        className={classes.mainbbox}
        container
        justify="center"
        spacing={1}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={12}
        sm={12}
        md={12}
      >
        <Grid item xs={12} sm={12} md={12}>
          <Card className={classes.padding}>
            <CardHeader
              className={[
                classes.paddingnone,
                classes.textRight,
                classes.textSize,
              ]}
              title="مرحبا بعودتك يرجى ادخال معلوماتك لتسجيل الدخول"
            ></CardHeader>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ dirty, isValid, values, handleChange, handleBlur }) => {
                return (
                  <Form className={[classes.marginRight]} dir="rtl">
                    <CardContent>
                      <Grid
                        container
                        spacing={1}
                        justify="center"
                        className={classes.margintop}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          dir="rtl"
                          className="logindiv"
                        >
                          <Field
                            className="form-control loginfelid"
                            Placeholder="tttttt"
                            label={
                              <Fragment>
                                <img
                                  src="img/account_circle_FILL0_wght400_GRAD0_opsz48 (1).svg"
                                  className={classes.customImage}
                                />
                                البريد الالكتروني
                              </Fragment>
                            }
                            variant="outlined"
                            fullWidth
                            name="email"
                            value={values.email}
                            component={TextField}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="logindiv">
                          <Field
                            className="loginfelid"
                            dir="rtl"
                            label={
                              <Fragment>
                                <img
                                  src="img/lock_FILL0_wght400_GRAD0_opsz48.svg"
                                  className={classes.customImage}
                                />
                                كلمة المرور
                              </Fragment>
                            }
                            variant="outlined"
                            fullWidth
                            name="password"
                            value={values.password}
                            type="password"
                            component={TextField}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions className={classes.justifyCont}>
                      {/* {isLoading ? (
                        <>
                          <div
                            className="bottonFullwidth loadlogin"
                            style={{
                              background: "#F9B917",
                              borderRaduis: "10px",
                            }}
                          >
                            <div v-if="loading" class="spinner">
                              <div class="rect1"></div>
                              <div class="rect2"></div>
                              <div class="rect3"></div>
                              <div class="rect4"></div>
                              <div class="rect5"></div>
                            </div>
                          </div>
                        </>
                      ) : ( */}
                        <Button
                          disabled={!dirty || !isValid}
                          variant="contained"
                          color="primary"
                          type="Submit"
                          className={classes.button}
                        >
                          تسجيل الدخول
                        </Button>
                      {/* )} */}
                    </CardActions>
                  </Form>
                );
              }}
            </Formik>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserForm;
