import React, { useState } from "react";
import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../styles/theme";
import MainButton from "./MainButton";
import ConfirmDialog1 from "./ConfirmDialog1";
const HeaderSets = ({ title, subtitle, img ,child,adults,id_reservation,count_passenger,datetime, id_trip,numberOfStatus3Seats}) => {
  const theme = useTheme();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const colors = tokens(theme.palette.mode);
  function onClick() {
    setConfirmDialog({
      isOpen: true,
      title: "تم إكمال   بنجاح",
      img: "img/travel/Successful purchase-cuate (2).svg",
      onConfirm: () =>{ },
     
    });
  }

  return (
    <Box mb="30px">
      <Box mt="100px">
        <Typography
          variant="h2"
          color={colors.primary[1100]}
          fontWeight="bold"
          fontFamily="bein"
          fontSize="30px"
          sx={{ mt: "30px", mr: "15px", minWidth: "60vw", maxWidth: "90%" }}
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          fontWeight="100"
          fontSize="20px"
          color={colors.primary[1100]}
        >
          {subtitle}
        </Typography>
      </Box>

      <Box className="setsButtonDivide">
        <Typography mt="50px">
          <img src={img} className="BusRed" />
        </Typography>
        <Typography mt="50px" p="0 130px" className="setsButton" >
          <MainButton title="اختيار" onClick={onClick} disabled={numberOfStatus3Seats !== count_passenger}/>
        </Typography>
      </Box>
      <ConfirmDialog1
        children={child}
        id_trip={id_trip}
        datetime={datetime}
        count_passenger={count_passenger}
        id_reservation={id_reservation}
        numberOfStatus3Seats={numberOfStatus3Seats}
        adultss={adults}
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Box>
  );
};

export default HeaderSets;
