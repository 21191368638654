import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import Box from "@mui/material/Box";
import StyledCard from "./card";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import TransportCompanyData from "./TranspotCompanyData";
import BusList from "./Buses/BusList";
import OfficCampany from "./OfficeCompany";
import TranspotCompanyPaths from "./TranspotCompanyPaths";
import { useSelector } from "react-redux";


import { useEffect } from "react";
import AddBusType from "./Buses/AddBusType";
import BusListType from "./Buses/BusListType";
import OfficeCompanyRestore from "./OfficeCompanytrashed";
import CardList1 from "../EmployeeNew/CardList";
import CardListTrashed from "../EmployeeNew/CardListTrashed";
import AddPrivacyPolicyCompany from "../Privacypolicy/addPrivacyPolicyCompany";
import AddTypeTickit from "../AddTypePepole/AddTypeTickit";
import TripsSchedluar from "../Trips_Schedular/Trips";
import MenuIcon from "@mui/icons-material/Menu";
import ReactVirtualizedTable from "../Trips/TripTable";
import Accounting from "./Accounting";
import { fetchCompanyById } from "../../redux/companyTransportDataSlice";
import { useDispatch } from "react-redux";
import TripEnd from "../Trips/TripEnd";
import TripLock from "../Trips/TripLock";
import TicketDesignForm from "./TicketDesignForm";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      // style={{ padding: "0px 2% " }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const ComplaintsList = ({ mylist ,id}) => {
  const { user } = useSelector((state) => state.auth);
  const Shortcut = localStorage.getItem("displayshortcut");
  const [value, setValue] = React.useState(
    parseInt(localStorage.getItem("activeTab")) || 0
  );
  const [enable, setEbanle] = React.useState(true);
  const [schortcut, setShortcut] = React.useState(0);
  const { loading, CompaniesData } = useSelector((state) => state.CompanyData);
  const [sidebarOpen, setSidebarOpen] = useState(false);
const dispatch = useDispatch();
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  useEffect(() => {},[CompaniesData,CompaniesData.id]);

  const ViewBranch =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "view.branch");

  const ViewSeatType =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "view.seat.type");

  const ViewBus =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "view.bus");

  const ViewTripType =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "view.trip.type");

  const ViewTrip =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "view.trip");

  const ViewTripStatic =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "view.trip.static");



  const ViewsystemUsers =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "view.systemUsers"
    );

    const GetTripIslock =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "get.trip.islock"
    );
    const manualDoneStatusTrip=
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "manual.done.status"
    );
    const RestorBranch=
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "restore.branch"
    );

  const ViewBranchdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "view.branch"
    );

  const ViewSeatTypedefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "view.seat.type"
    );

  const ViewBusdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some((permission) => permission === "view.bus");

  const ViewTripTypedefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "view.trip.type"
    );

  const ViewTripdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some((permission) => permission === "view.trip");

  const ViewTripStaticdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some((permission) => permission === "view.trip.static");

  const ViewsystemUsersdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "view.systemUsers"
    );
  const GetTripIslockdefult =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "get.trip.islock"
    );

    const manualDoneStatusTripdefult=
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "manual.done.status"
    );
    const RestoreBranchDefault=
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "restore.branch"
    );

    const RestorEmpoyee =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "restore.systemUser"
    );

    user.PermissionsNames.some((permission) =>
      permission.includes("restore.systemUser")
    );
  const HardDeleteEmpoyee =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "hard.delete.systemUser"
    );

  // Permission as default
  const RestorEmpoyeedefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "restore.systemUser"
    );

    
  const HardDeleteEmpoyeedefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "hard.delete.systemUser"
    );


  const handleKeyDown = (event) => {

  };
  useEffect(() => {
    if (Shortcut === "0") {
      window.addEventListener("keydown", handleKeyDown);
    } else if (
      Shortcut === "1" ||
      localStorage.getItem("displayshortcut") === "1"
    ) {
      window.removeEventListener("keydown", handleKeyDown);
    } else if (localStorage.getItem("displayshortcut") == null) {
      localStorage.setItem("displayshortcut", "0");
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [Shortcut, setValue]);

  // useEffect(() => {
  //   dispatch(fetchCompanyById(id));
  // }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("activeTab", newValue);
  };
console.log(CompaniesData,'CompaniesData');
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={2}
          sm={12}
          md={1}
          style={{
            width: sidebarOpen ? "100% " : "0 ",
            overflow: "hidden",
            transition: "width 0.3s",
            zIndex: sidebarOpen ? "101 " : "0 ",
          }}
          >
          {/* <MenuIcon onClick={toggleSidebar} /> */}
          <MenuIcon 
  onMouseEnter={() => setSidebarOpen(true)} 
 
/>
          <div className="div-sidebar-company"  onMouseLeave={() => setSidebarOpen(false)} >
            <div
            className="box-logo-company"
              style={{
                background: "#fff",
                width: sidebarOpen ? "100% " : "0",
                overflow: "hidden",
                transition: "width 0.3s",
                position: "absolute",
                boxShadow:'rgba(234, 182, 28, 0.6) 0px 3px 6px'
       
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  display: "flex",
                }}
              >
                <StyledCard
                  loading={loading}
                  secondary={CompaniesData.name_ar}
                  primary={CompaniesData.logo == ''?'img/Avatar.svg' :CompaniesData.logo}
                />
              </Box>
            </div>
            <div
              className="tab-multi-company"
              style={{
                width: sidebarOpen ? "100% " : "0 ",
                transition: "width 0.3s",
                position: "absolute",
                // zndex: "2000",
                boxShadow: "0px 3px 6px rgba(202, 166, 23, 0.63)",
                overflow:'auto',
                height: "73vh",
            
              }}
            >
              {/* <Grid container item lg={12} style={{ marginBottom: 25 , height:'7% !important' }}> */}
              <Box
                className="box-all-tabs11"
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  display: "flex",
                  // marginTop:'120px !important'
                }}
              >
                {" "}
                <Tabs
                  className="box-all-tabs"
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}
                >
                  <Tab label="مسارات الرحل" {...a11yProps(0)} />

                  {ViewTripType === true || ViewTripTypedefault === true ? (
                    <Tab label="أنواع الرحلات" {...a11yProps(1)} />
                  ) : (
                    <></>
                  )}
                  {ViewSeatTypedefault === true || ViewSeatType === true ? (
                    <Tab label=" انواع الباصات" {...a11yProps(2)} />
                  ) : (
                    <></>
                  )}
                  {ViewBus === true || ViewBusdefault === true ? (
                    <Tab label="الباصات" {...a11yProps(3)} />
                  ) : (
                    <></>
                  )}
                  {ViewBranch === true || ViewBranchdefault === true ? (
                    <Tab label="مكاتب الشركة" {...a11yProps(4)} />
                  ) : (
                    <></>
                  )}
                  {RestoreBranchDefault === true || RestorBranch === true ? (
                  <Tab label="المكاتب المحذوفة" {...a11yProps(5)} />
                  ) : (
                    <></>
                  )}
                  
                  {ViewsystemUsersdefault === true ||
                  ViewsystemUsers === true ? (
                    <Tab label="الموظفين" {...a11yProps(6)} />
                  ) : (
                    <></>
                  )}
                  {(HardDeleteEmpoyee === true ||
                    HardDeleteEmpoyeedefault === true) || (RestorEmpoyeedefault === true || RestorEmpoyee === true) ? (
                      <Tab label="الموظفين المحذوفين" {...a11yProps(7)} />
                  ) : (
                    <></>
                  )}



                  {ViewTrip === true || ViewTripdefault === true ? (
                    <Tab label="الرحلات" {...a11yProps(8)} />
                  ) : (
                    <></>
                  )}
                  {manualDoneStatusTrip === true || manualDoneStatusTripdefult === true ? (
                    <Tab label="  الرحلات المنتهية " {...a11yProps(9)} />
                  ) : (
                    <></>
                  )}
                  {GetTripIslock === true || GetTripIslockdefult === true ? (
                    <Tab label=" الرحلات المقفلة " {...a11yProps(10)} />
                  ) : (
                    <></>
                  )}
                  {ViewTripStatic === true || ViewTripStaticdefault === true ? (
                  <Tab label="جدولة الرحلات" {...a11yProps(11)} />
                  ) : (
                    <></>
                  )}


                  <Tab label="إضافة نوع تذكرة" {...a11yProps(12)} />
                  <Tab label="المحاسبة" {...a11yProps(13)} />
                  <Tab label="سياسة الخصوصية" {...a11yProps(14)} />
                  {/* <Tab label="تصميم تذكرة" {...a11yProps(15)} /> */}
                </Tabs>
              </Box>
              {/* </Grid> */}
            </div>
          </div>
        </Grid>
        <Grid item xs={10} sm={12} md={12} style={{zIndex:'100 !important'}} className="box-all-tabbs">
        <TabPanel value={value} index={0}>
            <TranspotCompanyPaths
              id_company={CompaniesData.id}
              setShortcut={setShortcut}
            />
          </TabPanel>
          {ViewTripType === true || ViewTripTypedefault === true ? (
            <TabPanel value={value} index={1}>
              <TransportCompanyData
                trip_type={CompaniesData.trip_type}
                id_company={CompaniesData.id}
                setShortcut={setShortcut}
              />
            </TabPanel>
          ) : (
            <></>
          )}
          {ViewSeatTypedefault === true || ViewSeatType === true ? (
            <TabPanel value={value} index={2}>
              {enable === true ? (
                <BusListType
                  id_company={CompaniesData.id}
                  setEbanle={setEbanle}
                  setShortcut={setShortcut}
                />
              ) : (
                <AddBusType
                  Bus={CompaniesData.bus}
                  id_company={CompaniesData.id}
                  setEbanle={setEbanle}
                  enable={enable}
                />
              )}
            </TabPanel>
          ) : (
            <></>
          )}
          {ViewBus === true || ViewBusdefault === true ? (
            <TabPanel value={value} index={3}>
              <BusList
                Bus={CompaniesData.bus}
                id_company={CompaniesData.id}
                loading={loading}
                setShortcut={setShortcut}
              />
            </TabPanel>
          ) : (
            <></>
          )}
          {ViewBranch === true || ViewBranchdefault === true ? (
            <TabPanel value={value} index={4}>
              <OfficCampany
                loading={loading}
                branch={CompaniesData.branch}
                id_company={CompaniesData.id}
                setShortcut={setShortcut}
              />
            </TabPanel>
          ) : (
            <></>
          )}
          <TabPanel value={value} index={5}>
            <OfficeCompanyRestore id_company={CompaniesData.id} />
          </TabPanel>

          <TabPanel value={value} index={6}>
            <CardList1
              id_company={CompaniesData.id}
              setShortcut={setShortcut}
            />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <CardListTrashed id_company={CompaniesData.id} />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <ReactVirtualizedTable
              id_company={CompaniesData.id}
              setValue={setValue}
              setShortcut={setShortcut}
              schortcut={schortcut}
            />
          </TabPanel>
          <TabPanel value={value} index={9}>
            <TripEnd
              id_company={CompaniesData.id}
              setValue={setValue}
              setShortcut={setShortcut}
              schortcut={schortcut}
            />
          </TabPanel>
          <TabPanel value={value} index={10}>
            <TripLock
              id_company={CompaniesData.id}
              setValue={setValue}
              setShortcut={setShortcut}
              schortcut={schortcut}
            />
          </TabPanel>
          <TabPanel value={value} index={11}>
            <TripsSchedluar
              id_company={CompaniesData.id}
              setValue={setValue}
              setShortcut={setShortcut}
              schortcut={schortcut}
            />
          </TabPanel>
     
          <TabPanel value={value} index={12}>
            <AddTypeTickit id_company={CompaniesData.id} />
          </TabPanel>

          <TabPanel value={value} index={13}>
            <Accounting
              id_company={CompaniesData.id}
              setShortcut={setShortcut}
            />
          </TabPanel>

          <TabPanel value={value} index={14}>
            <AddPrivacyPolicyCompany id_company={CompaniesData.id} />
          </TabPanel>

          {/* <TabPanel value={value} index={15}>
            <TicketDesignForm />
          </TabPanel> */}


      

        </Grid>
      </Grid>
    </Box>
  );
};

export default ComplaintsList;
