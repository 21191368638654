import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const insertSeateType = createAsyncThunk(
  'Seats/insertSeateType',
  async (values,thunkAPI) => {
    const { dispatch,rejectWithValue, getState } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/addSeatType`, values, { withCredentials: true });
      sweetalert({
        text: "تم الإضافة بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        dispatch(clearSeateState());
        window.location.reload();
      });
    } catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage = error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);

const createBusSlice = createSlice({
  name: 'createBus',
  initialState: {
    rows: 0,
    columns: 0,
    numberSeats: 0,
    submitted: false,
    loadingAdd: false,
    cellValues: [],
    Seat_Type:[],
    Seats:[],
    selectedType: '',
  },
  reducers: {
    clearSeateState: (state) => {
      state.rows= 0;
      state.columns= 0;
      state.numberSeats= 0;
      state.submitted= false;
      state.loadingAdd= false;
      state.cellValues= [];
      state.Seat_Type=[];
      state.Seats=[];
      state.selectedType= '';
    },
    setRows: (state, action) => {
        state.rows = parseInt(action.payload);
      
    },
    setColumns: (state, action) => {
      state.columns = parseInt(action.payload);
    },
    setNumberSeats: (state, action) => {
      state.numberSeats = parseInt(action.payload);
    },
    setSubmitted: (state, action) => {
      state.submitted = action.payload;
    },
    setCellValues: (state, action) => {
        state.cellValues = action.payload;
      },
    setSelectedType: (state, action) => {
      state.selectedType = action.payload;
    },

  },
});

export const {
  setRows,
  setColumns,
  setNumberSeats,
  setSubmitted,
  setCellValues,
  setSelectedType,
  setSelectedCompany,
  clearSeateState
} = createBusSlice.actions;

export default createBusSlice.reducer;
