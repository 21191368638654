import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import {  Select, MenuItem } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { Button } from "@mui/material";
import Popup from "../../component/Popup";
import EmployeeForm from "./EmployeeForm.js";
import {
  GridColDef,
  GridFooterContainer,
  GridValueGetterParams,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridPagination,
} from "@mui/x-data-grid";

import SearchBar from "material-ui-search-bar";
import MainButton from "../../component/MainButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchEmployee, fetchEmployeeByBranch, fetchEmployeeByName, fetchEmployeeCompany } from "../../redux/employeeSlice";
import { fetchAllRoles } from "../../redux/RolesSlice";
import CardEmployee from "./CardEmployee";
import { fetchCompanyById } from "../../redux/companyTransportDataSlice";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles({
  gridDivide: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "23px",
  },
  "@media (min-width: 768px) and (max-width:1366px)": {
    gridDivide: {
      justifyContent: "center",
      alignItems: "center",
    },
    searchDesign: {
      width: "100%",
    },
  },
  "@media (min-width: 1366px) and (max-width:1556px)": {
    gridDivide: {
      justifyContent: "space-around",
      alignItems: "space-around",
    },
  },
  mt: {
    marginTop: "30px",
  },

  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    height: "33px !important",
    marginBottom: "20px !important",
    paddingRight: "8px",
    flexDirection: "row-reverse",
  },
  searchDesign1: {
    marginBottom: "20px !important",
    height: "33px !important",
  },

  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  contentDivide: {
    width: "100%",
    marginBottom: "40px",
  },
  Marginbox1: {
    marginBottom: "20px !important",
  },
  AddDesign: {
    marginBottom: "20px",
    height: "33px !important",

  },
});

const CardList1 = ({ id_company,setShortcut }) => {
  const { t } = useTranslation();
  useEffect(() => {
    let typedWord = "";
    let typedWord2 = "";
  
    const handleKeyDown = (event) => {
      if (/^[a-zA-Z]$/.test(event.key)) {
        typedWord += event.key.toLowerCase();
        typedWord2 += event.key;
  
        const lastThreeChars = typedWord.substring(typedWord.length - 3);
  
        if (lastThreeChars === 'add') {
          localStorage.setItem("displayshortcut", "1");
          setOpenPopup(true);
          setRecordForEdit(null);
          setShortcut(1);
          typedWord = '';
        } 
      }
    };
  
    document.addEventListener("keydown", handleKeyDown);
  
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  },[]);

  const { loading, EmployeeCompany,names,Branches, error } = useSelector(
    (state) => state.Employee
  );
  const { Roles } = useSelector(
    (state) => state.Roles
  );

  const { user } = useSelector((state) => state.auth);
  //Add Permission
  const AddEmpoyee =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "add.systemUsers"
    );

  // Permission as default
  const AddEmpoyeedefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "add.systemUsers"
    );

  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Set the initial page to 1
  const itemsPerPage = 8;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.state;

  useEffect(() => {
      dispatch(fetchEmployeeCompany(id_company));
      dispatch(fetchEmployeeByName(id_company));
      dispatch(fetchEmployeeByBranch(id_company));
    
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchAllRoles());
  }, [dispatch]);
  

  const classes = useStyles();

  const [filteredData, setFilteredData] = useState(EmployeeCompany);
  const [filter, setFilter] = useState(true);
  const [age, setAge] = useState("all");
  const [searched, setSearched] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [selectedname, setSelectedName] = useState(null);
  const [selectedrole, setSelectedRole] = useState(null);
  const [selectedbranch, setSelectedBranch] = useState(null);


  const onHandleReset = () => {
    setSelectedName(null);
    setSelectedBranch(null);
    setSelectedRole(null);
  };
  useEffect(() => {

  }, [selectedname,selectedrole,selectedbranch]);
  function filterEmp(EmployeeCompany) {
    if (selectedname && selectedrole && selectedbranch) {
      return EmployeeCompany.filter(
        emp =>
        emp.first_name === selectedname &&
        emp.branch_name === selectedbranch && 
        emp.roles_name === selectedrole
        );
      } 
      
      else if (selectedname && selectedrole ) {
        return EmployeeCompany.filter(
          emp =>
          emp.first_name === selectedname &&
          emp.roles_name === selectedrole
          );
      }
      else if (selectedrole &&selectedbranch ) {
        return EmployeeCompany.filter(
          emp =>
          emp.branch_name === selectedbranch &&
          emp.roles_name === selectedrole
          );
      }
      else if (selectedname &&selectedbranch ) {
        return EmployeeCompany.filter(
          emp =>
          emp.first_name === selectedname &&
          emp.branch_name === selectedbranch
          );
      }
    else if (selectedname) {
      return EmployeeCompany.filter(emp => emp.first_name === selectedname);
    } else if (selectedbranch) {
      return EmployeeCompany.filter(emp => emp.branch_name === selectedbranch);
     
    } else if (selectedrole) {
      return EmployeeCompany.filter(emp => emp.roles_name === selectedrole);
    } 
  else {
      return EmployeeCompany;
    }
  }
  const filteredEmployee = filterEmp(EmployeeCompany);

  return (
    <Grid className="select-div" item lg={12} >
      <Grid
        container
        spacing={4}
        className={classes.gridContainer}
        justify="center"
      >
        <Grid container xs={12} className={classes.Marginbox1}>
          <Grid lg={3} md={6} sm={4} className='searchDesign1'  >
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  className="Autobox"
                  options={names || []}
                  value={selectedname}
                  onChange={(event, newValue) => setSelectedName(newValue)}
                  renderInput={(params) => <TextField {...params} label="اسم الموظف" />}
                />
          </Grid>
          <Grid lg={3} md={6} sm={4} className='searchDesign1'  >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className={`selected-role-filter ${selectedrole ? 'selects3211' : 'selects321'}`}
            text="بحث"
            value={selectedrole}
            onChange={(e) => setSelectedRole(e.target.value)}

          >
          
          {Roles.map(role =>(
            <MenuItem value={role.name} key={role.id}>
              <span>{role.name}</span>
            </MenuItem>
          ))}
          </Select>
          </Grid>
          <Grid lg={3} md={6} sm={4} className={classes.AddDesign} >
          <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  className="Autobox"
                  options={Branches || []}
                  value={selectedbranch}
                  onChange={(event, newValue) => setSelectedBranch(newValue)}
                  renderInput={(params) => <TextField {...params} label="فرع الشركة" />}
                />
    
          </Grid>
          <Grid lg={1} md={1} sm={4} className={classes.AddDesign} style={{display:'flex',justifyContent:'center'}}>
            <Button className="reloadButton1" onClick={onHandleReset}>
              <img src="img/reload.svg" />
            </Button>
          </Grid>
          {AddEmpoyee === true || AddEmpoyeedefault === true ? (
            <Grid lg={2} md={4} sm={4} className={classes.AddDesign}>
              <div className="bottonFullwidth1">
                <Controls.Button
                  type="submit"
                  text="إضافة"
                  onClick={() => {
                    setOpenPopup(true);
                    setRecordForEdit(null);
                  }}
                />
              </div>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <Popup
          title="اضافة الموظف"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setDisplayshortcut={setShortcut}
        >
          <EmployeeForm recordForEdit={recordForEdit} id_company={id_company} />
        </Popup>
  
        <Grid style={{ display: "flex", flexDirection: "column" }}>
    
          <Grid className={classes.gridDivide}>
            <CardEmployee
           
              startIndex={startIndex}
              endIndex={endIndex}
              Employeeies={filteredEmployee}
            />
          </Grid>
        
          <Pagination
            count={Math.ceil(EmployeeCompany.length / itemsPerPage)}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            className={classes.mt}
          />
        </Grid>
        {/* )} */}
      </Grid>
    </Grid>
  );
};
export default CardList1;
