import React, { Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm.js";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { insertTicket } from "../../redux/TickeType";
import { useSelector } from "react-redux";
export default function TypeTickitForm(props) {
  const dispatch = useDispatch();
  const initialFValues = {
    id_company: props.id_company,
    title: "",
    type: "",
    basic: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
  
    // Ensure that fieldValues and temp are properly initialized
    if (!fieldValues) {
      fieldValues = {};
    }
    if (!temp) {
      temp = {};
    }
  
    if ("type" in fieldValues)
      temp.type = fieldValues.type ? "" : "هذا الحقل مطلوب";
    if ("title" in fieldValues)
      temp.title = fieldValues.title ? "" : "هذا الحقل مطلوب";
  
      if ("basic" in fieldValues) {
        if (fieldValues.basic !== '0' && fieldValues.basic !== '1') {
          temp.basic = "يجب أن تكون القيمة بين 0 و 1";
        } else {
          temp.basic = "";
        }
      }
  
    return temp; // Always return an object
  };
  const { loadingAdd } = useSelector((state) => state.Ticket);
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {

    e.preventDefault();
    const validationErrors = validate();
  setErrors({ ...validationErrors });

  // Check if there are any validation errors
  const hasErrors = Object.values(validationErrors).some((error) => error !== "");

  if (!hasErrors) {
    
    const data = {
      id_company: props.id_company,
      title: values.title,
      type: values.type,
      basic: values.basic,
    };
    dispatch(insertTicket(data));
  };
  };
  const TicketType = [
    { title: "طفل", id: "child" },
    { title: "بالغ", id: "adult" },
  ];
  return (
    <Form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Controls.SelectGender
          className="tesr"
          name="type"
          InputLabelProps={{ shrink: true }}
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/emp/profile-circle.svg"></img>
                </IconButton>
                <span className="placeholdertitle">نوع التذكرة</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.type || ""}
          onChange={handleInputChange}
          options={TicketType}
          error={errors.type}
        />
        <Controls.Input
          className="tesr"
          name="title"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/2fd33edff69c9a7ef5379653d49b8aa1.svg"></img>
                </IconButton>
                <span className="placeholdertitle">اسم التذكرة</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.title}
          onChange={handleInputChange}
          error={errors.title}
        />
        <Controls.Input
          className="tesr"
          name="basic"
          label={
            <Fragment>
              <InputAdornment position="end">
                <IconButton
                  className="iconplaceholder"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <img src="img/2fd33edff69c9a7ef5379653d49b8aa1.svg"></img>
                </IconButton>
                <span className="placeholdertitle">تذكرة اساسية</span>
              </InputAdornment>
            </Fragment>
          }
          value={values.basic}
          onChange={handleInputChange}
          error={errors.basic}
        />
        {loadingAdd ? (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="load loadingupdate"
              style={{ background: "#F9B917", borderRaduis: "10px" }}
            >
              <div v-if="loading" class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="bottonFullwidth"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Controls.Button type="submit" text="إضافة" />
          </div>
        )}
      </Grid>
    </Form>
  );
}
