import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchAllTicket = createAsyncThunk(
  "Ticket/fetchAllTicket",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllTicket/${id}`, {
        withCredentials: true,
      });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const insertTicket = createAsyncThunk(
  "Ticket/insertTicket",
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/addTicket`, values, {
        withCredentials: true,
      });
      sweetalert({
        text: "تم الإضافة بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else if (error.response && error.response.status === 500) {
        // Handle the 500 error server   error specifically
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);
export const insertTicketPrice = createAsyncThunk(
  "Ticket/insertTicketPrice",
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/addTicketPrice`, values, {
        withCredentials: true,
      });
      sweetalert({
        text: "تم الإضافة بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else if (error.response && error.response.status === 500) {
        // Handle the 500 error server   error specifically
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updateTicketPrice = createAsyncThunk(
  "Ticket/updateTicketPrice",
  async (values, thunkAPI) => {
   
    const { rejectWithValue, getState } = thunkAPI;
    const data ={
      tripTypes: values.data
    }
    try {
      await axios.post(`${BaseUrl}/updatePrice/${values.id_ticket}`, data, {
        withCredentials: true,
      }).then((response) => {
      sweetalert({
        text: response.data.data.msg,
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {});
      })

    } catch (error) {
      if (error.response && error.response.status === 403) {
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else if (error.response && error.response.status === 500) {
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updateTicketPrice1 = createAsyncThunk(
  "Ticket/updateTicketPrice1",
  async (values, thunkAPI) => {
    const id = Object.keys(values.price)[0];
    const price = values.price[id].price;
    const reduction = values.price[id].reduction;
    const data = {
      price: price,
      reduction: reduction,
    };
    console.log(data,'data')
    const { rejectWithValue, getState } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/updatePrice/${id}`, data, {
        withCredentials: true,
      });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else if (error.response && error.response.status === 500) {
        sweetalert({
          text: error.response.data.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);
export const deleteTicket = createAsyncThunk(
  "Ticket/deleteTicket",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(`${BaseUrl}/hardDeleteTicket/${id}`, {
        withCredentials: true,
      });
      if (
        response.data &&
        response.data.success === "true" &&
        response.data.data === true
      ) {
        sweetalert({
          text: "تم الحذف بنجاح",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      } else {
        // Handle other responses if needed
        // For example, you can display an error message for failed deletion
        // You can access the specific error message from the response's "data" object
        sweetalert({
          text: response.data.data.original.message,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const TicketSlice = createSlice({
  name: "Ticket",
  initialState: {
    Tickets: [],
    Ticket: [],
    TicketPrice: [],
    updateTicketPrice: [],
    loading: false,
    loadingAdd: false,
    error: null,
  },
  reducers: {
    clearTicket: (state) => {
      state.Tickets = [];
      state.Ticket = [];
      state.TicketPrice = [];
      state.updateTicketPrice = [];
      state.loading = false;
      state.loadingAdd = false;
      state.error = null;
    },
  },
  extraReducers: {
    //Fetch All Ticket
    [fetchAllTicket.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchAllTicket.fulfilled]: (state, action) => {
      state.Tickets = action.payload;
      state.loading = false;
    },
    [fetchAllTicket.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //insert  Ticket
    [insertTicket.pending]: (state, action) => {
      state.loadingAdd = true;
      state.error = null;
    },
    [insertTicket.fulfilled]: (state, action) => {
      state.Ticket.push(action.payload);
      state.loadingAdd = false;
    },
    [insertTicket.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingAdd = false;
    },
    //insert  Ticket Price
    [insertTicketPrice.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [insertTicketPrice.fulfilled]: (state, action) => {
      state.TicketPrice.push(action.payload);
      state.loading = false;
    },
    [insertTicketPrice.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //update Ticket Price
    [updateTicketPrice.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [updateTicketPrice.fulfilled]: (state, action) => {
      state.updateTicketPrice.push(action.payload);
      state.loading = false;
    },
    [updateTicketPrice.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    // delete Ticket
    [deleteTicket.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deleteTicket.fulfilled]: (state, action) => {
    if (action.payload && action.payload.id) {
      state.Tickets = state.Tickets.filter((el) => el.id !== action.payload.id);
    }
    state.loading = false;
  },
    [deleteTicket.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export const {clearTicket, clearTicketState } = TicketSlice.actions;
export default TicketSlice.reducer;
