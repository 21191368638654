import React, { Fragment, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { insertTicketPrice, updateTicketPrice, updateTicketPrice1 } from "../../redux/TickeType";
import SaveAsIcon from "@mui/icons-material/SaveAs";

export default function TypeTripPriceFormUpdate(props) {
  const dispatch = useDispatch();
  const { TripTypeID } = useSelector((state) => state.TripType);
  const [initialFValues, setInitialFValues] = useState({
    id_trip_type: TripTypeID.id,
    price: TripTypeID.price.reduce((acc, ticket) => {
      acc[ticket.id] = {
        id: ticket.id,
        price: ticket.price,
        title: ticket.title,
        reduction: ticket.reduction,
      };
      return acc;
    }, {}),
  });
  useEffect(() => {},[props.activeStep])
  useEffect(() => {
    setInitialFValues({
      id_trip_type: TripTypeID.id,
      price: TripTypeID.price.reduce((acc, ticket) => {
        acc[ticket.id] = {
          id: ticket.id,
          price: ticket.price,
          title: ticket.title,
          reduction: ticket.reduction,
        };
        return acc;
      }, {}),
    });
  }, [TripTypeID]);
  const { values, setValues, errors, setErrors } = useForm(
    initialFValues,
    true
  );

  const [price, setprice] = useState(0);

  const handleInputChange = (e) => {
        const { name, value } = e.target;
        const [_, ticketId, field] = name.match(/price\.(\d+)\.(.+)/);
        setValues(prevState => ({
          ...prevState,
          id_trip_type: TripTypeID.id,
          price: {
            ...prevState.price,
            [ticketId]: {
              ...prevState.price[ticketId],
              [field]: value
            }
          }
        }));
      };
  

 
  const handelSubmitPrice = (ticketId, price,reduction) => {
    const dataToSend = {
      id_trip_type: values.id_trip_type,
      price: {
        [ticketId]: {
          id: ticketId,
          price: Number(price),
          reduction: Number(reduction),
        },
      },
    };
    console.log(dataToSend,'dataToSend')
     dispatch(updateTicketPrice1(dataToSend));
  };
  
  return (
    <Form>
      <Grid item xs={12}>
        <Grid lg={12} md={12}>
          {TripTypeID.price.map((ticket) => (
            <Grid
              key={ticket.id}
              item
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "space-between",
              }}
              lg={12}
              md={12}
            >
              <Grid
                lg={3}
                md={3}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontSize:"13px"
                }}
              >
               نوع التذكرة 
               :   {ticket.title}              
 </Grid>
              <Grid lg={3} md={3}>
                <Controls.Input
                  className="tesr price-ticket-trip-ticket"
                  name={`price.${ticket.id}.price`}
                  label={
                    <Fragment>
                      <InputAdornment position="end">
                        <span className="placeholdertitle">سعر التذكرة</span>
                      </InputAdornment>
                    </Fragment>
                  }
                  value={values.price[ticket.id].price} // Handle the case where price is not defined yet
                  onChange={handleInputChange}
                  error={errors.price}
                />
              </Grid>
              <Grid lg={3} md={3}>
                <Controls.Input
                  className="tesr price-ticket-trip-ticket"
                  name={`price.${ticket.id}.reduction`}
                  label={
                    <Fragment>
                      <InputAdornment position="end">
                        <span className="placeholdertitle">سعر القطع </span>
                      </InputAdornment>
                    </Fragment>
                  }
                  value={values.price[ticket.id].reduction} // Handle the case where price is not defined yet
                  onChange={handleInputChange}
                  error={errors.price}
                />
              </Grid>
              <Grid
                lg={1}
                md={1}
                onClick={() =>
                  handelSubmitPrice(ticket.id, values.price[ticket.id].price,values.price[ticket.id].reduction)
                }
              >
                <SaveAsIcon />
              </Grid>
            </Grid>
          ))}
          <div></div>
        </Grid>
      </Grid>
    </Form>
  );
}
