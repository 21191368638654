import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 3px 6px rgb(225, 255, 255, 0.63) !important",
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex:"100"
  },

  detail: {},

  title: {
    color: "#F9B917 ",
    fontFamily: "beIN ",
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0, 1, 0),
  },
  primary: {
    width: "50px",
    height: "50px",
  },

  widthImg: {
    width: "100% ",
    height: "100%",
  },
  "@media (min-width: 768px) and (max-width:1556px)": {
    primary: {
      width: "50px",
      height: "50px",
      paddingTop: "15%",
    },
    title: {
      color: "#F9B917 ",
      fontFamily: "beIN ",
      padding: 0,
      margin: 0,
    },
  },
}));

export default function Card(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.card}>
      {props.loading ? (
        <div className="spinner1-box" style={{ height: "100%" }}>
          <svg class="spinner1" viewBox="0 0 50 50">
            <circle
              class="path1"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </div>
      ) : (
        <>
          <Grid container xs justify="flex-start">
            <Typography className={classes.primary}>
              <img src={props.primary} className={classes.widthImg} />
            </Typography>
          </Grid>
          <Grid container>
            <Grid container justifyContent="flex-end" className={classes.title}>
              <Typography variant="h6" align="left">
                {props.secondary != null ? props.secondary : "..."}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
}
