import React, { useState, useEffect } from "react";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../component/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { Checkbox, Select, MenuItem } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { Button } from "@mui/material";
import Popup from "../../component/Popup";
import EmployeeForm from "./EmployeeForm.js";
import {
  GridColDef,
  GridFooterContainer,
  GridValueGetterParams,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridPagination,
} from "@mui/x-data-grid";

import SearchBar from "material-ui-search-bar";
import MainButton from "../../component/MainButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchAllTrashedSystemUser,
  fetchEmployee,
} from "../../redux/employeeSlice";
import { fetchAllRoles } from "../../redux/RolesSlice";
import CardTrashed from "./CardTrashed";
import { fetchCompanyById } from "../../redux/companyTransportDataSlice";
const useStyles = makeStyles({
  gridDivide: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "23px",
  },
  "@media (min-width: 768px) and (max-width:1366px)": {
    gridDivide: {
      justifyContent: "center",
      alignItems: "center",
    },
    searchDesign: {
      width: "100%",
    },
  },
  "@media (min-width: 1366px) and (max-width:1556px)": {
    gridDivide: {
      justifyContent: "space-around",
      alignItems: "space-around",
    },
  },
  mt: {
    marginTop: "30px",
  },

  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    height: "45px !important",
    marginBottom: "20px !important",
    paddingRight: "8px",
    flexDirection: "row-reverse",
  },
  searchDesign1: {
    marginBottom: "20px !important",
    height: "45px !important",
  },

  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  contentDivide: {
    width: "100%",
    marginBottom: "40px",
  },
  Marginbox1: {
    marginBottom: "20px !important",
  },
  AddDesign: {
    marginBottom: "20px",
  },
});

const CardListTrashed = ({ id_company }) => {
  const location = useLocation();
  const id = location.state;
  const { loading, TrashedEmployeeies, error } = useSelector(
    (state) => state.Employee
  );
  const { user } = useSelector((state) => state.auth);
  //Add Permission
  const RestorEmpoyee =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "restore.systemUser"
    );

 
  const HardDeleteEmpoyee =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "hard.delete.systemUser"
    );

  
  // Permission as default
  const RestorEmpoyeedefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "restore.systemUser"
    );

  const HardDeleteEmpoyeedefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "restore.systemUser"
    );

 
  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Set the initial page to 1
  const itemsPerPage = 6;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllTrashedSystemUser(id_company));
  }, [dispatch]);
  const classes = useStyles();
  const navigate = useNavigate();

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  ];
  const [filteredData, setFilteredData] = useState(TrashedEmployeeies);
  const [filter, setFilter] = useState(true);
  const [age, setAge] = useState("all");
  const [searched, setSearched] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);

  const requestSearch = (searchedVal) => {
    const filteredRows = filteredData.filter((row) => {
      return row.lastName.toLowerCase().includes(searchedVal.toLowerCase());
    });

    setFilteredData(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const onHandleReset = () => {
    setFilteredData(TrashedEmployeeies);
  };

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <Grid className={classes.gridContainer} item lg={12}>
      <Grid
        container
        spacing={4}
        className={classes.gridContainer}
        justify="center"
      >
        <Grid container xs={12} className={classes.Marginbox1}>
        
        </Grid>
        <Popup
          title="اضافة الموظف"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <EmployeeForm recordForEdit={recordForEdit} />
        </Popup>
        <Grid style={{ display: "flex", flexDirection: "column" }}>
      
          <Grid className={classes.gridDivide}>
 
            <CardTrashed
            loading={loading}
              startIndex={startIndex}
              endIndex={endIndex}
              TrashedEmployeeies={TrashedEmployeeies}
            />
      
          </Grid>
          <Pagination
            count={Math.ceil(TrashedEmployeeies.length / itemsPerPage)}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            className={classes.mt}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CardListTrashed;
