import React, { useState, useEffect } from "react";
import Buses from "./Buses";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../../component/Header";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { Checkbox, Select, MenuItem } from "@material-ui/core";
import Controls from "../../../component/controls/Controls";
import { Button } from "@mui/material";
import PopupBus from "../../../component/PopupBus";
import BusForm from "../BusForm";
import SearchBar from "material-ui-search-bar";
import { useDispatch } from "react-redux";
import { fetchCompanyById } from "../../../redux/companyTransportDataSlice";
import { useSelector } from "react-redux";
import { fetchAllCity } from "../../../redux/CitySlice";
const useStyles = makeStyles({
  gridDivide11: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "6px",
    flexWrap: "wrap",
    padding: "20px 25px",
    background: "#fff",
    width: "91%",
    borderRadius: "10px",
    boxShadow: "0 2px 6px rgba(0,0,0,0.16)",
  },
  "@media (min-width: 768px) and (max-width:1556px)": {
    gridDivide11: {
      justifyContent: "center",
      alignItems: "center",
      gap: "2%",
    },
  },
  mt: {
    marginTop: "30px",
  },

  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "90%",
    flexDirection: "row-reverse",
    paddingRight: "8px",
    height: "38px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
    marginRight: "10%",
  },
  searchDesign1: {},
  "@media (min-width: 600px) and (max-width:1280px)": {
    searchDesign: {
      marginBottom: "15px",
    },
  },
  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  contentDivide: {
    width: "100%",
    marginBottom: "40px",
  },
  Marginbox1: {
    marginBottom: "20px !important",
    maxWidth: "100% !important",
    flexBasis: "100%",
  },
});

const BusList = ({ mylist, Bus, setEbanle, id_company ,loading,setShortcut}) => {
  const { user } = useSelector((state) => state.auth);

  //Add Permission
  const AddBus =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "add.bus"
    );

  // Permission as default
  const AddBusdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "add.bus"
    );
 

  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Set the initial page to 1
  const {  CompaniesData } = useSelector((state) => state.CompanyData);

  const itemsPerPage = 4;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const classes = useStyles();
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    
    dispatch(fetchCompanyById(CompaniesData.id));
  }, [dispatch]);
  useEffect(() => {
    let typedWord = "";
    let typedWord2 = "";
  
    const handleKeyDown = (event) => {
      if (/^[a-zA-Z]$/.test(event.key)) {
        typedWord += event.key.toLowerCase();
        typedWord2 += event.key;
  
        const lastThreeChars = typedWord.substring(typedWord.length - 3);
  
        if (lastThreeChars === 'add') {
          localStorage.setItem("displayshortcut", "1");
          setOpenPopup(true);
          setRecordForEdit(null);
          setShortcut(1);
          typedWord = '';
        } 
      }
    };
  
    document.addEventListener("keydown", handleKeyDown);
  
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  },[]);
  const [filteredData, setFilteredData] = useState(Bus || []);
  const [age, setAge] = useState("all");
  const [searched, setSearched] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const { Cities } = useSelector((state) => state.City);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [selectedfromcityId, setSelectedFromCityId] = useState(null);
  const requestSearch = (searchedVal) => {
    const filteredRows = filteredData.filter((row) => {
      return row.lastName.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setFilteredData(filteredRows);
  };

  const onHandleReset = () => {
    setSelectedFromCityId(null);
    setSearched('');
  };
  function filterBus(Bus) {
    if (selectedfromcityId && searched) {
      return Bus.filter(
        bus =>
          bus.plate_code === selectedfromcityId &&
          String(bus.plate_number).includes(searched)
      );
    } else if (selectedfromcityId) {
      return Bus.filter(bus => bus.plate_code === selectedfromcityId);
    } else if (searched) {
      return Bus.filter(bus => String(bus.plate_number).includes(searched));
    } else {
      return Bus;
    }
  }


  const filterBuss = filterBus(Bus);
  return (
    <Grid className={classes.gridContainer} item lg={12}>
      <Grid
        container
        spacing={4}
        className={classes.gridContainer}
        justify="center"
      >
        <Grid container xs={12} className={classes.Marginbox1}>
          <Grid lg={3} md={3} sm={3} className={classes.searchDesign1}>
            <SearchBar
              className={classes.searchDesign}
              value={searched}
              onChange={setSearched}
              onCancelSearch={() => setSearched('')}
            />
          </Grid>
          <Grid lg={3} md={3} sm={3} className={classes.AddDesign}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className={` ${selectedfromcityId ? 'selects23' : 'selects21'} selected-type-bus`}
            value={selectedfromcityId}
            onChange={(e) => setSelectedFromCityId(e.target.value)}
            >
            {Cities.map(city =>(
            <MenuItem value={city.name_ar} key={city.id}>
              <span>{city.name_ar}</span>
            </MenuItem>
            ))}
          </Select>
          </Grid>
     
          <Grid lg={1} md={1} sm={3} className='button-reload-type-bus'>
            <Button className="reloadButton1" onClick={onHandleReset}>
              <img src="img/reload.svg" />
            </Button>
          </Grid>
          {AddBus === true || AddBusdefault === true ? (
            <Grid lg={2} md={2} sm={2} className={classes.AddDesign} >
              <div className="bottonFullwidth1">
                <Controls.Button
                  type="submit"
                  text="إضافة"
                  onClick={() => {
                    setOpenPopup(true);
                    setRecordForEdit(null);
                  }}
                />
              </div>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <PopupBus
          title="إضافة باص"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setDisplayshortcut={setShortcut}
        >
          <BusForm id_company={id_company} recordForEdit={recordForEdit} />
        </PopupBus>

        <Grid className={classes.gridDivide11}>
  
          <Buses
          loading={loading}
            Bus={filterBuss}
            id_company={id_company}
            startIndex={startIndex}
            endIndex={endIndex}
          />
        
          <Grid lg={12} md={12} sm={12}>
            {Bus && Bus.length > 0 && (
              <Pagination
                count={Math.ceil(Bus.length / itemsPerPage)}
                page={currentPage}
                onChange={(event, page) => setCurrentPage(page)}
                className={classes.mt}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default BusList;
