import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../styles/theme";
import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearauth } from "../redux/authSlice";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { useEffect } from "react";
import { clearCompany } from "../redux/companyTransportSlice";
import { clearCompanyData1 } from "../redux/companyTransportDataSlice";
import { clearActive } from "../redux/ActiveClientAcoount";
import { clearAssignAdminCompany } from "../redux/AddAssignAdminCompany";
import { clearCityEmployee } from "../redux/AddCityBranchEmployee";
import { clearCompanyEmpBranch } from "../redux/AddCompanyEmpBranch";
import { clearBus } from "../redux/busSlice";
import { clearCity } from "../redux/CitySlice";
import { clearComplaints } from "../redux/Complaints";
import { clearEmployeeState } from "../redux/employeeSlice";
import { clearNotifcations } from "../redux/notificationslice";
import { clearOffices } from "../redux/Office";
import { clearPassengers } from "../redux/PasssengersTrip";
import { clearPath } from "../redux/PathSlice";
import { clearTripState } from "../redux/tripsSlice";
import { clearSetsbus } from "../redux/SetsBus";
import { clearRoles } from "../redux/RolesSlice";
import { clearPriveePoliceCompany } from "../redux/prvicepoliceCompany";
import { clearPrivecPolice } from "../redux/prvicepolice";
import { clearProfits } from "../redux/profit";
import packageJson from "../../package.json";
import { clearPermissionState } from "../redux/Permission";
import { clearTripTypeState } from "../redux/tripTypesSlice";
import { clearreservationsState } from "../redux/reservation";
import { clearpassengerState } from "../redux/reservationPassanger";
import { clearSeateState } from "../redux/SeatsSlice";
import { clearSeatType } from "../redux/SeatsTypeSlice";
import { clearTripManulaState } from "../redux/tripsManulDoneSlice";
import { clearTicket, clearTicketState } from "../redux/TickeType";
import { clearTripScheluerState } from "../redux/tripsSchedularSlice";
export const BaseUrl = packageJson.projectURlApi.BaseUrl;
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const removeLocalStorageOnUnload = () => {
    if (navigate.location.pathname !== "/StepPassenger") {
      localStorage.removeItem("passengers");
    }
  };
  useEffect(() => {
    window.addEventListener("beforeunload", removeLocalStorageOnUnload);

    return () => {
      window.removeEventListener("beforeunload", removeLocalStorageOnUnload);
    };
  });
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.redAccent[1000],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const { user } = useSelector((state) => state.auth);
  const login = localStorage.getItem("isLoggedIn");
  const Viewnotifcation =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "view.notification"
    );

  const DisableAccount =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "get.disable.client.account"
    );

  const Viewnotifcationdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "view.notification"
    );

  const DisableAccountdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "get.disable.client.account"
    );

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  
  function logout() {
    // axios
    // .post(`${BaseUrl}/logout`)
    // .then(() => {
    dispatch(clearSeatType());
    dispatch(clearActive());
    dispatch(clearAssignAdminCompany());
    dispatch(clearCityEmployee());
    dispatch(clearCompanyEmpBranch());
    dispatch(clearTripManulaState());
    dispatch(clearBus());
    dispatch(clearSeateState());
    dispatch(clearCity());
    dispatch(clearCompany());
    dispatch(clearCompanyData1());
    dispatch(clearComplaints());
    dispatch(clearEmployeeState());
    dispatch(clearNotifcations());
    dispatch(clearOffices());
    dispatch(clearPassengers());
    dispatch(clearPath());
    dispatch(clearTripState());
    dispatch(clearSetsbus());
    dispatch(clearRoles());
    dispatch(clearPriveePoliceCompany());
    dispatch(clearPrivecPolice());
    dispatch(clearProfits());
    dispatch(clearauth());
    dispatch(clearpassengerState());
    dispatch(clearreservationsState());
    dispatch(clearPermissionState());
    dispatch(clearTripTypeState());
    dispatch(clearTicket());
    dispatch(clearTripScheluerState());
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("activeTab");
    setTimeout(() => {
      window.location.reload();
    }, 500);
    // })

    // .catch((error) => {
    //   // Handle error if needed
    // });
  }
  // const handleClick = () => {

  //   dispatch(clearCompanyData1());
  //   dispatch(clearSeatType());
  //   dispatch(clearActive());
  //   dispatch(clearAssignAdminCompany());
  //   dispatch(clearCityEmployee());
  //   dispatch(clearCompanyEmpBranch());
  //   dispatch(clearTripManulaState());
  //   dispatch(clearBus());
  //   dispatch(clearSeateState());
  //   dispatch(clearComplaints());
  //   dispatch(clearEmployeeState());
  //   dispatch(clearNotifcations());
  //   dispatch(clearOffices());
  //   dispatch(clearPath());
  //   dispatch(clearTripState());
  //   dispatch(clearPriveePoliceCompany());
  //   dispatch(clearPrivecPolice());
  //   dispatch(clearProfits());
  //   dispatch(clearTripTypeState());
  //   dispatch(clearSeatType());
  //   dispatch(clearTicket());
  //   dispatch(clearTripScheluerState());
    
  // };
  return (
    <Box
      className="custom_sidebar"
      sx={{
        "& .pro-sidebar-inner": {
          background: `#03314B !important`,
          height: `8vh !important`,
          padding: '0px 10px'
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "0px !important",
          color: `${colors.redAccent[500]} !important`,
        },
        "& .pro-inner-item button": {
          color: `${colors.redAccent[500]} !important`,
        },
        "& .pro-item-content > p": {
          fontSize: "16px !important",
          fontWeight: "600",
        },
      }}
    >
      <ProSidebar style={{ display: "flex", flexDirection: "row" }}>
        <Menu iconShape="square">
          <ListItemButton
            component={Link}
            to="/TransportCompanyData"
            sx={{ pt: "20px", pr: "40px", mt: "20px", mr: "15px" }}
            className={
              splitLocation[1] == "TransportCompanyData"
                ? "btn-sidebare active"
                : "btn-sidebare"
            }
            activeclassName="active"
            // onClick={handleClick}
          >
            <ListItemText
              className="title-sidebar"
              primary="بيانات شركة النقل"
            />
          </ListItemButton>

          {user != null && user.roles_name === "مسؤول النظام" ? (
            <>
              <ListItemButton
                component={Link}
                to="/company-admin"
                sx={{ pt: "20px", pr: "40px", mt: "20px", mr: "15px" }}
                className={
                  splitLocation[1] == "company-admin"
                    ? "btn-sidebare active"
                    : "btn-sidebare"
                }
                activeclassName="active"
              >
                <ListItemText
                  className="title-sidebar"
                  primary="مدراء النظام"
                />
              </ListItemButton>
              <ListItemButton
                component={Link}
                to="/Admin-trashede"
                sx={{ pt: "20px", pr: "40px", mt: "20px", mr: "15px" }}
                className={
                  splitLocation[1] == "Admin-trashede"
                    ? "btn-sidebare active"
                    : "btn-sidebare"
                }
                activeclassName="active"
              >
                <ListItemText
                  className="title-sidebar"
                  primary="استرجاع المدراء"
                />
              </ListItemButton>
            </>
          ) : (
            <></>
          )}
          {Viewnotifcation === true || Viewnotifcationdefault === true ? (
            <ListItemButton
              component={Link}
              to="/Notification"
              className={
                splitLocation[1] == "Notification"
                  ? "btn-sidebare active"
                  : "btn-sidebare"
              }
              sx={{ pt: "20px", pr: "40px", mt: "20px", mr: "15px" }}
            >
              <ListItemText className="title-sidebar" primary="الإشعارات" />
            </ListItemButton>
          ) : (
            <></>
          )}
          {user != null && user.roles_name == "مسؤول النظام" ? (
            <ListItemButton
              component={Link}
              to="/restore-company"
              className={
                splitLocation[1] == "/restore-company"
                  ? "btn-sidebare active"
                  : "btn-sidebare"
              }
              sx={{ pt: "20px", pr: "40px", mt: "20px", mr: "15px" }}
            >
              <ListItemText className="title-sidebar" primary="استرجاع شركات" />
            </ListItemButton>
          ) : (
            <></>
          )}

          <ListItemButton
            component={Link}
            to="/Complaint"
            className={
              splitLocation[1] == "Complaint"
                ? "btn-sidebare active"
                : "btn-sidebare"
            }
            sx={{ pt: "20px", pr: "40px", mt: "20px", mr: "15px" }}
          >
            <ListItemText className="title-sidebar" primary="الشكاوى" />
          </ListItemButton>

          {DisableAccount === true || DisableAccountdefault === true ? (
            <ListItemButton
              component={Link}
              to="/Deactive-client-account"
              className={
                splitLocation[1] == "Deactive-client-account"
                  ? "btn-sidebare active"
                  : "btn-sidebare"
              }
              sx={{ pt: "20px", pr: "40px", mt: "20px", mr: "15px" }}
            >
              <ListItemText
                className="title-sidebar"
                primary="الحسابات المعطلة"
              />
            </ListItemButton>
          ) : (
            <></>
          )}

          {user != null && user.roles_name === "مسؤول النظام" ? (
            <>
              <ListItemButton
                component={Link}
                to="/privacy-policy"
                className={
                  splitLocation[1] == "/privacy-policy"
                    ? "btn-sidebare active"
                    : "btn-sidebare"
                }
                sx={{ pt: "20px", pr: "40px", mt: "20px", mr: "15px" }}
              >
                <ListItemText
                  className="title-sidebar"
                  primary="سياسة الخصوصية"
                />
              </ListItemButton>
              <ListItemButton
                component={Link}
                to="/profit"
                className={
                  splitLocation[1] == "profit"
                    ? "btn-sidebare active"
                    : "btn-sidebare"
                }
                sx={{ pt: "20px", pr: "40px", mt: "20px", mr: "15px" }}
              >
                <ListItemText className="title-sidebar" primary="النسب" />
              </ListItemButton>
            </>
          ) : (
            <></>
          )}
          <ListItemButton
            onClick={() => logout()}
            component={Link}
            to="/"
            className={
              splitLocation[1] == "logout"
                ? "btn-sidebare active"
                : "btn-sidebare"
            }
            sx={{ pt: "20px", pr: "40px", mt: "20px", mr: "15px" }}
          >
            <ListItemText
              className="title-sidebar"
              primary="تسجيل الخروج"
              onClick={() => logout()}
            />
          </ListItemButton>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
