import { Grid, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useForm } from "../../component/useForm";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CancelIcon from "@mui/icons-material/Cancel";
function InputRowDate({ handleRemove, initialFValues, onDateChange }) {
  const [imageUrl, setImageUrl] = useState();
  const [selectedImage1, setSelectedImage1] = useState();
  const [dateError, setDateError] = useState(""); // Error message for date
  const [date1, setDate1] = React.useState(null);
  const renderInput = (params) => <TextField {...params} name="date" />;

  useEffect(() => {
    if (selectedImage1) {
      setImageUrl(URL.createObjectURL(selectedImage1));
    }
  }, [selectedImage1]);
  const handleDateChange = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setDate1(formattedDate);
    setValues((prevValues) => ({
      ...prevValues,
      date: formattedDate,
    }));
    setDateError("");

    // Call the callback function with the formatted date
    onDateChange(formattedDate);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("Add_certifcation" in fieldValues) {
      if (fieldValues.Add_certifcation == "")
        temp.Add_certifcation = "This field is required.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);
  return (
    <div style={{ width: "100%" }}>
      <Grid
        item
        xs={12}
        lg={12}
        md={12}
        sm={12}
        style={{ display: "flex", gap: "15px", flexDirection: "row-reverse" }}
      >
        <Grid
          item
          xs={3}
          md={3}
          lg={3}
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-evenly",
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={12} md={12} sm={12} className="datetrip">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="اختر تاريخ "
                  value={date1}
                  onChange={handleDateChange}
                  renderInput={renderInput}
                  views={["year", "month", "day"]}
                />
                {dateError && (
                  <p style={{ color: "red", fontSize: "13px" }}>{dateError}</p>
                )}
              </LocalizationProvider>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <div
          className="box-button-remove-trip-pass"
          onClick={() => handleRemove(date1)}
        >
          <CancelIcon />
        </div>
      </Grid>
    </div>
  );
}

export default InputRowDate;
