import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import Card from "@material-ui/core/Card";
import Echo from "laravel-echo";
import socketIOClient from "socket.io-client";
import * as sweetalert from "sweetalert";
import axios from "axios";
import {
  setstatus,
} from "../../redux/reservation";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import packageJson from "../../../package.json";
import Popuppassenger from "../../component/Popuppassenger";
import StepPassenger from "../StepPassengerTrip/StepPassenger";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PhoneIcon from "@mui/icons-material/Phone";
import { fetchTripBYId } from "../../redux/tripsSlice";

import { Grid } from "@material-ui/core";
import Popup from "../../component/Popup";
import ReservationPhone from "./ReservationPhone";
import StepPassengerDeleted from "./StepPassengerDeleted";
import Popuppassenger2 from "../../component/Popuppassenger2";
import Popuppassenger3 from "../../component/Popuppassenger3";
import StepPassengerSwap from "./StepPassengeSwap";
export const key_Shortcut = packageJson.key_Shortcut;
export const BaseUrl = packageJson.projectURlApi.BaseUrl;
const SetRed = ({
  nofocus,
  isAvailable2,
  isAvailable3,
  isAvailable1,
  valueinput,
  id_trip,
  typetrip,
  setShortcut,
  id_company,
  newStatus,
  setNewStatus,
  newStatusAvail,
  setNewStatusAvail,
  setPathtrip,
  schortcut,
  setValueinput,
  setdisabledenter,
  disabledenter,
  setAddReservation1,
  AddReservation1,
  setSelectedRow,
}) => {
  const dispatch = useDispatch();
  const [showDirectTrip, setShowDirectTrip] = useState(false);
  const [title, setTitle] = useState("");
  const [Reservations, setReservations] = useState("");
  const [reservationss, setReservation] = useState("");
  const [id_transit1, setIdTransit1] = useState("");
  const [id_transit2, setIdTransit2] = useState("");
  const [pathTransit, setPathTransit] = useState("");
  const [idTransit, setIDTransit] = useState();
  const [idDirect, setIDdirect] = useState(id_trip);
  const [idTricketPrice, setIdTicketPrice] = useState([]);
  const [directtrip, setdirecttrip] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [openPopup1, setOpenPopup1] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const [numCols, setNumCols] = useState("");
  const [trip_seat_status, setTrip_seat_status] = useState("");

  const [seatNumber, setseatNumber] = useState();
  const [idReservationresponse, setIdReservationResponse] = useState();
  const [transetnumber, settransetnumber] = useState();
  const [showSystemUserReservation, setShowSystemUserReservation] =
    useState(false);
  const [inputString, setInputString] = useState("");
  const [Reservationdirectrip, setReservationdirectrip] = useState();
  const [resultArray, setResultArray] = useState([]);
  const [seatsArray1, setSeatsArray1] = useState([]);
  const [seatsArray2, setSeatsArray2] = useState([]);
  const [seatsByCols, setSeatsByCols] = useState([]);
  const [openPopuppassenger, setOpenPopuppassenger] = useState(false);
  const [openPopuppassenger2, setOpenPopuppassenger2] = useState(false);
  const [isCodeUnlocked, setIsCodeUnlocked] = useState(false);
  const [reservationIds, setReservationIds] = useState([]);
  const [updateSeat, setUpdateSeat] = useState(false);
  const [updatePassenger, setUpdatePassenger] = useState(false);
  const [openopoup1, setOpenopoup1] = useState(false);
  const [transet, settranset] = useState(false);
  const [direct, setDirect] = useState(false);
  const [loadngg, setLoadngg] = useState(false);
  const [response1, setResponse1] = useState([]);
  const [arrayseat, setArraySeat] = useState([]);
  const [InitialIdTransit, setInitialIdTransit] = useState();
  const [arrayseatPassengerTransit1, setArraySeatPassengerTransit1] = useState(
    []
  );
  const [arrayseatPassengerTransit2, setArraySeatPassengerTransit2] = useState(
    []
  );
  const [seatAndUser, setSeatAndUser] = useState([]);
  const [resultArrayseatuser, setResultArrayseatuser] = useState([]);
  const { insertreservation1 } = useSelector((state) => state.reservations);
  const { loading, status, statusNoAvailabel } = useSelector(
    (state) => state.reservations
  );
  const AddReservation =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "add.path");
  const { statusupdatepassenger } = useSelector((state) => state.Passengers);
  const { Trips, TripID, TripReservation } = useSelector((state) => state.Trip);
  const [selectedTransitIndex, setSelectedTransitIndex] = useState();
  const [IDTripType, setIDTripType] = useState(TripID?.id_trip_type);
  const [idtrip, setIDtrip] = useState(TripID.id);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [selectedSeats1, setSelectedSeats1] = useState([]);
  const [selectedSeats2, setSelectedSeats2] = useState([]);
  const [arrayReservations, setArrayReservations] = useState([]);
  const popup = localStorage.getItem("popup");
  const [reservationseat, setReservationseat] = useState([]);
  const [clickFunction, setClickFunction] = useState(false);
  const [statusSeat, setStatusSeat] = useState();
  const [reservationseat1, setReservationseat1] = useState([]);
  const [select1, setSelected1] = useState("");
  const [id_triptran, setidTriptran] = useState(TripID.id);
  const inputRef = useRef(null);
  const handleChange = () => {
    setValueinput(inputRef.current.value);
  };
  useEffect(() => {}, [selectedTransitIndex]);
  useEffect(() => {}, [statusSeat]);
  
  const [select, setSelected] = useState({
    seat_number: "",
    id_system: "",
    id_client: "",
    status: "",
    transitStatus: "",
    numStatus: "",
    id_transit: "",
  });
  let id_triptransit = null;
  const passpopup = localStorage.getItem("openpassenger");
  const DeletedPassPopup = localStorage.getItem("DeletedPassenger");
  const idtransit = localStorage.getItem("idtransit");
  useEffect(() => {
    localStorage.setItem("openpassenger", false);
    localStorage.setItem("changeseat", false);
  }, []);
  useEffect(() => {}, [reservationseat1]);
  useEffect(() => {}, [idReservationresponse]);
  useEffect(() => {
    setArraySeat(arrayseat);
  }, [arrayseat, setArraySeat]);

  useEffect(() => {}, [transetnumber]);
  useEffect(() => {}, [directtrip, idtransit]);

  useEffect(() => {
    setTimeout(() => {
      if (passpopup === "true") {
        setdisabledenter(true);
        setOpenPopuppassenger(true);
      }
      setNewStatusAvail(statusupdatepassenger);

      if (passpopup === "false") {
        setOpenPopuppassenger(false);
        setUpdatePassenger(false);
        setClickFunction(false);
        setdisabledenter(false);
        setResultArray([]);
        localStorage.removeItem("passengers");
      }
    }, 300);
  }, [passpopup]);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (popup == "true") {
      setOpenPopuppassenger(false);
      setClickFunction(false);
      localStorage.setItem("popup", false);
      localStorage.setItem("AddReservation", false);

      setResultArray([]);
    }
    // eslint-disable-next-line eqeqeq
    if (popup == "false") {
      localStorage.removeItem("passengers");
      setUpdatePassenger(false);
      setUpdateSeat(false);
    }
  }, [popup]);

  useEffect(() => {}, [
    setIDdirect,
    setPathTransit,
    setPathtrip,
    settranset,
    setDirect,
    transet,
    direct,
  ]);

  const [color, setColor] = useState("rgb(168, 168, 168)");
  const [Clic, setClick] = useState(false);
  const [Clicseat, setClickseat] = useState(false);
  const numberOfStatus3Seats = countStatus3(trip_seat_status);
  const [commonSeats, setCommonSeats] = useState([]);

  const [seatsStatus, setSeatsStatus] = useState(); // Assuming seatsByCols is your initial seat data

  const updateStatus = (newStatus) => {
    dispatch(setstatus(newStatus));
  };
  const handleConfirm = () => {
    updateStatus(false);
  };

  
  async function setAddReservation() {
    setTitle("إضافة مسافر");
    setReservation("إضافة حجز");
    if (openPopuppassenger === false) {
      const valueInputArray = Array.isArray(valueinput)
        ? valueinput
        : valueinput.split(/[\s,+]+/);
      const combinedSeats = [
        ...selectedSeats,
        ...selectedSeats1,
        ...selectedSeats2,
        ...valueInputArray,
      ]
        .map((seat) => {
          if (typeof seat === "number") return seat;

          return seat.split(/[\s,+]+/).map(Number);
        })
        .flat();

      const sortedNumbers = Array.from(
        new Set(combinedSeats.filter((seat) => !isNaN(seat)))
      );
      const uniqueNumbersWithoutZero = sortedNumbers.filter(
        (number) => number !== 0
      );
      const seatNumberToSend = uniqueNumbersWithoutZero.sort((a, b) => a - b);
      setResultArray(seatNumberToSend);
      const isTransitTrip =
        TripID.tripTransit &&
        TripID.tripTransit.some((transit) => {
          return transit.reservationSeats.hasOwnProperty(seatNumberToSend[0]);
        });

      if (isTransitTrip) {
        const status = reservationseat1[seatNumberToSend[0]];
        if (status == 1) {
          setResultArray([]);
          sweetalert({
            text: "لا يمكن إضافة حجز لان الكرسي محجوز",
            icon: "error",
            buttons: {
              confirm: {
                text: "إغلاق",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
        } 
        else if (status == undefined || status == '') {
          setResultArray([]);
          sweetalert({
            text: "يجب إختيار كراسي قبل القيام بهذه العملية",
            icon: "error",
            buttons: {
              confirm: {
                text: "إغلاق",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
        }
        else if (status == 2) {
          setResultArray([]);
          sweetalert({
            text: "لا يمكن إضافة حجز لان الكرسي محجوز",
            icon: "error",
            buttons: {
              confirm: {
                text: "إغلاق",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
        } else {
          settranset(true);
          const data = {
            triptable_id: idTransit,
            trip_id: TripID.id,
            triptable_type: "Domain\\TripTransit",
            seat_number: seatNumberToSend,
            branch: TripID.id_branch,
          };
      
          
          await axios
            .post(`${BaseUrl}/addReservation`, data, {
              withCredentials: true,
            })
            .then((response) => {
              const responseData = response.data.data;
              setIdReservationResponse(responseData);
              if (!arrayReservations[responseData.id]) {
                arrayReservations[responseData.id] = responseData;
              }
              setReservationseat1(prevState => {
                // Create a new state object by copying the previous state
                const updatedState = {...prevState};
          
                // Iterate over each key in the responseData's reservation_seats_number
                Object.keys(responseData.reservation_seats_number).forEach(seatNumber => {
                  // Update the specific seat number with the new status from reservation_seats_number
                  // Convert seatNumber to an integer if your state keys are integers
                  const seatNum = parseInt(seatNumber);
                  if (!isNaN(seatNum)) {
                    updatedState[seatNum] = responseData.reservation_seats_number[seatNumber] // assuming this holds the new status
                    
                  }
                });
               
                // Return the updated state
                return updatedState;
              });
              localStorage.setItem("openpassenger", true);
              localStorage.setItem("AddReservation", false);
            })
            .catch((error) => {
              // Handle the error here
              setResponse1([]);
              dispatch(fetchTripBYId(TripID.id));
              setResponse1(TripID.reservation_seats);
            });
          setInputString("");
          setValueinput("");
          setSelectedSeats1([]);
          setSelectedSeats([]);
          setSeatAndUser([]);
  
        }
      } else {
        const status = response1[seatNumberToSend[0]];
        const isTransitTrip1 =
          TripID.tripTransit &&
          TripID.tripTransit.some((transit) => {
            return transit.reservationSeats.hasOwnProperty(selectedSeats[0]);
          });

        // eslint-disable-next-line eqeqeq
        if (isTransitTrip1 == true) {
          sweetalert({
            title: "خطأ في العملية",
            text: "  ",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          });
        } else {

          if (status == 1) {
            setResultArray([]);
            sweetalert({
              text: "لا يمكن إضافة حجز لان الكرسي محجوز",
              icon: "error",
              buttons: {
                confirm: {
                  text: "إغلاق",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
          }
          else if (status == undefined || status == '') {
            setResultArray([]);
            sweetalert({
              text: "يجب إختيار كراسي قبل القيام بهذه العملية",
              icon: "error",
              buttons: {
                confirm: {
                  text: "إغلاق",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
          }
           else if (status == 2) {
            setResultArray([]);
            sweetalert({
              text: "لا يمكن إضافة حجز لان الكرسي محجوز",
              icon: "error",
              buttons: {
                confirm: {
                  text: "إغلاق",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
          }
           else {
            const data = {
              triptable_id: TripID.id,
              trip_id: TripID.id,
              triptable_type: "Domain\\Trip",
              seat_number: seatNumberToSend,
              branch: TripID.id_branch,
            };
     
            setDirect(true);
            settranset(false);
            await axios
              .post(`${BaseUrl}/addReservation`, data, {
                withCredentials: true,
              })
              .then((response) => {
                const responseData = response.data.data;
                setIdReservationResponse(responseData);

                if (!arrayReservations[responseData.id]) {
                  arrayReservations[responseData.id] = responseData;
                }
                setResponse1(prevState => {
                  // Create a new state object by copying the previous state
                  const updatedState = {...prevState};
            
                  // Iterate over each key in the responseData's reservation_seats_number
                  Object.keys(responseData.reservation_seats_number).forEach(seatNumber => {
                    // Update the specific seat number with the new status from reservation_seats_number
                    // Convert seatNumber to an integer if your state keys are integers
                    const seatNum = parseInt(seatNumber);
                    if (!isNaN(seatNum)) {
                      updatedState[seatNum] = responseData.reservation_seats_number[seatNumber] // assuming this holds the new status
                      
                    }
                  });
                 
                  // Return the updated state
                  return updatedState;
                });

                setArraySeat((prevSeatsInfo) => {
                  const updatedSeatsInfo = { ...prevSeatsInfo };

                  seatNumberToSend.forEach((number) => {
                    const seatNumber = parseInt(number);

                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: {}, // Assuming you want to reset passengers info
                        id_reservation: responseData.id,
                        status: 1, // Resetting the status
                      };
                    }
                  });

                  return updatedSeatsInfo;
                });
                localStorage.setItem("openpassenger", true);
                localStorage.setItem("AddReservation", false);
              })
              .catch((error) => {
                // Handle the error here
                setResponse1([]);
                dispatch(fetchTripBYId(TripID.id));
                setResponse1(TripID.reservation_seats);
              });
            setInputString("");
            setValueinput("");
            setSelectedSeats1([]);
            setSelectedSeats([]);
            setSelectedSeats([]);

          }

        }
      }
    }

  }

  async function setAddReservationManager() {
    const sortedNumbers = selectedSeats;
    setResultArray(selectedSeats);
    setSelectedSeats1(selectedSeats);
    localStorage.setItem("changeseat", true);
    const valueInputArray = Array.isArray(valueinput)
      ? valueinput
      : valueinput.split(/[\s,+]+/);
    const combinedSeats = [
      ...selectedSeats,
      ...selectedSeats1,
      ...selectedSeats2,
      ...valueInputArray,
    ]
      .map((seat) => {
        if (typeof seat === "number") return seat;

        return seat.split(/[\s,+]+/).map(Number);
      })
      .flat();

    const sortedNumbers1 = Array.from(
      new Set(combinedSeats.filter((seat) => !isNaN(seat)))
    );
    const uniqueNumbersWithoutZero = sortedNumbers1.filter(
      (number) => number !== 0
    );
    const seatNumberToSend = uniqueNumbersWithoutZero.sort((a, b) => a - b);

    const isTransitTrip =
      TripID.tripTransit &&
      TripID.tripTransit.some((transit) => {
        return transit.reservationSeats.hasOwnProperty(seatNumberToSend[0]);
      });
    if (isTransitTrip) {
      const status = reservationseat1[seatNumberToSend[0]];

      if (status == 1) {
        sweetalert({
          text: "لا يمكن تحويل الكرسي لإدارة لانه مضاف لحجز معين",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } 
      else if (status == undefined || status == '') {
        setResultArray([]);
        sweetalert({
          text: "يجب إختيار كراسي قبل القيام بهذه العملية",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      }
      else if (status == 2) {
        sweetalert({
          text: "لا يمكن تحويل الكرسي لإدارة لانه مضاف لحجز معين",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else if (status == 3 || status == 4) {
        sweetalert({
          text: "لا يمكن القيام بهذه العملية",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else {
        const data = {
          branch: TripID.id_branch,
          tripid: TripID.id,
          triptable_id: idTransit,
          triptable_type: "Domain\\TripTransit",
          seat_number: seatNumberToSend,
          status: 3,
        };


        await axios
          .post(`${BaseUrl}/changeStatusSeat`, data, {
            withCredentials: true,
          })
          .then((response) => {
            if (response.data.status === 200) {
              setReservationseat1(response.data.data[0]);
              const mappedById = response.data.reservations.reduce(
                (acc, item) => {
                  acc[item.id] = item;
                  return acc;
                },
                {}
              );
              setArrayReservations(mappedById);
              if (selectedTransitIndex == 0) {
                let seatWithPassengerTransit1 = [];
                // Safely access the reservationSeats property
                seatWithPassengerTransit1 = Object.keys(
                  response.data.data[0]
                ).reduce((accumulator, currentKey) => {
                  // Assuming you want to do something with the keys here
                  const reservationInfo = response.data.reservations.find(
                    (reservation) =>
                      reservation.reservation_seats_number &&
                      reservation.reservation_seats_number[currentKey] > 0
                  );

                  const passenger = reservationInfo
                    ? reservationInfo.passengers?.find(
                        (p) => p.number_seat == currentKey
                      )
                    : null;

                  accumulator[currentKey] = {
                    passengers: passenger ? Object.assign({}, passenger) : null, // safely copy passenger object
                    status:
                      reservationInfo?.reservation_seats_number[currentKey],
                    id_reservation: reservationInfo?.id,
                  };

                  return accumulator;
                }, {});
               
                const exampleData = {
                  ...seatWithPassengerTransit1, // This is your existing seat data
                  id: TripID.tripTransit[0].id, // Ensure you are actually setting the id like this
                };
                setArraySeatPassengerTransit1(exampleData);
              }
              if (selectedTransitIndex == 1) {
                let seatWithPassengerTransit2 = [];
                // Safely access the reservationSeats property
                seatWithPassengerTransit2 = Object.keys(
                  response.data.data[0]
                ).reduce((accumulator, currentKey) => {
                  // Assuming you want to do something with the keys here
                  const reservationInfo = response.data.reservations.find(
                    (reservation) =>
                      reservation.reservation_seats_number &&
                      reservation.reservation_seats_number[currentKey] > 0
                  );

                  const passenger = reservationInfo
                    ? reservationInfo.passengers?.find(
                        (p) => p.number_seat == currentKey
                      )
                    : null;

                  accumulator[currentKey] = {
                    passengers: passenger ? Object.assign({}, passenger) : null, // safely copy passenger object
                    status:
                      reservationInfo?.reservation_seats_number[currentKey],
                    id_reservation: reservationInfo?.id,
                  };

                  return accumulator;
                }, {});
    
                const exampleData = {
                  ...seatWithPassengerTransit2, // This is your existing seat data
                  id: TripID.tripTransit[1].id, // Ensure you are actually setting the id like this
                };
                setArraySeatPassengerTransit2(exampleData);
              }
              const newDirectSelectedSeats = [];
              const transitSeatNumbers = new Set();
              if (TripID.tripTransit && TripID.tripTransit.length > 0) {
                TripID.tripTransit.forEach((transit) => {
                  Object.keys(transit.reservationSeats).forEach(
                    (seatNumber) => {
                      transitSeatNumbers.add(seatNumber);
                    }
                  );
                });
              }
              Object.keys(response.data.data[1]).forEach((seatNumber) => {
                const seatDetails = response.data.data[1][seatNumber];
                if (
                  seatDetails.numStatus === 5 &&
                  response1[seatNumber] !== undefined &&
                  !transitSeatNumbers.has(seatNumber)
                ) {
                  newDirectSelectedSeats.push({
                    seat_number: parseInt(seatNumber),
                    id_system: seatDetails.id_system,
                    numStatus: seatDetails.numStatus,
                  });
                }
              });
              const reservationSeats = response.data.data[0];
                      const seatStatusAuth = response.data.data[1];
                      Object.keys(response.data.data[0]).forEach((seatNumber) => {
                        const status = reservationSeats[seatNumber];
               
                        if (status === 5 && !seatStatusAuth[seatNumber]) {
                          newDirectSelectedSeats.push({
                            seat_number: parseInt(seatNumber),
                            id_system: 1,
                            numStatus: status,
                            id_client: 0,
                            status: "Selected",
                            transitStatus: null,
                            id_transit: idtrip,
                            trip_id: TripID.id,
                          });
                        }
                      });
              const currentUserReservedSeats = newDirectSelectedSeats.filter(
                (userSeat) =>
                  userSeat.id_system === user.user_id && userSeat.numStatus == 5
              );
              const currentUserReservedSeatNumbers =
                currentUserReservedSeats.map((seat) => seat.seat_number);
              setSelectedSeats(currentUserReservedSeatNumbers);
              setSeatAndUser(newDirectSelectedSeats);
              setSelected(newDirectSelectedSeats);
              setResultArrayseatuser(newDirectSelectedSeats);
              const sortedCombinedSeats = currentUserReservedSeatNumbers.sort(
                (a, b) => a - b
              );
              setValueinput(sortedCombinedSeats.join("+"));
              sweetalert({
                text: "تم حجز الكراسي من قبل الادارة",
                icon: "success",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
          })
          .catch((error) => {
            // Handle the error here
            setResponse1([]);
            dispatch(fetchTripBYId(TripID.id));
            setResponse1(TripID.reservation_seats);
          });
        setInputString("");
        setValueinput("");
        setSelectedSeats1([]);
        setSelectedSeats([]);
      

      }
    } else {
      const status = response1[seatNumberToSend[0]];

      if (status == 1) {
        sweetalert({
          text: "لا يمكن تحويل الكرسي لإدارة لانه مضاف لحجز معين",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      }
      else if (status == undefined || status == '') {
        setResultArray([]);
        sweetalert({
          text: "يجب إختيار كراسي قبل القيام بهذه العملية",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      }
      else if (status == 2) {
        sweetalert({
          text: "لا يمكن تحويل الكرسي لإدارة لانه مضاف لحجز معين",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else if (status == 3 || status == 4) {
        sweetalert({
          text: "لا يمكن القيام بهذه العملية",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else {
        const data = {
          triptable_id: idDirect,
          tripid: TripID.id,
          triptable_type: "Domain\\Trip",
          seat_number: seatNumberToSend,
          status: 3,
          branch: TripID.id_branch,
        };
 

        await axios
          .post(`${BaseUrl}/changeStatusSeat`, data, {
            withCredentials: true,
          })
          .then((response) => {
            if (response.data.status === 200) {
              setResponse1(response.data.data[0]);
              const mappedById = response.data.reservations.reduce(
                (acc, item) => {
                  acc[item.id] = item;
                  return acc;
                },
                {}
              );
              setArrayReservations(mappedById);
              let passenger;
              let seatWithPassengerNull = Object.keys(
                response.data.data[0]
              ).reduce((accumulator, currentKey) => {
                const reservationInfo = response.data.reservations.find(
                  (reservation) =>
                    reservation.reservation_seats_number[currentKey] > 0
                );
                passenger = reservationInfo
                  ? reservationInfo.passengers?.find(
                      (p) => p.number_seat == currentKey
                    )
                  : null;

                accumulator[currentKey] = {
                  passengers: Object(passenger),
                  status: reservationInfo?.reservation_seats_number[currentKey],
                  id_reservation: reservationInfo?.id,
                };
                return accumulator;
              }, {});

              setArraySeat(seatWithPassengerNull);
              const newDirectSelectedSeats = [];
              const transitSeatNumbers = new Set();
              if (TripID.tripTransit && TripID.tripTransit.length > 0) {
                TripID.tripTransit.forEach((transit) => {
                  Object.keys(transit.reservationSeats).forEach(
                    (seatNumber) => {
                      transitSeatNumbers.add(seatNumber);
                    }
                  );
                });
              }
              Object.keys(response.data.data[1]).forEach((seatNumber) => {
                const seatDetails = response.data.data[1][seatNumber];
                if (
                  seatDetails.numStatus === 5 &&
                  response1[seatNumber] !== undefined &&
                  !transitSeatNumbers.has(seatNumber)
                ) {
                  newDirectSelectedSeats.push({
                    seat_number: parseInt(seatNumber),
                    id_system: seatDetails.id_system,
                    numStatus: seatDetails.numStatus,
                  });
                }
              });
              const reservationSeats = response.data.data[0];
              const seatStatusAuth = response.data.data[1];
              Object.keys(response.data.data[0]).forEach((seatNumber) => {
                const status = reservationSeats[seatNumber];
       
                if (status === 5 && !seatStatusAuth[seatNumber]) {
                  newDirectSelectedSeats.push({
                    seat_number: parseInt(seatNumber),
                    id_system: 1,
                    numStatus: status,
                    id_client: 0,
                    status: "Selected",
                    transitStatus: null,
                    id_transit: null,
                    trip_id: idtrip,
                  });
                }
              });
              const currentUserReservedSeats = newDirectSelectedSeats.filter(
                (userSeat) =>
                  userSeat.id_system === user.user_id && userSeat.numStatus == 5
              );
              const currentUserReservedSeatNumbers =
                currentUserReservedSeats.map((seat) => seat.seat_number);
              setSelectedSeats(currentUserReservedSeatNumbers);
              setSeatAndUser(newDirectSelectedSeats);
              setSelected(newDirectSelectedSeats);
              setResultArrayseatuser(newDirectSelectedSeats);
              const sortedCombinedSeats = currentUserReservedSeatNumbers.sort(
                (a, b) => a - b
              );
              setValueinput(sortedCombinedSeats.join("+"));
              sweetalert({
                text: "تم حجز الكراسي من قبل الادارة",
                icon: "success",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
          })
          .catch((error) => {
            // Handle the error here
            setResponse1([]);
            dispatch(fetchTripBYId(TripID.id));
            setResponse1(TripID.reservation_seats);
          });
        setInputString("");
        setValueinput("");
        setSelectedSeats1([]);
        setSelectedSeats2([]);
        setResultArray([]);
        setSelectedSeats([]);
      }
    }
 
  }

  async function setAddReservationAvailable() {
    const valueInputArray = Array.isArray(valueinput)
      ? valueinput
      : valueinput.split(/[\s,+]+/);
    const combinedSeats = [
      ...selectedSeats,
      ...selectedSeats1,
      ...selectedSeats2,
      ...valueInputArray,
    ]
      .map((seat) => {
        if (typeof seat === "number") return seat;

        return seat.split(/[\s,+]+/).map(Number);
      })
      .flat();

    const sortedNumbers1 = Array.from(
      new Set(combinedSeats.filter((seat) => !isNaN(seat)))
    );
    const uniqueNumbersWithoutZero = sortedNumbers1.filter(
      (number) => number !== 0
    );
    const seatNumberToSend = uniqueNumbersWithoutZero.sort((a, b) => a - b);

    setResultArray(seatNumberToSend);
    localStorage.setItem("changeseat", true);

    const isTransitTrip =
      TripID.tripTransit &&
      TripID.tripTransit.some((transit) => {
        return transit.reservationSeats.hasOwnProperty(seatNumberToSend[0]);
      });
    if (isTransitTrip) {
      const status = reservationseat1[seatNumberToSend[0]];
      if (status == 5 || status == 0) {
        sweetalert({
          text: "لا يمكن تحول الكرسي لمتوفر لإنه متوفر فعلاً",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });

      } 
      else if (status == undefined || status == '') {
        setResultArray([]);
        sweetalert({
          text: "يجب إختيار كراسي قبل القيام بهذه العملية",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      }
      else if (status == 1) {
        setResultArray([]);
        sweetalert({
          text: "لا يمكن تحويل الكرسي لمتوفر لانه مضاف لحجز معين",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else if (status == 2) {
        setResultArray([]);
        sweetalert({
          text: "لا يمكن تحويل الكرسي لمتوفر ",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else {
        const data = {
          triptable_id: idTransit,
          triptable_type: "Domain\\TripTransit",
          seat_number: seatNumberToSend,
          status: 0,
          branch: TripID.id_branch,
          tripid: TripID.id,
        };
   

        await axios
          .post(`${BaseUrl}/changeStatusSeat`, data, {
            withCredentials: true,
          })
          .then((response) => {
            if (response.data.status === 200) {
              setReservationseat1(response.data.data[0]);
              const mappedById = response.data.reservations.reduce(
                (acc, item) => {
                  acc[item.id] = item;
                  return acc;
                },
                {}
              );
              setArrayReservations(mappedById);
              if (selectedTransitIndex == 0) {
                let seatWithPassengerTransit1 = [];
                // Safely access the reservationSeats property
                seatWithPassengerTransit1 = Object.keys(
                  response.data.data[0]
                ).reduce((accumulator, currentKey) => {
                  // Assuming you want to do something with the keys here
                  const reservationInfo = response.data.reservations.find(
                    (reservation) =>
                      reservation.reservation_seats_number &&
                      reservation.reservation_seats_number[currentKey] > 0
                  );

                  const passenger = reservationInfo
                    ? reservationInfo.passengers?.find(
                        (p) => p.number_seat == currentKey
                      )
                    : null;

                  accumulator[currentKey] = {
                    passengers: passenger ? Object.assign({}, passenger) : null, // safely copy passenger object
                    status:
                      reservationInfo?.reservation_seats_number[currentKey],
                    id_reservation: reservationInfo?.id,
                  };

                  return accumulator;
                }, {});

                const exampleData = {
                  ...seatWithPassengerTransit1, // This is your existing seat data
                  id: TripID.tripTransit[0].id, // Ensure you are actually setting the id like this
                };
                setArraySeatPassengerTransit1(exampleData);
              }
              if (selectedTransitIndex == 1) {
                let seatWithPassengerTransit2 = [];
                // Safely access the reservationSeats property
                seatWithPassengerTransit2 = Object.keys(
                  response.data.data[0]
                ).reduce((accumulator, currentKey) => {
                  // Assuming you want to do something with the keys here
                  const reservationInfo = response.data.reservations.find(
                    (reservation) =>
                      reservation.reservation_seats_number &&
                      reservation.reservation_seats_number[currentKey] > 0
                  );

                  const passenger = reservationInfo
                    ? reservationInfo.passengers?.find(
                        (p) => p.number_seat == currentKey
                      )
                    : null;

                  accumulator[currentKey] = {
                    passengers: passenger ? Object.assign({}, passenger) : null, // safely copy passenger object
                    status:
                      reservationInfo?.reservation_seats_number[currentKey],
                    id_reservation: reservationInfo?.id,
                  };

                  return accumulator;
                }, {});
      
                const exampleData = {
                  ...seatWithPassengerTransit2, // This is your existing seat data
                  id: TripID.tripTransit[1].id, // Ensure you are actually setting the id like this
                };
                setArraySeatPassengerTransit2(exampleData);
              }
              const newDirectSelectedSeats = [];
              const transitSeatNumbers = new Set();
              if (TripID.tripTransit && TripID.tripTransit.length > 0) {
                TripID.tripTransit.forEach((transit) => {
                  Object.keys(transit.reservationSeats).forEach(
                    (seatNumber) => {
                      transitSeatNumbers.add(seatNumber);
                    }
                  );
                });
              }
              Object.keys(response.data.data[1]).forEach((seatNumber) => {
                const seatDetails = response.data.data[1][seatNumber];
                if (
                  seatDetails.numStatus === 5 &&
                  response1[seatNumber] !== undefined &&
                  !transitSeatNumbers.has(seatNumber)
                ) {
                  newDirectSelectedSeats.push({
                    seat_number: parseInt(seatNumber),
                    id_system: seatDetails.id_system,
                    numStatus: seatDetails.numStatus,
                  });
                }
              });
              const reservationSeats = response.data.data[0];
              const seatStatusAuth = response.data.data[1];
              Object.keys(response.data.data[0]).forEach((seatNumber) => {
                const status = reservationSeats[seatNumber];
       
                if (status === 5 && !seatStatusAuth[seatNumber]) {
                  newDirectSelectedSeats.push({
                    seat_number: parseInt(seatNumber),
                    id_system: 1,
                    numStatus: status,
                    id_client: 0,
                    status: "Selected",
                    transitStatus: null,
                    id_transit: idtrip,
                    trip_id: TripID.id,
                  });
                }
              });
              const currentUserReservedSeats = newDirectSelectedSeats.filter(
                (userSeat) =>
                  userSeat.id_system === user.user_id && userSeat.numStatus == 5
              );
              const currentUserReservedSeatNumbers =
                currentUserReservedSeats.map((seat) => seat.seat_number);
              setSelectedSeats(currentUserReservedSeatNumbers);
              setSeatAndUser(newDirectSelectedSeats);
              setSelected(newDirectSelectedSeats);
              setResultArrayseatuser(newDirectSelectedSeats);
              const sortedCombinedSeats = currentUserReservedSeatNumbers.sort(
                (a, b) => a - b
              );
              setValueinput(sortedCombinedSeats.join("+"));
              sweetalert({
                text: "تم تحويل لمتوفر بنجاح",
                icon: "success",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
          })
          .catch((error) => {
            // Handle the error here
            setResponse1([]);
            dispatch(fetchTripBYId(TripID.id));
            setResponse1(TripID.reservation_seats);
          });
        localStorage.setItem("changeseat", false);
        setInputString("");
        setValueinput("");
        setSelectedSeats1([]);
        setResultArray([]);
        setSelectedSeats([]);
        // setSeatAndUser([]);
      }
    } else if (!isTransitTrip) {
      const status = response1[seatNumberToSend[0]];
      if (status == 5 || status == 0) {
        sweetalert({
          text: "لا يمكن تحول الكرسي لمتوفر لإنه متوفر فعلاً",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
  
      } 
      else if (status == undefined || status == '') {
        setResultArray([]);
        sweetalert({
          text: "يجب إختيار كراسي قبل القيام بهذه العملية",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      }
      else if (status == 1) {
        setResultArray([]);
        sweetalert({
          text: "لا يمكن تحويل الكرسي لمتوفر لانه مضاف لحجز معين",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else if (status == 2) {
        setResultArray([]);
        sweetalert({
          text: "لا يمكن تحويل الكرسي لمتوفر ",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else {
        const data = {
          triptable_id: idDirect,
          triptable_type: "Domain\\Trip",
          seat_number: seatNumberToSend,
          status: 0,
          branch: TripID.id_branch,
          tripid: TripID.id,
        };
       
        const callback = () => {
          dispatch(setResultArray([])); // Dispatch the action to update the state
        };
        await axios
          .post(`${BaseUrl}/changeStatusSeat`, data, {
            withCredentials: true,
          })
          .then((response) => {
            if (response.data.status === 200) {
              setResponse1(response.data.data[0]);
              const mappedById = response.data.reservations.reduce(
                (acc, item) => {
                  acc[item.id] = item;
                  return acc;
                },
                {}
              );
              setArrayReservations(mappedById);
              let passenger;
              let seatWithPassengerNull = Object.keys(
                response.data.data[0]
              ).reduce((accumulator, currentKey) => {
                const reservationInfo = response.data.reservations.find(
                  (reservation) =>
                    reservation.reservation_seats_number[currentKey] > 0
                );
                passenger = reservationInfo
                  ? reservationInfo.passengers?.find(
                      (p) => p.number_seat == currentKey
                    )
                  : null;

                accumulator[currentKey] = {
                  passengers: Object(passenger),
                  status: reservationInfo?.reservation_seats_number[currentKey],
                  id_reservation: reservationInfo?.id,
                };
                return accumulator;
              }, {});

              setArraySeat(seatWithPassengerNull);
              const newDirectSelectedSeats = [];
              const transitSeatNumbers = new Set();
              if (TripID.tripTransit && TripID.tripTransit.length > 0) {
                TripID.tripTransit.forEach((transit) => {
                  Object.keys(transit.reservationSeats).forEach(
                    (seatNumber) => {
                      transitSeatNumbers.add(seatNumber);
                    }
                  );
                });
              }
              Object.keys(response.data.data[1]).forEach((seatNumber) => {
                const seatDetails = response.data.data[1][seatNumber];
                if (
                  seatDetails.numStatus === 5 &&
                  response1[seatNumber] !== undefined &&
                  !transitSeatNumbers.has(seatNumber)
                ) {
                  newDirectSelectedSeats.push({
                    seat_number: parseInt(seatNumber),
                    id_system: seatDetails.id_system,
                    numStatus: seatDetails.numStatus,
                  });
                }
              });
              const reservationSeats = response.data.data[0];
              const seatStatusAuth = response.data.data[1];
              Object.keys(response.data.data[0]).forEach((seatNumber) => {
                const status = reservationSeats[seatNumber];
       
                if (status === 5 && !seatStatusAuth[seatNumber]) {
                  newDirectSelectedSeats.push({
                    seat_number: parseInt(seatNumber),
                    id_system: 1,
                    numStatus: status,
                    id_client: 0,
                    status: "Selected",
                    transitStatus: null,
                    id_transit: null,
                    trip_id: idtrip,
                  });
                }
              });
              const currentUserReservedSeats = newDirectSelectedSeats.filter(
                (userSeat) =>
                  userSeat.id_system === user.user_id && userSeat.numStatus == 5
              );
              const currentUserReservedSeatNumbers =
                currentUserReservedSeats.map((seat) => seat.seat_number);
              setSelectedSeats(currentUserReservedSeatNumbers);
              setSeatAndUser(newDirectSelectedSeats);
              setSelected(newDirectSelectedSeats);
              setResultArrayseatuser(newDirectSelectedSeats);
              const sortedCombinedSeats = currentUserReservedSeatNumbers.sort(
                (a, b) => a - b
              );
              setValueinput(sortedCombinedSeats.join("+"));
              sweetalert({
                text: "تم تحويل لمتوفر بنجاح",
                icon: "success",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
          })
          .catch((error) => {
            // Handle the error here
            setResponse1([]);
            dispatch(fetchTripBYId(TripID.id));
            setResponse1(TripID.reservation_seats);
          });
        setInputString("");
        setValueinput("");
        setSelectedSeats1([]);
        setResultArray([]);
        setSelectedSeats([]);
        setSelectedSeats2([]);
      }
  
    }
  }

  async function setAddReservationNoData() {
    const valueInputArray = Array.isArray(valueinput)
      ? valueinput
      : valueinput.split(/[\s,+]+/);
    const combinedSeats = [
      ...selectedSeats,
      ...selectedSeats1,
      ...selectedSeats2,
      ...valueInputArray,
    ]
      .map((seat) => {
        if (typeof seat === "number") return seat;

        return seat.split(/[\s,+]+/).map(Number);
      })
      .flat();

    const sortedNumbers1 = Array.from(
      new Set(combinedSeats.filter((seat) => !isNaN(seat)))
    );
    const uniqueNumbersWithoutZero = sortedNumbers1.filter(
      (number) => number !== 0
    );
    const seatNumberToSend = uniqueNumbersWithoutZero.sort((a, b) => a - b);
    setResultArray(seatNumberToSend);
    localStorage.setItem("changeseat", true);
    const isTransitTrip =
      TripID.tripTransit &&
      TripID.tripTransit.some((transit) => {
        return transit.reservationSeats.hasOwnProperty(seatNumberToSend[0]);
      });

    if (isTransitTrip) {
      const status = reservationseat1[seatNumberToSend[0]];

      if (status == 1) {
        setResultArray([]);
        sweetalert({
          text: "لا يمكن حجز الكرسي شركة  لانه مضاف لحجز معين",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } 
      else if (status == undefined || status == '') {
        setResultArray([]);
        sweetalert({
          text: "يجب إختيار كراسي قبل القيام بهذه العملية",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      }
      else if (status == 3 || status == 4) {
        setResultArray([]);
        sweetalert({
          text: "لا يمكن القيام بهذه العملية",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else if (status == 2) {
        setResultArray([]);
        sweetalert({
          text: "لا يمكن حجز الكرسي شركة  لانه مضاف لحجز معين",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else {
        const data = {
          triptable_id: idTransit,
          tripid: TripID.id,
          triptable_type: "Domain\\TripTransit",
          seat_number: seatNumberToSend,
          status: 4,
          branch: TripID.id_branch,
        };
    

        await axios
          .post(`${BaseUrl}/changeStatusSeat`, data, {
            withCredentials: true,
          })
          .then((response) => {
            if (response.data.status === 200) {
              setReservationseat1(response.data.data[0]);
              const mappedById = response.data.reservations.reduce(
                (acc, item) => {
                  acc[item.id] = item;
                  return acc;
                },
                {}
              );
              setArrayReservations(mappedById);
              if (selectedTransitIndex == 0) {
                let seatWithPassengerTransit1 = [];
                // Safely access the reservationSeats property
                seatWithPassengerTransit1 = Object.keys(
                  response.data.data[0]
                ).reduce((accumulator, currentKey) => {
                  // Assuming you want to do something with the keys here
                  const reservationInfo = response.data.reservations.find(
                    (reservation) =>
                      reservation.reservation_seats_number &&
                      reservation.reservation_seats_number[currentKey] > 0
                  );

                  const passenger = reservationInfo
                    ? reservationInfo.passengers?.find(
                        (p) => p.number_seat == currentKey
                      )
                    : null;

                  accumulator[currentKey] = {
                    passengers: passenger ? Object.assign({}, passenger) : null, // safely copy passenger object
                    status:
                      reservationInfo?.reservation_seats_number[currentKey],
                    id_reservation: reservationInfo?.id,
                  };

                  return accumulator;
                }, {});
                const exampleData = {
                  ...seatWithPassengerTransit1, // This is your existing seat data
                  id: TripID.tripTransit[0].id, // Ensure you are actually setting the id like this
                };
                setArraySeatPassengerTransit1(exampleData);
              }
              if (selectedTransitIndex == 1) {
                let seatWithPassengerTransit2 = [];
                // Safely access the reservationSeats property
                seatWithPassengerTransit2 = Object.keys(
                  response.data.data[0]
                ).reduce((accumulator, currentKey) => {
                  // Assuming you want to do something with the keys here
                  const reservationInfo = response.data.reservations.find(
                    (reservation) =>
                      reservation.reservation_seats_number &&
                      reservation.reservation_seats_number[currentKey] > 0
                  );

                  const passenger = reservationInfo
                    ? reservationInfo.passengers?.find(
                        (p) => p.number_seat == currentKey
                      )
                    : null;

                  accumulator[currentKey] = {
                    passengers: passenger ? Object.assign({}, passenger) : null, // safely copy passenger object
                    status:
                      reservationInfo?.reservation_seats_number[currentKey],
                    id_reservation: reservationInfo?.id,
                  };

                  return accumulator;
                }, {});

                const exampleData = {
                  ...seatWithPassengerTransit2, // This is your existing seat data
                  id: TripID.tripTransit[1].id, // Ensure you are actually setting the id like this
                };
                setArraySeatPassengerTransit2(exampleData);
              }
              const newDirectSelectedSeats = [];
              const transitSeatNumbers = new Set();
              if (TripID.tripTransit && TripID.tripTransit.length > 0) {
                TripID.tripTransit.forEach((transit) => {
                  Object.keys(transit.reservationSeats).forEach(
                    (seatNumber) => {
                      transitSeatNumbers.add(seatNumber);
                    }
                  );
                });
              }
              Object.keys(response.data.data[1]).forEach((seatNumber) => {
                const seatDetails = response.data.data[1][seatNumber];
                if (
                  seatDetails.numStatus === 5 &&
                  response1[seatNumber] !== undefined &&
                  !transitSeatNumbers.has(seatNumber)
                ) {
                  newDirectSelectedSeats.push({
                    seat_number: parseInt(seatNumber),
                    id_system: seatDetails.id_system,
                    numStatus: seatDetails.numStatus,
                  });
                }
              });
              const reservationSeats = response.data.data[0];
              const seatStatusAuth = response.data.data[1];
              Object.keys(response.data.data[0]).forEach((seatNumber) => {
                const status = reservationSeats[seatNumber];
       
                if (status === 5 && !seatStatusAuth[seatNumber]) {
                  newDirectSelectedSeats.push({
                    seat_number: parseInt(seatNumber),
                    id_system: 1,
                    numStatus: status,
                    id_client: 0,
                    status: "Selected",
                    transitStatus: null,
                    id_transit: idtrip,
                    trip_id: TripID.id,
                  });
                }
              });
              const currentUserReservedSeats = newDirectSelectedSeats.filter(
                (userSeat) =>
                  userSeat.id_system === user.user_id && userSeat.numStatus == 5
              );
              const currentUserReservedSeatNumbers =
                currentUserReservedSeats.map((seat) => seat.seat_number);
              setSelectedSeats(currentUserReservedSeatNumbers);
              setSeatAndUser(newDirectSelectedSeats);
              setSelected(newDirectSelectedSeats);
              setResultArrayseatuser(newDirectSelectedSeats);
              const sortedCombinedSeats = currentUserReservedSeatNumbers.sort(
                (a, b) => a - b
              );
              setValueinput(sortedCombinedSeats.join("+"));
              sweetalert({
                text: "تم الحجز بنجاح   ",
                icon: "success",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
          })
          .catch((error) => {
            // Handle the error here
            setResponse1([]);
            dispatch(fetchTripBYId(TripID.id));
            setResponse1(TripID.reservation_seats);
          });

        setInputString("");
        setValueinput("");
        setSelectedSeats1([]);
        setSelectedSeats([]);
   
        setResultArray([]);
      }
    } else {
      const status = response1[seatNumberToSend[0]];

      if (status == 1) {
        setResultArray([]);
       
        sweetalert({
          text: "لا يمكن حجز الكرسي شركة  لانه مضاف لحجز معين",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } 
      else if (status == undefined || status == '') {
        setResultArray([]);
        sweetalert({
          text: "يجب إختيار كراسي قبل القيام بهذه العملية",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      }
      else if (status == 3 || status == 4) {
        setResultArray([]);
        sweetalert({
          text: "لا يمكن القيام بهذه العملية",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else if (status == 2) {
        setResultArray([]);
        sweetalert({
          text: "لا يمكن حجز الكرسي شركة  لانه مضاف لحجز معين",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else {
        const data = {
          triptable_id: idDirect,
          tripid: TripID.id,
          triptable_type: "Domain\\Trip",
          seat_number: seatNumberToSend,
          status: 4,
          branch: TripID.id_branch,
        };
    

        await axios
          .post(`${BaseUrl}/changeStatusSeat`, data, {
            withCredentials: true,
          })
          .then((response) => {
            if (response.data.status === 200) {
              setResponse1(response.data.data[0]);
              const mappedById = response.data.reservations.reduce(
                (acc, item) => {
                  acc[item.id] = item;
                  return acc;
                },
                {}
              );
              setArrayReservations(mappedById);
              let passenger;
              let seatWithPassengerNull = Object.keys(
                response.data.data[0]
              ).reduce((accumulator, currentKey) => {
                const reservationInfo = response.data.reservations.find(
                  (reservation) =>
                    reservation.reservation_seats_number[currentKey] > 0
                );
                passenger = reservationInfo
                  ? reservationInfo.passengers?.find(
                      (p) => p.number_seat == currentKey
                    )
                  : null;

                accumulator[currentKey] = {
                  passengers: Object(passenger),
                  status: reservationInfo?.reservation_seats_number[currentKey],
                  id_reservation: reservationInfo?.id,
                };
                return accumulator;
              }, {});

              setArraySeat(seatWithPassengerNull);
              const newDirectSelectedSeats = [];
              const transitSeatNumbers = new Set();
              if (TripID.tripTransit && TripID.tripTransit.length > 0) {
                TripID.tripTransit.forEach((transit) => {
                  Object.keys(transit.reservationSeats).forEach(
                    (seatNumber) => {
                      transitSeatNumbers.add(seatNumber);
                    }
                  );
                });
              }
              Object.keys(response.data.data[1]).forEach((seatNumber) => {
                const seatDetails = response.data.data[1][seatNumber];
                if (
                  seatDetails.numStatus === 5 &&
                  response1[seatNumber] !== undefined &&
                  !transitSeatNumbers.has(seatNumber)
                ) {
                  newDirectSelectedSeats.push({
                    seat_number: parseInt(seatNumber),
                    id_system: seatDetails.id_system,
                    numStatus: seatDetails.numStatus,
                  });
                }
              });
              const reservationSeats = response.data.data[0];
              const seatStatusAuth = response.data.data[1];
              Object.keys(response.data.data[0]).forEach((seatNumber) => {
                const status = reservationSeats[seatNumber];
       
                if (status === 5 && !seatStatusAuth[seatNumber]) {
                  newDirectSelectedSeats.push({
                    seat_number: parseInt(seatNumber),
                    id_system: 1,
                    numStatus: status,
                    id_client: 0,
                    status: "Selected",
                    transitStatus: null,
                    id_transit: null,
                    trip_id: idtrip,
                  });
                }
              });
              const currentUserReservedSeats = newDirectSelectedSeats.filter(
                (userSeat) =>
                  userSeat.id_system === user.user_id && userSeat.numStatus == 5
              );
              const currentUserReservedSeatNumbers =
                currentUserReservedSeats.map((seat) => seat.seat_number);
              setSelectedSeats(currentUserReservedSeatNumbers);
              setSeatAndUser(newDirectSelectedSeats);
              setSelected(newDirectSelectedSeats);
              setResultArrayseatuser(newDirectSelectedSeats);
              const sortedCombinedSeats = currentUserReservedSeatNumbers.sort(
                (a, b) => a - b
              );
              setValueinput(sortedCombinedSeats.join("+"));
              sweetalert({
                text: "تم الحجز بنجاح   ",
                icon: "success",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
          })
          .catch((error) => {
            // Handle the error here
            setResponse1([]);
            dispatch(fetchTripBYId(TripID.id));
            setResponse1(TripID.reservation_seats);
          });

        setInputString("");
        setValueinput("");
        setSelectedSeats1([]);
        setSelectedSeats([]);
        setSelectedSeats2([]);
        // setSeatAndUser([]);
        setResultArray([]);
      }
    }
  }

  async function setAddReservationTransit() {
    const valueInputArray = Array.isArray(valueinput)
      ? valueinput
      : valueinput.split(/[\s,+]+/);
    const combinedSeats = [...selectedSeats, ...valueInputArray]
      .map((seat) => {
        if (typeof seat === "number") return seat;

        return seat.split(/[\s,+]+/).map(Number);
      })
      .flat();

    const sortedNumbers1 = Array.from(
      new Set(combinedSeats.filter((seat) => !isNaN(seat)))
    );
    const uniqueNumbersWithoutZero = sortedNumbers1.filter(
      (number) => number !== 0
    );
    const seatNumberToSend = uniqueNumbersWithoutZero.sort((a, b) => a - b);
    const isAnySeatCommon = seatNumberToSend.some((seat) =>
      commonSeats.includes(seat)
    );

    if (isAnySeatCommon) {
     
      setResultArray([]);
      localStorage.setItem("changeseat", true);
      sweetalert({
        text: "لا يمكن القيام بهذه العملية",
        icon: "error",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        localStorage.setItem("changeseat", false);
      });
   
    } else {
      const status = response1[seatNumberToSend[0]];
      if (status == 1) {
        setResultArray([]);
        localStorage.setItem("changeseat", true);
        sweetalert({
          text: "Ctrl + F3 لا يمكن القيام بهذه العملية يمكنك إضافة بيانات مسافر للكرسي بالضغط  ",
          icon: "error",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      }
      else if (status == undefined || status == '') {
        setResultArray([]);
        sweetalert({
          text: "يجب إختيار كراسي قبل القيام بهذه العملية",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      }
      else if (status == 3 || status == 4) {
        localStorage.setItem("changeseat", true);
        sweetalert({
          text: "Ctrl + 1 لا يمكن القيام بهذه العملية يمكنك إضافة حجز  بالضغط  ",
          icon: "error",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else if (status == 2) {
        setResultArray([]);
        localStorage.setItem("changeseat", true);
        sweetalert({
          text: "Ctrl + F3 لا يمكن القيام بهذه العملية يمكنك إضافة بيانات مسافر للكرسي بالضغط  ",
          icon: "error",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else {
        const data = {
          id_trip: idDirect,
          seat_number: seatNumberToSend,
          branch: TripID.id_branch,
          type: "transit",
        };
 

        const response = await axios.post(`${BaseUrl}/SeatsTrip`, data, {
          withCredentials: true,
        });
        if (response.status === 200) {
          localStorage.setItem("changeseat", true);

          const { data: responseData } = response;
          setArraySeatPassengerTransit1((prevSeatsInfo) => {
            const updatedSeatsInfo = { ...prevSeatsInfo };

            seatNumberToSend.forEach((number) => {
              const seatNumber = parseInt(number);

              if (!isNaN(seatNumber)) {
                updatedSeatsInfo[seatNumber] = {
                  passengers: {}, // Assuming you want to reset passengers info
                  id_reservation: undefined,
                  status: 0, // Resetting the status
                };
              }
            });

            return updatedSeatsInfo;
          });
          setArraySeatPassengerTransit2((prevSeatsInfo) => {
            const updatedSeatsInfo = { ...prevSeatsInfo };

            seatNumberToSend.forEach((number) => {
              const seatNumber = parseInt(number);

              if (!isNaN(seatNumber)) {
                updatedSeatsInfo[seatNumber] = {
                  passengers: {}, // Assuming you want to reset passengers info
                  id_reservation: undefined,
                  status: 0, // Resetting the status
                };
              }
            });

            return updatedSeatsInfo;
          });
          // Add 'seat main trip' to setResponse1 array
          if (responseData.hasOwnProperty("seat main trip")) {
            setResponse1(responseData["seat main trip"]);
          }

          // Add 'seat trip transit' to setReservationseat1 array
          if (responseData.hasOwnProperty("seat trip transit")) {
            setReservationseat1(responseData["seat trip transit"]);
          }
          const seatStatusData = responseData["seat main trip"];

          // Extract seat numbers with status 6
          const seatsWithStatusSix = Object.keys(seatStatusData).filter(
            (seatNumber) => seatStatusData[seatNumber] === 6
          );

          // Convert to integers, since keys are strings
          const seatNumbersToInt = seatsWithStatusSix.map(Number);
          // Update selectedSeats2 state to include these seat numbers
          setSeatsArray2(seatNumbersToInt);
          setCommonSeats(
            seatsArray1.filter((seat) => seatNumbersToInt.includes(seat))
          );

          sweetalert({
            text: "تم  إضافة الكراسي إلى رحلات  العبور",
            icon: "success",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            // dispatch(fetchTripBYId(data.id_trip));
            localStorage.setItem("changeseat", false);
          });
        }
        setInputString("");
        setValueinput("");
        setSelectedSeats1([]);
        setResultArray([]);
        setSelectedSeats([]);
        setSeatAndUser([]);
      }
    }
  }

  async function setseatfromtransittodirect() {
    const valueInputArray = Array.isArray(valueinput)
      ? valueinput
      : valueinput.split(/[\s,+]+/);
    const combinedSeats = [
      ...selectedSeats,
      ...selectedSeats2,
      ...selectedSeats1,
      ...valueInputArray,
    ]
      .map((seat) => {
        if (typeof seat === "number") return seat;

        return seat.split(/[\s,+]+/).map(Number);
      })
      .flat();

    const sortedNumbers1 = Array.from(
      new Set(combinedSeats.filter((seat) => !isNaN(seat)))
    );
    const uniqueNumbersWithoutZero = sortedNumbers1.filter(
      (number) => number !== 0
    );
    const seatNumberToSend = uniqueNumbersWithoutZero.sort((a, b) => a - b);
    const isAnySeatCommon = seatNumberToSend.some((seat) =>
      commonSeats.includes(seat)
    );

    if (isAnySeatCommon) {
      const status = reservationseat1[seatNumberToSend[0]];
      if (status == 1 || status == 2 || status == 3 || status == 4) {
        sweetalert({
          text: "لا يمكن القيام بهذه العملية",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else {
        const data = {
          id_trip: TripID.id,
          branch: TripID.id_branch,
          seat_number: seatNumberToSend,
          type: "",
        };
    

        const response = await axios.post(`${BaseUrl}/SeatsTrip`, data, {
          withCredentials: true,
        });
        if (response.status === 200) {
          localStorage.setItem("changeseat", true);
          const { data: responseData } = response;

          setArraySeatPassengerTransit1((prevReservations) => {
            const updatedReservations = { ...prevReservations };
            // Loop through each seat number and delete it from the reservations
            seatNumberToSend.forEach((seatNumber) => {
              delete updatedReservations[seatNumber];
            });
            return updatedReservations;
          });
          setArraySeatPassengerTransit2((prevReservations) => {
            const updatedReservations = { ...prevReservations };
            // Loop through each seat number and delete it from the reservations
            seatNumberToSend.forEach((seatNumber) => {
              delete updatedReservations[seatNumber];
            });
            return updatedReservations;
          });
          const seatStatusData = responseData["seat main trip"];

          // Extract seat numbers with status 6
          const seatsWithStatusSix = Object.keys(seatStatusData).filter(
            (seatNumber) => seatStatusData[seatNumber] === 6
          );

          // Convert to integers, since keys are strings
          const seatNumbersToInt = seatsWithStatusSix.map(Number);

          // Update selectedSeats2 state to include these seat numbers
          setSeatsArray2(seatNumbersToInt);
          setCommonSeats(
            seatsArray1.filter((seat) => seatNumbersToInt.includes(seat))
          );
          // Add 'seat main trip' to setResponse1 array
          if (responseData.hasOwnProperty("seat main trip")) {
            setResponse1(responseData["seat main trip"]);
          }

          // Add 'seat trip transit' to setReservationseat1 array
          if (responseData.hasOwnProperty("seat trip transit")) {
            setReservationseat1(responseData["seat trip transit"]);
          }
          sweetalert({
            text: "تم إضافة الكراسي إلى الرحلة الاساسية",
            icon: "success",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            dispatch(fetchTripBYId(data.id_trip));

            localStorage.setItem("changeseat", false);
          });
        }

        setResultArray([]);
        setInputString("");
        setValueinput("");
        setSelectedSeats([]);
        setSelectedSeats1([]);
        setSelectedSeats2([]);
        setSeatAndUser([]);
      }
    } else {
      const status = response1[seatNumberToSend[0]];
      if (status == 5 || status == 0 || status == 3 || status == 4) {
        sweetalert({
          text: "لا يمكن تحول الكرسي لأساسي لإنه كرسي رحلة أساسية فعلاً",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      }
      else if (status == undefined || status == '') {
        setResultArray([]);
        sweetalert({
          text: "يجب إختيار كراسي قبل القيام بهذه العملية",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      }
      else if (status == 1) {
        sweetalert({
          text: "لا يمكن تحويل لاساسي  لانه مضاف لحجز معين",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else if (status == 2) {
        setResultArray([]);
        sweetalert({
          text: "لا يمكن تحويل لاساسي  لانه مضاف لحجز معين",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else {
        const data = {
          id_trip: TripID.id,
          branch: TripID.id_branch,
          seat_number: seatNumberToSend,
          type: "",
        };
 

        const response = await axios.post(`${BaseUrl}/SeatsTrip`, data, {
          withCredentials: true,
        });
        if (response.status === 200) {
          localStorage.setItem("changeseat", true);
          const { data: responseData } = response;

          setArraySeatPassengerTransit1((prevReservations) => {
            const updatedReservations = { ...prevReservations };
            // Loop through each seat number and delete it from the reservations
            seatNumberToSend.forEach((seatNumber) => {
              delete updatedReservations[seatNumber];
            });
            return updatedReservations;
          });
          setArraySeatPassengerTransit2((prevReservations) => {
            const updatedReservations = { ...prevReservations };
            // Loop through each seat number and delete it from the reservations
            seatNumberToSend.forEach((seatNumber) => {
              delete updatedReservations[seatNumber];
            });
            return updatedReservations;
          });
          const seatStatusData = responseData["seat main trip"];

          // Extract seat numbers with status 6
          const seatsWithStatusSix = Object.keys(seatStatusData).filter(
            (seatNumber) => seatStatusData[seatNumber] === 6
          );

          // Convert to integers, since keys are strings
          const seatNumbersToInt = seatsWithStatusSix.map(Number);

          // Update selectedSeats2 state to include these seat numbers
          setSeatsArray2(seatNumbersToInt);
          setCommonSeats(
            seatsArray1.filter((seat) => seatNumbersToInt.includes(seat))
          );
          // Add 'seat main trip' to setResponse1 array
          if (responseData.hasOwnProperty("seat main trip")) {
            setResponse1(responseData["seat main trip"]);
          }

          // Add 'seat trip transit' to setReservationseat1 array
          if (responseData.hasOwnProperty("seat trip transit")) {
            setReservationseat1(responseData["seat trip transit"]);
          }
          sweetalert({
            text: "تم إضافة الكراسي إلى الرحلة الاساسية",
            icon: "success",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            dispatch(fetchTripBYId(data.id_trip));

            localStorage.setItem("changeseat", false);
          });
        }
        // dispatch(insertSeatTransittodirect(data));

        setResultArray([]);
        setInputString("");
        setValueinput("");
        setSelectedSeats([]);
        setSelectedSeats1([]);
        setSelectedSeats2([]);
        setSeatAndUser([]);
      }
    }
  }
  async function setDeleteReservation() {
    const valueInputArray = Array.isArray(valueinput)
      ? valueinput
      : valueinput.split(/[\s,+]+/);
    const combinedSeats = [
      ...selectedSeats,
      ...selectedSeats1,
      ...selectedSeats2,
      ...valueInputArray,
    ]
      .map((seat) => {
        if (typeof seat === "number") return seat;

        return seat.split(/[\s,+]+/).map(Number);
      })
      .flat();

    const sortedNumbers1 = Array.from(
      new Set(combinedSeats.filter((seat) => !isNaN(seat)))
    );
    const uniqueNumbersWithoutZero = sortedNumbers1.filter(
      (number) => number !== 0
    );
    const seatNumbersToSend2 = uniqueNumbersWithoutZero.sort((a, b) => a - b);

    setResultArray(seatNumbersToSend2);
    const seatNumbersToSend = seatNumbersToSend2;
    const reservationIds1 = [];

    const passengerData = [];
    let directTrip = 0;
    // seatNumbersToSend2.forEach((seat) => {
    const reservationIdDirect = Object.values(arrayReservations).find(
      (reservation) => {
        const seatNumbers = Object.keys(
          reservation.reservation_seats_number || {} // Adding fallback to empty object to ensure it always works
        ).map(Number); // Convert keys to numbers since seat numbers are often numeric
        return seatNumbers.includes(seatNumbersToSend2[0]);
      }
    )?.id;


    const reservationIdTransit =
      Array.isArray(TripID.tripTransit) && arrayReservations
        ? Object.values(arrayReservations)?.reduce((acc, reservation) => {
            // Ensure reservation_seats_number is not undefined before proceeding
            if (reservation && reservation.reservation_seats_number) {
              const seatNumbers = Object.keys(
                reservation.reservation_seats_number
              ).map(Number);
              if (seatNumbers.includes(seatNumbersToSend2[0])) {
                acc.push({ reservationId: reservation.id });
              }
            }
            return acc;
          }, [])
        : null;
    // eslint-disable-next-line eqeqeq
    if (idDirect == TripID.id) {
      if (Array.isArray(TripID.tripTransit) && TripID.tripTransit.length > 0) {
        const seatNumbersToSend = seatNumbersToSend2;
        let reservationseat = null;

        // Convert the object keys into an array and then use find
        const reservationSeatKeys = Object.keys(
          TripID.tripTransit[0].reservationSeats
        );
        reservationseat = reservationSeatKeys.find((seat) =>
          seatNumbersToSend2.includes(Number(seat))
        );

        if (seatNumbersToSend2.includes(Number(reservationseat))) {
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "لا يمكن حذف كرسي عبور من رحلة اساسية",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
        } else {
          // Seat not found in transit trip
          const status = response1[seatNumbersToSend2[0]];

          reservationIds1.push({ reservationId1: reservationIdDirect });

          // eslint-disable-next-line eqeqeq
          if (status == 5 || status == 0) {
            localStorage.setItem("changeseat", true);
            sweetalert({
              text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
            // eslint-disable-next-line eqeqeq
          } else if (status == 4) {
            setResultArray([]);
            localStorage.setItem("changeseat", true);
            sweetalert({
              text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
            // eslint-disable-next-line eqeqeq
          } else if (status == 3) {
            setResultArray([]);
            localStorage.setItem("changeseat", true);
            sweetalert({
              text: "Ctrl + 0 لتغيير حالة كرسي ادارة لمتوفر اضغط على  ",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
            // eslint-disable-next-line eqeqeq
          } else if (status == 1) {
            const id_reservation = reservationIds1[0].reservationId1;
            const seat_number = seatNumbersToSend2;
            const passengerDeleteData = {
              id_reservation,
              seat_number,
              branch: TripID.id_branch,
              trip_id: TripID.id,
            };
            axios
              .post(`${BaseUrl}/remove-seat-reservation`, passengerDeleteData, {
                withCredentials: true,
              })
              .then((response) => {
                // Handle the successful response here
                if (response.data.status === 200) {
                  setResponse1(response.data.data.seats);
                  setArraySeat((prevSeatsInfo) => {
                    const updatedSeatsInfo = { ...prevSeatsInfo };
                    const seatNumber = parseInt(seat_number);

                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: {},
                        status: 0,
                        id_reservation: undefined,
                      };
                    }

                    return updatedSeatsInfo;
                  });
                  const mappedById = response.data.data.reservations.reduce(
                    (acc, item) => {
                      acc[item.id] = item;
                      return acc;
                    },
                    {}
                  );
                  setArrayReservations(mappedById);

                  sweetalert({
                    text: response.data.message,
                    icon: "success",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                    localStorage.setItem("openpassenger", false);
                    localStorage.setItem("changeseat", false);
                  });
                }
              });
            settranset(false);
            setDirect(true);
            setResultArray([]);
            setInputString("");
            setValueinput("");
            setSeatAndUser([]);
            setSelectedSeats([]);
            setSelectedSeats1([]);
            setSelectedSeats2([]);
            // eslint-disable-next-line eqeqeq
          } else if (status == 2) {
            const id_reservation = reservationIds1[0].reservationId1;
            const number_seat = seatNumbersToSend2;
            const passengerDeleteData = {
              id_reservation,
              number_seat,
              branch: TripID.id_branch,
              id_trip: TripID.id,
            };
        

            axios
              .post(
                `${BaseUrl}/deletePassengerFromReservation`,
                passengerDeleteData,
                { withCredentials: true }
              )
              .then((response) => {
                if (response.data.status === 200) {
                  setResponse1(response.data.data.seats);
                  setArraySeat((prevSeatsInfo) => {
                    const updatedSeatsInfo = { ...prevSeatsInfo };
                    const seatNumber = parseInt(number_seat);

                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: {},
                        status: 0,
                        id_reservation: undefined,
                      };
                    }

                    return updatedSeatsInfo;
                  });
                  const mappedById = response.data.data.reservation.reduce(
                    (acc, item) => {
                      acc[item.id] = item;
                      return acc;
                    },
                    {}
                  );
                  setArrayReservations(mappedById);

                  sweetalert({
                    text: response.data.message,
                    icon: "success",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                    localStorage.setItem("openpassenger", false);
                    localStorage.setItem("changeseat", false);
                  });
                }
              })
              .catch((error) => {
                console.error(error);
                // Handle the error here
              });

            settranset(false);
            setDirect(true);
            setResultArray([]);
            setInputString("");
            setValueinput("");
            setSeatAndUser([]);
            setSelectedSeats([]);
            setSelectedSeats1([]);
            setSelectedSeats2([]);
          }
        }
      } else if (
        Array.isArray(TripID.tripTransit) &&
        // eslint-disable-next-line eqeqeq
        TripID.tripTransit.length == 0
      ) {
        const seatNumbersToSend = seatNumbersToSend2;
        let reservationseat = null;

        // Seat not found in transit trip
        const status = response1[seatNumbersToSend2[0]];
        reservationIds1.push({ reservationId1: reservationIdDirect });

        // eslint-disable-next-line eqeqeq
        if (status == 5 || status == 0) {
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
          // eslint-disable-next-line eqeqeq
        } else if (status == 4) {
          setResultArray([]);
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
          // eslint-disable-next-line eqeqeq
        } else if (status == 3) {
          setResultArray([]);
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "Ctrl + 0 لتغيير حالة كرسي ادارة اضغط على  ",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
          // eslint-disable-next-line eqeqeq
        } else if (status == 1) {
          const id_reservation = reservationIds1[0].reservationId1;
          const seat_number = seatNumbersToSend2;
          const passengerDeleteData = {
            id_reservation,
            seat_number,
            branch: TripID.id_branch,
            trip_id: TripID.id,
          };
          axios
            .post(`${BaseUrl}/remove-seat-reservation`, passengerDeleteData, {
              withCredentials: true,
            })
            .then((response) => {
              // Handle the successful response here
              if (response.data.status === 200) {
                setResponse1(response.data.data.seats);
                setArraySeat((prevSeatsInfo) => {
                  const updatedSeatsInfo = { ...prevSeatsInfo };
                  const seatNumber = parseInt(seat_number);

                  if (!isNaN(seatNumber)) {
                    updatedSeatsInfo[seatNumber] = {
                      passengers: {},
                      status: 0,
                      id_reservation: undefined,
                    };
                  }

                  return updatedSeatsInfo;
                });
                const mappedById = response.data.data.reservations.reduce(
                  (acc, item) => {
                    acc[item.id] = item;
                    return acc;
                  },
                  {}
                );
                setArrayReservations(mappedById);

                sweetalert({
                  text: response.data.message,
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("openpassenger", false);
                  localStorage.setItem("changeseat", false);
                });
              }
            });
          settranset(false);
          setDirect(true);
          setResultArray([]);
          setInputString("");
          setValueinput("");
          setSeatAndUser([]);
          setSelectedSeats([]);
          setSelectedSeats1([]);
          setSelectedSeats2([]);
          // eslint-disable-next-line eqeqeq
        } else if (status == 2) {
          const id_reservation = reservationIds1[0].reservationId1;
          const number_seat = seatNumbersToSend2;
          const passengerDeleteData = {
            id_reservation,
            number_seat,
            branch: TripID.id_branch,
            id_trip: TripID.id,
          };
          axios
            .post(
              `${BaseUrl}/deletePassengerFromReservation`,
              passengerDeleteData,
              { withCredentials: true }
            )
            .then((response) => {
              // Handle the successful response here
              if (response.data.status === 200) {
                setResponse1(response.data.data.seats);
                setArraySeat((prevSeatsInfo) => {
                  const updatedSeatsInfo = { ...prevSeatsInfo };
                  const seatNumber = parseInt(number_seat);

                  if (!isNaN(seatNumber)) {
                    updatedSeatsInfo[seatNumber] = {
                      passengers: {},
                      status: 0,
                      id_reservation: undefined,
                    };
                  }

                  return updatedSeatsInfo;
                });
                const mappedById = response.data.data.reservation.reduce(
                  (acc, item) => {
                    acc[item.id] = item;
                    return acc;
                  },
                  {}
                );
                setArrayReservations(mappedById);
            
                sweetalert({
                  text: response.data.message,
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("openpassenger", false);
                  localStorage.setItem("changeseat", false);
                });
              }
            })
            .catch((error) => {
              console.error(error);
              // Handle the error here
            });
          settranset(false);
          setDirect(true);
          setResultArray([]);
          setInputString("");
          setValueinput("");
          setSeatAndUser([]);
          setSelectedSeats([]);
          setSelectedSeats1([]);
          setSelectedSeats2([]);
        }
      }
      // eslint-disable-next-line eqeqeq
    } else if (idDirect != TripID.id) {
      let reservationseat = null;
      const reservationSeatKeys = Object.keys(
        TripID.tripTransit[selectedTransitIndex].reservationSeats
      );
      reservationseat = reservationSeatKeys.find((seat) =>
        seatNumbersToSend.includes(Number(seat))
      );
      settranset(true);
      setDirect(false);
      reservationIds1.push({ reservationId1: reservationIdTransit });
      const status = reservationseat1[seatNumbersToSend2[0]];

      // eslint-disable-next-line eqeqeq
      if (status == 5 || status == 0) {
        localStorage.setItem("changeseat", true);
        sweetalert({
          text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
          icon: "error",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
        // eslint-disable-next-line eqeqeq
      } else if (status == 4) {
        setResultArray([]);
        localStorage.setItem("changeseat", true);
        sweetalert({
          text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
          icon: "error",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
        // eslint-disable-next-line eqeqeq
      } else if (status == 3) {
        setResultArray([]);
        localStorage.setItem("changeseat", true);
        sweetalert({
          text: "Ctrl + 0 لتغيير حالة كرسي ادارة لمتوفر اضغط على  ",
          icon: "error",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
        // eslint-disable-next-line eqeqeq
      } else if (status == 1) {
        const id_reservation =
          reservationIds1[0]?.reservationId1[0].reservationId;
        const seat_number = seatNumbersToSend2;
        const passengerDeleteData = {
          id_reservation,
          seat_number,
          branch: TripID.id_branch,
          trip_id: TripID.id,
        };
        axios
          .post(`${BaseUrl}/remove-seat-reservation`, passengerDeleteData, {
            withCredentials: true,
          })
          .then((response) => {
            // Handle the successful response here
            if (response.data.status === 200) {
              setReservationseat1(response.data.data.seats);

              if (selectedTransitIndex == 0) {
                setArraySeatPassengerTransit1((prevSeatsInfo) => {
                  const updatedSeatsInfo = { ...prevSeatsInfo };

                  seatNumbersToSend2.forEach((seat_number) => {
                    const seatNumber = parseInt(seat_number);
                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: {}, // Empty object for passengers
                        status: 0, // Set status to 0
                        id_reservation: undefined, // No reservation ID
                      };
                    } else {
                    }
                  });

                  return updatedSeatsInfo;
                });
                const mappedById = response.data.data.reservations.reduce(
                  (acc, item) => {
                    acc[item.id] = item;
                    return acc;
                  },
                  {}
                );
                setArrayReservations(mappedById);
                sweetalert({
                  text: response.data.message,
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("openpassenger", false);
                  localStorage.setItem("changeseat", false);
                });
              } else if (selectedTransitIndex == 1) {
                setArraySeatPassengerTransit2((prevSeatsInfo) => {
                  const updatedSeatsInfo = { ...prevSeatsInfo };
                  const seatNumber = parseInt(seat_number);

                  if (!isNaN(seatNumber)) {
                    updatedSeatsInfo[seatNumber] = {
                      passengers: {},
                      status: 0,
                      id_reservation: undefined,
                    };
                  }

                  return updatedSeatsInfo;
                });
                const mappedById = response.data.data.reservations.reduce(
                  (acc, item) => {
                    acc[item.id] = item;
                    return acc;
                  },
                  {}
                );
                setArrayReservations(mappedById);
                sweetalert({
                  text: response.data.message,
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("openpassenger", false);
                  localStorage.setItem("changeseat", false);
                });
              }
            }
          });
        settranset(true);
        setDirect(false);
        setResultArray([]);
        setInputString("");
        setValueinput("");
        setSeatAndUser([]);
        setSelectedSeats([]);
        setSelectedSeats1([]);
        setSelectedSeats2([]);
      } else if (status == 2) {
        const id_reservation =
          reservationIds1[0]?.reservationId1[0].reservationId;
        const number_seat = seatNumbersToSend2;
      
        const passengerDeleteData = {
          id_reservation,
          number_seat,
          branch: TripID.id_branch,
          id_trip: TripID.id,
        };
        axios
          .post(
            `${BaseUrl}/deletePassengerFromReservation`,
            passengerDeleteData,
            { withCredentials: true }
          )
          .then((response) => {
            if (response.data.status === 200) {
              setReservationseat1(response.data.data.seats);
              if (selectedTransitIndex == 0) {
                setArraySeatPassengerTransit1((prevSeatsInfo) => {
                  const updatedSeatsInfo = { ...prevSeatsInfo };

                  seatNumbersToSend2.forEach((seat_number) => {
                    const seatNumber = parseInt(seat_number);
                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: {}, // Empty object for passengers
                        status: 0, // Set status to 0
                        id_reservation: undefined, // No reservation ID
                      };
                    } else {
                    }
                  });

                  return updatedSeatsInfo;
                });
                const mappedById = response.data.data.reservation.reduce(
                  (acc, item) => {
                    acc[item.id] = item;
                    return acc;
                  },
                  {}
                );
                setArrayReservations(mappedById);
                sweetalert({
                  text: response.data.message,
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("openpassenger", false);
                  localStorage.setItem("changeseat", false);
                });
              } else if (selectedTransitIndex == 1) {
                setArraySeatPassengerTransit2((prevSeatsInfo) => {
                  const updatedSeatsInfo = { ...prevSeatsInfo };
                  const seatNumber = parseInt(number_seat);

                  if (!isNaN(seatNumber)) {
                    updatedSeatsInfo[seatNumber] = {
                      passengers: {},
                      status: 0,
                      id_reservation: undefined,
                    };
                  }

                  return updatedSeatsInfo;
                });
                const mappedById = response.data.data.reservation.reduce(
                  (acc, item) => {
                    acc[item.id] = item;
                    return acc;
                  },
                  {}
                );
                setArrayReservations(mappedById);
                sweetalert({
                  text: response.data.message,
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("openpassenger", false);
                  localStorage.setItem("changeseat", false);
                });
              }

             
            }
          })
          .catch((error) => {
            console.error(error);
            // Handle the error here
          });
        setReservationseat1(
          TripID.tripTransit[selectedTransitIndex].reservationSeats
        );
        settranset(true);
        setDirect(false);
        setResultArray([]);
        setInputString("");
        setValueinput("");
        setSeatAndUser([]);
        setSelectedSeats([]);
        setSelectedSeats1([]);
        setSelectedSeats2([]);
      }
    }

  }
  function setReservationUpdateData() {
    setTitle("تعديل مسافر");
    setReservation("تعديل حجز");
    const reservationIds1 = [];
    const valueInputArray = Array.isArray(valueinput)
      ? valueinput
      : valueinput.split(/[\s,+]+/);
    const combinedSeats = [
      ...selectedSeats,
      ...selectedSeats1,
      ...selectedSeats2,
      ...valueInputArray,
    ]
      .map((seat) => {
        if (typeof seat === "number") return seat;

        return seat.split(/[\s,+]+/).map(Number);
      })
      .flat();

    const sortedNumbers1 = Array.from(
      new Set(combinedSeats.filter((seat) => !isNaN(seat)))
    );
    const uniqueNumbersWithoutZero = sortedNumbers1.filter(
      (number) => number !== 0
    );
    const seatNumbersToSend = uniqueNumbersWithoutZero.sort((a, b) => a - b);
    setResultArray(seatNumbersToSend);
    // seatNumbersToSend.forEach((seat) => {
    if (idDirect == TripID.id) {
      if (Array.isArray(TripID.tripTransit) && TripID.tripTransit.length > 0) {
        // const seatNumbersToSend = selectedSeats;
        let reservationseat = null;

        // Convert the object keys into an array and then use find
        const reservationSeatKeys = Object.keys(
          TripID.tripTransit[0].reservationSeats
        );
        reservationseat = reservationSeatKeys.find((seat) =>
          seatNumbersToSend.includes(Number(seat))
        );

        if (reservationseat == seatNumbersToSend) {
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "لا يمكن تعديل كرسي رحلة العبور من الرحلة الاساسية للقيام بذلك انتقل إلى رحلة العبور وقم بتعديل البيانات الكرسي الخاص يرحلة العبور",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
        } else {
          // Seat not found in transit trip
          const status = response1[seatNumbersToSend[0]];
          if (status == 0 || status == 3 || status == 5 || status == 4) {
            localStorage.setItem("changeseat", true);
            sweetalert({
              text: "Ctrl + 1 لا يمكن تعديل بيانات كرسي لا يحتوي على حجز يمكن إضافة حجز بالضغط ",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
          }
          else if (status == undefined || status == '') {
            setResultArray([]);
            sweetalert({
              text: "يجب إختيار كراسي قبل القيام بهذه العملية",
              icon: "error",
              buttons: {
                confirm: {
                  text: "إغلاق",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
          }
          else if (status == 1) {
            setResultArray([]);
            localStorage.setItem("changeseat", true);
            sweetalert({
              text: "Ctrl + F3 لا يمكن القيام بهذه العملية يمكنك إضافة بيانات مسافر للكرسي بالضغط  ",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
          } else {
            setOpenPopuppassenger(true);
            localStorage.setItem("openpassenger", true);

            const reservationDirect = Object.values(arrayReservations)?.find(
              (reservation) => {
                const seatNumbers = Object.keys(
                  reservation.reservation_seats_number
                ).map(Number);
                return seatNumbers.includes(seatNumbersToSend[0]);
              }
            );

            setIdReservationResponse(reservationDirect);
            settranset(false);
            setDirect(true);
            const passengerData = [];

            seatNumbersToSend.forEach((seat) => {
              const matchingPassenger = reservationDirect.passengers.find(
                (passenger) => {
                  return passenger.number_seat == seat;
                }
              );
              if (matchingPassenger) {
                passengerData.push({
                  id: matchingPassenger.id_passenger || matchingPassenger.id,
                  first_name: matchingPassenger.first_name,
                  number_seat: matchingPassenger.number_seat,
                  middle_name: matchingPassenger.middle_name,
                  last_name: matchingPassenger.last_name,
                  mother_name: matchingPassenger.mother_name,
                  national_id: matchingPassenger.national_id,
                  gender: matchingPassenger.gender,
                  birth_date: matchingPassenger.birth_date,
                  birth_place: matchingPassenger.birth_place,
                  civil_status: matchingPassenger.civil_status,
                  id_price_ticket: matchingPassenger.id_price_ticket,
                  price: matchingPassenger.price,
                  title: matchingPassenger.title,
                  // Add other passenger details as needed
                });

                reservationIds1.push({
                  reservationId1: reservationDirect.id,
                });
                setUpdatePassenger(true);
              }
            });

            if (passengerData.length > 0) {
              const existingPassengerData =
                JSON.parse(localStorage.getItem("passengers")) || [];
              const updatedPassengerData = [
                ...passengerData,
              ];

              localStorage.setItem(
                "passengers",
                JSON.stringify(updatedPassengerData)
              );
            }
            const matchingPassenger = reservationDirect.passengers.find(
              (passenger) => {
                return passenger.number_seat == selectedSeats;
              }
            );

            if (matchingPassenger) {
              const passengerData = [
                {
                  id: matchingPassenger.id_passenger || matchingPassenger.id,
                  first_name: matchingPassenger.first_name,
                  number_seat: matchingPassenger.number_seat,
                  middle_name: matchingPassenger.middle_name,
                  last_name: matchingPassenger.last_name,
                  mother_name: matchingPassenger.mother_name,
                  national_id: matchingPassenger.national_id,
                  gender: matchingPassenger.gender,
                  birth_date: matchingPassenger.birth_date,
                  birth_place: matchingPassenger.birth_place,
                  civil_status: matchingPassenger.civil_status,
                  id_price_ticket: matchingPassenger.id_price_ticket,
                  price: matchingPassenger.price,
                  title: matchingPassenger.title,
                  // Add other passenger details as needed
                },
              ];
              reservationIds1.push({
                reservationId1: reservationDirect.id,
              });
              setUpdatePassenger(true);
              const existingPassengerData =
                JSON.parse(localStorage.getItem("passengers")) || [];
              const updatedPassengerData = [
                ...existingPassengerData,
                ...passengerData,
              ];

              localStorage.setItem(
                "passengers",
                JSON.stringify(updatedPassengerData)
              );
            }
          }
        }
      } else if (
        Array.isArray(TripID.tripTransit) &&
        TripID.tripTransit?.length == 0
      ) {
        let changeseat = false;
        const seatStatus = {};
        const status = response1[seatNumbersToSend[0]];

        if (status === 0 || status === 3 || status == 5 || status == 4) {
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "Ctrl + 1 لا يمكن تعديل بيانات كرسي لا يحتوي على حجز يمكن إضافة حجز بالضغط ",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
        } 
        else if (status == undefined || status == '') {
          setResultArray([]);
          sweetalert({
            text: "يجب إختيار كراسي قبل القيام بهذه العملية",
            icon: "error",
            buttons: {
              confirm: {
                text: "إغلاق",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
        }
        else if (status == 1) {
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "Ctrl + F3 لا يمكن القيام بهذه العملية يمكنك إضافة بيانات مسافر للكرسي بالضغط  ",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
        } else {
          setOpenPopuppassenger(true);
          localStorage.setItem("openpassenger", true);

          const reservationDirect = Object.values(arrayReservations)?.find(
            (reservation) => {
              const seatNumbers = Object.keys(
                reservation.reservation_seats_number
              ).map(Number);
              return seatNumbers.includes(seatNumbersToSend[0]);
            }
          );
          setIdReservationResponse(reservationDirect);

          settranset(false);
          setDirect(true);
          const passengerData = [];

          // Loop through selectedSeats and find matching passengers
          seatNumbersToSend.forEach((seat) => {
            const matchingPassenger = reservationDirect.passengers.find(
              (passenger) => {
                return passenger.number_seat == seat;
              }
            );

            if (matchingPassenger) {
              passengerData.push({
                id: matchingPassenger.id_passenger || matchingPassenger.id,
                first_name: matchingPassenger.first_name,
                number_seat: matchingPassenger.number_seat,
                middle_name: matchingPassenger.middle_name,
                last_name: matchingPassenger.last_name,
                mother_name: matchingPassenger.mother_name,
                national_id: matchingPassenger.national_id,
                gender: matchingPassenger.gender,
                birth_date: matchingPassenger.birth_date,
                birth_place: matchingPassenger.birth_place,
                civil_status: matchingPassenger.civil_status,
                id_price_ticket: matchingPassenger.id_price_ticket,
                price: matchingPassenger.price,
                title: matchingPassenger.title,
                // Add other passenger details as needed
              });

              reservationIds1.push({
                reservationId1: reservationDirect.id,
              });
              setUpdatePassenger(true);
            }
          });

          if (passengerData.length > 0) {
            const existingPassengerData =
              JSON.parse(localStorage.getItem("passengers")) || [];
            const updatedPassengerData = [
              ...existingPassengerData,
              ...passengerData,
            ];
            localStorage.setItem(
              "passengers",
              JSON.stringify(updatedPassengerData)
            );
          }
        }
      }
    } else if (idDirect != TripID.id) {
      settranset(true);
      setDirect(false);

      // Seat not found in transit trip
      const status = reservationseat1[seatNumbersToSend[0]];
      if (status == 0 || status == 3 || status == 5 || status == 4) {
        localStorage.setItem("changeseat", true);
        sweetalert({
          text: "Ctrl + 1 لا يمكن تعديل بيانات كرسي لا يحتوي على حجز يمكن إضافة حجز بالضغط ",
          icon: "error",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      }
      else if (status == undefined || status == '') {
        setResultArray([]);
        sweetalert({
          text: "يجب إختيار كراسي قبل القيام بهذه العملية",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      }
      else if (status == 1) {
        setResultArray([]);
        localStorage.setItem("changeseat", true);
        sweetalert({
          text: "Ctrl + F3 لا يمكن القيام بهذه العملية يمكنك إضافة بيانات مسافر للكرسي بالضغط  ",
          icon: "error",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          localStorage.setItem("changeseat", false);
        });
      } else {
        setUpdatePassenger(true);
        setOpenPopuppassenger(true);
        localStorage.setItem("openpassenger", true);
        const reservationIdTransit = Array.isArray(TripID.tripTransit)
          ? Object.values(arrayReservations)?.reduce((acc, reservation) => {
              const seatNumbers = Object.keys(
                reservation.reservation_seats_number
              ).map(Number);
              if (seatNumbers.includes(seatNumbersToSend[0])) {
                acc.push({
                  reservationId: reservation.id,
                  passengers: reservation.passengers,
                  trip_id: reservation.trip_id || reservation.triptable_id,
                });
              }
              return acc;
            }, [])
          : null;
        const relevantReservations = Object.values(reservationIdTransit).filter(
          (reservation) => reservation.trip_id.toString() === idtransit
        );

        setIdReservationResponse(relevantReservations);
        if (relevantReservations) {
          const passengerData = [];

          if (Array.isArray(relevantReservations)) {
            const passengersForSeat = [];

            const matchingPassenger = relevantReservations[0]?.passengers.find(
              (passenger) => {
                return passenger.number_seat === seatNumbersToSend[0]; // Use strict equality
              }
            );

            if (matchingPassenger) {
              passengerData.push({
                id: matchingPassenger.id_passenger || matchingPassenger.id,
                first_name: matchingPassenger.first_name,
                number_seat: matchingPassenger.number_seat,
                middle_name: matchingPassenger.middle_name,
                last_name: matchingPassenger.last_name,
                mother_name: matchingPassenger.mother_name,
                national_id: matchingPassenger.national_id,
                gender: matchingPassenger.gender,
                birth_date: matchingPassenger.birth_date,
                birth_place: matchingPassenger.birth_place,
                civil_status: matchingPassenger.civil_status,
                id_price_ticket: matchingPassenger.id_price_ticket,
                price: matchingPassenger.price,
                title: matchingPassenger.title,
              });
            }

          }

          if (passengerData.length > 0) {
            const flattenedPassengerData = passengerData.flat();
            const updatedPassengerData = [...flattenedPassengerData];

            localStorage.setItem(
              "passengers",
              JSON.stringify(updatedPassengerData)
            );
          }
        }
      }
    }
  

    setReservationIds(reservationIds1);
  }
  function setReservationSetAddData() {
    setResultArray(selectedSeats);
    setOpenPopuppassenger(true);
    localStorage.setItem("openpassenger", true);
    localStorage.setItem("changeseat", true);
    const reservationIds1 = [];
    const seatNumberToSend = selectedSeats;
    seatNumberToSend.forEach((seat) => {
      const reservationIdDirect = TripID.reservations.find((reservation) => {
        const seatNumbers = Object.keys(
          reservation.reservation_seats_number
        ).map(Number);
        return seatNumbers.includes(seat);
      })?.id;

      if (reservationIdDirect) {
        reservationIds1.push({ reservationId1: reservationIdDirect });
      }

      // Add logic to check transit trips as well
      for (const tripTransit of TripID.tripTransit) {
        const reservationIdTransit = Array.isArray(TripID.tripTransit)
          ? TripID.tripTransit[selectedTransitIndex]?.reservation.reduce(
              (acc, reservation) => {
                const seatNumbers = Object.keys(
                  reservation.reservation_seats_number
                ).map(Number);
                if (seatNumbers.includes(seat)) {
                  acc.push({
                    reservationId: reservation.id,
                    passengers: reservation.passengers,
                  });
                }
                return acc;
              },
              []
            )
          : null;

        if (reservationIdTransit) {
          reservationIds1.push({
            reservationId1: reservationIdTransit[0].reservationId,
          });

          setIDTransit(tripTransit.id);
        }
      }
    });
    setReservationIds(reservationIds1);
    setUpdateSeat(true);
    setInputString("");
    setValueinput("");
    setSelectedSeats([]);
  }
  function setAddDataPassengerReservation() {
    if (nofocus) {
      nofocus.blur();
    }
    setTitle("إضافة بيانات مسافر لحجز مسبق");
    setReservation("إضافة بيانات");
    const parts1 = valueinput.split("+");

    const numbers = parts1
      .map((part) => {
        if (part.includes("-")) {
          const [start, end] = part.split("-").map(Number);
          return [...Array(end - start + 1).keys()].map((i) => i + start);
        } else {
          return Number(part);
        }
      })
      .flat();
 
    const valueInputArray = Array.isArray(valueinput)
      ? valueinput
      : valueinput.split(/[\s,+]+/);
    const combinedSeats = [
      ...selectedSeats,
      ...selectedSeats1,
      ...selectedSeats2,
      ...valueInputArray,
    ]
      .map((seat) => {
        if (typeof seat === "number") return seat;

        return seat.split(/[\s,+]+/).map(Number);
      })
      .flat();

    const sortedNumbers1 = Array.from(
      new Set(combinedSeats.filter((seat) => !isNaN(seat)))
    );
    const uniqueNumbersWithoutZero = sortedNumbers1.filter(
      (number) => number !== 0
    );
    const seatNumbersToSend = uniqueNumbersWithoutZero.sort((a, b) => a - b);
    setResultArray(seatNumbersToSend);

    const reservationIds1 = [];

    seatNumbersToSend.forEach((seat) => {
      if (idDirect == TripID.id) {
        if (
          Array.isArray(TripID.tripTransit) &&
          TripID.tripTransit?.length == 0
        ) {
          let changeseat = false;
          const seatStatus = {};
          const status = response1[seatNumbersToSend[0]];
        
          if (status === 0 || status === 3 || status == 5 || status == 4) {
            localStorage.setItem("changeseat", true);
            setResultArray([]);
            sweetalert({
              text: "Ctrl + 1 لا يمكن إضافة بيانات مسافر بدون حجز يمكن إضافة حجز بالضغط ",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
          }
          else if (status == undefined || status == '') {
            setResultArray([]);
            sweetalert({
              text: "يجب إختيار كراسي قبل القيام بهذه العملية",
              icon: "error",
              buttons: {
                confirm: {
                  text: "إغلاق",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
          }
          else if (status == 2) {
            setResultArray([]);
            localStorage.setItem("changeseat", true);
            sweetalert({
              text: " لا يمكن القيام بهذه العملية   ",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
          } else {
            setOpenPopuppassenger(true);
            localStorage.setItem("openpassenger", true);
            setUpdateSeat(true);
            const reservationDirect = Object.values(arrayReservations)?.find(
              (reservation) => {
                const seatNumbers = Object.keys(
                  reservation.reservation_seats_number
                ).map(Number);
                return seatNumbers.includes(seat);
              }
            );
            settranset(false);
            setDirect(true);
            const passengerData = [];

         
            reservationIds1.push({
              reservationId1: reservationDirect?.id,
            });

            setIdReservationResponse(reservationIds1);
       
            setInputString("");
            setValueinput("");
            setSelectedSeats1([]);
            setSelectedSeats([]);
            setSelectedSeats2([]);
            setSeatAndUser([]);
          }
        } else {
          let changeseat = false;
          const seatStatus = {};
          // for (const seat in seatStatus) {
          const status = response1[seatNumbersToSend[0]];

          if (status === 0 || status === 3 || status == 5 || status == 4) {
            setResultArray([]);
            localStorage.setItem("changeseat", true);
            sweetalert({
              text: "Ctrl + 1 لا يمكن إضافة بيانات مسافر بدون حجز يمكن إضافة حجز بالضغط ",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
          } 
          else if (status == undefined || status == '') {
            setResultArray([]);
            sweetalert({
              text: "يجب إختيار كراسي قبل القيام بهذه العملية",
              icon: "error",
              buttons: {
                confirm: {
                  text: "إغلاق",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
          }
          else if (status == 2) {
            setResultArray([]);
            localStorage.setItem("changeseat", true);
            sweetalert({
              text: " لا يمكن القيام بهذه العملية   ",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
          } else {
            setUpdateSeat(true);
            setOpenPopuppassenger(true);
            localStorage.setItem("openpassenger", true);

            const reservationDirect = Object.values(arrayReservations)?.find(
              (reservation) => {
                const seatNumbers = Object.keys(
                  reservation.reservation_seats_number
                ).map(Number);
                return seatNumbers.includes(seat);
              }
            );
            settranset(false);
            setDirect(true);
            const passengerData = [];

            reservationIds1.push({
              reservationId1: reservationDirect.id,
            });

            setIdReservationResponse(reservationIds1);
         
            setInputString("");
            setValueinput("");
            setSelectedSeats1([]);
            setSelectedSeats([]);
            setSelectedSeats2([]);
            setSeatAndUser([]);
          }
        }
      } else if (idDirect != TripID.id) {
        let reservationseat = null;
        const reservationSeatKeys = Object.keys(
          TripID.tripTransit[selectedTransitIndex].reservationSeats
        );
        reservationseat = reservationSeatKeys.find((seat) =>
          seatNumbersToSend.includes(Number(seat))
        );
        settranset(true);
        setDirect(false);
        const seatStatus = {};

        // Iterate through selectedSeats and get the status for each seat
        seatNumbersToSend.forEach((seat) => {
          const status =
            TripID.tripTransit[selectedTransitIndex].reservationSeats[seat];
          seatStatus[seat] = status;
        });

        let changeseat = false;

        for (const seat in seatStatus) {
          const status = seatStatus[seat];

          if (status === 0 || status === 3) {
            changeseat = true;
            break; // Exit the loop early if any seat has status 0 or 3
          }
        }

        const status = reservationseat1[seatNumbersToSend[0]];

        if (status === 0 || status === 3 || status == 5 || status == 4) {
          setResultArray([]);
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: "Ctrl + 1 لا يمكن إضافة بيانات مسافر بدون حجز يمكن إضافة حجز بالضغط ",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
        }
        else if (status == undefined || status == '') {
          setResultArray([]);
          sweetalert({
            text: "يجب إختيار كراسي قبل القيام بهذه العملية",
            icon: "error",
            buttons: {
              confirm: {
                text: "إغلاق",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
        }
         else if (status == 2) {
          setResultArray([]);
          localStorage.setItem("changeseat", true);
          sweetalert({
            text: " لا يمكن القيام بهذه العملية   ",
            icon: "error",
            buttons: {
              confirm: {
                text: "تم",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          }).then(() => {
            localStorage.setItem("changeseat", false);
          });
        } else {
          setUpdateSeat(true);
          setOpenPopuppassenger(true);
          localStorage.setItem("openpassenger", true);
     
            const reservationIdTransit =
            Array.isArray(TripID.tripTransit) && arrayReservations
              ? Object.values(arrayReservations)?.reduce(
                  (acc, reservation) => {
                    // Ensure reservation_seats_number is not undefined before proceeding
                    if (
                      reservation &&
                      reservation.reservation_seats_number
                    ) {
                      const seatNumbers = Object.keys(
                        reservation.reservation_seats_number
                      ).map(Number);
                      if (
                        seatNumbers.includes(seatNumbersToSend[0])
                      ) {
                        acc.push({ reservationId: reservation.id });
                      }
                    }
                    return acc;
                  },
                  []
                )
              : null;
          if (reservationIdTransit) {
            const passengerData = [];

            seatNumbersToSend.forEach((seat) => {
              if (Array.isArray(reservationIdTransit)) {
                const passengersForSeat = [];

               


                reservationIds1.push({
                  reservationId1: reservationIdTransit,
                });
                

                setIdReservationResponse(reservationIds1);
                // Push the list of passengers for this seat into passengerData
                passengerData.push(passengersForSeat);
              }
            });

          }
          setInputString("");
          setValueinput("");
          setSelectedSeats1([]);
          setSelectedSeats([]);
          setSelectedSeats2([]);
          setSeatAndUser([]);
        }
      }
    });

    setReservationIds(reservationIds1);
  }
   
  async function setReservationTransit1() {
    const valueInputArray = Array.isArray(valueinput)
    ? valueinput
    : valueinput.split(/[\s,+]+/);
  const combinedSeats = [
    ...selectedSeats,
    ...selectedSeats1,
    ...selectedSeats2,
    ...valueInputArray,
  ]
    .map((seat) => {
      if (typeof seat === "number") return seat;

      return seat.split(/[\s,+]+/).map(Number);
    })
    .flat();

  const sortedNumbers = Array.from(
    new Set(combinedSeats.filter((seat) => !isNaN(seat)))
  );
  const uniqueNumbersWithoutZero = sortedNumbers.filter(
    (number) => number !== 0
  );
  const seatNumberToSend = uniqueNumbersWithoutZero.sort((a, b) => a - b);
  
    if(seatNumberToSend.length == 0 ){

  
    setColor(color === "rgb(168, 168, 168)" ? "#82c40e" : "#82c40e");
    setClick(true);
    setIDdirect();
    settranset(true);
    setDirect(false);
    await axios
      .get(`${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          setPathTransit(response.data.data.tripTransit[0].path);
          setPathtrip(response.data.data.tripTransit[0].path);
          setIDTransit(response.data.data.tripTransit[0].id);
          setIdTransit1(response.data.data.tripTransit[0].id);
          setIDtrip(response.data.data.tripTransit[0].id);
          setReservationseat(response.data.data.tripTransit[0]);
          setIDTripType(response.data.data.tripTransit[0].id_trip_type);
          setReservationseat1(
            response.data.data.tripTransit[0].reservationSeats
          );
          setidTriptran(response.data.data.tripTransit[0].id);
          localStorage.setItem(
            "idtransit",
            response.data.data.tripTransit[0].id
          );
          const mappedById =
            response.data.data.tripTransit[0]?.reservation.reduce(
              (acc, item) => {
                acc[item.id] = item;
                return acc;
              },
              {}
            );
          setArrayReservations(mappedById);
          setCommonSeats(
            response.data.data.seats.seats
              .map((seat) => parseInt(seat))
              .filter((seat) =>
                response.data.data.tripTransit
                  .flatMap((e) => Object.keys(e.reservationSeats).map(Number))
                  .includes(seat)
              )
          );
          const transit = response.data.data.tripTransit[0];
          localStorage.setItem("idtransit", transit.id); // Store in local storage
          let seatWithPassengerTransit1 = [];
          const newTransitSelectedSeats = [];
          // Safely access the reservationSeats property
          seatWithPassengerTransit1 = Object.keys(
            response.data.data.tripTransit[0].reservationSeats
          ).reduce((accumulator, currentKey) => {
            // Assuming you want to do something with the keys here
            const reservationInfo =
              response.data.data.tripTransit[0]?.reservation.find(
                (reservation) =>
                  reservation.reservation_seats_number &&
                  reservation.reservation_seats_number[currentKey] > 0
              );

            const passenger = reservationInfo
              ? reservationInfo.passengers?.find(
                  (p) => p.number_seat == currentKey
                )
              : null;

            accumulator[currentKey] = {
              passengers: passenger ? Object.assign({}, passenger) : null, // safely copy passenger object
              status: reservationInfo?.reservation_seats_number[currentKey],
              id_reservation: reservationInfo?.id,
            };

            return accumulator;
          }, {});

          const exampleData = {
            ...seatWithPassengerTransit1, // This is your existing seat data
            id: response.data.data.tripTransit[0].id, // Ensure you are actually setting the id like this
          };
          setArraySeatPassengerTransit1(exampleData);
          Object.keys(transit.reservationSeats).forEach((seatNumber) => {
            const transitSeatStatus = transit.reservationSeats[seatNumber];

            if (transitSeatStatus === 5) {
              const seatDetails = transit.seatStatusAuth[seatNumber];

              if (seatDetails && seatDetails.numStatus === 5) {
                newTransitSelectedSeats.push({
                  seat_number: parseInt(seatNumber),
                  id_system: seatDetails.id_system,
                  numStatus: seatDetails.numStatus,
                  status: "Selected",
                  id_client: 0,
                  transitStatus: null,
                  id_transit: response.data.data.tripTransit[0]?.id,
                  trip_id: response.data.data.id,
                });
              }
            }
          });
          const reservationSeats = transit.reservationSeats;
          const seatStatusAuth = transit.seatStatusAuth;
          Object.keys(transit.reservationSeats).forEach((seatNumber) => {
            const status = reservationSeats[seatNumber];
   
            if (status === 5 && !seatStatusAuth[seatNumber]) {
              newTransitSelectedSeats.push({
                seat_number: parseInt(seatNumber),
                id_system: 1,
                numStatus: status,
                id_client: 0,
                status: "Selected",
                transitStatus: null,
                id_transit: response.data.data.tripTransit[0].id,
                trip_id: idtrip,
              });
            }
          });
          const currentUserReservedSeats = newTransitSelectedSeats.filter(
            (userSeat) =>
              userSeat.id_system === user.user_id && userSeat.numStatus == 5
          );

          const currentUserReservedSeatNumbers = currentUserReservedSeats.map(
            (seat) => seat.seat_number
          );
          setSelectedSeats(currentUserReservedSeatNumbers);
          setSeatAndUser(newTransitSelectedSeats);
          setSelected(newTransitSelectedSeats);
          // if(newTransitSelectedSeats == 0 ){

          //   setResultArray([]);
          //   setInputString("");
          //   setValueinput("");
          //   setSelectedSeats([]);
          //   setSelectedSeats1([]);
          //   setSelectedSeats2([]);
          // }
          setResultArrayseatuser(newTransitSelectedSeats);
          const sortedCombinedSeats = currentUserReservedSeatNumbers.sort(
            (a, b) => a - b
          );
          setValueinput(currentUserReservedSeatNumbers.join("+"));
        }
      });
     
     

    setSelectedTransitIndex(0);
    setShowDirectTrip(true);
    setDirect(false);
    settranset(true);
    settransetnumber(1);

  }else {
    sweetalert({
      text: "يجب إلغاء تحديد الكراسي قيل الانتقال لرحلة التالية",
      icon: "error",
      buttons: {
        confirm: {
          text: "إغلاق",
          className: "custom-button-class",
        },
      },
      className: "alert-div",
    }).then(() => {
    
    });
  }
  }
  const branch = localStorage.getItem("branch");

  async function setReservationTransit2() {
    const valueInputArray = Array.isArray(valueinput)
    ? valueinput
    : valueinput.split(/[\s,+]+/);
  const combinedSeats = [
    ...selectedSeats,
    ...selectedSeats1,
    ...selectedSeats2,
    ...valueInputArray,
  ]
    .map((seat) => {
      if (typeof seat === "number") return seat;

      return seat.split(/[\s,+]+/).map(Number);
    })
    .flat();

  const sortedNumbers = Array.from(
    new Set(combinedSeats.filter((seat) => !isNaN(seat)))
  );
  const uniqueNumbersWithoutZero = sortedNumbers.filter(
    (number) => number !== 0
  );
  const seatNumberToSend = uniqueNumbersWithoutZero.sort((a, b) => a - b);
  
    if(seatNumberToSend.length == 0 ){
    setSelectedTransitIndex(1);
    await axios
      .get(`${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          const newTransitSelectedSeats = [];
          const transit = response.data.data.tripTransit[1];
          Object.keys(transit.reservationSeats).forEach((seatNumber) => {
            const transitSeatStatus = transit.reservationSeats[seatNumber];
            if (transitSeatStatus === 5) {
              const seatDetails = transit.seatStatusAuth[seatNumber];
              if (seatDetails && seatDetails.numStatus === 5) {
                newTransitSelectedSeats.push({
                  seat_number: parseInt(seatNumber),
                  id_system: seatDetails.id_system,
                  numStatus: seatDetails.numStatus,
                  status: "Selected",
                  id_client: 0,
                  transitStatus: null,
                  id_transit: response.data.data.tripTransit[1]?.id,
                  trip_id: response.data.data.id,
                });
              }
            }
          });

          const reservationSeats = transit.reservationSeats;
          const seatStatusAuth = transit.seatStatusAuth;
          Object.keys(transit.reservationSeats).forEach((seatNumber) => {
            const status = reservationSeats[seatNumber];
   
            if (status === 5 && !seatStatusAuth[seatNumber]) {
              newTransitSelectedSeats.push({
                seat_number: parseInt(seatNumber),
                id_system: 1,
                numStatus: status,
                id_client: 0,
                status: "Selected",
                transitStatus: null,
                id_transit: response.data.data.tripTransit[1].id,
                trip_id: idtrip,
              });
            }
          });
          const currentUserReservedSeats = newTransitSelectedSeats.filter(
            (userSeat) =>
              userSeat.id_system === user.user_id && userSeat.numStatus == 5
          );

          const currentUserReservedSeatNumbers = currentUserReservedSeats.map(
            (seat) => seat.seat_number
          );

          setSelectedSeats(currentUserReservedSeatNumbers);
          setSeatAndUser(newTransitSelectedSeats);
          setSelected(newTransitSelectedSeats);
          // if(newTransitSelectedSeats == 0 ){

          //   setResultArray([]);
          //   setInputString("");
          //   setValueinput("");
          //   setSelectedSeats([]);
          //   setSelectedSeats1([]);
          //   setSelectedSeats2([]);
          // }
          setResultArrayseatuser(newTransitSelectedSeats);
          const sortedCombinedSeats = currentUserReservedSeatNumbers.sort(
            (a, b) => a - b
          );
          setValueinput(currentUserReservedSeatNumbers.join("+"));
          setPathTransit(response.data.data.tripTransit[1].path);
          setPathtrip(response.data.data.tripTransit[1].path);
          dispatch(fetchTripBYId(response.data.data.id));
          setIDTransit(response.data.data.tripTransit[1].id);
          setIdTransit2(response.data.data.tripTransit[1].id);
          setIDtrip(response.data.data.tripTransit[1].id);
          const mappedById =
            response.data.data.tripTransit[1]?.reservation.reduce(
              (acc, item) => {
                acc[item.id] = item;
                return acc;
              },
              {}
            );
          setArrayReservations(mappedById);

          let seatWithPassengerTransit2 = [];
          // Safely access the reservationSeats property
          seatWithPassengerTransit2 = Object.keys(
            response.data.data.tripTransit[1].reservationSeats
          ).reduce((accumulator, currentKey) => {
            // Assuming you want to do something with the keys here
            const reservationInfo =
              response.data.data.tripTransit[1]?.reservation.find(
                (reservation) =>
                  reservation.reservation_seats_number &&
                  reservation.reservation_seats_number[currentKey] > 0
              );

            const passenger = reservationInfo
              ? reservationInfo.passengers?.find(
                  (p) => p.number_seat == currentKey
                )
              : null;

            accumulator[currentKey] = {
              passengers: passenger ? Object.assign({}, passenger) : null, // safely copy passenger object
              status: reservationInfo?.reservation_seats_number[currentKey],
              id_reservation: reservationInfo?.id,
            };

            return accumulator;
          }, {});

          const exampleData = {
            ...seatWithPassengerTransit2, // This is your existing seat data
            id: response.data.data.tripTransit[1].id, // Ensure you are actually setting the id like this
          };
          setArraySeatPassengerTransit2(exampleData);

          setIDdirect();
          settranset(true);
          setDirect(false);
          localStorage.setItem("isdirect", false);
          setReservationseat(response.data.data.tripTransit[1]);
          setIDTripType(response.data.data.tripTransit[1].id_trip_type);

          setReservationseat1(
            response.data.data.tripTransit[1].reservationSeats
          );
          setShowDirectTrip(true);
          setColor(color === "#82c40e" ? "orange" : "orange");
          setClick(true);
          setidTriptran(response.data.data.tripTransit[1].id);
          settransetnumber(2);
          localStorage.setItem(
            "idtransit",
            response.data.data.tripTransit[1].id
          );
          setCommonSeats(
            response.data.data.seats.seats
              .map((seat) => parseInt(seat))
              .filter((seat) =>
                response.data.data.tripTransit
                  .flatMap((e) => Object.keys(e.reservationSeats).map(Number))
                  .includes(seat)
              )
          );
        }
      });

    }else {
      sweetalert({
        text: "يجب إلغاء تحديد الكراسي قيل الانتقال لرحلة التالية",
        icon: "error",
        buttons: {
          confirm: {
            text: "إغلاق",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
      
      });
    }
     
  }
  async function setReservationdirect() {
    const valueInputArray = Array.isArray(valueinput)
    ? valueinput
    : valueinput.split(/[\s,+]+/);
  const combinedSeats = [
    ...selectedSeats,
    ...selectedSeats1,
    ...selectedSeats2,
    ...valueInputArray,
  ]
    .map((seat) => {
      if (typeof seat === "number") return seat;

      return seat.split(/[\s,+]+/).map(Number);
    })
    .flat();

  const sortedNumbers = Array.from(
    new Set(combinedSeats.filter((seat) => !isNaN(seat)))
  );
  const uniqueNumbersWithoutZero = sortedNumbers.filter(
    (number) => number !== 0
  );
  const seatNumberToSend = uniqueNumbersWithoutZero.sort((a, b) => a - b);
  
    if(seatNumberToSend.length == 0 ){

    const branch = localStorage.getItem("branch");

    await axios
      .get(`${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          const mappedById = response.data.data.reservations.reduce(
            (acc, item) => {
              acc[item.id] = item;
              return acc;
            },
            {}
          );
          setReservationseat1(
            response.data.data.tripTransit[0].reservationSeats
          );
          setResponse1(response.data.data.reservation_seats);
          setArrayReservations(mappedById);
          let passenger;
          let seatWithPassengerNull = Object.keys(
            response.data.data.reservation_seats
          ).reduce((accumulator, currentKey) => {
            const reservationInfo = response.data.data.reservations.find(
              (reservation) =>
                reservation.reservation_seats_number[currentKey] > 0
            );
            passenger = reservationInfo
              ? reservationInfo.passengers?.find(
                  (p) => p.number_seat == currentKey
                )
              : null;

            accumulator[currentKey] = {
              passengers: Object(passenger),
              status: reservationInfo?.reservation_seats_number[currentKey],
              id_reservation: reservationInfo?.id,
            };
            return accumulator;
          }, {});

          setArraySeat(seatWithPassengerNull);
          // setSelected(Object.values(response.data.data.seatStatusAuth));
          const newDirectSelectedSeats = [];
          const reservationSeats = response.data.data.reservation_seats;
          const seatStatusAuth = response.data.data.seatStatusAuth;
          Object.keys(response.data.data.reservation_seats).forEach((seatNumber) => {
            const status = response.data.data.reservation_seats[seatNumber];
    
            if (status === 5 && !response.data.data.seatStatusAuth[seatNumber]) {
              newDirectSelectedSeats.push({
                seat_number: parseInt(seatNumber),
                id_system: 1,
                numStatus: status,
                id_client: 0,
                status: "Selected",
                transitStatus: null,
                id_transit: null,
                trip_id: response.data.data.id,
              });
            }
          });
          setSelected(newDirectSelectedSeats);
          const transitSeatNumbers = new Set();
          setDirect(true);
          settranset(false);
          if (
            response.data.data.tripTransit &&
            response.data.data.tripTransit.length > 0
          ) {
            response.data.data.tripTransit.forEach((transit) => {
              Object.keys(transit.reservationSeats).forEach((seatNumber) => {
                transitSeatNumbers.add(seatNumber);
              });
            });
          }
          Object.keys(response.data.data.seatStatusAuth).forEach(
            (seatNumber) => {
              const seatDetails = response.data.data.seatStatusAuth[seatNumber];
              if (
                seatDetails.numStatus === 5 &&
                response.data.data.reservation_seats[seatNumber] !==
                  undefined &&
                !transitSeatNumbers.has(seatNumber)
              ) {
                newDirectSelectedSeats.push({
                  seat_number: parseInt(seatNumber),
                  id_system: seatDetails.id_system,
                  numStatus: seatDetails.numStatus,
                  id_client: 0,
                  status: "Selected",
                  transitStatus: null,
                  id_transit: null,
                  trip_id: response.data.data.id,
                });
              }
            }
          );
          const currentUserReservedSeats = newDirectSelectedSeats.filter(
            (userSeat) =>
              userSeat.id_system === user.user_id && userSeat.numStatus == 5
          );

          const currentUserReservedSeatNumbers = currentUserReservedSeats.map(
            (seat) => seat.seat_number
          );
    
    
          // if(newDirectSelectedSeats == 0 ){

          //   setResultArray([]);
          //   setInputString("");
          //   setValueinput("");
          //   setSelectedSeats([]);
          //   setSelectedSeats1([]);
          //   setSelectedSeats2([]);
          // }
          const patth = `${response.data.data.path_from} - ${response.data.data.path_to}`;
          setPathtrip(patth);
          setIDdirect(response.data.data.id);
          setIDtrip(response.data.data.id);
          setIDTripType(response.data.data.id_trip_type);
          setidTriptran(response.data.data.id);
          setCommonSeats(
            response.data.data.seats.seats
              .map((seat) => parseInt(seat))
              .filter((seat) =>
                response.data.data.tripTransit
                  .flatMap((e) => Object.keys(e.reservationSeats).map(Number))
                  .includes(seat)
              )
          );
        }
      });

    setColor("rgb(168, 168, 168)");
    setClick(false);
    setIDTransit(null);
    setShowDirectTrip(false);
    setSelectedTransitIndex("");
    localStorage.setItem("isdirect", true);
    setIDTransit("");

   
   
    setDirect(true);
    settranset(false);
    settransetnumber();
  }else {
    sweetalert({
      text: "يجب إلغاء تحديد الكراسي قيل الانتقال لرحلة التالية",
      icon: "error",
      buttons: {
        confirm: {
          text: "إغلاق",
          className: "custom-button-class",
        },
      },
      className: "alert-div",
    }).then(() => {
    
    });
  }
  }
  useEffect(() => {}, [TripID,select,setSelected]);
  function focusInput() {
    // Focus on the input element
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }
  useEffect(() => {
    if (
      localStorage.getItem("openpassenger") === "false" &&
      localStorage.getItem("changeseat") === "false" &&
      localStorage.getItem("ReservationPhone") === "false"
    ) {
      const handleKeyDown = (event) => {
        if (
          event.key === key_Shortcut.focus_input_seats ||
          event.key === key_Shortcut.focus_input_seats11
        ) {
          event.preventDefault();
          focusInput();
        }
      };
      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [
    localStorage.getItem("openpassenger"),
    localStorage.getItem("changeseat"),
    localStorage.getItem("ReservationPhone"),
  ]);
  const handleKeyDown = async (event) => {
    // ================================================================
    setInputString((prev) => prev + event.key);
    if (openPopuppassenger === false) {
      if (valueinput !== "") {
        if (event.ctrlKey && event.key === "1") {
          if (nofocus) {
            nofocus.blur();
          }
          setTitle("إضافة مسافر");
          setReservation("إضافة حجز");
          if (clickFunction === false) {
            const parts1 = valueinput.split("+");
            const numbers = parts1
              .map((part) => {
                if (part.includes("-")) {
                  const [start, end] = part.split("-").map(Number);
                  return [...Array(end - start + 1).keys()].map(
                    (i) => i + start
                  );
                } else {
                  return Number(part);
                }
              })
              .flat();

            const valueInputArray = Array.isArray(valueinput)
              ? valueinput
              : valueinput.split(/[\s,+]+/);
            const combinedSeats = [
              ...selectedSeats,
              ...selectedSeats1,
              ...selectedSeats2,
              ...valueInputArray,
            ]
              .map((seat) => {
                if (typeof seat === "number") return seat;

                return seat.split(/[\s,+]+/).map(Number);
              })
              .flat();

            
            const uniqueNumbers = Array.from(
              new Set(combinedSeats.filter((seat) => !isNaN(seat)))
            );
            const uniqueNumbersWithoutZero = uniqueNumbers.filter(
              (number) => number !== 0
            );
            const sortedNumbers = uniqueNumbersWithoutZero.sort(
              (a, b) => a - b
            );

            setResultArray(sortedNumbers);

            const seatNumberToSend = sortedNumbers;
            const isTransitTrip =
              TripID.tripTransit &&
              TripID.tripTransit.some((transit) => {
                return transit.reservationSeats.hasOwnProperty(
                  seatNumberToSend[0]
                );
              });
            if (isTransitTrip) {
              const status = reservationseat1[seatNumberToSend[0]];
              if (status == 1) {
                setResultArray([]);
                sweetalert({
                  text: "لا يمكن إضافة حجز لان الكرسي محجوز",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (status == undefined || status == '') {
                setResultArray([]);
                sweetalert({
                  text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (status == 2) {
                setResultArray([]);
                sweetalert({
                  text: "لا يمكن إضافة حجز لان الكرسي محجوز",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else {
                const data = {
                  triptable_id: idTransit,
                  triptable_type: "Domain\\TripTransit",
                  seat_number: seatNumberToSend,
                  branch: TripID.id_branch,
                  trip_id: TripID.id,
                };
                await axios
                  .post(`${BaseUrl}/addReservation`, data, {
                    withCredentials: true,
                  })
                  .then((response) => {
                    const responseData = response.data.data;
                    setIdReservationResponse(responseData);
                    if (!arrayReservations[responseData.id]) {
                      arrayReservations[responseData.id] = responseData;
                    }

                    localStorage.setItem("openpassenger", true);
                    localStorage.setItem("AddReservation", false);
                    setReservationseat1(prevState => {
                      // Create a new state object by copying the previous state
                      const updatedState = {...prevState};
                
                      // Iterate over each key in the responseData's reservation_seats_number
                      Object.keys(responseData.reservation_seats_number).forEach(seatNumber => {
                        // Update the specific seat number with the new status from reservation_seats_number
                        // Convert seatNumber to an integer if your state keys are integers
                        const seatNum = parseInt(seatNumber);
                        if (!isNaN(seatNum)) {
                          updatedState[seatNum] = responseData.reservation_seats_number[seatNumber] // assuming this holds the new status
                          
                        }
                      });
                     
                      // Return the updated state
                      return updatedState;
                    });
                  })
                  .catch((error) => {
                    // Handle the error here
                    setResponse1([]);
                    dispatch(fetchTripBYId(TripID.id));
                    setResponse1(TripID.reservation_seats);
                  });
              }
            } else {
              const status = response1[seatNumberToSend[0]];
              const isTransitTrip1 =
                TripID.tripTransit &&
                TripID.tripTransit.some((transit) => {
                  return transit.reservationSeats.hasOwnProperty(
                    sortedNumbers[0]
                  );
                });

              if (isTransitTrip1 == true) {
                sweetalert({
                  title: "خطأ في العملية",
                  text: "  ",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                });
              } else {
                if (status == 1) {
                  setResultArray([]);
                  sweetalert({
                    text: "لا يمكن إضافة حجز لان الكرسي محجوز",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "إغلاق",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                }
                else if (status == undefined || status == '') {
                  setResultArray([]);
                  sweetalert({
                    text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "إغلاق",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                }
                else if (status == 2) {
                  setResultArray([]);
                  sweetalert({
                    text: "لا يمكن إضافة حجز لان الكرسي محجوز",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "إغلاق",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                } else {
                  const data = {
                    triptable_id: idDirect,
                    triptable_type: "Domain\\Trip",
                    seat_number: seatNumberToSend,
                    branch: TripID.id_branch,
                    trip_id: TripID.id,
                  };
                  await axios
                    .post(`${BaseUrl}/addReservation`, data, {
                      withCredentials: true,
                    })
                    .then((response) => {
                      const responseData = response.data.data;
                      setIdReservationResponse(responseData);
                      if (!arrayReservations[responseData.id]) {
                        arrayReservations[responseData.id] = responseData;
                      }
                      setArraySeat((prevSeatsInfo) => {
                        const updatedSeatsInfo = { ...prevSeatsInfo };

                        seatNumberToSend.forEach((number) => {
                          const seatNumber = parseInt(number);

                          if (!isNaN(seatNumber)) {
                            updatedSeatsInfo[seatNumber] = {
                              passengers: {}, // Assuming you want to reset passengers info
                              id_reservation: responseData.id,
                              status: 1, // Resetting the status
                            };
                          }
                        });

                        return updatedSeatsInfo;
                      });
                      setResponse1(prevState => {
                        // Create a new state object by copying the previous state
                        const updatedState = {...prevState};
                  
                        // Iterate over each key in the responseData's reservation_seats_number
                        Object.keys(responseData.reservation_seats_number).forEach(seatNumber => {
                          // Update the specific seat number with the new status from reservation_seats_number
                          // Convert seatNumber to an integer if your state keys are integers
                          const seatNum = parseInt(seatNumber);
                          if (!isNaN(seatNum)) {
                            updatedState[seatNum] = responseData.reservation_seats_number[seatNumber] // assuming this holds the new status
                            
                          }
                        });
                       
                        // Return the updated state
                        return updatedState;
                      });
                      localStorage.setItem("openpassenger", true);
                      localStorage.setItem("AddReservation", false);
                    })
                    .catch((error) => {
                      // Handle the error here
                      setResponse1([]);
                      dispatch(fetchTripBYId(TripID.id));
                      setResponse1(TripID.reservation_seats);
                    });

                  setInputString("");
                  setValueinput("");
                  setSelectedSeats1([]);
                  setSelectedSeats([]);
                  setSeatAndUser([]);
                }
               
              }
            }
          }
          if (clickFunction === true) {
            const parts1 = valueinput.split("+");
            const valueInputArray = Array.isArray(valueinput)
              ? valueinput
              : valueinput.split(/[\s,+]+/);
            const combinedSeats = [
              ...selectedSeats,
              ...selectedSeats1,
              ...selectedSeats2,
              ...valueInputArray,
            ]
              .map((seat) => {
                if (typeof seat === "number") return seat;

                return seat.split(/[\s,+]+/).map(Number);
              })
              .flat();

        
            const uniqueNumbers = Array.from(
              new Set(combinedSeats.filter((seat) => !isNaN(seat)))
            );
            const uniqueNumbersWithoutZero = uniqueNumbers.filter(
              (number) => number !== 0
            );
            const sortedNumbers = uniqueNumbersWithoutZero.sort(
              (a, b) => a - b
            );

            setResultArray(sortedNumbers);

            const isTransitTrip =
              TripID.tripTransit &&
              TripID.tripTransit.some((transit) => {
                return transit.reservationSeats.hasOwnProperty(
                  sortedNumbers[0]
                );
              });

            if (isTransitTrip) {
              const status = reservationseat1[sortedNumbers[0]];
              if (status == 1) {
                setResultArray([]);
                sweetalert({
                  text: "لا يمكن إضافة حجز لان الكرسي محجوز",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } 
              else if (status == undefined || status == '') {
                setResultArray([]);
                sweetalert({
                  text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (status == 2) {
                setResultArray([]);
                sweetalert({
                  text: "لا يمكن إضافة حجز لان الكرسي محجوز",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else {
                const data = {
                  triptable_id: idTransit,
                  triptable_type: "Domain\\TripTransit",
                  seat_number: sortedNumbers,
                  branch: TripID.id_branch,
                  trip_id: TripID.id,
                };
                await axios
                  .post(`${BaseUrl}/addReservation`, data, {
                    withCredentials: true,
                  })
                  .then((response) => {
                    const responseData = response.data.data;
                    setIdReservationResponse(responseData);
                    if (!arrayReservations[responseData.id]) {
                      arrayReservations[responseData.id] = responseData;
                    }
                    setReservationseat1(prevState => {
                      // Create a new state object by copying the previous state
                      const updatedState = {...prevState};
                
                      // Iterate over each key in the responseData's reservation_seats_number
                      Object.keys(responseData.reservation_seats_number).forEach(seatNumber => {
                        // Update the specific seat number with the new status from reservation_seats_number
                        // Convert seatNumber to an integer if your state keys are integers
                        const seatNum = parseInt(seatNumber);
                        if (!isNaN(seatNum)) {
                          updatedState[seatNum] = responseData.reservation_seats_number[seatNumber] // assuming this holds the new status
                          
                        }
                      });
                     
                      // Return the updated state
                      return updatedState;
                    });
                    localStorage.setItem("openpassenger", true);
                    localStorage.setItem("AddReservation", false);
                  })
                  .catch((error) => {
                    // Handle the error here
                    setResponse1([]);
                    dispatch(fetchTripBYId(TripID.id));
                    setResponse1(TripID.reservation_seats);
                  });
              }
            } else {
              const status = response1[sortedNumbers[0]];
              if (status == 1) {
                sweetalert({
                  text: "لا يمكن إضافة حجز لان الكرسي محجوز",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } 
              else if (status == undefined || status == '') {
                setResultArray([]);
                sweetalert({
                  text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (status == 2) {
                setResultArray([]);
                sweetalert({
                  text: "لا يمكن إضافة حجز لان الكرسي محجوز",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else {
                const data = {
                  triptable_id: idDirect,
                  triptable_type: "Domain\\Trip",
                  seat_number: sortedNumbers,
                  branch: TripID.id_branch,
                  trip_id: TripID.id,
                };
                await axios
                  .post(`${BaseUrl}/addReservation`, data, {
                    withCredentials: true,
                  })
                  .then((response) => {
                    const responseData = response.data.data;
                    setIdReservationResponse(responseData);
                    if (!arrayReservations[responseData.id]) {
                      arrayReservations[responseData.id] = responseData;
                    }
                    setArraySeat((prevSeatsInfo) => {
                      const updatedSeatsInfo = { ...prevSeatsInfo };

                      sortedNumbers.forEach((number) => {
                        const seatNumber = parseInt(number);

                        if (!isNaN(seatNumber)) {
                          updatedSeatsInfo[seatNumber] = {
                            passengers: {}, // Assuming you want to reset passengers info
                            id_reservation: responseData.id,
                            status: 1, // Resetting the status
                          };
                        }
                      });

                      return updatedSeatsInfo;
                    });
                    setResponse1(prevState => {
                      // Create a new state object by copying the previous state
                      const updatedState = {...prevState};
                
                      // Iterate over each key in the responseData's reservation_seats_number
                      Object.keys(responseData.reservation_seats_number).forEach(seatNumber => {
                        // Update the specific seat number with the new status from reservation_seats_number
                        // Convert seatNumber to an integer if your state keys are integers
                        const seatNum = parseInt(seatNumber);
                        if (!isNaN(seatNum)) {
                          updatedState[seatNum] = responseData.reservation_seats_number[seatNumber] // assuming this holds the new status
                          
                        }
                      });
                     
                      // Return the updated state
                      return updatedState;
                    });
                    localStorage.setItem("openpassenger", true);
                    localStorage.setItem("AddReservation", false);
                  })
                  .catch((error) => {
                    // Handle the error here
                    setResponse1([]);
                    dispatch(fetchTripBYId(TripID.id));
                    setResponse1(TripID.reservation_seats);
                  });
                setInputString("");
                setValueinput("");
                setSelectedSeats1([]);
                setSelectedSeats([]);
                setSelectedSeats2([]);
                setSeatAndUser([]);
              }
            }
          }
        }
        if (event.ctrlKey && event.key === "4") {
          if (nofocus) {
            nofocus.blur();
          }
          localStorage.setItem("changeseat", true);
          if (clickFunction === false) {
            const parts1 = valueinput.split("+");
            const numbers = parts1
              .map((part) => {
                if (part.includes("-")) {
                  const [start, end] = part.split("-").map(Number);
                  return [...Array(end - start + 1).keys()].map(
                    (i) => i + start
                  );
                } else {
                  return Number(part);
                }
              })
              .flat();

            const valueInputArray = Array.isArray(valueinput)
              ? valueinput
              : valueinput.split(/[\s,+]+/);
            const combinedSeats = [
              ...selectedSeats,
              ...selectedSeats1,
              ...selectedSeats2,
              ...valueInputArray,
            ]
              .map((seat) => {
                if (typeof seat === "number") return seat;

                return seat.split(/[\s,+]+/).map(Number);
              })
              .flat();

            const sortedNumbers1 = Array.from(
              new Set(combinedSeats.filter((seat) => !isNaN(seat)))
            );
            const uniqueNumbersWithoutZero = sortedNumbers1.filter(
              (number) => number !== 0
            );
            const sortedNumbers = sortedNumbers1.sort((a, b) => a - b);
            setResultArray(sortedNumbers);

            const seatNumberToSend = sortedNumbers;
            localStorage.setItem("changeseat", true);
            const isTransitTrip =
              TripID.tripTransit &&
              TripID.tripTransit.some((transit) => {
                return transit.reservationSeats.hasOwnProperty(
                  sortedNumbers[0]
                );
              });
            if (isTransitTrip) {
              const status = reservationseat1[seatNumberToSend[0]];

              if (status == 1) {
                setResultArray([]);
                
                sweetalert({
                  text: "لا يمكن حجز الكرسي شركة  لانه مضاف لحجز معين",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (status == undefined || status == '') {
                setResultArray([]);
                sweetalert({
                  text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (status == 3 || status == 4) {
                setResultArray([]);
                sweetalert({
                  text: "لا يمكن القيام بهذه العملية",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else if (status == 2) {
                setResultArray([]);
                sweetalert({
                  text: "لا يمكن حجز الكرسي شركة  لانه مضاف لحجز معين",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else {
                const data = {
                  triptable_id: idTransit,
                  tripid: TripID.id,
                  triptable_type: "Domain\\TripTransit",
                  seat_number: sortedNumbers,
                  status: 4,
                  branch: TripID.id_branch,
                };
                const callback = () => {
                  dispatch(setResultArray([])); // Dispatch the action to update the state
                };
                await axios
                  .post(`${BaseUrl}/changeStatusSeat`, data, {
                    withCredentials: true,
                  })
                  .then((response) => {
                    if (response.data.status === 200) {
                      setReservationseat1(response.data.data[0]);
                      const mappedById = response.data.reservations.reduce(
                        (acc, item) => {
                          acc[item.id] = item;
                          return acc;
                        },
                        {}
                      );
                      setArrayReservations(mappedById);
                      if (selectedTransitIndex == 0) {
                        let seatWithPassengerTransit1 = [];
                        // Safely access the reservationSeats property
                        seatWithPassengerTransit1 = Object.keys(
                          response.data.data[0]
                        ).reduce((accumulator, currentKey) => {
                          // Assuming you want to do something with the keys here
                          const reservationInfo =
                            response.data.reservations.find(
                              (reservation) =>
                                reservation.reservation_seats_number &&
                                reservation.reservation_seats_number[
                                  currentKey
                                ] > 0
                            );

                          const passenger = reservationInfo
                            ? reservationInfo.passengers?.find(
                                (p) => p.number_seat == currentKey
                              )
                            : null;

                          accumulator[currentKey] = {
                            passengers: passenger
                              ? Object.assign({}, passenger)
                              : null, // safely copy passenger object
                            status:
                              reservationInfo?.reservation_seats_number[
                                currentKey
                              ],
                            id_reservation: reservationInfo?.id,
                          };

                          return accumulator;
                        }, {});
                        // setInitialIdTransit(TripID.tripTransit[0].id);
                        // seatWithPassengerTransit1.id = TripID.tripTransit[0].id;
                        const exampleData = {
                          ...seatWithPassengerTransit1, // This is your existing seat data
                          id: TripID.tripTransit[0].id, // Ensure you are actually setting the id like this
                        };
                        setArraySeatPassengerTransit1(exampleData);
                      }
                      if (selectedTransitIndex == 1) {
                        let seatWithPassengerTransit2 = [];
                        // Safely access the reservationSeats property
                        seatWithPassengerTransit2 = Object.keys(
                          response.data.data[0]
                        ).reduce((accumulator, currentKey) => {
                          // Assuming you want to do something with the keys here
                          const reservationInfo =
                            response.data.reservations.find(
                              (reservation) =>
                                reservation.reservation_seats_number &&
                                reservation.reservation_seats_number[
                                  currentKey
                                ] > 0
                            );

                          const passenger = reservationInfo
                            ? reservationInfo.passengers?.find(
                                (p) => p.number_seat == currentKey
                              )
                            : null;

                          accumulator[currentKey] = {
                            passengers: passenger
                              ? Object.assign({}, passenger)
                              : null, // safely copy passenger object
                            status:
                              reservationInfo?.reservation_seats_number[
                                currentKey
                              ],
                            id_reservation: reservationInfo?.id,
                          };

                          return accumulator;
                        }, {});
                    
                        const exampleData = {
                          ...seatWithPassengerTransit2, // This is your existing seat data
                          id: TripID.tripTransit[1].id, // Ensure you are actually setting the id like this
                        };
                        setArraySeatPassengerTransit2(exampleData);
                      }
                      const newDirectSelectedSeats = [];
                      const transitSeatNumbers = new Set();
                      if (TripID.tripTransit && TripID.tripTransit.length > 0) {
                        TripID.tripTransit.forEach((transit) => {
                          Object.keys(transit.reservationSeats).forEach(
                            (seatNumber) => {
                              transitSeatNumbers.add(seatNumber);
                            }
                          );
                        });
                      }
                      Object.keys(response.data.data[1]).forEach(
                        (seatNumber) => {
                          const seatDetails = response.data.data[1][seatNumber];
                          if (
                            seatDetails.numStatus === 5 &&
                            response1[seatNumber] !== undefined &&
                            !transitSeatNumbers.has(seatNumber)
                          ) {
                            newDirectSelectedSeats.push({
                              seat_number: parseInt(seatNumber),
                              id_system: seatDetails.id_system,
                              numStatus: seatDetails.numStatus,
                            });
                          }
                        }
                      );
                      const reservationSeats = response.data.data[0];
                      const seatStatusAuth = response.data.data[1];
                      Object.keys(response.data.data[0]).forEach((seatNumber) => {
                        const status = reservationSeats[seatNumber];
               
                        if (status === 5 && !seatStatusAuth[seatNumber]) {
                          newDirectSelectedSeats.push({
                            seat_number: parseInt(seatNumber),
                            id_system: 1,
                            numStatus: status,
                            id_client: 0,
                            status: "Selected",
                            transitStatus: null,
                            id_transit: idtrip,
                            trip_id: TripID.id,
                          });
                        }
                      });
                      const currentUserReservedSeats =
                        newDirectSelectedSeats.filter(
                          (userSeat) =>
                            userSeat.id_system === user.user_id &&
                            userSeat.numStatus == 5
                        );
                      const currentUserReservedSeatNumbers =
                        currentUserReservedSeats.map(
                          (seat) => seat.seat_number
                        );
                      setSelectedSeats(currentUserReservedSeatNumbers);
                      setSeatAndUser(newDirectSelectedSeats);
                      setSelected(newDirectSelectedSeats);
                      setResultArrayseatuser(newDirectSelectedSeats);
                      const sortedCombinedSeats =
                        currentUserReservedSeatNumbers.sort((a, b) => a - b);
                      setValueinput(sortedCombinedSeats.join("+"));
                      sweetalert({
                        text: "تم الحجز بنجاح   ",
                        icon: "success",
                        buttons: {
                          confirm: {
                            text: "تم",
                            className: "custom-button-class",
                          },
                        },
                        className: "alert-div",
                      }).then(() => {
                        localStorage.setItem("changeseat", false);
                      });
                    }
                  })
                  .catch((error) => {
                    // Handle the error here
                    setResponse1([]);
                    dispatch(fetchTripBYId(TripID.id));
                    setResponse1(TripID.reservation_seats);
                  });
                setInputString("");
                setValueinput("");
              }
            } else {
              const status = response1[seatNumberToSend[0]];

              if (status == 1) {
                setResultArray([]);
                sweetalert({
                  text: "لا يمكن حجز الكرسي شركة  لانه مضاف لحجز معين",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } 
              else if (status == undefined || status == '') {
                setResultArray([]);
                sweetalert({
                  text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (status == 3) {
                setResultArray([]);
                sweetalert({
                  text: "لا يمكن حجز الكرسي شركة ",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else if (status == 4) {
                setResultArray([]);
                sweetalert({
                  text: "لا يمكن حجز الكرسي شركة ",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else if (status == 2) {
                setResultArray([]);
                sweetalert({
                  text: "لا يمكن حجز الكرسي شركة  لانه مضاف لحجز معين",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else {
                const data = {
                  triptable_id: idDirect,
                  triptable_type: "Domain\\Trip",
                  seat_number: sortedNumbers,
                  status: 4,
                  branch: TripID.id_branch,
                  tripid: TripID.id,
                };
                const callback = () => {
                  dispatch(setResultArray([])); // Dispatch the action to update the state
                };
                await axios
                  .post(`${BaseUrl}/changeStatusSeat`, data, {
                    withCredentials: true,
                  })
                  .then((response) => {
                    if (response.data.status === 200) {
                      setResponse1(response.data.data[0]);
                      const mappedById = response.data.reservations.reduce(
                        (acc, item) => {
                          acc[item.id] = item;
                          return acc;
                        },
                        {}
                      );
                      setArrayReservations(mappedById);
                      let passenger;
                      let seatWithPassengerNull = Object.keys(
                        response.data.data[0]
                      ).reduce((accumulator, currentKey) => {
                        const reservationInfo = response.data.reservations.find(
                          (reservation) =>
                            reservation.reservation_seats_number[currentKey] > 0
                        );
                        passenger = reservationInfo
                          ? reservationInfo.passengers?.find(
                              (p) => p.number_seat == currentKey
                            )
                          : null;

                        accumulator[currentKey] = {
                          passengers: Object(passenger),
                          status:
                            reservationInfo?.reservation_seats_number[
                              currentKey
                            ],
                          id_reservation: reservationInfo?.id,
                        };
                        return accumulator;
                      }, {});
                      setArraySeat(seatWithPassengerNull);
                      const newDirectSelectedSeats = [];
                      const transitSeatNumbers = new Set();
                      if (TripID.tripTransit && TripID.tripTransit.length > 0) {
                        TripID.tripTransit.forEach((transit) => {
                          Object.keys(transit.reservationSeats).forEach(
                            (seatNumber) => {
                              transitSeatNumbers.add(seatNumber);
                            }
                          );
                        });
                      }
                      Object.keys(response.data.data[1]).forEach(
                        (seatNumber) => {
                          const seatDetails = response.data.data[1][seatNumber];
                          if (
                            seatDetails.numStatus === 5 &&
                            response1[seatNumber] !== undefined &&
                            !transitSeatNumbers.has(seatNumber)
                          ) {
                            newDirectSelectedSeats.push({
                              seat_number: parseInt(seatNumber),
                              id_system: seatDetails.id_system,
                              numStatus: seatDetails.numStatus,
                            });
                          }
                        }
                      );
                      const reservationSeats = response.data.data[0];
                      const seatStatusAuth = response.data.data[1];
                      Object.keys(response.data.data[0]).forEach((seatNumber) => {
                        const status = reservationSeats[seatNumber];
               
                        if (status === 5 && !seatStatusAuth[seatNumber]) {
                          newDirectSelectedSeats.push({
                            seat_number: parseInt(seatNumber),
                            id_system: 1,
                            numStatus: status,
                            id_client: 0,
                            status: "Selected",
                            transitStatus: null,
                            id_transit: null,
                            trip_id: idtrip,
                          });
                        }
                      });
                      const currentUserReservedSeats =
                        newDirectSelectedSeats.filter(
                          (userSeat) =>
                            userSeat.id_system === user.user_id &&
                            userSeat.numStatus == 5
                        );
                      const currentUserReservedSeatNumbers =
                        currentUserReservedSeats.map(
                          (seat) => seat.seat_number
                        );
                      setSelectedSeats(currentUserReservedSeatNumbers);
                      setSeatAndUser(newDirectSelectedSeats);
                      setSelected(newDirectSelectedSeats);
                      setResultArrayseatuser(newDirectSelectedSeats);
                      const sortedCombinedSeats =
                        currentUserReservedSeatNumbers.sort((a, b) => a - b);
                      setValueinput(sortedCombinedSeats.join("+"));
                      sweetalert({
                        text: "تم الحجز بنجاح   ",
                        icon: "success",
                        buttons: {
                          confirm: {
                            text: "تم",
                            className: "custom-button-class",
                          },
                        },
                        className: "alert-div",
                      }).then(() => {
                        localStorage.setItem("changeseat", false);
                      });
                    }
                  })
                  .catch((error) => {
                    // Handle the error here
                    setResponse1([]);
                    dispatch(fetchTripBYId(TripID.id));
                    setResponse1(TripID.reservation_seats);
                  });
                setResultArray([]);
                setInputString("");
                setValueinput("");
                setSelectedSeats([]);
                setSelectedSeats1([]);
                setSelectedSeats2([]);
              }
       
            }
          }
          if (clickFunction === true) {
            const valueInputArray = Array.isArray(valueinput)
              ? valueinput
              : valueinput.split(/[\s,+]+/);
            const combinedSeats = [
              ...selectedSeats,
              ...selectedSeats1,
              ...selectedSeats2,
              ...valueInputArray,
            ]
              .map((seat) => {
                if (typeof seat === "number") return seat;

                return seat.split(/[\s,+]+/).map(Number);
              })
              .flat();
            const uniqueNumbers = Array.from(
              new Set(combinedSeats.filter((seat) => !isNaN(seat)))
            );
            const uniqueNumbersWithoutZero = uniqueNumbers.filter(
              (number) => number !== 0
            );
            const sortedNumbers = uniqueNumbersWithoutZero.sort(
              (a, b) => a - b
            );

            setResultArray(sortedNumbers);
            const isTransitTrip =
              TripID.tripTransit &&
              TripID.tripTransit.some((transit) => {
                return transit.reservationSeats.hasOwnProperty(
                  sortedNumbers[0]
                );
              });
            if (isTransitTrip) {
              const status = reservationseat1[sortedNumbers[0]];

              if (status == 1) {
                setResultArray([]);
                
                sweetalert({
                  text: "لا يمكن حجز الكرسي شركة  لانه مضاف لحجز معين",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (status == undefined || status == '') {
                setResultArray([]);
                sweetalert({
                  text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (status == 3 || status == 4) {
                setResultArray([]);
                sweetalert({
                  text: "لا يمكن القيام بهذه العملية",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else if (status == 2) {
                setResultArray([]);
                sweetalert({
                  text: "لا يمكن حجز الكرسي شركة  لانه مضاف لحجز معين",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else {
                const data = {
                  triptable_id: idTransit,
                  triptable_type: "Domain\\TripTransit",
                  seat_number: sortedNumbers,
                  status: 4,
                  branch: TripID.id_branch,
                  tripid: TripID.id,
                };
                await axios
                  .post(`${BaseUrl}/changeStatusSeat`, data, {
                    withCredentials: true,
                  })
                  .then((response) => {
                    if (response.data.status === 200) {
                      setReservationseat1(response.data.data[0]);
                      const mappedById = response.data.reservations.reduce(
                        (acc, item) => {
                          acc[item.id] = item;
                          return acc;
                        },
                        {}
                      );
                      setArrayReservations(mappedById);
                      if (selectedTransitIndex == 0) {
                        let seatWithPassengerTransit1 = [];
                        // Safely access the reservationSeats property
                        seatWithPassengerTransit1 = Object.keys(
                          response.data.data[0]
                        ).reduce((accumulator, currentKey) => {
                          // Assuming you want to do something with the keys here
                          const reservationInfo =
                            response.data.reservations.find(
                              (reservation) =>
                                reservation.reservation_seats_number &&
                                reservation.reservation_seats_number[
                                  currentKey
                                ] > 0
                            );

                          const passenger = reservationInfo
                            ? reservationInfo.passengers?.find(
                                (p) => p.number_seat == currentKey
                              )
                            : null;

                          accumulator[currentKey] = {
                            passengers: passenger
                              ? Object.assign({}, passenger)
                              : null, // safely copy passenger object
                            status:
                              reservationInfo?.reservation_seats_number[
                                currentKey
                              ],
                            id_reservation: reservationInfo?.id,
                          };

                          return accumulator;
                        }, {});
                       
                        const exampleData = {
                          ...seatWithPassengerTransit1, // This is your existing seat data
                          id: TripID.tripTransit[0].id, // Ensure you are actually setting the id like this
                        };
                        setArraySeatPassengerTransit1(exampleData);
                      }
                      if (selectedTransitIndex == 1) {
                        let seatWithPassengerTransit2 = [];
                        // Safely access the reservationSeats property
                        seatWithPassengerTransit2 = Object.keys(
                          response.data.data[0]
                        ).reduce((accumulator, currentKey) => {
                          // Assuming you want to do something with the keys here
                          const reservationInfo =
                            response.data.reservations.find(
                              (reservation) =>
                                reservation.reservation_seats_number &&
                                reservation.reservation_seats_number[
                                  currentKey
                                ] > 0
                            );

                          const passenger = reservationInfo
                            ? reservationInfo.passengers?.find(
                                (p) => p.number_seat == currentKey
                              )
                            : null;

                          accumulator[currentKey] = {
                            passengers: passenger
                              ? Object.assign({}, passenger)
                              : null, // safely copy passenger object
                            status:
                              reservationInfo?.reservation_seats_number[
                                currentKey
                              ],
                            id_reservation: reservationInfo?.id,
                          };

                          return accumulator;
                        }, {});
                   
                        const exampleData = {
                          ...seatWithPassengerTransit2, // This is your existing seat data
                          id: TripID.tripTransit[1].id, // Ensure you are actually setting the id like this
                        };
                        setArraySeatPassengerTransit2(exampleData);
                      }
                      const newDirectSelectedSeats = [];
                      const transitSeatNumbers = new Set();
                      if (TripID.tripTransit && TripID.tripTransit.length > 0) {
                        TripID.tripTransit.forEach((transit) => {
                          Object.keys(transit.reservationSeats).forEach(
                            (seatNumber) => {
                              transitSeatNumbers.add(seatNumber);
                            }
                          );
                        });
                      }
                      Object.keys(response.data.data[1]).forEach(
                        (seatNumber) => {
                          const seatDetails = response.data.data[1][seatNumber];
                          if (
                            seatDetails.numStatus === 5 &&
                            response1[seatNumber] !== undefined &&
                            !transitSeatNumbers.has(seatNumber)
                          ) {
                            newDirectSelectedSeats.push({
                              seat_number: parseInt(seatNumber),
                              id_system: seatDetails.id_system,
                              numStatus: seatDetails.numStatus,
                            });
                          }
                        }
                      );
                      const reservationSeats = response.data.data[0];
                      const seatStatusAuth = response.data.data[1];
                      Object.keys(response.data.data[0]).forEach((seatNumber) => {
                        const status = reservationSeats[seatNumber];
               
                        if (status === 5 && !seatStatusAuth[seatNumber]) {
                          newDirectSelectedSeats.push({
                            seat_number: parseInt(seatNumber),
                            id_system: 1,
                            numStatus: status,
                            id_client: 0,
                            status: "Selected",
                            transitStatus: null,
                            id_transit: idtrip,
                            trip_id: TripID.id,
                          });
                        }
                      });
                      const currentUserReservedSeats =
                        newDirectSelectedSeats.filter(
                          (userSeat) =>
                            userSeat.id_system === user.user_id &&
                            userSeat.numStatus == 5
                        );
                      const currentUserReservedSeatNumbers =
                        currentUserReservedSeats.map(
                          (seat) => seat.seat_number
                        );
                      setSelectedSeats(currentUserReservedSeatNumbers);
                      setSeatAndUser(newDirectSelectedSeats);
                      setSelected(newDirectSelectedSeats);
                      setResultArrayseatuser(newDirectSelectedSeats);
                      const sortedCombinedSeats =
                        currentUserReservedSeatNumbers.sort((a, b) => a - b);
                      setValueinput(sortedCombinedSeats.join("+"));
                      sweetalert({
                        text: "تم الحجز بنجاح   ",
                        icon: "success",
                        buttons: {
                          confirm: {
                            text: "تم",
                            className: "custom-button-class",
                          },
                        },
                        className: "alert-div",
                      }).then(() => {
                        localStorage.setItem("changeseat", false);
                      });
                    }
                  })
                  .catch((error) => {
                    // Handle the error here
                    setResponse1([]);
                    dispatch(fetchTripBYId(TripID.id));
                    setResponse1(TripID.reservation_seats);
                  });
              }
            } else {
              const status = response1[sortedNumbers[0]];

              if (status == 1) {
                sweetalert({
                  text: "لا يمكن حجز الكرسي شركة  لانه مضاف لحجز معين",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (status == undefined || status == '') {
                setResultArray([]);
                sweetalert({
                  text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (status == 2) {
                setResultArray([]);
                sweetalert({
                  text: "لا يمكن حجز الكرسي شركة  لانه مضاف لحجز معين",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else if (status == 3) {
                sweetalert({
                  text: "لا يمكن حجز الكرسي شركة ",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else if (status == 4) {
                setResultArray([]);
                sweetalert({
                  text: "لا يمكن حجز الكرسي شركة ",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else {
                const data = {
                  triptable_id: idDirect,
                  triptable_type: "Domain\\Trip",
                  seat_number: resultArray,
                  status: 4,
                  tripid: TripID.id,
                  branch: TripID.id_branch,
                };
                const values = {
                  data: data,
                  status: "no passenger",
                  branch: TripID.id_branch,
                };

                await axios
                  .post(`${BaseUrl}/changeStatusSeat`, data, {
                    withCredentials: true,
                  })
                  .then((response) => {
                    if (response.data.status === 200) {
                      setResponse1(response.data.data[0]);
                      const mappedById = response.data.reservations.reduce(
                        (acc, item) => {
                          acc[item.id] = item;
                          return acc;
                        },
                        {}
                      );
                      setArrayReservations(mappedById);
                      let passenger;
                      let seatWithPassengerNull = Object.keys(
                        response.data.data[0]
                      ).reduce((accumulator, currentKey) => {
                        const reservationInfo = response.data.reservations.find(
                          (reservation) =>
                            reservation.reservation_seats_number[currentKey] > 0
                        );
                        passenger = reservationInfo
                          ? reservationInfo.passengers?.find(
                              (p) => p.number_seat == currentKey
                            )
                          : null;

                        accumulator[currentKey] = {
                          passengers: Object(passenger),
                          status:
                            reservationInfo?.reservation_seats_number[
                              currentKey
                            ],
                          id_reservation: reservationInfo?.id,
                        };
                        return accumulator;
                      }, {});
                      setArraySeat(seatWithPassengerNull);
                      const newDirectSelectedSeats = [];
                      const transitSeatNumbers = new Set();
                      if (TripID.tripTransit && TripID.tripTransit.length > 0) {
                        TripID.tripTransit.forEach((transit) => {
                          Object.keys(transit.reservationSeats).forEach(
                            (seatNumber) => {
                              transitSeatNumbers.add(seatNumber);
                            }
                          );
                        });
                      }
                      Object.keys(response.data.data[1]).forEach(
                        (seatNumber) => {
                          const seatDetails = response.data.data[1][seatNumber];
                          if (
                            seatDetails.numStatus === 5 &&
                            response1[seatNumber] !== undefined &&
                            !transitSeatNumbers.has(seatNumber)
                          ) {
                            newDirectSelectedSeats.push({
                              seat_number: parseInt(seatNumber),
                              id_system: seatDetails.id_system,
                              numStatus: seatDetails.numStatus,
                            });
                          }
                        }
                      );
                      const reservationSeats = response.data.data[0];
                      const seatStatusAuth = response.data.data[1];
                      Object.keys(response.data.data[0]).forEach((seatNumber) => {
                        const status = reservationSeats[seatNumber];
               
                        if (status === 5 && !seatStatusAuth[seatNumber]) {
                          newDirectSelectedSeats.push({
                            seat_number: parseInt(seatNumber),
                            id_system: 1,
                            numStatus: status,
                            id_client: 0,
                            status: "Selected",
                            transitStatus: null,
                            id_transit: null,
                            trip_id: idtrip,
                          });
                        }
                      });
                      const currentUserReservedSeats =
                        newDirectSelectedSeats.filter(
                          (userSeat) =>
                            userSeat.id_system === user.user_id &&
                            userSeat.numStatus == 5
                        );
                      const currentUserReservedSeatNumbers =
                        currentUserReservedSeats.map(
                          (seat) => seat.seat_number
                        );
                      setSelectedSeats(currentUserReservedSeatNumbers);
                      setSeatAndUser(newDirectSelectedSeats);
                      setSelected(newDirectSelectedSeats);
                      setResultArrayseatuser(newDirectSelectedSeats);
                      const sortedCombinedSeats =
                        currentUserReservedSeatNumbers.sort((a, b) => a - b);
                      setValueinput(sortedCombinedSeats.join("+"));
                      sweetalert({
                        text: "تم الحجز بنجاح   ",
                        icon: "success",
                        buttons: {
                          confirm: {
                            text: "تم",
                            className: "custom-button-class",
                          },
                        },
                        className: "alert-div",
                      }).then(() => {
                        localStorage.setItem("changeseat", false);
                      });
                    }
                  })
                  .catch((error) => {
                    // Handle the error here
                    setResponse1([]);
                    dispatch(fetchTripBYId(TripID.id));
                    setResponse1(TripID.reservation_seats);
                  });
              

                setResultArray([]);
                setInputString("");
                setValueinput("");
                setSelectedSeats([]);
                setSelectedSeats1([]);
                setSelectedSeats2([]);
              }
            }
          }
        }

        if (event.ctrlKey && event.key === "6") {
          if (nofocus) {
            nofocus.blur();
          }

          const parts1 = valueinput.split("+");
          const numbers = parts1
            .map((part) => {
              if (part.includes("-")) {
                const [start, end] = part.split("-").map(Number);
                return [...Array(end - start + 1).keys()].map((i) => i + start);
              } else {
                return Number(part);
              }
            })
            .flat();

          const valueInputArray = Array.isArray(valueinput)
            ? valueinput
            : valueinput.split(/[\s,+]+/);
          const combinedSeats = [
            ...selectedSeats,
            ...selectedSeats1,
            ...selectedSeats2,
            ...valueInputArray,
          ]
            .map((seat) => {
              if (typeof seat === "number") return seat;

              return seat.split(/[\s,+]+/).map(Number);
            })
            .flat();

          const sortedNumbers1 = Array.from(
            new Set(combinedSeats.filter((seat) => !isNaN(seat)))
          );
          const uniqueNumbersWithoutZero = sortedNumbers1.filter(
            (number) => number !== 0
          );
          const sortedNumbers = uniqueNumbersWithoutZero.sort((a, b) => a - b);
          setResultArray(sortedNumbers);
          localStorage.setItem("changeseat", true);
          const seatNumberToSend = sortedNumbers;
          const isTransitTrip =
            TripID.tripTransit &&
            TripID.tripTransit.some((transit) => {
              return transit.reservationSeats.hasOwnProperty(
                seatNumberToSend[0]
              );
            });
          if (isTransitTrip) {
            const status = reservationseat1[seatNumberToSend[0]];

            if (status == 1) {
              sweetalert({
                text: "لا يمكن تحويل الكرسي لإدارة لانه مضاف لحجز معين",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } 
            else if (status == undefined || status == '') {
              setResultArray([]);
              sweetalert({
                text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
            else if (status == 2) {
              sweetalert({
                text: "لا يمكن تحويل الكرسي لإدارة لانه مضاف لحجز معين",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } else if (status == 3 || status == 4) {
              sweetalert({
                text: "لا يمكن القيام بهذه العملية",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } else {
              const data = {
                triptable_id: idTransit,
                triptable_type: "Domain\\TripTransit",
                seat_number: seatNumberToSend,
                status: 3,
                branch: TripID.id_branch,
              };
              const callback = () => {
                dispatch(setResultArray([])); // Dispatch the action to update the state
              };

              await axios
                .post(`${BaseUrl}/changeStatusSeat`, data, {
                  withCredentials: true,
                })
                .then((response) => {
                  if (response.data.status === 200) {
                    setReservationseat1(response.data.data[0]);
                    const mappedById = response.data.reservations.reduce(
                      (acc, item) => {
                        acc[item.id] = item;
                        return acc;
                      },
                      {}
                    );
                    setArrayReservations(mappedById);
                    if (selectedTransitIndex == 0) {
                      let seatWithPassengerTransit1 = [];
                      // Safely access the reservationSeats property
                      seatWithPassengerTransit1 = Object.keys(
                        response.data.data[0]
                      ).reduce((accumulator, currentKey) => {
                        // Assuming you want to do something with the keys here
                        const reservationInfo = response.data.reservations.find(
                          (reservation) =>
                            reservation.reservation_seats_number &&
                            reservation.reservation_seats_number[currentKey] > 0
                        );

                        const passenger = reservationInfo
                          ? reservationInfo.passengers?.find(
                              (p) => p.number_seat == currentKey
                            )
                          : null;

                        accumulator[currentKey] = {
                          passengers: passenger
                            ? Object.assign({}, passenger)
                            : null, // safely copy passenger object
                          status:
                            reservationInfo?.reservation_seats_number[
                              currentKey
                            ],
                          id_reservation: reservationInfo?.id,
                        };

                        return accumulator;
                      }, {});
           
                      const exampleData = {
                        ...seatWithPassengerTransit1, // This is your existing seat data
                        id: TripID.tripTransit[0].id, // Ensure you are actually setting the id like this
                      };
                      setArraySeatPassengerTransit1(exampleData);
                    }
                    if (selectedTransitIndex == 1) {
                      let seatWithPassengerTransit2 = [];
                      // Safely access the reservationSeats property
                      seatWithPassengerTransit2 = Object.keys(
                        response.data.data[0]
                      ).reduce((accumulator, currentKey) => {
                        // Assuming you want to do something with the keys here
                        const reservationInfo = response.data.reservations.find(
                          (reservation) =>
                            reservation.reservation_seats_number &&
                            reservation.reservation_seats_number[currentKey] > 0
                        );

                        const passenger = reservationInfo
                          ? reservationInfo.passengers?.find(
                              (p) => p.number_seat == currentKey
                            )
                          : null;

                        accumulator[currentKey] = {
                          passengers: passenger
                            ? Object.assign({}, passenger)
                            : null, // safely copy passenger object
                          status:
                            reservationInfo?.reservation_seats_number[
                              currentKey
                            ],
                          id_reservation: reservationInfo?.id,
                        };

                        return accumulator;
                      }, {});
                     
                      const exampleData = {
                        ...seatWithPassengerTransit2, // This is your existing seat data
                        id: TripID.tripTransit[1].id, // Ensure you are actually setting the id like this
                      };
                      setArraySeatPassengerTransit2(exampleData);
                    }
                    const newDirectSelectedSeats = [];
                    const transitSeatNumbers = new Set();
                    if (TripID.tripTransit && TripID.tripTransit.length > 0) {
                      TripID.tripTransit.forEach((transit) => {
                        Object.keys(transit.reservationSeats).forEach(
                          (seatNumber) => {
                            transitSeatNumbers.add(seatNumber);
                          }
                        );
                      });
                    }
                    Object.keys(response.data.data[1]).forEach((seatNumber) => {
                      const seatDetails = response.data.data[1][seatNumber];
                      if (
                        seatDetails.numStatus === 5 &&
                        response1[seatNumber] !== undefined &&
                        !transitSeatNumbers.has(seatNumber)
                      ) {
                        newDirectSelectedSeats.push({
                          seat_number: parseInt(seatNumber),
                          id_system: seatDetails.id_system,
                          numStatus: seatDetails.numStatus,
                        });
                      }
                    });
                    const reservationSeats = response.data.data[0];
                    const seatStatusAuth = response.data.data[1];
                    Object.keys(response.data.data[0]).forEach((seatNumber) => {
                      const status = reservationSeats[seatNumber];
             
                      if (status === 5 && !seatStatusAuth[seatNumber]) {
                        newDirectSelectedSeats.push({
                          seat_number: parseInt(seatNumber),
                          id_system: 1,
                          numStatus: status,
                          id_client: 0,
                          status: "Selected",
                          transitStatus: null,
                          id_transit: idtrip,
                            trip_id: TripID.id,
                        });
                      }
                    });
                    const currentUserReservedSeats =
                      newDirectSelectedSeats.filter(
                        (userSeat) =>
                          userSeat.id_system === user.user_id &&
                          userSeat.numStatus == 5
                      );
                    const currentUserReservedSeatNumbers =
                      currentUserReservedSeats.map((seat) => seat.seat_number);
                    setSelectedSeats(currentUserReservedSeatNumbers);
                    setSeatAndUser(newDirectSelectedSeats);
                    setSelected(newDirectSelectedSeats);
                    setResultArrayseatuser(newDirectSelectedSeats);
                    const sortedCombinedSeats =
                      currentUserReservedSeatNumbers.sort((a, b) => a - b);
                    setValueinput(sortedCombinedSeats.join("+"));
                    sweetalert({
                      text: "تم حجز الكراسي من قبل الادارة",
                      icon: "success",
                      buttons: {
                        confirm: {
                          text: "تم",
                          className: "custom-button-class",
                        },
                      },
                      className: "alert-div",
                    }).then(() => {
                      localStorage.setItem("changeseat", false);
                    });
                  }
                })
                .catch((error) => {
                  // Handle the error here
                  setResponse1([]);
                  dispatch(fetchTripBYId(TripID.id));
                  setResponse1(TripID.reservation_seats);
                });
              setInputString("");
              setValueinput("");
              setSelectedSeats1([]);
              setResultArray([]);
              setSelectedSeats([]);
            }
          } else {
            const status = response1[seatNumberToSend[0]];

            if (status == 1) {
              setResultArray([]);
              sweetalert({
                text: "لا يمكن تحويل الكرسي لإدارة لانه مضاف لحجز معين",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } 
            else if (status == undefined || status == '') {
              setResultArray([]);
              sweetalert({
                text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
            else if (status == 2) {
              setResultArray([]);
              sweetalert({
                text: "لا يمكن تحويل الكرسي لإدارة لانه مضاف لحجز معين",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } else if (status == 3 || status == 4) {
              setResultArray([]);
              localStorage.setItem("changeseat", true);
              sweetalert({
                text: " لا يمكن القيام بهذه العملية ",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } else {
              const data = {
                triptable_id: idDirect,
                triptable_type: "Domain\\Trip",
                seat_number: seatNumberToSend,
                status: 3,
                branch: TripID.id_branch,
              };
              const callback = () => {
                dispatch(setResultArray([])); // Dispatch the action to update the state
              };
              await axios
                .post(`${BaseUrl}/changeStatusSeat`, data, {
                  withCredentials: true,
                })
                .then((response) => {
                  if (response.data.status === 200) {
                    setResponse1(response.data.data[0]);
                    const mappedById = response.data.reservations.reduce(
                      (acc, item) => {
                        acc[item.id] = item;
                        return acc;
                      },
                      {}
                    );
                    setArrayReservations(mappedById);
                    let passenger;
                    let seatWithPassengerNull = Object.keys(
                      response.data.data[0]
                    ).reduce((accumulator, currentKey) => {
                      const reservationInfo = response.data.reservations.find(
                        (reservation) =>
                          reservation.reservation_seats_number[currentKey] > 0
                      );
                      passenger = reservationInfo
                        ? reservationInfo.passengers?.find(
                            (p) => p.number_seat == currentKey
                          )
                        : null;

                      accumulator[currentKey] = {
                        passengers: Object(passenger),
                        status:
                          reservationInfo?.reservation_seats_number[currentKey],
                        id_reservation: reservationInfo?.id,
                      };
                      return accumulator;
                    }, {});
                    setArraySeat(seatWithPassengerNull);
                    const newDirectSelectedSeats = [];
                    const transitSeatNumbers = new Set();
                    if (TripID.tripTransit && TripID.tripTransit.length > 0) {
                      TripID.tripTransit.forEach((transit) => {
                        Object.keys(transit.reservationSeats).forEach(
                          (seatNumber) => {
                            transitSeatNumbers.add(seatNumber);
                          }
                        );
                      });
                    }
                    Object.keys(response.data.data[1]).forEach((seatNumber) => {
                      const seatDetails = response.data.data[1][seatNumber];
                      if (
                        seatDetails.numStatus === 5 &&
                        response1[seatNumber] !== undefined &&
                        !transitSeatNumbers.has(seatNumber)
                      ) {
                        newDirectSelectedSeats.push({
                          seat_number: parseInt(seatNumber),
                          id_system: seatDetails.id_system,
                          numStatus: seatDetails.numStatus,
                        });
                      }
                    });
                    const reservationSeats = response.data.data[0];
                    const seatStatusAuth = response.data.data[1];
                    Object.keys(response.data.data[0]).forEach((seatNumber) => {
                      const status = reservationSeats[seatNumber];
             
                      if (status === 5 && !seatStatusAuth[seatNumber]) {
                        newDirectSelectedSeats.push({
                          seat_number: parseInt(seatNumber),
                          id_system: 1,
                          numStatus: status,
                          id_client: 0,
                          status: "Selected",
                          transitStatus: null,
                          id_transit: null,
                          trip_id: idtrip,
                        });
                      }
                    });
                    const currentUserReservedSeats =
                      newDirectSelectedSeats.filter(
                        (userSeat) =>
                          userSeat.id_system === user.user_id &&
                          userSeat.numStatus == 5
                      );
                    const currentUserReservedSeatNumbers =
                      currentUserReservedSeats.map((seat) => seat.seat_number);
                    setSelectedSeats(currentUserReservedSeatNumbers);
                    setSeatAndUser(newDirectSelectedSeats);
                    setSelected(newDirectSelectedSeats);
                    setResultArrayseatuser(newDirectSelectedSeats);
                    const sortedCombinedSeats =
                      currentUserReservedSeatNumbers.sort((a, b) => a - b);
                    setValueinput(sortedCombinedSeats.join("+"));
                    sweetalert({
                      text: "تم حجز الكراسي من قبل الادارة",
                      icon: "success",
                      buttons: {
                        confirm: {
                          text: "تم",
                          className: "custom-button-class",
                        },
                      },
                      className: "alert-div",
                    }).then(() => {
                      localStorage.setItem("changeseat", false);
                    });
                  }
                })
                .catch((error) => {
                  // Handle the error here
                  setResponse1([]);
                  dispatch(fetchTripBYId(TripID.id));
                  setResponse1(TripID.reservation_seats);
                });
              setInputString("");
              setValueinput("");
              setSelectedSeats1([]);
              setResultArray([]);
              setSelectedSeats([]);
            }
          }
        }

        if (event.ctrlKey && event.key === "5") {
          if (nofocus) {
            nofocus.blur();
          }

          const parts1 = valueinput.split("+");
          const numbers = parts1
            .map((part) => {
              if (part.includes("-")) {
                const [start, end] = part.split("-").map(Number);
                return [...Array(end - start + 1).keys()].map((i) => i + start);
              } else {
                return Number(part);
              }
            })
            .flat();

          const valueInputArray = Array.isArray(valueinput)
            ? valueinput
            : valueinput.split(/[\s,+]+/);
          const combinedSeats = [...selectedSeats, ...valueInputArray]
            .map((seat) => {
              if (typeof seat === "number") return seat;

              return seat.split(/[\s,+]+/).map(Number);
            })
            .flat();

          const sortedNumbers1 = Array.from(
            new Set(combinedSeats.filter((seat) => !isNaN(seat)))
          );
          const uniqueNumbersWithoutZero = sortedNumbers1.filter(
            (number) => number !== 0
          );
          const sortedNumbers = uniqueNumbersWithoutZero.sort((a, b) => a - b);
          setResultArray(sortedNumbers);
          const seatNumberToSend = sortedNumbers;
          const isAnySeatCommon = seatNumberToSend.some((seat) =>
            commonSeats.includes(seat)
          );
          if (isAnySeatCommon) {
            setResultArray([]);
            localStorage.setItem("changeseat", true);
            sweetalert({
              text: "لا يمكن القيام بهذه العملية",
              icon: "error",
              buttons: {
                confirm: {
                  text: "تم",
                  className: "custom-button-class",
                },
              },
              className: "alert-div",
            }).then(() => {
              localStorage.setItem("changeseat", false);
            });
          
          } else {
            const status = response1[seatNumberToSend[0]];
            if (status == 1) {
              setResultArray([]);
              localStorage.setItem("changeseat", true);
              sweetalert({
                text: "Ctrl + F3 لا يمكن القيام بهذه العملية يمكنك إضافة بيانات مسافر للكرسي بالضغط  ",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } 
            else if (status == undefined || status == '') {
              setResultArray([]);
              sweetalert({
                text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
            
            else if (status == 2) {
              setResultArray([]);
              localStorage.setItem("changeseat", true);
              sweetalert({
                text: " لا يمكن القيام بهذه العملية ",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } else if (status == 3 || status == 4) {
              setResultArray([]);
              localStorage.setItem("changeseat", true);
              sweetalert({
                text: " لا يمكن القيام بهذه العملية ",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } else {
              const data = {
                id_trip: TripID.id,
                seat_number: seatNumberToSend,
                branch: TripID.id_branch,
                type: "transit",
              };
              const response = await axios.post(`${BaseUrl}/SeatsTrip`, data, {
                withCredentials: true,
              });
              if (response.status === 200) {
                localStorage.setItem("changeseat", true);

                const { data: responseData } = response;
                setArraySeatPassengerTransit1((prevSeatsInfo) => {
                  const updatedSeatsInfo = { ...prevSeatsInfo };

                  seatNumberToSend.forEach((number) => {
                    const seatNumber = parseInt(number);

                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: {}, // Assuming you want to reset passengers info
                        id_reservation: undefined,
                        status: 0, // Resetting the status
                      };
                    }
                  });

                  return updatedSeatsInfo;
                });
                setArraySeatPassengerTransit2((prevSeatsInfo) => {
                  const updatedSeatsInfo = { ...prevSeatsInfo };

                  seatNumberToSend.forEach((number) => {
                    const seatNumber = parseInt(number);

                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: {}, // Assuming you want to reset passengers info
                        id_reservation: undefined,
                        status: 0, // Resetting the status
                      };
                    }
                  });

                  return updatedSeatsInfo;
                });
                // Add 'seat main trip' to setResponse1 array
                if (responseData.hasOwnProperty("seat main trip")) {
                  setResponse1(responseData["seat main trip"]);
                }

                // Add 'seat trip transit' to setReservationseat1 array
                if (responseData.hasOwnProperty("seat trip transit")) {
                  setReservationseat1(responseData["seat trip transit"]);
                }

                sweetalert({
                  text: "تم  إضافة الكراسي إلى رحلات  العبور",
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  dispatch(fetchTripBYId(data.id_trip));
                  localStorage.setItem("changeseat", false);
                });
              }
              setResultArray([]);
              setInputString("");
              setValueinput("");
              setSelectedSeats([]);
            }
          }
        }
        if (event.ctrlKey && event.key === "7") {
          if (nofocus) {
            nofocus.blur();
          }

          const parts1 = valueinput.split("+");
          const numbers = parts1
            .map((part) => {
              if (part.includes("-")) {
                const [start, end] = part.split("-").map(Number);
                return [...Array(end - start + 1).keys()].map((i) => i + start);
              } else {
                return Number(part);
              }
            })
            .flat();

      
          const valueInputArray = Array.isArray(valueinput)
            ? valueinput
            : valueinput.split(/[\s,+]+/);
          const combinedSeats = [
            ...selectedSeats,
            ...selectedSeats1,
            ...selectedSeats2,
            ...valueInputArray,
          ]
            .map((seat) => {
              if (typeof seat === "number") return seat;

              return seat.split(/[\s,+]+/).map(Number);
            })
            .flat();

          const sortedNumbers1 = Array.from(
            new Set(combinedSeats.filter((seat) => !isNaN(seat)))
          );
          const uniqueNumbersWithoutZero = sortedNumbers1.filter(
            (number) => number !== 0
          );
          const sortedNumbers = uniqueNumbersWithoutZero.sort((a, b) => a - b);
          setResultArray(sortedNumbers);
    
          const seatNumberToSend = sortedNumbers;
          
          const isAnySeatCommon = seatNumberToSend.some((seat) =>
            commonSeats.includes(seat)
          );

          if (isAnySeatCommon) {
            const status = reservationseat1[seatNumberToSend[0]];
            if (status == 1 || status == 2 || status == 3 || status == 4) {
              sweetalert({
                text: "لا يمكن القيام بهذه العملية",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } 
            else if (status == undefined || status == '') {
              setResultArray([]);
              sweetalert({
                text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
            else {
              const data = {
                id_trip: TripID.id,
                branch: TripID.id_branch,
                seat_number: seatNumberToSend,
                type: "",
              };


              const response = await axios.post(`${BaseUrl}/SeatsTrip`, data, {
                withCredentials: true,
              });
              if (response.status === 200) {
                localStorage.setItem("changeseat", true);
                const { data: responseData } = response;

                setArraySeatPassengerTransit1((prevReservations) => {
                  const updatedReservations = { ...prevReservations };
                  // Loop through each seat number and delete it from the reservations
                  seatNumberToSend.forEach((seatNumber) => {
                    delete updatedReservations[seatNumber];
                  });
                  return updatedReservations;
                });
                setArraySeatPassengerTransit2((prevReservations) => {
                  const updatedReservations = { ...prevReservations };
                  // Loop through each seat number and delete it from the reservations
                  seatNumberToSend.forEach((seatNumber) => {
                    delete updatedReservations[seatNumber];
                  });
                  return updatedReservations;
                });
                const seatStatusData = responseData["seat main trip"];

                // Extract seat numbers with status 6
                const seatsWithStatusSix = Object.keys(seatStatusData).filter(
                  (seatNumber) => seatStatusData[seatNumber] === 6
                );

                // Convert to integers, since keys are strings
                const seatNumbersToInt = seatsWithStatusSix.map(Number);

                // Update selectedSeats2 state to include these seat numbers
                setSeatsArray2(seatNumbersToInt);
                setCommonSeats(
                  seatsArray1.filter((seat) => seatNumbersToInt.includes(seat))
                );
                // Add 'seat main trip' to setResponse1 array
                if (responseData.hasOwnProperty("seat main trip")) {
                  setResponse1(responseData["seat main trip"]);
                }

                // Add 'seat trip transit' to setReservationseat1 array
                if (responseData.hasOwnProperty("seat trip transit")) {
                  setReservationseat1(responseData["seat trip transit"]);
                }
                sweetalert({
                  text: "تم إضافة الكراسي إلى الرحلة الاساسية",
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  dispatch(fetchTripBYId(data.id_trip));

                  localStorage.setItem("changeseat", false);
                });
              }

              setResultArray([]);
              setInputString("");
              setValueinput("");
              setSelectedSeats([]);
              setSelectedSeats1([]);
              setSelectedSeats2([]);
              setSeatAndUser([]);
            }
          } else {
            const status = response1[seatNumberToSend[0]];
            if (status == 5 || status == 0) {
              sweetalert({
                text: "لا يمكن تحول الكرسي لأساسي لإنه كرسي رحلة أساسية فعلاً",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
            else if (status == undefined || status == '') {
              setResultArray([]);
              sweetalert({
                text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
            else if (status == 1) {
              setResultArray([]);
              sweetalert({
                text: "لا يمكن تحويل لاساسي  لانه مضاف لحجز معين",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } else if (status == 2) {
              setResultArray([]);
              localStorage.setItem("changeseat", true);
              sweetalert({
                text: " لا يمكن القيام بهذه العملية ",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } else if (status == 3 || status == 4) {
              setResultArray([]);
              localStorage.setItem("changeseat", true);
              sweetalert({
                text: "  لا يمكن القيام بهذه العملية لان الكراسي تابعين للرحلة الأساسية ",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } else {
              const data = {
                id_trip: idDirect,
                seat_number: seatNumberToSend,
                branch: TripID.id_branch,
                type: "transit",
              };
              const response = await axios.post(`${BaseUrl}/SeatsTrip`, data, {
                withCredentials: true,
              });
              if (response.status === 200) {
                localStorage.setItem("changeseat", true);
                const { data: responseData } = response;

                setArraySeatPassengerTransit1((prevReservations) => {
                  const updatedReservations = { ...prevReservations };
                  // Loop through each seat number and delete it from the reservations
                  seatNumberToSend.forEach((seatNumber) => {
                    delete updatedReservations[seatNumber];
                  });
                  return updatedReservations;
                });
                setArraySeatPassengerTransit2((prevReservations) => {
                  const updatedReservations = { ...prevReservations };
                  // Loop through each seat number and delete it from the reservations
                  seatNumberToSend.forEach((seatNumber) => {
                    delete updatedReservations[seatNumber];
                  });
                  return updatedReservations;
                });
                // Add 'seat main trip' to setResponse1 array
                if (responseData.hasOwnProperty("seat main trip")) {
                  setResponse1(responseData["seat main trip"]);
                }

                // Add 'seat trip transit' to setReservationseat1 array
                if (responseData.hasOwnProperty("seat trip transit")) {
                  setReservationseat1(responseData["seat trip transit"]);
                }
                sweetalert({
                  text: "تم إضافة الكراسي إلى الرحلة الاساسية",
                  icon: "success",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  dispatch(fetchTripBYId(data.id_trip));

                  localStorage.setItem("changeseat", false);
                });
              }

              setResultArray([]);
              setInputString("");
              setValueinput("");
              setSelectedSeats([]);
              setSelectedSeats1([]);
              setSelectedSeats2([]);
            }
          }
        }
        if (event.ctrlKey && event.key === "2") {
          if (nofocus) {
            nofocus.blur();
          }
          setTitle("تعديل مسافر");
          setReservation("تعديل حجز");
          const parts1 = valueinput.split("+");

          const numbers = parts1
            .map((part) => {
              if (part.includes("-")) {
                const [start, end] = part.split("-").map(Number);
                return [...Array(end - start + 1).keys()].map((i) => i + start);
              } else {
                return Number(part);
              }
            })
            .flat();
      
          const valueInputArray = Array.isArray(valueinput)
            ? valueinput
            : valueinput.split(/[\s,+]+/);
          const combinedSeats = [
            ...selectedSeats,
            ...selectedSeats1,
            ...selectedSeats2,
            ...valueInputArray,
          ]
            .map((seat) => {
              if (typeof seat === "number") return seat;

              return seat.split(/[\s,+]+/).map(Number);
            })
            .flat();

          const sortedNumbers1 = Array.from(
            new Set(combinedSeats.filter((seat) => !isNaN(seat)))
          );
          const uniqueNumbersWithoutZero = sortedNumbers1.filter(
            (number) => number !== 0
          );
          const sortedNumbers = uniqueNumbersWithoutZero.sort((a, b) => a - b);
          setResultArray(sortedNumbers);

          const reservationIds1 = [];
          const seatNumbersToSend = sortedNumbers;

          seatNumbersToSend.forEach((seat) => {
            if (idDirect == TripID.id) {
              if (
                Array.isArray(TripID.tripTransit) &&
                TripID.tripTransit.length > 0
              ) {
                const seatNumbersToSend = uniqueNumbersWithoutZero;
                let reservationseat = null;

                // Convert the object keys into an array and then use find
                const reservationSeatKeys = Object.keys(
                  TripID.tripTransit[0].reservationSeats
                );
                reservationseat = reservationSeatKeys.find((seat) =>
                  seatNumbersToSend.includes(Number(seat))
                );

                if (reservationseat == seatNumbersToSend) {
                  localStorage.setItem("changeseat", true);
                  sweetalert({
                    text: "لا يمكن تعديل كرسي رحلة العبور من الرحلة الاساسية للقيام بذلك انتقل إلى رحلة العبور وقم بتعديل البيانات الكرسي الخاص يرحلة العبور",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                } else {
                  // Seat not found in transit trip
                  const status = response1[seatNumbersToSend[0]];
                  if (
                    status == 0 ||
                    status == 3 ||
                    status == 4 ||
                    status == 5
                  ) {
                    setResultArray([]);
                    localStorage.setItem("changeseat", true);
                    sweetalert({
                      text: "Ctrl + 1 لا يمكن تعديل بيانات كرسي لا يحتوي على حجز يمكن إضافة حجز بالضغط ",
                      icon: "error",
                      buttons: {
                        confirm: {
                          text: "تم",
                          className: "custom-button-class",
                        },
                      },
                      className: "alert-div",
                    }).then(() => {
                      localStorage.setItem("changeseat", false);
                    });
                  } 
                  else if (status == undefined || status == '') {
                    setResultArray([]);
                    sweetalert({
                      text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                      icon: "error",
                      buttons: {
                        confirm: {
                          text: "إغلاق",
                          className: "custom-button-class",
                        },
                      },
                      className: "alert-div",
                    }).then(() => {
                      localStorage.setItem("changeseat", false);
                    });
                  }
                  else if (status == 1) {
                    setResultArray([]);
                    localStorage.setItem("changeseat", true);
                    sweetalert({
                      text: "Ctrl + F3 لا يمكن القيام بهذه العملية يمكنك إضافة بيانات مسافر للكرسي بالضغط  ",
                      icon: "error",
                      buttons: {
                        confirm: {
                          text: "تم",
                          className: "custom-button-class",
                        },
                      },
                      className: "alert-div",
                    }).then(() => {
                      localStorage.setItem("changeseat", false);
                    });
                  } else {
                    setOpenPopuppassenger(true);
                    localStorage.setItem("openpassenger", true);
                    const reservationDirect = Object.values(
                      arrayReservations
                    )?.find((reservation) => {
                      const seatNumbers = Object.keys(
                        reservation.reservation_seats_number
                      ).map(Number);
                      return seatNumbers.includes(seat);
                    });
                    settranset(false);
                    setDirect(true);
                    const passengerData = [];

                    // Loop through selectedSeats and find matching passengers
                    seatNumbersToSend.forEach((seat) => {
                      const matchingPassenger =
                        reservationDirect.passengers.find((passenger) => {
                          return passenger.number_seat == seat;
                        });

                      if (matchingPassenger) {
                        passengerData.push({
                          id:
                            matchingPassenger.id_passenger ||
                            matchingPassenger.id,
                          first_name: matchingPassenger.first_name,
                          number_seat: matchingPassenger.number_seat,
                          middle_name: matchingPassenger.middle_name,
                          last_name: matchingPassenger.last_name,
                          mother_name: matchingPassenger.mother_name,
                          national_id: matchingPassenger.national_id,
                          gender: matchingPassenger.gender,
                          birth_date: matchingPassenger.birth_date,
                          birth_place: matchingPassenger.birth_place,
                          civil_status: matchingPassenger.civil_status,
                          id_price_ticket: matchingPassenger.id_price_ticket,
                          price: matchingPassenger.price,
                          title: matchingPassenger.title,
                          // Add other passenger details as needed
                        });

                        reservationIds1.push({
                          reservationId1: reservationDirect.id,
                        });
                        setUpdatePassenger(true);
                      }
                    });

                    if (passengerData.length > 0) {
                      const existingPassengerData =
                        JSON.parse(localStorage.getItem("passengers")) || [];
                      const updatedPassengerData = [
                        ...existingPassengerData,
                        ...passengerData,
                      ];

                      localStorage.setItem(
                        "passengers",
                        JSON.stringify(updatedPassengerData)
                      );
                    }
                    setInputString("");
                    setValueinput("");
                    setSelectedSeats1([]);
                    setSelectedSeats([]);
                    setSelectedSeats2([]);
                    setSeatAndUser([]);
                  }
                }
              } else if (
                Array.isArray(TripID.tripTransit) &&
                TripID.tripTransit?.length == 0
              ) {
                let changeseat = false;
                const seatStatus = {};
                const status = response1[seatNumbersToSend[0]];


                if (
                  status === 0 ||
                  status === 3 ||
                  status === 4 ||
                  status == 5
                ) {
                  localStorage.setItem("changeseat", true);
                  setResultArray([]);
                  sweetalert({
                    text: "Ctrl + 1 لا يمكن تعديل بيانات كرسي لا يحتوي على حجز يمكن إضافة حجز بالضغط ",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                }
                else if (status == undefined || status == '') {
                  setResultArray([]);
                  sweetalert({
                    text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "إغلاق",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                }
                else if (status == 1) {
                  setResultArray([]);
                  localStorage.setItem("changeseat", true);
                  sweetalert({
                    text: "Ctrl + F3 لا يمكن القيام بهذه العملية يمكنك إضافة بيانات مسافر للكرسي بالضغط  ",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                } else {
                  setOpenPopuppassenger(true);
                  localStorage.setItem("openpassenger", true);

                  const reservationDirect = Object.values(
                    arrayReservations
                  ).find((reservation) => {
                    const seatNumbers = Object.keys(
                      reservation.reservation_seats_number
                    ).map(Number);
                    return seatNumbers.includes(seat);
                  });
                  settranset(false);
                  setDirect(true);
                  const passengerData = [];

                  // Loop through selectedSeats and find matching passengers
                  seatNumbersToSend.forEach((seat) => {
                    const matchingPassenger = reservationDirect.passengers.find(
                      (passenger) => {
                        return passenger.number_seat == seat;
                      }
                    );

                    if (matchingPassenger) {
                      passengerData.push({
                        id:
                          matchingPassenger.id_passenger ||
                          matchingPassenger.id,
                        first_name: matchingPassenger.first_name,
                        number_seat: matchingPassenger.number_seat,
                        middle_name: matchingPassenger.middle_name,
                        last_name: matchingPassenger.last_name,
                        mother_name: matchingPassenger.mother_name,
                        national_id: matchingPassenger.national_id,
                        gender: matchingPassenger.gender,
                        birth_date: matchingPassenger.birth_date,
                        birth_place: matchingPassenger.birth_place,
                        civil_status: matchingPassenger.civil_status,
                        id_price_ticket: matchingPassenger.id_price_ticket,
                        price: matchingPassenger.price,
                        title: matchingPassenger.title,
                        // Add other passenger details as needed
                      });

                      reservationIds1.push({
                        reservationId1: reservationDirect.id,
                      });
                      setUpdatePassenger(true);
                    }
                  });

                  if (passengerData.length > 0) {
                    const existingPassengerData =
                      JSON.parse(localStorage.getItem("passengers")) || [];
                    const updatedPassengerData = [
                      ...existingPassengerData,
                      ...passengerData,
                    ];

                    localStorage.setItem(
                      "passengers",
                      JSON.stringify(updatedPassengerData)
                    );
                  }
                  setInputString("");
                  setValueinput("");
                  setSelectedSeats1([]);
                  setSelectedSeats([]);
                  setSelectedSeats2([]);
                  setSeatAndUser([]);
                }
              } else {
                setOpenPopuppassenger(true);
                localStorage.setItem("openpassenger", true);

                const reservationDirect = Object.values(arrayReservations).find(
                  (reservation) => {
                    const seatNumbers = Object.keys(
                      reservation.reservation_seats_number
                    ).map(Number);
                    return seatNumbers.includes(seat);
                  }
                );
                settranset(false);
                setDirect(true);
                const passengerData = [];

                // Loop through selectedSeats and find matching passengers
                seatNumbersToSend.forEach((seat) => {
                  const matchingPassenger = reservationDirect.passengers.find(
                    (passenger) => {
                      return passenger.number_seat == seat;
                    }
                  );

                  if (matchingPassenger) {
                    passengerData.push({
                      id:
                        matchingPassenger.id_passenger || matchingPassenger.id,
                      first_name: matchingPassenger.first_name,
                      number_seat: matchingPassenger.number_seat,
                      middle_name: matchingPassenger.middle_name,
                      last_name: matchingPassenger.last_name,
                      mother_name: matchingPassenger.mother_name,
                      national_id: matchingPassenger.national_id,
                      gender: matchingPassenger.gender,
                      birth_date: matchingPassenger.birth_date,
                      birth_place: matchingPassenger.birth_place,
                      civil_status: matchingPassenger.civil_status,
                      id_price_ticket: matchingPassenger.id_price_ticket,
                      price: matchingPassenger.price,
                      title: matchingPassenger.title,
                      // Add other passenger details as needed
                    });

                    reservationIds1.push({
                      reservationId1: reservationDirect.id,
                    });
                    setUpdatePassenger(true);
                  }
                });

                if (passengerData.length > 0) {
                  const existingPassengerData =
                    JSON.parse(localStorage.getItem("passengers")) || [];
                  const updatedPassengerData = [
                    ...existingPassengerData,
                    ...passengerData,
                  ];

                  localStorage.setItem(
                    "passengers",
                    JSON.stringify(updatedPassengerData)
                  );
                }
                setInputString("");
                setValueinput("");
                setSelectedSeats1([]);
                setSelectedSeats([]);
                setSelectedSeats2([]);
                setSeatAndUser([]);
              }
            } else if (idDirect != TripID.id) {
              let reservationseat = null;
              const reservationSeatKeys = Object.keys(
                TripID.tripTransit[selectedTransitIndex].reservationSeats
              );
              reservationseat = reservationSeatKeys.find((seat) =>
                seatNumbersToSend.includes(Number(seat))
              );
              settranset(true);
              setDirect(false);
              const seatStatus = {};

              // Iterate through selectedSeats and get the status for each seat
              seatNumbersToSend.forEach((seat) => {
                const status =
                  TripID.tripTransit[selectedTransitIndex].reservationSeats[
                    seat
                  ];
                seatStatus[seat] = status;
              });

              let changeseat = false;

              const status = reservationseat1[seatNumbersToSend[0]];
              if (status == 0 || status == 3 || status == 5 || status == 4) {
                localStorage.setItem("changeseat", true);
                sweetalert({
                  text: "Ctrl + 1 لا يمكن تعديل بيانات كرسي لا يحتوي على حجز يمكن إضافة حجز بالضغط ",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } 
              else if (status == undefined || status == '') {
                setResultArray([]);
                sweetalert({
                  text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (status == 1) {
                setResultArray([]);
                localStorage.setItem("changeseat", true);
                sweetalert({
                  text: "Ctrl + F3 لا يمكن القيام بهذه العملية يمكنك إضافة بيانات مسافر للكرسي بالضغط  ",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else {
                setOpenPopuppassenger(true);
                localStorage.setItem("openpassenger", true);
                const reservationIdTransit = Array.isArray(TripID.tripTransit)
                  ? Object.values(arrayReservations)?.reduce(
                      (acc, reservation) => {
                        const seatNumbers = Object.keys(
                          reservation.reservation_seats_number
                        ).map(Number);
                        if (seatNumbers.includes(seatNumbersToSend[0])) {
                          acc.push({
                            reservationId: reservation.id,
                            passengers: reservation.passengers,
                            trip_id:
                              reservation.trip_id || reservation.triptable_id,
                          });
                        }
                        return acc;
                      },
                      []
                    )
                  : null;
                const relevantReservations = Object.values(
                  reservationIdTransit
                ).filter(
                  (reservation) => reservation.trip_id.toString() === idtransit
                );

                setIdReservationResponse(relevantReservations);

                if (reservationIdTransit) {
                  const passengerData = [];

                  seatNumbersToSend.forEach((seat) => {
                    if (Array.isArray(reservationIdTransit)) {
                      const passengersForSeat = [];

                      reservationIdTransit.forEach((transit) => {
                        const matchingPassenger = transit.passengers.find(
                          (passenger) => {
                            return passenger.number_seat == seat;
                          }
                        );

                        if (matchingPassenger) {
                          passengersForSeat.push({
                            id:
                              matchingPassenger.id_passenger ||
                              matchingPassenger.id,
                            first_name: matchingPassenger.first_name,
                            number_seat: matchingPassenger.number_seat,
                            middle_name: matchingPassenger.middle_name,
                            last_name: matchingPassenger.last_name,
                            mother_name: matchingPassenger.mother_name,
                            national_id: matchingPassenger.national_id,
                            gender: matchingPassenger.gender,
                            birth_date: matchingPassenger.birth_date,
                            birth_place: matchingPassenger.birth_place,
                            civil_status: matchingPassenger.civil_status,
                            id_price_ticket: matchingPassenger.id_price_ticket,
                            price: matchingPassenger.price,
                            title: matchingPassenger.title,
                            // Add other passenger details as needed
                          });

                          reservationIds1.push({
                            reservationId1: reservationIdTransit,
                          });
                          setUpdatePassenger(true);
                        }
                      });

                      // Push the list of passengers for this seat into passengerData
                      passengerData.push(passengersForSeat);
                    }
                  });

                  if (passengerData.length > 0) {
                    // Flatten the passengerData array to merge all passenger lists
                    const flattenedPassengerData = passengerData.flat();

                    const existingPassengerData =
                      JSON.parse(localStorage.getItem("passengers")) || [];
                    const updatedPassengerData = [
                      ...existingPassengerData,
                      ...flattenedPassengerData,
                    ];

                    localStorage.setItem(
                      "passengers",
                      JSON.stringify(updatedPassengerData)
                    );
                  }
                }
              }
            }
          });
       
          setReservationIds(reservationIds1);
        }
        if (event.ctrlKey && event.key === "0") {
          const sortedNumbers = selectedSeats1;

          localStorage.setItem("changeseat", true);
     
          const valueInputArray = Array.isArray(valueinput)
            ? valueinput
            : valueinput.split(/[\s,+]+/);
          const combinedSeats = [
            ...selectedSeats,
            ...selectedSeats1,
            ...selectedSeats2,
            ...valueInputArray,
          ]
            .map((seat) => {
              if (typeof seat === "number") return seat;

              return seat.split(/[\s,+]+/).map(Number);
            })
            .flat();

          const sortedNumbers1 = Array.from(
            new Set(combinedSeats.filter((seat) => !isNaN(seat)))
          );
          const uniqueNumbersWithoutZero = sortedNumbers1.filter(
            (number) => number !== 0
          );
          const seatNumberToSend = uniqueNumbersWithoutZero.sort(
            (a, b) => a - b
          );
          setResultArray(seatNumberToSend);
          const isTransitTrip =
            TripID.tripTransit &&
            TripID.tripTransit.some((transit) => {
              return transit.reservationSeats.hasOwnProperty(
                seatNumberToSend[0]
              );
            });
          if (isTransitTrip) {
            const status = reservationseat1[seatNumberToSend[0]];
            if (status == 5 || status == 0) {
              sweetalert({
                text: "لا يمكن تحول الكرسي لمتوفر لإنه متوفر فعلاً",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
 
            } 
            else if (status == undefined || status == '') {
              setResultArray([]);
              sweetalert({
                text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
            
            else if (status == 1) {
              setResultArray([]);
              sweetalert({
                text: "لا يمكن تحويل الكرسي لمتوفر لانه مضاف لحجز معين",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } else if (status == 2) {
              setResultArray([]);
              sweetalert({
                text: "لا يمكن تحويل الكرسي لمتوفر ",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } else {
              const data = {
                triptable_id: idTransit,
                triptable_type: "Domain\\TripTransit",
                seat_number: seatNumberToSend,
                status: 0,
                branch: TripID.id_branch,
                tripid: TripID.id,
              };
     

              await axios
                .post(`${BaseUrl}/changeStatusSeat`, data, {
                  withCredentials: true,
                })
                .then((response) => {
                  if (response.data.status === 200) {
                    setReservationseat1(response.data.data[0]);
                    const mappedById = response.data.reservations.reduce(
                      (acc, item) => {
                        acc[item.id] = item;
                        return acc;
                      },
                      {}
                    );
                    setArrayReservations(mappedById);
                    if (selectedTransitIndex == 0) {
                      let seatWithPassengerTransit1 = [];
                      // Safely access the reservationSeats property
                      seatWithPassengerTransit1 = Object.keys(
                        response.data.data[0]
                      ).reduce((accumulator, currentKey) => {
                        // Assuming you want to do something with the keys here
                        const reservationInfo = response.data.reservations.find(
                          (reservation) =>
                            reservation.reservation_seats_number &&
                            reservation.reservation_seats_number[currentKey] > 0
                        );

                        const passenger = reservationInfo
                          ? reservationInfo.passengers?.find(
                              (p) => p.number_seat == currentKey
                            )
                          : null;

                        accumulator[currentKey] = {
                          passengers: passenger
                            ? Object.assign({}, passenger)
                            : null, // safely copy passenger object
                          status:
                            reservationInfo?.reservation_seats_number[
                              currentKey
                            ],
                          id_reservation: reservationInfo?.id,
                        };

                        return accumulator;
                      }, {});
                      const exampleData = {
                        ...seatWithPassengerTransit1, // This is your existing seat data
                        id: TripID.tripTransit[0].id, // Ensure you are actually setting the id like this
                      };
                      setArraySeatPassengerTransit1(exampleData);
                    }
                    if (selectedTransitIndex == 1) {
                      let seatWithPassengerTransit2 = [];
                      // Safely access the reservationSeats property
                      seatWithPassengerTransit2 = Object.keys(
                        response.data.data[0]
                      ).reduce((accumulator, currentKey) => {
                        // Assuming you want to do something with the keys here
                        const reservationInfo = response.data.reservations.find(
                          (reservation) =>
                            reservation.reservation_seats_number &&
                            reservation.reservation_seats_number[currentKey] > 0
                        );

                        const passenger = reservationInfo
                          ? reservationInfo.passengers?.find(
                              (p) => p.number_seat == currentKey
                            )
                          : null;

                        accumulator[currentKey] = {
                          passengers: passenger
                            ? Object.assign({}, passenger)
                            : null, // safely copy passenger object
                          status:
                            reservationInfo?.reservation_seats_number[
                              currentKey
                            ],
                          id_reservation: reservationInfo?.id,
                        };

                        return accumulator;
                      }, {});
                      const exampleData = {
                        ...seatWithPassengerTransit2, // This is your existing seat data
                        id: TripID.tripTransit[1].id, // Ensure you are actually setting the id like this
                      };
                      setArraySeatPassengerTransit2(exampleData);
                    }
                    const newDirectSelectedSeats = [];
                    const transitSeatNumbers = new Set();
                    if (TripID.tripTransit && TripID.tripTransit.length > 0) {
                      TripID.tripTransit.forEach((transit) => {
                        Object.keys(transit.reservationSeats).forEach(
                          (seatNumber) => {
                            transitSeatNumbers.add(seatNumber);
                          }
                        );
                      });
                    }
                    Object.keys(response.data.data[1]).forEach((seatNumber) => {
                      const seatDetails = response.data.data[1][seatNumber];
                      if (
                        seatDetails.numStatus === 5 &&
                        response1[seatNumber] !== undefined &&
                        !transitSeatNumbers.has(seatNumber)
                      ) {
                        newDirectSelectedSeats.push({
                          seat_number: parseInt(seatNumber),
                          id_system: seatDetails.id_system,
                          numStatus: seatDetails.numStatus,
                        });
                      }
                    });
                    const reservationSeats = response.data.data[0];
                    const seatStatusAuth = response.data.data[1];
                    Object.keys(response.data.data[0]).forEach((seatNumber) => {
                      const status = reservationSeats[seatNumber];
             
                      if (status === 5 && !seatStatusAuth[seatNumber]) {
                        newDirectSelectedSeats.push({
                          seat_number: parseInt(seatNumber),
                          id_system: 1,
                          numStatus: status,
                          id_client: 0,
                          status: "Selected",
                          transitStatus: null,
                          id_transit: idtrip,
                          trip_id: TripID.id,
                        });
                      }
                    });
                    const currentUserReservedSeats =
                      newDirectSelectedSeats.filter(
                        (userSeat) =>
                          userSeat.id_system === user.user_id &&
                          userSeat.numStatus == 5
                      );
                    const currentUserReservedSeatNumbers =
                      currentUserReservedSeats.map((seat) => seat.seat_number);
                    setSelectedSeats(currentUserReservedSeatNumbers);
                    setSeatAndUser(newDirectSelectedSeats);
                    setSelected(newDirectSelectedSeats);
                    setResultArrayseatuser(newDirectSelectedSeats);
                    const sortedCombinedSeats =
                      currentUserReservedSeatNumbers.sort((a, b) => a - b);
                    setValueinput(sortedCombinedSeats.join("+"));
                    sweetalert({
                      text: "تم تحويل لمتوفر بنجاح",
                      icon: "success",
                      buttons: {
                        confirm: {
                          text: "تم",
                          className: "custom-button-class",
                        },
                      },
                      className: "alert-div",
                    }).then(() => {
                      localStorage.setItem("changeseat", false);
                    });
                  }
                })
                .catch((error) => {
                  // Handle the error here
                  setResponse1([]);
                  dispatch(fetchTripBYId(TripID.id));
                  setResponse1(TripID.reservation_seats);
                });
              localStorage.setItem("changeseat", false);
              setInputString("");
              setValueinput("");
              setSelectedSeats1([]);
              setResultArray([]);
              setSelectedSeats([]);
            }
          } else if (!isTransitTrip) {
            const status = response1[seatNumberToSend[0]];
            if (status == 5 || status == 0) {
              sweetalert({
                text: "لا يمكن تحول الكرسي لمتوفر لإنه متوفر فعلاً",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
        
            }
            else if (status == undefined || status == '') {
              setResultArray([]);
              sweetalert({
                text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
            else if (status == 1) {
              setResultArray([]);
              sweetalert({
                text: "لا يمكن تحويل الكرسي لمتوفر لانه مضاف لحجز معين",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } else if (status == 2) {
              setResultArray([]);
              sweetalert({
                text: "لا يمكن تحويل الكرسي لمتوفر ",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            } else {
              const data = {
                triptable_id: idDirect,
                triptable_type: "Domain\\Trip",
                seat_number: seatNumberToSend,
                status: 0,
                branch: TripID.id_branch,
                tripid: TripID.id,
              };
     
              const callback = () => {
                dispatch(setResultArray([])); // Dispatch the action to update the state
              };
              await axios
                .post(`${BaseUrl}/changeStatusSeat`, data, {
                  withCredentials: true,
                })
                .then((response) => {
                  if (response.data.status === 200) {
                    setResponse1(response.data.data[0]);
                    const mappedById = response.data.reservations.reduce(
                      (acc, item) => {
                        acc[item.id] = item;
                        return acc;
                      },
                      {}
                    );
                    setArrayReservations(mappedById);
                    let passenger;
                    let seatWithPassengerNull = Object.keys(
                      response.data.data[0]
                    ).reduce((accumulator, currentKey) => {
                      const reservationInfo = response.data.reservations.find(
                        (reservation) =>
                          reservation.reservation_seats_number[currentKey] > 0
                      );
                      passenger = reservationInfo
                        ? reservationInfo.passengers?.find(
                            (p) => p.number_seat == currentKey
                          )
                        : null;

                      accumulator[currentKey] = {
                        passengers: Object(passenger),
                        status:
                          reservationInfo?.reservation_seats_number[currentKey],
                        id_reservation: reservationInfo?.id,
                      };
                      return accumulator;
                    }, {});
                    setArraySeat(seatWithPassengerNull);
                    const newDirectSelectedSeats = [];
                    const transitSeatNumbers = new Set();
                    if (TripID.tripTransit && TripID.tripTransit.length > 0) {
                      TripID.tripTransit.forEach((transit) => {
                        Object.keys(transit.reservationSeats).forEach(
                          (seatNumber) => {
                            transitSeatNumbers.add(seatNumber);
                          }
                        );
                      });
                    }
                    Object.keys(response.data.data[1]).forEach((seatNumber) => {
                      const seatDetails = response.data.data[1][seatNumber];
                      if (
                        seatDetails.numStatus === 5 &&
                        response1[seatNumber] !== undefined &&
                        !transitSeatNumbers.has(seatNumber)
                      ) {
                        newDirectSelectedSeats.push({
                          seat_number: parseInt(seatNumber),
                          id_system: seatDetails.id_system,
                          numStatus: seatDetails.numStatus,
                        });
                      }
                    });
                    const reservationSeats = response.data.data[0];
                    const seatStatusAuth = response.data.data[1];
                    Object.keys(response.data.data[0]).forEach((seatNumber) => {
                      const status = reservationSeats[seatNumber];
             
                      if (status === 5 && !seatStatusAuth[seatNumber]) {
                        newDirectSelectedSeats.push({
                          seat_number: parseInt(seatNumber),
                          id_system: 1,
                          numStatus: status,
                          id_client: 0,
                          status: "Selected",
                          transitStatus: null,
                          id_transit: null,
                          trip_id: idtrip,
                        });
                      }
                    });
                    const currentUserReservedSeats =
                      newDirectSelectedSeats.filter(
                        (userSeat) =>
                          userSeat.id_system === user.user_id &&
                          userSeat.numStatus == 5
                      );
                    const currentUserReservedSeatNumbers =
                      currentUserReservedSeats.map((seat) => seat.seat_number);
                    setSelectedSeats(currentUserReservedSeatNumbers);
                    setSeatAndUser(newDirectSelectedSeats);
                    setSelected(newDirectSelectedSeats);
                    setResultArrayseatuser(newDirectSelectedSeats);
                    const sortedCombinedSeats =
                      currentUserReservedSeatNumbers.sort((a, b) => a - b);
                    setValueinput(sortedCombinedSeats.join("+"));
                    sweetalert({
                      text: "تم تحويل لمتوفر بنجاح",
                      icon: "success",
                      buttons: {
                        confirm: {
                          text: "تم",
                          className: "custom-button-class",
                        },
                      },
                      className: "alert-div",
                    }).then(() => {
                      localStorage.setItem("changeseat", false);
                    });
                    setInputString("");
                    setValueinput("");
                    setSelectedSeats1([]);
                    setSelectedSeats2([]);
                    setResultArray([]);
                    setSelectedSeats([]);
                  }
                })
                .catch((error) => {
                  // Handle the error here
                  setResponse1([]);
                  dispatch(fetchTripBYId(TripID.id));
                  setResponse1(TripID.reservation_seats);
                });
            }
          }
        }
        if (event.ctrlKey && event.key === "F3") {
          if (nofocus) {
            nofocus.blur();
          }
          setTitle("إضافة بيانات مسافر لحجز مسبق");
          setReservation("إضافة بيانات");
          const parts1 = valueinput.split("+");

          const numbers = parts1
            .map((part) => {
              if (part.includes("-")) {
                const [start, end] = part.split("-").map(Number);
                return [...Array(end - start + 1).keys()].map((i) => i + start);
              } else {
                return Number(part);
              }
            })
            .flat();
          const valueInputArray = Array.isArray(valueinput)
            ? valueinput
            : valueinput.split(/[\s,+]+/);
          const combinedSeats = [
            ...selectedSeats,
            ...selectedSeats1,
            ...selectedSeats2,
            ...valueInputArray,
          ]
            .map((seat) => {
              if (typeof seat === "number") return seat;

              return seat.split(/[\s,+]+/).map(Number);
            })
            .flat();

          const sortedNumbers1 = Array.from(
            new Set(combinedSeats.filter((seat) => !isNaN(seat)))
          );
          const uniqueNumbersWithoutZero = sortedNumbers1.filter(
            (number) => number !== 0
          );
          const sortedNumbers = uniqueNumbersWithoutZero.sort((a, b) => a - b);
          setResultArray(sortedNumbers);

          const reservationIds1 = [];
          const seatNumbersToSend = sortedNumbers;

          seatNumbersToSend.forEach((seat) => {
            if (idDirect == TripID.id) {
              if (
                Array.isArray(TripID.tripTransit) &&
                TripID.tripTransit?.length == 0
              ) {
                alert(1)
                let changeseat = false;
                const seatStatus = {};
            
             
                if (response1[seatNumbersToSend[0]] === 0 || response1[seatNumbersToSend[0]] === 3 || response1[seatNumbersToSend[0]] === 5 || response1[seatNumbersToSend[0]] === 4) {
                  setResultArray([]);
                  localStorage.setItem("changeseat", true);
                  sweetalert({
                    text: "Ctrl + 1 لا يمكن إضافة بيانات كرسي لا يحتوي على حجز يمكن إضافة حجز بالضغط ",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                }
                
                else if (response1[seatNumbersToSend[0]] == undefined) {
                  setResultArray([]);
                  sweetalert({
                    text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "إغلاق",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                }
                else if (response1[seatNumbersToSend[0]] == 2) {
                  setResultArray([]);
                  localStorage.setItem("changeseat", true);
                  sweetalert({
                    text: " لا يمكن القيام بهذه العملية   ",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                } else if (response1[seatNumbersToSend[0]] == 1) {
                  setOpenPopuppassenger(true);
                  localStorage.setItem("openpassenger", true);
                  setUpdateSeat(true);
                  const reservationDirect = Object.values(
                    arrayReservations
                  )?.find((reservation) => {
                    const seatNumbers = Object.keys(
                      reservation.reservation_seats_number
                    ).map(Number);
                    return seatNumbers.includes(seat);
                  });
                  settranset(false);
                  setDirect(true);
                  const passengerData = [];

                  reservationIds1.push({
                    reservationId1: reservationDirect.id,
                  });

                  setIdReservationResponse(reservationIds1);
              
                  setInputString("");
                  setValueinput("");
                  setSelectedSeats1([]);
                  setSelectedSeats([]);
                  setSelectedSeats2([]);
                  setSeatAndUser([]);
                }
              } else {
            
                if (response1[seatNumbersToSend[0]] == 0 || response1[seatNumbersToSend[0]] == 3 || response1[seatNumbersToSend[0]] == 5 || response1[seatNumbersToSend[0]] == 4) {
                 
                  setResultArray([]);
                  localStorage.setItem("changeseat", true);
                  sweetalert({
                    text: "Ctrl + 1 لا يمكن إضافة بيانات كرسي لا يحتوي على حجز يمكن إضافة حجز بالضغط ",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                }
                else if (response1[seatNumbersToSend[0]] == undefined) {
                  setResultArray([]);
                  sweetalert({
                    text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "إغلاق",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                }
                else if (response1[seatNumbersToSend[0]] == 2) {
                
                  setResultArray([]);
                  localStorage.setItem("changeseat", true);
                  sweetalert({
                    text: " لا يمكن القيام بهذه العملية   ",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                } else  if (response1[seatNumbersToSend[0]] == 1){
                
                  setUpdateSeat(true);
                  setOpenPopuppassenger(true);
                  localStorage.setItem("openpassenger", true);

                  const reservationDirect = Object.values(
                    arrayReservations
                  )?.find((reservation) => {
                    const seatNumbers = Object.keys(
                      reservation.reservation_seats_number
                    ).map(Number);
                    return seatNumbers.includes(seat);
                  });
                  settranset(false);
                  setDirect(true);
                  const passengerData = [];

                 
                  reservationIds1.push({
                    reservationId1: reservationDirect.id,
                  });

                  setIdReservationResponse(reservationIds1);
                 
                  setInputString("");
                  setValueinput("");
                  setSelectedSeats1([]);
                  setSelectedSeats([]);
                  setSelectedSeats2([]);
                  setSeatAndUser([]);
                }
              }
            } else if (idDirect != TripID.id) {
              let reservationseat = null;
              const reservationSeatKeys = Object.keys(
                TripID.tripTransit[selectedTransitIndex].reservationSeats
              );
              reservationseat = reservationSeatKeys.find((seat) =>
                seatNumbersToSend.includes(Number(seat))
              );
              settranset(true);
              setDirect(false);
              const seatStatus = {};

              // Iterate through selectedSeats and get the status for each seat
              seatNumbersToSend.forEach((seat) => {
                const status =
                  TripID.tripTransit[selectedTransitIndex].reservationSeats[
                    seat
                  ];
                seatStatus[seat] = status;
              });

              let changeseat = false;
              const status = reservationseat1[seatNumbersToSend[0]];

              if (reservationseat1[seatNumbersToSend[0]] === 0 || reservationseat1[seatNumbersToSend[0]] === 3 || reservationseat1[seatNumbersToSend[0]] == 5 || reservationseat1[seatNumbersToSend[0]] == 4) {
                setResultArray([]);
                localStorage.setItem("changeseat", true);
                sweetalert({
                  text: "Ctrl + 1 لا يمكن إضافة بيانات مسافر بدون حجز يمكن إضافة حجز بالضغط ",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } 
              else if (response1[seatNumbersToSend[0]] == undefined) {
                setResultArray([]);
                sweetalert({
                  text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (reservationseat1[seatNumbersToSend[0]] == 2) {
                setResultArray([]);
                localStorage.setItem("changeseat", true);
                sweetalert({
                  text: " لا يمكن القيام بهذه العملية   ",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else {
                setUpdateSeat(true);
                setOpenPopuppassenger(true);
                localStorage.setItem("openpassenger", true);
                const reservationIdTransit = Array.isArray(TripID.tripTransit)
                  ? TripID.tripTransit[
                      selectedTransitIndex
                    ]?.reservation.reduce((acc, reservation) => {
                      const seatNumbers = Object.keys(
                        reservation.reservation_seats_number
                      ).map(Number);
                      if (seatNumbers.includes(seat)) {
                        acc.push({
                          reservationId: reservation.id,
                        });
                      }
                      return acc;
                    }, [])
                  : null;

                if (reservationIdTransit) {
                  const passengerData = [];

                  seatNumbersToSend.forEach((seat) => {
                    if (Array.isArray(reservationIdTransit)) {
                      const passengersForSeat = [];

                     

                      reservationIds1.push({
                        reservationId1: reservationIdTransit,
                      });
                    

                      setIdReservationResponse(reservationIds1);
                      // Push the list of passengers for this seat into passengerData
                      passengerData.push(passengersForSeat);
                    }
                  });

                }
                setInputString("");
                setValueinput("");
                setSelectedSeats1([]);
                setSelectedSeats([]);
                setSelectedSeats2([]);
                setSeatAndUser([]);
              }
            }
          });
        
          setReservationIds(reservationIds1);
        }
      }

      if (event.ctrlKey && event.key === "3") {
        if (nofocus) {
          nofocus.blur();
        }

        const parts1 = valueinput.split("+");
        const numbers = parts1
          .map((part) => {
            if (part.includes("-")) {
              const [start, end] = part.split("-").map(Number);
              return [...Array(end - start + 1).keys()].map((i) => i + start);
            } else {
              return Number(part);
            }
          })
          .flat();

          const reservationIds1 = [];
          const valueInputArray = Array.isArray(valueinput)
            ? valueinput
            : valueinput.split(/[\s,+]+/);
          const combinedSeats = [
            ...selectedSeats,
            ...selectedSeats1,
            ...selectedSeats2,
            ...valueInputArray,
          ]
            .map((seat) => {
              if (typeof seat === "number") return seat;

              return seat.split(/[\s,+]+/).map(Number);
            })
            .flat();

          const sortedNumbers1 = Array.from(
            new Set(combinedSeats.filter((seat) => !isNaN(seat)))
          );
          const uniqueNumbersWithoutZero = sortedNumbers1.filter(
            (number) => number !== 0
          );
          const seatNumbersToSend = uniqueNumbersWithoutZero.sort(
            (a, b) => a - b
          );
          setResultArray(seatNumbersToSend);
          const reservationIdDirect = Object.values(
            arrayReservations
          ).find((reservation) => {
            const seatNumbers = Object.keys(
              reservation.reservation_seats_number || {} // Adding fallback to empty object to ensure it always works
            ).map(Number); // Convert keys to numbers since seat numbers are often numeric
            return seatNumbers.includes(seatNumbersToSend[0]);
          })?.id;
        

          const reservationIdTransit =
            Array.isArray(TripID.tripTransit) && arrayReservations
              ? Object.values(arrayReservations)?.reduce(
                  (acc, reservation) => {
                    // Ensure reservation_seats_number is not undefined before proceeding
                    if (
                      reservation &&
                      reservation.reservation_seats_number
                    ) {
                      const seatNumbers = Object.keys(
                        reservation.reservation_seats_number
                      ).map(Number);
                      if (
                        seatNumbers.includes(seatNumbersToSend[0])
                      ) {
                        acc.push({ reservationId: reservation.id });
                      }
                    }
                    return acc;
                  },
                  []
                )
              : null;
          if (idDirect == TripID.id) {
            if (
              Array.isArray(TripID.tripTransit) &&
              TripID.tripTransit.length > 0
            ) {
              let reservationseat = null;

              // Convert the object keys into an array and then use find
              const reservationSeatKeys = Object.keys(
                TripID.tripTransit[0].reservationSeats
              );
              reservationseat = reservationSeatKeys.find((seat) =>
                seatNumbersToSend.includes(Number(seat))
              );

              if (reservationseat == seatNumbersToSend) {
                localStorage.setItem("changeseat", true);
                sweetalert({
                  text: "لا يمكن تعديل كرسي رحلة العبور من الرحلة الاساسية للقيام بذلك انتقل إلى رحلة العبور وقم بتعديل البيانات الكرسي الخاص يرحلة العبور",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              } else {
                // Seat not found in transit trip
                const status = response1[seatNumbersToSend[0]];
                // eslint-disable-next-line eqeqeq
                if (status == 5 || status == 0) {
                  localStorage.setItem("changeseat", true);
                  sweetalert({
                    text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                  // eslint-disable-next-line eqeqeq
                }
                else if (status == undefined || status == '') {
                  setResultArray([]);
                  sweetalert({
                    text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "إغلاق",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                }
                else if (status == 4) {
                  setResultArray([]);
                  localStorage.setItem("changeseat", true);
                  sweetalert({
                    text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                  // eslint-disable-next-line eqeqeq
                } else if (status == 3) {
                  setResultArray([]);
                  localStorage.setItem("changeseat", true);
                  sweetalert({
                    text: "Ctrl + 0 لتغيير حالة كرسي ادارة لمتوفر اضغط على  ",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                  // eslint-disable-next-line eqeqeq
                } else if (status == 1) {
                  const id_reservation =
                    reservationIds1[0].reservationId1;
                  const seat_number = seatNumbersToSend;
                  const passengerDeleteData = {
                    id_reservation,
                    seat_number,
                    branch: TripID.id_branch,
                    trip_id: TripID.id,
                  };
                  axios
                    .post(
                      `${BaseUrl}/remove-seat-reservation`,
                      passengerDeleteData,
                      {
                        withCredentials: true,
                      }
                    )
                    .then((response) => {
                      // Handle the successful response here
                      if (response.data.status === 200) {
                        setResponse1(response.data.data.seats);
                        setArraySeat((prevSeatsInfo) => {
                          const updatedSeatsInfo = {
                            ...prevSeatsInfo,
                          };
                          const seatNumber = parseInt(seat_number);

                          if (!isNaN(seatNumber)) {
                            updatedSeatsInfo[seatNumber] = {
                              passengers: {},
                              status: 0,
                              id_reservation: undefined,
                            };
                          }

                          return updatedSeatsInfo;
                        });
                        const mappedById =
                          response.data.data.reservations.reduce(
                            (acc, item) => {
                              acc[item.id] = item;
                              return acc;
                            },
                            {}
                          );
                        setArrayReservations(mappedById);

                        sweetalert({
                          text: response.data.message,
                          icon: "success",
                          buttons: {
                            confirm: {
                              text: "تم",
                              className: "custom-button-class",
                            },
                          },
                          className: "alert-div",
                        }).then(() => {
                          localStorage.setItem(
                            "openpassenger",
                            false
                          );
                          localStorage.setItem("changeseat", false);
                        });
                      }
                    });
                  settranset(false);
                  setDirect(true);
                  setResultArray([]);
                  setInputString("");
                  setValueinput("");
                  setSeatAndUser([]);
                  setSelectedSeats([]);
                  setSelectedSeats1([]);
                  setSelectedSeats2([]);
                  // eslint-disable-next-line eqeqeq
                } else {
                  setOpenPopuppassenger2(true);
                  localStorage.setItem("ReservationDeleted", true);
                  const reservationDirect = Object.values(
                    arrayReservations
                  )?.find((reservation) => {
                    const seatNumbers = Object.keys(
                      reservation.reservation_seats_number
                    ).map(Number);
                    return seatNumbers.includes(
                      seatNumbersToSend[0]
                    );
                  });

                  setIdReservationResponse(reservationDirect);
                  settranset(false);
                  setDirect(true);
                  const passengerData = [];

                  seatNumbersToSend.forEach((seat) => {
                    const matchingPassenger =
                      reservationDirect.passengers.find(
                        (passenger) => {
                          return passenger.number_seat == seat;
                        }
                      );
                    if (matchingPassenger) {
                      passengerData.push({
                        id:
                          matchingPassenger.id_passenger ||
                          matchingPassenger.id,
                        id_price_ticket:
                          matchingPassenger.id_price_ticket,
                        price: matchingPassenger.price,
                        code_seat: "",
                        typeReduction: "",
                        // Add other passenger details as needed
                      });

                      reservationIds1.push({
                        reservationId1: reservationDirect.id,
                      });
                      setUpdatePassenger(true);
                    }
                  });

                  if (passengerData.length > 0) {
                    const existingPassengerData =
                      JSON.parse(
                        localStorage.getItem("passengers")
                      ) || [];
                    const updatedPassengerData = [
                      ...passengerData,
                    ];

                    localStorage.setItem(
                      "passengers",
                      JSON.stringify(updatedPassengerData)
                    );
                  }
                  const matchingPassenger =
                    reservationDirect.passengers.find(
                      (passenger) => {
                        return (
                          passenger.number_seat == selectedSeats
                        );
                      }
                    );

                  if (matchingPassenger) {
                    const passengerData = [
                      {
                        id:
                          matchingPassenger.id_passenger ||
                          matchingPassenger.id,
                        first_name: matchingPassenger.first_name,
                        number_seat: matchingPassenger.number_seat,
                        id_price_ticket:
                          matchingPassenger.id_price_ticket,
                        price: matchingPassenger.price,
                        code_seat: "",
                        typeReduction: "",
                        // Add other passenger details as needed
                      },
                    ];
                    reservationIds1.push({
                      reservationId1: reservationDirect.id,
                    });
                    setUpdatePassenger(true);
                    const existingPassengerData =
                      JSON.parse(
                        localStorage.getItem("passengers")
                      ) || [];
                    const updatedPassengerData = [
                      ...existingPassengerData,
                      ...passengerData,
                    ];

                    localStorage.setItem(
                      "passengers",
                      JSON.stringify(updatedPassengerData)
                    );
                  }
                }
              }
            } else if (
              Array.isArray(TripID.tripTransit) &&
              TripID.tripTransit?.length == 0
            ) {
              let changeseat = false;
              const seatStatus = {};
              const status = response1[seatNumbersToSend[0]];
              reservationIds1.push({
                reservationId1: reservationIdDirect,
              });

              // eslint-disable-next-line eqeqeq
              if (status == 5 || status == 0) {
                localStorage.setItem("changeseat", true);
                sweetalert({
                  text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
                // eslint-disable-next-line eqeqeq
              } 
              else if (status == undefined || status == '') {
                setResultArray([]);
                sweetalert({
                  text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (status == 4) {
                setResultArray([]);
                localStorage.setItem("changeseat", true);
                sweetalert({
                  text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
                // eslint-disable-next-line eqeqeq
              } else if (status == 3) {
                setResultArray([]);
                localStorage.setItem("changeseat", true);
                sweetalert({
                  text: "Ctrl + 0 لتغيير حالة كرسي ادارة اضغط على  ",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
                // eslint-disable-next-line eqeqeq
              } else if (status == 1) {
                const id_reservation =
                  reservationIds1[0].reservationId1;
                const seat_number = seatNumbersToSend;
                const passengerDeleteData = {
                  id_reservation,
                  seat_number,
                  branch: TripID.id_branch,
                  trip_id: TripID.id,
                };
                axios
                  .post(
                    `${BaseUrl}/remove-seat-reservation`,
                    passengerDeleteData,
                    {
                      withCredentials: true,
                    }
                  )
                  .then((response) => {
                    // Handle the successful response here
                    if (response.data.status === 200) {
                      setResponse1(response.data.data.seats);
                      setArraySeat((prevSeatsInfo) => {
                        const updatedSeatsInfo = {
                          ...prevSeatsInfo,
                        };
                        const seatNumber = parseInt(seat_number);

                        if (!isNaN(seatNumber)) {
                          updatedSeatsInfo[seatNumber] = {
                            passengers: {},
                            status: 0,
                            id_reservation: undefined,
                          };
                        }

                        return updatedSeatsInfo;
                      });
                      const mappedById =
                        response.data.data.reservations.reduce(
                          (acc, item) => {
                            acc[item.id] = item;
                            return acc;
                          },
                          {}
                        );
                      setArrayReservations(mappedById);

                      sweetalert({
                        text: response.data.message,
                        icon: "success",
                        buttons: {
                          confirm: {
                            text: "تم",
                            className: "custom-button-class",
                          },
                        },
                        className: "alert-div",
                      }).then(() => {
                        localStorage.setItem(
                          "openpassenger",
                          false
                        );
                        localStorage.setItem("changeseat", false);
                        setOpenPopuppassenger2(false);
                      });
                    }
                  });
                settranset(false);
                setDirect(true);
                setResultArray([]);
                setInputString("");
                setValueinput("");
                setSeatAndUser([]);
                setSelectedSeats([]);
                setSelectedSeats1([]);
                setSelectedSeats2([]);
                // eslint-disable-next-line eqeqeq
              } else {
                setOpenPopuppassenger2(true);
                localStorage.setItem("ReservationDeleted", true);
                const reservationDirect = Object.values(
                  arrayReservations
                )?.find((reservation) => {
                  const seatNumbers = Object.keys(
                    reservation.reservation_seats_number
                  ).map(Number);
                  return seatNumbers.includes(seatNumbersToSend[0]);
                });
                setIdReservationResponse(reservationDirect);

                settranset(false);
                setDirect(true);
                const passengerData = [];

                // Loop through selectedSeats and find matching passengers
                seatNumbersToSend.forEach((seat) => {
                  const matchingPassenger =
                    reservationDirect.passengers.find(
                      (passenger) => {
                        return passenger.number_seat == seat;
                      }
                    );

                  if (matchingPassenger) {
                    passengerData.push({
                      id:
                        matchingPassenger.id_passenger ||
                        matchingPassenger.id,
                      number_seat: matchingPassenger.number_seat,
                      id_price_ticket:
                        matchingPassenger.id_price_ticket,
                      price: matchingPassenger.price,
                      code_seat: "",
                      typeReduction: "",

                      // Add other passenger details as needed
                    });

                    reservationIds1.push({
                      reservationId1: reservationDirect.id,
                    });
                    setUpdatePassenger(true);
                  }
                });

                if (passengerData.length > 0) {
                  const existingPassengerData =
                    JSON.parse(
                      localStorage.getItem("passengers")
                    ) || [];
                  const updatedPassengerData = [
                    ...existingPassengerData,
                    ...passengerData,
                  ];
                  localStorage.setItem(
                    "passengers",
                    JSON.stringify(updatedPassengerData)
                  );
                }
              }
            }
          } else if (idDirect != TripID.id) {
            settranset(true);
            setDirect(false);

            // Seat not found in transit trip
            const status = reservationseat1[seatNumbersToSend[0]];
            reservationIds1.push({
              reservationId1: reservationIdTransit,
            });

            // eslint-disable-next-line eqeqeq
            if (status == 5 || status == 0) {
              localStorage.setItem("changeseat", true);
              sweetalert({
                text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
              // eslint-disable-next-line eqeqeq
            } 
            else if (status == undefined || status == '') {
              setResultArray([]);
              sweetalert({
                text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
            
            else if (status == 4) {
              setResultArray([]);
              localStorage.setItem("changeseat", true);
              sweetalert({
                text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
              // eslint-disable-next-line eqeqeq
            } else if (status == 3) {
              setResultArray([]);
              localStorage.setItem("changeseat", true);
              sweetalert({
                text: "Ctrl + 0 لتغيير حالة كرسي ادارة لمتوفر اضغط على  ",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
              // eslint-disable-next-line eqeqeq
            } else if (status == 1) {
              const id_reservation =
                reservationIds1[0]?.reservationId1[0].reservationId;
              const seat_number = seatNumbersToSend;
              const passengerDeleteData = {
                id_reservation,
                seat_number,
                branch: TripID.id_branch,
                trip_id: TripID.id,
              };
              axios
                .post(
                  `${BaseUrl}/remove-seat-reservation`,
                  passengerDeleteData,
                  {
                    withCredentials: true,
                  }
                )
                .then((response) => {
                  // Handle the successful response here
                  if (response.data.status === 200) {
                    setReservationseat1(response.data.data.seats);

                    if (selectedTransitIndex == 0) {
                      setArraySeatPassengerTransit1(
                        (prevSeatsInfo) => {
                          const updatedSeatsInfo = {
                            ...prevSeatsInfo,
                          };

                          seatNumbersToSend.forEach(
                            (seat_number) => {
                              const seatNumber =
                                parseInt(seat_number);
                              if (!isNaN(seatNumber)) {
                                updatedSeatsInfo[seatNumber] = {
                                  passengers: {}, // Empty object for passengers
                                  status: 0, // Set status to 0
                                  id_reservation: undefined, // No reservation ID
                                };
                              } else {
                              }
                            }
                          );

                          return updatedSeatsInfo;
                        }
                      );
                      const mappedById =
                        response.data.data.reservations.reduce(
                          (acc, item) => {
                            acc[item.id] = item;
                            return acc;
                          },
                          {}
                        );
                      setArrayReservations(mappedById);
                      sweetalert({
                        text: response.data.message,
                        icon: "success",
                        buttons: {
                          confirm: {
                            text: "تم",
                            className: "custom-button-class",
                          },
                        },
                        className: "alert-div",
                      }).then(() => {
                        // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                        localStorage.setItem(
                          "openpassenger",
                          false
                        );
                        localStorage.setItem("changeseat", false);
                        setOpenPopuppassenger2(false);
                      });
                    } else if (selectedTransitIndex == 1) {
                      setArraySeatPassengerTransit2(
                        (prevSeatsInfo) => {
                          const updatedSeatsInfo = {
                            ...prevSeatsInfo,
                          };
                          const seatNumber = parseInt(seat_number);

                          if (!isNaN(seatNumber)) {
                            updatedSeatsInfo[seatNumber] = {
                              passengers: {},
                              status: 0,
                              id_reservation: undefined,
                            };
                          }

                          return updatedSeatsInfo;
                        }
                      );
                      const mappedById =
                        response.data.data.reservations.reduce(
                          (acc, item) => {
                            acc[item.id] = item;
                            return acc;
                          },
                          {}
                        );
                      setArrayReservations(mappedById);
                      sweetalert({
                        text: response.data.message,
                        icon: "success",
                        buttons: {
                          confirm: {
                            text: "تم",
                            className: "custom-button-class",
                          },
                        },
                        className: "alert-div",
                      }).then(() => {
                        localStorage.setItem(
                          "openpassenger",
                          false
                        );
                        localStorage.setItem("changeseat", false);
                        setOpenPopuppassenger2(false);
                      });
                    }
                  }
                });
              settranset(true);
              setDirect(false);
              setResultArray([]);
              setInputString("");
              setValueinput("");
              setSeatAndUser([]);
              setSelectedSeats([]);
              setSelectedSeats1([]);
              setSelectedSeats2([]);
            } else {
              setOpenPopuppassenger2(true);
              localStorage.setItem("ReservationDeleted", true);
              const reservationIdTransit = Array.isArray(
                TripID.tripTransit
              )
                ? Object.values(arrayReservations)?.reduce(
                    (acc, reservation) => {
                      const seatNumbers = Object.keys(
                        reservation.reservation_seats_number
                      ).map(Number);
                      if (
                        seatNumbers.includes(seatNumbersToSend[0])
                      ) {
                        acc.push({
                          reservationId: reservation.id,
                          passengers: reservation.passengers,
                          trip_id:
                            reservation.trip_id ||
                            reservation.triptable_id,
                        });
                      }
                      return acc;
                    },
                    []
                  )
                : null;
              const relevantReservations = Object.values(
                reservationIdTransit
              ).filter(
                (reservation) =>
                  reservation.trip_id.toString() === idtransit
              );

              setIdReservationResponse(relevantReservations);
              if (relevantReservations) {
                const passengerData = [];

                if (Array.isArray(relevantReservations)) {
                  const passengersForSeat = [];

                  // relevantReservations.forEach((reservation) => {
                  const matchingPassenger =
                    relevantReservations[0]?.passengers.find(
                      (passenger) => {
                        return (
                          passenger.number_seat ===
                          seatNumbersToSend[0]
                        ); // Use strict equality
                      }
                    );

                  if (matchingPassenger) {
                    passengerData.push({
                      id:
                        matchingPassenger.id_passenger ||
                        matchingPassenger.id,
                      number_seat: matchingPassenger.number_seat,
                      id_price_ticket:
                        matchingPassenger.id_price_ticket,
                      price: matchingPassenger.price,
                      code_seat: "",
                      typeReduction: "",
                    });
                  }
                  

                }

                if (passengerData.length > 0) {
                  const flattenedPassengerData =
                    passengerData.flat();

                 
                  const updatedPassengerData = [
                    ...flattenedPassengerData,
                  ];

                  localStorage.setItem(
                    "passengers",
                    JSON.stringify(updatedPassengerData)
                  );
                }
              }
            }
          }
      
      }
      if(event.ctrlKey && event.key ==="F2"){
     
          const reservationIds1 = [];
          const valueInputArray = Array.isArray(valueinput)
            ? valueinput
            : valueinput.split(/[\s,+]+/);
          const combinedSeats = [
            ...selectedSeats,
            ...selectedSeats1,
            ...selectedSeats2,
            ...valueInputArray,
          ]
            .map((seat) => {
              if (typeof seat === "number") return seat;

              return seat.split(/[\s,+]+/).map(Number);
            })
            .flat();

          const sortedNumbers1 = Array.from(
            new Set(combinedSeats.filter((seat) => !isNaN(seat)))
          );
          const uniqueNumbersWithoutZero = sortedNumbers1.filter(
            (number) => number !== 0
          );
          const seatNumbersToSend = uniqueNumbersWithoutZero.sort(
            (a, b) => a - b
          );
          setResultArray(seatNumbersToSend);
          // seatNumbersToSend.forEach((seat) => {
          const reservationIdDirect = Object.values(
            arrayReservations
          ).find((reservation) => {
            const seatNumbers = Object.keys(
              reservation.reservation_seats_number || {} // Adding fallback to empty object to ensure it always works
            ).map(Number); // Convert keys to numbers since seat numbers are often numeric
            return seatNumbers.includes(seatNumbersToSend[0]);
          })?.id;
          const reservationIdTransit =
            Array.isArray(TripID.tripTransit) && arrayReservations
              ? Object.values(arrayReservations)?.reduce(
                  (acc, reservation) => {
                    // Ensure reservation_seats_number is not undefined before proceeding
                    if (
                      reservation &&
                      reservation.reservation_seats_number
                    ) {
                      const seatNumbers = Object.keys(
                        reservation.reservation_seats_number
                      ).map(Number);
                      if (
                        seatNumbers.includes(seatNumbersToSend[0])
                      ) {
                        acc.push({ reservationId: reservation.id });
                      }
                    }
                    return acc;
                  },
                  []
                )
              : null;
          if (idDirect == TripID.id) {
            if (
              Array.isArray(TripID.tripTransit) &&
              TripID.tripTransit.length > 0
            ) {
              // const seatNumbersToSend = selectedSeats;
              let reservationseat = null;

              // Convert the object keys into an array and then use find
              const reservationSeatKeys = Object.keys(
                TripID.tripTransit[0].reservationSeats
              );
              reservationseat = reservationSeatKeys.find((seat) =>
                seatNumbersToSend.includes(Number(seat))
              );

              if (reservationseat == seatNumbersToSend) {
                localStorage.setItem("changeseat", true);
             
                sweetalert({
                  text: "لا يمكن تعديل كرسي رحلة العبور من الرحلة الاساسية للقيام بذلك انتقل إلى رحلة العبور وقم بتعديل البيانات الكرسي الخاص يرحلة العبور",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                  localStorage.setItem("ReservationChange", false);
                });
              } else {
                // Seat not found in transit trip
                const status = response1[seatNumbersToSend[0]];
                reservationIds1.push({ reservationId1: reservationIdDirect });

                // eslint-disable-next-line eqeqeq
                if (status == 5 || status == 0) {
                  localStorage.setItem("changeseat", true);
                  localStorage.setItem("ReservationChange", true);
                   
                  sweetalert({
                    text: "لا يمكن تيديل كرسي لا يحتوي على بيانات",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                    localStorage.setItem("ReservationChange", false);
          
                  });
                  // eslint-disable-next-line eqeqeq
                } 
                else if (status == undefined || status == '') {
                  setResultArray([]);
                  sweetalert({
                    text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "إغلاق",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                }
                else if (status == 4) {
                  setResultArray([]);
                  localStorage.setItem("changeseat", true);
                  localStorage.setItem("ReservationChange", true);
                   
                  sweetalert({
                    text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                    localStorage.setItem("ReservationChange", false);
                
                  });
                  // eslint-disable-next-line eqeqeq
                } else if (status == 3) {
                  setResultArray([]);
                  localStorage.setItem("changeseat", true);
                  localStorage.setItem("ReservationChange", true);
               
                  sweetalert({
                    text: "Ctrl + 0 لتغيير حالة كرسي ادارة لمتوفر اضغط على  ",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                    localStorage.setItem("ReservationChange", false);
                   
                  });
                  // eslint-disable-next-line eqeqeq
                }
                else if (status == 1) {
                  setOpenPopup2(true);

const reservationDirect = Object.values(arrayReservations)?.find(
  (reservation) => {
    const seatNumbers = Object.keys(
      reservation.reservation_seats_number
    ).map(Number);
    return seatNumbers.includes(seatNumbersToSend[0]);
  }
);
settranset(false);
setDirect(true);
const passengerData = [];

seatNumbersToSend.forEach((seat) => {
                    const matchingPassenger =
                      reservationDirect.passengers.find(
                        (passenger) => {
                          return passenger.number_seat == seat;
                        }
                      );
             
                      passengerData.push({
                        id:"",
                        number_seat:seatNumbersToSend[0],
                        id_price_ticket:"",
                        price:"",
                        NewSeat: "",
                        typeReduction: "",
                        // Add other passenger details as needed
                      });

                      reservationIds1.push({
                        reservationId1: reservationDirect.id,
                      });
                      
                    
                  });

                  if (passengerData.length > 0) {
                    const existingPassengerData =
                      JSON.parse(
                        localStorage.getItem("passengers")
                      ) || [];
                    const updatedPassengerData = [
                      ...passengerData,
                    ];

                    localStorage.setItem(
                      "passengers",
                      JSON.stringify(updatedPassengerData)
                    );
                  }
                 

reservationIds1.push({
  reservationId1: reservationDirect.id,
});

setIdReservationResponse(reservationIds1);
                }
             
                else if( status == 2){
               
                  localStorage.setItem("changeseat", true);
                  localStorage.setItem("ReservationChange", true);
                  setOpenPopup2(true);
                  const reservationDirect = Object.values(
                    arrayReservations
                  )?.find((reservation) => {
                    const seatNumbers = Object.keys(
                      reservation.reservation_seats_number
                    ).map(Number);
                    return seatNumbers.includes(
                      seatNumbersToSend[0]
                    );
                  });
                  
                  setIdReservationResponse(reservationDirect);
                  settranset(false);
                  setDirect(true);
                  const passengerData = [];

                  seatNumbersToSend.forEach((seat) => {
                    const matchingPassenger =
                      reservationDirect.passengers.find(
                        (passenger) => {
                          return passenger.number_seat == seat;
                        }
                      );
                    if (matchingPassenger) {
                      passengerData.push({
                        id:
                          matchingPassenger.id_passenger ||
                          matchingPassenger.id,
                          number_seat: matchingPassenger.number_seat,
                        id_price_ticket:
                          matchingPassenger.id_price_ticket,
                        price: matchingPassenger.price,
                        NewSeat: "",
                        typeReduction: "",
                        // Add other passenger details as needed
                      });

                      reservationIds1.push({
                        reservationId1: reservationDirect.id,
                      });
                      
                    }
                  });

                  if (passengerData.length > 0) {
                    const existingPassengerData =
                      JSON.parse(
                        localStorage.getItem("passengers")
                      ) || [];
                    const updatedPassengerData = [
                      // ...existingPassengerData,
                      ...passengerData,
                    ];

                    localStorage.setItem(
                      "passengers",
                      JSON.stringify(updatedPassengerData)
                    );
                  }
                  const matchingPassenger =
                    reservationDirect.passengers.find(
                      (passenger) => {
                        return (
                          passenger.number_seat == selectedSeats
                        );
                      }
                    );

                  if (matchingPassenger) {
                    const passengerData = [
                      {
                        id:
                          matchingPassenger.id_passenger ||
                          matchingPassenger.id,
                        first_name: matchingPassenger.first_name,
                        number_seat: matchingPassenger.number_seat,
                        id_price_ticket:
                          matchingPassenger.id_price_ticket,
                        price: matchingPassenger.price,
                        code_seat: "",
                        typeReduction: "",
                        // Add other passenger details as needed
                      },
                    ];
                    reservationIds1.push({
                      reservationId1: reservationDirect.id,
                    });
                    setUpdatePassenger(true);
                    const existingPassengerData =
                      JSON.parse(
                        localStorage.getItem("passengers")
                      ) || [];
                    const updatedPassengerData = [
                      ...existingPassengerData,
                      ...passengerData,
                    ];

                    localStorage.setItem(
                      "passengers",
                      JSON.stringify(updatedPassengerData)
                    );
                  }
                }
              }
            } else if (
              Array.isArray(TripID.tripTransit) &&
              TripID.tripTransit?.length == 0
            ) {
              let changeseat = false;
              const seatStatus = {};
              const status = response1[seatNumbersToSend[0]];
              reservationIds1.push({
                reservationId1: reservationIdDirect,
              });

              // eslint-disable-next-line eqeqeq
              if (status == 5 || status == 0) {
                localStorage.setItem("changeseat", true);
                localStorage.setItem("ReservationChange", true);
                  
                sweetalert({
                  text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                  localStorage.setItem("ReservationChange", false);
                  
                });
                // eslint-disable-next-line eqeqeq
              }
              else if (status == undefined || status == '') {
                setResultArray([]);
                sweetalert({
                  text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (status == 4) {
                setResultArray([]);
                localStorage.setItem("changeseat", true);
                localStorage.setItem("ReservationChange", true);

                sweetalert({
                  text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                  localStorage.setItem("ReservationChange", false);
                });
                // eslint-disable-next-line eqeqeq
              } else if (status == 3) {
                setResultArray([]);
                localStorage.setItem("changeseat", true);
                localStorage.setItem("ReservationChange", true);
                sweetalert({
                  text: "Ctrl + 0 لتغيير حالة كرسي ادارة اضغط على  ",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                  localStorage.setItem("ReservationChange", false);
                });
                // eslint-disable-next-line eqeqeq
              }
              
       
              else if (status == 1) {
                  setOpenPopup2(true);

const reservationDirect = Object.values(arrayReservations)?.find(
  (reservation) => {
    const seatNumbers = Object.keys(
      reservation.reservation_seats_number
    ).map(Number);
    return seatNumbers.includes(seatNumbersToSend[0]);
  }
);
settranset(false);
setDirect(true);
const passengerData = [];

seatNumbersToSend.forEach((seat) => {
                    const matchingPassenger =
                      reservationDirect.passengers.find(
                        (passenger) => {
                          return passenger.number_seat == seat;
                        }
                      );
             
                      passengerData.push({
                        id:"",
                        number_seat:seatNumbersToSend[0],
                        id_price_ticket:"",
                        price:"",
                        NewSeat: "",
                        typeReduction: "",
                        // Add other passenger details as needed
                      });

                      reservationIds1.push({
                        reservationId1: reservationDirect.id,
                      });
                      
                    
                  });

                  if (passengerData.length > 0) {
                    const existingPassengerData =
                      JSON.parse(
                        localStorage.getItem("passengers")
                      ) || [];
                    const updatedPassengerData = [
                      // ...existingPassengerData,
                      ...passengerData,
                    ];

                    localStorage.setItem(
                      "passengers",
                      JSON.stringify(updatedPassengerData)
                    );
                  }
             
reservationIds1.push({
  reservationId1: reservationDirect.id,
});

setIdReservationResponse(reservationIds1);
                }
              else if(status == 2){
                setOpenPopup2(true);
                localStorage.setItem("changeseat", true);
                localStorage.setItem("ReservationChange", true);
                const reservationDirect = Object.values(
                  arrayReservations
                )?.find((reservation) => {
                  const seatNumbers = Object.keys(
                    reservation.reservation_seats_number
                  ).map(Number);
                  return seatNumbers.includes(seatNumbersToSend[0]);
                });
                setIdReservationResponse(reservationDirect);

                settranset(false);
                setDirect(true);
                const passengerData = [];

                // Loop through selectedSeats and find matching passengers
                seatNumbersToSend.forEach((seat) => {
                  const matchingPassenger =
                    reservationDirect.passengers.find(
                      (passenger) => {
                        return passenger.number_seat == seat;
                      }
                    );

                  if (matchingPassenger) {
                    passengerData.push({
                      id:
                        matchingPassenger.id_passenger ||
                        matchingPassenger.id,
                      number_seat: matchingPassenger.number_seat,
                      id_price_ticket:
                        matchingPassenger.id_price_ticket,
                      price: matchingPassenger.price,
                      NewSeat: "",
                      typeReduction: "",

                      // Add other passenger details as needed
                    });

                    reservationIds1.push({
                      reservationId1: reservationDirect.id,
                    });
                    setUpdatePassenger(true);
                  }
                });

                if (passengerData.length > 0) {
                  const existingPassengerData =
                    JSON.parse(
                      localStorage.getItem("passengers")
                    ) || [];
                  const updatedPassengerData = [
                    ...existingPassengerData,
                    ...passengerData,
                  ];
                  localStorage.setItem(
                    "passengers",
                    JSON.stringify(updatedPassengerData)
                  );
                }
              }
            }
          } else if (idDirect != TripID.id) {
            settranset(true);
            setDirect(false);

            // Seat not found in transit trip
            const status = reservationseat1[seatNumbersToSend[0]];
            reservationIds1.push({
              reservationId1: reservationIdTransit,
            });

            // eslint-disable-next-line eqeqeq
            if (status == 5 || status == 0) {
              localStorage.setItem("changeseat", true);
              localStorage.setItem("ReservationChange", true);
              sweetalert({
                text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
                localStorage.setItem("ReservationChange", false);
              });
              // eslint-disable-next-line eqeqeq
            }
            else if (status == undefined || status == '') {
              setResultArray([]);
              sweetalert({
                text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
            else if (status == 4) {
              setResultArray([]);
              localStorage.setItem("changeseat", true);
              localStorage.setItem("ReservationChange", true);
              sweetalert({
                text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
                localStorage.setItem("ReservationChange", false);
              });
              // eslint-disable-next-line eqeqeq
            } else if (status == 3) {
              setResultArray([]);
              localStorage.setItem("changeseat", true);
              localStorage.setItem("ReservationChange", true);
              sweetalert({
                text: "Ctrl + 0 لتغيير حالة كرسي ادارة لمتوفر اضغط على  ",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
                localStorage.setItem("ReservationChange", false);
              });
              // eslint-disable-next-line eqeqeq
            } 
           
            else if (status == 1) {
                  setOpenPopup2(true);

const reservationDirect = Object.values(arrayReservations)?.find(
  (reservation) => {
    const seatNumbers = Object.keys(
      reservation.reservation_seats_number
    ).map(Number);
    return seatNumbers.includes(seatNumbersToSend[0]);
  }
);
settranset(false);
setDirect(true);
const passengerData = [];

seatNumbersToSend.forEach((seat) => {
                    const matchingPassenger =
                      reservationDirect.passengers.find(
                        (passenger) => {
                          return passenger.number_seat == seat;
                        }
                      );
             
                      passengerData.push({
                        id:"",
                        number_seat:seatNumbersToSend[0],
                        id_price_ticket:"",
                        price:"",
                        NewSeat: "",
                        typeReduction: "",
                        // Add other passenger details as needed
                      });

                      reservationIds1.push({
                        reservationId1: reservationDirect.id,
                      });
                      
                    
                  });

                  if (passengerData.length > 0) {
                    const existingPassengerData =
                      JSON.parse(
                        localStorage.getItem("passengers")
                      ) || [];
                    const updatedPassengerData = [
            
                      ...passengerData,
                    ];

                    localStorage.setItem(
                      "passengers",
                      JSON.stringify(updatedPassengerData)
                    );
                  }
        

reservationIds1.push({
  reservationId1: reservationDirect.id,
});

setIdReservationResponse(reservationIds1);
                }
            else if(status == 2){
              setOpenPopup2(true);
              localStorage.setItem("changeseat", true);
              localStorage.setItem("ReservationChange", true);
              const reservationIdTransit = Array.isArray(
                TripID.tripTransit
              )
                ? Object.values(arrayReservations)?.reduce(
                    (acc, reservation) => {
                      const seatNumbers = Object.keys(
                        reservation.reservation_seats_number
                      ).map(Number);
                      if (
                        seatNumbers.includes(seatNumbersToSend[0])
                      ) {
                        acc.push({
                          reservationId: reservation.id,
                          passengers: reservation.passengers,
                          trip_id:
                            reservation.trip_id ||
                            reservation.triptable_id,
                        });
                      }
                      return acc;
                    },
                    []
                  )
                : null;
              const relevantReservations = Object.values(
                reservationIdTransit
              ).filter(
                (reservation) =>
                  reservation.trip_id.toString() === idtransit
              );

              setIdReservationResponse(relevantReservations);
              if (relevantReservations) {
                const passengerData = [];

                if (Array.isArray(relevantReservations)) {
                  const passengersForSeat = [];

                  // relevantReservations.forEach((reservation) => {
                  const matchingPassenger =
                    relevantReservations[0]?.passengers.find(
                      (passenger) => {
                        return (
                          passenger.number_seat ===
                          seatNumbersToSend[0]
                        ); // Use strict equality
                      }
                    );

                  if (matchingPassenger) {
                    passengerData.push({
                      id:
                        matchingPassenger.id_passenger ||
                        matchingPassenger.id,
                      number_seat: matchingPassenger.number_seat,
                      id_price_ticket:
                        matchingPassenger.id_price_ticket,
                      price: matchingPassenger.price,
                      NewSeat: "",
                      typeReduction: "",
                    });
                  }
     
                }

                if (passengerData.length > 0) {
                  const flattenedPassengerData =
                    passengerData.flat();

          
                  const updatedPassengerData = [
                    ...flattenedPassengerData,
                  ];

                  localStorage.setItem(
                    "passengers",
                    JSON.stringify(updatedPassengerData)
                  );
                }
              }
            }
          }
        
      }
      if(event.ctrlKey && event.key === "F1") {
      
          const reservationIds1 = [];
          const valueInputArray = Array.isArray(valueinput)
            ? valueinput
            : valueinput.split(/[\s,+]+/);
          const combinedSeats = [
            ...selectedSeats,
            ...selectedSeats1,
            ...selectedSeats2,
            ...valueInputArray,
          ]
            .map((seat) => {
              if (typeof seat === "number") return seat;

              return seat.split(/[\s,+]+/).map(Number);
            })
            .flat();

          const sortedNumbers1 = Array.from(
            new Set(combinedSeats.filter((seat) => !isNaN(seat)))
          );
          const uniqueNumbersWithoutZero = sortedNumbers1.filter(
            (number) => number !== 0
          );
          const seatNumbersToSend = uniqueNumbersWithoutZero.sort(
            (a, b) => a - b
          );
          setResultArray(seatNumbersToSend);
          // seatNumbersToSend.forEach((seat) => {
          const reservationIdDirect = Object.values(
            arrayReservations
          ).find((reservation) => {
            const seatNumbers = Object.keys(
              reservation.reservation_seats_number || {} // Adding fallback to empty object to ensure it always works
            ).map(Number); // Convert keys to numbers since seat numbers are often numeric
            return seatNumbers.includes(seatNumbersToSend[0]);
          })?.id;
          const reservationIdTransit =
            Array.isArray(TripID.tripTransit) && arrayReservations
              ? Object.values(arrayReservations)?.reduce(
                  (acc, reservation) => {
                    // Ensure reservation_seats_number is not undefined before proceeding
                    if (
                      reservation &&
                      reservation.reservation_seats_number
                    ) {
                      const seatNumbers = Object.keys(
                        reservation.reservation_seats_number
                      ).map(Number);
                      if (
                        seatNumbers.includes(seatNumbersToSend[0])
                      ) {
                        acc.push({ reservationId: reservation.id });
                      }
                    }
                    return acc;
                  },
                  []
                )
              : null;
          if (idDirect == TripID.id) {
            if (
              Array.isArray(TripID.tripTransit) &&
              TripID.tripTransit.length > 0
            ) {
              // const seatNumbersToSend = selectedSeats;
              let reservationseat = null;

              // Convert the object keys into an array and then use find
              const reservationSeatKeys = Object.keys(
                TripID.tripTransit[0].reservationSeats
              );
              reservationseat = reservationSeatKeys.find((seat) =>
                seatNumbersToSend.includes(Number(seat))
              );

       
                // Seat not found in transit trip
                const status = response1[seatNumbersToSend[0]];

                // eslint-disable-next-line eqeqeq
                if (status == 5 || status == 0) {
                  setOpenPopup(true);
                  localStorage.setItem("ReservationPhone", true);
                  // eslint-disable-next-line eqeqeq
                } 

                else if (status == undefined || status == '') {
                  setResultArray([]);
                  sweetalert({
                    text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "إغلاق",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                  });
                }
                else if (status == 4 || status == 3 || status == 2 || status == 1) {
                  setResultArray([]);
                  localStorage.setItem("changeseat", true);
                  sweetalert({
                    text: "لا يمكن القيام بهذه العملية",
                    icon: "error",
                    buttons: {
                      confirm: {
                        text: "تم",
                        className: "custom-button-class",
                      },
                    },
                    className: "alert-div",
                  }).then(() => {
                    localStorage.setItem("changeseat", false);
                    localStorage.setItem("ReservationChange", false);
                
                  });
                  // eslint-disable-next-line eqeqeq
                }
                
              
            } else if (
              Array.isArray(TripID.tripTransit) &&
              TripID.tripTransit?.length == 0
            ) {
              
              const status = response1[seatNumbersToSend[0]];
          

              if (status == 5 || status == 0) {
                setOpenPopup(true);
                localStorage.setItem("ReservationPhone", true);
                // eslint-disable-next-line eqeqeq
              } 

              else if (status == undefined || status == '') {
                setResultArray([]);
                sweetalert({
                  text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                });
              }
              else if (status == 4 || status == 3 || status == 2 || status == 1) {
                setResultArray([]);
                localStorage.setItem("changeseat", true);
                sweetalert({
                  text: "لا يمكن القيام بهذه العملية",
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "تم",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                }).then(() => {
                  localStorage.setItem("changeseat", false);
                  localStorage.setItem("ReservationChange", false);
              
                });
                // eslint-disable-next-line eqeqeq
              }
             
            }
          } else if (idDirect != TripID.id) {
          
            // if (reservationseat == selectedSeats) {

            // Seat not found in transit trip
            const status = reservationseat1[seatNumbersToSend[0]];
           

            // eslint-disable-next-line eqeqeq
            if (status == 5 || status == 0) {
              setOpenPopup(true);
              localStorage.setItem("ReservationPhone", true);
              // eslint-disable-next-line eqeqeq
            } 
            else if (status == undefined || status == '') {
              setResultArray([]);
              sweetalert({
                text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
              });
            }
            else if (status == 4 || status == 3 || status == 2 || status == 1) {
              setResultArray([]);
              localStorage.setItem("changeseat", true);
              sweetalert({
                text: "لا يمكن القيام بهذه العملية",
                icon: "error",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              }).then(() => {
                localStorage.setItem("changeseat", false);
                localStorage.setItem("ReservationChange", false);
            
              });
              // eslint-disable-next-line eqeqeq
            }
           
          
          }
        
      }
      if (
        localStorage.getItem("openpassenger") === "false" &&
        localStorage.getItem("changeseat") === "false" &&
        localStorage.getItem("ReservationPhone") == "false"
      ) {
        if (event.key === "i") {
          setShowSystemUserReservation(true);
          dispatch(fetchTripBYId(TripID.id));
          let allReservations = [...TripID.reservations];
          if (TripID.tripTransit) {
            TripID.tripTransit.forEach((transit) => {
              if (transit.reservation) {
                allReservations = [...allReservations, ...transit.reservation];
              }
            });
          }
          const mappedById = allReservations.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {});

          setReservations(mappedById);
        }
        if (event.key === "o") {
          setShowSystemUserReservation(false);
        }
      }

      // ================================================================

      if (
        isCodeUnlocked === false &&
        (event.key === key_Shortcut.lock_key_tansit_key ||
          event.key === key_Shortcut.lock_key_tansit_key1)
      ) {
        setIsCodeUnlocked(true);
        return; // Don't execute the rest of the code
      }

      if (isCodeUnlocked) {
        if (TripID.tripTransit.length > 0) {
          if (event.key === key_Shortcut.get_transit_1) {
            setColor(color === "rgb(168, 168, 168)" ? "#82c40e" : "#82c40e");
            setClick(true);
            setIDdirect("");
            await axios
              .get(`${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`, {
                withCredentials: true,
              })
              .then((response) => {
                if (response.status === 200) {
                  setPathTransit(response.data.data.tripTransit[0].path);
                  setPathtrip(response.data.data.tripTransit[0].path);
                  setIDTransit(response.data.data.tripTransit[0].id);
                  setIdTransit1(response.data.data.tripTransit[0].id);
                  setIDtrip(response.data.data.tripTransit[0].id);
                  setReservationseat(response.data.data.tripTransit[0]);
                  setIDTripType(response.data.data.tripTransit[0].id_trip_type);
                  setReservationseat1(
                    response.data.data.tripTransit[0].reservationSeats
                  );
                  setidTriptran(response.data.data.tripTransit[0].id);
                  localStorage.setItem(
                    "idtransit",
                    response.data.data.tripTransit[0].id
                  );
                  const mappedById =
                    response.data.data.tripTransit[0]?.reservation.reduce(
                      (acc, item) => {
                        acc[item.id] = item;
                        return acc;
                      },
                      {}
                    );
                  setArrayReservations(mappedById);
                  setCommonSeats(
                    response.data.data.seats.seats
                      .map((seat) => parseInt(seat))
                      .filter((seat) =>
                        response.data.data.tripTransit
                          .flatMap((e) =>
                            Object.keys(e.reservationSeats).map(Number)
                          )
                          .includes(seat)
                      )
                  );
                  const transit = response.data.data.tripTransit[0];
                  localStorage.setItem("idtransit", transit.id); // Store in local storage
                  let seatWithPassengerTransit1 = [];
                  const newTransitSelectedSeats = [];
                  // Safely access the reservationSeats property
                  seatWithPassengerTransit1 = Object.keys(
                    response.data.data.tripTransit[0].reservationSeats
                  ).reduce((accumulator, currentKey) => {
                    // Assuming you want to do something with the keys here
                    const reservationInfo =
                      response.data.data.tripTransit[0]?.reservation.find(
                        (reservation) =>
                          reservation.reservation_seats_number &&
                          reservation.reservation_seats_number[currentKey] > 0
                      );

                    const passenger = reservationInfo
                      ? reservationInfo.passengers?.find(
                          (p) => p.number_seat == currentKey
                        )
                      : null;

                    accumulator[currentKey] = {
                      passengers: passenger
                        ? Object.assign({}, passenger)
                        : null, // safely copy passenger object
                      status:
                        reservationInfo?.reservation_seats_number[currentKey],
                      id_reservation: reservationInfo?.id,
                    };

                    return accumulator;
                  }, {});
                  const exampleData = {
                    ...seatWithPassengerTransit1, // This is your existing seat data
                    id: response.data.data.tripTransit[0].id, // Ensure you are actually setting the id like this
                  };
                  setArraySeatPassengerTransit1(exampleData);
                  Object.keys(transit.reservationSeats).forEach(
                    (seatNumber) => {
                      const transitSeatStatus =
                        transit.reservationSeats[seatNumber];

                      if (transitSeatStatus === 5) {
                        const seatDetails = transit.seatStatusAuth[seatNumber];

                        if (seatDetails && seatDetails.numStatus === 5) {
                          newTransitSelectedSeats.push({
                            seat_number: parseInt(seatNumber),
                            id_system: seatDetails.id_system,
                            numStatus: seatDetails.numStatus,
                            status: "Selected",
                            id_client: 0,
                            transitStatus: null,
                            id_transit: response.data.data.tripTransit[0]?.id,
                            trip_id: response.data.data.id,
                          });
                        }
                      }
                    }
                  );

                  const currentUserReservedSeats =
                    newTransitSelectedSeats.filter(
                      (userSeat) =>
                        userSeat.id_system === user.user_id &&
                        userSeat.numStatus == 5
                    );

                  const currentUserReservedSeatNumbers =
                    currentUserReservedSeats.map((seat) => seat.seat_number);
                  setSelectedSeats(currentUserReservedSeatNumbers);
                  setSeatAndUser(newTransitSelectedSeats);
                  setSelected(newTransitSelectedSeats);
                  setResultArrayseatuser(newTransitSelectedSeats);
                  const sortedCombinedSeats =
                    currentUserReservedSeatNumbers.sort((a, b) => a - b);
                  setValueinput(currentUserReservedSeatNumbers.join("+"));
                }
              });

            setSelectedTransitIndex(0);

            setShowDirectTrip(true);
            settransetnumber(1);
          } else if (event.key === key_Shortcut.get_transit_2) {
            if (TripID.tripTransit.length >= 2) {
              await axios
                .get(`${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`, {
                  withCredentials: true,
                })
                .then((response) => {
                  if (response.status === 200) {
                    const newTransitSelectedSeats = [];
                    const transit = response.data.data.tripTransit[1];
                    Object.keys(transit.reservationSeats).forEach(
                      (seatNumber) => {
                        const transitSeatStatus =
                          transit.reservationSeats[seatNumber];
                        if (transitSeatStatus === 5) {
                          const seatDetails =
                            transit.seatStatusAuth[seatNumber];
                          if (seatDetails && seatDetails.numStatus === 5) {
                            newTransitSelectedSeats.push({
                              seat_number: parseInt(seatNumber),
                              id_system: seatDetails.id_system,
                              numStatus: seatDetails.numStatus,
                              status: "Selected",
                              id_client: 0,
                              transitStatus: null,
                              id_transit: response.data.data.tripTransit[1]?.id,
                              trip_id: response.data.data.id,
                            });
                          }
                        }
                      }
                    );
                    const currentUserReservedSeats =
                      newTransitSelectedSeats.filter(
                        (userSeat) =>
                          userSeat.id_system === user.user_id &&
                          userSeat.numStatus == 5
                      );

                    const currentUserReservedSeatNumbers =
                      currentUserReservedSeats.map((seat) => seat.seat_number);

                    setSelectedSeats(currentUserReservedSeatNumbers);
                    setSeatAndUser(newTransitSelectedSeats);
                    setSelected(newTransitSelectedSeats);
                    setResultArrayseatuser(newTransitSelectedSeats);
                    const sortedCombinedSeats =
                      currentUserReservedSeatNumbers.sort((a, b) => a - b);
                    setValueinput(currentUserReservedSeatNumbers.join("+"));
                    setPathTransit(response.data.data.tripTransit[1].path);
                    setPathtrip(response.data.data.tripTransit[1].path);
                    dispatch(fetchTripBYId(response.data.data.id));
                    setIDTransit(response.data.data.tripTransit[1].id);
                    setIdTransit2(response.data.data.tripTransit[1].id);
                    setIDtrip(response.data.data.tripTransit[1].id);
                    const mappedById =
                      response.data.data.tripTransit[1]?.reservation.reduce(
                        (acc, item) => {
                          acc[item.id] = item;
                          return acc;
                        },
                        {}
                      );
                    setArrayReservations(mappedById);

                    let seatWithPassengerTransit2 = [];
                    // Safely access the reservationSeats property
                    seatWithPassengerTransit2 = Object.keys(
                      response.data.data.tripTransit[1].reservationSeats
                    ).reduce((accumulator, currentKey) => {
                      // Assuming you want to do something with the keys here
                      const reservationInfo =
                        response.data.data.tripTransit[1]?.reservation.find(
                          (reservation) =>
                            reservation.reservation_seats_number &&
                            reservation.reservation_seats_number[currentKey] > 0
                        );

                      const passenger = reservationInfo
                        ? reservationInfo.passengers?.find(
                            (p) => p.number_seat == currentKey
                          )
                        : null;

                      accumulator[currentKey] = {
                        passengers: passenger
                          ? Object.assign({}, passenger)
                          : null, // safely copy passenger object
                        status:
                          reservationInfo?.reservation_seats_number[currentKey],
                        id_reservation: reservationInfo?.id,
                      };

                      return accumulator;
                    }, {});
                  
                    const exampleData = {
                      ...seatWithPassengerTransit2, // This is your existing seat data
                      id: response.data.data.tripTransit[1].id, // Ensure you are actually setting the id like this
                    };
                    setArraySeatPassengerTransit2(exampleData);

                    setIDdirect();
                    settranset(true);
                    setDirect(false);
                    localStorage.setItem("isdirect", false);
                    setReservationseat(response.data.data.tripTransit[1]);
                    setIDTripType(
                      response.data.data.tripTransit[1].id_trip_type
                    );

                    setReservationseat1(
                      response.data.data.tripTransit[1].reservationSeats
                    );
                    setShowDirectTrip(true);
                    setColor(color === "#82c40e" ? "orange" : "orange");
                    setClick(true);
                    setidTriptran(response.data.data.tripTransit[1].id);
                    settransetnumber(2);
                    localStorage.setItem(
                      "idtransit",
                      response.data.data.tripTransit[1].id
                    );
                    setCommonSeats(
                      response.data.data.seats.seats
                        .map((seat) => parseInt(seat))
                        .filter((seat) =>
                          response.data.data.tripTransit
                            .flatMap((e) =>
                              Object.keys(e.reservationSeats).map(Number)
                            )
                            .includes(seat)
                        )
                    );
                    localStorage.setItem("idtransit", transit.id); // Store in local storage
                  }
                });

              setIDdirect("");
              localStorage.setItem("isdirect", false);
              setSelectedTransitIndex(1);
              setShowDirectTrip(true);
              setColor(color === "#82c40e" ? "orange" : "orange");
              setClick(true);
              settransetnumber(2);
            } else {
              sweetalert({
                text: "تم عرض جميع رحلات العبور",
                icon: "success",
                buttons: {
                  confirm: {
                    text: "تم",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              });
            }
          } else if (event.key === key_Shortcut.get_direct_trip) {
            setColor("rgb(168, 168, 168)");
            setClick(false);
            setIDTransit(null);
            setShowDirectTrip(false);
            setSelectedTransitIndex("");
            await axios
              .get(`${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`, {
                withCredentials: true,
              })
              .then((response) => {
                if (response.status === 200) {
                  const mappedById = response.data.data.reservations.reduce(
                    (acc, item) => {
                      acc[item.id] = item;
                      return acc;
                    },
                    {}
                  );
                  setReservationseat1(
                    response.data.data.tripTransit[0].reservationSeats
                  );
                  setResponse1(response.data.data.reservation_seats);
                  setArrayReservations(mappedById);
                  let passenger;
                  let seatWithPassengerNull = Object.keys(
                    response.data.data.reservation_seats
                  ).reduce((accumulator, currentKey) => {
                    const reservationInfo =
                      response.data.data.reservations.find(
                        (reservation) =>
                          reservation.reservation_seats_number[currentKey] > 0
                      );
                    passenger = reservationInfo
                      ? reservationInfo.passengers?.find(
                          (p) => p.number_seat == currentKey
                        )
                      : null;

                    accumulator[currentKey] = {
                      passengers: Object(passenger),
                      status:
                        reservationInfo?.reservation_seats_number[currentKey],
                      id_reservation: reservationInfo?.id,
                    };
                    return accumulator;
                  }, {});

                  setArraySeat(seatWithPassengerNull);
                  setSelected(Object.values(response.data.data.seatStatusAuth));
                  const newDirectSelectedSeats = [];
                  const transitSeatNumbers = new Set();
                  setDirect(true);
                  settranset(false);
                  if (
                    response.data.data.tripTransit &&
                    response.data.data.tripTransit.length > 0
                  ) {
                    response.data.data.tripTransit.forEach((transit) => {
                      Object.keys(transit.reservationSeats).forEach(
                        (seatNumber) => {
                          transitSeatNumbers.add(seatNumber);
                        }
                      );
                    });
                  }
                  Object.keys(response.data.data.seatStatusAuth).forEach(
                    (seatNumber) => {
                      const seatDetails =
                        response.data.data.seatStatusAuth[seatNumber];
                      if (
                        seatDetails.numStatus === 5 &&
                        response.data.data.reservation_seats[seatNumber] !==
                          undefined &&
                        !transitSeatNumbers.has(seatNumber)
                      ) {
                        newDirectSelectedSeats.push({
                          seat_number: parseInt(seatNumber),
                          id_system: seatDetails.id_system,
                          numStatus: seatDetails.numStatus,
                          id_client: 0,
                          status: "Selected",
                          transitStatus: null,
                          id_transit: null,
                          trip_id: response.data.data.id,
                        });
                      }
                    }
                  );
                  const currentUserReservedSeats =
                    newDirectSelectedSeats.filter(
                      (userSeat) =>
                        userSeat.id_system === user.user_id &&
                        userSeat.numStatus == 5
                    );

                  const currentUserReservedSeatNumbers =
                    currentUserReservedSeats.map((seat) => seat.seat_number);

                  setSelected(newDirectSelectedSeats);
                  const patth = `${response.data.data.path_from} - ${response.data.data.path_to}`;
                  setPathtrip(patth);
                  setIDdirect(response.data.data.id);
                  setIDtrip(response.data.data.id);
                  setIDTripType(response.data.data.id_trip_type);
                  setidTriptran(response.data.data.id);
                  setCommonSeats(
                    response.data.data.seats.seats
                      .map((seat) => parseInt(seat))
                      .filter((seat) =>
                        response.data.data.tripTransit
                          .flatMap((e) =>
                            Object.keys(e.reservationSeats).map(Number)
                          )
                          .includes(seat)
                      )
                  );
                  setIDTripType(response.data.data.id_trip_type);
                }
              });

            localStorage.setItem("isdirect", true);
            setIDTransit("");
            settransetnumber();
          } else if (event.key === key_Shortcut.lock_key_tansit_key) {
            setIsCodeUnlocked(false);
          }
        }
      }
    }
  };
  // reservationseat1
  useEffect(() => {
    const patth = `${TripID.path_from} - ${TripID.path_to}`;
    setPathtrip(patth);
  }, []);
  useEffect(() => {}, [setSelected, select, select1]);
  useEffect(() => {}, [transet, direct, Clic]);
  const [transitSelectedSeats, setTransitSelectedSeats] = useState([]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const { SetsBus } = useSelector((state) => state.Setsbus);

  if (
    arrayseat &&
    arrayseat[1] &&
    Object.keys(arrayseat[1].passengers).length > 0
  ) {
  } else {
  }
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (TripID && TripID.seats) {
        setNumCols(TripID.seats.num_col); 
        setTrip_seat_status(TripID.seats.seats); 
        if (TripID.seats.seats && numCols) {
          const newSeatsByCols = [];
          for (let i = 0; i < TripID.seats.seats.length; i += numCols) {
            const col = TripID.seats.seats.slice(i, i + numCols);
            newSeatsByCols.push(col);
          }
          setSeatsByCols(newSeatsByCols);
        }
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [TripID?.id, TripID?.seats?.num_col, TripID?.seats?.seats, numCols,TripID]);


  useEffect(() => {
    const newTransitSelectedSeats = [];
    const newDirectSelectedSeats = [];
    const transitSeatNumbers = new Set();

    if (TripID.id == idDirect) {
      setDirect(true);
      settranset(false);
      if (TripID.tripTransit && TripID.tripTransit.length > 0) {
        TripID.tripTransit.forEach((transit) => {
          Object.keys(transit.reservationSeats).forEach((seatNumber) => {
            transitSeatNumbers.add(seatNumber);
          });
        });
      }
      const reservationSeats = TripID?.reservation_seats;
      const seatStatusAuth = TripID?.seatStatusAuth;
      Object.keys(TripID?.reservation_seats).forEach((seatNumber) => {
        const status = reservationSeats[seatNumber];
        if (status === 5 && !seatStatusAuth[seatNumber]) {
          newDirectSelectedSeats.push({
            seat_number: parseInt(seatNumber),
            id_system: null,
            numStatus: status,
            id_client: 0,
            status: "Selected",
            transitStatus: null,
            id_transit: null,
            trip_id: TripID.id,
          });
          // Perform your action here
        }
      });
      Object.keys(TripID.seatStatusAuth).forEach((seatNumber) => {
        const seatDetails = TripID.seatStatusAuth[seatNumber];

        if (
          seatDetails.numStatus === 5 &&
          TripID.reservation_seats[seatNumber] !== undefined &&
          !transitSeatNumbers.has(seatNumber)
        ) {
          newDirectSelectedSeats.push({
            seat_number: parseInt(seatNumber),
            id_system: seatDetails.id_system,
            numStatus: seatDetails.numStatus,
            id_client: 0,
            status: "Selected",
            transitStatus: null,
            id_transit: null,
            trip_id: TripID.id,
          });
        }
      });
    
      setResponse1(TripID?.reservation_seats);
      if (TripID && TripID.seats !== null) {
        setNumCols(TripID.seats.num_col);
        setSeatsArray1(TripID.seats.seats.map((seat) => parseInt(seat)));
        setSeatsArray2(
          TripID.tripTransit.flatMap((e) =>
            Object.keys(e.reservationSeats).map(Number)
          )
        );
      }
      setCommonSeats(
        TripID.seats.seats
          .map((seat) => parseInt(seat))
          .filter((seat) =>
            TripID.tripTransit
              .flatMap((e) => Object.keys(e.reservationSeats).map(Number))
              .includes(seat)
          )
      );

      // direct trip
      let seatwithPassenger = TripID.reservation_seats;
      let passenger;
      let seatWithPassengerNull = Object.keys(TripID.reservation_seats).reduce(
        (accumulator, currentKey) => {
          const reservationInfo = TripID.reservations.find(
            (reservation) =>
              reservation.reservation_seats_number[currentKey] > 0
          );
          passenger = reservationInfo
            ? reservationInfo.passengers?.find(
                (p) => p.number_seat == currentKey
              )
            : null;

          accumulator[currentKey] = {
            passengers: Object(passenger),
            status: reservationInfo?.reservation_seats_number[currentKey],
            id_reservation: reservationInfo?.id,
          };
          return accumulator;
        },
        {}
      );

      setArraySeat(seatWithPassengerNull);

      // Transit 1
      let seatWithPassengerTransit1 = [];

      if (
        TripID.tripTransit &&
        TripID.tripTransit[0] &&
        TripID.tripTransit[0]?.reservationSeats
      ) {
        // Safely access the reservationSeats property
        seatWithPassengerTransit1 = Object.keys(
          TripID.tripTransit[0].reservationSeats
        ).reduce((accumulator, currentKey) => {
          // Assuming you want to do something with the keys here
          const reservationInfo = TripID.tripTransit[0].reservation.find(
            (reservation) =>
              reservation.reservation_seats_number &&
              reservation.reservation_seats_number[currentKey] > 0
          );

          const passenger = reservationInfo
            ? reservationInfo.passengers?.find(
                (p) => p.number_seat == currentKey
              )
            : null;

          accumulator[currentKey] = {
            passengers: passenger ? Object.assign({}, passenger) : null, // safely copy passenger object
            status: reservationInfo?.reservation_seats_number[currentKey],
            id_reservation: reservationInfo?.id,
          };

          return accumulator;
        }, {});
      
        const exampleData = {
          ...seatWithPassengerTransit1, // This is your existing seat data
          id: TripID.tripTransit[0].id, // Ensure you are actually setting the id like this
        };
        setArraySeatPassengerTransit1(exampleData);
      }

      // Transit 2

      let seatWithPassengerTransit2 = [];

      if (
        TripID.tripTransit &&
        TripID.tripTransit[1] &&
        TripID.tripTransit[1].reservationSeats
      ) {
        // Safely access the reservationSeats property
        seatWithPassengerTransit2 = Object.keys(
          TripID.tripTransit[1].reservationSeats
        ).reduce((accumulator, currentKey) => {
          // Assuming you want to do something with the keys here
          const reservationInfo = TripID.tripTransit[1].reservation.find(
            (reservation) =>
              reservation.reservation_seats_number &&
              reservation.reservation_seats_number[currentKey] > 0
          );

          const passenger = reservationInfo
            ? reservationInfo.passengers?.find(
                (p) => p.number_seat == currentKey
              )
            : null;

          accumulator[currentKey] = {
            passengers: passenger ? Object.assign({}, passenger) : null, // safely copy passenger object
            status: reservationInfo?.reservation_seats_number[currentKey],
            id_reservation: reservationInfo?.id,
          };

          return accumulator;
        }, {});
        setInitialIdTransit(TripID.tripTransit[1]?.id);
        const exampleData1 = {
          ...seatWithPassengerTransit2, // This is your existing seat data
          id: TripID.tripTransit[1]?.id, // Ensure you are actually setting the id like this
        };
        setArraySeatPassengerTransit2(exampleData1);
      }
     
      let allReservations = [...TripID.reservations];
      if (TripID.tripTransit) {
        TripID.tripTransit.forEach((transit) => {
          if (transit.reservation) {
            allReservations = [...allReservations, ...transit.reservation];
          }
        });
      }
      const mappedById = allReservations.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      setArrayReservations(mappedById);

      const currentUserReservedSeats = newDirectSelectedSeats.filter(
        (userSeat) =>
          userSeat.id_system === user.user_id && userSeat.numStatus == 5
      );

      const currentUserReservedSeatNumbers = currentUserReservedSeats.map(
        (seat) => seat.seat_number
      );
      setSelectedSeats(currentUserReservedSeatNumbers);
      setSeatAndUser(newDirectSelectedSeats);
      setSelected(newDirectSelectedSeats);
      setResultArrayseatuser(newDirectSelectedSeats);
      const sortedCombinedSeats = currentUserReservedSeatNumbers.sort(
        (a, b) => a - b
      );
      setValueinput(sortedCombinedSeats.join("+"));
    }
  }, [TripID]);


  const fetchReservationStatus = () => {};
  useEffect(() => {
    const interval = setInterval(fetchReservationStatus, 2000);
    return () => clearInterval(interval);
  }, [id_trip, TripID]);
 

  useEffect(() => {}, [reservationIds, selectedSeats, seatAndUser]);

  const handleNewSeatInfo = (newSeat) => {
    setSelected((currentSelect) => {
      // Create a new array for immutability
      const updatedSelect = Array.isArray(currentSelect)
        ? [...currentSelect]
        : [];

      // Find the index of the seat, if it exists
      const seatIndex = updatedSelect.findIndex(
        (s) => s.seat_number === newSeat.seat_number
      );

      if (seatIndex !== -1) {
        // Update the existing seat info
        updatedSelect[seatIndex] = newSeat;
      } else {
        // Add the new seat info
        updatedSelect.push(newSeat);
      }

      // Return the new state
      return updatedSelect;
    });
  };

  useEffect(() => {}, [arrayseatPassengerTransit1]);
  useEffect(() => {}, [arrayseatPassengerTransit2]);
  useEffect(() => {}, [arrayseatPassengerTransit2]);
  let eventSeat = response1;
  let eventSeatTranset = reservationseat1;
  const transit1Ref = useRef(arrayseatPassengerTransit1);
  const transit2Ref = useRef(arrayseatPassengerTransit2);

  // تحديث المراجع مع أحدث القيم
  useEffect(() => {
    transit1Ref.current = arrayseatPassengerTransit1;
    transit2Ref.current = arrayseatPassengerTransit2;
  }, [arrayseatPassengerTransit1, arrayseatPassengerTransit2]);
  ////////////////////////////
  useEffect(() => {
    
    const echo = new Echo({
      // broadcaster: "socket.io",
      // client: socketIOClient,
      // host: window.location.hostname + ":6100",
      // authEndpoint: window.location.hostname + "/broadcasting/auth",
      // rejectUnauthorized: false,
      broadcaster: "socket.io",
      client: socketIOClient,
      host: "http://191.101.0.140:6100",
      authEndpoint: "http://191.101.0.140:6100/broadcasting/auth",
      rejectUnauthorized: false,
    });
    const handleTripEvent = (e) => {
      // console.log(e, "trip");
     
      if (e.eventName === "trips" && e.passenger != null && e.status =='Selected') {
        setArrayReservations((prevReservations) => {
          // Deep clone the previous reservations to avoid direct state mutation
          const updatedReservations = JSON.parse(
            JSON.stringify(prevReservations)
          );

          // Ensure the reservation exists
          const reservation = updatedReservations[e.id_reservation];
          if (reservation) {
            // Check if the passenger array is not null and properly formatted as an array
            {
              const existingPassengerIndex = reservation.passengers.findIndex(
                (p) => p.id === e.passenger.id
              );

              if (existingPassengerIndex >= 0) {
                reservation.passengers[existingPassengerIndex] = {
                  ...e.passenger,
                };
              } else {
                // Add new passenger directly as an object, no extra nesting
                reservation.passengers.push(e.passenger);
              }
            }
            if (reservation.reservation_seats_number.hasOwnProperty(e.seat_number)) {
              // This check ensures that you only modify the status of seats that exist in the reservation
              reservation.reservation_seats_number[e.seat_number] = 2; // Set the status to 2 for selected
            }
      
          }

          return updatedReservations;
        });
        setResponse1((currentResponse1) => {
          const updatedResponse1 = { ...currentResponse1 };
          if (updatedResponse1.hasOwnProperty(e.seat_number)) {
            updatedResponse1[e.seat_number] = e.numStatus;
            return updatedResponse1;
          }
          return currentResponse1;
        });

        setArraySeat((prevSeatsInfo) => {
          const updatedSeatsInfo = { ...prevSeatsInfo };
          const seatNumber = parseInt(e.seat_number);

          if (!isNaN(seatNumber)) {
            // Check if e.passenger is an array and access the first element directly
            const passengerInfo = Array.isArray(e.passenger)
              ? e.passenger[0]
              : e.passenger;

            updatedSeatsInfo[seatNumber] = {
              passengers: passengerInfo, // Ensuring this is directly an object
              status: 2, // Default status or derive from existing data
              id_reservation: e.id_reservation, // Assuming this is directly usable
            };
          }
       
          return updatedSeatsInfo;
        });
   
      }
      if (
        e.eventName === "trips" &&
        e.passenger == null &&
        e.reservation != null
      ) {
        setResponse1((currentResponse1) => {
          const updatedResponse1 = { ...currentResponse1 };
          if (updatedResponse1.hasOwnProperty(e.seat_number)) {
            updatedResponse1[e.seat_number] = e.numStatus;
            return updatedResponse1;
          }
          return currentResponse1;
        });

        setArraySeat((prevSeatsInfo) => {
          const updatedSeatsInfo = { ...prevSeatsInfo };
          const seatNumber = parseInt(e.seat_number);

          if (!isNaN(seatNumber)) {
            updatedSeatsInfo[seatNumber] = {
              passengers: {}, // Ensuring this is directly an object
              status: 1, // Default status or derive from existing data
              id_reservation: e.id_reservation, // Assuming this is directly usable
            };
          }

          return updatedSeatsInfo;
        });
      }
      if (
        e.eventName === "trips" &&
        e.reservation != null &&
        e.passenger != null
      ) {
        if (
          e.trip_id == idDirect &&
          e.id_transit == null &&
          e.passenger != null
        ) {
          setArraySeat((prevSeatsInfo) => {
            const updatedSeatsInfo = { ...prevSeatsInfo };
            const seatNumber = parseInt(e.seat_number);

            if (!isNaN(seatNumber)) {
              // Check if e.passenger is an array and access the first element directly
              const passengerInfo = Array.isArray(e.passenger)
                ? e.passenger[0]
                : e.passenger;

              updatedSeatsInfo[seatNumber] = {
                passengers: passengerInfo, // Ensuring this is directly an object
                status: 2, // Default status or derive from existing data
                id_reservation: e.id_reservation, // Assuming this is directly usable
              };
            }

            return updatedSeatsInfo;
          });
          setArrayReservations((prevReservations) => {
            // Deep clone the previous reservations to avoid direct state mutation
            const updatedReservations = JSON.parse(
              JSON.stringify(prevReservations)
            );

            // Ensure the reservation exists
            const reservation = updatedReservations[e.id_reservation];
            if (reservation) {
              // Check if the passenger array is not null and properly formatted as an array
              {
                const existingPassengerIndex = reservation.passengers.findIndex(
                  (p) => p.id === e.passenger.id
                );

                if (existingPassengerIndex >= 0) {
                  reservation.passengers[existingPassengerIndex] = {
                    ...e.passenger,
                  };
                } else {
                  // Add new passenger directly as an object, no extra nesting
                  reservation.passengers.push(e.passenger);
                }
              }

              // Update seat status to 2 if not already
              Object.keys(reservation.reservation_seats_number).forEach(
                (seatNumber) => {
                  if (reservation.reservation_seats_number[seatNumber] !== 2) {
                    reservation.reservation_seats_number[seatNumber] = 2;
                  }
                }
              );
            }

            return updatedReservations;
          });

  
        }
      }
      if (
        e.eventName === "trips" &&
        e.passenger == null &&
        e.status == "Deleted" &&
        e.transitStatus == null
      ) {
        setResponse1((currentResponse1) => {
          const updatedResponse1 = { ...currentResponse1 };
          if (updatedResponse1.hasOwnProperty(e.seat_number)) {
            updatedResponse1[e.seat_number] = e.numStatus;
            return updatedResponse1;
          }
          return currentResponse1;
        });

        setArraySeat((prevSeatsInfo) => {
          const updatedSeatsInfo = { ...prevSeatsInfo };
          const seatNumber = parseInt(e.seat_number);

          if (!isNaN(seatNumber)) {
            updatedSeatsInfo[seatNumber] = {
              passengers: {}, // Directly using the object
              status: 0,
              id_reservation: undefined,
            };
          }
      
          return updatedSeatsInfo;
        });

        setArrayReservations((prevReservations) => {
          const updatedReservations = JSON.parse(
            JSON.stringify(prevReservations)
          );

          // Find reservations by trip_id and update seat numbers
          Object.keys(updatedReservations).forEach((reservationId) => {
            const reservation = updatedReservations[reservationId];
            if (reservation.trip_id === e.trip_id) {
  
              if (
                reservation.reservation_seats_number.hasOwnProperty(
                  e.seat_number
                )
              ) {
                delete reservation.reservation_seats_number[e.seat_number];
                
              }
              const passengerIndex = reservation.passengers.findIndex(
                (passenger) => passenger.number_seat == e.seat_number
              );
              if (passengerIndex !== -1) {
                reservation.passengers.splice(passengerIndex, 1);
              }


              if (
                Object.keys(reservation.reservation_seats_number).length === 0
              ) {
              }
            }
          });

          return updatedReservations;
        });
      }
      if (e.eventName == "trips" && e.transitStatus == 1) {
        setResponse1((currentResponse1) => {
          const updatedResponse1 = { ...currentResponse1 };
          if (updatedResponse1.hasOwnProperty(e.seat_number)) {
            updatedResponse1[e.seat_number] = e.numStatus;
            return updatedResponse1;
          }
          return currentResponse1;
        });
      }
      if (
        e.eventName == "trip_transits" &&
        e.transitStatus == null &&
        e.id_transit == idTransit
      ) {
        setReservationseat1((currentResponse2) => {
          const updatedResponse2 = { ...currentResponse2 };
          if (updatedResponse2.hasOwnProperty(e.seat_number)) {
            updatedResponse2[e.seat_number] = e.numStatus;
            return updatedResponse2;
          }
          return currentResponse2;
        });
      }

      if (
        e.eventName == "trips" &&
        e.transitStatus == "transitDeleted" &&
        e.status == "Deleted"
      ) {
        setResponse1((currentResponse1) => {
          const updatedResponse1 = { ...currentResponse1 };
          if (updatedResponse1.hasOwnProperty(e.seat_number)) {
            updatedResponse1[e.seat_number] = e.numStatus;
            return updatedResponse1;
          }
          return currentResponse1;
        });
      }
      if (e.eventName == "trips" && e.status == "Selected") {
        setResponse1((currentResponse1) => {
          const updatedResponse1 = { ...currentResponse1 };
          if (updatedResponse1.hasOwnProperty(e.seat_number)) {
            updatedResponse1[e.seat_number] = e.numStatus;
            return updatedResponse1;
          }
          return currentResponse1;
        });
      }

      const pendingSeats =
        JSON.parse(localStorage.getItem("seatpinding")) || [];

      const newSeat = {
        trip_id: e.trip_id,
        transitStatus: e.transitStatus,
        seat_number: e.seat_number,
        status: e.status,
        numStatus: e.numStatus,
        id_client: e.id_client,
        id_system: e.id_system,
        id_transit: e.id_transit,
      };

      const seatIndex = pendingSeats.findIndex(
        (s) => s.seat_number === newSeat.seat_number
      );

      if (seatIndex === -1) {
        pendingSeats.push(newSeat);
      } else {
        pendingSeats[seatIndex] = newSeat;
      }

      localStorage.setItem("seatpinding", JSON.stringify(pendingSeats));
      setSelected((currentSelect) => {
        if (!Array.isArray(currentSelect)) {
          return [newSeat];
        }

        const existingIndex = currentSelect.findIndex(
          (s) => s.seat_number === newSeat.seat_number
        );

        if (existingIndex !== -1) {
          const updatedSelect = [...currentSelect];
          updatedSelect[existingIndex] = newSeat;
          return updatedSelect;
        } else {
          return [...currentSelect, newSeat];
        }
      });
      setSelected1(e.status);
      // ... rest of your existing code to update local state 
    };

    const handleReservationEvent = (e) => {
      // Handle reservation event
              // console.log(e, "reservation");
        
              const updatedResponse1 = { ...eventSeat };
              const updatedResponse2 = { ...eventSeatTranset };
        
             
              if (
                e.reservation != null &&
                e.passenger == null &&
                e.status == "Selected"
              ) {
               
                if (
                  e.reservation != null &&
                  e.passenger == null &&
                  e.status == "Selected" &&
                  e.id_transit == null &&
                  e.numStatus == 1
                ) {
                  setResponse1((currentResponse1) => {
                    const updatedResponse1 = { ...currentResponse1 };
                    if (updatedResponse1.hasOwnProperty(e.seat_number)) {
                      updatedResponse1[e.seat_number] = e.numStatus;
                      return updatedResponse1;
                    }
                    return currentResponse1;
                  });
                  setArrayReservations((prevReservations) => {
                    // Create a new object to ensure immutability
                    const updatedReservations = { ...prevReservations };
        
                    // Add or update the reservation by id_reservation
                    if (!updatedReservations[e.id_reservation]) {
                      updatedReservations[e.id_reservation] = {
                        ...e.reservation,
                        passengers: [],
                      };
                    } else {
                      // Optionally update some properties if the reservation already exists
                      updatedReservations[e.id_reservation] = {
                        ...updatedReservations[e.id_reservation],
                        ...e.reservation, // assuming you want to merge or update details
                        passengers: [],
                      };
                    }
        
                    return updatedReservations; // Return the updated reservations object
                  });
                  setArraySeat((prevSeatsInfo) => {
                    const updatedSeatsInfo = { ...prevSeatsInfo };
                    const seatNumber = parseInt(e.seat_number);
        
                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: {}, // Ensuring this is directly an object
                        status: 1, // Default status or derive from existing data
                        id_reservation: e.id_reservation, // Assuming this is directly usable
                      };
                    }
        
                    return updatedSeatsInfo;
                  });
                }
                if (
                  e.reservation != null &&
                  e.passenger == null &&
                  e.status == "Selected" &&
                  e.id_transit === transit1Ref.current.id &&
                  e.numStatus == 1
                ) {
                  setReservationseat1((currentResponse1) => {
                    const updatedResponse1 = { ...currentResponse1 };
                    if (updatedResponse1.hasOwnProperty(e.seat_number)) {
                      updatedResponse1[e.seat_number] = e.numStatus;
                      return updatedResponse1;
                    }
                    return currentResponse1;
                  });
        
                  setArraySeatPassengerTransit1((prevSeatsInfo) => {
                    const updatedSeatsInfo = { ...prevSeatsInfo };
                    const seatNumber = parseInt(e.seat_number);
        
                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: null, // Directly using the object
                        id_reservation: e.id_reservation, // Directly using the object
                        status: 1, // Default status or derive from existing data
                      };
                    }
        
                    return updatedSeatsInfo;
                  });
                  setArrayReservations((prevReservations) => {
                    // Create a new object to ensure immutability
                    const updatedReservations = { ...prevReservations };
        
                    // Add or update the reservation by id_reservation
                    if (!updatedReservations[e.id_reservation]) {
                      updatedReservations[e.id_reservation] = {
                        ...e.reservation,
                        passengers: [],
                      };
                    } else {
                      // Optionally update some properties if the reservation already exists
                      updatedReservations[e.id_reservation] = {
                        ...updatedReservations[e.id_reservation],
                        ...e.reservation, // assuming you want to merge or update details
                        passengers: [],
                      };
                    }
        
                    return updatedReservations; // Return the updated reservations object
                  });
               
                }
                if (
                  e.reservation != null &&
                  e.passenger == null &&
                  e.status == "Selected" &&
                  e.id_transit === transit2Ref.current.id &&
                  e.numStatus == 1
                ) {
                  setReservationseat1((currentResponse1) => {
                    const updatedResponse1 = { ...currentResponse1 };
                    if (updatedResponse1.hasOwnProperty(e.seat_number)) {
                      updatedResponse1[e.seat_number] = e.numStatus;
                      return updatedResponse1;
                    }
                    return currentResponse1;
                  });
        
                  setArraySeatPassengerTransit2((prevSeatsInfo) => {
                    const updatedSeatsInfo = { ...prevSeatsInfo };
                    const seatNumber = parseInt(e.seat_number);
        
                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: e.passenger, // Directly using the object
                        id_reservation: e.id_reservation, // Directly using the object
                        status: 1, // Default status or derive from existing data
                      };
                    }
        
                    return updatedSeatsInfo;
                  });
                  setArrayReservations((prevReservations) => {
                    // Create a new object to ensure immutability
                    const updatedReservations = { ...prevReservations };
        
                    // Add or update the reservation by id_reservation
                    if (!updatedReservations[e.id_reservation]) {
                      updatedReservations[e.id_reservation] = {
                        ...e.reservation,
                        passengers: [],
                      };
                    } else {
                      // Optionally update some properties if the reservation already exists
                      updatedReservations[e.id_reservation] = {
                        ...updatedReservations[e.id_reservation],
                        ...e.reservation, // assuming you want to merge or update details
                        passengers: [],
                      };
                    }
        
                    return updatedReservations; // Return the updated reservations object
                  });
                }
        
               
              }
              if (
                e.reservation != null &&
                e.passenger == null &&
                e.status == "Deleted"
              ) {
                if (arrayReservations[e.id_reservation] === undefined) {
                  setArrayReservations((prevReservations) => {
                    const updatedReservations = JSON.parse(
                      JSON.stringify(prevReservations)
                    );
        
                    // Find reservations by trip_id and update seat numbers
                    Object.keys(updatedReservations).forEach((reservationId) => {
                      const reservation = updatedReservations[reservationId];
                      if (reservation.trip_id === e.trip_id) {
                     
                        if (
                          reservation.reservation_seats_number.hasOwnProperty(
                            e.seat_number
                          )
                        ) {
                          delete reservation.reservation_seats_number[e.seat_number];
                        }
                        const passengerIndex = reservation.passengers.findIndex(
                          (passenger) => passenger.number_seat === e.seat_number
                        );
                        if (passengerIndex !== -1) {
                          reservation.passengers.splice(passengerIndex, 1);
                        }
                        // });
        
                        // Optionally, if the reservation_seats_number is empty, consider removing or adjusting the reservation itself
                        if (
                          Object.keys(reservation.reservation_seats_number).length === 0
                        ) {
                          // delete updatedReservations[reservationId];  // Or handle it another way, such as marking it inactive
                        }
                      }
                    });
        
                    return updatedReservations;
                  });
                }
              }
              if (
                e.eventName === "reservations" &&
                e.reservation != null &&
                e.passenger != null
              ) {
                if (
                  e.trip_id == idDirect &&
                  e.id_transit == null &&
                  e.passenger != null
                ) {
                  setArraySeat((prevSeatsInfo) => {
                    const updatedSeatsInfo = { ...prevSeatsInfo };
                    const seatNumber = parseInt(e.seat_number);
        
                    if (!isNaN(seatNumber)) {
                      // Check if e.passenger is an array and access the first element directly
                      const passengerInfo = Array.isArray(e.passenger)
                        ? e.passenger[0]
                        : e.passenger;
        
                      updatedSeatsInfo[seatNumber] = {
                        passengers: passengerInfo, // Ensuring this is directly an object
                        status: 2, // Default status or derive from existing data
                        id_reservation: e.id_reservation, // Assuming this is directly usable
                      };
                    }
        
                    return updatedSeatsInfo;
                  });
                  setArrayReservations((prevReservations) => {
                    // Deep clone the previous reservations to avoid direct state mutation
                    const updatedReservations = JSON.parse(
                      JSON.stringify(prevReservations)
                    );
        
                    // Ensure the reservation exists
                    const reservation = updatedReservations[e.id_reservation];
                    if (reservation) {
                      // Check if the passenger array is not null and properly formatted as an array
                      {
                        const existingPassengerIndex = reservation.passengers.findIndex(
                          (p) => p.id === e.passenger.id
                        );
        
                        if (existingPassengerIndex >= 0) {
                          reservation.passengers[existingPassengerIndex] = {
                            ...e.passenger,
                          };
                        } else {
                          // Add new passenger directly as an object, no extra nesting
                          reservation.passengers.push(e.passenger);
                        }
                      }
        
                      // Update seat status to 2 if not already
                      Object.keys(reservation.reservation_seats_number).forEach(
                        (seatNumber) => {
                          if (reservation.reservation_seats_number[seatNumber] !== 2) {
                            reservation.reservation_seats_number[seatNumber] = 2;
                          }
                        }
                      );
                    }
        
                    return updatedReservations;
                  });
                  const newSeat = {
                    trip_id: e.trip_id,
                    transitStatus: e.transitStatus,
                    seat_number: e.seat_number,
                    status: e.status,
                    numStatus: e.numStatus,
                    id_client: e.id_client,
                    id_system: e.id_system,
                    id_transit: e.id_transit,
                  };
        
                  setSelected((currentSelect) => {
                    if (!Array.isArray(currentSelect)) {
                      return [newSeat];
                    }
        
                    const existingIndex = currentSelect.findIndex(
                      (s) => s.seat_number === newSeat.seat_number
                    );
        
                    if (existingIndex !== -1) {
                      const updatedSelect = [...currentSelect];
                      updatedSelect[existingIndex] = newSeat;
                      return updatedSelect;
                    } else {
                      return [...currentSelect, newSeat];
                    }
                  });
            
                }
               
                if (
                  e.id_transit != null &&
                  e.id_transit == arrayseatPassengerTransit1["id"]
                ) {
                  setArraySeatPassengerTransit1((prevSeatsInfo) => {
                    const updatedSeatsInfo = { ...prevSeatsInfo };
                    const seatNumber = parseInt(e.seat_number);
        
                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: e.passenger, // Directly using the object
                        id_reservation: e.id_reservation, // Directly using the object
                        status: 2, // Default status or derive from existing data
                      };
                    }
        
                    return updatedSeatsInfo;
                  });
                  setArrayReservations((prevReservations) => {
                    // Deep clone the previous reservations to avoid direct state mutation
                    const updatedReservations = JSON.parse(
                      JSON.stringify(prevReservations)
                    );
        
                    // Ensure the reservation exists
                    const reservation = updatedReservations[e.id_reservation];
                    if (reservation) {
                      // Check if the passenger array is not null and properly formatted as an array
                      {
                        const existingPassengerIndex = reservation.passengers.findIndex(
                          (p) => p.id === e.passenger.id
                        );
        
                        if (existingPassengerIndex >= 0) {
                          reservation.passengers[existingPassengerIndex] = {
                            ...e.passenger,
                          };
                        } else {
                          // Add new passenger directly as an object, no extra nesting
                          reservation.passengers.push(e.passenger);
                        }
                      }
        
                      // Update seat status to 2 if not already
                      Object.keys(reservation.reservation_seats_number).forEach(
                        (seatNumber) => {
                          if (reservation.reservation_seats_number[seatNumber] !== 2) {
                            reservation.reservation_seats_number[seatNumber] = 2;
                          }
                        }
                      );
                    }
        
                    return updatedReservations;
                  });
                }
                if (
                  e.id_transit != null &&
                  e.id_transit == arrayseatPassengerTransit2["id"]
                ) {
                  setArraySeatPassengerTransit2((prevSeatsInfo) => {
                    const updatedSeatsInfo = { ...prevSeatsInfo };
                    const seatNumber = parseInt(e.seat_number);
        
                    if (!isNaN(seatNumber)) {
                      updatedSeatsInfo[seatNumber] = {
                        passengers: e.passenger, // Directly using the object
                        id_reservation: e.id_reservation, // Directly using the object
                        status: 2, // Default status or derive from existing data
                      };
                    }
        
                    return updatedSeatsInfo;
                  });
                  setArrayReservations((prevReservations) => {
                    // Deep clone the previous reservations to avoid direct state mutation
                    const updatedReservations = JSON.parse(
                      JSON.stringify(prevReservations)
                    );
        
                    // Ensure the reservation exists
                    const reservation = updatedReservations[e.id_reservation];
                    if (reservation) {
                      // Check if the passenger array is not null and properly formatted as an array
                      {
                        const existingPassengerIndex = reservation.passengers.findIndex(
                          (p) => p.id === e.passenger.id
                        );
        
                        if (existingPassengerIndex >= 0) {
                          reservation.passengers[existingPassengerIndex] = {
                            ...e.passenger,
                          };
                        } else {
                          // Add new passenger directly as an object, no extra nesting
                          reservation.passengers.push(e.passenger);
                        }
                      }
        
                      // Update seat status to 2 if not already
                      Object.keys(reservation.reservation_seats_number).forEach(
                        (seatNumber) => {
                          if (reservation.reservation_seats_number[seatNumber] !== 2) {
                            reservation.reservation_seats_number[seatNumber] = 2;
                          }
                        }
                      );
                    }
        
                    return updatedReservations;
                  });
                }
              }
              const pendingSeats =
                JSON.parse(localStorage.getItem("seatpinding")) || [];
        
              const newSeat = {
                trip_id: e.trip_id,
                transitStatus: e.transitStatus,
                seat_number: e.seat_number,
                status: e.status,
                numStatus: e.numStatus,
                id_client: e.id_client,
                id_system: e.id_system,
                id_transit: e.id_transit,
              };
        
              const seatIndex = pendingSeats.findIndex(
                (s) => s.seat_number === newSeat.seat_number
              );
        
              if (seatIndex === -1) {
                pendingSeats.push(newSeat);
              } else {
                pendingSeats[seatIndex] = newSeat;
              }
        
              localStorage.setItem("seatpinding", JSON.stringify(pendingSeats));
              setSelected((currentSelect) => {
                if (!Array.isArray(currentSelect)) {
                  return [newSeat];
                }
        
                const existingIndex = currentSelect.findIndex(
                  (s) => s.seat_number === newSeat.seat_number
                );
        
                if (existingIndex !== -1) {
                  const updatedSelect = [...currentSelect];
                  updatedSelect[existingIndex] = newSeat;
                  return updatedSelect;
                } else {
                  return [...currentSelect, newSeat];
                }
              });
              setSelected1(e.status);
        
              
    };

    const handleTransitEvent = (e) => {
      // Handle transit event
      // console.log(e, "transit");
            if (e.eventName == "trip_transits" && e.transitStatus == 1) {
             
              setReservationseat1((currentResponse2) => {
                const updatedResponse2 = { ...currentResponse2 };
                if (updatedResponse2.hasOwnProperty(e.seat_number)) {
                  updatedResponse2[e.seat_number] = e.numStatus;
                  return updatedResponse2;
                }
                return currentResponse2;
              });
            
              setSeatsArray2((prevSeatsArray2) => {
                // Ensure that prevSeatsArray2 is always treated as an array
                const safePrevSeatsArray = Array.isArray(prevSeatsArray2)
                  ? prevSeatsArray2
                  : [];
      
                // Define arrayaddtransit locally within the callback
                let arrayaddtransit;
      
                // Check if the seat number already exists in the array
                if (!safePrevSeatsArray.includes(e.seat_number)) {
                  // If it doesn't exist, add it
                  arrayaddtransit = [...safePrevSeatsArray, e.seat_number];
                } else {
                  // If it exists, just use the previous array
                  arrayaddtransit = safePrevSeatsArray;
                }
      
                // Filter to find common seats between seatsArray1 and the updated arrayaddtransit
                const commonSeats = seatsArray1.filter((seat) =>
                  arrayaddtransit.includes(seat)
                );
      
                return arrayaddtransit;
              });
      
              setArraySeatPassengerTransit1((prevSeatsInfo) => {
                const updatedSeatsInfo = { ...prevSeatsInfo };
      
                const seatNumber = parseInt(e.seat_number);
      
                updatedSeatsInfo[seatNumber] = {
                  passengers: null,
                  id_reservation: undefined,
                  status: 0, // Default status or derive from existing data
                };
      
                return updatedSeatsInfo;
              });
              setArraySeatPassengerTransit2((prevSeatsInfo) => {
                const updatedSeatsInfo = { ...prevSeatsInfo };
      
                const seatNumber = parseInt(e.seat_number);
      
                updatedSeatsInfo[seatNumber] = {
                  passengers: null,
                  id_reservation: undefined,
                  status: 0, // Default status or derive from existing data
                };
      
                return updatedSeatsInfo;
              });
            }
            if (e.eventName == "trip_transits" && e.transitStatus == 2) {
              setSeatsArray2((prevSeatsArray2) => {
                // Ensure that prevSeatsArray2 is always treated as an array
                const safePrevSeatsArray = Array.isArray(prevSeatsArray2)
                  ? prevSeatsArray2
                  : [];
      
                // Define arrayaddtransit locally within the callback
                let arrayaddtransit;
      
                // Check if the seat number already exists in the array
                if (!safePrevSeatsArray.includes(e.seat_number)) {
                  // If it doesn't exist, add it
                  arrayaddtransit = [...safePrevSeatsArray, e.seat_number];
                } else {
                  // If it exists, just use the previous array
                  arrayaddtransit = safePrevSeatsArray;
                }
      
                // Filter to find common seats between seatsArray1 and the updated arrayaddtransit
                const commonSeats = seatsArray1.filter((seat) =>
                  arrayaddtransit.includes(seat)
                );
                // setCommonSeats(commonSeats);
      
                return arrayaddtransit;
              });
              setReservationseat1((currentResponse) => {
                const updatedResponse = { ...currentResponse };
      
                // Check if the seat number exists; update or add new entry
                if (!updatedResponse.hasOwnProperty(e.seat_number)) {
                  // If not exist, add new entry
                  updatedResponse[e.seat_number] = e.numStatus;
                } else {
                  // If exists, just update it
                  updatedResponse[e.seat_number] = e.numStatus;
                }
      
                return updatedResponse;
              });
              setArraySeatPassengerTransit1((prevSeatsInfo) => {
                const updatedSeatsInfo = { ...prevSeatsInfo };
      
                const seatNumber = parseInt(e.seat_number);
      
                updatedSeatsInfo[seatNumber] = {
                  passengers: null,
                  id_reservation: undefined,
                  status: 0, // Default status or derive from existing data
                };
      
                return updatedSeatsInfo;
              });
              setArraySeatPassengerTransit2((prevSeatsInfo) => {
                const updatedSeatsInfo = { ...prevSeatsInfo };
      
                const seatNumber = parseInt(e.seat_number);
      
                updatedSeatsInfo[seatNumber] = {
                  passengers: null,
                  id_reservation: undefined,
                  status: 0, // Default status or derive from existing data
                };
      
                return updatedSeatsInfo;
              });
            }
       
            if (
              e.eventName === "trip_transits" &&
              e.id_transit != null &&
              e.id_transit == transit1Ref.current.id
            
            ) {
              setReservationseat1((currentResponse2) => {
                const updatedResponse2 = { ...currentResponse2 };
                if (updatedResponse2.hasOwnProperty(e.seat_number)) {
                  updatedResponse2[e.seat_number] = e.numStatus;
                  return updatedResponse2;
                }
                return currentResponse2;
              });
        
              setArraySeatPassengerTransit1((prevSeatsInfo) => {
                const updatedSeatsInfo = { ...prevSeatsInfo };
                const seatNumber = parseInt(e.seat_number);
      
                if (!isNaN(seatNumber)) {
                  // Check if e.passenger is an array and access the first element directly
                  const passengerInfo = Array.isArray(e.passenger)
                    ? e.passenger[0]
                    : e.passenger;
      
                  updatedSeatsInfo[seatNumber] = {
                    passengers: passengerInfo, // Ensuring this is directly an object
                    status: 2, // Default status or derive from existing data
                    id_reservation: e.id_reservation, // Assuming this is directly usable
                  };
                }
      
                return updatedSeatsInfo;
              });
              setArrayReservations((prevReservations) => {
                // Deep clone the previous reservations to avoid direct state mutation
                const updatedReservations = JSON.parse(
                  JSON.stringify(prevReservations)
                );
      
                // Ensure the reservation exists
                const reservation = updatedReservations[e.id_reservation];
                if (reservation) {
                  // Check if the passenger array is not null and properly formatted as an array
                  {
                    const existingPassengerIndex = reservation.passengers.findIndex(
                      (p) => p.id === e.passenger.id
                    );
      
                    if (existingPassengerIndex >= 0) {
                      reservation.passengers[existingPassengerIndex] = {
                        ...e.passenger,
                      };
                    } else {
                      // Add new passenger directly as an object, no extra nesting
                      reservation.passengers.push(e.passenger);
                    }
                  }
      
                  // Update seat status to 2 if not already
                  Object.keys(reservation.reservation_seats_number).forEach(
                    (seatNumber) => {
                      if (reservation.reservation_seats_number[seatNumber] !== 2) {
                        reservation.reservation_seats_number[seatNumber] = 2;
                      }
                    }
                  );
                }
      
                return updatedReservations;
              });
            }
            if (
              e.eventName === "trip_transits" &&
              e.id_transit != null &&
              e.id_transit == transit2Ref.current.id
            ) {
              setReservationseat1((currentResponse2) => {
                const updatedResponse2 = { ...currentResponse2 };
                if (updatedResponse2.hasOwnProperty(e.seat_number)) {
                  updatedResponse2[e.seat_number] = e.numStatus;
                  return updatedResponse2;
                }
                return currentResponse2;
              });
         
              
              setArraySeatPassengerTransit2((prevSeatsInfo) => {
                const updatedSeatsInfo = { ...prevSeatsInfo };
                const seatNumber = parseInt(e.seat_number);
      
                if (!isNaN(seatNumber)) {
                  // Check if e.passenger is an array and access the first element directly
                  const passengerInfo = Array.isArray(e.passenger)
                    ? e.passenger[0]
                    : e.passenger;
      
                  updatedSeatsInfo[seatNumber] = {
                    passengers: passengerInfo, // Ensuring this is directly an object
                    status: 2, // Default status or derive from existing data
                    id_reservation: e.id_reservation, // Assuming this is directly usable
                  };
                }
      
                return updatedSeatsInfo;
              });
              setArrayReservations((prevReservations) => {
                // Deep clone the previous reservations to avoid direct state mutation
                const updatedReservations = JSON.parse(
                  JSON.stringify(prevReservations)
                );
      
                // Ensure the reservation exists
                const reservation = updatedReservations[e.id_reservation];
                if (reservation) {
                  // Check if the passenger array is not null and properly formatted as an array
                  {
                    const existingPassengerIndex = reservation.passengers.findIndex(
                      (p) => p.id === e.passenger.id
                    );
      
                    if (existingPassengerIndex >= 0) {
                      reservation.passengers[existingPassengerIndex] = {
                        ...e.passenger,
                      };
                    } else {
                      // Add new passenger directly as an object, no extra nesting
                      reservation.passengers.push(e.passenger);
                    }
                  }
      
                  // Update seat status to 2 if not already
                  Object.keys(reservation.reservation_seats_number).forEach(
                    (seatNumber) => {
                      if (reservation.reservation_seats_number[seatNumber] !== 2) {
                        reservation.reservation_seats_number[seatNumber] = 2;
                      }
                    }
                  );
                }
      
                return updatedReservations;
              });
            }
            if (
              e.eventName === "trip_transits" &&
              e.passenger == null &&
              e.id_transit == idTransit
            ) {
              setReservationseat1((currentResponse2) => {
                const updatedResponse2 = { ...currentResponse2 };
                if (updatedResponse2.hasOwnProperty(e.seat_number)) {
                  updatedResponse2[e.seat_number] = e.numStatus;
                  return updatedResponse2;
                }
                return currentResponse2;
              });
            }
      
            if (
              e.eventName == "trip_transits" &&
              e.transitStatus == null &&
              e.status == "Deleted"
            ) {
              setReservationseat1((currentResponse2) => {
                const updatedResponse2 = { ...currentResponse2 };
                if (updatedResponse2.hasOwnProperty(e.seat_number)) {
                  updatedResponse2[e.seat_number] = e.numStatus;
                  return updatedResponse2;
                }
                return currentResponse2;
              });
              setArrayReservations((prevReservations) => {
                const updatedReservations = JSON.parse(
                  JSON.stringify(prevReservations)
                );
      
                // Find reservations by trip_id and update seat numbers
                Object.keys(updatedReservations).forEach((reservationId) => {
                  const reservation = updatedReservations[reservationId];
                  if (reservation.trip_id === e.trip_id) {
                    // Check each seat number if it's in the current reservation
                    // seatNumberList.forEach(seatNumber => {
                    if (
                      reservation.reservation_seats_number.hasOwnProperty(
                        e.seat_number
                      )
                    ) {
                      delete reservation.reservation_seats_number[e.seat_number];
                    }
                    const passengerIndex = reservation.passengers.findIndex(
                      (passenger) => passenger.number_seat === e.seat_number
                    );
                    if (passengerIndex !== -1) {
                      reservation.passengers.splice(passengerIndex, 1);
                    }
                    // });
      
                    // Optionally, if the reservation_seats_number is empty, consider removing or adjusting the reservation itself
                    if (
                      Object.keys(reservation.reservation_seats_number).length === 0
                    ) {
                      // delete updatedReservations[reservationId];  // Or handle it another way, such as marking it inactive
                    }
                  }
                });
      
                return updatedReservations;
              });
              if (e.id_transit == arrayseatPassengerTransit1["id"]) {
                setArraySeatPassengerTransit1((prevReservations) => {
                  const updatedReservations = { ...prevReservations };
                  delete updatedReservations[e.seat_number]; // Delete the reservation using its ID
                  return updatedReservations;
                });
              }
              if (e.id_transit == arrayseatPassengerTransit2["id"]) {
                setArraySeatPassengerTransit2((prevReservations) => {
                  const updatedReservations = { ...prevReservations };
                  delete updatedReservations[e.seat_number]; // Delete the reservation using its ID
                  return updatedReservations;
                });
              }
            }

            if (
              e.eventName == "trip_transits" &&
              e.transitStatus == null &&
              e.id_transit == idTransit
            ) {
              setReservationseat1((currentResponse2) => {
                const updatedResponse2 = { ...currentResponse2 };
                if (updatedResponse2.hasOwnProperty(e.seat_number)) {
                  updatedResponse2[e.seat_number] = e.numStatus;
                  return updatedResponse2;
                }
                return currentResponse2;
              });
            }
            if (
              e.eventName == "trip_transits" &&
              e.transitStatus == "transitDeleted" &&
              e.status == "Deleted"
            ) {
              setReservationseat1((currentResponse1) => {
                const updatedResponse2 = { ...currentResponse1 };
      
                // Check if the specific seat number exists and update it
                if (updatedResponse2.hasOwnProperty(e.seat_number)) {
                  updatedResponse2[e.seat_number] = e.numStatus;
      
                  // Create a new object filtering out even-numbered seats
                  const filteredResponse = Object.keys(updatedResponse2)
                    .filter((key) => parseInt(key) % 2 !== 0) // Keep only odd-numbered seats
                    .reduce((obj, key) => {
                      obj[key] = updatedResponse2[key];
                      return obj;
                    }, {});
      
                  return filteredResponse;
                }
      
                return currentResponse1;
              });
      
              setSeatsArray2((prevSeatsArray2) => {
                // Ensure that prevSeatsArray2 is always treated as an array
                const safePrevSeatsArray = Array.isArray(prevSeatsArray2)
                  ? prevSeatsArray2
                  : [];
      
                // Remove the seat number if it exists in the array
                let arrayaddtransit = safePrevSeatsArray.filter(
                  (seat) => seat !== e.seat_number
                );
      
                // Filter to find common seats between seatsArray1 and the updated arrayaddtransit
                const commonSeats = seatsArray1.filter((seat) =>
                  arrayaddtransit.includes(seat)
                );
      
                return arrayaddtransit;
              });
            }
      
            const pendingSeats =
              JSON.parse(localStorage.getItem("seatpinding")) || [];
      
            const newSeat = {
              trip_id: e.trip_id,
              transitStatus: e.transitStatus,
              seat_number: e.seat_number,
              status: e.status,
              numStatus: e.numStatus,
              id_client: e.id_client,
              id_system: e.id_system,
              id_transit: e.id_transit,
            };
      
            const seatIndex = pendingSeats.findIndex(
              (s) => s.seat_number === newSeat.seat_number
            );
      
            if (seatIndex === -1) {
              pendingSeats.push(newSeat);
            } else {
              pendingSeats[seatIndex] = newSeat;
            }
      
            localStorage.setItem("seatpinding", JSON.stringify(pendingSeats));
            setSelected((currentSelect) => {
              if (!Array.isArray(currentSelect)) {
                return [newSeat];
              }
      
              const existingIndex = currentSelect.findIndex(
                (s) => s.seat_number === newSeat.seat_number
              );
      
              if (existingIndex !== -1) {
                const updatedSelect = [...currentSelect];
                updatedSelect[existingIndex] = newSeat;
                return updatedSelect;
              } else {
                return [...currentSelect, newSeat];
              }
            });
            setSelected1(e.status);
            // });
    };

    // Conditional logic to subscribe to channels based on the component's need
    if (TripID) {
      echo.channel(`seat-reservation-trips:${TripID.id}`).listen("SeatNumberReservation", handleTripEvent);
      echo.channel(`seat-reservation-reservations:${TripID.id}`).listen("SeatNumberReservation", handleReservationEvent);
      echo.channel(`seat-reservation-trip_transits:${TripID.id}`).listen("SeatNumberReservation", handleTransitEvent);

    }

    // Cleanup function to unsubscribe from channels
    return () => {
      if (TripID) {
        echo.leave(`seat-reservation-trips:${TripID.id}`);
        echo.leave(`seat-reservation-reservations:${TripID.id}`);
        echo.leave(`seat-reservation-trip_transits:${TripID.id}`);
      }
      echo.disconnect();
    };
  }, [
    TripID.id,
    user.user_id,
    dispatch,
  ]);  
  

  function countStatus3(seatStatus) {
    let count = 0;
    for (const seatNumber in seatStatus) {
      if (seatStatus[seatNumber] === 3) {
        count++;
      }
    }
    return count;
  }

  useEffect(() => {}, [
    selectedSeats,
    selectedSeats,
    selectedSeats2,
    valueinput,
    title,
    reservationss,
  ]);

  function getReservationIdForSeat(seatNumber, reservations, tripId) {
    // Check if reservations is an object and not null
    if (typeof reservations === "object" && reservations !== null) {
      for (let reservation of Object.values(reservations)) {
        // Check both the seat number and the trip_id
        if (
          (reservation.trip_id == tripId ||
            (reservation.triptable_id && reservation.triptable_id == tripId)) &&
          reservation.reservation_seats_number &&
          reservation.reservation_seats_number[seatNumber]
        ) {
          return reservation.id;
        }
      }
    }
    return null; // Return null if no matching reservation is found
  }

  useEffect(() => {
    setCommonSeats(seatsArray1.filter((seat) => seatsArray2.includes(seat)));
  }, [seatsArray2]);
  useEffect(() => {}, [selectedSeats2]);
  useEffect(() => {}, [response1]);
  async function handleSeatClick(seatNumber) {
    const uniqueSelectedSeats = [...new Set(selectedSeats)];
    const uniqueSelectedSeats1 = [...new Set(selectedSeats1)];
    const uniqueSelectedSeats2 = [...new Set(selectedSeats2)];
    const isSeatSelected = uniqueSelectedSeats.includes(seatNumber);
    const isSeatSelected1 = uniqueSelectedSeats1.includes(seatNumber);
    const isSeatSelected2 = uniqueSelectedSeats2.includes(seatNumber);
    const newSeatStatus = response1[seatNumber];

    let updatedSelectedSeats;
    let updatedSelectedSeats2;
    let updatedSelectedSeats3;
    const lastSelectedSeat = selectedSeats[selectedSeats.length - 1];
    updatedSelectedSeats = uniqueSelectedSeats;
    updatedSelectedSeats2 = uniqueSelectedSeats1;
    updatedSelectedSeats3 = uniqueSelectedSeats2;

    if (isSeatSelected || isSeatSelected1 || isSeatSelected2) {
      const lastSelectedSeatStatus = lastSelectedSeat
        ? response1[lastSelectedSeat]
        : null;
      const newSeatStatusnew = response1[seatNumber];
      if (direct) {
        if (newSeatStatusnew == 5) {
          const data = {
            triptable_id: idDirect,
            tripid: TripID.id,
            triptable_type: "Domain\\Trip",
            seat_number: [seatNumber],
            status: 0,
            branch: TripID.id_branch,
          };
 

          try {
            axios
              .post(`${BaseUrl}/changeStatusSeat`, data, {
                withCredentials: true,
              })
              .then((response) => {
                if (response.status === 200) {
                  setResponse1(response.data.data[0]);
                  const mappedById = response.data.reservations.reduce(
                    (acc, item) => {
                      acc[item.id] = item;
                      return acc;
                    },
                    {}
                  );
                  setArrayReservations(mappedById);
                  let passenger;
                  let seatWithPassengerNull = Object.keys(
                    response.data.data[0]
                  ).reduce((accumulator, currentKey) => {
                    const reservationInfo = response.data.reservations.find(
                      (reservation) =>
                        reservation.reservation_seats_number[currentKey] > 0
                    );
                    passenger = reservationInfo
                      ? reservationInfo.passengers?.find(
                          (p) => p.number_seat == currentKey
                        )
                      : null;

                    accumulator[currentKey] = {
                      passengers: Object(passenger),
                      status:
                        reservationInfo?.reservation_seats_number[currentKey],
                      id_reservation: reservationInfo?.id,
                    };
                    return accumulator;
                  }, {});

                  setArraySeat(seatWithPassengerNull);
                  const seatStatusData = response.data.data[0];

                  // Extract seat numbers with status 6
                  const seatsWithStatusSix = Object.keys(seatStatusData).filter(
                    (seatNumber) => seatStatusData[seatNumber] === 6
                  );

                  // Convert to integers, since keys are strings
                  const seatNumbersToInt = seatsWithStatusSix.map(Number);

                  // Update selectedSeats2 state to include these seat numbers
                  setSeatsArray2(seatNumbersToInt);
                  setCommonSeats(
                    seatsArray1.filter((seat) =>
                      seatNumbersToInt.includes(seat)
                    )
                  );

                  updatedSelectedSeats2 = selectedSeats1?.filter(
                    (selectedSeat) => selectedSeat !== seatNumber
                  );

                  updatedSelectedSeats = selectedSeats?.filter(
                    (selectedSeat) => selectedSeat !== seatNumber
                  );
                  const sortedSelectedSeats2 = updatedSelectedSeats2
                    .filter((number) => number !== 0) // Remove 0 if needed
                    .sort((a, b) => a - b);
                  const sortedSelectedSeats = updatedSelectedSeats
                    .filter((number) => number !== 0) // Remove 0 if needed
                    .sort((a, b) => a - b);
                  // Temporarily use this logic on an action to test state accumulation
                  const combinedSortedSeats = [
                    ...sortedSelectedSeats2,
                    ...sortedSelectedSeats,
                  ];
                  const sortedCombinedSeats = combinedSortedSeats.sort(
                    (a, b) => a - b
                  );

                  const reservedSeats = Object.keys(response.data.data[0]).map(
                    (seat) => parseInt(seat)
                  );

                  const matchedSeats = Object.values(
                    response.data.data[1]
                  ).filter(
                    (seat) =>
                      reservedSeats.includes(seat.seat_number) &&
                      seat.numStatus === 5
                  );

                  let resultArray2 = [];
                  const addedSeatNumbers = new Set(); // Set to track added seat numbers
                  const transitSeatNumbers = new Set();

                  // Populate transitSeatNumbers as before
                  TripID.tripTransit.forEach((transit) => {
                    Object.keys(transit.reservationSeats).forEach(
                      (seatNumber) => {
                        transitSeatNumbers.add(seatNumber);
                      }
                    );
                  });
                  const reservationSeats = response.data.data[0];
                  const seatStatusAuth = response.data.data[1];
                  Object.keys(response.data.data[0]).forEach((seatNumber) => {
                    const status = reservationSeats[seatNumber];
           
                    if (status === 5 && !seatStatusAuth[seatNumber]) {
                      resultArray2.push({
                        seat_number: parseInt(seatNumber),
                        id_system: 1,
                        numStatus: status,
                        id_client: 0,
                        status: "Selected",
                        transitStatus: null,
                        id_transit: null,
                        trip_id: idtrip,
                      });
                    }
                  });
                  Object.keys(response.data.data[1]).forEach((seatNumber) => {
                    const seatDetails = response.data.data[1][seatNumber];
                    if (
                      seatDetails.numStatus === 5 &&
                      response1[seatNumber] !== undefined &&
                      !transitSeatNumbers.has(seatNumber) &&
                      !addedSeatNumbers.has(seatNumber) // Check if the seat number has already been added
                    ) {
                      resultArray2.push({
                        seat_number: parseInt(seatNumber),
                        id_system: seatDetails.id_system,
                        numStatus: seatDetails.numStatus,
                        id_client: 0,
                        status: "Selected",
                        transitStatus: null,
                        id_transit: null,
                        trip_id: idtrip,
                      });
                      addedSeatNumbers.add(seatNumber); // Mark this seat number as added
                    }
                  });

                  // Filter resultArray1 to find seats reserved by the current user
                  const currentUserReservedSeats = resultArray2.filter(
                    (userSeat) =>
                      userSeat.id_system === user.user_id &&
                      userSeat.numStatus == 5
                  );
                  const currentUserReservedSeatNumbers =
                    currentUserReservedSeats.map((seat) => seat.seat_number);
                  setSelectedSeats(currentUserReservedSeatNumbers);
                  setSelected(resultArray2);
                  setSeatAndUser(resultArray2);
                  // setSeatAndUser(resultArray2);
                  setResultArrayseatuser(resultArray2);
                  setResultArray(sortedCombinedSeats);
                  setValueinput(sortedCombinedSeats.join("+"));
                } else {
                }
              })
              .catch((error) => {
                // Handle the error here
                setResponse1([]);
                dispatch(fetchTripBYId(TripID.id));
                setResponse1(TripID.reservation_seats);
              });
          } catch (error) {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              sweetalert({
                text: error.response.data,
                icon: "error",
                buttons: {
                  confirm: {
                    text: "إغلاق",
                    className: "custom-button-class",
                  },
                },
                className: "alert-div",
              });
            }
          }
        } else if (newSeatStatusnew == 0) {
          const data = {
            triptable_id: idDirect,
            tripid: TripID.id,
            triptable_type: "Domain\\Trip",
            seat_number: [seatNumber],
            status: 5,
            branch: TripID.id_branch,
          };
    

          try {
            axios
              .post(`${BaseUrl}/changeStatusSeat`, data, {
                withCredentials: true,
              })
              .then((response) => {
                if (response.status === 200) {
                  setResponse1(response.data.data[0]);
                  const mappedById = response.data.reservations.reduce(
                    (acc, item) => {
                      acc[item.id] = item;
                      return acc;
                    },
                    {}
                  );
                  setArrayReservations(mappedById);
                  let passenger;
                  let seatWithPassengerNull = Object.keys(
                    response.data.data[0]
                  ).reduce((accumulator, currentKey) => {
                    const reservationInfo = response.data.reservations.find(
                      (reservation) =>
                        reservation.reservation_seats_number[currentKey] > 0
                    );
                    passenger = reservationInfo
                      ? reservationInfo.passengers?.find(
                          (p) => p.number_seat == currentKey
                        )
                      : null;

                    accumulator[currentKey] = {
                      passengers: Object(passenger),
                      status:
                        reservationInfo?.reservation_seats_number[currentKey],
                      id_reservation: reservationInfo?.id,
                    };
                    return accumulator;
                  }, {});

                  setArraySeat(seatWithPassengerNull);
                  const seatStatusData = response.data.data[0];

                  // Extract seat numbers with status 6
                  const seatsWithStatusSix = Object.keys(seatStatusData).filter(
                    (seatNumber) => seatStatusData[seatNumber] === 6
                  );

                  // Convert to integers, since keys are strings
                  const seatNumbersToInt = seatsWithStatusSix.map(Number);

                  // Update selectedSeats2 state to include these seat numbers
                  setSeatsArray2(seatNumbersToInt);
                  setCommonSeats(
                    seatsArray1.filter((seat) =>
                      seatNumbersToInt.includes(seat)
                    )
                  );
                  // setSelectedSeats(currentUserReservedSeatNumbers);

                  // setSelectedSeats(updatedSelectedSeats);
                  updatedSelectedSeats2 = selectedSeats1?.filter(
                    (selectedSeat) => selectedSeat !== seatNumber
                  );

                  updatedSelectedSeats = selectedSeats?.filter(
                    (selectedSeat) => selectedSeat !== seatNumber
                  );
                  const sortedSelectedSeats2 = updatedSelectedSeats2
                    .filter((number) => number !== 0) // Remove 0 if needed
                    .sort((a, b) => a - b);
                  const sortedSelectedSeats = updatedSelectedSeats
                    .filter((number) => number !== 0) // Remove 0 if needed
                    .sort((a, b) => a - b);
                  // Temporarily use this logic on an action to test state accumulation
                  const combinedSortedSeats = [
                    ...sortedSelectedSeats2,
                    ...sortedSelectedSeats,
                  ];
                  const sortedCombinedSeats = combinedSortedSeats.sort(
                    (a, b) => a - b
                  );

                  const reservedSeats = Object.keys(response.data.data[0]).map(
                    (seat) => parseInt(seat)
                  );

                  const matchedSeats = Object.values(
                    response.data.data[1]
                  ).filter(
                    (seat) =>
                      reservedSeats.includes(seat.seat_number) &&
                      seat.numStatus === 5
                  );

                  let resultArray2 = [];
                  const addedSeatNumbers = new Set(); // Set to track added seat numbers
                  const transitSeatNumbers = new Set();

                  // Populate transitSeatNumbers as before
                  TripID.tripTransit.forEach((transit) => {
                    Object.keys(transit.reservationSeats).forEach(
                      (seatNumber) => {
                        transitSeatNumbers.add(seatNumber);
                      }
                    );
                  });
                  const reservationSeats = response.data.data[0];
                  const seatStatusAuth = response.data.data[1];
                  Object.keys(response.data.data[0]).forEach((seatNumber) => {
                    const status = reservationSeats[seatNumber];
           
                    if (status === 5 && !seatStatusAuth[seatNumber]) {
                      resultArray2.push({
                        seat_number: parseInt(seatNumber),
                        id_system: 1,
                        numStatus: status,
                        id_client: 0,
                        status: "Selected",
                        transitStatus: null,
                        id_transit: null,
                        trip_id: idtrip,
                      });
                    }
                  });
                  Object.keys(response.data.data[1]).forEach((seatNumber) => {
                    const seatDetails = response.data.data[1][seatNumber];
                    if (
                      seatDetails.numStatus === 5 &&
                      response1[seatNumber] !== undefined &&
                      !transitSeatNumbers.has(seatNumber) &&
                      !addedSeatNumbers.has(seatNumber) // Check if the seat number has already been added
                    ) {
                      resultArray2.push({
                        seat_number: parseInt(seatNumber),
                        id_system: seatDetails.id_system,
                        numStatus: seatDetails.numStatus,
                        id_client: 0,
                        status: "Selected",
                        transitStatus: null,
                        id_transit: null,
                        trip_id: idtrip,
                      });
                      addedSeatNumbers.add(seatNumber); // Mark this seat number as added
                    }
                  });

                  // Filter resultArray1 to find seats reserved by the current user
                  const currentUserReservedSeats = resultArray2.filter(
                    (userSeat) =>
                      userSeat.id_system === user.user_id &&
                      userSeat.numStatus == 5
                  );
                  const currentUserReservedSeatNumbers =
                    currentUserReservedSeats.map((seat) => seat.seat_number);
                  setSelectedSeats(currentUserReservedSeatNumbers);
                  setSelected(resultArray2);
                  setSeatAndUser(resultArray2);
                  // setSeatAndUser(resultArray2);
                  setResultArrayseatuser(resultArray2);
                  setResultArray(sortedCombinedSeats);
                  setValueinput(sortedCombinedSeats.join("+"));
                } else {
                }
              })
              .catch((error) => {
                // Handle the error here
                setResponse1([]);
                dispatch(fetchTripBYId(TripID.id));
                setResponse1(TripID.reservation_seats);
              });
          } catch (error) {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              setResponse1([]);
              dispatch(fetchTripBYId(TripID.id));
              setResponse1(TripID.reservation_seats);
            }
          }
        } else {
          if (newSeatStatusnew == 3 || newSeatStatusnew == 4) {
            updatedSelectedSeats2 = uniqueSelectedSeats1?.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );

            updatedSelectedSeats = uniqueSelectedSeats?.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );
            const sortedSelectedSeats2 = updatedSelectedSeats2
              .filter((number) => number !== 0) // Remove 0 if needed
              .sort((a, b) => a - b);
            const sortedSelectedSeats = updatedSelectedSeats
              .filter((number) => number !== 0) // Remove 0 if needed
              .sort((a, b) => a - b);
            // Temporarily use this logic on an action to test state accumulation
            setSelectedSeats1(sortedSelectedSeats2);
            setSelectedSeats(sortedSelectedSeats);
            const combinedSortedSeats = [
              ...sortedSelectedSeats2,
              ...sortedSelectedSeats,
            ];
            const sortedCombinedSeats = combinedSortedSeats.sort(
              (a, b) => a - b
            );

            setResultArray(combinedSortedSeats);
            setValueinput(sortedCombinedSeats.join("+"));
          } else if (newSeatStatusnew == 1) {
            updatedSelectedSeats2 = uniqueSelectedSeats1.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );
            const sortedSelectedSeats = updatedSelectedSeats2
              .filter((number) => number !== 0) // Remove 0 if needed
              .sort((a, b) => a - b);
            // Temporarily use this logic on an action to test state accumulation
            setSelectedSeats(sortedSelectedSeats);
            setSelectedSeats1(sortedSelectedSeats);
            const combinedSortedSeats = sortedSelectedSeats;
            setResultArray(combinedSortedSeats);

            const newValueInput = combinedSortedSeats.join("+");
            setValueinput(newValueInput);
          } else {
            updatedSelectedSeats3 = selectedSeats2.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );

            const sortedSelectedSeats = updatedSelectedSeats3
              .filter((number) => number !== 0) // Remove 0 if needed
              .sort((a, b) => a - b);
            // Temporarily use this logic on an action to test state accumulation
            setSelectedSeats2(sortedSelectedSeats);
            const combinedSortedSeats = sortedSelectedSeats;
            setResultArray(combinedSortedSeats);

            const newValueInput = combinedSortedSeats.join("+");
            setValueinput(newValueInput);
          }
        }
      } else if (transet) {
        if (newSeatStatusnew == 6) {
          if (
            reservationseat1[seatNumber] == 3 ||
            reservationseat1[seatNumber] == 4
          ) {
            updatedSelectedSeats2 = selectedSeats1.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );
            const sortedSelectedSeats2 = updatedSelectedSeats2
              .filter((number) => number !== 0) // Remove 0 if needed
              .sort((a, b) => a - b);
            updatedSelectedSeats = selectedSeats?.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );
            const sortedSelectedSeats = updatedSelectedSeats
              .filter((number) => number !== 0) // Remove 0 if needed
              .sort((a, b) => a - b);
            // Temporarily use this logic on an action to test state accumulation
            setSelectedSeats1(sortedSelectedSeats2);
            const combinedSortedSeats = [
              ...sortedSelectedSeats2,
              ...sortedSelectedSeats,
            ];

            const sortedCombinedSeats = combinedSortedSeats.sort(
              (a, b) => a - b
            );
            setValueinput(sortedCombinedSeats.join("+"));
          } else if (reservationseat1[seatNumber] == 2) {
            updatedSelectedSeats3 = selectedSeats2.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );

            const sortedSelectedSeats = updatedSelectedSeats3
              .filter((number) => number !== 0) // Remove 0 if needed
              .sort((a, b) => a - b);
            // Temporarily use this logic on an action to test state accumulation
            setSelectedSeats2(sortedSelectedSeats);
            const combinedSortedSeats = sortedSelectedSeats;
            setResultArray(combinedSortedSeats);

            const newValueInput = combinedSortedSeats.join("+");
            setValueinput(newValueInput);
          } else if (reservationseat1[seatNumber] == 1) {
            updatedSelectedSeats = selectedSeats.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );

            const sortedSelectedSeats = updatedSelectedSeats
              .filter((number) => number !== 0) // Remove 0 if needed
              .sort((a, b) => a - b);
            // Temporarily use this logic on an action to test state accumulation
            setSelectedSeats(sortedSelectedSeats);
            const combinedSortedSeats = sortedSelectedSeats;
            setResultArray(combinedSortedSeats);

            const newValueInput = combinedSortedSeats.join("+");
            setValueinput(newValueInput);
          } else if (reservationseat1[seatNumber] == 5) {
            const data = {
              triptable_id: idTransit,
              tripid: TripID.id,
              triptable_type: "Domain\\TripTransit",
              seat_number: [seatNumber],
              status: 0,
              branch: TripID.id_branch,
            };
   
            updatedSelectedSeats2 = selectedSeats1.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );
            const sortedSelectedSeats2 = updatedSelectedSeats2
              .filter((number) => number !== 0)
              .sort((a, b) => a - b);
            updatedSelectedSeats = selectedSeats?.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );
            const sortedSelectedSeats = updatedSelectedSeats
              .filter((number) => number !== 0)
              .sort((a, b) => a - b);
            setSelectedSeats(sortedSelectedSeats);
            const combinedSortedSeats = [
              ...sortedSelectedSeats2,
              ...sortedSelectedSeats,
            ];

            const sortedCombinedSeats = combinedSortedSeats.sort(
              (a, b) => a - b
            );
            try {
              axios
                .post(`${BaseUrl}/changeStatusSeat`, data, {
                  withCredentials: true,
                })
                .then((response) => {
                  if (response.status === 200) {
                    setSelectedSeats(sortedSelectedSeats);
                    const mappedById = response.data.reservations.reduce(
                      (acc, item) => {
                        acc[item.id] = item;
                        return acc;
                      },
                      {}
                    );
                    setArrayReservations(mappedById);
                    if (selectedTransitIndex == 0) {
                      let seatWithPassengerTransit1 = [];
                      // Safely access the reservationSeats property
                      seatWithPassengerTransit1 = Object.keys(
                        response.data.data[0]
                      ).reduce((accumulator, currentKey) => {
                        // Assuming you want to do something with the keys here
                        const reservationInfo = response.data.reservations.find(
                          (reservation) =>
                            reservation.reservation_seats_number &&
                            reservation.reservation_seats_number[currentKey] > 0
                        );

                        const passenger = reservationInfo
                          ? reservationInfo.passengers?.find(
                              (p) => p.number_seat == currentKey
                            )
                          : null;

                        accumulator[currentKey] = {
                          passengers: passenger
                            ? Object.assign({}, passenger)
                            : null, // safely copy passenger object
                          status:
                            reservationInfo?.reservation_seats_number[
                              currentKey
                            ],
                          id_reservation: reservationInfo?.id,
                        };

                        return accumulator;
                      }, {});
                      // setInitialIdTransit(TripID.tripTransit[0].id);
                      // seatWithPassengerTransit1.id = TripID.tripTransit[0].id;
                      const exampleData = {
                        ...seatWithPassengerTransit1, // This is your existing seat data
                        id: TripID.tripTransit[0].id, // Ensure you are actually setting the id like this
                      };
                      setArraySeatPassengerTransit1(exampleData);
                    }
                    if (selectedTransitIndex == 1) {
                      let seatWithPassengerTransit2 = [];
                      // Safely access the reservationSeats property
                      seatWithPassengerTransit2 = Object.keys(
                        response.data.data[0]
                      ).reduce((accumulator, currentKey) => {
                        // Assuming you want to do something with the keys here
                        const reservationInfo = response.data.reservations.find(
                          (reservation) =>
                            reservation.reservation_seats_number &&
                            reservation.reservation_seats_number[currentKey] > 0
                        );

                        const passenger = reservationInfo
                          ? reservationInfo.passengers?.find(
                              (p) => p.number_seat == currentKey
                            )
                          : null;

                        accumulator[currentKey] = {
                          passengers: passenger
                            ? Object.assign({}, passenger)
                            : null, // safely copy passenger object
                          status:
                            reservationInfo?.reservation_seats_number[
                              currentKey
                            ],
                          id_reservation: reservationInfo?.id,
                        };

                        return accumulator;
                      }, {});
                      // setInitialIdTransit(TripID.tripTransit[0].id);
                      // seatWithPassengerTransit1.id = TripID.tripTransit[0].id;
                      const exampleData = {
                        ...seatWithPassengerTransit2, // This is your existing seat data
                        id: TripID.tripTransit[1].id, // Ensure you are actually setting the id like this
                      };
                      setArraySeatPassengerTransit2(exampleData);
                    }
                    setReservationseat1(response.data.data[0]);
                    const combinedSortedSeats = [
                      ...sortedSelectedSeats2,
                      ...sortedSelectedSeats,
                    ];

                    const sortedCombinedSeats = combinedSortedSeats.sort(
                      (a, b) => a - b
                    );

                    const reservedSeats = Object.keys(
                      response.data.data[0]
                    ).map((seat) => parseInt(seat));

                    const matchedSeats = Object.values(
                      response.data.data[1]
                    ).filter(
                      (seat) =>
                        reservedSeats.includes(seat.seat_number) &&
                        seat.numStatus === 5
                    );
                    const resultArray1 = matchedSeats.map((seat) => ({
                      seat_number: seat.seat_number,
                      id_system: seat.id_system,
                      numStatus: seat.numStatus,
                    }));
                    const reservationSeats = response.data.data[0];
                    const seatStatusAuth = response.data.data[1];
                    Object.keys(response.data.data[0]).forEach((seatNumber) => {
                      const status = reservationSeats[seatNumber];
             
                      if (status === 5 && !seatStatusAuth[seatNumber]) {
                        resultArray1.push({
                          seat_number: parseInt(seatNumber),
                          id_system: 1,
                          numStatus: status,
                          id_client: 0,
                          status: "Selected",
                          transitStatus: null,
                          id_transit: idtrip,
                          trip_id: TripID.id,
                        });
                      }
                    });
                    // Filter resultArray1 to find seats reserved by the current user
                    const currentUserReservedSeats = resultArray1.filter(
                      (userSeat) =>
                        userSeat.id_system === user.user_id &&
                        userSeat.numStatus == 5
                    );
                    const currentUserReservedSeatNumbers =
                      currentUserReservedSeats.map((seat) => seat.seat_number);
                    setSelectedSeats(currentUserReservedSeatNumbers);
                    setSelected(resultArray1);
                    setSeatAndUser(resultArray1);
                    setResultArrayseatuser(resultArray1);
                    setResultArray(sortedCombinedSeats);

                    setValueinput(sortedCombinedSeats.join("+"));
                  } else {
                  }
                })
                .catch((error) => {
                  // Handle the error here
                  setResponse1([]);
                  axios
                    .get(`${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`, {
                      withCredentials: true,
                    })
                    .then((response) => {
                      if (response.status === 200) {
                        const mappedById =
                          response.data.data.reservations.reduce(
                            (acc, item) => {
                              acc[item.id] = item;
                              return acc;
                            },
                            {}
                          );
                        setResponse1(response.data.data.reservation_seats);
                        setArrayReservations(mappedById);
                        setSelected(
                          Object.values(response.data.data.seatStatusAuth)
                        );
                      }
                    });
                });
            } catch (error) {
              if (
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                setResponse1([]);
                await axios
                  .get(`${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`, {
                    withCredentials: true,
                  })
                  .then((response) => {
                    if (response.status === 200) {
                      const mappedById = response.data.data.reservations.reduce(
                        (acc, item) => {
                          acc[item.id] = item;
                          return acc;
                        },
                        {}
                      );
                      setResponse1(response.data.data.reservation_seats);
                      setArrayReservations(mappedById);
                      setSelected(
                        Object.values(response.data.data.seatStatusAuth)
                      );
                    }
                  });
              }
            }
          } else if (reservationseat1[seatNumber] == 0) {
            const data = {
              triptable_id: idTransit,
              tripid: TripID.id,
              triptable_type: "Domain\\TripTransit",
              seat_number: [seatNumber],
              status: 5,
              branch: TripID.id_branch,
            };
      
            updatedSelectedSeats2 = selectedSeats1.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );
            const sortedSelectedSeats2 = updatedSelectedSeats2
              .filter((number) => number !== 0)
              .sort((a, b) => a - b);
            updatedSelectedSeats = selectedSeats?.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );
            const sortedSelectedSeats = updatedSelectedSeats
              .filter((number) => number !== 0)
              .sort((a, b) => a - b);
            setSelectedSeats(sortedSelectedSeats);
            const combinedSortedSeats = [
              ...sortedSelectedSeats2,
              ...sortedSelectedSeats,
            ];

            const sortedCombinedSeats = combinedSortedSeats.sort(
              (a, b) => a - b
            );
            try {
              axios
                .post(`${BaseUrl}/changeStatusSeat`, data, {
                  withCredentials: true,
                })
                .then((response) => {
                  if (response.status === 200) {
                    setSelectedSeats(sortedSelectedSeats);
                    const mappedById = response.data.reservations.reduce(
                      (acc, item) => {
                        acc[item.id] = item;
                        return acc;
                      },
                      {}
                    );
                    setArrayReservations(mappedById);
                    if (selectedTransitIndex == 0) {
                      let seatWithPassengerTransit1 = [];
                      // Safely access the reservationSeats property
                      seatWithPassengerTransit1 = Object.keys(
                        response.data.data[0]
                      ).reduce((accumulator, currentKey) => {
                        // Assuming you want to do something with the keys here
                        const reservationInfo = response.data.reservations.find(
                          (reservation) =>
                            reservation.reservation_seats_number &&
                            reservation.reservation_seats_number[currentKey] > 0
                        );

                        const passenger = reservationInfo
                          ? reservationInfo.passengers?.find(
                              (p) => p.number_seat == currentKey
                            )
                          : null;

                        accumulator[currentKey] = {
                          passengers: passenger
                            ? Object.assign({}, passenger)
                            : null, // safely copy passenger object
                          status:
                            reservationInfo?.reservation_seats_number[
                              currentKey
                            ],
                          id_reservation: reservationInfo?.id,
                        };

                        return accumulator;
                      }, {});
                      // setInitialIdTransit(TripID.tripTransit[0].id);
                      // seatWithPassengerTransit1.id = TripID.tripTransit[0].id;
                      const exampleData = {
                        ...seatWithPassengerTransit1, // This is your existing seat data
                        id: TripID.tripTransit[0].id, // Ensure you are actually setting the id like this
                      };
                      setArraySeatPassengerTransit1(exampleData);
                    }
                    if (selectedTransitIndex == 1) {
                      let seatWithPassengerTransit2 = [];
                      // Safely access the reservationSeats property
                      seatWithPassengerTransit2 = Object.keys(
                        response.data.data[0]
                      ).reduce((accumulator, currentKey) => {
                        // Assuming you want to do something with the keys here
                        const reservationInfo = response.data.reservations.find(
                          (reservation) =>
                            reservation.reservation_seats_number &&
                            reservation.reservation_seats_number[currentKey] > 0
                        );

                        const passenger = reservationInfo
                          ? reservationInfo.passengers?.find(
                              (p) => p.number_seat == currentKey
                            )
                          : null;

                        accumulator[currentKey] = {
                          passengers: passenger
                            ? Object.assign({}, passenger)
                            : null, // safely copy passenger object
                          status:
                            reservationInfo?.reservation_seats_number[
                              currentKey
                            ],
                          id_reservation: reservationInfo?.id,
                        };

                        return accumulator;
                      }, {});
                      // setInitialIdTransit(TripID.tripTransit[0].id);
                      // seatWithPassengerTransit1.id = TripID.tripTransit[0].id;
                      const exampleData = {
                        ...seatWithPassengerTransit2, // This is your existing seat data
                        id: TripID.tripTransit[1].id, // Ensure you are actually setting the id like this
                      };
                      setArraySeatPassengerTransit2(exampleData);
                    }
                    setReservationseat1(response.data.data[0]);
                    const combinedSortedSeats = [
                      ...sortedSelectedSeats2,
                      ...sortedSelectedSeats,
                    ];

                    const sortedCombinedSeats = combinedSortedSeats.sort(
                      (a, b) => a - b
                    );

                    const reservedSeats = Object.keys(
                      response.data.data[0]
                    ).map((seat) => parseInt(seat));

                    const matchedSeats = Object.values(
                      response.data.data[1]
                    ).filter(
                      (seat) =>
                        reservedSeats.includes(seat.seat_number) &&
                        seat.numStatus === 5
                    );
                    const resultArray1 = matchedSeats.map((seat) => ({
                      seat_number: seat.seat_number,
                      id_system: seat.id_system,
                      numStatus: seat.numStatus,
                    }));
                    const reservationSeats = response.data.data[0];
                    const seatStatusAuth = response.data.data[1];
                    Object.keys(response.data.data[0]).forEach((seatNumber) => {
                      const status = reservationSeats[seatNumber];
             
                      if (status === 5 && !seatStatusAuth[seatNumber]) {
                        resultArray1.push({
                          seat_number: parseInt(seatNumber),
                          id_system: 1,
                          numStatus: status,
                          id_client: 0,
                          status: "Selected",
                          transitStatus: null,
                          id_transit: idtrip,
                          trip_id: TripID.id,
                        });
                      }
                    });
                    // Filter resultArray1 to find seats reserved by the current user
                    const currentUserReservedSeats = resultArray1.filter(
                      (userSeat) =>
                        userSeat.id_system === user.user_id &&
                        userSeat.numStatus == 5
                    );
                    const currentUserReservedSeatNumbers =
                      currentUserReservedSeats.map((seat) => seat.seat_number);
                    setSelectedSeats(currentUserReservedSeatNumbers);
                    setSelected(resultArray1);
                    setSeatAndUser(resultArray1);
                    setResultArrayseatuser(resultArray1);
                    setResultArray(sortedCombinedSeats);

                    setValueinput(sortedCombinedSeats.join("+"));
                  } else {
                  }
                })
                .catch(async (error) => {
                  // Handle the error here

                  const response = await axios.get(
                    `${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`,
                    {
                      withCredentials: true,
                    }
                  );

                  if (response.status === 200) {
                    const mappedById = response.data.data.reservations.reduce(
                      (acc, item) => {
                        acc[item.id] = item;
                        return acc;
                      },
                      {}
                    );

                    setResponse1(response.data.data.reservation_seats);
                    setArrayReservations(mappedById);
                    setSelected(
                      Object.values(response.data.data.seatStatusAuth)
                    );
                  }

                  // axios.get(`${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`, { withCredentials: true })
                  // .then((response) => {
                  //   if (response.status === 200) {
                  //     const mappedById = response.data.data.reservations.reduce((acc, item) => {
                  //       acc[item.id] = item;
                  //       return acc;
                  //     }, {});
                  //     setResponse1(response.data.data.reservation_seats);
                  //     setArrayReservations(mappedById);
                  //   }
                  // })
                  // .catch((error) => {
                  //   console.error("Error fetching data:", error);
                  //   // Handle any errors here, such as updating state to show an error message
                  // });
                  // await axios.get(
                  //   `${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`,
                  //   {
                  //     withCredentials: true,
                  //   }
                  // ).then((response)=>{
                  // if(response.status === 200) {
                  // const mappedById = response.data.data.reservations.reduce((acc, item) => {
                  //   acc[item.id] = item;
                  //   return acc;
                  // }, {});
                  // setResponse1(response.data.data.reservation_seats);
                  // setArrayReservations(mappedById);
                  // }
                  // })
                });
            } catch (error) {
              if (
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                setResponse1([]);
                await axios
                  .get(`${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`, {
                    withCredentials: true,
                  })
                  .then((response) => {
                    if (response.status === 200) {
                      const mappedById = response.data.data.reservations.reduce(
                        (acc, item) => {
                          acc[item.id] = item;
                          return acc;
                        },
                        {}
                      );
                      setResponse1(response.data.data.reservation_seats);
                      setArrayReservations(mappedById);
                      setSelected(
                        Object.values(response.data.data.seatStatusAuth)
                      );
                    }
                  });
              }
            }
          }
        } else {
          if (newSeatStatusnew == 3 || newSeatStatusnew == 4) {
            updatedSelectedSeats2 = selectedSeats1.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );
            const sortedSelectedSeats2 = updatedSelectedSeats2
              .filter((number) => number !== 0) // Remove 0 if needed
              .sort((a, b) => a - b);
            updatedSelectedSeats = selectedSeats?.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );
            const sortedSelectedSeats = updatedSelectedSeats
              .filter((number) => number !== 0) // Remove 0 if needed
              .sort((a, b) => a - b);
            // Temporarily use this logic on an action to test state accumulation
            setSelectedSeats1(sortedSelectedSeats2);
            const combinedSortedSeats = [
              ...sortedSelectedSeats2,
              ...sortedSelectedSeats,
            ];

            const sortedCombinedSeats = combinedSortedSeats.sort(
              (a, b) => a - b
            );
            setValueinput(sortedCombinedSeats.join("+"));
          } else {
            updatedSelectedSeats = selectedSeats.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );
            updatedSelectedSeats2 = selectedSeats1.filter(
              (selectedSeat) => selectedSeat !== seatNumber
            );
            const sortedSelectedSeats2 = updatedSelectedSeats2
              .filter((number) => number !== 0) // Remove 0 if needed
              .sort((a, b) => a - b);
            const sortedSelectedSeats = updatedSelectedSeats
              .filter((number) => number !== 0) // Remove 0 if needed
              .sort((a, b) => a - b);
            // Temporarily use this logic on an action to test state accumulation
            setSelectedSeats1(sortedSelectedSeats2);
            const combinedSortedSeats = [
              ...sortedSelectedSeats2,
              ...sortedSelectedSeats,
            ];

            const sortedCombinedSeats = combinedSortedSeats.sort(
              (a, b) => a - b
            );
            setValueinput(sortedCombinedSeats.join("+"));
          }
        }
      }
    } else {
      const lastSelectedSeat = selectedSeats[selectedSeats.length - 1];
      const lastSelectedSeatStatus = lastSelectedSeat
        ? response1[lastSelectedSeat]
        : null;
      if (
        (lastSelectedSeatStatus == 6 && newSeatStatus == 6) ||
        (lastSelectedSeatStatus == null && newSeatStatus == 6)
      ) {
        if (transet) {
          let firstSeatReservationId = getReservationIdForSeat(
            seatNumber,
            arrayReservations,
            idtrip
          );
          // Find the reservation ID of the new seat
          let newSeatReservationId = getReservationIdForSeat(
            seatNumber,
            arrayReservations,
            idtrip
          );

          // Check if the new seat belongs to the same reservation
          if (firstSeatReservationId !== newSeatReservationId) {
            return;
          } else {
            if (
              reservationseat1[seatNumber] === 3 ||
              reservationseat1[seatNumber] === 4
            ) {
              updatedSelectedSeats2 = [...selectedSeats1, seatNumber];
              updatedSelectedSeats = selectedSeats;
              setClickFunction(true);
              const sortedSelectedSeats2 = updatedSelectedSeats2
                .filter((number) => number !== 0) // Remove 0 if needed
                .sort((a, b) => a - b);
              const sortedSelectedSeats = updatedSelectedSeats
                .filter((number) => number !== 0) // Remove 0 if needed
                .sort((a, b) => a - b);
              // Temporarily use this logic on an action to test state accumulation

              // Update the selected seats and resultArray
              const combinedSortedSeats = [
                ...updatedSelectedSeats,
                ...updatedSelectedSeats2,
              ];

              setSelectedSeats1(sortedSelectedSeats2);
              setResultArray(combinedSortedSeats);
              const sortedCombinedSeats = combinedSortedSeats.sort(
                (a, b) => a - b
              );
              setValueinput(combinedSortedSeats.join("+"));
            } else if (reservationseat1[seatNumber] === 1) {
              updatedSelectedSeats = [...selectedSeats, seatNumber];
              updatedSelectedSeats2 = selectedSeats1;
              setClickFunction(true);
              const sortedSelectedSeats = updatedSelectedSeats
                .filter((number) => number !== 0) // Remove 0 if needed
                .sort((a, b) => a - b);
              const sortedSelectedSeats2 = updatedSelectedSeats2
                .filter((number) => number !== 0) // Remove 0 if needed
                .sort((a, b) => a - b);
              // Temporarily use this logic on an action to test state accumulation

              // Update the selected seats and resultArray
              const combinedSortedSeats = [
                ...updatedSelectedSeats,
                ...updatedSelectedSeats2,
              ];

              setSelectedSeats(sortedSelectedSeats);
              setResultArray(combinedSortedSeats);

              const sortedCombinedSeats = combinedSortedSeats.sort(
                (a, b) => a - b
              );
              setValueinput(combinedSortedSeats.join("+"));
            } else if (reservationseat1[seatNumber] == 2) {
              updatedSelectedSeats3 = [...selectedSeats2, seatNumber];
              setClickFunction(true);
              const sortedSelectedSeats = updatedSelectedSeats3
                .filter((number) => number !== 0) // Remove 0 if needed
                .sort((a, b) => a - b);

              // Update the selected seats and resultArray
              setSelectedSeats2(sortedSelectedSeats);
              setResultArray(sortedSelectedSeats);
              const newValueInput = sortedSelectedSeats.join("+");
              setValueinput(newValueInput);
            }
            
            else if (reservationseat1[seatNumber] == 0) {
              setClickFunction(true);
              updatedSelectedSeats = [...selectedSeats, seatNumber];
              setClickFunction(true);
              const sortedSelectedSeats = updatedSelectedSeats
                .filter((number) => number !== 0) // Remove 0 if needed
                .sort((a, b) => a - b);
              updatedSelectedSeats2 = [...selectedSeats1];

              const sortedSelectedSeats2 = updatedSelectedSeats2
                .filter((number) => number !== 0) // Remove 0 if needed
                .sort((a, b) => a - b);
              const data = {
                triptable_id: idTransit,
                tripid: TripID.id,
                triptable_type: "Domain\\TripTransit",
                seat_number: [seatNumber],
                status: 5,
                branch: TripID.id_branch,
              };

              try {
                axios
                  .post(`${BaseUrl}/changeStatusSeat`, data, {
                    withCredentials: true,
                  })
                  .then((response) => {
                    if (response.status === 200) {
                      const mappedById = response.data.reservations.reduce(
                        (acc, item) => {
                          acc[item.id] = item;
                          return acc;
                        },
                        {}
                      );
                      setArrayReservations(mappedById);
                      if (selectedTransitIndex == 0) {
                        let seatWithPassengerTransit1 = [];
                        // Safely access the reservationSeats property
                        seatWithPassengerTransit1 = Object.keys(
                          response.data.data[0]
                        ).reduce((accumulator, currentKey) => {
                          // Assuming you want to do something with the keys here
                          const reservationInfo =
                            response.data.reservations.find(
                              (reservation) =>
                                reservation.reservation_seats_number &&
                                reservation.reservation_seats_number[
                                  currentKey
                                ] > 0
                            );

                          const passenger = reservationInfo
                            ? reservationInfo.passengers?.find(
                                (p) => p.number_seat == currentKey
                              )
                            : null;

                          accumulator[currentKey] = {
                            passengers: passenger
                              ? Object.assign({}, passenger)
                              : null, // safely copy passenger object
                            status:
                              reservationInfo?.reservation_seats_number[
                                currentKey
                              ],
                            id_reservation: reservationInfo?.id,
                          };

                          return accumulator;
                        }, {});
                        // setInitialIdTransit(TripID.tripTransit[0].id);
                        // seatWithPassengerTransit1.id = TripID.tripTransit[0].id;
                        const exampleData = {
                          ...seatWithPassengerTransit1, // This is your existing seat data
                          id: TripID.tripTransit[0].id, // Ensure you are actually setting the id like this
                        };
                        setArraySeatPassengerTransit1(exampleData);
                      }
                      if (selectedTransitIndex == 1) {
                        let seatWithPassengerTransit2 = [];
                        // Safely access the reservationSeats property
                        seatWithPassengerTransit2 = Object.keys(
                          response.data.data[0]
                        ).reduce((accumulator, currentKey) => {
                          // Assuming you want to do something with the keys here
                          const reservationInfo =
                            response.data.reservations.find(
                              (reservation) =>
                                reservation.reservation_seats_number &&
                                reservation.reservation_seats_number[
                                  currentKey
                                ] > 0
                            );

                          const passenger = reservationInfo
                            ? reservationInfo.passengers?.find(
                                (p) => p.number_seat == currentKey
                              )
                            : null;

                          accumulator[currentKey] = {
                            passengers: passenger
                              ? Object.assign({}, passenger)
                              : null, // safely copy passenger object
                            status:
                              reservationInfo?.reservation_seats_number[
                                currentKey
                              ],
                            id_reservation: reservationInfo?.id,
                          };

                          return accumulator;
                        }, {});
                        // setInitialIdTransit(TripID.tripTransit[0].id);
                        // seatWithPassengerTransit1.id = TripID.tripTransit[0].id;
                        const exampleData = {
                          ...seatWithPassengerTransit2, // This is your existing seat data
                          id: TripID.tripTransit[1].id, // Ensure you are actually setting the id like this
                        };
                        setArraySeatPassengerTransit2(exampleData);
                      }

                      setSelectedSeats(sortedSelectedSeats);
                      setReservationseat1(response.data.data[0]);
                      const combinedSortedSeats = [
                        ...sortedSelectedSeats2,
                        ...sortedSelectedSeats,
                      ];

                      const sortedCombinedSeats = combinedSortedSeats.sort(
                        (a, b) => a - b
                      );

                      const reservedSeats = Object.keys(
                        response.data.data[0]
                      ).map((seat) => parseInt(seat));

                      const matchedSeats = Object.values(
                        response.data.data[1]
                      ).filter(
                        (seat) =>
                          reservedSeats.includes(seat.seat_number) &&
                          seat.numStatus === 5
                      );
                      const resultArray1 = matchedSeats.map((seat) => ({
                        seat_number: seat.seat_number,
                        id_system: seat.id_system,
                        numStatus: seat.numStatus,
                      }));
                      const reservationSeats = response.data.data[0];
                      const seatStatusAuth = response.data.data[1];
                      Object.keys(response.data.data[0]).forEach((seatNumber) => {
                        const status = reservationSeats[seatNumber];
               
                        if (status === 5 && !seatStatusAuth[seatNumber]) {
                          resultArray1.push({
                            seat_number: parseInt(seatNumber),
                            id_system: 1,
                            numStatus: status,
                            id_client: 0,
                            status: "Selected",
                            transitStatus: null,
                            id_transit: idtrip,
                            trip_id: TripID.id,
                          });
                        }
                      });
                      const currentUserReservedSeats = resultArray1.filter(
                        (userSeat) =>
                          userSeat.id_system === user.user_id &&
                          userSeat.numStatus == 5
                      );
                      const currentUserReservedSeatNumbers =
                        currentUserReservedSeats.map(
                          (seat) => seat.seat_number
                        );
                      setSelectedSeats(currentUserReservedSeatNumbers);
                      setSeatAndUser(resultArray1);
                      setSelected(resultArray1);
                      setResultArrayseatuser(resultArray1);
                      setResultArray(sortedCombinedSeats);
                      setValueinput(sortedCombinedSeats.join("+"));
                    } else {
                    }
                  })
                  .catch((error) => {
                    // Handle the error here
                    setResponse1([]);
                    axios
                      .get(`${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`, {
                        withCredentials: true,
                      })
                      .then((response) => {
                        if (response.status === 200) {
                          const mappedById =
                            response.data.data.reservations.reduce(
                              (acc, item) => {
                                acc[item.id] = item;
                                return acc;
                              },
                              {}
                            );
                          setResponse1(response.data.data.reservation_seats);
                          setArrayReservations(mappedById);
                          setSelected(
                            Object.values(response.data.data.seatStatusAuth)
                          );
                        }
                      });
                  });
              } catch (error) {
                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                ) {
                  setResponse1([]);
                  await axios
                    .get(`${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`, {
                      withCredentials: true,
                    })
                    .then((response) => {
                      if (response.status === 200) {
                        const mappedById =
                          response.data.data.reservations.reduce(
                            (acc, item) => {
                              acc[item.id] = item;
                              return acc;
                            },
                            {}
                          );
                        setResponse1(response.data.data.reservation_seats);
                        setArrayReservations(mappedById);
                        setSelected(
                          Object.values(response.data.data.seatStatusAuth)
                        );
                      }
                    });
                  
                }
              }
              setSelectedSeats(sortedSelectedSeats);
              setResultArray(sortedSelectedSeats);
            } else if (reservationseat1[seatNumber] == 5) {
              setClickFunction(true);
              updatedSelectedSeats = [...selectedSeats, seatNumber];
              setClickFunction(true);
              const sortedSelectedSeats = updatedSelectedSeats
                .filter((number) => number !== 0) // Remove 0 if needed
                .sort((a, b) => a - b);
              updatedSelectedSeats2 = [...selectedSeats1];

              const sortedSelectedSeats2 = updatedSelectedSeats2
                .filter((number) => number !== 0) // Remove 0 if needed
                .sort((a, b) => a - b);
              const data = {
                triptable_id: idTransit,
                tripid: TripID.id,
                triptable_type: "Domain\\TripTransit",
                seat_number: [seatNumber],
                status: 0,
                branch: TripID.id_branch,
              };

           
              try {
                axios
                  .post(`${BaseUrl}/changeStatusSeat`, data, {
                    withCredentials: true,
                  })
                  .then((response) => {
                    if (response.status === 200) {
                      setSelectedSeats(sortedSelectedSeats);
                      const mappedById = response.data.reservations.reduce(
                        (acc, item) => {
                          acc[item.id] = item;
                          return acc;
                        },
                        {}
                      );
                      setArrayReservations(mappedById);
                      if (selectedTransitIndex == 0) {
                        let seatWithPassengerTransit1 = [];
                        // Safely access the reservationSeats property
                        seatWithPassengerTransit1 = Object.keys(
                          response.data.data[0]
                        ).reduce((accumulator, currentKey) => {
                          // Assuming you want to do something with the keys here
                          const reservationInfo =
                            response.data.reservations.find(
                              (reservation) =>
                                reservation.reservation_seats_number &&
                                reservation.reservation_seats_number[
                                  currentKey
                                ] > 0
                            );

                          const passenger = reservationInfo
                            ? reservationInfo.passengers?.find(
                                (p) => p.number_seat == currentKey
                              )
                            : null;

                          accumulator[currentKey] = {
                            passengers: passenger
                              ? Object.assign({}, passenger)
                              : null, // safely copy passenger object
                            status:
                              reservationInfo?.reservation_seats_number[
                                currentKey
                              ],
                            id_reservation: reservationInfo?.id,
                          };

                          return accumulator;
                        }, {});
                        // setInitialIdTransit(TripID.tripTransit[0].id);
                        // seatWithPassengerTransit1.id = TripID.tripTransit[0].id;
                        const exampleData = {
                          ...seatWithPassengerTransit1, // This is your existing seat data
                          id: TripID.tripTransit[0].id, // Ensure you are actually setting the id like this
                        };
                        setArraySeatPassengerTransit1(exampleData);
                      }
                      if (selectedTransitIndex == 1) {
                        let seatWithPassengerTransit2 = [];
                        // Safely access the reservationSeats property
                        seatWithPassengerTransit2 = Object.keys(
                          response.data.data[0]
                        ).reduce((accumulator, currentKey) => {
                          // Assuming you want to do something with the keys here
                          const reservationInfo =
                            response.data.reservations.find(
                              (reservation) =>
                                reservation.reservation_seats_number &&
                                reservation.reservation_seats_number[
                                  currentKey
                                ] > 0
                            );

                          const passenger = reservationInfo
                            ? reservationInfo.passengers?.find(
                                (p) => p.number_seat == currentKey
                              )
                            : null;

                          accumulator[currentKey] = {
                            passengers: passenger
                              ? Object.assign({}, passenger)
                              : null, // safely copy passenger object
                            status:
                              reservationInfo?.reservation_seats_number[
                                currentKey
                              ],
                            id_reservation: reservationInfo?.id,
                          };

                          return accumulator;
                        }, {});
                        // setInitialIdTransit(TripID.tripTransit[0].id);
                        // seatWithPassengerTransit1.id = TripID.tripTransit[0].id;
                        const exampleData = {
                          ...seatWithPassengerTransit2, // This is your existing seat data
                          id: TripID.tripTransit[1].id, // Ensure you are actually setting the id like this
                        };
                        setArraySeatPassengerTransit2(exampleData);
                      }
                      setReservationseat1(response.data.data[0]);
                      const combinedSortedSeats = [
                        ...sortedSelectedSeats2,
                        ...sortedSelectedSeats,
                      ];

                      const sortedCombinedSeats = combinedSortedSeats.sort(
                        (a, b) => a - b
                      );

                      const reservedSeats = Object.keys(
                        response.data.data[0]
                      ).map((seat) => parseInt(seat));

                      const matchedSeats = Object.values(
                        response.data.data[1]
                      ).filter(
                        (seat) =>
                          reservedSeats.includes(seat.seat_number) &&
                          seat.numStatus === 5
                      );
                      const resultArray1 = matchedSeats.map((seat) => ({
                        seat_number: seat.seat_number,
                        id_system: seat.id_system,
                        numStatus: seat.numStatus,
                      }));
                      const reservationSeats = response.data.data[0];
                      const seatStatusAuth = response.data.data[1];
                      Object.keys(response.data.data[0]).forEach((seatNumber) => {
                        const status = reservationSeats[seatNumber];
               
                        if (status === 5 && !seatStatusAuth[seatNumber]) {
                          resultArray1.push({
                            seat_number: parseInt(seatNumber),
                            id_system: 1,
                            numStatus: status,
                            id_client: 0,
                            status: "Selected",
                            transitStatus: null,
                            id_transit: idtrip,
                            trip_id: TripID.id,
                          });
                        }
                      });
                      const currentUserReservedSeats = resultArray1.filter(
                        (userSeat) =>
                          userSeat.id_system === user.user_id &&
                          userSeat.numStatus == 5
                      );
                      const currentUserReservedSeatNumbers =
                        currentUserReservedSeats.map(
                          (seat) => seat.seat_number
                        );
                      setSelectedSeats(currentUserReservedSeatNumbers);
                      setSeatAndUser(resultArray1);
                      setSelected(resultArray1);
                      setResultArrayseatuser(resultArray1);
                      setResultArray(sortedCombinedSeats);
                      setValueinput(sortedCombinedSeats.join("+"));
                    } else {
                    }
                  })
                  .catch((error) => {
                    // Handle the error here
                    setResponse1([]);
                    axios
                      .get(`${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`, {
                        withCredentials: true,
                      })
                      .then((response) => {
                        if (response.status === 200) {
                          const mappedById =
                            response.data.data.reservations.reduce(
                              (acc, item) => {
                                acc[item.id] = item;
                                return acc;
                              },
                              {}
                            );
                          setResponse1(response.data.data.reservation_seats);
                          setArrayReservations(mappedById);
                          setSelected(
                            Object.values(response.data.data.seatStatusAuth)
                          );
                        }
                      });
                  });
              } catch (error) {
                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                ) {
                  setResponse1([]);
                  await axios
                    .get(`${BaseUrl}/getTrip/${TripID.id}?branch=${branch}`, {
                      withCredentials: true,
                    })
                    .then((response) => {
                      if (response.status === 200) {
                        const mappedById =
                          response.data.data.reservations.reduce(
                            (acc, item) => {
                              acc[item.id] = item;
                              return acc;
                            },
                            {}
                          );
                        setResponse1(response.data.data.reservation_seats);
                        setArrayReservations(mappedById);
                        setSelected(
                          Object.values(response.data.data.seatStatusAuth)
                        );
                      }
                    });
             
                }
              }
              setSelectedSeats(sortedSelectedSeats);
              setResultArray(sortedSelectedSeats);
            }
          }
        } else if (direct) {
          let firstSeatReservationId = getReservationIdForSeat(
            selectedSeats[0],
            arrayReservations,
            idtrip
          );

          // Find the reservation ID of the new seat
          let newSeatReservationId = getReservationIdForSeat(
            seatNumber,
            arrayReservations,
            idtrip
          );

          // Check if the new seat belongs to the same reservation
          if (firstSeatReservationId !== newSeatReservationId) {
            return;
          } else {
            updatedSelectedSeats = [...selectedSeats, seatNumber];
            setClickFunction(true);
            const sortedSelectedSeats = updatedSelectedSeats
              .filter((number) => number !== 0) // Remove 0 if needed
              .sort((a, b) => a - b);

            // Update the selected seats and resultArray
            setSelectedSeats(sortedSelectedSeats);
            setResultArray(sortedSelectedSeats);
            const newValueInput = sortedSelectedSeats.join("+");
            setValueinput(newValueInput);
          }
        }
      }

      if (
        (lastSelectedSeatStatus == 2 && newSeatStatus == 2) ||
        (lastSelectedSeatStatus == null && newSeatStatus == 2)
      ) {
        setStatusSeat(2);
        let firstSeatReservationId = getReservationIdForSeat(
          selectedSeats2[0],
          arrayReservations,
          idtrip
        );

        // Find the reservation ID of the new seat
        let newSeatReservationId = getReservationIdForSeat(
          seatNumber,
          arrayReservations,
          idtrip
        );
        // Check if the new seat belongs to the same reservation
        if (
          firstSeatReservationId !== newSeatReservationId &&
          firstSeatReservationId !== null
        ) {
          return;
        } else {
          updatedSelectedSeats3 = [...selectedSeats2, seatNumber];
          setClickFunction(true);
          const sortedSelectedSeats = updatedSelectedSeats3
            .filter((number) => number !== 0) // Remove 0 if needed
            .sort((a, b) => a - b);

          // Update the selected seats and resultArray
          setSelectedSeats2(sortedSelectedSeats);
          setResultArray(sortedSelectedSeats);
          const newValueInput = sortedSelectedSeats.join("+");
          setValueinput(newValueInput);
        }
      }

      if (
        (lastSelectedSeatStatus == null &&
          newSeatStatus == 3 &&
          newSeatStatus !== lastSelectedSeatStatus) ||
        (newSeatStatus == 4 && newSeatStatus !== lastSelectedSeatStatus) ||
        (newSeatStatus == 4 && lastSelectedSeatStatus == null) ||
        newSeatStatus == 3
      ) {
        setStatusSeat(3);
        updatedSelectedSeats2 = [...selectedSeats1, seatNumber];
        updatedSelectedSeats = selectedSeats;
        setClickFunction(true);
        const sortedSelectedSeats2 = updatedSelectedSeats2
          .filter((number) => number !== 0) // Remove 0 if needed
          .sort((a, b) => a - b);
        const sortedSelectedSeats = updatedSelectedSeats
          .filter((number) => number !== 0) // Remove 0 if needed
          .sort((a, b) => a - b);
        // Temporarily use this logic on an action to test state accumulation

        // Update the selected seats and resultArray
        const combinedSortedSeats = [
          ...updatedSelectedSeats,
          ...updatedSelectedSeats2,
        ];

        setSelectedSeats1(sortedSelectedSeats2);
        setResultArray(combinedSortedSeats);
        const sortedCombinedSeats = combinedSortedSeats.sort((a, b) => a - b);
        setValueinput(combinedSortedSeats.join("+"));
      }
      if (
        (lastSelectedSeatStatus == null &&
          newSeatStatus == 3 &&
          newSeatStatus !== lastSelectedSeatStatus) ||
        (newSeatStatus == 1 && newSeatStatus !== lastSelectedSeatStatus) ||
        (newSeatStatus == 1 && lastSelectedSeatStatus == null) ||
        newSeatStatus == 1
      ) {
        setStatusSeat(1);
        updatedSelectedSeats = [...selectedSeats, seatNumber];
        updatedSelectedSeats2 = selectedSeats1;
        setClickFunction(true);
        const sortedSelectedSeats = updatedSelectedSeats
          .filter((number) => number !== 0) // Remove 0 if needed
          .sort((a, b) => a - b);
        const sortedSelectedSeats2 = updatedSelectedSeats2
          .filter((number) => number !== 0) // Remove 0 if needed
          .sort((a, b) => a - b);
        // Temporarily use this logic on an action to test state accumulation

        // Update the selected seats and resultArray
        const combinedSortedSeats = [
          ...updatedSelectedSeats,
          ...updatedSelectedSeats2,
        ];

        setSelectedSeats(sortedSelectedSeats);
        setResultArray(combinedSortedSeats);
        const sortedCombinedSeats = combinedSortedSeats.sort((a, b) => a - b);
        setValueinput(combinedSortedSeats.join("+"));
      } else if (newSeatStatus == 0) {
       
        setStatusSeat(0);
        updatedSelectedSeats = [...uniqueSelectedSeats, seatNumber];
        setClickFunction(true);

        updatedSelectedSeats2 = [...uniqueSelectedSeats1];

        const sortedSelectedSeats2 = updatedSelectedSeats2
          .filter((number) => number !== 0)
          .sort((a, b) => a - b);
        const sortedSelectedSeats = updatedSelectedSeats
          .filter((number) => number !== 0)
          .sort((a, b) => a - b);
        const data = {
          triptable_id: TripID.id,
          tripid: TripID.id,
          triptable_type: "Domain\\Trip",
          seat_number: [seatNumber],
          status: 5,
          branch: TripID.id_branch,
        };


        try {
          axios
            .post(`${BaseUrl}/changeStatusSeat`, data, {
              withCredentials: true,
            })
            .then((response) => {
              if (response.status === 200) {
                setSelectedSeats(sortedSelectedSeats);
                setResponse1(response.data.data[0]);
                const mappedById = response.data.reservations.reduce(
                  (acc, item) => {
                    acc[item.id] = item;
                    return acc;
                  },
                  {}
                );
                setArrayReservations(mappedById);
                let passenger;
                let seatWithPassengerNull = Object.keys(
                  response.data.data[0]
                ).reduce((accumulator, currentKey) => {
                  const reservationInfo = response.data.reservations.find(
                    (reservation) =>
                      reservation.reservation_seats_number[currentKey] > 0
                  );
                  passenger = reservationInfo
                    ? reservationInfo.passengers?.find(
                        (p) => p.number_seat == currentKey
                      )
                    : null;

                  accumulator[currentKey] = {
                    passengers: Object(passenger),
                    status:
                      reservationInfo?.reservation_seats_number[currentKey],
                    id_reservation: reservationInfo?.id,
                  };
                  return accumulator;
                }, {});

                setArraySeat(seatWithPassengerNull);
                const seatStatusData = response.data.data[0];

                // Extract seat numbers with status 6
                const seatsWithStatusSix = Object.keys(seatStatusData).filter(
                  (seatNumber) => seatStatusData[seatNumber] === 6
                );

                // Convert to integers, since keys are strings
                const seatNumbersToInt = seatsWithStatusSix.map(Number);

                // Update selectedSeats2 state to include these seat numbers
                setSeatsArray2(seatNumbersToInt);
                setCommonSeats(
                  seatsArray1.filter((seat) => seatNumbersToInt.includes(seat))
                );

      
                let combinedSortedSeats = [
                  ...sortedSelectedSeats2,
                  ...sortedSelectedSeats,
                ].sort((a, b) => a - b);

                // Function to remove duplicates
                function removeDuplicates(arr) {
                  return [...new Set(arr)]; // Creates a new array from a Set constructed from the original array
                }

                // Apply removeDuplicates function
                const uniqueSortedSeats = removeDuplicates(combinedSortedSeats);

                const reservedSeats = Object.keys(response.data.data[0]).map(
                  (seat) => parseInt(seat)
                );

                const matchedSeats = Object.values(
                  response.data.data[1]
                ).filter(
                  (seat) =>
                    reservedSeats.includes(seat.seat_number) &&
                    seat.numStatus === 5
                );

                let resultArray2 = [];
                const addedSeatNumbers = new Set(); // Set to track added seat numbers
                const transitSeatNumbers = new Set();

                // Populate transitSeatNumbers as before
                TripID.tripTransit.forEach((transit) => {
                  Object.keys(transit.reservationSeats).forEach(
                    (seatNumber) => {
                      transitSeatNumbers.add(seatNumber);
                    }
                  );
                });
                const reservationSeats = response.data.data[0];
                const seatStatusAuth = response.data.data[1];
                Object.keys(response.data.data[0]).forEach((seatNumber) => {
                  const status = reservationSeats[seatNumber];
                  if (status === 5 && !seatStatusAuth[seatNumber]) {
                    resultArray2.push({
                      seat_number: parseInt(seatNumber),
                      id_system: 1,
                      numStatus: status,
                      id_client: 0,
                      status: "Selected",
                      transitStatus: null,
                      id_transit: null,
                      trip_id: idtrip,
                    });
                  }
                });
                Object.keys(response.data.data[1]).forEach((seatNumber) => {
                  const seatDetails = response.data.data[1][seatNumber];
                  if (
                    seatDetails.numStatus === 5 &&
                    response1[seatNumber] !== undefined &&
                    !transitSeatNumbers.has(seatNumber) &&
                    !addedSeatNumbers.has(seatNumber) // Check if the seat number has already been added
                  ) {
                    resultArray2.push({
                      seat_number: parseInt(seatNumber),
                      id_system: seatDetails.id_system,
                      numStatus: seatDetails.numStatus,
                    });
                    addedSeatNumbers.add(seatNumber); // Mark this seat number as added
                  }
                });

                // Filter resultArray1 to find seats reserved by the current user
                const currentUserReservedSeats = resultArray2.filter(
                  (userSeat) =>
                    userSeat.id_system === user.user_id &&
                    userSeat.numStatus == 5
                );

                const currentUserReservedSeatNumbers =
                  currentUserReservedSeats.map((seat) => seat.seat_number);
                setSelectedSeats(currentUserReservedSeatNumbers);
               
                setSeatAndUser(resultArray2);
                setSelected(resultArray2);
                setResultArrayseatuser(resultArray2);
                setResultArray(uniqueSortedSeats);
                setValueinput(uniqueSortedSeats.join("+"));
              } else {
                sweetalert({
                  text: response.data.message,
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                });
              }
            })
            .catch((error) => {
              // Handle the error here
              setResponse1([]);
              dispatch(fetchTripBYId(TripID.id));
              setResponse1(TripID.reservation_seats);
            });
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setResponse1([]);
            dispatch(fetchTripBYId(TripID.id));
            setResponse1(TripID.reservation_seats);
    
          }
        }
      } 
      else if (newSeatStatus == 5) {
        setStatusSeat(0);
        updatedSelectedSeats = [...uniqueSelectedSeats, seatNumber];
        setClickFunction(true);
  
        updatedSelectedSeats2 = [...uniqueSelectedSeats1];

        const sortedSelectedSeats2 = updatedSelectedSeats2
          .filter((number) => number !== 0)
          .sort((a, b) => a - b);
        const sortedSelectedSeats = updatedSelectedSeats
          .filter((number) => number !== 0)
          .sort((a, b) => a - b);
        const data = {
          triptable_id: TripID.id,
          tripid: TripID.id,
          triptable_type: "Domain\\Trip",
          seat_number: [seatNumber],
          status: 0,
          branch: TripID.id_branch,
        };



        try {
          axios
            .post(`${BaseUrl}/changeStatusSeat`, data, {
              withCredentials: true,
            })
            .then((response) => {
              if (response.status === 200) {
                setSelectedSeats(sortedSelectedSeats);
                setResponse1(response.data.data[0]);
                const mappedById = response.data.reservations.reduce(
                  (acc, item) => {
                    acc[item.id] = item;
                    return acc;
                  },
                  {}
                );
                setArrayReservations(mappedById);
                let passenger;
                let seatWithPassengerNull = Object.keys(
                  response.data.data[0]
                ).reduce((accumulator, currentKey) => {
                  const reservationInfo = response.data.reservations.find(
                    (reservation) =>
                      reservation.reservation_seats_number[currentKey] > 0
                  );
                  passenger = reservationInfo
                    ? reservationInfo.passengers?.find(
                        (p) => p.number_seat == currentKey
                      )
                    : null;

                  accumulator[currentKey] = {
                    passengers: Object(passenger),
                    status:
                      reservationInfo?.reservation_seats_number[currentKey],
                    id_reservation: reservationInfo?.id,
                  };
                  return accumulator;
                }, {});

                setArraySeat(seatWithPassengerNull);
                const seatStatusData = response.data.data[0];

                // Extract seat numbers with status 6
                const seatsWithStatusSix = Object.keys(seatStatusData).filter(
                  (seatNumber) => seatStatusData[seatNumber] === 6
                );

                // Convert to integers, since keys are strings
                const seatNumbersToInt = seatsWithStatusSix.map(Number);

                // Update selectedSeats2 state to include these seat numbers
                setSeatsArray2(seatNumbersToInt);
                setCommonSeats(
                  seatsArray1.filter((seat) => seatNumbersToInt.includes(seat))
                );

                let combinedSortedSeats = [
                  ...sortedSelectedSeats2,
                  ...sortedSelectedSeats,
                ].sort((a, b) => a - b);

                // Function to remove duplicates
                function removeDuplicates(arr) {
                  return [...new Set(arr)]; // Creates a new array from a Set constructed from the original array
                }

                // Apply removeDuplicates function
                const uniqueSortedSeats = removeDuplicates(combinedSortedSeats);

                const reservedSeats = Object.keys(response.data.data[0]).map(
                  (seat) => parseInt(seat)
                );

                const matchedSeats = Object.values(
                  response.data.data[1]
                ).filter(
                  (seat) =>
                    reservedSeats.includes(seat.seat_number) &&
                    seat.numStatus === 5
                );

                let resultArray2 = [];
                const addedSeatNumbers = new Set(); // Set to track added seat numbers
                const transitSeatNumbers = new Set();

                // Populate transitSeatNumbers as before
                TripID.tripTransit.forEach((transit) => {
                  Object.keys(transit.reservationSeats).forEach(
                    (seatNumber) => {
                      transitSeatNumbers.add(seatNumber);
                    }
                  );
                });
                const reservationSeats = response.data.data[0];
                const seatStatusAuth = response.data.data[1];
                Object.keys(response.data.data[0]).forEach((seatNumber) => {
                  const status = reservationSeats[seatNumber];
                  if (status === 5 && !seatStatusAuth[seatNumber]) {
                    resultArray2.push({
                      seat_number: parseInt(seatNumber),
                      id_system: 1,
                      numStatus: status,
                      id_client: 0,
                      status: "Selected",
                      transitStatus: null,
                      id_transit: null,
                      trip_id: idtrip,
                    });
                
                    // Perform your action here
                  }
                });
                Object.keys(response.data.data[1]).forEach((seatNumber) => {
                  const seatDetails = response.data.data[1][seatNumber];
                  if (
                    seatDetails.numStatus === 5 &&
                    response1[seatNumber] !== undefined &&
                    !transitSeatNumbers.has(seatNumber) &&
                    !addedSeatNumbers.has(seatNumber) // Check if the seat number has already been added
                  ) {
                    resultArray2.push({
                      seat_number: parseInt(seatNumber),
                      id_system: seatDetails.id_system,
                      numStatus: seatDetails.numStatus,
                    });
                    addedSeatNumbers.add(seatNumber); // Mark this seat number as added
                  }
                });

                // Filter resultArray1 to find seats reserved by the current user
                const currentUserReservedSeats = resultArray2.filter(
                  (userSeat) =>
                    userSeat.id_system === user.user_id &&
                    userSeat.numStatus == 5
                );

                const currentUserReservedSeatNumbers =
                  currentUserReservedSeats.map((seat) => seat.seat_number);
                setSelectedSeats(currentUserReservedSeatNumbers);
                 
                setSeatAndUser(resultArray2);
                setSelected(resultArray2);
                setResultArrayseatuser(resultArray2);
                setResultArray(uniqueSortedSeats);
                setValueinput(uniqueSortedSeats.join("+"));
              } else {
                sweetalert({
                  text: response.data.message,
                  icon: "error",
                  buttons: {
                    confirm: {
                      text: "إغلاق",
                      className: "custom-button-class",
                    },
                  },
                  className: "alert-div",
                });
              }
            })
            .catch((error) => {
              // Handle the error here
              setResponse1([]);
              dispatch(fetchTripBYId(TripID.id));
              setResponse1(TripID.reservation_seats);
            });
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setResponse1([]);
            dispatch(fetchTripBYId(TripID.id));
            setResponse1(TripID.reservation_seats);
     
          }
        }
      }
    }

    // Sort the selected seats numerically
    const sortedSelectedSeats = updatedSelectedSeats
      .filter((number) => number !== 0) // Remove 0 if needed
      .sort((a, b) => a - b);
    const sortedSelectedSeats2 = updatedSelectedSeats2
      .filter((number) => number !== 0) // Remove 0 if needed
      .sort((a, b) => a - b);
    const sortedSelectedSeats3 = updatedSelectedSeats3
      .filter((number) => number !== 0) // Remove 0 if needed
      .sort((a, b) => a - b);

    const combinedSortedSeats = [
      ...sortedSelectedSeats2,
      ...sortedSelectedSeats,
      ...sortedSelectedSeats3,
    ];
    const sortedCombinedSeats = combinedSortedSeats.sort((a, b) => a - b);
  }
  
  return (
    <>
      {" "}
      {response1 == "" ? (
        <div className="spinner1-box">
          <svg class="spinner1" viewBox="0 0 50 50">
            <circle
              class="path1"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </div>
      ) : (
        <>
          <Box>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              style={{
                marginTop: "1%",
                boxShadow: "0px 3px 6px rgba(0,0,0,0.6)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "98.6%",
              }}
            >
              <Grid
                item
                xs={9}
                md={9}
                sm={9}
                style={{
                  paddingRight: "10px",
                  fontSize: "12px",
                  display: "flex",
                }}
              >
                <span style={{ marginLeft: "3%" }}>{TripID.info}</span>
                <Grid
                  item
                  style={{
                    fontSize: "12px",
                    display: "flex",
                  }}
                >
                  <Grid
                    item
                    style={{
                      fontWeight: "bold",
                      marginRight: "2vw",
                    }}
                  >
                    إضافة :{" "}
                  </Grid>

                  <Grid
                    item
                    className="button-setting"
                    onClick={() => {
                      setAddReservation();
                    }}
                  >
                    حجز
                  </Grid>

                  <Grid
                    item
                    className="button-setting"
                    onClick={() => {
                      setAddReservationManager();
                    }}
                  >
                    إدارة
                  </Grid>
                  <Grid
                    item
                    className="button-setting"
                    onClick={() => {
                      setAddReservationNoData();
                    }}
                  >
                    شركة
                  </Grid>
                  <Grid
                    item
                    className="button-setting"
                 

                    onClick={() => {
                      const reservationIds1 = [];
                      const valueInputArray = Array.isArray(valueinput)
                        ? valueinput
                        : valueinput.split(/[\s,+]+/);
                      const combinedSeats = [
                        ...selectedSeats,
                        ...selectedSeats1,
                        ...selectedSeats2,
                        ...valueInputArray,
                      ]
                        .map((seat) => {
                          if (typeof seat === "number") return seat;

                          return seat.split(/[\s,+]+/).map(Number);
                        })
                        .flat();

                      const sortedNumbers1 = Array.from(
                        new Set(combinedSeats.filter((seat) => !isNaN(seat)))
                      );
                      const uniqueNumbersWithoutZero = sortedNumbers1.filter(
                        (number) => number !== 0
                      );
                      const seatNumbersToSend = uniqueNumbersWithoutZero.sort(
                        (a, b) => a - b
                      );
                      setResultArray(seatNumbersToSend);
                      // seatNumbersToSend.forEach((seat) => {
                      const reservationIdDirect = Object.values(
                        arrayReservations
                      ).find((reservation) => {
                        const seatNumbers = Object.keys(
                          reservation.reservation_seats_number || {} // Adding fallback to empty object to ensure it always works
                        ).map(Number); // Convert keys to numbers since seat numbers are often numeric
                        return seatNumbers.includes(seatNumbersToSend[0]);
                      })?.id;
                      const reservationIdTransit =
                        Array.isArray(TripID.tripTransit) && arrayReservations
                          ? Object.values(arrayReservations)?.reduce(
                              (acc, reservation) => {
                                // Ensure reservation_seats_number is not undefined before proceeding
                                if (
                                  reservation &&
                                  reservation.reservation_seats_number
                                ) {
                                  const seatNumbers = Object.keys(
                                    reservation.reservation_seats_number
                                  ).map(Number);
                                  if (
                                    seatNumbers.includes(seatNumbersToSend[0])
                                  ) {
                                    acc.push({ reservationId: reservation.id });
                                  }
                                }
                                return acc;
                              },
                              []
                            )
                          : null;
                      if (idDirect == TripID.id) {
                        if (
                          Array.isArray(TripID.tripTransit) &&
                          TripID.tripTransit.length > 0
                        ) {
                          // const seatNumbersToSend = selectedSeats;
                          let reservationseat = null;

                          // Convert the object keys into an array and then use find
                          const reservationSeatKeys = Object.keys(
                            TripID.tripTransit[0].reservationSeats
                          );
                          reservationseat = reservationSeatKeys.find((seat) =>
                            seatNumbersToSend.includes(Number(seat))
                          );

                   
                            // Seat not found in transit trip
                            const status = response1[seatNumbersToSend[0]];

                            // eslint-disable-next-line eqeqeq
                            if (status == 5 || status == 0) {
                              setOpenPopup(true);
                              localStorage.setItem("ReservationPhone", true);
                              // eslint-disable-next-line eqeqeq
                            } 

                            else if (status == undefined || status == '') {
                              setResultArray([]);
                              sweetalert({
                                text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                                icon: "error",
                                buttons: {
                                  confirm: {
                                    text: "إغلاق",
                                    className: "custom-button-class",
                                  },
                                },
                                className: "alert-div",
                              }).then(() => {
                                localStorage.setItem("changeseat", false);
                              });
                            }
                            else if (status == 4 || status == 3 || status == 2 || status == 1) {
                              setResultArray([]);
                              localStorage.setItem("changeseat", true);
                              sweetalert({
                                text: "لا يمكن القيام بهذه العملية",
                                icon: "error",
                                buttons: {
                                  confirm: {
                                    text: "تم",
                                    className: "custom-button-class",
                                  },
                                },
                                className: "alert-div",
                              }).then(() => {
                                localStorage.setItem("changeseat", false);
                                localStorage.setItem("ReservationChange", false);
                            
                              });
                              // eslint-disable-next-line eqeqeq
                            }
                            
                          
                        } else if (
                          Array.isArray(TripID.tripTransit) &&
                          TripID.tripTransit?.length == 0
                        ) {
                          
                          const status = response1[seatNumbersToSend[0]];
                      

                          if (status == 5 || status == 0) {
                            setOpenPopup(true);
                            localStorage.setItem("ReservationPhone", true);
                            // eslint-disable-next-line eqeqeq
                          } 
                          else if (status == undefined || status == '') {
                            setResultArray([]);
                            sweetalert({
                              text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                              icon: "error",
                              buttons: {
                                confirm: {
                                  text: "إغلاق",
                                  className: "custom-button-class",
                                },
                              },
                              className: "alert-div",
                            }).then(() => {
                              localStorage.setItem("changeseat", false);
                            });
                          }

                          else if (status == 4 || status == 3 || status == 2 || status == 1) {
                            setResultArray([]);
                            localStorage.setItem("changeseat", true);
                            sweetalert({
                              text: "لا يمكن القيام بهذه العملية",
                              icon: "error",
                              buttons: {
                                confirm: {
                                  text: "تم",
                                  className: "custom-button-class",
                                },
                              },
                              className: "alert-div",
                            }).then(() => {
                              localStorage.setItem("changeseat", false);
                              localStorage.setItem("ReservationChange", false);
                          
                            });
                            // eslint-disable-next-line eqeqeq
                          }
                         
                        }
                      } else if (idDirect != TripID.id) {
                      
                        // if (reservationseat == selectedSeats) {

                        // Seat not found in transit trip
                        const status = reservationseat1[seatNumbersToSend[0]];
                       

                        // eslint-disable-next-line eqeqeq
                        if (status == 5 || status == 0) {
                          setOpenPopup(true);
                          localStorage.setItem("ReservationPhone", true);
                          // eslint-disable-next-line eqeqeq
                        } 
                        else if (status == undefined || status == '') {
                          setResultArray([]);
                          sweetalert({
                            text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                            icon: "error",
                            buttons: {
                              confirm: {
                                text: "إغلاق",
                                className: "custom-button-class",
                              },
                            },
                            className: "alert-div",
                          }).then(() => {
                            localStorage.setItem("changeseat", false);
                          });
                        }
                        else if (status == 4 || status == 3 || status == 2 || status == 1) {
                          setResultArray([]);
                          localStorage.setItem("changeseat", true);
                          sweetalert({
                            text: "لا يمكن القيام بهذه العملية",
                            icon: "error",
                            buttons: {
                              confirm: {
                                text: "تم",
                                className: "custom-button-class",
                              },
                            },
                            className: "alert-div",
                          }).then(() => {
                            localStorage.setItem("changeseat", false);
                            localStorage.setItem("ReservationChange", false);
                        
                          });
                          // eslint-disable-next-line eqeqeq
                        }
                       
                      
                      }
                    }}
                  >
                    هاتفي
                  </Grid>
                  <Popup
                    title="إضافة حجز هاتفي"
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    setDisplayshortcut={setShortcut}
                  >
                    <ReservationPhone
                      id_company={id_company}
                      valueinput={valueinput}
                      selectedSeats={selectedSeats}
                      selectedSeats1={selectedSeats1}
                      selectedSeats2={selectedSeats2}
                      TripID={TripID}
                      response1={response1}
                      settranset={settranset}
                      setResultArray={setResultArray}
                      idTransit={idTransit}
                      setIdReservationResponse={setIdReservationResponse}
                      idReservationresponse={idReservationresponse}
                      arrayReservations={arrayReservations}
                      setResponse1={setResponse1}
                      setInputString={setInputString}
                      setValueinput={setValueinput}
                      setSelectedSeats1={setSelectedSeats1}
                      setSeatAndUser={setSeatAndUser}
                      setSelectedSeats={setSelectedSeats}
                      setDirect={setDirect}
                      reservationseat1={reservationseat1}
                      setOpenPopup={setOpenPopup}
                      setArraySeat={setArraySeat}
                      setSelectedSeats2={setSelectedSeats2}
                      selectedTransitIndex={selectedTransitIndex}
                      setReservationseat1={setReservationseat1}
                      setArrayReservations={setArrayReservations}
                      setArraySeatPassengerTransit2={
                        setArraySeatPassengerTransit2
                      }
                      setArraySeatPassengerTransit1={
                        setArraySeatPassengerTransit1
                      }
                    />
                  </Popup>
                  <Grid
                    item
                    className="button-setting"
                    onClick={() => {
                      setAddDataPassengerReservation();
                    }}
                  >
                    بيانات
                  </Grid>
                </Grid>

                <Grid
                  item
                  style={{
                    fontSize: "12px",
                    display: "flex",
                  }}
                >
                  <Grid
                    item
                    style={{
                      fontWeight: "bold",
                      marginRight: "2vw",
                    }}
                  >
                    تعديل :{" "}
                  </Grid>
                  <Grid
                    item
                    className="button-setting"
                    onClick={() => {
                      setReservationUpdateData();
                    }}
                  >
                    حجز
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    fontSize: "12px",
                    display: "flex",
                  }}
                >
                  <Grid
                    item
                    style={{
                      fontWeight: "bold",
                      marginRight: "2vw",
                    }}
                  >
                    تحويل :{" "}
                  </Grid>

                  <Grid
                    item
                    className="button-setting"
                    onClick={() => {
                      setAddReservationAvailable();
                    }}
                  >
                    {" "}
                    تحويل لمتوفر
                  </Grid>
                  {TripID?.tripTransit && TripID?.tripTransit.length > 0 ? (
                    <>
                      <Grid
                        item
                        className="button-setting"
                        onClick={() => {
                          setseatfromtransittodirect();
                        }}
                      >
                        تحويل لاساسي
                      </Grid>
                      <Grid
                        item
                        className="button-setting"
                        onClick={() => {
                          setAddReservationTransit();
                        }}
                      >
                        {" "}
                        تحويل لعبور
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid
                  item
                  style={{
                    fontSize: "12px",
                    display: "flex",
                  }}
                >
                  <Grid
                    item
                    style={{
                      fontWeight: "bold",
                      marginRight: "2vw",
                    }}
                  >
                    حذف :{" "}
                  </Grid>
                  <Grid
                    item
                    className="button-setting"
                 
                    onClick={() => {
                      const reservationIds1 = [];
                      const valueInputArray = Array.isArray(valueinput)
                        ? valueinput
                        : valueinput.split(/[\s,+]+/);
                      const combinedSeats = [
                        ...selectedSeats,
                        ...selectedSeats1,
                        ...selectedSeats2,
                        ...valueInputArray,
                      ]
                        .map((seat) => {
                          if (typeof seat === "number") return seat;

                          return seat.split(/[\s,+]+/).map(Number);
                        })
                        .flat();

                      const sortedNumbers1 = Array.from(
                        new Set(combinedSeats.filter((seat) => !isNaN(seat)))
                      );
                      const uniqueNumbersWithoutZero = sortedNumbers1.filter(
                        (number) => number !== 0
                      );
                      const seatNumbersToSend = uniqueNumbersWithoutZero.sort(
                        (a, b) => a - b
                      );
                      setResultArray(seatNumbersToSend);
                      // seatNumbersToSend.forEach((seat) => {
                      const reservationIdDirect = Object.values(
                        arrayReservations
                      ).find((reservation) => {
                        const seatNumbers = Object.keys(
                          reservation.reservation_seats_number || {} // Adding fallback to empty object to ensure it always works
                        ).map(Number); // Convert keys to numbers since seat numbers are often numeric
                        return seatNumbers.includes(seatNumbersToSend[0]);
                      })?.id;
                      const reservationIdTransit =
                        Array.isArray(TripID.tripTransit) && arrayReservations
                          ? Object.values(arrayReservations)?.reduce(
                              (acc, reservation) => {
                                // Ensure reservation_seats_number is not undefined before proceeding
                                if (
                                  reservation &&
                                  reservation.reservation_seats_number
                                ) {
                                  const seatNumbers = Object.keys(
                                    reservation.reservation_seats_number
                                  ).map(Number);
                                  if (
                                    seatNumbers.includes(seatNumbersToSend[0])
                                  ) {
                                    acc.push({ reservationId: reservation.id });
                                  }
                                }
                                return acc;
                              },
                              []
                            )
                          : null;
                      if (idDirect == TripID.id) {
                        if (
                          Array.isArray(TripID.tripTransit) &&
                          TripID.tripTransit.length > 0
                        ) {
                          // const seatNumbersToSend = selectedSeats;
                          let reservationseat = null;

                          // Convert the object keys into an array and then use find
                          const reservationSeatKeys = Object.keys(
                            TripID.tripTransit[0].reservationSeats
                          );
                          reservationseat = reservationSeatKeys.find((seat) =>
                            seatNumbersToSend.includes(Number(seat))
                          );

                          if (reservationseat == seatNumbersToSend) {
                            localStorage.setItem("changeseat", true);
                            sweetalert({
                              text: "لا يمكن تعديل كرسي رحلة العبور من الرحلة الاساسية للقيام بذلك انتقل إلى رحلة العبور وقم بتعديل البيانات الكرسي الخاص يرحلة العبور",
                              icon: "error",
                              buttons: {
                                confirm: {
                                  text: "تم",
                                  className: "custom-button-class",
                                },
                              },
                              className: "alert-div",
                            }).then(() => {
                              localStorage.setItem("changeseat", false);
                            });
                          } else {
                            // Seat not found in transit trip
                            const status = response1[seatNumbersToSend[0]];
                            reservationIds1.push({ reservationId1: reservationIdDirect });

                            // eslint-disable-next-line eqeqeq
                            if (status == 5 || status == 0) {
                              localStorage.setItem("changeseat", true);
                              sweetalert({
                                text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
                                icon: "error",
                                buttons: {
                                  confirm: {
                                    text: "تم",
                                    className: "custom-button-class",
                                  },
                                },
                                className: "alert-div",
                              }).then(() => {
                                localStorage.setItem("changeseat", false);
                              });
                              // eslint-disable-next-line eqeqeq
                            }
                            else if (status == undefined || status == '') {
                                setResultArray([]);
                                sweetalert({
                                  text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                                  icon: "error",
                                  buttons: {
                                    confirm: {
                                      text: "إغلاق",
                                      className: "custom-button-class",
                                    },
                                  },
                                  className: "alert-div",
                                }).then(() => {
                                  localStorage.setItem("changeseat", false);
                                });
                              }
                             else if (status == 4) {
                              setResultArray([]);
                              localStorage.setItem("changeseat", true);
                              sweetalert({
                                text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
                                icon: "error",
                                buttons: {
                                  confirm: {
                                    text: "تم",
                                    className: "custom-button-class",
                                  },
                                },
                                className: "alert-div",
                              }).then(() => {
                                localStorage.setItem("changeseat", false);
                              });
                              // eslint-disable-next-line eqeqeq
                            } else if (status == 3) {
                              setResultArray([]);
                              localStorage.setItem("changeseat", true);
                              sweetalert({
                                text: "Ctrl + 0 لتغيير حالة كرسي ادارة لمتوفر اضغط على  ",
                                icon: "error",
                                buttons: {
                                  confirm: {
                                    text: "تم",
                                    className: "custom-button-class",
                                  },
                                },
                                className: "alert-div",
                              }).then(() => {
                                localStorage.setItem("changeseat", false);
                              });
                              // eslint-disable-next-line eqeqeq
                            } else if (status == 1) {
                              const id_reservation =
                                reservationIds1[0].reservationId1;
                              const seat_number = seatNumbersToSend;
                              const passengerDeleteData = {
                                id_reservation,
                                seat_number,
                                branch: TripID.id_branch,
                                trip_id: TripID.id,
                              };
                              axios
                                .post(
                                  `${BaseUrl}/remove-seat-reservation`,
                                  passengerDeleteData,
                                  {
                                    withCredentials: true,
                                  }
                                )
                                .then((response) => {
                                  // Handle the successful response here
                                  if (response.data.status === 200) {
                                    setResponse1(response.data.data.seats);
                                    setArraySeat((prevSeatsInfo) => {
                                      const updatedSeatsInfo = {
                                        ...prevSeatsInfo,
                                      };
                                      const seatNumber = parseInt(seat_number);

                                      if (!isNaN(seatNumber)) {
                                        updatedSeatsInfo[seatNumber] = {
                                          passengers: {},
                                          status: 0,
                                          id_reservation: undefined,
                                        };
                                      }

                                      return updatedSeatsInfo;
                                    });
                                    const mappedById =
                                      response.data.data.reservations.reduce(
                                        (acc, item) => {
                                          acc[item.id] = item;
                                          return acc;
                                        },
                                        {}
                                      );
                                    setArrayReservations(mappedById);

                                    sweetalert({
                                      text: response.data.message,
                                      icon: "success",
                                      buttons: {
                                        confirm: {
                                          text: "تم",
                                          className: "custom-button-class",
                                        },
                                      },
                                      className: "alert-div",
                                    }).then(() => {
                                      // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                                      localStorage.setItem(
                                        "openpassenger",
                                        false
                                      );
                                      localStorage.setItem("changeseat", false);
                                    });
                                  }
                                });
                              // dispatch(deleteSeatToReservation(passengerDeleteData));
                              settranset(false);
                              setDirect(true);
                              setResultArray([]);
                              setInputString("");
                              setValueinput("");
                              setSeatAndUser([]);
                              setSelectedSeats([]);
                              setSelectedSeats1([]);
                              setSelectedSeats2([]);
                              // eslint-disable-next-line eqeqeq
                            } else {
                              setOpenPopuppassenger2(true);
                              localStorage.setItem("ReservationDeleted", true);
                              const reservationDirect = Object.values(
                                arrayReservations
                              )?.find((reservation) => {
                                const seatNumbers = Object.keys(
                                  reservation.reservation_seats_number
                                ).map(Number);
                                return seatNumbers.includes(
                                  seatNumbersToSend[0]
                                );
                              });

                              setIdReservationResponse(reservationDirect);
                              settranset(false);
                              setDirect(true);
                              const passengerData = [];

                              seatNumbersToSend.forEach((seat) => {
                                const matchingPassenger =
                                  reservationDirect.passengers.find(
                                    (passenger) => {
                                      return passenger.number_seat == seat;
                                    }
                                  );
                                if (matchingPassenger) {
                                  passengerData.push({
                                    id:
                                      matchingPassenger.id_passenger ||
                                      matchingPassenger.id,
                                      number_seat: matchingPassenger.number_seat,
                                    id_price_ticket:
                                      matchingPassenger.id_price_ticket,
                                    price: matchingPassenger.price,
                                    code_seat: "",
                                    typeReduction: "",
                                    // Add other passenger details as needed
                                  });

                                  reservationIds1.push({
                                    reservationId1: reservationDirect.id,
                                  });
                                  setUpdatePassenger(true);
                                }
                              });

                              if (passengerData.length > 0) {
                                const existingPassengerData =
                                  JSON.parse(
                                    localStorage.getItem("passengers")
                                  ) || [];
                                const updatedPassengerData = [
                                  // ...existingPassengerData,
                                  ...passengerData,
                                ];

                                localStorage.setItem(
                                  "passengers",
                                  JSON.stringify(updatedPassengerData)
                                );
                              }
                              const matchingPassenger =
                                reservationDirect.passengers.find(
                                  (passenger) => {
                                    return (
                                      passenger.number_seat == selectedSeats
                                    );
                                  }
                                );

                              if (matchingPassenger) {
                                const passengerData = [
                                  {
                                    id:
                                      matchingPassenger.id_passenger ||
                                      matchingPassenger.id,
                                    first_name: matchingPassenger.first_name,
                                    number_seat: matchingPassenger.number_seat,
                                    id_price_ticket:
                                      matchingPassenger.id_price_ticket,
                                    price: matchingPassenger.price,
                                    code_seat: "",
                                    typeReduction: "",
                                    // Add other passenger details as needed
                                  },
                                ];
                                reservationIds1.push({
                                  reservationId1: reservationDirect.id,
                                });
                                setUpdatePassenger(true);
                                const existingPassengerData =
                                  JSON.parse(
                                    localStorage.getItem("passengers")
                                  ) || [];
                                const updatedPassengerData = [
                                  ...existingPassengerData,
                                  ...passengerData,
                                ];

                                localStorage.setItem(
                                  "passengers",
                                  JSON.stringify(updatedPassengerData)
                                );
                              }
                            }
                          }
                        } else if (
                          Array.isArray(TripID.tripTransit) &&
                          TripID.tripTransit?.length == 0
                        ) {
                          let changeseat = false;
                          const seatStatus = {};
                          const status = response1[seatNumbersToSend[0]];
                          reservationIds1.push({
                            reservationId1: reservationIdDirect,
                          });

                          // eslint-disable-next-line eqeqeq
                          if (status == 5 || status == 0) {
                            localStorage.setItem("changeseat", true);
                            sweetalert({
                              text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
                              icon: "error",
                              buttons: {
                                confirm: {
                                  text: "تم",
                                  className: "custom-button-class",
                                },
                              },
                              className: "alert-div",
                            }).then(() => {
                              localStorage.setItem("changeseat", false);
                            });
                            // eslint-disable-next-line eqeqeq
                          }
                          else if (status == undefined || status == '') {
                              setResultArray([]);
                              sweetalert({
                                text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                                icon: "error",
                                buttons: {
                                  confirm: {
                                    text: "إغلاق",
                                    className: "custom-button-class",
                                  },
                                },
                                className: "alert-div",
                              }).then(() => {
                                localStorage.setItem("changeseat", false);
                              });
                            }
                           else if (status == 4) {
                            setResultArray([]);
                            localStorage.setItem("changeseat", true);
                            sweetalert({
                              text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
                              icon: "error",
                              buttons: {
                                confirm: {
                                  text: "تم",
                                  className: "custom-button-class",
                                },
                              },
                              className: "alert-div",
                            }).then(() => {
                              localStorage.setItem("changeseat", false);
                            });
                            // eslint-disable-next-line eqeqeq
                          } else if (status == 3) {
                            setResultArray([]);
                            localStorage.setItem("changeseat", true);
                            sweetalert({
                              text: "Ctrl + 0 لتغيير حالة كرسي ادارة اضغط على  ",
                              icon: "error",
                              buttons: {
                                confirm: {
                                  text: "تم",
                                  className: "custom-button-class",
                                },
                              },
                              className: "alert-div",
                            }).then(() => {
                              localStorage.setItem("changeseat", false);
                            });
                            // eslint-disable-next-line eqeqeq
                          } else if (status == 1) {
                            const id_reservation =
                              reservationIds1[0].reservationId1;
                            const seat_number = seatNumbersToSend;
                            const passengerDeleteData = {
                              id_reservation,
                              seat_number,
                              branch: TripID.id_branch,
                              trip_id: TripID.id,
                            };
                            // dispatch(deleteSeatToReservation(passengerDeleteData));
                            axios
                              .post(
                                `${BaseUrl}/remove-seat-reservation`,
                                passengerDeleteData,
                                {
                                  withCredentials: true,
                                }
                              )
                              .then((response) => {
                                // Handle the successful response here
                                if (response.data.status === 200) {
                                  setResponse1(response.data.data.seats);
                                  setArraySeat((prevSeatsInfo) => {
                                    const updatedSeatsInfo = {
                                      ...prevSeatsInfo,
                                    };
                                    const seatNumber = parseInt(seat_number);

                                    if (!isNaN(seatNumber)) {
                                      updatedSeatsInfo[seatNumber] = {
                                        passengers: {},
                                        status: 0,
                                        id_reservation: undefined,
                                      };
                                    }

                                    return updatedSeatsInfo;
                                  });
                                  const mappedById =
                                    response.data.data.reservations.reduce(
                                      (acc, item) => {
                                        acc[item.id] = item;
                                        return acc;
                                      },
                                      {}
                                    );
                                  setArrayReservations(mappedById);

                                  sweetalert({
                                    text: response.data.message,
                                    icon: "success",
                                    buttons: {
                                      confirm: {
                                        text: "تم",
                                        className: "custom-button-class",
                                      },
                                    },
                                    className: "alert-div",
                                  }).then(() => {
                                    // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                                    localStorage.setItem(
                                      "openpassenger",
                                      false
                                    );
                                    localStorage.setItem("changeseat", false);
                                    setOpenPopuppassenger2(false);
                                  });
                                }
                              });
                            // dispatch(deleteSeatToReservation(passengerDeleteData));
                            settranset(false);
                            setDirect(true);
                            setResultArray([]);
                            setInputString("");
                            setValueinput("");
                            setSeatAndUser([]);
                            setSelectedSeats([]);
                            setSelectedSeats1([]);
                            setSelectedSeats2([]);
                            // eslint-disable-next-line eqeqeq
                          } else {
                            setOpenPopuppassenger2(true);
                            localStorage.setItem("ReservationDeleted", true);
                            const reservationDirect = Object.values(
                              arrayReservations
                            )?.find((reservation) => {
                              const seatNumbers = Object.keys(
                                reservation.reservation_seats_number
                              ).map(Number);
                              return seatNumbers.includes(seatNumbersToSend[0]);
                            });
                            setIdReservationResponse(reservationDirect);

                            settranset(false);
                            setDirect(true);
                            const passengerData = [];

                            // Loop through selectedSeats and find matching passengers
                            seatNumbersToSend.forEach((seat) => {
                              const matchingPassenger =
                                reservationDirect?.passengers?.find(
                                  (passenger) => {
                                    return passenger.number_seat == seat;
                                  }
                                );

                              if (matchingPassenger) {
                                passengerData.push({
                                  id:
                                    matchingPassenger.id_passenger ||
                                    matchingPassenger.id,
                                  number_seat: matchingPassenger.number_seat,
                                  id_price_ticket:
                                    matchingPassenger.id_price_ticket,
                                  price: matchingPassenger.price,
                                  code_seat: "",
                                  typeReduction: "",

                                  // Add other passenger details as needed
                                });

                                reservationIds1.push({
                                  reservationId1: reservationDirect.id,
                                });
                                setUpdatePassenger(true);
                              }
                            });

                            if (passengerData.length > 0) {
                              const existingPassengerData =
                                JSON.parse(
                                  localStorage.getItem("passengers")
                                ) || [];
                              const updatedPassengerData = [
                                ...existingPassengerData,
                                ...passengerData,
                              ];
                              localStorage.setItem(
                                "passengers",
                                JSON.stringify(updatedPassengerData)
                              );
                            }
                          }
                        }
                      } else if (idDirect != TripID.id) {
                        settranset(true);
                        setDirect(false);
                        // if (reservationseat == selectedSeats) {

                        // Seat not found in transit trip
                        const status = reservationseat1[seatNumbersToSend[0]];
                        reservationIds1.push({
                          reservationId1: reservationIdTransit,
                        });
                   
                        // eslint-disable-next-line eqeqeq
                        if (status == 5 || status == 0) {
                          localStorage.setItem("changeseat", true);
                          sweetalert({
                            text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
                            icon: "error",
                            buttons: {
                              confirm: {
                                text: "تم",
                                className: "custom-button-class",
                              },
                            },
                            className: "alert-div",
                          }).then(() => {
                            localStorage.setItem("changeseat", false);
                          });
                          // eslint-disable-next-line eqeqeq
                        }
                        else if (status == undefined || status == '') {
                            setResultArray([]);
                            sweetalert({
                              text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                              icon: "error",
                              buttons: {
                                confirm: {
                                  text: "إغلاق",
                                  className: "custom-button-class",
                                },
                              },
                              className: "alert-div",
                            }).then(() => {
                              localStorage.setItem("changeseat", false);
                            });
                          }
                         else if (status == 4) {
                          setResultArray([]);
                          localStorage.setItem("changeseat", true);
                          sweetalert({
                            text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
                            icon: "error",
                            buttons: {
                              confirm: {
                                text: "تم",
                                className: "custom-button-class",
                              },
                            },
                            className: "alert-div",
                          }).then(() => {
                            localStorage.setItem("changeseat", false);
                          });
                          // eslint-disable-next-line eqeqeq
                        } else if (status == 3) {
                          setResultArray([]);
                          localStorage.setItem("changeseat", true);
                          sweetalert({
                            text: "Ctrl + 0 لتغيير حالة كرسي ادارة لمتوفر اضغط على  ",
                            icon: "error",
                            buttons: {
                              confirm: {
                                text: "تم",
                                className: "custom-button-class",
                              },
                            },
                            className: "alert-div",
                          }).then(() => {
                            localStorage.setItem("changeseat", false);
                          });
                          // eslint-disable-next-line eqeqeq
                        } else if (status == 1) {
                          const id_reservation =
                            reservationIds1[0]?.reservationId1[0].reservationId;
                          const seat_number = seatNumbersToSend;
                          const passengerDeleteData = {
                            id_reservation,
                            seat_number,
                            branch: TripID.id_branch,
                            trip_id: TripID.id,
                          };
                          axios
                            .post(
                              `${BaseUrl}/remove-seat-reservation`,
                              passengerDeleteData,
                              {
                                withCredentials: true,
                              }
                            )
                            .then((response) => {
                              // Handle the successful response here
                              if (response.data.status === 200) {
                                setReservationseat1(response.data.data.seats);

                                if (selectedTransitIndex == 0) {
                                  setArraySeatPassengerTransit1(
                                    (prevSeatsInfo) => {
                                      const updatedSeatsInfo = {
                                        ...prevSeatsInfo,
                                      };

                                      seatNumbersToSend.forEach(
                                        (seat_number) => {
                                          const seatNumber =
                                            parseInt(seat_number);
                                          if (!isNaN(seatNumber)) {
                                            updatedSeatsInfo[seatNumber] = {
                                              passengers: {}, // Empty object for passengers
                                              status: 0, // Set status to 0
                                              id_reservation: undefined, // No reservation ID
                                            };
                                          } else {
                                          }
                                        }
                                      );

                                      return updatedSeatsInfo;
                                    }
                                  );
                                  const mappedById =
                                    response.data.data.reservations.reduce(
                                      (acc, item) => {
                                        acc[item.id] = item;
                                        return acc;
                                      },
                                      {}
                                    );
                                  setArrayReservations(mappedById);
                                  sweetalert({
                                    text: response.data.message,
                                    icon: "success",
                                    buttons: {
                                      confirm: {
                                        text: "تم",
                                        className: "custom-button-class",
                                      },
                                    },
                                    className: "alert-div",
                                  }).then(() => {
                                    // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                                    localStorage.setItem(
                                      "openpassenger",
                                      false
                                    );
                                    localStorage.setItem("changeseat", false);
                                    setOpenPopuppassenger2(false);
                                  });
                                } else if (selectedTransitIndex == 1) {
                                  setArraySeatPassengerTransit2(
                                    (prevSeatsInfo) => {
                                      const updatedSeatsInfo = {
                                        ...prevSeatsInfo,
                                      };
                                      const seatNumber = parseInt(seat_number);

                                      if (!isNaN(seatNumber)) {
                                        updatedSeatsInfo[seatNumber] = {
                                          passengers: {},
                                          status: 0,
                                          id_reservation: undefined,
                                        };
                                      }

                                      return updatedSeatsInfo;
                                    }
                                  );
                                  const mappedById =
                                    response.data.data.reservations.reduce(
                                      (acc, item) => {
                                        acc[item.id] = item;
                                        return acc;
                                      },
                                      {}
                                    );
                                  setArrayReservations(mappedById);
                                  sweetalert({
                                    text: response.data.message,
                                    icon: "success",
                                    buttons: {
                                      confirm: {
                                        text: "تم",
                                        className: "custom-button-class",
                                      },
                                    },
                                    className: "alert-div",
                                  }).then(() => {
                                    // dispatch(fetchTripBYId(passengerDeleteData.id_trip));
                                    localStorage.setItem(
                                      "openpassenger",
                                      false
                                    );
                                    localStorage.setItem("changeseat", false);
                                    setOpenPopuppassenger2(false);
                                  });
                                }
                              }
                            });
                          settranset(true);
                          setDirect(false);
                          setResultArray([]);
                          setInputString("");
                          setValueinput("");
                          setSeatAndUser([]);
                          setSelectedSeats([]);
                          setSelectedSeats1([]);
                          setSelectedSeats2([]);
                        } else {
                          setOpenPopuppassenger2(true);
                          localStorage.setItem("ReservationDeleted", true);
                          const reservationIdTransit = Array.isArray(
                            TripID.tripTransit
                          )
                            ? Object.values(arrayReservations)?.reduce(
                                (acc, reservation) => {
                                  const seatNumbers = Object.keys(
                                    reservation.reservation_seats_number
                                  ).map(Number);
                                  if (
                                    seatNumbers.includes(seatNumbersToSend[0])
                                  ) {
                                    acc.push({
                                      reservationId: reservation.id,
                                      passengers: reservation.passengers,
                                      trip_id:
                                        reservation.trip_id ||
                                        reservation.triptable_id,
                                    });
                                  }
                                  return acc;
                                },
                                []
                              )
                            : null;
                          const relevantReservations = Object.values(
                            reservationIdTransit
                          ).filter(
                            (reservation) =>
                              reservation.trip_id.toString() === idtransit
                          );

                          setIdReservationResponse(relevantReservations);
                          if (relevantReservations) {
                            const passengerData = [];

                            if (Array.isArray(relevantReservations)) {
                              const passengersForSeat = [];

                              // relevantReservations.forEach((reservation) => {
                              const matchingPassenger =
                                relevantReservations[0]?.passengers.find(
                                  (passenger) => {
                                    return (
                                      passenger.number_seat ===
                                      seatNumbersToSend[0]
                                    ); // Use strict equality
                                  }
                                );

                              if (matchingPassenger) {
                                passengerData.push({
                                  id:
                                    matchingPassenger.id_passenger ||
                                    matchingPassenger.id,
                                  number_seat: matchingPassenger.number_seat,
                                  id_price_ticket:
                                    matchingPassenger.id_price_ticket,
                                  price: matchingPassenger.price,
                                  code_seat: "",
                                  typeReduction: "",
                                });
                              }
                              // });

                              // passengerData.push(passengersForSeat);
                            }

                            if (passengerData.length > 0) {
                              const flattenedPassengerData =
                                passengerData.flat();

                              // // Function to check if the passenger is already included
                              // const isPassengerIncluded = (newPassenger, existingData) => {
                              //     return existingData.some((passenger) => passenger.id === newPassenger.id);
                              // };

                              // // Filter the new data to exclude already stored passengers
                              // const filteredNewPassengers = flattenedPassengerData.filter((newPassenger) => {
                              //     return !isPassengerIncluded(newPassenger, existingPassengerData);
                              // });
                              const updatedPassengerData = [
                                ...flattenedPassengerData,
                              ];

                              localStorage.setItem(
                                "passengers",
                                JSON.stringify(updatedPassengerData)
                              );
                            }
                          }
                        }
                      }
                    }}
                  >
                    حجز
                  </Grid>
                  <Popuppassenger2
                    title="حذف حجز"
                    openPopup={openPopuppassenger2}
                    setOpenPopup={setOpenPopuppassenger2}
                    setDisplayshortcut={setShortcut}
                    setstatus={setstatus}
                    updatePassenger={updatePassenger}
                    setUpdatePassenger={setUpdatePassenger}
                    setNewStatus={setNewStatus}
                    onConfiarm={handleConfirm}
                    setOpenopoup1={setOpenopoup1}
                    openopoup1={openopoup1}
                    setResultArray={setResultArray}
                    setSeatAndUser={setSeatAndUser}
                    reservationIds={Reservationdirectrip}
                    transet={transet}
                    idTransit={idTransit}
                    direct={direct}
                    id_trip={id_trip}
                    Trip={TripID}
                    setDirect={setDirect}
                    settranset={settranset}
                    seatNumber={seatNumber}
                    selectedSeats={selectedSeats}
                    setSelected={setSelected}
                    select={select}
                  >
                    <Box class="triptablewidth1">
                      <StepPassengerDeleted
                        setResultArray={setResultArray}
                        numberpassenger={resultArray}
                        onConfiarm={handleConfirm}
                        setstatus={setstatus}
                        reservationIds={reservationIds}
                        response1={response1}
                        id_company={id_company}
                        id_trip={id_trip}
                        id_trip_type={IDTripType}
                        transet={transet}
                        direct={direct}
                        idDirect={idDirect}
                        setResponse1={setResponse1}
                        setReservationseat1={setReservationseat1}
                        branch_id={TripID.id_branch}
                        setDirect={setDirect}
                        updateSeat={updateSeat}
                        setUpdateSeat={setUpdateSeat}
                        updatePassenger={updatePassenger}
                        setUpdatePassenger={setUpdatePassenger}
                        reservationss={reservationss}
                        setArraySeat={setArraySeat}
                        arrayseat={arrayseat}
                        idReservationresponse={idReservationresponse}
                        setSelectedSeats1={setSelectedSeats1}
                        setSelectedSeats={setSelectedSeats}
                        setSeatAndUser={setSeatAndUser}
                        setArraySeatPassengerTransit1={
                          setArraySeatPassengerTransit1
                        }
                        arrayseatPassengerTransit1={arrayseatPassengerTransit1}
                        setArraySeatPassengerTransit2={
                          setArraySeatPassengerTransit2
                        }
                        arrayseatPassengerTransit2={arrayseatPassengerTransit2}
                        selectedTransitIndex={selectedTransitIndex}
                        setIdTransit1={setIdTransit1}
                        id_transit1={id_transit1}
                        idTransit={idTransit}
                        setSelectedSeats2={setSelectedSeats2}
                        setArrayReservations={setArrayReservations}
                        arrayReservations={arrayReservations}
                        setIdTicketPrice={setIdTicketPrice}
                        idTricketPrice={idTricketPrice}
                        valueinput={valueinput}
                        settranset={settranset}
                        reservationseat1={reservationseat1}
                        setValueinput={setValueinput}
                        setInputString={setInputString}
                        selectedSeats={selectedSeats}
                        selectedSeats2={selectedSeats2}
                        selectedSeats1={selectedSeats1}
                        openPopup={openPopuppassenger2}
                        setOpenPopup={setOpenPopuppassenger2}
                      />
                    </Box>
                  </Popuppassenger2>
            
                </Grid>
                  <Grid
                    item
                    style={{
                      fontWeight: "bold",
                      marginRight: "2vw",
                    }}
                  >
                    تبديل :{" "}
                  </Grid>
                  <Grid
                    item
                    className="button-setting"
                   
                      onClick={() => {
                      const reservationIds1 = [];
                      const valueInputArray = Array.isArray(valueinput)
                        ? valueinput
                        : valueinput.split(/[\s,+]+/);
                      const combinedSeats = [
                        ...selectedSeats,
                        ...selectedSeats1,
                        ...selectedSeats2,
                        ...valueInputArray,
                      ]
                        .map((seat) => {
                          if (typeof seat === "number") return seat;

                          return seat.split(/[\s,+]+/).map(Number);
                        })
                        .flat();

                      const sortedNumbers1 = Array.from(
                        new Set(combinedSeats.filter((seat) => !isNaN(seat)))
                      );
                      const uniqueNumbersWithoutZero = sortedNumbers1.filter(
                        (number) => number !== 0
                      );
                      const seatNumbersToSend = uniqueNumbersWithoutZero.sort(
                        (a, b) => a - b
                      );
                      setResultArray(seatNumbersToSend);
                      // seatNumbersToSend.forEach((seat) => {
                      const reservationIdDirect = Object.values(
                        arrayReservations
                      ).find((reservation) => {
                        const seatNumbers = Object.keys(
                          reservation.reservation_seats_number || {} // Adding fallback to empty object to ensure it always works
                        ).map(Number); // Convert keys to numbers since seat numbers are often numeric
                        return seatNumbers.includes(seatNumbersToSend[0]);
                      })?.id;
                      const reservationIdTransit =
                        Array.isArray(TripID.tripTransit) && arrayReservations
                          ? Object.values(arrayReservations)?.reduce(
                              (acc, reservation) => {
                                // Ensure reservation_seats_number is not undefined before proceeding
                                if (
                                  reservation &&
                                  reservation.reservation_seats_number
                                ) {
                                  const seatNumbers = Object.keys(
                                    reservation.reservation_seats_number
                                  ).map(Number);
                                  if (
                                    seatNumbers.includes(seatNumbersToSend[0])
                                  ) {
                                    acc.push({ reservationId: reservation.id });
                                  }
                                }
                                return acc;
                              },
                              []
                            )
                          : null;
                      if (idDirect == TripID.id) {
                        if (
                          Array.isArray(TripID.tripTransit) &&
                          TripID.tripTransit.length > 0
                        ) {
                          // const seatNumbersToSend = selectedSeats;
                          let reservationseat = null;

                          // Convert the object keys into an array and then use find
                          const reservationSeatKeys = Object.keys(
                            TripID.tripTransit[0].reservationSeats
                          );
                          reservationseat = reservationSeatKeys.find((seat) =>
                            seatNumbersToSend.includes(Number(seat))
                          );

                          if (reservationseat == seatNumbersToSend) {
                            localStorage.setItem("changeseat", true);
                         
                            sweetalert({
                              text: "لا يمكن تعديل كرسي رحلة العبور من الرحلة الاساسية للقيام بذلك انتقل إلى رحلة العبور وقم بتعديل البيانات الكرسي الخاص يرحلة العبور",
                              icon: "error",
                              buttons: {
                                confirm: {
                                  text: "تم",
                                  className: "custom-button-class",
                                },
                              },
                              className: "alert-div",
                            }).then(() => {
                              localStorage.setItem("changeseat", false);
                              localStorage.setItem("ReservationChange", false);
                            });
                          } else {
                            // Seat not found in transit trip
                            const status = response1[seatNumbersToSend[0]];
                            reservationIds1.push({ reservationId1: reservationIdDirect });

                            // eslint-disable-next-line eqeqeq
                            if (status == 5 || status == 0) {
                              localStorage.setItem("changeseat", true);
                              localStorage.setItem("ReservationChange", true);
                               
                              sweetalert({
                                text: "لا يمكن تيديل كرسي لا يحتوي على بيانات",
                                icon: "error",
                                buttons: {
                                  confirm: {
                                    text: "تم",
                                    className: "custom-button-class",
                                  },
                                },
                                className: "alert-div",
                              }).then(() => {
                                localStorage.setItem("changeseat", false);
                                localStorage.setItem("ReservationChange", false);
                      
                              });
                              // eslint-disable-next-line eqeqeq
                            }
                            else if (status == undefined || status == '') {
                            setResultArray([]);
                            sweetalert({
                              text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                              icon: "error",
                              buttons: {
                                confirm: {
                                  text: "إغلاق",
                                  className: "custom-button-class",
                                },
                              },
                              className: "alert-div",
                            }).then(() => {
                              localStorage.setItem("changeseat", false);
                            });
                             }
                             else if (status == 4) {
                              setResultArray([]);
                              localStorage.setItem("changeseat", true);
                              localStorage.setItem("ReservationChange", true);
                               
                              sweetalert({
                                text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
                                icon: "error",
                                buttons: {
                                  confirm: {
                                    text: "تم",
                                    className: "custom-button-class",
                                  },
                                },
                                className: "alert-div",
                              }).then(() => {
                                localStorage.setItem("changeseat", false);
                                localStorage.setItem("ReservationChange", false);
                            
                              });
                              // eslint-disable-next-line eqeqeq
                            } else if (status == 3) {
                              setResultArray([]);
                              localStorage.setItem("changeseat", true);
                              localStorage.setItem("ReservationChange", true);
                           
                              sweetalert({
                                text: "Ctrl + 0 لتغيير حالة كرسي ادارة لمتوفر اضغط على  ",
                                icon: "error",
                                buttons: {
                                  confirm: {
                                    text: "تم",
                                    className: "custom-button-class",
                                  },
                                },
                                className: "alert-div",
                              }).then(() => {
                                localStorage.setItem("changeseat", false);
                                localStorage.setItem("ReservationChange", false);
                               
                              });
                              // eslint-disable-next-line eqeqeq
                            }
                            else if (status == 1) {
                              setOpenPopup2(true);

            const reservationDirect = Object.values(arrayReservations)?.find(
              (reservation) => {
                const seatNumbers = Object.keys(
                  reservation.reservation_seats_number
                ).map(Number);
                return seatNumbers.includes(seatNumbersToSend[0]);
              }
            );
            settranset(false);
            setDirect(true);
            const passengerData = [];

            seatNumbersToSend.forEach((seat) => {
                                const matchingPassenger =
                                  reservationDirect.passengers.find(
                                    (passenger) => {
                                      return passenger.number_seat == seat;
                                    }
                                  );
                         
                                  passengerData.push({
                                    id:"",
                                    number_seat:seatNumbersToSend[0],
                                    id_price_ticket:"",
                                    price:"",
                                    NewSeat: "",
                                    typeReduction: "",
                                    // Add other passenger details as needed
                                  });

                                  reservationIds1.push({
                                    reservationId1: reservationDirect.id,
                                  });
                                  
                                
                              });

                              if (passengerData.length > 0) {
                                const existingPassengerData =
                                  JSON.parse(
                                    localStorage.getItem("passengers")
                                  ) || [];
                                const updatedPassengerData = [
                                  ...passengerData,
                                ];

                                localStorage.setItem(
                                  "passengers",
                                  JSON.stringify(updatedPassengerData)
                                );
                              }
                             

            reservationIds1.push({
              reservationId1: reservationDirect.id,
            });

            setIdReservationResponse(reservationIds1);
                            }
                         
                            else if( status == 2){
                           
                              localStorage.setItem("changeseat", true);
                              localStorage.setItem("ReservationChange", true);
                              setOpenPopup2(true);
                              const reservationDirect = Object.values(
                                arrayReservations
                              )?.find((reservation) => {
                                const seatNumbers = Object.keys(
                                  reservation.reservation_seats_number
                                ).map(Number);
                                return seatNumbers.includes(
                                  seatNumbersToSend[0]
                                );
                              });
                              
                              setIdReservationResponse(reservationDirect);
                              settranset(false);
                              setDirect(true);
                              const passengerData = [];

                              seatNumbersToSend.forEach((seat) => {
                                const matchingPassenger =
                                  reservationDirect.passengers.find(
                                    (passenger) => {
                                      return passenger.number_seat == seat;
                                    }
                                  );
                                if (matchingPassenger) {
                                  passengerData.push({
                                    id:
                                      matchingPassenger.id_passenger ||
                                      matchingPassenger.id,
                                      number_seat: matchingPassenger.number_seat,
                                    id_price_ticket:
                                      matchingPassenger.id_price_ticket,
                                    price: matchingPassenger.price,
                                    NewSeat: "",
                                    typeReduction: "",
                                    // Add other passenger details as needed
                                  });

                                  reservationIds1.push({
                                    reservationId1: reservationDirect.id,
                                  });
                                  
                                }
                              });

                              if (passengerData.length > 0) {
                                const existingPassengerData =
                                  JSON.parse(
                                    localStorage.getItem("passengers")
                                  ) || [];
                                const updatedPassengerData = [
                                  // ...existingPassengerData,
                                  ...passengerData,
                                ];

                                localStorage.setItem(
                                  "passengers",
                                  JSON.stringify(updatedPassengerData)
                                );
                              }
                              const matchingPassenger =
                                reservationDirect.passengers.find(
                                  (passenger) => {
                                    return (
                                      passenger.number_seat == selectedSeats
                                    );
                                  }
                                );

                              if (matchingPassenger) {
                                const passengerData = [
                                  {
                                    id:
                                      matchingPassenger.id_passenger ||
                                      matchingPassenger.id,
                                    first_name: matchingPassenger.first_name,
                                    number_seat: matchingPassenger.number_seat,
                                    id_price_ticket:
                                      matchingPassenger.id_price_ticket,
                                    price: matchingPassenger.price,
                                    code_seat: "",
                                    typeReduction: "",
                                    // Add other passenger details as needed
                                  },
                                ];
                                reservationIds1.push({
                                  reservationId1: reservationDirect.id,
                                });
                                setUpdatePassenger(true);
                                const existingPassengerData =
                                  JSON.parse(
                                    localStorage.getItem("passengers")
                                  ) || [];
                                const updatedPassengerData = [
                                  ...existingPassengerData,
                                  ...passengerData,
                                ];

                                localStorage.setItem(
                                  "passengers",
                                  JSON.stringify(updatedPassengerData)
                                );
                              }
                            }
                          }
                        } else if (
                          Array.isArray(TripID.tripTransit) &&
                          TripID.tripTransit?.length == 0
                        ) {
                          let changeseat = false;
                          const seatStatus = {};
                          const status = response1[seatNumbersToSend[0]];
                          reservationIds1.push({
                            reservationId1: reservationIdDirect,
                          });

                          // eslint-disable-next-line eqeqeq
                          if (status == 5 || status == 0) {
                            localStorage.setItem("changeseat", true);
                            localStorage.setItem("ReservationChange", true);
                              
                            sweetalert({
                              text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
                              icon: "error",
                              buttons: {
                                confirm: {
                                  text: "تم",
                                  className: "custom-button-class",
                                },
                              },
                              className: "alert-div",
                            }).then(() => {
                              localStorage.setItem("changeseat", false);
                              localStorage.setItem("ReservationChange", false);
                              
                            });
                            // eslint-disable-next-line eqeqeq
                          }
                          else if (status == undefined || status == '') {
                            setResultArray([]);
                            sweetalert({
                              text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                              icon: "error",
                              buttons: {
                                confirm: {
                                  text: "إغلاق",
                                  className: "custom-button-class",
                                },
                              },
                              className: "alert-div",
                            }).then(() => {
                              localStorage.setItem("changeseat", false);
                            });
                          }
                           else if (status == 4) {
                            setResultArray([]);
                            localStorage.setItem("changeseat", true);
                            localStorage.setItem("ReservationChange", true);

                            sweetalert({
                              text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
                              icon: "error",
                              buttons: {
                                confirm: {
                                  text: "تم",
                                  className: "custom-button-class",
                                },
                              },
                              className: "alert-div",
                            }).then(() => {
                              localStorage.setItem("changeseat", false);
                              localStorage.setItem("ReservationChange", false);
                            });
                            // eslint-disable-next-line eqeqeq
                          } else if (status == 3) {
                            setResultArray([]);
                            localStorage.setItem("changeseat", true);
                            localStorage.setItem("ReservationChange", true);
                            sweetalert({
                              text: "Ctrl + 0 لتغيير حالة كرسي ادارة اضغط على  ",
                              icon: "error",
                              buttons: {
                                confirm: {
                                  text: "تم",
                                  className: "custom-button-class",
                                },
                              },
                              className: "alert-div",
                            }).then(() => {
                              localStorage.setItem("changeseat", false);
                              localStorage.setItem("ReservationChange", false);
                            });
                            // eslint-disable-next-line eqeqeq
                          }
                          
                   
                          else if (status == 1) {
                              setOpenPopup2(true);

            const reservationDirect = Object.values(arrayReservations)?.find(
              (reservation) => {
                const seatNumbers = Object.keys(
                  reservation.reservation_seats_number
                ).map(Number);
                return seatNumbers.includes(seatNumbersToSend[0]);
              }
            );
            settranset(false);
            setDirect(true);
            const passengerData = [];

            seatNumbersToSend.forEach((seat) => {
                                const matchingPassenger =
                                  reservationDirect.passengers.find(
                                    (passenger) => {
                                      return passenger.number_seat == seat;
                                    }
                                  );
                         
                                  passengerData.push({
                                    id:"",
                                    number_seat:seatNumbersToSend[0],
                                    id_price_ticket:"",
                                    price:"",
                                    NewSeat: "",
                                    typeReduction: "",
                                    // Add other passenger details as needed
                                  });

                                  reservationIds1.push({
                                    reservationId1: reservationDirect.id,
                                  });
                                  
                                
                              });

                              if (passengerData.length > 0) {
                                const existingPassengerData =
                                  JSON.parse(
                                    localStorage.getItem("passengers")
                                  ) || [];
                                const updatedPassengerData = [
                                  // ...existingPassengerData,
                                  ...passengerData,
                                ];

                                localStorage.setItem(
                                  "passengers",
                                  JSON.stringify(updatedPassengerData)
                                );
                              }
                         
            reservationIds1.push({
              reservationId1: reservationDirect.id,
            });

            setIdReservationResponse(reservationIds1);
                            }
                          else if(status == 2){
                            setOpenPopup2(true);
                            localStorage.setItem("changeseat", true);
                            localStorage.setItem("ReservationChange", true);
                            const reservationDirect = Object.values(
                              arrayReservations
                            )?.find((reservation) => {
                              const seatNumbers = Object.keys(
                                reservation.reservation_seats_number
                              ).map(Number);
                              return seatNumbers.includes(seatNumbersToSend[0]);
                            });
                            setIdReservationResponse(reservationDirect);

                            settranset(false);
                            setDirect(true);
                            const passengerData = [];

                            // Loop through selectedSeats and find matching passengers
                            seatNumbersToSend.forEach((seat) => {
                              const matchingPassenger =
                                reservationDirect.passengers.find(
                                  (passenger) => {
                                    return passenger.number_seat == seat;
                                  }
                                );

                              if (matchingPassenger) {
                                passengerData.push({
                                  id:
                                    matchingPassenger.id_passenger ||
                                    matchingPassenger.id,
                                  number_seat: matchingPassenger.number_seat,
                                  id_price_ticket:
                                    matchingPassenger.id_price_ticket,
                                  price: matchingPassenger.price,
                                  NewSeat: "",
                                  typeReduction: "",

                                  // Add other passenger details as needed
                                });

                                reservationIds1.push({
                                  reservationId1: reservationDirect.id,
                                });
                                setUpdatePassenger(true);
                              }
                            });

                            if (passengerData.length > 0) {
                              const existingPassengerData =
                                JSON.parse(
                                  localStorage.getItem("passengers")
                                ) || [];
                              const updatedPassengerData = [
                                ...existingPassengerData,
                                ...passengerData,
                              ];
                              localStorage.setItem(
                                "passengers",
                                JSON.stringify(updatedPassengerData)
                              );
                            }
                          }
                        }
                      } else if (idDirect != TripID.id) {
                        settranset(true);
                        setDirect(false);

                        // Seat not found in transit trip
                        const status = reservationseat1[seatNumbersToSend[0]];
                        reservationIds1.push({
                          reservationId1: reservationIdTransit,
                        });

                        // eslint-disable-next-line eqeqeq
                        if (status == 5 || status == 0) {
                          localStorage.setItem("changeseat", true);
                          localStorage.setItem("ReservationChange", true);
                          sweetalert({
                            text: "لا يمكن حذف كرسي لا يحتوي على بيانات",
                            icon: "error",
                            buttons: {
                              confirm: {
                                text: "تم",
                                className: "custom-button-class",
                              },
                            },
                            className: "alert-div",
                          }).then(() => {
                            localStorage.setItem("changeseat", false);
                            localStorage.setItem("ReservationChange", false);
                          });
                          // eslint-disable-next-line eqeqeq
                        }
                        else if (status == undefined || status == '') {
                            setResultArray([]);
                            sweetalert({
                              text: "يجب إختيار كراسي قبل القيام بهذه العملية",
                              icon: "error",
                              buttons: {
                                confirm: {
                                  text: "إغلاق",
                                  className: "custom-button-class",
                                },
                              },
                              className: "alert-div",
                            }).then(() => {
                              localStorage.setItem("changeseat", false);
                            });
                          }
                         else if (status == 4) {
                          setResultArray([]);
                          localStorage.setItem("changeseat", true);
                          localStorage.setItem("ReservationChange", true);
                          sweetalert({
                            text: "Ctrl + 0 لتغيير حالة كرسي شركة لمتوفر اضغط على  ",
                            icon: "error",
                            buttons: {
                              confirm: {
                                text: "تم",
                                className: "custom-button-class",
                              },
                            },
                            className: "alert-div",
                          }).then(() => {
                            localStorage.setItem("changeseat", false);
                            localStorage.setItem("ReservationChange", false);
                          });
                          // eslint-disable-next-line eqeqeq
                        } else if (status == 3) {
                          setResultArray([]);
                          localStorage.setItem("changeseat", true);
                          localStorage.setItem("ReservationChange", true);
                          sweetalert({
                            text: "Ctrl + 0 لتغيير حالة كرسي ادارة لمتوفر اضغط على  ",
                            icon: "error",
                            buttons: {
                              confirm: {
                                text: "تم",
                                className: "custom-button-class",
                              },
                            },
                            className: "alert-div",
                          }).then(() => {
                            localStorage.setItem("changeseat", false);
                            localStorage.setItem("ReservationChange", false);
                          });
                          // eslint-disable-next-line eqeqeq
                        } 
                       
                        else if (status == 1) {
                              setOpenPopup2(true);

            const reservationDirect = Object.values(arrayReservations)?.find(
              (reservation) => {
                const seatNumbers = Object.keys(
                  reservation.reservation_seats_number
                ).map(Number);
                return seatNumbers.includes(seatNumbersToSend[0]);
              }
            );
            settranset(false);
            setDirect(true);
            const passengerData = [];

            seatNumbersToSend.forEach((seat) => {
                                const matchingPassenger =
                                  reservationDirect.passengers.find(
                                    (passenger) => {
                                      return passenger.number_seat == seat;
                                    }
                                  );
                         
                                  passengerData.push({
                                    id:"",
                                    number_seat:seatNumbersToSend[0],
                                    id_price_ticket:"",
                                    price:"",
                                    NewSeat: "",
                                    typeReduction: "",
                                    // Add other passenger details as needed
                                  });

                                  reservationIds1.push({
                                    reservationId1: reservationDirect.id,
                                  });
                                  
                                
                              });

                              if (passengerData.length > 0) {
                                const existingPassengerData =
                                  JSON.parse(
                                    localStorage.getItem("passengers")
                                  ) || [];
                                const updatedPassengerData = [
                        
                                  ...passengerData,
                                ];

                                localStorage.setItem(
                                  "passengers",
                                  JSON.stringify(updatedPassengerData)
                                );
                              }
                    

            reservationIds1.push({
              reservationId1: reservationDirect.id,
            });

            setIdReservationResponse(reservationIds1);
                            }
                        else if(status == 2){
                          setOpenPopup2(true);
                          localStorage.setItem("changeseat", true);
                          localStorage.setItem("ReservationChange", true);
                          const reservationIdTransit = Array.isArray(
                            TripID.tripTransit
                          )
                            ? Object.values(arrayReservations)?.reduce(
                                (acc, reservation) => {
                                  const seatNumbers = Object.keys(
                                    reservation.reservation_seats_number
                                  ).map(Number);
                                  if (
                                    seatNumbers.includes(seatNumbersToSend[0])
                                  ) {
                                    acc.push({
                                      reservationId: reservation.id,
                                      passengers: reservation.passengers,
                                      trip_id:
                                        reservation.trip_id ||
                                        reservation.triptable_id,
                                    });
                                  }
                                  return acc;
                                },
                                []
                              )
                            : null;
                          const relevantReservations = Object.values(
                            reservationIdTransit
                          ).filter(
                            (reservation) =>
                              reservation.trip_id.toString() === idtransit
                          );

                          setIdReservationResponse(relevantReservations);
                          if (relevantReservations) {
                            const passengerData = [];

                            if (Array.isArray(relevantReservations)) {
                              const passengersForSeat = [];

                              // relevantReservations.forEach((reservation) => {
                              const matchingPassenger =
                                relevantReservations[0]?.passengers.find(
                                  (passenger) => {
                                    return (
                                      passenger.number_seat ===
                                      seatNumbersToSend[0]
                                    ); // Use strict equality
                                  }
                                );

                              if (matchingPassenger) {
                                passengerData.push({
                                  id:
                                    matchingPassenger.id_passenger ||
                                    matchingPassenger.id,
                                  number_seat: matchingPassenger.number_seat,
                                  id_price_ticket:
                                    matchingPassenger.id_price_ticket,
                                  price: matchingPassenger.price,
                                  NewSeat: "",
                                  typeReduction: "",
                                });
                              }
                 
                            }

                            if (passengerData.length > 0) {
                              const flattenedPassengerData =
                                passengerData.flat();

                      
                              const updatedPassengerData = [
                                ...flattenedPassengerData,
                              ];

                              localStorage.setItem(
                                "passengers",
                                JSON.stringify(updatedPassengerData)
                              );
                            }
                          }
                        }
                      }
                    }}
                
                  
                  >
                    مسافر
                  </Grid>
                  <Popuppassenger3
                    title="تبديل مقعد مسافر"
                    openPopup={openPopup2}
                    setOpenPopup={setOpenPopup2}
                    setDisplayshortcut={setShortcut}
                    setstatus={setstatus}
                    updatePassenger={updatePassenger}
                    setUpdatePassenger={setUpdatePassenger}
                    setNewStatus={setNewStatus}
                    onConfiarm={handleConfirm}
                    setOpenopoup1={setOpenopoup1}
                    openopoup1={openopoup1}
                    setResultArray={setResultArray}
                    setSeatAndUser={setSeatAndUser}
                    reservationIds={Reservationdirectrip}
                    transet={transet}
                    idTransit={idTransit}
                    direct={direct}
                    id_trip={id_trip}
                    Trip={TripID}
                    setDirect={setDirect}
                    settranset={settranset}
                    seatNumber={seatNumber}
                    selectedSeats={selectedSeats}
                    setSelected={setSelected}
                    select={select}
                  >
                    <Box class="triptablewidth1">
                      <StepPassengerSwap
                       setOpenPopup={setOpenPopup2}
                        setResultArray={setResultArray}
                        numberpassenger={resultArray}
                        onConfiarm={handleConfirm}
                        setstatus={setstatus}
                        reservationIds={reservationIds}
                        response1={response1}
                        id_company={id_company}
                        id_trip={id_trip}
                        id_trip_type={IDTripType}
                        transet={transet}
                        direct={direct}
                        idDirect={idDirect}
                        setResponse1={setResponse1}
                        setReservationseat1={setReservationseat1}
                        branch_id={TripID.id_branch}
                        setDirect={setDirect}
                        updateSeat={updateSeat}
                        setUpdateSeat={setUpdateSeat}
                        updatePassenger={updatePassenger}
                        setUpdatePassenger={setUpdatePassenger}
                        reservationss={reservationss}
                        setArraySeat={setArraySeat}
                        arrayseat={arrayseat}
                        idReservationresponse={idReservationresponse}
                        setSelectedSeats1={setSelectedSeats1}
                        setSelectedSeats={setSelectedSeats}
                        setSeatAndUser={setSeatAndUser}
                        setArraySeatPassengerTransit1={
                          setArraySeatPassengerTransit1
                        }
                        arrayseatPassengerTransit1={arrayseatPassengerTransit1}
                        setArraySeatPassengerTransit2={
                          setArraySeatPassengerTransit2
                        }
                        arrayseatPassengerTransit2={arrayseatPassengerTransit2}
                        selectedTransitIndex={selectedTransitIndex}
                        setIdTransit1={setIdTransit1}
                        id_transit1={id_transit1}
                        idTransit={idTransit}
                        setSelectedSeats2={setSelectedSeats2}
                        setArrayReservations={setArrayReservations}
                        arrayReservations={arrayReservations}
                        setIdTicketPrice={setIdTicketPrice}
                        idTricketPrice={idTricketPrice}
                        valueinput={valueinput}
                        settranset={settranset}
                        reservationseat1={reservationseat1}
                        setValueinput={setValueinput}
                        setInputString={setInputString}
                        selectedSeats={selectedSeats}
                        selectedSeats2={selectedSeats2}
                        selectedSeats1={selectedSeats1}
                        openPopup={openPopuppassenger2}
                        
                      />
                    </Box>
                  </Popuppassenger3>
          
            
              
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                sm={6}
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={6}
                  md={6}
                  sm={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      padding: "0px 5px",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    الرحلات
                  </p>
                  {TripID && (
                    <React.Fragment>
                      <div
                        style={{ height: "25px", cursor: "pointer" }}
                        className="tiptransitdiv"
                        onClick={() => {
                          setReservationdirect();
                        }}
                      >
                        {TripID.info}
                      </div>
                    </React.Fragment>
                  )}
                  {TripID.tripTransit.length > 0 && (
                    <div
                      style={{ height: "25px", cursor: "pointer" }}
                      className="tiptransitdiv"
                      onClick={() => setReservationTransit1()}
                    >
                      {TripID.tripTransit[0]?.name}
                    </div>
                  )}

                  {TripID.tripTransit.length > 1 && (
                    <div
                      style={{ height: "25px", cursor: "pointer" }}
                      className="tiptransitdiv"
                      onClick={() => setReservationTransit2()}
                    >
                      {TripID.tripTransit[1]?.name}
                    </div>
                  )}
                </Grid>

                <Grid
                  item
                  xs={6}
                  md={6}
                  sm={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      padding: "0px 5px",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    رقم المقاعد
                  </p>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    sm={6}
                    style={{ padding: "1% 0px" }}
                    className="input-seats"
                  >
                    <input
                      style={{ width: "90%" }}
                      ref={inputRef}
                      onChange={handleChange}
                      value={valueinput}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {showSystemUserReservation == true ? (
            <Box className="Box-Bus">
              {seatsByCols.map((col, index) => (
                <div key={index} className="seat-group">
                  {col.map((seat, seatIndex) => {
                    if (seat !== null) {
                      let reservedPassenger = null;
                      Object.values(Reservations)?.forEach((reservation) => {
                        if (
                          reservation.reservation_seats_number[seat] === 2 &&
                          reservation.trip_id == idtrip
                        ) {
                          reservedPassenger = reservation; // Found the reservation for this seat
                        }
                      });
                      let isCommonSeat = seatsArray2.includes(seat);

                      return (
                        <Card
                          key={seatIndex}
                          style={{
                            width: "23%",
                            marginBottom: "0.2%",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "1%",
                            position: "relative",
                            borderRadius: "none !important",
                            height: "95%",
                            background: isCommonSeat ? color : "#03314B",
                            cursor: "not-allowed",
                          }}
                        >
                          <span
                            style={{
                              color: "white",
                              display: "flex",
                              justifyContent: "center",
                              position: "relative",
                              fontSize: "12px",
                              padding: "0px 3px 0px 0px",
                            }}
                          >
                            {seat} -
                          </span>

                          {reservedPassenger && (
                            <span
                              style={{
                                color: "white",
                                textAlign: "center",
                                fontSize: "12px",
                                padding: "0% 0px",
                              }}
                            >
                              {`${reservedPassenger.reservationFName} ${reservedPassenger.reservationLName}`}
                            </span>
                          )}
                        </Card>
                      );
                    }
                    // Return spacer if there is no seat
                    return <div style={{ width: "23%", height: "20px" }} />;
                  })}
                </div>
              ))}
            </Box>
          ) : (
            <Box className="Box-Bus">
              {seatsByCols.map((col, index) => (
                <div key={index} className="seat-group">
                  {col.map((seat, seatIndex) => {
                    if (seat !== null) {
                      const mergedSelectedSeats = selectedSeats
                        .concat(selectedSeats1)
                        .sort();
                      const combinedSortedSeats = [
                        ...selectedSeats,
                        ...selectedSeats1,
                      ];
                      const sortedCombinedSeats = combinedSortedSeats.sort(
                        (a, b) => a - b
                      );
                      const lastSelectedSeatStatus =
                        sortedCombinedSeats.length > 0
                          ? response1[
                              sortedCombinedSeats[
                                sortedCombinedSeats.length - 1
                              ]
                            ]
                          : null;
                      const lastSelectedSeatStatus2 =
                        selectedSeats2.length > 0
                          ? response1[selectedSeats2[selectedSeats2.length - 1]]
                          : null;

                      const lastSelectedSeatReservationId2 =
                        selectedSeats2.length > 0
                          ? getReservationIdForSeat(
                              selectedSeats2[selectedSeats2.length - 1],
                              arrayReservations,
                              idtrip
                            )
                          : null;

                      const lastSelectedSeatReservationId =
                        sortedCombinedSeats.length > 0
                          ? getReservationIdForSeat(
                              sortedCombinedSeats[
                                sortedCombinedSeats.length - 1
                              ],
                              arrayReservations,
                              idtrip
                            )
                          : null;
                      const seatStatus = response1[seat];
                      const isAvailable = seatStatus === 0;
                      const chang_seat_status = seatStatus === 3;
                      const status_seat_pending_notData = seatStatus === 2;
                      const isSeatInTransit = TripID.tripTransit.some(
                        (trip) => trip.reservationSeats[seat] > 0
                      );
                      let isReserved = response1[seat] === 2;
                      let isReserved1 = reservationseat1[seat] === 2;
                      let isReserved2 = reservationseat1[seat] === 1;
                      const isSeatAllowed =
                        lastSelectedSeatStatus === null ||
                        ((seatStatus == 0 || seatStatus == 5) &&
                          seatStatus !== lastSelectedSeatStatus) ||
                        (seatStatus == 3 &&
                          seatStatus !== lastSelectedSeatStatus) ||
                        (seatStatus == 4 &&
                          seatStatus !== lastSelectedSeatStatus) ||
                        (seatStatus === lastSelectedSeatStatus &&
                          (!isReserved ||
                            (isReserved && lastSelectedSeatStatus === 2))) ||
                        (seatStatus === lastSelectedSeatStatus2 &&
                          (!isReserved ||
                            (isReserved && lastSelectedSeatStatus2 === 2)));

                      const isSeatAllowed2 =
                        lastSelectedSeatStatus2 === null ||
                        !isReserved ||
                        (isReserved && lastSelectedSeatStatus2 === 2);
                      const isSeatAllowedtransit =
                        lastSelectedSeatStatus2 === null ||
                        !isReserved1 ||
                        (isReserved1 && lastSelectedSeatStatus2 === 6);

                      const isSeatAllowedtransitnodata =
                        lastSelectedSeatStatus2 === null ||
                        !isReserved2 ||
                        (isReserved2 && lastSelectedSeatStatus2 === 6);

                      const reservationInfo = TripID.reservations.find(
                        (reservation) =>
                          reservation.reservation_seats_number[seat] > 0
                      );

                      const passenger = reservationInfo
                        ? reservationInfo.passengers.find(
                            (p) => p.number_seat === seat
                          )
                        : null;

                      {
                        /* const foundPassenger = findPassengerBySeat(seat, TripID.reservations); */
                      }

                      const isSeatInTransit1 = TripID.tripTransit.some(
                        (trip) =>
                          trip.reservationSeats &&
                          trip.reservationSeats[seat] > 0
                      );
                      const reservationSeatsObject =
                        TripID.tripTransit.find(
                          (reservation) => reservation.reservationSeats
                        )?.reservationSeats || {};
                      const isSeatInTransit11 =
                        response1[seat] === 6 &&
                        reservationSeatsObject[seat] === 0;
                      const isSeatInTransitselected =
                        reservationseat1[seat] === 5;
                      const isSeatInTransitNodata =
                        reservationseat1[seat] === 1;

                      const isSeatInTransit21 =
                        response1[seat] == 6 && reservationseat1[seat] == 4;
                      const isSeatInTransit211 =
                        response1[seat] == 6 && reservationseat1[seat] == 1;
                      const isSeatInTransit221 =
                        response1[seat] == 6 && reservationseat1[seat] == 3;
                      const pendingSeats =
                        JSON.parse(localStorage.getItem("seatpinding")) || [];

                      const isReservedByOther = pendingSeats.some(
                        (pendingSeat) =>
                          pendingSeat.seat_number === seat &&
                          pendingSeat.trip_id === TripID.id &&
                          (pendingSeat.id_system !== user.user_id ||
                            pendingSeat.id_client !== 0)
                      );
                      let passengerTransit = null;
                      let resertranst = [];

                      for (const trip of TripID.tripTransit) {
                        for (const reservation of trip.reservation) {
                          if (
                            reservation.reservation_seats_number[seat] &&
                            reservation.trip_id === idTransit
                          ) {
                            passengerTransit = reservation.passengers.find(
                              (p) => p.number_seat === seat
                            );
                            resertranst = reservation.reservation_seats_number;
                            if (passengerTransit) {
                              id_triptransit = trip.id;
                              break;
                            }
                          }
                        }
                        if (passengerTransit) {
                          break;
                        }
                      }
                      let isTransitIdMatch = TripID.tripTransit.some(
                        (transit) => transit.id === idTransit
                      );
                      const doesSeatBelongToTransit = (
                        seatNumber,
                        idTransit
                      ) => {
                        // Find the transit object that matches idTransit
                        const transitObject = TripID.tripTransit.find(
                          (transit) => transit.id === idTransit
                        );

                        if (
                          transitObject &&
                          transitObject.reservationSeats.hasOwnProperty(
                            seatNumber
                          )
                        ) {
                          return true;
                        }
                        return false;
                      };

                      const idTransitFromStorage =
                        localStorage.getItem("idtransit");
                      const activeTransitId = idTransit || idTransitFromStorage; // Use state or localStorage value

                      const seatBelongsToCurrentTransit =
                        doesSeatBelongToTransit(seat, activeTransitId);

                      let isCommonSeat = commonSeats.includes(seat);

                      const isCommonSeat1 =
                        commonSeats.includes(seat) && response1[seat] === 6;
                      const seatStatus1 = response1[seat];
                      const isReservedInTransit =
                        seatStatus === 6 && commonSeats.includes(seat);
                      const isReservedInTransitnodata =
                        reservationSeatsObject[seat] == 4;
                      const resultArray1 = resultArray.includes(seat);
                      const selectedReservation = selectedSeats2.includes(seat);
                      const selectedSeats11 = selectedSeats1.includes(seat);
                      const normalizedSelect = Array.isArray(select)
                        ? select
                        : [select];
                     
                      const isSeatSelectedByUser = (seat) => {
                        // Check if the seat is reserved by the current user

                        return normalizedSelect.some(
                          (userSeat) =>
                            userSeat.id_system === user.user_id &&
                            userSeat.seat_number === seat &&
                            userSeat.status == "Selected" &&
                            userSeat.numStatus == 5 &&
                            userSeat.id_transit == null &&
                            userSeat.trip_id == TripID.id
                        );
                      };
                      const isSeatSelectedByUserTransit = (seat) => {
                        // Check if the seat is reserved by the current user

                        return normalizedSelect.some(
                          (userSeat) =>
                            userSeat.id_system === user.user_id &&
                            userSeat.seat_number === seat &&
                            userSeat.status == "Selected" &&
                            userSeat.numStatus == 5 &&
                            userSeat.id_transit == idTransit &&
                            userSeat.trip_id == TripID.id
                        );
                      };
                      const isSeatReservedByAnotherUserTransit = (seat) => {
                        return normalizedSelect.some(
                          (userSeat) =>
                            userSeat.seat_number === seat &&
                            userSeat.id_system !== user.user_id &&
                            userSeat.status == "Selected" &&
                            userSeat.numStatus == 5 &&
                            userSeat.id_transit == idTransit &&
                            userSeat.trip_id == TripID.id
                        );
                      };

                      const isSeatReservedByAnotherUser = (seat) => {
                        return normalizedSelect.some(
                          (userSeat) =>
                            userSeat.seat_number === seat &&
                            userSeat.id_system !== user.user_id &&
                            userSeat.status == "Selected" &&
                            userSeat.numStatus == 5 &&
                            userSeat.id_transit == null &&
                            userSeat.trip_id == TripID.id
                        );
                      };

                      const isReservedsystemuser =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Selected" &&
                            seatObj.id_system == user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat
                        );

                      const isReservedNoData =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Selected" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null &&
                            seatObj.numStatus == 4 &&
                            seatObj.id_transit == null
                        );
                      const isReservedManager =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Selected" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null &&
                            seatObj.numStatus == 3 &&
                            seatObj.id_transit == null
                        );
                      const isReservedNoAnyData =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Selected" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null &&
                            seatObj.numStatus == 4 &&
                            seatObj.id_transit == null
                        );
                      const isReservedManagerTransit =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Selected" &&
                            seatObj.numStatus == 3 &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_transit === idTransit
                        );
          
                      const isReservedRed =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Selected" &&
                            seatObj.numStatus == 1 &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_transit == null
                        );
         
                      const isReservedRedTransit =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Selected" &&
                            seatObj.numStatus == 1 &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_transit === idTransit
                        );

                      const isReservedNoDataTransit =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Selected" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null &&
                            seatObj.numStatus == 4 &&
                            seatObj.id_transit === idTransit
                        );
                      const isReservedTransit =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Selected" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null &&
                            seatObj.numStatus == null &&
                            seatObj.id_transit === idTransit
                        );

                      const isReservedconverttoAvailable =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Deleted" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null &&
                            seatObj.numStatus == 0 &&
                            seatObj.id_transit == null
                        );

                      const isReservedconverttoAvailableTransit =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Deleted" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null &&
                            seatObj.numStatus == 0 &&
                            seatObj.id_transit == idTransit
                        );

                      const isSelectedclient =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Selected" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.numStatus == 5
                        );
                      const isSelecteduser =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Selected" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.numStatus == 5 &&
                            seatObj.id_transit == null &&
                            seatObj.trip_id == TripID.id
                        );

                      const isSelectedusertranset =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Selected" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.numStatus == 5 &&
                            seatObj.id_transit == idTransit &&
                            seatObj.trip_id == TripID.id
                        );
                      const isReservedsystemuserDeleted =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Deleted" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null &&
                            seatObj.numStatus == null &&
                            seatObj.id_transit == null
                        );
                      const isReservedsystemuserDeletedtransit =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Deleted" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null &&
                            seatObj.numStatus == null &&
                            seatObj.id_transit == idTransit
                        );

                      const isReservedclient =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Selected" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null &&
                            seatObj.numStatus == null &&
                            seatObj.id_transit == null
                        );
                      const isReservedpassengerclientdirecttrip =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "no" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null &&
                            seatObj.id_transit == null &&
                            seatObj.trip_id == TripID.id
                        );
                      const isReservedpassengerclienttransit =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "no" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null &&
                            seatObj.id_transit == idtrip
                        );

                      const isReservedclientDeleted =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Deleted" &&
                            seatObj.seat_number == seat
                        );

                      const isReservedSeatTransitsystemuser =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Selected" &&
                            seatObj.id_system == user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == null
                        );

                      const isReservedSeatTransitsystemuserclient =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Selected" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == 1
                        );
                      const isDeleteReservedSeatTransitclient =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Deleted" &&
                            seatObj.id_system !== user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == "transitDeleted"
                        );

                      const isDeleteReservedSeatTransitsystem =
                        Array.isArray(normalizedSelect) &&
                        normalizedSelect.some(
                          (seatObj) =>
                            seatObj.status == "Deleted" &&
                            seatObj.id_system == user.user_id &&
                            seatObj.id_client == 0 &&
                            seatObj.seat_number == seat &&
                            seatObj.transitStatus == "transitDeleted"
                        );

                      const resultArray2 = selectedSeats.includes(seat);
                      const isDisabled = color === "rgb(168, 168, 168)"; // Check if seats should be disabled
                      const seatReservationId = getReservationIdForSeat(
                        seat,
                        arrayReservations,
                        idtrip
                      );

                      // Determine if the seat belongs to a different reservation
                      let seatReservationIdtransit1;
                      let lastSelectedSeatReservationIdtransit1;
                      let seatReservationIdtransit2;
                      let lastSelectedSeatReservationIdtransit2;

                      if (transetnumber !== null) {
                        if (transetnumber === 1) {
                          // Access the reservation of the first transit (index 0)
                          seatReservationIdtransit1 = getReservationIdForSeat(
                            seat,
                            arrayReservations,
                            idtrip
                          );
                          lastSelectedSeatReservationIdtransit1 =
                            selectedSeats2.length > 0
                              ? getReservationIdForSeat(
                                  selectedSeats2[selectedSeats2.length - 1],
                                  arrayReservations,
                                  idtrip
                                )
                              : null;
                        } else if (transetnumber === 2) {
                          // Ensure there is a second transit available
                          if (TripID.tripTransit.length > 1) {
                            // Access the reservation of the second transit (index 1)
                            seatReservationIdtransit2 = getReservationIdForSeat(
                              seat,
                              arrayReservations,
                              idtrip
                            );
                            lastSelectedSeatReservationIdtransit2 =
                              selectedSeats2.length > 0
                                ? getReservationIdForSeat(
                                    selectedSeats2[selectedSeats2.length - 1],
                                    arrayReservations,
                                    idtrip
                                  )
                                : null;
                          } else {
                            console.warn("Second transit does not exist.");
                          }
                        }
                      }
                      if (transetnumber !== null) {
                        if (transetnumber === 2) {
                          // Ensure there is a second transit available
                          if (TripID.tripTransit.length > 1) {
                            // Access the reservation of the second transit (index 1)
                            seatReservationIdtransit2 = getReservationIdForSeat(
                              seat,
                              arrayReservations,
                              idtrip
                            );
                            lastSelectedSeatReservationIdtransit2 =
                              selectedSeats2.length > 0
                                ? getReservationIdForSeat(
                                    selectedSeats2[selectedSeats2.length - 1],
                                    arrayReservations,
                                    idtrip
                                  )
                                : null;
                          } else {
                            console.warn("Second transit does not exist.");
                          }
                        }
                      }
                      let seatReservationIdtransitnodata1;
                      let lastSelectedSeatReservationIdtransitnodata1;
                      let seatReservationIdtransitnodata2;
                      let lastSelectedSeatReservationIdtransitnodata2;

                      if (transetnumber !== null) {
                        if (transetnumber === 1) {
                          // Access the reservation of the first transit (index 0)
                          seatReservationIdtransitnodata1 =
                            getReservationIdForSeat(
                              seat,
                              arrayReservations,
                              idtrip
                            );
                          lastSelectedSeatReservationIdtransitnodata1 =
                            selectedSeats.length > 0
                              ? getReservationIdForSeat(
                                  selectedSeats[selectedSeats.length - 1],
                                  arrayReservations,
                                  idtrip
                                )
                              : null;
                        } else if (transetnumber === 2) {
                          // Ensure there is a second transit available
                          if (TripID.tripTransit.length > 1) {
                            // Access the reservation of the second transit (index 1)
                            seatReservationIdtransitnodata2 =
                              getReservationIdForSeat(
                                seat,
                                arrayReservations,
                                idtrip
                              );
                            lastSelectedSeatReservationIdtransitnodata2 =
                              selectedSeats.length > 0
                                ? getReservationIdForSeat(
                                    selectedSeats[selectedSeats.length - 1],
                                    arrayReservations,
                                    idtrip
                                  )
                                : null;
                          } else {
                            console.warn("Second transit does not exist.");
                          }
                        }
                      }
                      if (transetnumber !== null) {
                        if (transetnumber === 2) {
                          // Ensure there is a second transit available
                          if (TripID.tripTransit.length > 1) {
                            // Access the reservation of the second transit (index 1)
                            seatReservationIdtransit2 = getReservationIdForSeat(
                              seat,
                              arrayReservations,
                              idtrip
                            );
                            lastSelectedSeatReservationIdtransit2 =
                              selectedSeats2.length > 0
                                ? getReservationIdForSeat(
                                    selectedSeats2[selectedSeats2.length - 1],
                                    arrayReservations,
                                    idtrip
                                  )
                                : null;
                          } else {
                            console.warn("Second transit does not exist.");
                          }
                        }
                      }

                      const isDifferentReservation =
                        lastSelectedSeatReservationId !== null &&
                        seatReservationId !== lastSelectedSeatReservationId;

                      const isDifferentReservation2 =
                        lastSelectedSeatReservationId2 !== null &&
                        seatReservationId !== lastSelectedSeatReservationId2;

                      const isDifferentReservationtransit1 =
                        lastSelectedSeatReservationIdtransit1 !== null &&
                        seatReservationIdtransit1 !==
                          lastSelectedSeatReservationIdtransit1;

                      const isDifferentReservationtransit2 =
                        lastSelectedSeatReservationIdtransit2 !== null &&
                        seatReservationIdtransit2 !==
                          lastSelectedSeatReservationIdtransit2;

                      const isDifferentReservationtransitnodata1 =
                        lastSelectedSeatReservationIdtransitnodata1 !== null &&
                        seatReservationIdtransitnodata1 !==
                          lastSelectedSeatReservationIdtransitnodata1;
                        
                      const isDifferentReservationtransitnodata2 =
                        lastSelectedSeatReservationIdtransitnodata2 !== null &&
                        seatReservationIdtransitnodata2 !==
                          lastSelectedSeatReservationIdtransitnodata2;

                      if (
                        !isSeatAllowed == true ||
                        isDifferentReservation == true ||
                        Clic == true
                      ) {
                        isReserved = false;
                      }
                      if (
                        isDifferentReservation2 == true ||
                        Clic == true ||
                        !isSeatAllowed2 == true
                      ) {
                        isReserved = false;
                      }

                      if (
                        isDifferentReservationtransit1 == true ||
                        Clic == true ||
                        !isSeatAllowedtransit == true
                      ) {
                        isReserved1 = false;
                      }
                      if (
                        isDifferentReservationtransitnodata1 == true ||
                        Clic == true ||
                        !isSeatAllowedtransitnodata == true
                      ) {
                        isReserved2 = false;
                      }
                      if (
                        isDifferentReservationtransit2 == true ||
                        Clic == true ||
                        !isSeatAllowedtransit == true
                      ) {
                        isReserved1 = false;
                      }
                      if (
                        isDifferentReservationtransitnodata2 == true ||
                        Clic == true ||
                        !isSeatAllowedtransitnodata == true
                      ) {
                        isReserved2 = false;
                      }
                      let imgSrc = "";
                      if (isAvailable) {
                        imgSrc = "img/set1.svg";
                      } else if (isAvailable1) {
                        imgSrc = "img/set3.svg";
                      } else if (isAvailable2) {
                        imgSrc = "img/set2.svg";
                      } else if (isAvailable3) {
                        imgSrc = "img/set5.png";
                      }
                      const reservationDetails =
                        arrayReservations[arrayseat[seat]?.id_reservation];
                      const isNameNull = reservationDetails
                        ? reservationDetails.name === null
                        : false;
              
                      return (
                        <>
                          <Card
                            key={seatIndex}
                            style={{
                              width: "23%",
                              marginBottom: "0.2%",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: "1%",
                              position: "relative",
                              borderRadius: "none !important",
                              height: "95%",
                              background:
                                (isSelecteduser &&
                                  isReservedconverttoAvailable != true &&
                                  idDirect != undefined &&
                                  idDirect != "") ||
                            
                                (isSelectedusertranset &&
                                  isReservedconverttoAvailable != true &&
                                  !(idDirect != undefined && idDirect != "") &&
                                  isCommonSeat) ||
                                (isSeatReservedByAnotherUser(seat) &&
                                  idDirect != undefined &&
                                  idDirect != "" &&
                                  !isCommonSeat) ||
                              
                                (isSeatReservedByAnotherUserTransit(seat) &&
                                  !(idDirect != undefined && idDirect != "") &&
                                  isCommonSeat &&
                                  isSeatInTransitselected &&
                                  !(idDirect != undefined && idDirect != ""))
                                  ? "red"
                                  : (resultArray1 &&
                                      idDirect != undefined &&
                                      idDirect != "" &&
                                      !isCommonSeat) ||
                                    (resultArray1 &&
                                      !(
                                        idDirect != undefined && idDirect != ""
                                      ) &&
                                      isCommonSeat &&
                                      isSeatInTransitselected) ||
                                    (resultArray2 &&
                                      idDirect != undefined &&
                                      idDirect != "" &&
                                      !isCommonSeat) ||
                                    (resultArray2 &&
                                      !(
                                        idDirect != undefined && idDirect != ""
                                      ) &&
                                      isCommonSeat &&
                                      isSeatInTransitselected) ||
                                    (resultArray2 &&
                                      !(
                                        idDirect != undefined && idDirect != ""
                                      ) &&
                                      isCommonSeat &&
                                      isSeatInTransitNodata) ||
                                    (selectedReservation &&
                                      idDirect != undefined &&
                                      idDirect != "" &&
                                      !isCommonSeat) ||
                                    (selectedReservation &&
                                      !(
                                        idDirect != undefined && idDirect != ""
                                      ) &&
                                      isCommonSeat) ||
                                    (selectedSeats11 &&
                                      idDirect != undefined &&
                                      idDirect != "" &&
                                      !isCommonSeat) ||
                                    (selectedSeats11 &&
                                      !(
                                        idDirect != undefined && idDirect != ""
                                      ) &&
                                      isCommonSeat) ||
                                    (isSeatSelectedByUser(seat) &&
                                      idDirect != undefined &&
                                      idDirect != "" &&
                                      !isCommonSeat) ||
                                    (isSeatSelectedByUserTransit(seat) &&
                                      !(
                                        idDirect != undefined && idDirect != ""
                                      ) &&
                                      isCommonSeat)
                                  ? "green"
                                  : 
              
                                  isDifferentReservationtransit1 == true ||
                                    isDifferentReservationtransit2 == true ||
                                    isDifferentReservationtransitnodata2 ==
                                      true ||
                                    isDifferentReservationtransitnodata1 ==
                                      true ||
                                    (isDeleteReservedSeatTransitclient &&
                                      !(
                                        idDirect != undefined && idDirect != ""
                                      ))
                                  ? "gray"
                                  : (isCommonSeat &&
                                      !isDeleteReservedSeatTransitclient &&
                                      !isReservedsystemuserDeleted &&
                                      !isDifferentReservationtransit1 &&
                                      !isDifferentReservationtransit2 &&
                                      !isReservedTransit) ||
                                    isReservedSeatTransitsystemuserclient ||
                                    (isReservedsystemuserDeletedtransit &&
                                      !(
                                        idDirect != undefined && idDirect != ""
                                      ))
                                  ? color
                                  : (isReserved &&
                                      !isReservedsystemuserDeleted) ||
                                    (isReservedpassengerclientdirecttrip &&
                                      idDirect != undefined &&
                                      idDirect != "") ||
                                    (isReservedpassengerclienttransit &&
                                      !(
                                        idDirect != undefined && idDirect != ""
                                      ))
                                  ? "#A15A95"
                                  : (isSeatAllowed == false &&
                                      isCommonSeat === true) ||
                                    isDifferentReservation == true ||
                                    isDifferentReservation2 == true
                                  ? color
                                  : ((isCommonSeat === false &&
                                      Clic === true) ||
                                      (isCommonSeat === true &&
                                        Clic === false) ||
                                      isSeatAllowed === false) &&
                                    ((isDeleteReservedSeatTransitclient &&
                                      !(
                                        idDirect != undefined && idDirect != ""
                                      )) ||
                                      !isReservedsystemuserDeleted ||
                                      (isReservedsystemuserDeleted &&
                                        !(
                                          idDirect != undefined &&
                                          idDirect != ""
                                        )))
                                  ? "gray"
                                  : 

                                  isReservedsystemuserDeleted ||
                                   
                                    isReservedManager ||
                                    isReservedNoAnyData ||
                                    (isDeleteReservedSeatTransitclient &&
                                      idDirect != undefined &&
                                      idDirect != "") ||
                                    isReservedconverttoAvailable ||
                                    seatStatus === 0 ||
                                    (idDirect != undefined && idDirect != "")
                                  ? "#03314B"
                                  : "#03314B",
                              cursor:
                                resultArray1 ||
                                (isDeleteReservedSeatTransitclient &&
                                  idDirect != undefined &&
                                  idDirect != "")
                                  ? "pointer"
                                  : !isSeatAllowed ||
                                    isReservedclient === true ||
                                    (isCommonSeat === true && Clic === false) ||
                                    (isCommonSeat === false && Clic === true) ||
                                    isDifferentReservation == true ||
                                    isDifferentReservation2 == true ||
                                    isDifferentReservationtransit1 == true ||
                                    isDifferentReservationtransit2 == true ||
                                    isDifferentReservationtransitnodata2 ==
                                      true ||
                                    isDifferentReservationtransitnodata1 ==
                                      true ||
                                    isSelecteduser == true ||
                                    (seatStatus == 6 &&
                                      idDirect != undefined &&
                                      idDirect != "") ||
                                    // isReservedRed ||
                                    isReservedRedTransit ||
                                    isSeatReservedByAnotherUser(seat) ||
                                    isSeatReservedByAnotherUserTransit(seat) ||
                                    (isDeleteReservedSeatTransitclient &&
                                      !(
                                        idDirect != undefined && idDirect != ""
                                      ))
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                            onClick={
                              (isCommonSeat === true && Clic === false) ||
                              (isCommonSeat === false && Clic === true) ||
                              isSelecteduser == true ||
                              isReservedclient ||
                              isDifferentReservation == true ||
                              isDifferentReservation2 == true ||
                              (seatStatus == 6 &&
                                idDirect != undefined &&
                                idDirect != "") ||
                              isDifferentReservationtransit2 == true ||
                              isDifferentReservationtransit1 == true ||
                              isReservedRed ||
                              isReservedRedTransit ||
                              isSeatReservedByAnotherUser(seat) ||
                              isSeatReservedByAnotherUserTransit(seat)
                                ? null
                                : () => handleSeatClick(seat)
                            }
                          >
                            <span
                              style={{
                                color: "white",
                                display: "flex",
                                justifyContent: "center",
                                position: "relative",
                              }}
                            >
                              <span
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  padding: "0px 3px 0px 0px",
                                }}
                              >
                                {seat} -
                              </span>
                              {((!isReservedManager &&
                                isReservedconverttoAvailable) ||
                                (isDeleteReservedSeatTransitclient &&
                                  idDirect != undefined &&
                                  idDirect != "") ||
                                isReservedsystemuserDeleted ||
                                isReservedSeatTransitsystemuserclient ||
                                (isReservedsystemuserDeletedtransit &&
                                  !(
                                    idDirect != undefined && idDirect != ""
                                  ))) && (
                                <span
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "12px",
                                    padding: "0px 3px 0px 0px",
                                  }}
                                ></span>
                              )}
                            </span>
                            {((isReservedclient &&
                              idDirect != undefined &&
                              idDirect != "") ||
                              isReservedTransit ||
                              (isReservedRed &&
                                !isReservedRedTransit &&
                                idDirect != undefined &&
                                idDirect != "")) && (
                              <span
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  padding: "0% 0px",
                                }}
                              >
                                {/* محجوز */}
                              </span>
                            )}

                            {arrayseat &&
                              arrayseat[seat] &&
                              Object.keys(arrayseat[seat].passengers).length >
                                0 &&
                              idDirect != undefined &&
                              idDirect != "" &&
                              !isReservedsystemuserDeleted && (
                                <>
                                  <span
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      fontSize: "12px",
                                      padding: "0% 0px",
                                    }}
                                  >
                                    {arrayseat &&
                                      arrayseat[seat]?.passengers.first_name !=
                                        null &&
                                      arrayseat[seat]?.passengers.first_name}
                                    {arrayseat &&
                                      arrayseat[seat]?.passengers.middle_name !=
                                        null &&
                                      arrayseat[seat]?.passengers.middle_name}
                                    {arrayseat &&
                                      arrayseat[seat]?.passengers.last_name !=
                                        null &&
                                      arrayseat[seat]?.passengers.last_name}

                                    {/* {`${arrayseat[seat]?.passengers.first_name} ${arrayseat[seat]?.passengers.middle_name}  ${arrayseat[seat]?.passengers.last_name}`} */}
                                  </span>
                                  <span className="heart-div">
                                    {arrayseat &&
                                      arrayseat[seat]?.passengers.gender ===
                                        "female" && <FavoriteIcon />}
                                  </span>
                                </>
                              )}
                            {((isReservedpassengerclienttransit &&
                              !(idDirect != undefined && idDirect != "")) ||
                              (isReservedRedTransit &&
                                !isReservedRed &&
                                !(idDirect != undefined && idDirect != "")) ||
                              (isReservedpassengerclientdirecttrip &&
                                !passenger &&
                                idDirect != undefined &&
                                idDirect != "")) && (
                              <span>
                                <span
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "12px",
                                    padding: "0% 0px",
                                  }}
                                >
                                  {/* محجوز */}
                                </span>
                              </span>
                            )}
                            <span>
                              <span>
                                {selectedTransitIndex == 0 &&
                                !(idDirect != undefined && idDirect != "") &&
                                !isReservedsystemuserDeletedtransit ? (
                                  <>
                                    <span
                                      style={{
                                        color: "white",
                                        textAlign: "center",
                                        fontSize: "12px",
                                        padding: "0% 0px",
                                      }}
                                    >
                                      {
                                        arrayseatPassengerTransit1[seat]
                                          ?.passengers?.first_name
                                      }
                                      {
                                        arrayseatPassengerTransit1[seat]
                                          ?.passengers?.last_name
                                      }
                                    </span>
                                    <span className="heart-div-transit">
                                      {arrayseatPassengerTransit1 &&
                                        arrayseatPassengerTransit1[seat]
                                          ?.passengers?.gender == "female" && (
                                          <FavoriteIcon />
                                        )}
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {selectedTransitIndex == 1 &&
                                !(idDirect != undefined && idDirect != "") &&
                                !isReservedsystemuserDeletedtransit ? (
                                  <>
                                    <span
                                      style={{
                                        color: "white",
                                        textAlign: "center",
                                        fontSize: "12px",
                                        padding: "0% 0px",
                                      }}
                                    >
                                      {
                                        arrayseatPassengerTransit2[seat]
                                          ?.passengers?.first_name
                                      }
                                      {
                                        arrayseatPassengerTransit2[seat]
                                          ?.passengers?.last_name
                                      }
                                    </span>
                                    <span className="heart-div-transit">
                                      {arrayseatPassengerTransit2 &&
                                        arrayseatPassengerTransit2[seat]
                                          ?.passengers?.gender == "female" && (
                                          <FavoriteIcon />
                                        )}
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </span>
                            </span>
                            {isAvailable && (
                              <span
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  padding: "1% 0px",
                                }}
                              ></span>
                            )}

                            {((isReservedSeatTransitsystemuserclient &&
                              !isReservedclient &&
                              idDirect != undefined &&
                              idDirect != "") ||
                              (isCommonSeat &&
                                idDirect != undefined &&
                                idDirect != "" &&
                                isReservedconverttoAvailable == false &&
                                !isDeleteReservedSeatTransitclient &&
                                idDirect != undefined &&
                                idDirect != "" &&
                                passengerTransit == null)) && (
                              <span
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  padding: "1% 0px",
                                }}
                              >
                                رحلة عبور
                              </span>
                            )}
                            {((chang_seat_status &&
                              !isReservedclient &&
                              passenger == null &&
                              passengerTransit == null &&
                              !isReservedconverttoAvailable &&
                              ((!isCommonSeat &&
                                idDirect != undefined &&
                                idDirect != "") ||
                                (idTransit != undefined &&
                                  idTransit != "" &&
                                  !isReservedpassengerclientdirecttrip &&
                                  isCommonSeat))) ||
                              (isReservedManager &&
                                !isReservedconverttoAvailable &&
                                !(
                                  isCommonSeat &&
                                  idDirect != undefined &&
                                  idDirect != ""
                                ) &&
                                !(
                                  idTransit != undefined &&
                                  idTransit != "" &&
                                  !isCommonSeat
                                ))) && (
                              <span
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  padding: "0% 0px",
                                }}
                                className="reservation-manager"
                              >
                                الادارة
                              </span>
                            )}
                            {/* isReservedRed && */}
                            {seatStatus1 == 1 &&
                              arrayReservations &&
                              arrayReservations[
                                arrayseat[seat].id_reservation
                              ] &&
                              arrayReservations[arrayseat[seat].id_reservation]
                                .name === null &&
                              !isReservedconverttoAvailable &&
                              idDirect != undefined &&
                              idDirect != "" && (
                                <span
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "12px",
                                    padding: "0% 0px",
                                  }}
                                  className="reservation-manager"
                                >
                                  {/* {seatStatus1} */}
                                  محجوز بدون بيانات
                                </span>
                              )}
                            {seatStatus1 == 1 &&
                              arrayReservations &&
                              arrayReservations[
                                arrayseat[seat].id_reservation
                              ] && // Ensure the reservation ID exists in arrayReservations
                              arrayReservations[arrayseat[seat].id_reservation]
                                .name !== null &&
                              !isReservedconverttoAvailable &&
                              idDirect != undefined &&
                              idDirect != "" && (
                                <>
                                  <span
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      fontSize: "12px",
                                      padding: "0% 0px",
                                    }}
                                    className="reservation-manager"
                                  >
                                    {/* {seatStatus1} */}
                                    {
                                      arrayReservations[
                                        arrayseat[seat]?.id_reservation
                                      ]?.name
                                    }
                                  </span>
                                  <span className="heart-div2">
                                    <PhoneIcon />
                                  </span>
                                </>
                              )}
                            {((seatStatus1 == 4 &&
                              !isReservedclient &&
                              passenger == null &&
                              passengerTransit == null &&
                              !isReservedconverttoAvailable &&
                              ((!isCommonSeat &&
                                idDirect != undefined &&
                                idDirect != "") ||
                                (idTransit != undefined &&
                                  idTransit != "" &&
                                  isCommonSeat))) ||
                              (isReservedNoData &&
                                !isReservedconverttoAvailable &&
                                !(
                                  isCommonSeat &&
                                  idDirect != undefined &&
                                  idDirect != ""
                                ) &&
                                !(
                                  idTransit != undefined &&
                                  idTransit != "" &&
                                  !isCommonSeat
                                ))) && (
                              <span
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  padding: "0% 0px",
                                }}
                                className="reservation-manager"
                              >
                                شركة
                              </span>
                            )}
                          
                            {isReservedInTransit && (
                              <span
                                style={{
                                  color: "white",
                                  textAlign: "center",
                                  fontSize: "12px",
                                }}
                              ></span>
                            )}
                         
                    

                            {((isSeatInTransit21 &&
                              idTransit &&
                              !isReservedTransit &&
                              passengerTransit == null) ||
                              isReservedNoDataTransit) &&
                              !(
                                !isReservedNoDataTransit &&
                                isReservedconverttoAvailableTransit
                              ) && (
                                <span
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "12px",
                                    padding: "0% 0px",
                                  }}
                                >
                                  شركة
                                </span>
                              )}
                       
                            {isSeatInTransit211 &&
                              ((arrayReservations &&
                                arrayReservations[
                                  arrayseatPassengerTransit1[seat]
                                    ?.id_reservation
                                ] && // Ensure the reservation ID exists in arrayReservations
                                arrayReservations[
                                  arrayseatPassengerTransit1[seat]
                                    ?.id_reservation
                                ].name == null &&
                                arrayseatPassengerTransit1.id == idTransit) ||
                                (arrayReservations &&
                                  arrayReservations[
                                    arrayseatPassengerTransit2[seat]
                                      ?.id_reservation
                                  ] && // Ensure the reservation ID exists in arrayReservations
                                  arrayReservations[
                                    arrayseatPassengerTransit2[seat]
                                      ?.id_reservation
                                  ].name == null &&
                                  arrayseatPassengerTransit2.id ==
                                    idTransit)) &&
                              !isReservedpassengerclienttransit &&
                              !isReservedconverttoAvailableTransit &&
                              !(idDirect != undefined && idDirect != "") && (
                                <span
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "12px",
                                    padding: "0% 0px",
                                  }}
                                >
                                  محجوز بدون بيانات
                                </span>
                              )}

                            {isSeatInTransit211 &&
                              arrayReservations &&
                              arrayReservations[
                                arrayseatPassengerTransit2[seat]?.id_reservation
                              ] && // Ensure the reservation ID exists in arrayReservations
                              arrayReservations[
                                arrayseatPassengerTransit2[seat]?.id_reservation
                              ].name !== null &&
                              arrayseatPassengerTransit2.id == idTransit &&
                              !isReservedpassengerclienttransit &&
                              !isReservedconverttoAvailableTransit &&
                              !(idDirect != undefined && idDirect != "") && (
                                <>
                                  <span
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      fontSize: "12px",
                                      padding: "0% 0px",
                                    }}
                                  >
                                    {
                                      arrayReservations[
                                        arrayseatPassengerTransit2[seat]
                                          ?.id_reservation
                                      ]?.name
                                    }
                                  </span>
                                  <span className="heart-div2">
                                    <PhoneIcon />
                                  </span>
                                </>
                              )}
                            {isSeatInTransit211 &&
                              arrayReservations &&
                              arrayReservations[
                                arrayseatPassengerTransit1[seat]?.id_reservation
                              ] && // Ensure the reservation ID exists in arrayReservations
                              arrayReservations[
                                arrayseatPassengerTransit1[seat]?.id_reservation
                              ].name !== null &&
                              arrayseatPassengerTransit1.id == idTransit &&
                              !isReservedpassengerclienttransit &&
                              !isReservedconverttoAvailableTransit &&
                              !(idDirect != undefined && idDirect != "") && (
                                <>
                                  <span
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      fontSize: "12px",
                                      padding: "0% 0px",
                                    }}
                                  >
                                    {
                                      arrayReservations[
                                        arrayseatPassengerTransit1[seat]
                                          ?.id_reservation
                                      ]?.name
                                    }
                                  </span>
                                  <span className="heart-div2">
                                    <PhoneIcon />
                                  </span>
                                </>
                              )}
                            {((isSeatInTransit221 &&
                              idTransit &&
                              !isReservedTransit &&
                              passengerTransit == null) ||
                              isReservedManagerTransit) &&
                              !(
                                !isReservedManagerTransit &&
                                isReservedconverttoAvailableTransit
                              ) && (
                                <span
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "12px",
                                    padding: "0% 0px",
                                  }}
                                >
                                  الادارة
                                </span>
                              )}
                          </Card>
                        </>
                      );
                    }
                    return <div style={{ width: "23%", height: "20px" }}></div>;
                  })}
                </div>
              ))}
            </Box>
          )}
        </>
      )}
      <Popuppassenger
        title={title}
        openPopup={openPopuppassenger}
        setOpenPopup={setOpenPopuppassenger}
        setDisplayshortcut={setShortcut}
        setstatus={setstatus}
        updatePassenger={updatePassenger}
        setUpdatePassenger={setUpdatePassenger}
        setNewStatus={setNewStatus}
        onConfiarm={handleConfirm}
        setOpenopoup1={setOpenopoup1}
        openopoup1={openopoup1}
        setResultArray={setResultArray}
        setSeatAndUser={setSeatAndUser}
        reservationIds={Reservationdirectrip}
        transet={transet}
        idTransit={idTransit}
        direct={direct}
        id_trip={id_trip}
        Trip={TripID}
        setDirect={setDirect}
        settranset={settranset}
        seatNumber={seatNumber}
        selectedSeats={selectedSeats}
        setSelected={setSelected}
        select={select}
      >
        <Box class="triptablewidth1">
          <StepPassenger
            setResultArray={setResultArray}
            numberpassenger={resultArray}
            onConfiarm={handleConfirm}
            setstatus={setstatus}
            reservationIds={reservationIds}
            response1={response1}
            id_company={id_company}
            id_trip={id_trip}
            id_trip_type={IDTripType}
            transet={transet}
            direct={direct}
            idDirect={idDirect}
            setResponse1={setResponse1}
            setReservationseat1={setReservationseat1}
            branch_id={TripID.id_branch}
            setDirect={setDirect}
            updateSeat={updateSeat}
            setUpdateSeat={setUpdateSeat}
            updatePassenger={updatePassenger}
            setUpdatePassenger={setUpdatePassenger}
            reservationss={reservationss}
            setArraySeat={setArraySeat}
            arrayseat={arrayseat}
            idReservationresponse={idReservationresponse}
            setSelectedSeats1={setSelectedSeats1}
            setSelectedSeats={setSelectedSeats}
            setSeatAndUser={setSeatAndUser}
            setArraySeatPassengerTransit1={setArraySeatPassengerTransit1}
            arrayseatPassengerTransit1={arrayseatPassengerTransit1}
            setArraySeatPassengerTransit2={setArraySeatPassengerTransit2}
            arrayseatPassengerTransit2={arrayseatPassengerTransit2}
            selectedTransitIndex={selectedTransitIndex}
            setIdTransit1={setIdTransit1}
            id_transit1={id_transit1}
            idTransit={idTransit}
            setSelectedSeats2={setSelectedSeats2}
            setArrayReservations={setArrayReservations}
            arrayReservations={arrayReservations}
            setIdTicketPrice={setIdTicketPrice}
            idTricketPrice={idTricketPrice}
          />
        </Box>
      </Popuppassenger>
    </>
  );
};

export default SetRed;
