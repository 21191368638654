import React, { useEffect } from "react";
import Card from "./Card";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompany } from "../../redux/companyTransportSlice";
import Controls from "../../component/controls/Controls";
import { useState } from "react";
import Popup from "../../component/Popup";
import CompanyAddForm from "./CompanyAddForm";
import PopupCompany from "../../component/PopupCompany";

const useStyles = makeStyles({
  gridDivide: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "23px",
  },
  "@media (min-width: 768px) and (max-width:1366px)": {
    gridDivide: {
      justifyContent: "space-around",
      gap: "10px",
    },
  },
});

const CardList = ({ mylist }) => {
  const classes = useStyles();
  const [openPopup, setOpenPopup] = useState(false);
  const { loading, Companies } = useSelector((state) => state.Company);
  const { user } = useSelector((state) => state.auth);
  const AddCompanyPermission =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "add.company");

  const AddCompanyPermissionsdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "add.company"
    );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompany());
  }, [dispatch]);

  return (
    <Grid className={classes.gridContainer} item lg={12}>
      {AddCompanyPermission === true ||
      AddCompanyPermissionsdefault === true && (
        <Grid
          lg={12}
          md={12}
          sm={12}
          style={{ direction: "ltr" }}
          className="AddCompany"
        >
          <div style={{ direction: "ltr" }} className="add-company">
            <Controls.Button
              type="submit"
              text="إضافة"
              onClick={() => {
                setOpenPopup(true);
              }}
            />
          </div>
        </Grid>
      )}
      <PopupCompany
        title="إضافة شركة"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <CompanyAddForm />
      </PopupCompany>
      <Grid
        container
        spacing={4}
        className={classes.gridContainer}
        justify="center"
      >
        <Grid className={classes.gridDivide}>
          {loading ? (
            <div className="spinner1-box">
              <svg class="spinner1" viewBox="0 0 50 50">
                <circle
                  class="path1"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </div>
          ) : (
            <Card AllCompany={Companies} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CardList;
