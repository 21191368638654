import React, { useState, useEffect, Fragment } from "react";
import { Checkbox, FormGroup, Grid, TextField } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "./useForm";
import "date-fns";
import { useDispatch } from "react-redux";
import { insertTrip } from "../../redux/tripsSlice";
import { useSelector } from "react-redux";
import {
  clearTripTypeState,
  fetchTripTypeById,
} from "../../redux/tripTypesSlice";
import dayjs from "dayjs";
import * as sweetalert from "sweetalert";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputRowDate from "./inputRowDate";
export default function DateForm(props) {
  const [value, setValue] = React.useState(null);
  const [date1, setDate1] = React.useState("");

  const dispatch = useDispatch();
  const initialFValues = {
    driver_name: "",
    driver_assistant_name: "",
    date: "",
    time: "",
    id_trip_type: "",
    id_bus: "",
    id_branch: "",
  };

  const [dateError, setDateError] = useState(""); // Error message for date
  const [timeError, setTimeError] = useState(""); // Error message for time

  const [selectedValue, setSelectedValue] = React.useState("a");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  const handleCreate = () => {
    setSelectedValue("a");
  };
  const handleClose = () => {
    props.setOpenPopup(false);
  };

  const [initialFValues1, setIinitialFValues1] = useState([]);

  const handleRemove = (index) => {
    if (initialFValues1.length !== 1 || initialFValues1.length === 1) {
      const values = [...initialFValues1];
      const removedDate = values.splice(index, 1)[0];
      setIinitialFValues1(values);

      setDate1((prevDates) => {
        // Check if prevDates is an array
        if (Array.isArray(prevDates)) {
          return prevDates.filter((date) => date !== index);
        }
        return prevDates;
      });

      // Retrieve dates from local storage
      const datesFromStorage = JSON.parse(localStorage.getItem("dates")) || [];

      // Remove the unchecked date
      const updatedDates = datesFromStorage.filter((date) => date !== index);

      // Update local storage with modified list
      localStorage.setItem("dates", JSON.stringify(updatedDates));
    }
  };
  const handleAdd = () => {
    setIinitialFValues1([
      ...initialFValues1,
      {
        date: "",
      },
    ]);
  };

  const handleChange2 = (event, index) => {
    const values = [...initialFValues];
    values[index][event.target.name] = event.target.value;

    setIinitialFValues1(values);
  };
  // =========generation trip pass =================================================================
  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("driver_name" in fieldValues) {
      if (!fieldValues.driver_name) {
        temp.driver_name = "هذا الحقل مطلوب";
      } else if (!/^[\p{L}\s]+$/u.test(fieldValues.driver_name)) {
        temp.driver_name = "يرجى إدخال أحرف فقط";
      } else {
        temp.driver_name = "";
      }
    }
    if ("driver_assistant_name" in fieldValues) {
      if (!fieldValues.driver_assistant_name) {
        temp.driver_assistant_name = "هذا الحقل مطلوب";
      } else if (!/^[\p{L}\s]+$/u.test(fieldValues.driver_assistant_name)) {
        temp.driver_assistant_name = "يرجى إدخال أحرف فقط";
      } else {
        temp.driver_assistant_name = "";
      }
    }

    if ("date" in fieldValues)
      temp.date = fieldValues.date ? "" : "هذا الحقل مطلوب";

    if ("id_bus" in fieldValues)
      temp.id_bus = fieldValues.id_bus ? "" : "هذا الحقل مطلوب";
    if ("id_branch" in fieldValues)
      temp.id_branch = fieldValues.id_branch ? "" : "هذا الحقل مطلوب";
    if ("id_trip_type" in fieldValues)
      temp.id_trip_type = fieldValues.id_trip_type ? "" : "هذا الحقل مطلوب";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate() && date1 != null && value != null) {
      const currentDate = new Date();
      const cuttentDate = dayjs(currentDate).format("YYYY-MM-DD");
      const selectedDate = values.date;
      const upper = selectedDate >= cuttentDate;
      const same = selectedDate === cuttentDate;
      const lower = selectedDate < cuttentDate;

      if (selectedDate && (same === true || upper === true)) {
        dispatch(insertTrip(values)).then(() => {
          // After successful insertion, reset the relevant state
          dispatch(clearTripTypeState());
        });
      } else if (!selectedDate) {
        sweetalert({
          text: "يرجى اختيار التاريخ",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        sweetalert({
          text: " يرجى اختيار التاريخ أكبر او يساوي التاريخ الحالي",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
    }
    if (date1 == null) {
      setDateError("يرجى اختيار تاريخ الرحلة");
    }
    if (value == null) {
      setTimeError("يرجى اختيار توقيت الرحلة");
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  const handleChange1 = async (e) => {
    const tripTypeId = e.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      id_trip_type: e.target.value,
      trip_price: "", // Clear the trip_price field when selecting a new trip type
    }));
    dispatch(fetchTripTypeById(e.target.value));
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      // Add date to array
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const afterTomorrow = new Date();
      afterTomorrow.setDate(afterTomorrow.getDate() + 2);

      let selectedDate;

      if (event.target.value === "today") {
        selectedDate = today;
      } else if (event.target.value === "tomorrow") {
        selectedDate = tomorrow;
      } else if (event.target.value === "afterTomorrow") {
        selectedDate = afterTomorrow;
      }
      const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
      setDate1((prevDates) => [...prevDates, formattedDate]);

      // Use the updated state to save in local storage
      localStorage.setItem("dates", JSON.stringify([...date1, formattedDate]));
      setValues((prevValues) => ({
        ...prevValues,
        date: formattedDate,
      }));
    } else if (event.target.checked === false) {
      if (event.target.value === "today") {
        const today = new Date();
        const formattedDate = dayjs(today).format("YYYY-MM-DD");
        // Remove date from array
        setDate1((prevDates) =>
          prevDates.filter((date) => date !== formattedDate)
        );
        // Retrieve dates from local storage
        const datesFromStorage =
          JSON.parse(localStorage.getItem("dates")) || [];

        // Remove the unchecked date
        const updatedDates = datesFromStorage.filter(
          (date) => date !== formattedDate
        );

        // Update local storage with modified list
        localStorage.setItem("dates", JSON.stringify(updatedDates));
      } else if (event.target.value === "tomorrow") {
        const tomorrow = new Date();
        const tommorow1 = tomorrow.setDate(tomorrow.getDate() + 1);
        const formattedDate = dayjs(tommorow1).format("YYYY-MM-DD");
        // Remove date from array
        setDate1((prevDates) =>
          prevDates.filter((date) => date !== formattedDate)
        );
        // Retrieve dates from local storage
        const datesFromStorage =
          JSON.parse(localStorage.getItem("dates")) || [];

        // Remove the unchecked date
        const updatedDates = datesFromStorage.filter(
          (date) => date !== formattedDate
        );

        // Update local storage with modified list
        localStorage.setItem("dates", JSON.stringify(updatedDates));
      } else if (event.target.value === "afterTomorrow") {
        const afterTomorrow = new Date();
        const afterTomorrow1 = afterTomorrow.setDate(
          afterTomorrow.getDate() + 2
        );
        const formattedDate = dayjs(afterTomorrow1).format("YYYY-MM-DD");
        // Remove date from array
        setDate1((prevDates) =>
          prevDates.filter((date) => date !== formattedDate)
        );
        // Retrieve dates from local storage
        const datesFromStorage =
          JSON.parse(localStorage.getItem("dates")) || [];

        // Remove the unchecked date
        const updatedDates = datesFromStorage.filter(
          (date) => date !== formattedDate
        );

        // Update local storage with modified list
        localStorage.setItem("dates", JSON.stringify(updatedDates));
      }
    }
  };

  const handleDateChange = (formattedDate) => {
    setDate1((prevDates) => [...prevDates, formattedDate]);
    localStorage.setItem("dates", JSON.stringify([...date1, formattedDate]));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid className="createdTrip">
        <Grid item xs={12} md={12} sm={12}>
          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row-reverse",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox value="today" onChange={handleCheckboxChange} />
                }
                label="اليوم"
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginLeft: "10px",
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox value="tomorrow" onChange={handleCheckboxChange} />
                }
                label="غداً"
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginLeft: "10px",
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="afterTomorrow"
                    onChange={handleCheckboxChange}
                  />
                }
                label="بعد غد"
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginLeft: "10px",
                }}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        {initialFValues1.map((item, index) => (
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{
              display: "flex",

              marginTop: "10px",
            }}
            className="add-date"
          >
            <Grid item xs={12} md={12} key={index}>
              <InputRowDate
                initialFValues={initialFValues1}
                index={index}
                item={item}
                handleChange={handleChange}
                handleRemove={(date) => handleRemove(date)} // Pass the date
                handleAdd={handleAdd}
                onDateChange={handleDateChange}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <p
        style={{
          fontSize: "15px",
          padding: "10px 0px",
          color: "#f9b917",
          cursor: "pointer",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        onClick={handleAdd}
      >
        إضافة تاريخ+
      </p>

      <Grid xs={12} md={12}>
        <div
          className="bottonFullwidth"
          onClick={handleClose}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Controls.Button type="submit" text="إضافة" />
        </div>
      </Grid>
    </Form>
  );
}
