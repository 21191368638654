import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import { useNavigate } from "react-router-dom";
import {
  clearCompanyData1,
  deleteCompany,
  fetchCompanyById,
} from "../../redux/companyTransportDataSlice";
import { useDispatch } from "react-redux";
import { Menu, MenuItem } from "@mui/material";
import ConfirmDialog from "../../component/ConfirmDialogCompany";
import { useSelector } from "react-redux";
import CompanyEditForm from "./CompanyEditForm";
import PopupCompany from "../../component/PopupCompany";
const useStyles = makeStyles({
  root: {
    minWidth: 200,
  },
  "@media (min-width: 768px) and (max-width:1366px)": {
    root: {
      marginBottom: "20px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  media: {
    width: 50,
    height: 50,
  },
  title: {
    fontFamily: "beIN Normal",
    fontSize: 20,
    color: "#000002",
    fontWeight: 200,
  },
  dialogTitle: {
    padding: "0px ",
  },
  headerDesgin: {
    textAlign: "center",
    color: "#F9B917",
    fontSize: "25px",
  },
});

const OutlinedCard = ({ AllCompany }) => {
  const { Companies } = useSelector((state) => state.Company);
  const { loadingdelete, loadingUpdate } = useSelector((state) => state.CompanyData);
  const { user } = useSelector((state) => state.auth);
  const UpdateCompanyPermission =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "update.company"
    );

  const SoftdeleteCompanyPermission =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "soft.delete.company"
    );
  const UpdateCompanyPermissiondefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "update.company"
    );

 
  const SoftdeleteCompanyPermissiondefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "soft.delete.company"
    );

  const classes = useStyles();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopupEdit1, setOpenPopupEdit1] = useState(false);
  const [recordForEdit, setRecordForEdit1] = useState(null);
  const [clickedEmployeeId, setClickedEmployeeId] = useState(null);
  const { loading, CompaniesData } = useSelector((state) => state.CompanyData);
  const [openPopup1, setOpenPopup1] = useState(false);
  const getcompanyData = (id) => {

    dispatch(clearCompanyData1());
    dispatch(fetchCompanyById(id));
    navigate(`/CompanyData`, { state: CompaniesData.id });
  };
  const handleDelete = () => {
    setConfirmDialog({
      isOpen: true,
      title: "أنت على وشك حذف هذه الشركة",
      img: "img/emp/Inbox cleanup-rafiki.svg",
      onConfirm: () => {
        dispatch(deleteCompany(clickedEmployeeId));
      },
    });
  };
  const handleClose = () => {
    // const menuElements = document.getElementsByClassName("menuopen");
    // for (let i = 0; i < menuElements.length; i++) {
    //   menuElements[i].style.display = "none";
    // }
    setOpenPopup1(false);
    setAnchorEl(null);
  };
  const handleEdit1 = (id) => {
    // const menuElements = document.getElementsByClassName("menuopen");
    // for (let i = 0; i < menuElements.length; i++) {
    //   menuElements[i].style.display = "none";
    // }
    setOpenPopup1(true);
    const record = Companies.find((comp) => comp.id === id);
    if (record) {
      setRecordForEdit1(record);
      setOpenPopupEdit1(true);
      dispatch(fetchCompanyById(id));
    }
  };
  console.log(UpdateCompanyPermission,'UpdateCompanyPermission');
  console.log(UpdateCompanyPermissiondefault,'UpdateCompanyPermissiondefault');
  return (
    <>
      <PopupCompany
        title="تعديل بيانات الشركة"
        openPopup={openPopup1}
        setOpenPopup={setOpenPopup1}
        handleClose={handleClose}
      >
        <CompanyEditForm id_employee={clickedEmployeeId} />
      </PopupCompany>

      {AllCompany &&
        AllCompany.map((e) => (
          <Card className={classes.root} key={e.id} variant="outlined">
            <div style={{display:'flex',justifyContent:'flex-end'}}>
              {UpdateCompanyPermission == true ||
              UpdateCompanyPermissiondefault == true ||
              SoftdeleteCompanyPermission == true ||
              SoftdeleteCompanyPermissiondefault == true ? (
                <Button
                  aria-controls="simple-menu"
                  // aria-haspopup="true"
                  onClick={(event) => {
                    setRecordForEdit1(null);
                    setClickedEmployeeId(e.id);
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <img src="img/More icon.svg" />
                </Button>
           
              ):(<></>)}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="testtt menuopen"
              >
                {UpdateCompanyPermission === true ||
                UpdateCompanyPermissiondefault === true && (
                  <MenuItem
                    className="menuStyle"
                    onClick={() => handleEdit1(clickedEmployeeId)}
                  >
                    <img src="img/Path 17091.svg" />
                    <span>تعديل</span>
                  </MenuItem>
             
                )}

                {SoftdeleteCompanyPermission === true ||
                SoftdeleteCompanyPermissiondefault === true && (
                  <MenuItem
                    className="menuStyle"
                    onClick={() => handleDelete(clickedEmployeeId)}
                  >
                    <img src="img/Path 17089.svg" />
                    <span>حذف</span>
                  </MenuItem>
              
                )}
              </Menu>

              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                loadingdelete={loadingdelete}
              />
            </div>
            <CardContent
              className={classes.content}
              onClick={() => {
                getcompanyData(e.id);
              }}
              style={{ cursor: "pointer" }}
            >
              <CardMedia title="Contemplative Reptile">
                {e.logo == "" ? (
                  <img
                    src="img/Avatar.svg"
                    className={classes.media}
                    alt="logo-company"
                  />
                ) : (
                  <img
                    src={e.logo}
                    className={classes.media}
                    alt="logo-company"
                  />
                )}
              </CardMedia>

              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {e.name_ar}
              </Typography>
            </CardContent>
          </Card>
        ))}
    </>
  );
};
export default OutlinedCard;
