import { tokens } from "../../styles/theme";
import React, { useEffect, useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { Grid } from "@material-ui/core";
import ConfirmDialog from "../../component/ConfirmDialogCompany";
import { DataGridPro } from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import {
  GridFooterContainer,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridPagination,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { HarddeleteCompany, RestorinsertCompany, fetchAllTrashedCompany } from "../../redux/companyTransportDataSlice";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ConfirmDialog3 from "../../component/ConfirmDialog3";
import DeleteIcon from '@mui/icons-material/Delete';

export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function CustomFooter() {
  return (
    <GridFooterContainer>
      <CustomPagination />
      <GridPagination />
    </GridFooterContainer>
  );
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    flexDirection: "row-reverse",
    paddingRight: "8px",
    height: "45px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
    marginRight: "3px",
  },
  "@media (min-width: 600px) and (max-width:1280px)": {
    searchDesign: {
      marginBottom: "15px",
    },
  },
  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  editButton: {
    display: "none",
    flexDirection: "row-reverse",
    color: "#000002 !important",
    fontSize: "20px",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  deleteButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#F9B917 !important",
    fontSize: "20px ",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  editImg: {
    width: "25px",
  },
  Marginbox1: {
    maxWidth: "100% !important",
    flexBasis: "100%",
  },
});

export default function RestorCompanyTable() {
  const { TrashComany,loadingRestor ,loadingdelete} = useSelector((state) => state.CompanyData);
  const dispatch = useDispatch();
  const[rowCount1,setRowCount1] = useState();

  const classes = useStyles();
  useEffect(() => {
      dispatch(fetchAllTrashedCompany());
      setRowCount1(TrashComany.length);
  }, [dispatch]);

  function EditToolbar(props) {
    return (
      <div className="trip">
        <Grid container xs={12} className={classes.Marginbox1}>
        </Grid>
      </div>
    );
  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [confirmDialog1, setConfirmDialog1] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [rowModesModel, setRowModesModel] = React.useState({});
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  function handleRestor(id) {
    setConfirmDialog({
      isOpen: true,
      title: "أنت على وشك استرجاع الشركة",
      img: "img/emp/Inbox cleanup-rafiki.svg",
      onConfirm: () => {
        dispatch(RestorinsertCompany(id));
      },
    });
  }
  function handleDelete(id) {
    setConfirmDialog1({
      isOpen: true,
      title: "أنت على وشك حذف الشركة بشكل نهائي",
      img: "img/emp/Inbox cleanup-rafiki.svg",
      onConfirm: () => {
        dispatch(HarddeleteCompany(id));
      },
    });
  }
  const getPageSize = () => {
    if (window.innerWidth < 992) {
      return 5; 
    } else if (window.innerWidth < 1566) {
      return 7; 
    }
     else if (window.innerWidth < 1760) {
      return 8; 
    }
     else if (window.innerWidth < 1930) {
      return 13; 
    }
  };
  useEffect(()=>{
    getPageSize()
  })
  const columns = [
    {
      field: "name_ar",
      sortable: false,
      headerName: "اسم الشركة",
      resizable: true,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },


    {
      field: "action",
      headerName: "العمليات",
      resizable: true,
      minWidth: 150,
      flex: 1,
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const id = params.row.id;
        return (
          <Box>
            <Button
              className={classes.editButton}
              onClick={() => {
                handleRestor(id);
              }}
            >
           <p style={{ cursor:'pointer'}}>استرجاع</p>
            <SettingsBackupRestoreIcon className="restor-company" />
            </Button>
            <Button
              className={classes.editButton}
              onClick={() => {
                handleDelete(id);
              }}
            >
           <p style={{ cursor:'pointer'}}>حذف</p>
            <DeleteIcon className="delete-company"/>
            </Button>

           
            <ConfirmDialog3
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
              loadingdelete={loadingRestor}
            />
            <ConfirmDialog
            loadingdelete={loadingdelete}
              confirmDialog={confirmDialog1}
              setConfirmDialog={setConfirmDialog1}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },

          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "none",
            fontSize: "15px !important",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "15px !important",
            color: `${colors.primary[1000]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.white[100],
          },
        }}
      >
        <DataGridPro
          rows={TrashComany || []}
          columns={columns}
          sx={{ overflowX: "scroll" }}
          className="field-table1"
          editMode="row"
          pageSize={15}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          pagination
          rowCount={rowCount1}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          components={{
            Toolbar: EditToolbar,
            Footer: CustomFooter,
          }}
          localeText={{
            footerRowSelected: CustomPagination,
          }}
        />
      </Box>
    </Box>
  );
}
