import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";
export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const insertAssignAdminCompany= createAsyncThunk(
  "AssignAdminCompany/insertAssignAdminCompany",
  async (values, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/companyAdmin`, values, {
        withCredentials: true,
      });
      sweetalert({
        text: "تم التعديل بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchAssignAdminCompany = createAsyncThunk(
  "AssignAdminCompany/fetchAssignAdminCompany",
  async (id, thunkAPI) => {
    const {  rejectWithValue } = thunkAPI;
   
    try {
      const res = await axios.get(`${BaseUrl}/companyAdmin/${id}`, {
        withCredentials: true,
      });
      const  result  = await res.data.data;

      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const BranchEmployeeSlice = createSlice({
  name: "AssignAdminCompany",
  initialState: {
    AdminCompany: [],
    AdminCompanyID: [],
    loading: false,
    loadingAdd: false,
    error: null,
  },
  reducers: {
    clearAssignAdminCompany: (state) => {
      state.AdminCompany = [];
      state.AdminCompanyID = [];
      state.loading = false;
      state.loadingAdd = false;
      state.error = null;

    },
  },
  extraReducers: {
    //insert Assign Admin Company
    [insertAssignAdminCompany.pending]: (state, action) => {
      state.loadingAdd = true;
      state.error = null;
    },
    [insertAssignAdminCompany.fulfilled]: (state, action) => {
      state.AdminCompany.push(action.payload);
      state.loadingAdd = false;
    },
    [insertAssignAdminCompany.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingAdd = false;
    },
    //Fetch Assign Admin Company
    [fetchAssignAdminCompany.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchAssignAdminCompany.fulfilled]: (state, action) => {
      state.AdminCompanyID = action.payload;
      state.loading = false;
    },
    [fetchAssignAdminCompany.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export const { clearAssignAdminCompany } = BranchEmployeeSlice.actions;
export default BranchEmployeeSlice.reducer;
