import { tokens } from "../../../styles/theme";
import React, { useEffect, useState , useRef} from "react";
import { Box, useTheme, Button } from "@mui/material";
// import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";
import {  Select, MenuItem } from "@material-ui/core";
import {

  DataGridPro,
} from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import {
  GridFooterContainer,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridPagination,
} from "@mui/x-data-grid";
import Tooltip from '@mui/material/Tooltip';

import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../../../component/controls/Controls";
import ConfirmDialog from "../../../component/ConfirmDialogCompany.js";
import { deleteTripType, fetchTripTypeById } from "../../../redux/tripTypesSlice";
import { useDispatch } from "react-redux";
import { fetchPaths } from "../../../redux/PathSlice";
import { useSelector } from "react-redux";
import { fetchAllCity } from "../../../redux/CitySlice";
import { fetchCompanyById } from "../../../redux/companyTransportDataSlice.jsx";
import { fetchTripsManulaDone } from "../../../redux/tripsManulDoneSlice.jsx";
import { Field, Label, Input } from "@zendeskgarden/react-forms";
import { Row, Col } from "@zendeskgarden/react-grid";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { Datepicker } from "@zendeskgarden/react-datepickers";
import dayjs from "dayjs";
import axios from "axios";
import BlockIcon from '@mui/icons-material/Block';

import * as sweetalert from "sweetalert";
import ReplayIcon from '@mui/icons-material/Replay';

import packageJson from "../../../../package.json";
export const BaseUrl = packageJson.projectURlApi.BaseUrl;

export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function CustomFooter() {
  return (
    <GridFooterContainer>
      <CustomPagination />
      <GridPagination />
    </GridFooterContainer>
  );
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    flexDirection: "row-reverse",
    paddingRight: "8px",
    height: "45px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
    marginRight: "3px",
  },
  "@media (min-width: 600px) and (max-width:1280px)": {
    searchDesign: {
      marginBottom: "15px",
    },
  },
  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  editButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#000002 !important",
    fontSize: "12px !important",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  deleteButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#000002 !important",
    fontSize: "12px !important",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  editImg: {
    width: "17px",
  },
  Marginbox1: {
    maxWidth: "100% !important",
    flexBasis: "100%",
  },
});

export default function TripEnd({ trip_type, id_company,setShortcut }) {
  const [openPopup1, setOpenPopup1] = useState(false);
  const [showFinishedTrips, setShowFinishedTrips] = useState(false);

  const dispatch = useDispatch();
  const [recordForEdit1, setRecordForEdit1] = useState(null);
  const { loading } = useSelector((state) => state.CompanyData);
  const { TripManulas } = useSelector((state) => state.TripManulas);
  const { Cities } = useSelector((state) => state.City);
  const [filteredData, setFilteredData] = useState(trip_type);
  const classes = useStyles();
  const [age, setAge] = useState("all");
  const [searched, setSearched] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [clickedEmployeeId, setClickedEmployeeId] = useState(null);
  const [selectedfromcityId, setSelectedFromCityId] = useState(null);
  const [selectedtocityId, setSelectedToCityId] = useState(null);
  const [date, setDate] = React.useState();
  const [notshowFinishedTrips, setnotShowFinishedTrips] = useState(false);

  const { loadingdelete } = useSelector((state) => state.TripType);
  const[rowCount1,setRowCount1] = useState();


  useEffect(() => {
    let typedWord = "";
    let typedWord2 = "";
  
    const handleKeyDown = (event) => {
      if (/^[a-zA-Z]$/.test(event.key)) {
        typedWord += event.key.toLowerCase();
        typedWord2 += event.key;
  
        const lastThreeChars = typedWord.substring(typedWord.length - 3);
  
        if (lastThreeChars === 'add') {
          localStorage.setItem("displayshortcut", "1");
          setOpenPopup(true);
          setRecordForEdit(null);
          setShortcut(1);
          typedWord = '';
        } 
      }
    };
  
    document.addEventListener("keydown", handleKeyDown);
  
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  },[]);

 

 
  const firstColumnElementRef = useRef(null);
  useEffect(() => {
    if (firstColumnElementRef.current) {
      firstColumnElementRef.current.focus();
    }
  }, []);
  const { user } = useSelector((state) => state.auth);

  const manualDoneStatusdefult =
  user != null &&
  user.rolePermissionsNames.length > 0 &&
  user.rolePermissionsNames.some(
    (permission) => permission === "manual.done.status"
  );
  const manualDoneStatus =
  user != null &&
  user.PermissionsNames.length > 0 &&
  user.PermissionsNames.some(
    (permission) => permission === "manual.done.status"
  );


 
  
  const { Companies } = useSelector((state) => state.Company);
  useEffect(() => {}, [dispatch, id_company, Companies.length]);
 
  const formattedRows = TripManulas.map((row) => {
    const date = new Date(row.date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
   
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);

    let formattedDate;
    let days;
    // Compare the date parts separately
    if (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    ) {
      const dayOptions = { day: "2-digit" };
      const monthOptions = { month: "2-digit" };
      const yearOptions = { year: "numeric" };
      const day = date.toLocaleDateString("en-EG", dayOptions);
      const month = date.toLocaleDateString("en-EG", monthOptions);
      const year = date.toLocaleDateString("en-EG", yearOptions);
      days = "اليوم";
      formattedDate = `${year}/${month}/${day}`;
    } else if (
      date.getFullYear() === tomorrow.getFullYear() &&
      date.getMonth() === tomorrow.getMonth() &&
      date.getDate() === tomorrow.getDate()
    ) {
      const dayOptions = { day: "2-digit" };
      const monthOptions = { month: "2-digit" };
      const yearOptions = { year: "numeric" };
      const day = date.toLocaleDateString("en-EG", dayOptions);
      const month = date.toLocaleDateString("en-EG", monthOptions);
      const year = date.toLocaleDateString("en-EG", yearOptions);
      days = "غداً";
      formattedDate = `${year}/${month}/${day}`;
    } else if (
      date.getFullYear() === dayAfterTomorrow.getFullYear() &&
      date.getMonth() === dayAfterTomorrow.getMonth() &&
      date.getDate() === dayAfterTomorrow.getDate()

    ) {
      const dayOptions = { day: "2-digit" };
      const monthOptions = { month: "2-digit" };
      const yearOptions = { year: "numeric" };
      const weekdayOptions = { weekday: "long" };
      const day = date.toLocaleDateString("en-EG", dayOptions);
      const month = date.toLocaleDateString("en-EG", monthOptions);
      const year = date.toLocaleDateString("en-EG", yearOptions);

      days = "بعد غد";
      formattedDate = `${year}/${month}/${day}`;
    } else {
      const dayOptions = { day: "2-digit" };
      const monthOptions = { month: "2-digit" };
      const yearOptions = { year: "numeric" };
      const weekdayOptions = { weekday: "long" };
      const day = date.toLocaleDateString("en-EG", dayOptions);
      const month = date.toLocaleDateString("en-EG", monthOptions);
      const year = date.toLocaleDateString("en-EG", yearOptions);
      const weekday = date.toLocaleDateString("ar-EG", weekdayOptions);

      days = `${weekday}`;
      formattedDate = `${year}/${month}/${day}`;
    }

    return {
      ...row,
      Days: days,
      date: formattedDate,
    };
  });

  
  function filterTrips(Trips) {
    let filteredTrips;

    if (selectedfromcityId && selectedtocityId) {
      filteredTrips = Trips.filter(
        (trip) =>
          trip.path_from === selectedfromcityId &&
          trip.path_to === selectedtocityId
      );
    } else if (selectedfromcityId && selectedtocityId && date) {
      filteredTrips = Trips.filter(
        (trip) =>
          trip.path_from === selectedfromcityId &&
          trip.path_to === selectedtocityId &&
          trip.date === dayjs(date).format("YYYY-MM-DD")
      );
    } else if (selectedfromcityId && date) {
      filteredTrips = Trips.filter(
        (trip) =>
          trip.path_from === selectedfromcityId &&
          trip.date === dayjs(date).format("YYYY-MM-DD")
      );
    } else if (selectedtocityId && date) {
      filteredTrips = Trips.filter(
        (trip) =>
          trip.path_to === selectedtocityId &&
          trip.date === dayjs(date).format("YYYY-MM-DD")
      );
    } else if (selectedfromcityId) {
      filteredTrips = Trips.filter(
        (trip) => trip.path_from === selectedfromcityId
      );
    } else if (selectedtocityId) {
      filteredTrips = Trips.filter((trip) => trip.path_to === selectedtocityId);
    } else if (date) {
      filteredTrips = Trips.filter(
        (trip) => trip.date === dayjs(date).format("YYYY-MM-DD")
      );
    } else if (showFinishedTrips && notshowFinishedTrips) {
      // Show all trips when both checkboxes are unchecked
      filteredTrips = Trips;
    } else if (showFinishedTrips) {
      // Show only finished trips
      filteredTrips = Trips.filter((trip) => {
        const correctedDateTime = trip.dateTime.replace(
          /(\d{4}-\d{2}-\d{2})(\d{2}:\d{2}:\d{2})/,
          "$1 $2"
        );
        const tripDateTime = new Date(correctedDateTime);
        const now = new Date();
        const twoHoursAgo = new Date(now.getTime() + 2 * 60 * 60 * 1000); // 2 hours ago
        return tripDateTime < twoHoursAgo;
      });
    } else if (notshowFinishedTrips) {
      // Show only continuing trips
      filteredTrips = Trips.filter((trip) => {
        const correctedDateTime = trip.dateTime.replace(
          /(\d{4}-\d{2}-\d{2})(\d{2}:\d{2}:\d{2})/,
          "$1 $2"
        );
        const tripDateTime = new Date(correctedDateTime);
        const now = new Date();
        const twoHoursAgo = new Date(now.getTime() + 2 * 60 * 60 * 1000); // 2 hours ago
        return tripDateTime > twoHoursAgo;
      });
    } else {
      filteredTrips = [...Trips]; // Create a shallow copy before sorting
      filteredTrips.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });
    }

    return filteredTrips;
  }

  const filteredTripes = filterTrips(formattedRows);
  const {  CompaniesData } = useSelector((state) => state.CompanyData);

  useEffect(() => {
    dispatch(fetchAllCity());
    dispatch(fetchTripsManulaDone(CompaniesData.id));
    setRowCount1(trip_type?.length)
  }, [dispatch]);
  const handleDateChange = (newValue) => {
    setDate(newValue);
  };
  const onHandleReset = () => {
    setSelectedFromCityId(null);
    setSelectedToCityId(null);
    setDate(null);
  };
  function EditToolbar(props) {
    return (
      <Grid container xs={12} lg={12} md={12} className={classes.Marginbox1}>
        <Grid lg={2} md={2} sm={2} className={classes.AddDesign}>
            <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className={` ${selectedfromcityId ? "selects11" : "selects1"}`}
            text="بحث"
            value={selectedfromcityId}
              onChange={(e) => setSelectedFromCityId(e.target.value)}
              style={{ marginBottom: "10px !important" }}
            >
              {Cities.map((city) => (
                <MenuItem value={city.name_ar} key={city.id}>
                  <span>{city.name_ar}</span>
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid lg={2} md={2} sm={2} className={classes.AddDesign}>
            <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className={` ${selectedtocityId ? "selects22" : "selects2"}`}
            value={selectedtocityId}
              onChange={(e) => setSelectedToCityId(e.target.value)}
            >
              {Cities.map((city) => (
                <MenuItem value={city.name_ar} key={city.id}>
                  <span>{city.name_ar}</span>
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid
          lg={2} md={2} sm={2}
            className="filterdateBlocked"
            style={{
              border: "1px solid #eee",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "1px 3px 6px rgba(0,0,0,0.3)",
              borderRadius: "10px",
              marginBottom: "10px",
            }}
          >
            <ThemeProvider>
              <Row justifyContent="center">
                <Col sm={5}>
                  <Field
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <Label
                      style={{
                        color: "rgb(207, 204, 205)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "10px",
                      }}
                    >
                      {" "}
                      تاريخ الرحلة
                    </Label>
                    <Datepicker
                      clearable
                      okLabel="موافق"
                      cancelLabel="الغاء"
                      clearLabel="مسح"
                      value={date}
                      onChange={handleDateChange}
                      locale="ar-SY"
                    >
                      <Input
                        className="iput-filter-date"
                        lang="ar-SY"
                        style={{
                          border: "none",
                          width: "60%",
                          cursor: "pointer",
                          marginTop: "0px ",
                        }}
                      />
                    </Datepicker>
                  </Field>
                </Col>
              </Row>
            </ThemeProvider>
          </Grid>

          <Grid lg={1} md={1} sm={2} className={classes.AddDesign} style={{marginRight:'10px'}}>
          <Button className="reloadButton1" onClick={onHandleReset}>
            <img src="img/reload.svg" alt="reload.svg" />
          </Button>
        </Grid>
      </Grid>
    );
  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };


  async function handleBlocked(id_branch,rowId) {
    
    const data ={
      branch: id_branch,
      id_trip:rowId,
      status:'pending',
    }
    sweetalert({
      title: "استمرار الرحلة",
      text: 'هل أنت متاكد من إعادة الرحلة لستمرة ',
      icon: "info",
      buttons: {
        confirm: {
            text: "موافق",
            value: true,
            className: "custom-button-class",
        },
        cancel: {
            text: "إلغاء",
            value: false,
            visible: true,
            className: "cancel-button-class",
        }
    },
      dangerMode:true
    }).then((value) => {
      if (value) {
        axios
      .post(`${BaseUrl}/manualDoneStatus`,data ,{
        withCredentials: true,
      })
     .then((response) => {
       if(response.data.code == 200) {
         sweetalert({
           text: "تم إعادة الرحلة لمستمرة",
           icon: "success",
           buttons: {
             confirm: {
               text: "تم",
               className: "custom-button-class",
             },
           },
           className: "alert-div",
         }).then(() => {
           window.location.reload();
         });
       }
 
     }).catch((error) => {
      
     });
    }
     });
   }

  const processRowUpdate = (newRow) => {
   
  };

  const columns = [
    {
      headerName: "الانطلاق",
      field: "path_from",
      sortable: false,
      resizable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div
          className=""
          data-field={params.field}
          data-field2={params.row.id}
          tabindex={0}
        >
          {params.value}
        </div>
      ),
    },
    {
      headerName: "الوجهة",
      field: "path_to",
      sortable: false,
      resizable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div
          className="autofocusenter"
          data-field={params.field}
          data-field2={params.row.id}
          tabindex={0}
        >
          {params.value}
        </div>
      ),
    },
    {
      headerName: "الفرع",
      field: "branch",
      sortable: false,
      resizable: true,
      headerAlign: "center",
      align: "center",
     
    },

    {
      field: "Days",
      headerName: "اليوم",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "التاريخ",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "time",
      headerName: "التوقيت",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "trip_type",
      headerName: "نوع الرحلة",
      headerAlign: "center",
      align: "center",
    },
 
    {
      field: "stations",
      sortable: false,
      resizable: true,
      headerName: "أماكن التوقف",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      sortable: false,
      resizable: true,
      headerName: "الرحلة ",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div
          className=""
          data-field={params.field}
          data-field2={params.row.id}
          tabindex={0}
        >
          {params.value == 'manual_done' ?'منتهية':''}
        </div>
      ),
    },
   
    {
      field: "tripTransit",
      sortable: false,
      resizable: true,
      headerName: "رحلة العبور",
      // maxWidth: 200,
      minWidth: "150",
      maxWidth: "150",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div data-field={params.field}>
          {params.value && params.value.length === 1 ? (
            <div>
              {params.value[0].name} -- {params.value[0].time}
            </div>
          ) : params.value && params.value.length >= 2 ? (
            <div>
              <div>
                {params.value[0].name} --
                {params.value[0].time}
              </div>

              <div>
                {params.value[1].name} --
                {params.value[1].time}
              </div>
            </div>
          ) : (
            <p> </p>
          )}
        </div>
      ),
    },

    {
      field: "action",
      headerAlign: "center",
      headerName: "العمليات",
      resizable: true,
    
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const id = params.row.id;
        const date = params.row.date;
        const time = params.row.time;
        const tripDateTime = new Date(`${date}T${time}`);

        return (
          <Box>
       
       {manualDoneStatus === true || manualDoneStatusdefult === true  ? (
        <Tooltip title="إعادة الرحلة لمستمرة">
                  <Button
                    className="blocked"
                    onClick={() => {
                      handleBlocked(params.row.id_branch,params.row.id);
                    }}
                    //  disabled={compareResult < 0 ? true : false}
                  >
                   <ReplayIcon />
                    <span>إعادة الرحلة لمستمرة</span>
                  </Button>
                  </Tooltip>
                  ) : null}
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <Box
        className="box-triptype table-accounting"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },

          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "none",
            fontSize: "15px !important",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "15px !important",
            color: `${colors.primary[1000]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.white[100],
          },
        }}
      >
        {loading ? (
          <div className="spinner1-box">
            <svg class="spinner1" viewBox="0 0 50 50">
              <circle
                class="path1"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          </div>
        ) : (
          <DataGridPro
            rows={filteredTripes || []}
            columns={columns}
            className="field-table"
            editMode="row"
            pageSize={15}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            rowCount={rowCount1}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            components={{
              Toolbar: EditToolbar,
              Footer: CustomFooter,

            }} 
          />
        )}
      </Box>
    </Box>
  );
}
