import React, { useEffect, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "../../component/useForm";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchAllCity } from "../../redux/CitySlice";
import { fetchPathsById, updatePath } from "../../redux/PathSlice";
import { fetchTripType } from "../../redux/tripTypesSlice";
import { fetchCompanyById } from "../../redux/companyTransportDataSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import { updateaudit } from "../../redux/AddAndRemoveaudit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export default function AddOrRemoveOduit(props) {
  const dispatch = useDispatch();
  // audit_id_system_user
  const { loading,loadingupdate } = useSelector((state) => state.Aduit);


  const initialFValues = {
    audit_id_system_user: props.audit_id_system_user,
    value: 0,
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("value" in fieldValues)
      temp.id_city_from = fieldValues.value ? "" : "هذا الحقل مطلوب";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const [selectedValue, setSelectedValue] = React.useState("a");

   const  handleChange =  (event) => {
    setSelectedValue(event.target.value);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);
  const handleSubmit =async (e) => {
    e.preventDefault();

const values1={
      value:Number(values.value),
      audit_id_system_user: props.audit_id_system_user,

}
    if (validate()) {
      try {
      const res=   await axios.post(`${BaseUrl}/audit`,values1, { withCredentials: true });
        if(res.data.code === 200) {
        sweetalert({
          text: "تم التشطيب بنجاح",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
          props.setShohowtwotable2(true)
        });
      } 
      } 
      catch (error) {
        // Check if the error status code is 403 Forbidden or another status code
        if (error.response && error.response.status === 403) {
          // Handle the 403 Forbidden error specifically
          sweetalert({
            text: "ليس لديك صلاحية للقيام بهذا",
            icon: "error",
            buttons: {
              confirm: {
                text: "إغلاق",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          });
        } else {
          // Handle other errors with a generic message
          const errorMessage = error.response?.data?.data || error.response?.data?.message ;
          sweetalert({
            text: errorMessage,
            icon: "error",
            buttons: {
              confirm: {
                text: "إغلاق",
                className: "custom-button-class",
              },
            },
            className: "alert-div",
          });
        }
      }
    }
  };


  return (
    <>
    {loading ?(
         <div className="spinner1-box">
         <svg class="spinner1" viewBox="0 0 50 50">
           <circle
             class="path1"
             cx="25"
             cy="25"
             r="20"
             fill="none"
             stroke-width="5"
           ></circle>
         </svg>
       </div>
    ):(

   
  
    <Form onSubmit={handleSubmit}>
      <Grid className="createdTrip">
        <>
          <Grid item xs={12} md={12} sm={12}>
         

            <Controls.Input
             InputLabelProps={{ shrink: true }} 
              name="value"
              onChange={handleInputChange}
              label={
                <Fragment>
                  <InputAdornment position="end">
                    <span className="placeholdertitle">المبلغ</span>
                  </InputAdornment>
                </Fragment>
              }
              value={values.type}
              
              error={errors.type}
            />

          </Grid>
        </>
      </Grid>
   
      <Grid xs={12} md={12}>
      {loadingupdate  ? (
              <div
              className="bottonFullwidth"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="load loadingupdate"
                style={{ background: "#F9B917", borderRaduis: "10px" }}
              >
                <div v-if="loading" class="spinner">
                  <div class="rect1"></div>
                  <div class="rect2"></div>
                  <div class="rect3"></div>
                  <div class="rect4"></div>
                  <div class="rect5"></div>
                </div>
              </div>
            </div>
        ) : (
        <div className="bottonFullwidth"  style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Controls.Button type="submit" text="تشطيب" />
        </div>
        )}
      </Grid>
    </Form>
     )}
    </>
  );
}
