/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, Fragment, useMemo } from "react";
import { Grid, TextField } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useForm, Form } from "./useFormupdate";
import IconButton from "@mui/material/IconButton";
import * as sweetalert from "sweetalert";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useDispatch } from "react-redux";
import { updateTrip } from "../../redux/tripsSlice";
import { useSelector } from "react-redux";
import { fetchBusByIdFilter, fetchBusByIdFilter1, fetchTripTypeById } from "../../redux/tripTypesSlice";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { LocalizationProvider, DatePicker } from '@mui/lab';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {  TimePicker } from "antd";
import InputRowupdate from "./inputRowupdate";
import { fetchSeateTypeAll } from "../../redux/SeatsTypeSlice";
import axios from "axios";
import packageJson from "../../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
function TripFormEdit(props) {
  const renderInput = (params) => <TextField {...params} />;
  const [selectedValue, setSelectedValue] = React.useState("");
  const [idBus, setIdBus] = React.useState("");
  const [TypeTrip, setTypeTrip] = React.useState("");

  const dispatch = useDispatch();
  const seat =
    props.TripID?.tripTransit?.length > 0 &&
    props.TripID?.tripTransit?.[0].reservationSeats;
  const seatNumbers = Object.keys(seat).map(Number);
  const { loadingget } = useSelector((state) => state.Trip);

  const initialFValues = {
    id: props.TripID.id,
    driver_name: props.TripID.driver_name,
    driver_assistant_name: props.TripID.driver_assistant_name,
    date: props.TripID.date,
    time: props.TripID.time,
    id_trip_type: props.TripID.id_trip_type,
    id_seat_type: props.TripID.id_seat_type,
    type:
      props.TripID?.tripTransit && props.TripID?.tripTransit.length > 0
        ? "transit"
        : "direct",
    id_bus: props.TripID.id_bus,
    id_branch: props.TripID.id_branch,
    transit_count_seats: seatNumbers.length,
    transit_seats_input: seatNumbers,
  };

  const { CompaniesData } = useSelector((state) => state.CompanyData);
  const {  filteredBranch } = useSelector((state) => state.TripType);
  const {  TypeBus ,TypeBus2} = useSelector((state) => state.TripType);
  const { loadingupdate } = useSelector((state) => state.Trip);
  const { SeatType,filterTypeSeat } = useSelector((state) => state.SeatsType);
  const [value, setValue] = React.useState(null);
  const [TypeBus22, setTypeBus2] = React.useState([]);

  const [timeError, setTimeError] = useState(""); // Error message for time
  const [dateError, setDateError] = useState("");
  const [seatTransit, setSeatTransit] = useState([]);
  const [Driverassistantname, setDriverassistantname] = useState('');
  const [DriverName, setDriverName] = useState('');
  const [IDtripType, setIDtripType] = useState('');
  const [IdBranch, setIdBranch] = useState('');
  const [SetTypeFillter, setSeatTypeFillter] = useState('');
  const [time1, setTime1] = React.useState(null);
  const handleCreate = () => {
    setSelectedValue("a");
  };
  const handleExport = () => {
    // setExportd(true)
  };
  const [initialFValues1, setIinitialFValues1] = useState([
    {
      id: 1,
      order: 1,
      id_trip_type: "",

      date: "",
      time_h: "",
      time_m: "",
    },
  ]);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("driver_name" in fieldValues)
      temp.driver_name = fieldValues.driver_name ? "" : "هذا الحقل مطلوب";
    if ("driver_assistant_name" in fieldValues)
      temp.driver_assistant_name = fieldValues.driver_assistant_name
        ? ""
        : "هذا الحقل مطلوب";
    if ("date" in fieldValues)
      temp.date = fieldValues.date ? "" : "هذا الحقل مطلوب";

    if ("id_bus" in fieldValues)
      temp.id_bus = fieldValues.id_bus ? "" : "هذا الحقل مطلوب";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const { values, setValues, errors, setErrors, handleInputChange,handleInputChange11 } =
  useForm(initialFValues, true, validate);
  const initialFValues1Data = useMemo(() => {
    const isTransit = props.TripID?.tripTransit && props.TripID?.tripTransit.length > 0;
    return isTransit
      ?props.TripID?.tripTransit.map((transit, index) => ({
          id: transit.id,
          order: index + 1,
          id_trip_type: transit.id_trip_type,
          date: transit.date,
          time_h: transit.time.split(":")[0],
          time_m: transit.time.split(":")[1],
        }))
      : [];

    
  }, [props.TripID?.tripTransit]);

  useEffect(() => {

    if (props.TripID.id === props.id_Trip) {
      const isTransit =
        props.TripID?.tripTransit && props.TripID?.tripTransit.length > 0;

        setValues(prevValues => ({
          ...prevValues,
          id: props.TripID.id,
          driver_name: props.TripID.driver_name,
          driver_assistant_name: props.TripID.driver_assistant_name,
          date: props.TripID.date,
          time: props.TripID.time,
          id_trip_type: props.TripID.id_trip_type,
          type: isTransit ? "transit" : "direct",
          id_seat_type: props.TripID.id_seat_type,
          
          id_bus: props.TripID?.id_bus || '',
          id_branch: props.TripID.id_branch,
          reservations: props.TripID.reservations,
          transit_count_seats: seatNumbers.length,
          transit_seats_input: seatNumbers,
        }));
    
      
      setValue(props.TripID.time);
      

    }
  }, [props.TripID, props.id_Trip, seatNumbers, dispatch, setValues]);
  useEffect(()=>{
    dispatch(fetchBusByIdFilter1(props.idcompany));
    dispatch(fetchSeateTypeAll(props.idcompany));
    setIdBus(props.TripID?.id_bus);
    setIinitialFValues1(initialFValues1Data);
    setIDtripType(props.TripID.id_trip_type);
    setIdBranch(props.TripID.id_branch);
    setDate1(props.TripID.date);
    setDriverassistantname(props.TripID.driver_assistant_name);
    setDriverName(props.TripID.driver_name);
    const isTransit1 =
    props.TripID?.tripTransit && props.TripID?.tripTransit.length > 0;
    const type = isTransit1 ? "transit" : "direct";
    setTypeTrip(type)
  
    setTime1(props.TripID.time);
    let Type;

    const dataseatfilter={
      id:CompaniesData.id,
      type:props.TripID.trip_type 
    }
    axios
    .get(`${BaseUrl}/seatTypesForCompany/${dataseatfilter.id}`, {
      withCredentials: true,
    })
    .then((res) => {

      const result =  res.data.data;
      const filtered = result.filter((b) => b.type === dataseatfilter.type); // Filter buses by type
      setSeatTypeFillter(filtered)
    })

    if (TypeBus2.length > 0 && props.TripID && props.TripID.trip_type) {
      const filteredTypeBus2 = TypeBus2.filter(bus => bus.type === props.TripID.trip_type);
      setTypeBus2(filteredTypeBus2); // Update state with filtered data
    }
      
    
  },[dispatch,props.TripID]);

  

  const handleRemove = (id) => {
    setIinitialFValues1((prevValues) =>
      prevValues.filter((row) => row.id !== id)
    );
  };
  const handleAdd = () => {
    setIinitialFValues1([
      ...initialFValues1,
      {
        id: initialFValues1.length + 1, // Generate a unique ID for the new row
        order: initialFValues1.length + 1, // Increment the order
        id_trip_type: "",
        date: "",
        time_h: "",
        time_m: "",
      },
    ]);
  };
  const handleAddSeat = (e) => {
    const newSeatsInput = e.target.value.trim();
    const newSeats = newSeatsInput.split(",").map((s) => Number(s.trim()));

    // Extracting seat numbers from reservationSeats object
    const reservationSeats =
      props.TripID?.tripTransit?.length > 0 &&
      props.TripID?.tripTransit?.[0]?.reservationSeats;
    const seatNumbers = Object.keys(reservationSeats).map(Number);

    // Merging newSeats with seatNumbers and removing duplicates
    const updatedSeats = [...newSeats, ...seatNumbers].filter(
      (seat, index, self) => self.indexOf(seat) === index
    );

    setValues({
      ...values,
      transit_seats: updatedSeats,
      transit_seats_input: updatedSeats,
    });
  };


  const [date1, setDate1] = React.useState(props.TripID.date);

  const handleTimeChange = (time) => {
    if (time !== null) {
      const formattedTime = dayjs(time).format("HH:mm");

      setTime1(formattedTime);
      setValues((prevValues) => ({
        ...prevValues,
        time: formattedTime,
      }));
    } else {
      const currentTime = dayjs().format("HH:mm:ss");
      setTime1(currentTime);
      setValues((prevValues) => ({
        ...prevValues,
        time: currentTime,
      }));
    }
  };
  const handleDateChange = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setDate1(formattedDate);

    setValues((prevValues) => ({
      ...prevValues,
      date: date1,
    }));
  };

  const { addOrEdit, recordForEdit } = props;

 
  const handleChange = (event) => {
    // dispatch(fetchPathsById());
    setSelectedValue(event.target.value);
  };
  useEffect(() => {}, [selectedValue,idBus,setIdBus]);
  const handleChange21 = (event) => {
    setIdBus(event.target.value);
    setValues({
      ...values,
      id_bus: event.target.value,
    });
  };
  const handleChangedrivername = (event) => {
    setDriverName(event.target.value);
    setValues({
      ...values,
      driver_name: event.target.value,
    });
  };
  const handleChangedriverassistantname= (event) => {
    setDriverassistantname(event.target.value);
    setValues({
      ...values,
      driver_assistant_name: event.target.value,
    });
  };
  const handleChangeTypeTrip = (event) => {
    setTypeTrip(event.target.value);
    setValues({
      ...values,
      type: event.target.value,
    });
  };
 
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const handleSubmit = (e) => {

    e.preventDefault();
    if (date1 != null && value != null) {
      const currentDate = new Date();
      const cuttentDate = dayjs(currentDate).format("YYYY-MM-DD");
      const selectedDate = values.date;
      const upper = selectedDate >= cuttentDate;
      const same = selectedDate === cuttentDate;
      const lower = selectedDate < cuttentDate;
      let calculatedIdBus = idBus === '' ? '' : idBus;
      
      if (selectedDate !== null && value !== null) {
        if (TypeTrip === "direct") {
          const data = {
            id: values.id,
            driver_name: DriverName,
            driver_assistant_name: Driverassistantname,
            date: date1,
            time_h: time1.split(":")[0], // Extract hours from time string
            time_m: time1.split(":")[1],
            id_trip_type: IDtripType,
            id_seat_type: values.id_seat_type,
            id_bus: idBus,
            id_branch: IdBranch,
            branch: IdBranch,
            type: TypeTrip,
          };
          const data2 = {
            time_h: time1.split(":")[0], // Extract hours from time string
            time_m: time1.split(":")[1],
            id_trip_type: IDtripType,
            id_branch:IdBranch,
            branch:IdBranch,
            id_bus: idBus,
            type:TypeTrip,
            transit: {
              trip: initialFValues1,
              transit_seats: values.transit_seats,
              transit_count_seats: values.transit_count_seats,
            },
          };
          dispatch(updateTrip(data));
          localStorage.setItem("trip_schedular_2", JSON.stringify(data2)); // Save data2
        } else {

          const data = {
            id: values.id,
            driver_name: DriverName,
            driver_assistant_name: Driverassistantname,
            date: date1,
            id_bus: idBus,
            time_h: time1.split(":")[0], // Extract hours from time string
            time_m: time1.split(":")[1],
            id_trip_type: IDtripType,
            id_seat_type: values.id_seat_type,
            id_branch: IdBranch,
            branch: IdBranch,
            type: TypeTrip,
            transit: {
              trip: initialFValues1,
              transit_seats: values.transit_seats,
              transit_count_seats: values.transit_count_seats,
            },
          };
          const modifiedData = initialFValues1.map((item) => {
            const { id, order, id_trip_type, time_h, time_m } = item;
            return { id, order, id_trip_type, time_h, time_m };
          });

          const data2 = {
            time_h: time1.split(":")[0], // Extract hours from time string
            time_m: time1.split(":")[1],
            id_trip_type: IDtripType,
            id_branch: IdBranch,
            branch: IdBranch,
            type: TypeTrip,
            transit: modifiedData,
            transit_seats: values.transit_seats,
            transit_count_seats: values.transit_count_seats,
          };
          localStorage.setItem("trip_schedular_2", JSON.stringify(data2)); // Save data2

          dispatch(updateTrip(data)).then(() => {
            // dispatch(clearTripTypeState());
          });
        }
      } else if (!selectedDate) {
        sweetalert({
          text: "يرجى اختيار التاريخ",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        sweetalert({
          text: " يرجى اختيار التاريخ أكبر او يساوي التاريخ الحالي",
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
    }
    if (date1 == null) {
      setDateError("يرجى اختيار تاريخ الرحلة");
    }
    if (value == null) {
      setTimeError("يرجى اختيار توقيت الرحلة");
    }
  };
  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit,setValues]);
  const handleChange1 = async (e) => {
    dispatch(fetchTripTypeById(e.target.value));
    setIDtripType(e.target.value);
    setValues((prevValues) => ({
      ...prevValues,
      id_trip_type: e.target.value,
    }));
  };
  const handleChangebranch = async (e) => {
    setIdBranch(e.target.value);
    setValues((prevValues) => ({
      ...prevValues,
      id_branch: e.target.value,
    }));
  };
  return (
    <>
      {loadingget ? (
        <div className="spinner1-box">
          <svg class="spinner1" viewBox="0 0 50 50">
            <circle
              class="path1"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Grid className="createdTrip">
            <Grid item xs={12} md={12} sm={12}>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row-reverse",
                }}
              >
                <Grid lg={12} md={12} sm={12}>
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <Grid lg={6} md={6} sm={6}>
                      <Controls.SelectTrip
                        className="tesr122"
                        name="id_trip_type"
                        label={
                          <Fragment>
                            <InputAdornment position="end">
                              <IconButton
                                className="iconplaceholder"
                                aria-label="toggle password visibility"
                                edge="end"
                              >
                                <img src="img/path.png" alt=''></img>
                              </IconButton>
                              <span className="placeholdertitle">
                                مسار الرحلة
                              </span>
                            </InputAdornment>
                          </Fragment>
                        }
                        value={IDtripType}
                        onChange={handleChange1}
                        error={errors.id_trip_type}
                        options={CompaniesData.trip_type}
                      />
                    </Grid>
                    <Grid lg={6} md={6} sm={6} className="trip-select-type">
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-form-control-label-placement"
                          name="type"
                          value={TypeTrip}
                          // defaultValue="top"
                          onChange={handleChangeTypeTrip}
                          style={{
                            display: "flex",
                            flexDirection: "column-reverse",
                          }}
                        >
                          <FormControlLabel
                            value="transit"
                            control={
                              <Radio
                                // {...controlProps("transit")}
                                sx={{
                                  color: "#f9b917",
                                  "&.Mui-checked": {
                                    color: "#f9b917",
                                  },
                                }}
                              />
                            }
                            label="عبور"
                            labelPlacement="start"
                            onClick={handleExport}
                          />
                          <FormControlLabel
                            value="direct"
                            control={
                              <Radio
                                // {...controlProps('a')}
                                sx={{
                                  color: "#f9b917",
                                  "&.Mui-checked": {
                                    color: "#f9b917",
                                  },
                                }}
                              />
                            }
                            label="مباشر"
                            labelPlacement="start"
                            onClick={handleCreate}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      gap: "10px",
                    }}
                  >
                    <Grid item xs={4} md={4} sm={4}>
                      <Controls.Input
                        className="tesr122"
                        name="driver_name"
                        label={
                          <Fragment>
                            <InputAdornment position="end">
                              <IconButton
                                className="iconplaceholder"
                                aria-label="toggle password visibility"
                                edge="end"
                              >
                                <img src="img/person.png" alt=''></img>
                              </IconButton>
                              <span className="placeholdertitle">
                                اسم السائق
                              </span>
                            </InputAdornment>
                          </Fragment>
                        }
                        value={DriverName}
                        onChange={handleChangedrivername}
                        error={errors.driver_name}
                      />
                    </Grid>
                    <Grid item xs={4} md={4} sm={4}>
                      <Controls.Input
                        className="tesr122"
                        name="driver_assistant_name"
                        label={
                          <Fragment>
                            <InputAdornment position="end">
                              <IconButton
                                className="iconplaceholder"
                                aria-label="toggle password visibility"
                                edge="end"
                              >
                                <img src="img/person.png" alt=''></img>
                              </IconButton>
                              <span className="placeholdertitle">
                                اسم المعاون
                              </span>
                            </InputAdornment>
                          </Fragment>
                        }
                        value={Driverassistantname}
                        onChange={handleChangedriverassistantname}
                        error={errors.driver_assistant_name}
                      />
                    </Grid>

                    <Grid item xs={5} md={5} sm={5}>
                      <Controls.SelectBranch
                        className="tesr122"
                        name="id_branch"
                        label={
                          <Fragment>
                            <InputAdornment position="end">
                              <IconButton
                                className="iconplaceholder"
                                aria-label="toggle password visibility"
                                edge="end"
                              >
                                <img src="img/office.png" alt=''></img>
                              </IconButton>
                              <span className="placeholdertitle">
                                فروع الشركة
                              </span>
                            </InputAdornment>
                          </Fragment>
                        }
                        value={IdBranch}
                        onChange={handleChangebranch}
                        error={errors.id_branch}
                        options={CompaniesData.branch || []}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row-reverse",
                    }}
                  >  <Grid item xs={4} md={4} sm={4}>
                  <Controls.SelectBusType
                    name="id_seat_type"
                    label={
                      <Fragment>
                        <InputAdornment position="end">
                          <IconButton
                            className="iconplaceholder"
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <img src="img/create1.svg"></img>
                          </IconButton>
                          <span className="placeholdertitle">نوع الباص</span>
                        </InputAdornment>
                      </Fragment>
                    }
                    value={values.id_seat_type}
                    onChange={handleInputChange}
                    options={SetTypeFillter || []}
                    error={errors.id_seats_type}
                    className="oloa"
                  />
                </Grid>
                
                    <Grid item xs={5} md={5} sm={5}>
                      <Controls.SelectBus
                        className="tesr122"
                        name="id_bus"
                        value={idBus}
                        label={
                          <Fragment>
                            <InputAdornment position="end">
                              <IconButton
                                className="iconplaceholder"
                                aria-label="toggle password visibility"
                                edge="end"
                              >
                                <img src="img/create1.svg" alt=''></img>
                              </IconButton>
                              <span className="placeholdertitle">
                                 الباص
                              </span>
                            </InputAdornment>
                          </Fragment>
                        }
                        // disabled={
                        //   !values.reservations ||
                        //   values.reservations.length != 0
                        // }
                        
                        onChange={handleChange21}
                        error={errors.id_bus}
                        options={(Array.isArray(TypeBus22) && TypeBus22) || []}

                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid item xs={5} md={5} sm={5}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="اختر تاريخ الرحلة"
                            value={date1}
                            onChange={handleDateChange}
                            renderInput={renderInput}
                            views={["year", "month", "day"]}
                          />
                          {dateError && (
                            <p style={{ color: "red", fontSize: "13px" }}>
                              {dateError}
                            </p>
                          )}
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={5} md={5} sm={5} className="timetrip">
                        <FormControl fullWidth className="timediv">
                          <InputLabel htmlFor="time-picker">
                            اختر توقيت الرحلة
                          </InputLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              defaultValue={dayjs(time1, "HH:mm")}
                              // defaultValue={'ادخل القيمة'}
                              size="large"
                              title="اختر التاريخ"
                              format="HH:mm"
                              onChange={(newValue) => {
                                setValue(newValue);
                                const currentTimehours =
                                  dayjs(newValue).format("HH");
                                const currentTimeminute =
                                  dayjs(newValue).format("mm");
                                  const formattedTime = dayjs(newValue).format("HH:mm");

                                  setTime1(formattedTime);

                                setValues((prevValues) => ({
                                  ...prevValues,
                                  time_h: currentTimehours,
                                  time_m: currentTimeminute,
                                }));
                                // setTimeError("")
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid>
                    {TypeTrip == "transit" || selectedValue == "transit" ? (
                      <>
                        <hr></hr>
                        <Grid
                          lg={12}
                          md={12}
                          sm={12}
                          style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: "25px", padding: "10px 0px" }}>
                            رحلات العبور
                          </p>
                          <p
                            style={{
                              fontSize: "20px",
                              padding: "10px 0px",
                              color: "#f9b917",
                              cursor: "pointer",
                            }}
                            onClick={handleAdd}
                          >
                            إضافة رحلة عبور <AddCircleOutlineIcon />
                          </p>
                        </Grid>
                        {initialFValues1.map((item, index) => (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            style={{
                              display: "flex",
                              marginBottom: "40px",
                              marginTop: "10px",
                            }}
                          >
                            <Grid item xs={12} md={12} key={index}>
                              <InputRowupdate
                                key={index}
                                initialFValues={initialFValues1}
                                setIinitialFValues1={setIinitialFValues1}
                                index={index}
                                item={item}
                                handleChange={handleChange}
                                handleRemove={() => handleRemove(item.id)}
                                handleAdd={handleAdd}
                              />
                            </Grid>
                          </Grid>
                        ))}
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid item xs={5} md={5} lg={5}>
                            <TextField
                              id="outlined-textarea"
                              label={
                                <Fragment>
                                  <span id="number1">{"عدد المقاعد"}</span>
                                </Fragment>
                              }
                              name="transit_count_seats"
                              variant="outlined"
                              value={values.transit_count_seats}
                              onChange={handleInputChange}
                              // onChange={(e) => handleInputChange11(e, values[index].id)}
                              error={errors.transit_count_seats}
                            />
                          </Grid>
                          <Grid item xs={5} md={5} lg={5}>
                            <TextField
                              InputLabelProps={{ shrink: true }} // Add this line
                              id="outlined-textarea"
                              label={
                                <Fragment>
                                  <span id="number1">{"ارقام  المقاعد"}</span>
                                </Fragment>
                              }
                              name="transit_seats_input"
                              variant="outlined"
                              value={values.transit_seats_input || ""}
                              onChange={(e) => handleAddSeat(e)}
                              // onChange={(e) => handleInputChange11(e, values[index].id)}
                              error={errors.transit_seats_input}
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12} md={12}>
                {loadingupdate ? (
                  <div
                    className="bottonFullwidth"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="load loadingupdate"
                      style={{ background: "#F9B917", borderRaduis: "10px" }}
                    >
                      <div v-if="loading" class="spinner">
                        <div class="rect1"></div>
                        <div class="rect2"></div>
                        <div class="rect3"></div>
                        <div class="rect4"></div>
                        <div class="rect5"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="bottonFullwidth"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Controls.Button type="submit" text="تعديل" />
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </>
  );
}
export default TripFormEdit;
