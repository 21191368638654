import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import packageJson from "../../package.json";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchAllRoles = createAsyncThunk(
  'Roles/fetchAllRoles',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getRoles`, { withCredentials: true });
      const result = await res.data.data;
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



const RolesSlice = createSlice({
  name: 'Roles',
  initialState: { Roles: [], loading: false, error: null },
  reducers: {
    clearRoles: (state) => {
      state.Roles = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: {
    //fetch
    [fetchAllRoles.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchAllRoles.fulfilled]: (state, action) => {
      state.Roles = action.payload;
      state.loading = false;
    },
    [fetchAllRoles.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export const { clearRoles } = RolesSlice.actions;
export default RolesSlice.reducer;

