import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

export default function SelectBusType(props) {
  const { name, label, value, error = null, onChange, options , class1} = props;
  return (
    <FormControl variant="outlined" {...(error && { error: true })} >
      <InputLabel  >{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        className={class1}
        
      >
        {options.map((item) => (
          <MenuItem

            key={item.id}
            value={item.id}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
          <hr className="hr-seat"></hr>
          <div style={{width:'100%',height:'40vh'}}>
            <img src={item.image} alt="seats_Type" style={{width:'100%',height:'100%'}} />
          </div>
  
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
