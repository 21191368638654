import { tokens } from "../../styles/theme";
import React, { useEffect, useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { Grid } from "@material-ui/core";
import ConfirmDialog from "../../component/ConfirmDialogCompany";
import { DataGridPro } from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import {
  GridFooterContainer,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridPagination,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import {
  HarddeleteOfficeCompany,
  Offitrashed,
  RestoreOffice,
} from "../../redux/Office";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}
function CustomFooter() {
  return (
    <GridFooterContainer>
      <CustomPagination />
      <GridPagination />
    </GridFooterContainer>
  );
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    flexDirection: "row-reverse",
    paddingRight: "8px",
    height: "45px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
    marginRight: "3px",
  },
  "@media (min-width: 600px) and (max-width:1280px)": {
    searchDesign: {
      marginBottom: "15px",
    },
  },
  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  editButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#000002 !important",
    fontSize: "20px",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  deleteButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#F9B917 !important",
    fontSize: "20px ",
    // fontWeight: "600 !important",
    fontFamily: "beIN",
  },
  editImg: {
    width: "15px",
  },
});

export default function OfficeCompanyRestore({  id_company }) {
  const { user } = useSelector((state) => state.auth);
  const[rowCount1,setRowCount1] = useState();
  const dispatch = useDispatch();

  const HardDeleteBranch =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "hard.delete.branch"
    );


  const RestorBranch =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some(
      (permission) => permission === "restore.branch"
    );



  const RestorBranchdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "restore.branch"
    );

  const HardDeleteBranchdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some(
      (permission) => permission === "hard.delete.branch"
    );
    useEffect(() => {
      dispatch(Offitrashed(id_company));
      setRowCount1(OfficeTrshed.lenght)
    }, [dispatch]);
    const {loading, OfficeTrshed,loadingdeleteHard } = useSelector((state) => state.Offices);

  
  const [filteredData, setFilteredData] = useState(OfficeTrshed);
  const classes = useStyles();
  const [searched, setSearched] = useState("");
  const [clickedEmployeeId, setClickedEmployeeId] = useState(null);


  const requestSearch = (searchedVal) => {
    const filteredRows = filteredData.filter((row) => {
      return row.lastName.toLowerCase().includes(searchedVal.toLowerCase());
    });

    setFilteredData(filteredRows);
  };

  function handleDelete(id) {
    setConfirmDialog({
      isOpen: true,
      title: "أنت على وشك حذف مكتب الشركة بشكل نهائي",
      img: "img/emp/Inbox cleanup-rafiki.svg",
      onConfirm: () => {
        dispatch(HarddeleteOfficeCompany(id));
      },
    });
  }
  function EditToolbar(props) {
    return (
      <Grid container xs={12} className={classes.Marginbox1}>
      
      </Grid>
    );
  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const getPageSize = () => {
    if (window.innerWidth < 992) {
      return 5; 
    } else if (window.innerWidth < 1566) {
      return 7;
    } else if (window.innerWidth < 1760) {
      return 8; 
    } else if (window.innerWidth < 1930) {
      return 13;
    }
  };
  useEffect(() => {
    getPageSize();
  });

  const processRowUpdate = (newRow) => {};

  const columns = [
    {
      field: "name_ar",
      sortable: false,
      headerName: "اسم المكتب",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phone_number",
      sortable: false,
      headerName: "الرقم الاول",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "mobile",
      sortable: false,
      headerName: "الرقم الثاني",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "address",
      sortable: false,
      headerName: "عنوان المكتب",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerName: "",
      align: "center",
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <Box>
            {RestorBranch === true || RestorBranchdefault === true ? (
              <Button
                className={classes.editButton}
                onClick={() => {
                  setClickedEmployeeId(id);
                  dispatch(RestoreOffice(id));
                }}
              >
                استرجاع
                <img
                  src="img/edit.svg"
                  className={classes.editImg}
                  alt="delete icon"
                ></img>
              </Button>
            ) : (
              <></>
            )}
            {HardDeleteBranch === true || HardDeleteBranchdefault === true ? (
              <Button
                className={classes.deleteButton}
                onClick={() => {
                  handleDelete(id);
                }}
              >
                حذف
                <img
                  src="img/delete.svg"
                  className={classes.editImg}
                  alt="delete icon"
                ></img>
              </Button>
            ) : (
              <></>
            )}
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
              loadingdelete={loadingdeleteHard}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <Box
        className="box-triptype"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },

          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "none",
            fontSize: "15px !important",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "15px !important",
            color: `${colors.primary[1000]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.white[100],
          },
        }}
      >
               {loading ? (
          <div className="spinner1-box">
            <svg class="spinner1" viewBox="0 0 50 50">
              <circle
                class="path1"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          </div>
        ) : (
        <DataGridPro
          rows={OfficeTrshed || []}
          columns={columns}
          className="field-table"
          editMode="row"
          pageSize={15}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          pagination
          rowCount={rowCount1}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          components={{
            Toolbar: EditToolbar,
            Footer: CustomFooter,

          }}
       
        />
        )}
      </Box>
    </Box>
  );
}
