import { useLocation, useNavigate } from "react-router-dom";
import { tokens } from "../../styles/theme";
import React, { useEffect, useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { Grid } from "@material-ui/core";
import PopupCreate from "../../component/PopupCreate";
import TripForm from "./TripForm";
import * as sweetalert from "sweetalert";
import ConfirmDialog from "../../component/ConfirmDialog.js";
import { DataGridPro } from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../../component/controls/Controls";
import StopTrip from "./StopTrip";
import PopupStop from "../../component/PopupStop";
import PopupCreate2 from "../../component/PopupCreate2.js";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTripBYId,
  fetchTripBYIdReservation,
  fetchTrips,
} from "../../redux/tripsSlice";
import TripFormEdit from "./TripEdit";
import { fetchCompany } from "../../redux/companyTransportSlice";
import dayjs from "dayjs";
import { useRef } from "react";
import { fetchAllCity } from "../../redux/CitySlice";
import "moment/locale/ar-sa";
import DateForm from "./DateForm";
import {
  deleteTripStatic,
  fetchTripsSchedular,
  insertTripSchedular,
} from "../../redux/tripsSchedularSlice";
import { fetchSeateTypeAll, fetchSeateTypeAllfilter } from "../../redux/SeatsTypeSlice";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";
export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    flexDirection: "row-reverse",
    paddingRight: "8px",
    height: "45px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3) !important",
    marginRight: "3px",
  },
  "@media (min-width: 600px) and (max-width:1280px)": {
    searchDesign: {
      marginBottom: "15px",
    },
  },
  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  editButton: {
    display: "none",
    flexDirection: "row-reverse",
    color: "#000002 !important",
    fontSize: "20px",
    fontFamily: "beIN",
  },
  deleteButton: {
    display: "flex",
    flexDirection: "row-reverse",
    color: "#F9B917 !important",
    fontSize: "20px",
    fontFamily: "beIN",
  },
  editImg: {
    width: "15px",
  },
  Marginbox1: {
    maxWidth: "100% !important",
    flexBasis: "100%",
  },
});
export default function TripsSchedularTable({
  id_company,
  setShortcut,
}) {
  const [currentTime1, setCurrentTime1] = useState(new Date());
  const { user } = useSelector((state) => state.auth);
  const dataGridRef = useRef(null);
  const [focusedColumn, setFocusedColumn] = useState(null);

  //Add Permission
  const AddTrip =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "add.trip.static");
  const HardDeleteTrip =
    user != null &&
    user.PermissionsNames.length > 0 &&
    user.PermissionsNames.some((permission) => permission === "hard.delete.trip.static");



  // Permission as default
  const AddTripdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some((permission) => permission === "add.trip.static");

  const HardDeleteTripdefault =
    user != null &&
    user.rolePermissionsNames.length > 0 &&
    user.rolePermissionsNames.some((permission) => permission === "hard.delete.trip.static");

  function CustomFooter() {
    return (
      <GridFooterContainer>
        <CustomPagination />
        <GridPagination />
      </GridFooterContainer>
    );
  }
  const location = useLocation();

  const {  Trips, TripID } = useSelector((state) => state.Trip);
  const { TripsSchedulars } = useSelector((state) => state.Trip_Schedular);
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState(Trips);
  const [rowCount1, setRowCount1] = useState();

  const currentDateTime = new Date();
  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup3, setOpenPopup3] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForEdit11, setRecordForEdit11] = useState(null);
  const [recordForEdit1, setRecordForEdit1] = useState(null);
  const [openPopupStop, setOpenPopupStop] = useState(false);
  const [clickedEmployeeId, setClickedEmployeeId] = useState(null);
  const [selectedfromcityId, setSelectedFromCityId] = useState(null);
  const [selectedtocityId, setSelectedToCityId] = useState(null);
  const [isTableDisabled, setIsTableDisabled] = useState(false);
  const [date, setDate] = React.useState();
  const [numberpassenger, setNumberPassenger] = useState(0);
  const [showpassenger, setShowPassenger] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const { SeatType } = useSelector((state) => state.SeatsType);

  const tableRef = useRef(null);
  const pathToColumnRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(
    JSON.parse(localStorage.getItem("trip")) || []
  );
  const [removeIdSeatType, setRemoveIdSeatType] = useState(
    JSON.parse(localStorage.getItem("removeIdSeatType")) || false
  );

  useEffect(() => {
    localStorage.setItem("dates", '[]');
  }, []);
  useEffect(() => {
    const tripData = JSON.parse(localStorage.getItem("trip")) || [];
    TripsSchedulars.forEach((row) => {
      const existingRow = tripData.find((item) => item.id === row.id);
      if (!existingRow) {
        const newSelectedOption = {
          id: row.id,
          branch: row.id_branch,
          type: row.tripType,
          id_seat_type: "",
        };
        tripData.push(newSelectedOption);
      }
    });
    setSelectedOption(tripData);

  }, [removeIdSeatType]);

  useEffect(() => {

    const timer = setTimeout(() => {
      if (loading1 === true) {
        setLoading1(false);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, []);


  const handleSelectChange = (event, id, Type, index) => {
    const selectedOption11 = JSON.parse(localStorage.getItem("trip")) || []; // Parse the string into an array or use an empty array if null
    const selectedItem = selectedOption11.find((option) => option.id === id);

    if (selectedItem) {
      selectedItem.id_seat_type = event.target.value;

      const updatedSelectedOptions = selectedOption11.map((option) =>
        option.id === id ? { ...option, id_seat_type: event.target.value } : option
      );

      setSelectedOption(updatedSelectedOptions);
      localStorage.setItem("trip", JSON.stringify(updatedSelectedOptions));
    }
  };

  const handleCheckboxChange = () => {

    const tripData = JSON.parse(localStorage.getItem("trip")) || [];
    TripsSchedulars.forEach((row) => {
      const existingRow = tripData.find((item) => item.id === row.id);
      if (!existingRow) {
        const newSelectedOption = {
          id: row.id,
          branch: row.id_branch,
          type: row.tripType,
          id_seat_type: "",
        };
        tripData.push(newSelectedOption);
      }
    });
    tripData.forEach((trip) => {
      const seatTypeItem = SeatType.find((item) => item.type === trip.type);
      if (seatTypeItem) {
        trip.id_seat_type = seatTypeItem.id;
      }
      localStorage.setItem("trip", JSON.stringify(tripData));

      if (removeIdSeatType === true) {
        trip.id_seat_type = "";
        localStorage.setItem("trip", '[]');
        setSelectedOption([]);
      }
    });

    setRemoveIdSeatType((prevState) => !prevState);
    localStorage.setItem("removeIdSeatType", JSON.stringify(!removeIdSeatType));
    setLoading1(true);
  };
  const SubmitSchedular = () => {
    const tripData = JSON.parse(localStorage.getItem("trip"));
    const date = JSON.parse(localStorage.getItem("dates"));

    if (date.length === 0 || date === null) {
      sweetalert({
        text: "يجب اختيار تاريخ جدولة الرحلات",
        icon: "error",
        buttons: {
          confirm: {
            text: "إغلاق",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      });
    } else if (tripData.length === 0 || tripData === null) {
      sweetalert({
        text: "يجب اخيار الرحلات وانواع الباصات",
        icon: "error",
        buttons: {
          confirm: {
            text: "إغلاق",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      });
    } else {
      let seatTypeEmpty = false;

      for (let i = 0; i < tripData.length; i++) {
        if (tripData[i].id_seat_type === "") {
          seatTypeEmpty = true;
          break;
        }
      }

      if (seatTypeEmpty) {
        sweetalert({
          text: "يجب اختيار أنواع الباصات لجميع الرحلات",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        const data = {
          schedul: {
            date: date,
            trip: tripData,
          },
        };
        dispatch(insertTripSchedular(data));
      }
    }
  };

  const getDataGridInstance = () => {
    if (tableRef.current) {
      return tableRef.current;
    }
    return null;
  };
  useEffect(() => {
    const dataGridInstance = getDataGridInstance();
  }, [tableRef]);

  useEffect(() => {
    if (id_company !== null) {
      dispatch(fetchTrips(id_company));
      dispatch(fetchTrips(id_company));
      dispatch(fetchTripsSchedular(id_company));
      dispatch(fetchSeateTypeAll(id_company));
      dispatch(fetchCompany());
      localStorage.setItem("displayshortcut", "1");
      setFilteredData(Trips);
      if (Trips !== null) {
        setTimeout(() => {
          const targetElement = document.querySelector(
            ".field-table1-trip .MuiDataGrid-virtualScrollerRenderZone > div:first-child"
          );
          const element = document.querySelector(
            ".field-table1-trip .MuiDataGrid-virtualScrollerRenderZone > div:first-child > .MuiDataGrid-cell"
          );
          if (targetElement) {
            element.focus();
            targetElement.classList.add("Mui-selected");
          }
        }, 1000);
      }
    }
    setRowCount1(filteredTripes.length)
  }, [dispatch, setFilteredData]);

  useEffect(() => {
    const elementsWithTabIndexZero = document.querySelectorAll(
      ".field-table1-trip .Mui-selected"
    );
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      let typedWord = "";
      let typedWord2 = "";
      if (/^[a-zA-Z]$/.test(event.key)) {
        typedWord += event.key.toLowerCase();
        typedWord2 += event.key;
        const lastThreeChars = typedWord.substring(typedWord.length - 3);
        if (lastThreeChars === "add") {
          localStorage.setItem("displayshortcut", "1");
          setOpenPopup(true);
          setRecordForEdit(null);
          setShortcut(1);
          typedWord = "";
        }
        if (typedWord.substring(typedWord.length - 1) === "x") {
          localStorage.setItem("displayshortcut", "0");
          setShortcut(0);
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    let typedWord = "";
    let typedWord2 = "";

    const handleKeyDown = (event) => {
      if (event.key === " ") {
        event.preventDefault();
      }
      if (/^[a-zA-Z]$/.test(event.key)) {
        typedWord += event.key.toLowerCase();
        typedWord2 += event.key;

        const lastThreeChars = typedWord.substring(typedWord.length - 3);

        if (lastThreeChars === "add") {
          localStorage.setItem("displayshortcut", "1");
          setOpenPopup(true);
          setRecordForEdit(null);
          setShortcut(1);
          typedWord = "";
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown1 = (event) => {
    if (event.key === "Enter") {
      setOpenPopupStop(true);
      setIsDisabled(true);
    }
  };
  useEffect(() => {
    if (isDisabled === true) {
      const elements = tableRef.current.querySelectorAll(
        '.disabled-grid .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row [tabindex="0"]'
      );
      elements.forEach((element) => {
        element.setAttribute("tabindex", "-1");
      });
    }
  }, [isDisabled]);

  const handleKeyDown2 = (event) => {
    if (event.key === "Enter") {
      setIsDisabled(true);

      const fieldName = event.target.getAttribute("data-field");
      if (fieldName === "path_from" || fieldName === "path_to") {
        setShow(true);
      }
    }
  };
  useEffect(() => {
    if (Trips !== null) {
      setTimeout(() => {
        const targetElement = document.querySelector(
          ".field-table1-trip .MuiDataGrid-virtualScrollerRenderZone > div:first-child"
        );
        const element = document.querySelector(
          ".field-table1-trip .MuiDataGrid-virtualScrollerRenderZone > div:first-child > .MuiDataGrid-cell"
        );
        const autofocusenter = document.querySelector(
          ".field-table1-trip .autofocusenter"
        );
        if (targetElement) {
          element.focus();
          autofocusenter.focus();
          targetElement.classList.add("Mui-selected");
        }
      }, 1000);
    }
  }, [Trips]);
  const intervalRef = useRef(null);

  function handleOpen() {
    setOpenPopup(true);
    setRecordForEdit(null);
  }
  function handleOpen2() {
    setOpenPopup3(true);
    setRecordForEdit(null);
  }
  function handleE(id) {
    setOpenPopup2(true);
    setRecordForEdit1(null);
    setClickedEmployeeId(id);
    dispatch(fetchTripBYId(id));
  }
  useEffect(() => {
    dispatch(fetchAllCity());
  }, [dispatch, id_company]);
  const onHandleReset = () => {
    setSelectedFromCityId(null);
    setSelectedToCityId(null);
    setDate(null);
  };
  function filterTrips(Trips) {
    if (selectedfromcityId && selectedtocityId) {
      return Trips.filter(
        (trip) =>
          trip.path_from === selectedfromcityId &&
          trip.path_to === selectedtocityId
      );
    } else if (selectedfromcityId && date) {
      return Trips.filter(
        (trip) =>
          trip.path_from === selectedfromcityId &&
          trip.date === dayjs(date).format("YYYY-MM-DD")
      );
    } else if (selectedtocityId && date) {
      return Trips.filter(
        (trip) =>
          trip.path_to === selectedtocityId &&
          trip.date === dayjs(date).format("YYYY-MM-DD")
      );
    } else if (selectedfromcityId) {
      return Trips.filter((trip) => trip.path_from === selectedfromcityId);
    } else if (selectedtocityId) {
      return Trips.filter((trip) => trip.path_to === selectedtocityId);
    } else if (date) {
      return Trips.filter(
        (trip) => trip.date === dayjs(date).format("YYYY-MM-DD")
      );
    } else {
      return Trips;
    }
  }
  const filteredTripes = filterTrips(TripsSchedulars);

  const handleDateChange = (newValue) => {
    setDate(newValue);
  };
  useEffect(() => {
    if (dataGridRef.current) {
      const firstRowId = dataGridRef.current.apiRef.current.getRows()[0]?.id;
      const firstColumn = "path_from";
      dataGridRef.current.apiRef.current.setCellFocus(firstRowId, firstColumn);
    }
  }, []);
  function EditToolbar(props) {
    return (
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        className={classes.Marginbox1}
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          alignItems: "baseline",
          gap: "10px",
        }}
      >
        {AddTrip === true || AddTripdefault === true ? (
          <>
            <Grid lg={2} md={2} sm={2} className={classes.AddDesign}>
              <div className="bottonFullwidth1">
                <Controls.Button
                  type="submit"
                  text="اختيار التاريخ"
                  onClick={handleOpen}
                />
              </div>
            </Grid>
            <Grid
              lg={6}
              md={6}
              sm={6}
              className={classes.AddDesign}
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "10px",
              }}
            >
              <Grid
                lg={6}
                md={6}
                sm={6}
                className={classes.AddDesign}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginTop: "10px",
                }}
              >
                <div className="bottonFullwidth1">
                  <Controls.Button
                    type="submit"
                    text="إنشاء رحلة مجدولة"
                    onClick={handleOpen2}
                  />
                </div>
              </Grid>
              <Grid
                lg={6}
                md={6}
                sm={6}
                className={classes.AddDesign}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <div className="form-control-selecte" >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={removeIdSeatType}
                        onChange={() => handleCheckboxChange()}
                        color="primary"

                      />

                    }
                    label="إضافة نوع باص بشكل تلقائي"
                  />
                </div>
              </Grid>
            </Grid>
            <PopupCreate2
              title="إضافة رحلة مجدولة"
              openPopup={openPopup3}
              setOpenPopup={setOpenPopup3}
              setDisplayshortcut1={setShortcut}
            >
              <TripForm id_comp={id_company} recordForEdit={recordForEdit} />
            </PopupCreate2>
          </>
        ) : (
          <></>
        )}
        <PopupCreate2
          title="اختيار تواريخ"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setDisplayshortcut1={setShortcut}
        >
          <DateForm id_comp={id_company} recordForEdit={recordForEdit} setOpenPopup={setOpenPopup} />
        </PopupCreate2>
      </Grid>
    );
  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
  };

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [openPopup1, setOpenPopup1] = useState(false);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const goTo = ({ id, compareResult }) => {
    dispatch(fetchTripBYIdReservation(id));
    navigate(`/reservation`, { state: { id: id, datetime: compareResult } });
  };
  function handleClickEdit(id) {
    setClickedEmployeeId(id);
    dispatch(fetchTripBYId(id));
  }

  function handleDelete(id, id_branch) {
    setConfirmDialog({
      isOpen: true,
      title: "  أنت على وشك حذف هذه الرحلة المجدولة",
      img: "img/emp/Inbox cleanup-rafiki.svg",
      onConfirm: () => {
        dispatch(deleteTripStatic({ id, id_branch }));
      },
    });
  }
  const getPageSize = () => {
    if (window.innerWidth < 992) {
      return 5; // Set a smaller pageSize for smaller screens
    } else if (window.innerWidth < 1566) {
      return 7; // Default pageSize for larger screens
    } else if (window.innerWidth < 1760) {
      return 8; // Default pageSize for larger screens
    } else if (window.innerWidth < 1930) {
      return 13; // Default pageSize for larger screens
    }
  };

  const columns = [
    {
      field: "name",
      sortable: false,
      resizable: true,
      headerName: "الرحلة",
      maxWidth: 80,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div
          className=""
          data-field={params.field}
          onKeyDown={handleKeyDown2}
          tabindex={0} // Allow div to be focusable
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "transit",
      sortable: false,
      resizable: true,
      headerName: "رحلة العبور",
      maxWidth: 150,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          {params.value && params.value.length === 1 ? (
            <div>
              {params.value[0].name} -- {params.value[0].time}
            </div>
          ) : params.value && params.value.length >= 2 ? (
            <div>
              <div>
                {params.value[0].name} --
                {params.value[0].time}
              </div>

              <div>
                {params.value[1].name} --
                {params.value[1].time}
              </div>
            </div>

          ) : (
            <p> </p>
          )}
        </div>
      ),
    },
    {
      field: "time",
      sortable: false,
      headerName: "التوقيت",
      resizable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tripType",
      sortable: false,
      headerName: "نوع الرحلة",
      resizable: true,
      headerAlign: "center",
      align: "center",
      ref: { pathToColumnRef },

    },
    {
      field: "status",
      sortable: false,
      resizable: true,
      headerName: "أماكن التوقف",
      maxWidth: 80,
      minWidth: 80,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: ({ id }) => {
        return (
          <>
            <div
              className="autofocusenter"
              onKeyDown={handleKeyDown1}
              tabindex={0} // Allow div to be focusable
            >
              <img
                src="img/vuesax-linear-eye.svg"
                onClick={() => {
                  setOpenPopupStop(true);
                  setRecordForEdit(null);
                  setClickedEmployeeId(id);
                  dispatch(fetchTripBYId(id));
                }}
                alt="eye-image"
              />
            </div>
            <PopupStop
              title="أماكن التوقف"
              openPopup={openPopupStop}
              setOpenPopup={setOpenPopupStop}
            >
              <StopTrip id_Trip={clickedEmployeeId} TripID={TripID} />
            </PopupStop>
          </>
        );
      },
    },
    {
      field: "select",
      headerName: "تحديد",
      sortable: false,
      resizable: true,
      maxWidth: 50,
      minWidth: 50,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const option = selectedOption.find((option) => option.id === params.row.id);
        const tripData = JSON.parse(localStorage.getItem("trip")) || [];
        const isCheckboxChecked = tripData.some((item) => item.id === params.row.id);

        const handleCheckboxChange = (e) => {
          const checked = e.target.checked;

          if (checked) {
            const updatedOption = {
              id: params.row.id,
              type: params.row.tripType,
              id_seat_type: '', // Assuming id_seat_type is the same as id in this case
            };
            const filterSeat = {
              id: id_company,
              type: params.row.tripType,
              id_seat_type: '', // Assuming id_seat_type is the same as id in this case
            };
            dispatch(fetchSeateTypeAllfilter(filterSeat));
            const existingOption = selectedOption.find((item) => item.id === params.row.id);

            if (!existingOption) {
              const updatedOptions = [...selectedOption, updatedOption];
              setSelectedOption(updatedOptions);
            }

            const updatedLocalStorage = JSON.parse(localStorage.getItem("trip")) || [];
            const existingOptionIndex = updatedLocalStorage.findIndex((item) => item.id === params.row.id);

            if (existingOptionIndex !== -1) {
              updatedLocalStorage[existingOptionIndex] = updatedOption;
            } else {
              updatedLocalStorage.push(updatedOption);
            }

            localStorage.setItem("trip", JSON.stringify(updatedLocalStorage));
          } else {
            const updatedOptions = selectedOption.filter((item) => item.id !== params.row.id);
            setSelectedOption(updatedOptions);

            const updatedLocalStorage = JSON.parse(localStorage.getItem("trip")) || [];
            const updatedLocalStorageFiltered = updatedLocalStorage.filter((item) => item.id !== params.row.id);

            localStorage.setItem("trip", JSON.stringify(updatedLocalStorageFiltered));
          }
        };


        return (
          <Checkbox
            checked={isCheckboxChecked}
            color="primary"
            inputProps={{ "aria-label": "checkbox" }}
            onChange={handleCheckboxChange}
          />
        );
      },
    },
    {
      field: "select4-type-bus",
      headerName: "نوع الباص",
      sortable: false,
      resizable: true,
      maxWidth: 180,
      minWidth: 180,
      headerAlign: "center",
      align: "center",
      className: "oloa",
      renderCell: (params) => (
        <FormControl sx={{ m: 1, width: 300 }}>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"

            name="select4-type-bus"
            value={
              selectedOption.find((option) => option.id === params.row.id)
                ?.id_seat_type || ""
            }
            onChange={(e) =>
              handleSelectChange(e, params.row.id, params.row.tripType)
            }
            input={<OutlinedInput label="Name" />}
            className="select-seat "
          >
            {SeatType.map((name) => (
              <MenuItem key={name.id} value={name.id}>
                <div style={{ width: "100%", height: "40vh" }}>
                  <hr className="hr-seat"></hr>
                  <img
                    src={name.image}
                    alt="seats_Type"
                    style={{ width: "100%", height: "100%" }}
                  />
                  {/* {name.title} */}

                  {/* min-width: 341px; */}
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },

    {
      field: "action4",
      headerAlign: "center",
      resizable: true,
      minWidth: 40,
      maxWidth: 40,
      sortable: false,
      headerName: "",
      align: "center",
      renderCell: (params) => {
        const id = params.row.id;
        const id_branch = params.row.id_branch;
        const date = params.row.date;
        const time = params.row.time;
        const tripDateTime = new Date(`${date}T${time}`);
        const isDateTimePassed = `${date}${time}` < new Date();
        const compareResult = tripDateTime.getTime() - currentTime1.getTime();

        return (
          <Box>
          {(HardDeleteTripdefault === true || HardDeleteTrip === true)?(
            <Button
              className={classes.editButton}
              onClick={() => handleDelete(params.row.id, params.row.id_branch)}
              disabled={compareResult < 0 ? true : false}
            >
              <img
                src="img/delete.svg"
                className={classes.editImg}
                alt="delete icon"
              ></img>
            </Button>
          ):(<></>)}

            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </Box>
        );
      },
    },
    {
      field: "action",
      headerAlign: "center",
      minWidth: 40,
      maxWidth: 40,
      sortable: false,
      headerName: "",
      align: "center",
      renderCell: (params) => {
        const id = params.row.id;
        const date = params.row.date;
        const time = params.row.time;
        const tripDateTime = new Date(`${date}T${time}`);
        const isDateTimePassed = `${date}${time}` < new Date();
        const compareResult = tripDateTime.getTime() - currentTime1.getTime();

        return (
          <Box>
            {compareResult < 0 ? (
              <Button className="finishtrip" disabled={true}>
                منتهية
              </Button>
            ) : (
              <Button className="nofinishtrip">منتهية</Button>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box>
        <Box
          className="box-triptype2 table-accounting"
          style={{ display: "flex", gap: "10%" }}
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },

            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "none",
              fontSize: "15px !important",
            },
            "& .MuiDataGrid-cellContent": {
              fontSize: "15px !important",
              color: `${colors.primary[1000]} !important`,
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.white[100],
            },
          }}
        >
          <DataGridPro
            className="field-table schedular-table"
            ref={tableRef}
            rows={filteredTripes || []}
            columns={columns.map((column) => ({
              ...column,
              headerRender: (params) => {
                return (
                  <div
                    tabIndex={params.field === focusedColumn ? 0 : -1} // Set tabindex based on focused column
                    onFocus={() => setFocusedColumn(params.field)}
                  >
                    {params.headerName}
                  </div>
                );
              },
            }))}
            editMode="row"
            pageSize={10}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            rowCount={rowCount1}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            components={{
              Toolbar: EditToolbar,
              Footer: CustomFooter,
            }}
          />
          {/* )} */}
        </Box>
        {AddTrip === true || AddTripdefault === true ? (
        <Grid
          lg={12}
          md={12}
          sm={12}
          className={classes.AddDesign}
          style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}
        >
          <div className="bottonFullwidthSchedular">
            <Controls.Button
              type="submit"
              text="إنشاء رحلات"
              onClick={SubmitSchedular}
            />
          </div>
        </Grid>
        ):(<></>)}
      </Box>
    </>
  );
}
