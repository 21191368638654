import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

export default function SelectBusType3(props) {
  const { name, label, value, error = null, onChange, options , class1} = props;
  return (
    <FormControl variant="outlined" {...(error && { error: true })} >
      <InputLabel  >{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        className={class1}
        
      >
            {options.map((item) => (
          <MenuItem key={item.id} value={item.plate_number}>
            {item.plate_number} -{item.plate_code}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
