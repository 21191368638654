import React, { useEffect, useState } from "react";
import Card from "./CardComplaint";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchComplaints } from "../../redux/Complaints";
const useStyles = makeStyles({
  gridDivide: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "23px",
  },
  "@media (min-width: 768px) and (max-width:1366px)": {
    gridDivide: {
      justifyContent: "center",
      alignItems: "center",
    },
    searchDesign: {
      width: "100%",
    },
  },
  "@media (min-width: 1366px) and (max-width:1556px)": {
    gridDivide: {
      justifyContent: "space-around",
      alignItems: "space-around",
    },
  },
  mt: {
    marginTop: "30px",
  },

  searchDesign: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    height: "45px !important",
    marginBottom: "20px !important",
    paddingRight: "8px",
    flexDirection: "row-reverse",
    oxShadow:'0 2px 6px rgba(0, 0, 0, 0.3) !important',
  },
  searchDesign1: {
    direction: "rtl",
    marginTop: "0 !important",
    width: "95%",
    height: "45px !important",
    marginBottom: "20px !important",
    paddingRight: "8px",
    flexDirection: "row-reverse",
    boxShadow:'0 2px 6px rgba(0, 0, 0, 0.3) !important',
  

  },

  filter: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    flexWrap: "wrap !important",
    height: "40px !important",
    borderRadius: "5px !important",
  },
  contentDivide: {
    width: "100%",
    marginBottom: "40px",
  },
  Marginbox1: {
    marginBottom: "20px !important",
  },
  AddDesign: {
    marginBottom: "20px",
  },
});

const CardListComplaint = ({ mylist }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
   const [currentPage, setCurrentPage] = useState(1); 
   const itemsPerPage = 5; 
   const startIndex = (currentPage - 1) * itemsPerPage;
   const endIndex = startIndex + itemsPerPage;
  const { Complaint } = useSelector((state) => state.Compolaint);
  useEffect(() => {
    dispatch(fetchComplaints());
  }, [dispatch]);
  return (
    <Grid className={classes.gridContainer} item lg={12}>
      <Grid
        container
        spacing={4}
        className={classes.gridContainer}
        justify="center"
        style={{display:'flex',flexDirection:'column'}}
      >
        <Grid container xs={12} className={classes.Marginbox1} >
        </Grid>
        <Grid className={classes.gridDivide}>
          <Card Complaints={Complaint} startIndex={startIndex} endIndex={endIndex}/>
        </Grid>
        <Pagination
              count={Math.ceil(Complaint.length / itemsPerPage)}
              page={currentPage}
              onChange={(event, page) => setCurrentPage(page)}
              className={classes.mt}
            />
      </Grid>
    </Grid>
  );
};
export default CardListComplaint;
