import UserForm from "./pages/auth/Login";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { ErrorPage } from "./component/ErrorPage";
import CardList1 from "./pages/EmployeeNew/CardList";
import Notifcation1 from "./pages/Notification/CardList";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux";
import CardList from "./pages/CompanyTransport/CardList";
import AppLayout from "./component/AppLayout";
import ComplaintsList from "./pages/Complaints/ComplaintsList";
import Filter from "./component/Filter";
import Trips from "./pages/Trips/Trips";
import CardListRating from "./pages/Rating/CardListRating";
import CardListComplaint from "./pages/Complaint/CardListComplaint";
import Table from "./component/Table";
import CardListTrashed from "./pages/EmployeeNew/CardListTrashed";
import CompanyEditForm from "./pages/CompanyTransport/CompanyEditForm";
import DeactiveClient from "./pages/Deactiv-client/Deactive-client";
import CardList2 from "./pages/CompanyTransport/CardList2";
import EmployeeAdmin from "./pages/EmployeeNew/CardList2";
import RestorCompanyTable from "./pages/Complaints/RestorCompanyTable";
import Permission from "./pages/EmployeeNew/Permission/Permissions";
import { useEffect, useState } from "react";
import CardListAdminTrashed from "./pages/EmployeeNew/CardListAdminTrashed";
import AddPrivacyPolicy from "./pages/Privacypolicy/addPrivacyPolicy";
import ProfitTable from "./pages/provit/provitTable";
import TransportCompanyData from "./pages/Complaints/TranspotCompanyData";

import i18n from "i18next";
import ReactVirtualizedTable from "./pages/Trips/TripTable";
import TripForm from "./pages/Trips/TripForm";


const isLoggedIn = localStorage.getItem("isLoggedIn");
const Redirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    } else {
      i18n.changeLanguage("ar"); // Set the language to Arabic
    }
  }, [isLoggedIn, navigate, i18n]);
};
const router = createBrowserRouter([
  {
    path: "/",
    element: isLoggedIn ? <AppLayout /> : <UserForm />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/Trips",
        element: <Trips />,
      },
      {
        path: "/TransportCompanyData",
        element: <CardList />,
      },
      {
        path: "/Empolyees",
        element: <CardList1 />,
      },
      {
        path: "/company/:id/trip-type",
        element: <TransportCompanyData />,
      },
      {
        path: "/Empolyees11",
        element: <CompanyEditForm />,
      },
      {
        path: "/add",
        element: <TripForm />,
      },
      {
        path: "/Empolyees-trashede",
        element: <CardListTrashed />,
      },
      {
        path: "/Admin-trashede",
        element: <CardListAdminTrashed />,
      },
   
      {
        path: "/Notification",
        element: <Notifcation1 />,
      },
      {
        path: "/CompanyData",
        element: <ComplaintsList />,
      },
      {
        path: "/Complains",
        element: <Filter />,
      },
      {
        path: "/Complaint",
        element: <CardListComplaint />,
      },
  
   

      {
        path: "/Ratings",
        element: <CardListRating />,
      },
      {
        path: "/Table",
        element: <Table />,
      },
    
      {
        path: "test",
        element: <ReactVirtualizedTable />,
      },
      {
        path: "/Deactive-client-account",
        element: <DeactiveClient />,
      },

      {
        path: "/Company",
        element: <CardList2 />,
      },
      {
        path: "/restore-company",
        element: <RestorCompanyTable />,
      },
      {
        path: "/company-admin",
        element: <EmployeeAdmin />,
      },
      {
        path: "/permission-user",
        element: <Permission />,
      },

      {
        path: "/privacy-policy",
        element: <AddPrivacyPolicy />,
      },
      {
        path: "/profit",
        element: <ProfitTable />,
      },
    ],
  },
]);

function App() {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    if (!isLoggedIn && window.location.pathname !== "/") {
      window.location.href = "/";
    }
  }, [isLoggedIn, window.location.href]);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} toggleSidebar={toggleSidebar} />
      </PersistGate>
    </Provider>
  );
}

export default App;
