import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as sweetalert from "sweetalert";
import packageJson from "../../package.json";
import { fetchPaths } from "./PathSlice";
import { fetchTripsManulaDone } from "./tripsManulDoneSlice";
import { fetchTripsLock } from "./tripsLock";

export const BaseUrl = packageJson.projectURlApi.BaseUrl;
export const fetchCompanyById = createAsyncThunk(
  "company/fetchCompanyById",
  async (data, thunkAPI) => {
    const { rejectWithValue ,dispatch } = thunkAPI;
    try {
      dispatch(clearCompanyData1());
      const res = await axios.get(`${BaseUrl}/getCompany/${data}`, {
        withCredentials: true,
      });
      dispatch(fetchPaths(res.data.data.id));
      dispatch(fetchTripsManulaDone(res.data.data.id));
      dispatch(fetchTripsLock(res.data.data.id));
      const {
        branch,
        bus,
        trip_type,
        id,
        name_ar,
        description,
        logo,
        social_media,
      } = res.data.data; // Extract branch and bus properties
      return {
        branch,
        bus,
        trip_type,
        id,
        name_ar,
        description,
        logo,
        social_media,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchCompanyBranch = createAsyncThunk(
  "company/fetchCompanyBranch",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getCompany/${data}`, {
        withCredentials: true,
      });
      const { branch } = res.data.data; // Extract branch and bus properties
      return { branch };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchAllTrashedCompany = createAsyncThunk(
  "company/fetchAllTrashedCompany",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getAllTrashedCompany`, {
        withCredentials: true,
      });
      const result = res.data.data; // Extract branch and bus properties
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCompanyBranchIdCity = createAsyncThunk(
  "company/fetchCompanyBranchIdCity",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BaseUrl}/getCompany/${data}`, {
        withCredentials: true,
      });
      const { branch } = res.data.data; // Extract branch and bus properties
      return { branch };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const insertCompany = createAsyncThunk(
  "company/insertCompany",
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/addCompany`, values, {
        withCredentials: true,
      });
      sweetalert({
        text: "تم الإضافة بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.data &&
        error.response.data.data.name_ar
      ) {
        const errorMessages = error.response.data.data.name_ar;

        sweetalert({
          title: "",
          text: "اسم الشركة موجود مسبقا",
          icon: "error",
          buttons: {
            confirm: {
              text: "OK",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else if (error.response && error.response.status === 403) {
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "OK",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);

export const RestorinsertCompany = createAsyncThunk(
  "company/RestorinsertCompany",
  async (id, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const response = await axios.get(`${BaseUrl}/restoreCompany/${id}`, {
        withCredentials: true,
      });
      if (response.data && response.data.status === true && response.data.msg) {
        sweetalert({
          text: response.data.msg,
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updateCompany = createAsyncThunk(
  "company/updateCompany",
  async (values, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      await axios.post(`${BaseUrl}/updateCompany/${values.get("id")}`, values, {
        withCredentials: true,
      });
      sweetalert({
        text: "تم التعديل بنجاح",
        icon: "success",
        buttons: {
          confirm: {
            text: "تم",
            className: "custom-button-class",
          },
        },
        className: "alert-div",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);
export const deleteCompany = createAsyncThunk(
  "company/deleteCompany",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(
        `${BaseUrl}/softDeleteCompany/${id}`,
        { withCredentials: true }
      );
      if (response.data && response.data.status === true && response.data.msg) {
        sweetalert({
          text: response.data.msg,
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      // Check if the error status code is 403 Forbidden or another status code
      if (error.response && error.response.status === 403) {
        // Handle the 403 Forbidden error specifically
        sweetalert({
          text: "ليس لديك صلاحية للقيام بهذا",
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      } else {
        // Handle other errors with a generic message
        const errorMessage =
          error.response?.data?.message || error.response?.data?.data;
        sweetalert({
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "إغلاق",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        });
      }
      return rejectWithValue(error.message);
    }
  }
);
export const HarddeleteCompany = createAsyncThunk(
  "company/HarddeleteCompany",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(
        `${BaseUrl}/hardDeleteCompany/${id}`,
        { withCredentials: true }
      );

      if (response.data && response.data.status === true && response.data.msg) {
        sweetalert({
          text: response.data.msg,
          icon: "success",
          buttons: {
            confirm: {
              text: "تم",
              className: "custom-button-class",
            },
          },
          className: "alert-div",
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const CompanyDataSlice = createSlice({
  name: "CompanyData",
  initialState: {
    CompaniesData: [],
    CompanyBranch: [],
    AddCompanies: [],
    UpdateCompany: [],
    TrashComany: [],
    RestorCompany: [],
    loading: false,
    loadingRestor: false,
    loadingdelete: false,
    loadingUpdate: false,
    loadingAdd: false,
    error: null,
  },
  reducers: {
    clearCompanyData1: (state) => {
      state.CompaniesData = [];
      state.CompanyBranch = [];
      state.AddCompanies = [];
      state.UpdateCompany = [];
      state.TrashComany = [];
      state.RestorCompany = [];
      state.loading = false;
      state.loadingAdd = false;
      state.loadingdelete = false;
      state.loadingUpdate = false;
      state.loadingRestor = false;
      state.error = null;
    },
  },
  extraReducers: {
    //fetch
    [fetchCompanyById.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchCompanyById.fulfilled]: (state, action) => {
      state.CompaniesData = action.payload;
      state.loading = false;
    },
    [fetchCompanyById.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //fetch Trash Company
    [fetchAllTrashedCompany.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchAllTrashedCompany.fulfilled]: (state, action) => {
      state.TrashComany = action.payload;
      state.loading = false;
    },
    [fetchAllTrashedCompany.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //fetch Company Branch
    [fetchCompanyBranch.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchCompanyBranch.fulfilled]: (state, action) => {
      state.CompanyBranch = action.payload;
      state.loading = false;
    },
    [fetchCompanyBranch.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //Add Company
    [insertCompany.pending]: (state, action) => {
      state.loadingAdd = true;
      state.error = null;
    },
    [insertCompany.fulfilled]: (state, action) => {
      state.AddCompanies = action.payload;
      state.loadingAdd = false;
    },
    [insertCompany.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingAdd = false;
    },
    //Restor Company
    [RestorinsertCompany.pending]: (state, action) => {
      state.loadingRestor = true;
      state.error = null;
    },
    [RestorinsertCompany.fulfilled]: (state, action) => {
      state.RestorCompany = action.payload;
      state.loadingRestor = false;
    },
    [RestorinsertCompany.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingRestor = false;
    },
    //update Company
    [updateCompany.pending]: (state, action) => {
      state.loadingUpdate = true;
      state.error = null;
    },
    [updateCompany.fulfilled]: (state, action) => {
      state.UpdateCompany = action.payload;
      state.loadingUpdate = false;
    },
    [updateCompany.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingUpdate = false;
    },
    //delete Company
    [deleteCompany.pending]: (state, action) => {
      state.loadingdelete = true;
      state.error = null;
    },
    [deleteCompany.fulfilled]: (state, action) => {
      if (Array.isArray(state.CompaniesData)) {
        // Check if CompaniesData is an array
        state.CompaniesData = state.CompaniesData.filter(
          (el) => el.id !== action.payload.id
        );
      }
      state.loadingdelete = false;
    },
    [deleteCompany.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingdelete = false;
    },
    //delete  hard Company
    [HarddeleteCompany.pending]: (state, action) => {
      state.loadingdelete = true;
      state.error = null;
    },
    [HarddeleteCompany.fulfilled]: (state, action) => {
      if (Array.isArray(state.CompaniesData)) {
        // Check if CompaniesData is an array
        state.CompaniesData = state.CompaniesData.filter(
          (el) => el.id !== action.payload.id
        );
      }
      state.loadingdelete = false;
    },
    [HarddeleteCompany.rejected]: (state, action) => {
      state.error = action.payload;
      state.loadingdelete = false;
    },
  },
});
export const { clearCompanyData1 } = CompanyDataSlice.actions;
export default CompanyDataSlice.reducer;
