import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import { useNavigate } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Popup from "../../component/Popup";
import EmployeeForm from "./NotifcationAddForm";
import EmployeeFormPassword from "./EmployeeFormPassword";
import ConfirmDialog from '../../component/ConfirmDialog.js';
import { Grid } from "@material-ui/core";
const useStyles = makeStyles({
  root: {
    minWidth: '100%',
   
  },

  content:{
    display:"flex",
    flexDirection:"column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: '0 !important',
    position:'relative'
  },
  media:{
    width:113,
    height:113,
    margin:'12px 16px 12px 0',
  },
  title:{
    fontFamily:"beIN Normal",
    fontSize:27,
    color:"#000002",
    fontWeight:'normal',
    },
    divnotifcation:{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
    
    }
});

 const OutlinedCard = ({startIndex, endIndex,Notifcations}) => {
  const classes = useStyles();
  const navigate=useNavigate();
  const bull = <span className={classes.bullet}>•</span>;
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopupPassword, setOpenPopupPassword] = useState(false);
  const [openPopupEdit, setOpenPopupEdit] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null)
  function handleClick  (event)  {
    setAnchorEl(event.currentTarget);
    
  };


  function handleEdit ()  {
    setOpenPopupEdit(true); 
    setRecordForEdit(null); 
  }
  function handlePassword ()  {
    setOpenPopupPassword(true); 
    setRecordForEdit(null); 
  }
  function handleDelete ()  {
    setConfirmDialog({
      isOpen: true,
      title: 'أنت على وشك حذف هذا الإشعار',
      img:'img/emp/Inbox cleanup-rafiki.svg',
      
    })
    
  }
  function handleClose ()  {
    setAnchorEl(null);

  }
  return (
    <>
     {Notifcations.slice(startIndex, endIndex).map((not) => (
      // Add a conditional check for not before rendering the Card
      // Check if not exists and is not null or undefined
      not && (
        <Card className={classes.root} variant="outlined" >
          <CardContent className={classes.content}>
          <Grid item className={classes.divnotifcation}>
          <Grid item>

            <CardMedia  title="Contemplative Reptile">
              <img src='img/notifcation/photo-1633332755192-727a05c4013d.svg' className={classes.media} alt='img-notifcation'/>
            </CardMedia>
          </Grid>
          <Grid item>
         
            <div className="body-container2">
            <div className="divtitleone">
           
              <span>اسم الموظف : {not.nameSystemUser}  & الشركة التابع لها : {not.CompanySystemUser}  <img src="img/notifcation/vuesax-linear-arrow-left.svg"/> 
              {not.typeNotification ==='clients' ?('كل مستخدمي التطبيق') :('المسافرين ضمن رحلة محددة')}
              </span>
            </div>
            <div className="divtitletwo">
           
              <span>{not.body}</span>
            </div>
           
            </div>
           
          </Grid>
          </Grid>
          </CardContent>
        </Card>
        )
    ))}
    </>
  );
}
export default OutlinedCard;