import React from "react";
import { Button, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0,
  },
  secondary: {
    "& .MuiButton-label": {
      color: "#fff",
      background: "#F9B917",
      borderRadius: "50%",
      width: "25px",
      height: "25px",
      position: "relative",
      right: "55px",
    },

    borderRadius: "50%",
  },

  primary: {
    backgroundColor: theme.palette.primary.light,
    "& .MuiButton-label": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function ActionButton(props) {
  const { color, children, onClick } = props;
  const classes = useStyles();

  return (
    <Button className={`${classes.root} ${classes[color]}`} onClick={onClick}>
      {children}
    </Button>
  );
}
