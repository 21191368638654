import React, { useEffect, Fragment } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../component/controls/Controls";
import { useFormStop, FormStop } from "../Trips/useFormStop";

import { makeStyles } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { fetchPathsById } from "../../redux/PathSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    direction: "rtl",
  },
}));

const initialFValues = {
  tripName: "",
  tripPrice: "",
  tripDiscount: "",
  tripDate: "",
};

export default function StopTripType(props) {
  const { loading, PathsID } = useSelector((state) => state.Paths);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("tripName" in fieldValues)
      temp.tripName = fieldValues.tripName ? "" : "This field is required.";
    if ("tripPrice" in fieldValues)
      temp.tripPrice = fieldValues.tripPrice ? "" : "This field is required.";
    if ("tripDiscount" in fieldValues)
      temp.tripDiscount = fieldValues.tripDiscount
        ? ""
        : "This field is required.";
    if ("tripDate" in fieldValues)
      temp.tripDate = fieldValues.tripDate ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useFormStop(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });

    dispatch(fetchPathsById(props.id_path_row));
  }, [dispatch, props.id_path_row]);

  return (
    <>
      {loading ? (
        <div className="spinner1-box">
          <svg class="spinner1" viewBox="0 0 50 50">
            <circle
              class="path1"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </div>
      ) : (
        <Grid className="stopPlaces" style={{ height: "100%" }}>
          <Grid item xs={2} md={2}>
            <img src="img/stop.svg" alt="line"></img>
          </Grid>
          <FormStop onSubmit={handleSubmit}>
            <Grid item xs={10} md={10} style={{ height: "100%" }}>
              <Controls.Input
                className="gggggggg"
                name="fullName"
                disabled={true}
                label={
                  <Fragment>
                    <InputAdornment position="end">
                      <IconButton
                        className="iconplaceholder"
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <img src="img/stop1.svg"></img>
                      </IconButton>
                      <span className="placeholdertitle">
                        الانطلاق: {PathsID?.CityFrom}
                      </span>
                    </InputAdornment>
                  </Fragment>
                }
                value={values.tripPrice}
                onChange={handleInputChange}
                error={errors.tripPrice}
              />
              {/* {PathsID?.stations} */}
              {PathsID?.stations &&
                PathsID?.stations.map((station) => (
                  <Controls.Input
                    disabled={true}
                    key={station.id}
                    className="tesr"
                    label={
                      <Fragment>
                        <InputAdornment position="end">
                          <IconButton
                            className="iconplaceholder"
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <img src="img/stop2.svg"></img>
                          </IconButton>
                          <span className="placeholdertitle">
                            نقطة التوقف :{station.name}
                          </span>
                        </InputAdornment>
                      </Fragment>
                    }
                    value={values.tripPrice}
                    onChange={handleInputChange}
                    error={errors.tripPrice}
                  />
                ))}

              <Controls.Input
                disabled={true}
                className="tesr"
                name="tripPrice"
                label={
                  <Fragment>
                    <InputAdornment position="end">
                      <IconButton
                        className="iconplaceholder"
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <img src="img/stop1.svg"></img>
                      </IconButton>
                      <span className="placeholdertitle">
                        الوجهة: {PathsID?.CityTo}
                      </span>
                    </InputAdornment>
                  </Fragment>
                }
                value={values.tripPrice}
                onChange={handleInputChange}
                error={errors.tripPrice}
              />
            </Grid>
          </FormStop>
        </Grid>
      )}
    </>
  );
}
